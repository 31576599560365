export enum ModuleType {
  GestionDesAbsences,
  GestionNoteDeFrais,
  GestionDeVisiteMedicale,
  GestionEntretienAnnuel,
  GestionDesFormations,
  GestionDesFichesDePostesCV,
  GestionDesFournisseurs,
  GestionDesST,
  GestionDesIntervenantsPOA,
  GestionDesPlanningsPOA,
  GestionDesLivrablesPOA,
  GestionEtudeDePrixPOA,
  GestionMethodesPOA,
  GestionQualitePOA,
  GestionHSEPOA,
  GestionDesEtudesPOA,
  GestionDesRisquesPOA,
  GestionDesReunionsPOA,
  GestionDesRH,
  GestionDesPOA,
  GestionDesPREA,
  GestionDesFournisseursPREA,
  GestionDesSTPREA,
  GestionDesIntervenantsPREA,
  GestionDesPlanningsPREA,
  GestionDesLivrablesPREA,
  GestionEtudeDePrixPREA,
  GestionMethodesPREA,
  GestionQualitePREA,
  GestionHSEPREA,
  GestionDesEtudesPREA,
  GestionDesRisquesPREA,
  GestionDesReunionsPREA,
  GestionDesRHRapports,
  GestionDesRapports,
  GestionDesDocuments,
  GestionDesMaterials,
  GestiondesSousTraitant,
}
