import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { removeExpense } from '@/state/slices/projectExpensesSlice';
import { ExpenseModel } from '@/models/ProjectExpenseModel';
import ExpensesTableForm from './ExpensesTableForm';
import { ExpenseCategory } from '@/enums/ExpenseCategory';
import { Currency } from '@/enums/Currency';
import { PaymentMethod } from '@/enums/PaymentMethod';
// Import any necessary actions or services
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { formatCurrency } from '@/utils/helpers';

interface ExpensesTableProps {
  expenses: ExpenseModel[]; // Pass the array of expenses
  readonly?: boolean;
}

const ExpensesTable: React.FC<ExpensesTableProps> = ({
  expenses,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editExpense, setEditExpense] = useState<ExpenseModel | null>(null);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { projectExpense, status } = useSelector(
    (state: RootState) => state.projectExpenses
  );
  // Function to handle adding a new expense
  const handleAddClick = () => {
    setEditExpense(null);
    setIsModalOpen(true);
  };

  // Function to handle editing an expense
  const handleEditClick = (expense: ExpenseModel) => {
    setEditExpense(expense);
    setIsModalOpen(true);
  };

  // Function to handle deleting an expense
  const handleDeleteClick = (expenseId: string) => {
    dispatch(removeExpense({ expenseId: expenseId }));
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setEditExpense(null);
  };

  // Function to handle saving an expense
  const handleSaveExpense = (expense: ExpenseModel) => {
    // Handle save logic
    // Call the API or dispatch an action to save the expense
    closeModal();
  };

  return (
    <div className="w-full overflow-auto">
      <table className="w-full max-w-full overflow-x-scroll text-left text-sm text-gray-500 dark:text-gray-400">
        {/* Table headers */}
        <thead className="text-md bg-gray-50 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {/* <th className="px-6 py-3">{t('expenses.id')}</th> */}
            {/* <th className="px-6 py-3">{t('expenses.projectExpenseId')}</th> */}
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.title')}
            </th>

            <th className="px-6 py-3">{t('expenses.actions')}</th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.nature')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.state')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.country')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.category')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.fromCurrency')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.amountExcludingTax')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">{t('expenses.vat')}</th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.taxRate')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.total')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.totalAfterExchange')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.expenditureDate')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.paymentMethod')}
            </th>
            <th className="whitespace-nowrap px-6 py-3">
              {t('expenses.comments')}
            </th>
          </tr>
        </thead>
        <tbody>
          {expenses.map((expense) => (
            <tr
              key={expense.id}
              className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              {/* <td className="px-6 py-3">{expense.id}</td> */}
              {/* <td className="px-6 py-3">{expense.projectExpenseId}</td> */}
              <td className="whitespace-nowrap px-6 py-3">{expense.title}</td>
              {readonly ? (
                <td className="flex px-6 py-3">
                  <EyeIcon
                    onClick={() => handleEditClick(expense)}
                    className="h-5 w-5 text-gray-600 dark:text-gray-300"
                  />
                </td>
              ) : (
                <td className="flex px-6 py-3">
                  <button
                    type="button"
                    onClick={() => handleEditClick(expense)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {t('expenses.edit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteClick(expense.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    {t('expenses.delete')}
                  </button>
                </td>
              )}
              <td className="whitespace-nowrap px-6 py-3">{expense.nature}</td>
              <td className="whitespace-nowrap px-6 py-3">
                {expense.stateName}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {expense.countryName}
              </td>

              <td className="whitespace-nowrap px-6 py-3">
                {ExpenseCategory[expense.category]}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {Currency[expense.fromCurrency]}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {formatCurrency(
                  expense.amountExcludingTax,
                  expense.fromCurrency
                )}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {formatCurrency(expense.vat, expense.fromCurrency)}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {formatCurrency(expense.taxRate, expense.fromCurrency)}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {formatCurrency(expense.total, expense.fromCurrency)}
              </td>
              <td className="whitespace-nowrap px-6 py-3">
                {formatCurrency(
                  expense.total / expense.exchangeRate,
                  projectExpense.projectExpenseCurrency
                )}
              </td>
              <td className="px-6 py-3">
                {moment(expense.expenditureDate).format('DD/MM/YYYY')}
              </td>
              <td className="px-6 py-3">
                {PaymentMethod[expense.paymentMethod]}
              </td>
              <td className="px-6 py-3">{expense.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {!readonly && (
        <div className="my-4 flex justify-start">
          <button
            type="button"
            onClick={handleAddClick}
            className="mb-2 mr-2 rounded-lg bg-green-500 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {t('expenses.add')}
          </button>
        </div>
      )}

      {isModalOpen && (
        <ExpensesTableForm
          initialValues={editExpense || undefined}
          onSubmit={handleSaveExpense}
          handleClose={closeModal}
          readOnly={readonly}
        />
      )}
    </div>
  );
};

export default ExpensesTable;
