import { InterfaceType } from '@/enums/InterfaceType';
import { RiskType } from '@/enums/RiskType';
import api from '@/utils/api';
import { RiskUrl } from '@/variables/Urls';

const apiEndpoint = RiskUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface Risk {
  id?: string;
  riskDescription: string;
  riskCategory: InterfaceType;
  riskType: RiskType;
  preMitigationProbability: number;
  preMitigationImpact: number;
  preMitigationRiskScore: number;
  preMitigationRiskMatrix: string;
  mitigationMeasures: string;
  postMitigationProbability: number;
  postMitigationImpact: number;
  postMitigationRiskScore: number;
  postMitigationRiskMatrix: string;
  responsiblePersonId: string;
  responsiblePersonFullName?: string;
  currentState: string;
  identificationDate: Date;
  reevaluationDate: Date;
  comments?: string;
  projectId: string;
}

export interface RiskResponse {
  risks: Risk[];
  count: number;
}

export async function saveRisk(risk: Risk) {
  if (risk.id) {
    const body = { ...risk };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', risk);
  return response;
}

export async function getRisk(id: string) {
  const { data } = await api.get<Risk>(apiEndpoint + '/GetById', {
    params: { id },
  });

  return data;
}

export async function getRisksByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<RiskResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export function deleteRisk(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
