import React, { useEffect, useRef, useState } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FreeDaysModel } from '@/services/WorkCalendarsService';
import { DaysCalendarColorHex } from '@/variables/const';

export interface IDatePickerProps {
  label?: string;
  errors?: string;
  name: string;
  onChange?: (date: Date) => void;
  options?: DatePickerOptions;
  touched?: any;
  value: Date | null;
  defaultDate?: Date;
  id: string;
  disabled?: boolean;
  containerClass?: string;
  labelDir?: 'inLine' | 'Above';
  freeDays?: FreeDaysModel;
}

interface DatePickerOptions {
  title?: string;
  autoHide?: boolean;
  todayBtn?: boolean;
  clearBtn?: boolean;
  maxDate?: Date;
  minDate?: Date;
  theme?: {
    background?: string;
    todayBtn?: string;
    clearBtn?: string;
    icons?: string;
    text?: string;
    disabledText?: string;
    input?: string;
    inputIcon?: string;
    selected?: string;
  };
  icons?: {
    prev?: () => React.ReactElement;
    next?: () => React.ReactElement;
  };
  datepickerClassNames?: string;
  defaultDate?: string | Date;
  language?: string;
  labelClassName?: string;
  inputContainerClassName?: string;
}
const userTimeZone = moment.tz.guess();
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DatePickerDefault = ({
  freeDays,
  id,
  name,
  label,
  errors,
  options,
  onChange,
  value,
  disabled,
  labelDir = 'Above',
  containerClass,
  labelClassName,
  inputContainerClassName,
}: IDatePickerProps) => {
  const { t } = useTranslation();
  const [showDate, setShowDate] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (selectedDate: Date) => {
    onChange(selectedDate);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setShowDate(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const formatDate = (date: Date | null) => {
    return date
      ? moment(date, 'ddd MMM DD YYYY HH:mm:ss')
          .tz(userTimeZone)
          .format('DD-MM-YYYY')
      : '';
  };

  const inputClass = classNames(
    'block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
    {
      'text-red-700 dark:text-red-500 bg-red-300 dark:bg-red-300': errors,
    }
  );

  useEffect(() => {
    if (!showDate || !freeDays) return;

    const elements = document.querySelectorAll('.grid-cols-7 > span'); // Selecting the spans representing dates
    let startIndex = -1;
    let endIndex = -1;

    // Find the index of the first occurrence of the number 1
    elements.forEach((element, index) => {
      const day = parseInt(element?.textContent?.trim() ?? ''); // Ensure to handle potential null values

      if (day === 1 && startIndex === -1) {
        startIndex = index;
      } else if (day === 1 && startIndex !== -1) {
        endIndex = index;
        return; // Exit the loop if we found the second occurrence of 1
      }
    });

    // If endIndex is -1, set it to the end of the elements
    if (endIndex === -1) endIndex = elements.length;

    // Loop through the array from startIndex to endIndex and change the color of weekend days
    for (let i = startIndex; i < endIndex; i++) {
      const element = elements[i];
      element.style.color = DaysCalendarColorHex.default;
      const day = parseInt(element?.textContent?.trim() ?? '');
      const weekday = new Date(value?.getFullYear(), value?.getMonth(), day);

      const formattedDate = weekday.toISOString().split('T')[0]; // Get the date part (YYYY-MM-DD)

      // Format each date in freeDays.weekendDates to match the format of formattedDate
      const formattedWeekendDates = freeDays.weekendDates.map((dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      });
      const formattedHolidayDates = freeDays.holidayDates.map((dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      });

      const formattedClosinDates = freeDays.closingDates.map((dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      });
      // Check if the formatted date is in the formattedWeekendDates array
      if (formattedWeekendDates.includes(formattedDate)) {
        // 0 is Sunday, 6 is Saturday
        element.style.color = DaysCalendarColorHex.weekend; // Change color as needed for weekend days
      }
      if (formattedHolidayDates.includes(formattedDate)) {
        // 0 is Sunday, 6 is Saturday
        element.style.color = DaysCalendarColorHex.holiday; // Change color as needed for weekend days
      }

      if (formattedClosinDates.includes(formattedDate)) {
        // 0 is Sunday, 6 is Saturday
        element.style.color = DaysCalendarColorHex.enterpriseClosing; // Change color as needed for weekend days
      }
    }
  }, [showDate, freeDays, value]);

  return (
    <div
      id="datePicker"
      className={classNames(
        containerClass ?? '',
        labelDir == 'Above'
          ? 'relative'
          : ' relative inline-flex w-full items-baseline  gap-4 '
      )}
      ref={datePickerRef}
    >
      <label
        htmlFor={id}
        className={classNames(
          labelClassName,
          `
        mb-2 block w-[30%] text-sm font-medium text-gray-900 dark:text-white
        ${errors ? 'text-red-700 dark:text-red-500' : ''}
      `
        )}
      >
        {label}
      </label>

      <Datepicker
        disabled={disabled}
        options={options}
        onChange={handleDateChange}
        show={disabled ? false : showDate}
        setShow={setShowDate}
      >
        <div
          className={
            'relative col-span-2 grid w-full py-2' + inputContainerClassName ??
            ''
          }
        >
          {/* Custom input */}
          <input
            type="text"
            className={inputClass}
            value={formatDate(value)}
            onFocus={() => setShowDate(true)}
            readOnly
          />
        </div>
      </Datepicker>

      {errors && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors)}
        </p>
      )}
    </div>
  );
};

export default DatePickerDefault;
