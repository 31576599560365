import React, { useEffect, useState } from 'react';
import '@/pages/Company/Modal.css';
import { useTranslation } from 'react-i18next';
import {
  UserRoles,
  UserRolesEnum,
  nonAdminUserRole,
  userRolesArray,
} from '@/enums/UsersRole';
import Dropdown from '@/components/DropDown';
import {
  EmployeesResponisibiltiesResponse,
  getResponsibility,
} from '@/services/UserService';
import { getJwt } from '@/services/AuthService';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import { UserResponsibility } from '@/enums/UserResponsibility';

interface EmployeeRoleChangerModalProps {
  onChangeRole: any;
  onCancel: any;
  isOpen: boolean;
  textContent?: string;
  role: UserRoles;
  setSelectedRole: any;
  userId: string;
}

const EmployeeRoleChangerModal: React.FC<EmployeeRoleChangerModalProps> = ({
  onChangeRole,
  onCancel,
  isOpen,
  textContent,
  role,
  setSelectedRole,
  userId,
}) => {
  const [modalClass, setModalClass] = useState('modal-hide');
  const [responsiblesArray, setResponsiblesArray] = useState<
    { id: string; label: any }[]
  >([]);

  const { t } = useTranslation();

  useEffect(() => {
    setModalClass((prev) => (prev ? 'modal-show' : 'modal-hide'));
  }, [isOpen]);

  const {
    isLoading,
    isError,
    data: employeesResponsibility,
  } = useQuery<any>(
    ['employeeResponsibilties', userId],
    () => {
      return getResponsibility();
    },
    {
      onSuccess: (data) => {
        if (data) {
          let dataRoles = data as UserResponsibility[];

          const userRolesArray: {
            id: string;
            label: string;
          }[] = dataRoles
            .map((role, index) => ({
              id: role.toString(),
              label: t(`AccessRight.${UserResponsibility[role]}`) as string,
            }))
            .filter((x) => !x.label.includes('undefined'));

          setResponsiblesArray(userRolesArray);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  return isLoading ? (
    <Spinner />
  ) : (
    <div
      id="popup-modal"
      tabIndex={-1}
      className={`fixed left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-800 bg-opacity-75 ${modalClass}`}
    >
      <div className="w-80 rounded-lg bg-white p-6 shadow-md dark:bg-gray-700">
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {textContent ? t(textContent) : t('DeleteModal.confirmDelete')}
          </h3>

          <div className="group relative z-0 mb-6 w-full">
            <Dropdown
              id="userRole"
              value={role ?? ''}
              onChange={(e) => {
                setSelectedRole(e.target.value);
                return;
              }}
              options={responsiblesArray ?? []}
              label={''}
              optionLabel="label"
            />
          </div>
          <button
            onClick={onChangeRole}
            type="button"
            className="mr-2 inline-flex items-center rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-800"
          >
            {t('DeleteModal.yes')}
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
          >
            {t('DeleteModal.no')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeRoleChangerModal;
