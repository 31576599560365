import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LanguageSkillModel } from '@/services/JobDescriptionService';
import { useTranslation } from 'react-i18next';
import DefaultInput from '@/components/Input';
import Checkbox from '@/components/Checkbox';
import { LabelButton } from '@/components/shared/Button';
import Dropdown from '@/components/DropDown';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  addLanguageSkill,
  updateLanguageSkill,
} from '@/state/slices/jobdescriptionSlice';
import { getLanguagesOption } from '@/utils/Converter';

interface LanguageSkillsFormProps {
  initialValues?: LanguageSkillModel;
  onSubmit: (values: LanguageSkillModel) => void;
  handleClose: (e: any) => void;
  edit?: boolean;
}

const LanguageSkillsForm: React.FC<LanguageSkillsFormProps> = ({
  initialValues,
  onSubmit,
  handleClose,
  edit = false,
}) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const validationSchema = Yup.object().shape({
    language: Yup.string().required(t('languageSkills.errors.required')),
    // Add validation rules for other fields if needed
  });

  const handleSubmit = (values: LanguageSkillModel) => {
    if (!!initialValues) {
      dispatch(updateLanguageSkill({ languageSkill: values }));
    } else {
      console.warn(
        '🚀 ~ file: LanguageSkillForm.tsx:41 ~ handleSubmit ~ values:',
        values
      );

      dispatch(addLanguageSkill({ languageSkill: values }));
    }
    handleClose();
  };

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white bg-opacity-20  dark:bg-gray-700 dark:bg-opacity-20">
      <div className="mt-20 max-h-full w-96 overflow-y-auto rounded bg-white p-6 shadow-md dark:bg-gray-700">
        <div className="mb-4 text-2xl font-semibold">
          {!!initialValues
            ? t('languageSkills.title.edit')
            : t('languageSkills.title.add')}
        </div>

        <Formik
          initialValues={
            initialValues || {
              language: '',
              canSpeak: false,
              canRead: false,
              canWrite: false,
              hasTest: false,
              testScore: '',
            }
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isValid }) => (
            <Form>
              <div className="mb-4">
                {/* <DefaultInput
                  id="language"
                  type="text"
                  name="language"
                  label={t('languageSkills.placeholders.language')}
                  onChange={handleChange}
                  value={values.language}
                  errors={errors.language}
                /> */}

                <Dropdown
                  id="language"
                  name="language"
                  label={t('languageSkills.placeholders.language')}
                  onChange={handleChange}
                  value={values.language}
                  errors={errors.language}
                  optionLabel="name"
                  optionValue="code"
                  isTranslated={true}
                  optionTransalatedKey="code"
                  translatedNameSpace={'LanguageNames'}
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  options={getLanguagesOption() ?? []}
                />
              </div>
              <div className="mb-4">
                <Checkbox
                  id="canSpeak"
                  name="canSpeak"
                  checked={values.canSpeak}
                  onChange={handleChange}
                  errors={errors.canSpeak}
                  label={t('languageSkills.canSpeak')}
                />
              </div>
              <div className="mb-4">
                <Checkbox
                  id="canRead"
                  name="canRead"
                  checked={values.canRead}
                  onChange={handleChange}
                  errors={errors.canRead}
                  label={t('languageSkills.canRead')}
                />
              </div>
              <div className="mb-4">
                <Checkbox
                  id="canWrite"
                  name="canWrite"
                  checked={values.canWrite}
                  onChange={handleChange}
                  errors={errors.canWrite}
                  label={t('languageSkills.canWrite')}
                />
              </div>
              <div className="mb-4">
                <Checkbox
                  id="hasTest"
                  name="hasTest"
                  checked={values.hasTest}
                  onChange={handleChange}
                  errors={errors.hasTest}
                  label={t('languageSkills.hasTest')}
                />
              </div>
              <div className="mb-4">
                <DefaultInput
                  id="testScore"
                  type="text"
                  name="testScore"
                  label={t('languageSkills.placeholders.testScore')}
                  onChange={handleChange}
                  value={values.testScore}
                  errors={errors.testScore}
                />
              </div>
              <div className="flex items-center justify-between">
                <LabelButton type="button" onClick={handleClose}>
                  {t('languageSkills.buttons.cancel')}
                </LabelButton>
                <button
                  type="button"
                  onClick={(e) => handleSubmit(values)}
                  disabled={!isValid}
                  className="mb-2 mr-2 rounded-lg border border-primary-700 px-5 py-2.5 text-center text-sm font-medium text-primary-700 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300 dark:border-primary-500 dark:text-primary-500 dark:hover:bg-primary-600 dark:hover:text-white dark:focus:ring-primary-800 "
                >
                  {t('languageSkills.buttons.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LanguageSkillsForm;
