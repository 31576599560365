import Accordion from '@/components/Accordian';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Spinner from '@/components/Spinner';
import DefaultLayout from '@/layout/DefaultLayout';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import PieChart from './components/PieChart';
import { useTranslation } from 'react-i18next';
import MedicalAppointments from '../MedicalAppointment';
import {
  AppointmentRapports,
  getAppointmentRapports,
} from '@/services/RapportsServices';
import { useQuery } from '@tanstack/react-query';
import { ChartWrapperOptions } from 'react-google-charts';
import moment from 'moment-timezone';
import { AppointmentState } from '@/enums/AppointmentState';
import { stateColors } from '@/enums/RequestLeaveState';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import { UserRoles } from '@/enums/UsersRole';
import { useAuth } from '@/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/Pagination';
import {
  AppointmentsResponse,
  getAppointments,
} from '@/services/AppointmentService';
import { UserResponsibility } from '@/enums/UserResponsibility';
import { getYearOptions } from '@/variables/const';
import Dropdown, { Option } from '@/components/DropDown';

const options: ChartWrapperOptions['options'] = {
  title: 'My Daily Activities',
  width: 450,
  height: 400,
  is3D: true,
  // legend: {
  //   position: 'bottom',
  //   textStyle: {
  //     color: '#000000', // Changing legend text color
  //   },
  // },
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1500,
  },
  backgroundColor: 'transparent',
  // titleTextStyle: {
  //   color: '#000000', // Changing title text color
  // },
};

const userTimeZone = moment.tz.guess();
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
function AppointmentsIndicators() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<Option>();
  const [yearOptions, setYearOptions] = useState(getYearOptions());
  const { t } = useTranslation();
  const {
    isLoading: isRapportsLaoding,
    isError: isRapportError,
    data: rapports,
  } = useQuery<AppointmentRapports | undefined>(
    ['appointementsRapport', selectedYear?.id],
    () => {
      return getAppointmentRapports({ year: selectedYear?.id });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  const navigate = useNavigate();
  const { user } = useAuth();
  const isMedicalVisiteAdmin =
    user?.responsibilityClaim ===
    UserResponsibility[UserResponsibility.GestionDeVisiteMedicale].toString();
  const ActionButtonsCell = ({ value, state, appointment }: any) => {
    return isMedicalVisiteAdmin && state == AppointmentState.Pending ? (
      <div></div>
    ) : (
      <div>
        <PrimaryButton
          type="button"
          onClick={() => {
            navigate(
              `/gestion-resources-humains/gestion-de-visite-medicale/${value}`
            );
          }}
        >
          {t('Appointments.Details')}
        </PrimaryButton>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Appointments.employeeName'),
        accessor: 'employeeName',
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value, row } }: any) => (
          <input type="text" className="min-w-40" value={value} readOnly />
        ),
      },
      {
        Header: t('Appointments.department'),
        accessor: 'departmentName',
      },
      {
        Header: t('Appointments.branch'),
        accessor: 'branchName',
      },
      {
        Header: t('Appointments.teamLeaderfullName'),
        accessor: 'teamLeaderName',
        Cell: ({ cell: { value, row } }: any) => (
          <input type="text" className="min-w-50" value={value} readOnly />
        ),
      },

      {
        Header: t('Appointments.dateEnvoiConvocation'),
        accessor: 'dateEnvoiConvocation',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('YYYY-MM-DD'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('Appointments.convocationValideParSalarie'),
        accessor: 'appointmentState',
        Cell: ({ cell: { value } }) => {
          const frontendState = AppointmentState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`min-w-50 w-40 rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: t('Appointments.visiteMedicaleDone'),
        accessor: 'visiteMedicaleDone',
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value, row } }: any) => (
          <input
            type="checkbox"
            className="min-w-50 "
            checked={value}
            readOnly
          />
        ),
      },
      {
        Header: t('Appointments.rendezvousDateTime'),
        accessor: 'rendezvousDateTime',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('YYYY-MM-DD hh:mm'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('Appointments.Apte'),
        accessor: 'apte',
        Filter: SelectColumnFilter,

        Cell: ({ cell: { value, row } }: any) => (
          <input type="checkbox" checked={value} readOnly />
        ),
      },
      {
        Header: t('Appointments.comment'),
        accessor: 'comment',
      },
      {
        Header: t('Appointments.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => {
          return (
            <ActionButtonsCell
              value={value}
              state={row.original['appointmentState']}
              appointment={row.original}
            />
          );
        },
      },
    ],
    [t]
  );

  const {
    isLoading,
    isError,
    data: appointments,
  } = useQuery<AppointmentsResponse | undefined>(
    ['appointments', pageIndex, pageSize, search],
    () =>
      getAppointments({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),

    {
      refetchOnWindowFocus: false,
      staleTime: 3000,
      keepPreviousData: true,
    }
  );

  const handleYearChange = (event: any) => {
    const selectedYearId = event.target.value;

    const selectedYearObj = yearOptions.find((tem) => tem.id == selectedYearId);

    // Update the inputRef value
    setSelectedYear(selectedYearObj);
  };
  const pageCount = useMemo(() => {
    return Math.ceil((appointments?.count ?? 0) / pageSize);
  }, [pageSize, appointments]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('AppintmentIndicatorsPage.breadcrumb')} />
      <Accordion title={t('Appointments.pageName')}>
        <div className="flex flex-col">
          <DynamicTable
            dataCount={appointments?.count ?? 0}
            data={appointments?.appointments ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      </Accordion>
      <Accordion title={t('AppintmentIndicatorsPage.indicators')}>
        {isRapportsLaoding ? (
          <Spinner />
        ) : (
          <div>
            <Dropdown
              label={t('Year')}
              optionValue="id"
              optionLabel="name"
              options={yearOptions}
              labelClassName="w-[50%]"
              containerClass="w-[20%] mx-auto my-5"
              value={selectedYear?.id ?? ''}
              onChange={(e) => handleYearChange(e)}
            />
            <div className="grid gap-2 md:grid-cols-2">
              <PieChart
                data={rapports?.appointmentDoneStateData ?? []}
                options={options}
                title={t('AppintmentIndicatorsPage.AppointmentState')}
              />

              <div>
                <PieChart
                  data={rapports?.apteStateData ?? []}
                  options={options}
                  title={t('AppintmentIndicatorsPage.ApteState')}
                />
              </div>
            </div>
          </div>
        )}
      </Accordion>
    </DefaultLayout>
  );
}

export default AppointmentsIndicators;
