import api from '@/utils/api';
import { JournalEntryUrl } from '@/variables/Urls';

const apiEndpoint = JournalEntryUrl;

export interface JournalEntry {
  id?: string;
  authorId: string;
  authorName: string;
  subject: string;
  type: JournalEntryType;
  dateAdded: Date;
  comments?: string;
  projectId: string;
  projectTitle: string;
}

export interface JournalEntryResponse {
  journalEntries: JournalEntry[];
  count: number;
}

export enum JournalEntryType {
  // Define your enum values if not already defined in enums or constants
  Type1 = 'Type1',
  Type2 = 'Type2',
}

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export async function getJournalEntriesByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<JournalEntryResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export async function saveJournalEntry(journalEntry: JournalEntry) {
  if (journalEntry.id) {
    const response = await api.put(apiEndpoint + '/put', journalEntry);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', journalEntry);
  return response;
}

export async function getJournalEntry(id: string) {
  const { data } = await api.get<JournalEntry>(apiEndpoint + '/GetById', {
    params: { id },
  });

  return data;
}

export function deleteJournalEntry(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
