import { EquipmentUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { EquipmentCategory } from '@/enums/EquipmentCategory';
import { EquipmentStatus } from '@/enums/EquipmentStatus';
import { Currency } from '@/enums/Currency';

export interface Equipment {
  id?: string;
  equipmentName: string;
  type: string;
  category: EquipmentCategory;
  brand: string;
  model: string;
  weightInTons: number;
  power: number;
  serialNumber: string;
  location: string;
  onSite: boolean;
  availableDate: Date;
  purchaseDate: Date;
  cost: number;
  lastMaintenanceDate: Date;
  nextMaintenanceDate: Date;
  status: EquipmentStatus;
  locationDetails: string;
  responsiblePerson: string;
  companyId?: string;
  currency?: Currency;
}

export interface EquipmentResponse {
  equipments: Equipment[];
  count: number;
}

const apiEndpoint = EquipmentUrl;

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  category?: EquipmentCategory;
}

export async function getEquipments({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  category,
}: PaginatingParams) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
      category: category,
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as EquipmentResponse;
}

export async function getEquipment(equipmentId: string) {
  const { data } = await api.get<Equipment>(apiEndpoint + '/GetById', {
    params: { id: equipmentId },
  });

  return data;
}

export async function saveEquipment(equipment: Equipment) {
  if (equipment.id) {
    const body = { ...equipment };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', equipment);
  return response_1;
}

export function deleteEquipment(equipmentId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: equipmentId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
