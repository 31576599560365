import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { ModuleType } from '@/models/ModuleTypeEnum';

interface SideBarMenuItemProps {
  to: string;
  pathname?: string;
  title: string;
  isMenu?: boolean;
  IconSvg?: any;
  moduleType?: string | number;
  isDisabled?: boolean;
  userIsAllowed?: boolean;
}

const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
  to,
  title,
  isMenu,
  IconSvg,
  moduleType,
  isDisabled,
  userIsAllowed,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  return (
    <NavLink
      to={to}
      className={`group relative flex items-center 
        gap-2.5 rounded-sm px-4 py-2 font-medium 
        text-bodydark1 duration-300 ease-in-out
        hover:bg-fuchsia-500 dark:hover:bg-fuchsia-500 ${
          pathname === to && 'bg-fuchsia-500 dark:bg-fuchsia-500'
        }
        ${isDisabled ? 'pointer-events-none opacity-60' : ''}
      `}
    >
      {IconSvg && !isDisabled && (
        <img src={IconSvg} height={30} width={30} alt="" />
      )}

      {t(
        `sidebar.${ModuleType[moduleType]?.toString().toLowerCase() ?? title}`
      )}

      {isDisabled && <LockClosedIcon className="h-4 w-4 text-white" />}
    </NavLink>
  );
};

export default SideBarMenuItem;
