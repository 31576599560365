import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  ProjectAOStatusModel,
  getProjectAOStateByProjectId,
  saveProjectAOCase,
} from '@/services/ProjectAOStateService';
import {
  ProjectAOStatus,
  ProjectAOStatusOptions,
} from '@/enums/ProjectAOStatus';
import Dropdown from '@/components/DropDown';

const validationSchema = Yup.object().shape({
  description: Yup.string().required('ProjectAOCaseForm.descriptionRequired'),
  status: Yup.string().required('ProjectAOCaseForm.statusRequired'),
  projectId: Yup.string().required('ProjectAOCaseForm.projectIdRequired'),
});

interface FormValues extends ProjectAOStatusModel {}

function ProjectAOCaseForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    id: '',
    description: '',
    status: '',
    comments: '',
    reasonForLoss: '',
    projectId: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: projectAOStatusData,
    isLoading,
    refetch: refetchProjectAOStatus,
  } = useQuery<ProjectAOStatusModel | undefined>(
    ['projectAOStatus', id],
    async () => await getProjectAOStateByProjectId(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (projectAOStatus) => {
        if (projectAOStatus) {
          setFormikValuesState((prev) => ({ ...prev, ...projectAOStatus }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && projectAOStatusData) {
      setFormikValuesState((prev) => ({ ...prev, ...projectAOStatusData }));
    }
  }, [editForm, projectAOStatusData]);

  const handleSaveProjectAOStatus = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const projectAOStatus: ProjectAOStatus = {
        ...values,
        projectId: id!,
      };

      const data = await saveProjectAOCase(projectAOStatus);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.ProjectAOStatus'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.ProjectAOStatus'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchProjectAOStatus();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('ProjectAOCaseForm.editProjectAOCase')
            : t('ProjectAOCaseForm.addProjectAOCase')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveProjectAOStatus}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="description"
                  id="description"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.description}
                  label={t('ProjectAOCaseForm.descriptionLabel')}
                />
              </div>

              <div className="group relative z-0 mb-6 w-full">
                <Dropdown
                  name="status"
                  id="status"
                  options={ProjectAOStatusOptions}
                  onChange={handleChange}
                  optionTransalatedKey="name"
                  isTranslated={true}
                  translatedNameSpace="ProjectAOStatus"
                  errors={errors}
                  value={values.status}
                  label={t('ProjectAOCaseForm.statusLabel')}
                />
              </div>

              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="comments"
                  id="comments"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.comments}
                  label={t('ProjectAOCaseForm.commentsLabel')}
                />
              </div>

              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="reasonForLoss"
                  id="reasonForLoss"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.reasonForLoss}
                  label={t('ProjectAOCaseForm.reasonForLossLabel')}
                />
              </div>

              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('ProjectAOCaseForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('ProjectAOCaseForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default ProjectAOCaseForm;
