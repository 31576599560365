import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultInput from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { getCompanyId } from '@/services/AuthService';
// Update the path to the actual service
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import MutiDatePickerRange from '@/components/MultiDatePickerRange';
import Checkbox from '@/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import {
  Vacation,
  getVacation,
  saveVacation,
} from '@/services/VacationService';
import { VacationTypeEnum } from '@/enums/VacationTypeEnum';

// Translations for Yup validation
const validationMessages = {
  nameRequired: 'Vacation.nameIsRequired',
  totalDaysRequired: 'Vacation.totalDaysIsRequired',
  // Add more validation messages as needed
};

interface FormValues extends Vacation {
  // Define your FormValues interface here
}

function VacationForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const editForm = id !== 'new';
  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    name: '',
    totalDays: 0,
    year: 0,
    expirationYears: 0,
  });
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(validationMessages.nameRequired),
    totalDays: Yup.number().required(validationMessages.totalDaysRequired),
  });

  const {
    data: vacationData,
    refetch: refetchVacation,
    isLoading,
    isSuccess,
  } = useQuery<Vacation | undefined>(
    ['vacation', id],
    async () => await getVacation(id!), // Update this to your actual getVacation function
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 2000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (editForm && isSuccess) {
      setFormikValuesState({
        ...formikValuesState,
        ...vacationData,
      });
    }
  }, [isSuccess, vacationData]);

  // const {
  //   isLoading,
  //   isError,
  //   data: calendarTypes,
  // } = useQuery<CalendarTypesResponse | undefined>(
  //   ['calendarTypes'],
  //   () =>
  //     getCalendarTypes({
  //       fromvalue: 0,
  //       takevalue: 0,
  //       search: '',
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     staleTime: 6000,
  //     keepPreviousData: true,
  //   }
  // );

  const handleSaveVacation = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);
    try {
      const companyId = getCompanyId();
      const vacation: Vacation = {
        ...values,
        companyId: companyId! as string,
      };

      const vacationId = await saveVacation(vacation);

      if (editForm) {
        refetchVacation();
      }
      toast.success(t('Vacation.saveSuccessMessage'));
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm ? t('Vacation.editVacation') : t('Vacation.addVacation')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveVacation}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.name}
                    label={t('Vacation.name')}
                  />
                </div>

                {values.isNotLimited === false && (
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="number"
                      name="totalDays"
                      id="totalDays"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.totalDays}
                      label={t('Vacation.totalDays')}
                      labelClassName="w-[40%]"
                    />
                  </div>
                )}
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                  disabled={loading}
                >
                  {t('Vacation.submit')}
                </button>
                <button
                  type="button"
                  id="cancelForm"
                  className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                  onClick={() => {
                    resetForm();
                    navigate(-1);
                  }}
                >
                  {t('Vacation.cancel')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default VacationForm;
