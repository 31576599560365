import { LockClosedIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Step {
  stepNumber: number;
  title: string;
  description: string;
}

interface StepperProps {
  steps: Step[];
  currentStep: number;
  isDisabled: boolean;
  onStepChange: (index: number) => void;
}

const SubProjectStepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
  onStepChange,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
        <ul className="-mb-px flex flex-wrap">
          {steps.map((step, index) => (
            <li key={index} className="mr-2 ">
              <a
                href="#"
                className={`flex gap-2 rounded-t-lg border-b-2 border-transparent 
                  ${
                    isDisabled &&
                    index != 0 &&
                    'pointer-events-none text-purple-500'
                  }
                  
                  p-4 ${
                    step.stepNumber === currentStep
                      ? 'border-primary-600 text-primary hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300'
                      : 'hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                onClick={() => onStepChange(step.stepNumber)}
                aria-disabled={isDisabled && index != 0}
              >
                {t(step.title)}

                {isDisabled && index !== 0 && (
                  <LockClosedIcon className="h-4 w-4 text-purple-400" />
                )}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubProjectStepper;
