export enum TaskStatus {
  Planned,
  InProgress,
  Completed,
  Cancelled,
}

export const TaskStatusOptions = Object.keys(TaskStatus)
  .filter((key) => isNaN(Number(TaskStatus[key])))
  .map((key) => ({
    id: key,
    name: TaskStatus[key],
  }));
