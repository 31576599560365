import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Input from '@/components/Input';
import TextArea from '@/components/TextArea';
import { VacationTypeEnum } from '@/enums/VacationTypeEnum';
import { CompanyRHDataModel } from '@/models/CompanyRHDataModel';
import BarChart from '@/pages/Analytics/components/BarChart';
import {
  EmployeeDataModel,
  GraphDataItem,
  getCompanyRHRapports,
} from '@/services/RapportsServices';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChartWrapperOptions } from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import SeniorityTable from './SeniorityTable';
import AgesTable from './AgesTable';
import InterviewStatusTable from './InterviewStatusTable';
import EmployeeTurnOverDataTable from './EmployeeTurnOverDataTable';
import EmployeeContractDataTable from './EmployeeContractDataTable';
import TrainingByTempDataTable from './TrainingByTempDataTable';
import SickLeaveDataTable from './SickLeaveDataTable';
import TrainingDataTable from './TrainingDataTable';
import AbsenteeismRateDataTable from './AbsenteeismRateDataTable';
import Spinner from '@/components/Spinner';
import { useAuth } from '@/context/AuthContext';
import { EmployeeGradeType } from '@/enums/EmployeeGradeType';
import MultiLineChart from '@/pages/Analytics/components/MultiLineChart';
import { GenderEnum } from '@/enums/genderEnum';
import {
  SeniorityRange,
  getEnumNames,
  getTranslatedEnumNames,
} from '@/enums/SeniorityRange';
import { AgeSlice } from '@/enums/AgeSlice';
import StackedBarChart from '@/pages/Analytics/components/StackedBarChart';
import { Branch, getBranches } from '@/services/BranchService';
import { Departement } from '@/services/DepartementService';
import {
  Team,
  TeamsResponse,
  getTeamsByDepartment,
} from '@/services/TeamService';
import Dropdown, { Option } from '@/components/DropDown';
import { PrimaryButton } from '@/components/shared/Button';
import { debounce } from 'lodash';
import ReactDatePicker from 'react-datepicker';
import { getYearOptions } from '@/variables/const';
import { UserResponsibility } from '@/enums/UserResponsibility';
import { UserRoles, UserRolesEnum } from '@/enums/UsersRole';
import RatingTable from './RatingTable';
import ExperienceTable from './ExperienceTable';
import ProjectExpensesDataTable from './ProjectExpensesDataTable';
import PieChart from '@/pages/Analytics/components/PieChart';
import { ExpenseCategory } from '@/enums/ExpenseCategory';
import { Currency } from '@/enums/Currency';
import Accordion from '@/components/Accordian';
import BorderWrapper from '@/components/BorderWrapper';

export interface ICompanyResourceHumainsDataProps {}
function CompanyResourceHumainsData({}: ICompanyResourceHumainsDataProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string>('');
  const { user } = useAuth();
  const [departements, setDepartements] = React.useState<Departement[]>([]);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [selectedDepartment, setSelectedDepartment] = useState<Departement>();
  const [selectedBranch, setSelectedBranch] = useState<Branch>();
  const [selectedYear, setSelectedYear] = useState<Option>();
  const [yearOptions, setYearOptions] = useState(getYearOptions());

  const {
    isLoading,
    data: companyData,
    refetch: refetchCompanyData,
  } = useQuery<CompanyRHDataModel>(
    [
      'companyData',
      user?.id,
      selectedBranch?.id,
      selectedDepartment?.id,
      selectedTeam?.id,
      selectedYear,
    ],
    () =>
      // Fetch all employees
      getCompanyRHRapports({
        departmentId: selectedDepartment?.id,
        branchId: selectedBranch?.id,
        teamId: selectedTeam?.id,
        year: selectedYear?.id,
        teamLeaderId: user?.isTeamLeader == 'True' ? user?.id : undefined,
      }),

    {
      refetchOnWindowFocus: false,
      staleTime: 3000,
      keepPreviousData: true,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: t('Equipments.typeColumn'),
        accessor: 'vacationName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('vacationBalances.VacationTotal'),
        accessor: 'vacationTotal',
      },
      {
        Header: t('vacationBalances.TokedDays'),
        accessor: 'tokedDays',
        // Cell: ({ row }) => {
        //   const totalDays = row.original.TotalDays || 0;
        //   const remainingDays = row.original.RemainingDays || 0;
        //   const tokedDays = totalDays - remainingDays;
        //   return <span>{tokedDays}</span>;
        // },
      },
      {
        Header: t('vacationBalances.RemainingDays'),
        accessor: 'remainingDays',
      },
    ],
    [t]
  );
  const columnsEmpargn = useMemo(
    () => [
      {
        Header: t('Equipments.typeColumn'),
        accessor: 'vacationName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('vacationBalances.VacationTotal'),
        accessor: 'vacationTotal',
      },
      {
        Header: t('vacationBalances.TokedDays'),
        accessor: 'tokedDays',
        // Cell: ({ row }) => {
        //   // const totalDays = row.original.TotalDays || 0;
        //   // const remainingDays = row.original.RemainingDays || 0;
        //   // const tokedDays = totalDays - remainingDays;
        //   return <span>{tokedDays}</span>;
        // },
      },
      {
        Header: t('vacationBalances.RemainingDays'),
        accessor: 'remainingDays',
      },
    ],
    [t]
  );

  const { data: branchesData, isSuccess: isBranchSuccess } = useQuery(
    ['branches'],
    () => getBranches({ fromvalue: 0, takevalue: 0 }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (branchesData) {
      setBranches(branchesData?.branches);
    }
  }, [isBranchSuccess]);
  const handleDepartementChange = (event: any) => {
    const selectedDepartmentId = event.target.value;
    const selectedDepartemntObj = departements.find(
      (dep) => dep.id === selectedDepartmentId
    );

    setSelectedDepartment(selectedDepartemntObj);
    refetchTeam();
  };
  const handleTeamChange = (event: any) => {
    const selectedTeamId = event.target.value;
    const selectedTeamObj = teams.find((tem) => tem.id === selectedTeamId);

    setSelectedTeam(selectedTeamObj);
    refetchCompanyData();
  };
  const handleBranchChange = (event: any) => {
    const selectedBranchId = event.target.value;

    const selectedBranchObj = branchesData?.branches.find(
      (branch) => branch.id === selectedBranchId
    );

    if (selectedBranchObj) {
      setDepartements(selectedBranchObj.departments! as Departement[]);
    } else {
      setDepartements([]);
    }

    setSelectedBranch(selectedBranchObj);
  };

  const handleYearChange = (event: any) => {
    const selectedYearId = event.target.value;

    const selectedYearObj = yearOptions.find((tem) => tem.id == selectedYearId);

    // Update the inputRef value
    setSelectedYear(selectedYearObj);
  };

  const {
    isSuccess: isTeamSuccess,
    data: teamsData,
    refetch: refetchTeam,
  } = useQuery<TeamsResponse | undefined>(
    ['teams', selectedDepartment],
    () =>
      selectedDepartment
        ? getTeamsByDepartment({
            fromvalue: 0,
            takevalue: 0,
            departmentId: selectedDepartment.id,
          })
        : undefined,
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (teamsData) {
      setTeams(teamsData.teams);
    }
  }, [isTeamSuccess, teamsData]);

  const CpVacations = companyData?.vacationBalanceData?.filter(
    (x) => x.vacationName === VacationTypeEnum.CP
  )[0];

  const RttVacations = companyData?.vacationBalanceData?.filter(
    (x) => x.vacationName == VacationTypeEnum.RTT
  )[0];
  const TtVacations = companyData?.vacationBalanceData?.filter(
    (x) => x.vacationName == VacationTypeEnum.TT
  )[0];
  const EmargnRTTVacations = companyData?.emparnVacationBalance?.filter(
    (x) => x.vacationName == VacationTypeEnum.CET_RTT
  )[0];
  const EmargnCpVacations = companyData?.emparnVacationBalance?.filter(
    (x) => x.vacationName == VacationTypeEnum.CET_CP
  )[0];
  // const interviewGraphData = useMemo(
  //   () => [
  //     ['Employee Type', 'Done', 'Not Done'],
  //     [
  //       'Cadre',
  //       companyData?.interviewDataModel?.filter(
  //         (x) => x.employeeType === EmployeeGradeType.Cadre
  //       )[0].done ?? 0,
  //       companyData?.interviewDataModel?.filter(
  //         (x) => x.employeeType === EmployeeGradeType.Cadre
  //       )[0].notDone ?? 0,
  //     ],
  //     [
  //       'No Cadre',
  //       companyData?.interviewDataModel?.filter(
  //         (x) => x.employeeType === EmployeeGradeType.NoCadre
  //       )[0].done ?? 0,
  //       companyData?.interviewDataModel?.filter(
  //         (x) => x.employeeType === EmployeeGradeType.NoCadre
  //       )[0].notDone ?? 0,
  //     ],
  //   ],
  //   [companyData, CpVacations, RttVacations, TtVacations]
  // );

  const employeeAgeSlicechartData = useMemo(() => {
    const ageSLiceLabels = getTranslatedEnumNames(AgeSlice, t, 'AgeSlice');

    const maleData = Array(ageSLiceLabels.length).fill(0);
    const femaleData = Array(ageSLiceLabels.length).fill(0);
    const NotAnswerData = Array(ageSLiceLabels.length).fill(0);

    companyData?.seniorityByAgeSliceData?.forEach((entry) => {
      const index = entry.ageSlice;

      if (entry.gender === GenderEnum.Male) {
        maleData[index] = entry.employeeCount;
      } else if (entry.gender === GenderEnum.Female) {
        femaleData[index] = entry.employeeCount;
      } else if (entry.gender === GenderEnum.NoAnswer) {
        NotAnswerData[index] = entry.employeeCount;
      }
    });

    return {
      labels: ageSLiceLabels,
      datasets: [
        {
          label: t('GenderEnum.Male'),
          data: maleData,
          backgroundColor: 'blue',
          borderColor: 'lightblue',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
        {
          label: t('GenderEnum.Female'),
          data: femaleData,
          backgroundColor: 'pink',
          borderColor: 'lightpink',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
        {
          label: t('GenderEnum.NoAnswer'),
          data: NotAnswerData,
          backgroundColor: 'green',
          borderColor: 'lightgreen',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
      ],
    };
  }, [companyData, isLoading]);

  const SeniorityRangechartData = useMemo(() => {
    const seniorityRangeLabels = getTranslatedEnumNames(
      SeniorityRange,
      t,
      'SenoritySlice'
    );

    const maleData = Array(seniorityRangeLabels.length).fill(0);
    const femaleData = Array(seniorityRangeLabels.length).fill(0);
    const NotAnswerData = Array(seniorityRangeLabels.length).fill(0);

    companyData?.seniorityByGenderData?.forEach((entry) => {
      const index = entry.seniorityRange;

      if (entry.gender === GenderEnum.Male) {
        maleData[index] = entry.employeeCount;
      } else if (entry.gender === GenderEnum.Female) {
        femaleData[index] = entry.employeeCount;
      } else if (entry.gender === GenderEnum.NoAnswer) {
        NotAnswerData[index] = entry.employeeCount;
      }
    });

    return {
      labels: seniorityRangeLabels,
      datasets: [
        {
          label: t('GenderEnum.Male'),
          data: maleData,
          backgroundColor: 'blue',
          borderColor: 'lightblue',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
        {
          label: t('GenderEnum.Female'),
          data: femaleData,
          backgroundColor: 'pink',
          borderColor: 'lightpink',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
        {
          label: t('GenderEnum.NoAnswer'),
          data: NotAnswerData,
          backgroundColor: 'green',
          borderColor: 'lightgreen',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
      ],
    };
  }, [companyData, isLoading, t]);
  const ExperienceRangechartData = useMemo(() => {
    const seniorityRangeLabels = getTranslatedEnumNames(
      SeniorityRange,
      t,
      'SenoritySlice'
    );

    const maleData = Array(seniorityRangeLabels.length).fill(0);
    const femaleData = Array(seniorityRangeLabels.length).fill(0);
    const NotAnswerData = Array(seniorityRangeLabels.length).fill(0);

    companyData?.experienceByGenderData?.forEach((entry) => {
      const index = entry.experience;

      if (entry.gender === GenderEnum.Male) {
        maleData[index] = entry.employeeCount;
      } else if (entry.gender === GenderEnum.Female) {
        femaleData[index] = entry.employeeCount;
      } else if (entry.gender === GenderEnum.NoAnswer) {
        NotAnswerData[index] = entry.employeeCount;
      }
    });

    return {
      labels: seniorityRangeLabels,
      datasets: [
        {
          label: t('GenderEnum.Male'),
          data: maleData,
          backgroundColor: 'blue',
          borderColor: 'lightblue',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
        {
          label: t('GenderEnum.Female'),
          data: femaleData,
          backgroundColor: 'pink',
          borderColor: 'lightpink',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
        {
          label: t('GenderEnum.NoAnswer'),
          data: NotAnswerData,
          backgroundColor: 'green',
          borderColor: 'lightgreen',
          fill: false,
          lineTension: 0,
          radius: 5,
        },
      ],
    };
  }, [companyData, isLoading, t]);

  const CompanyVacationChartData = useMemo(() => {
    return {
      labels: ['CP', 'RTT', 'TT'],
      datasets: [
        {
          label: t('vacationBalances.TokedDays'),
          data: [
            CpVacations?.tokedDays?.toString(),
            RttVacations?.tokedDays?.toString(),
            TtVacations?.tokedDays?.toString(),
          ],
          backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red
        },
        {
          label: t('vacationBalances.RemainingDays'),
          data: [
            CpVacations?.remainingDays?.toString(),
            RttVacations?.remainingDays?.toString(),
            TtVacations?.remainingDays?.toString(),
          ],
          backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue
        },
        {
          label: t('vacationBalances.VacationTotal'),
          data: [
            CpVacations?.vacationTotal?.toString(),
            RttVacations?.vacationTotal?.toString(),
            TtVacations?.vacationTotal?.toString(),
          ],
          backgroundColor: 'rgba(245, 111, 245, 0.7)', // Green
        },
      ],
    };
  }, [CpVacations, RttVacations, TtVacations]);

  const CompanyEmargnVacationChartData = useMemo(() => {
    return {
      labels: ['CP', 'RTT'],
      datasets: [
        {
          label: t('vacationBalances.TokedDays'),
          data: [
            EmargnCpVacations?.tokedDays?.toString(),
            EmargnRTTVacations?.tokedDays?.toString(),
          ],
          backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red
        },
        {
          label: t('vacationBalances.RemainingDays'),
          data: [
            EmargnCpVacations?.remainingDays?.toString(),
            EmargnRTTVacations?.remainingDays?.toString(),
          ],
          backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue
        },
        {
          label: 'Total',
          data: [
            EmargnCpVacations?.vacationTotal?.toString(),
            EmargnRTTVacations?.vacationTotal?.toString(),
          ],
          backgroundColor: 'rgba(245, 111, 245, 0.7)', // Green
        },
      ],
    };
  }, [EmargnRTTVacations, EmargnCpVacations]);

  const SicknessLeaveDaysChartData = useMemo(() => {
    return {
      labels: [
        t('SickLeaveTable.numberOfSickLeaveDays'),
        t('SickLeaveTable.totalWorkingDays'),
      ],
      datasets: [
        {
          label: 'Dataset',
          data: [
            companyData?.sickLeaveData?.numberOfSickLeaveDays.toString(),
            companyData?.sickLeaveData?.totalWorkingDays.toString(),
          ],
          backgroundColor: 'rgba(99, 128, 255, 0.7)', // Red
        },
      ],
    };
  }, [companyData?.sickLeaveData]);
  const AbsentismeRateChartData = useMemo(() => {
    return {
      labels: [
        t('AbsenteeismRateTable.numberOfLeaveDays'),
        t('AbsenteeismRateTable.totalWorkingDays'),
      ],
      datasets: [
        {
          label: 'Dataset',
          data: [
            companyData?.absenteeismRateData?.numberOfLeaveDays.toString(),
            companyData?.absenteeismRateData?.totalWorkingDays.toString(),
          ],
          backgroundColor: 'rgba(99, 128, 255, 0.7)', // Red
        },
      ],
    };
  }, [companyData?.absenteeismRateData]);

  const EmployeeContractChartData = useMemo(() => {
    return {
      labels: [
        t('EmployeeContractDataTable.newEmployees'),
        t('EmployeeContractDataTable.existingEmployees'),
      ],
      datasets: [
        {
          label: 'Dataset',
          data: [
            companyData?.employeeContractData?.newEmployees.toString(),
            companyData?.employeeContractData?.existingEmployees.toString(),
          ],
          backgroundColor: 'rgba(99, 128, 255, 0.7)', // Red
        },
      ],
    };
  }, [companyData?.employeeContractData]);
  const EmployeeTurnOverChartData = useMemo(() => {
    return {
      labels: [
        t('EmployeeTurnOverTable.employeesLeftInYear'),
        t('EmployeeTurnOverTable.totalEmployees'),
      ],
      datasets: [
        {
          label: 'Dataset',
          data: [
            companyData?.employeeContractData?.newEmployees.toString(),
            companyData?.employeeContractData?.existingEmployees.toString(),
          ],
          backgroundColor: 'rgba(99, 128, 255, 0.7)', // Red
        },
      ],
    };
  }, [companyData?.employeeContractData]);

  const interviewChartData = useMemo(() => {
    return {
      labels: [t('EmployeeGradeType.Cadre'), t('EmployeeGradeType.NoCadre')],
      datasets: [
        {
          label: t('InterviewStatusTable.done'),
          data: [
            companyData?.interviewsStatusData?.filter(
              (x) => x.employeeType === EmployeeGradeType.Cadre
            )[0].done ?? 0,
            companyData?.interviewsStatusData?.filter(
              (x) => x.employeeType === EmployeeGradeType.NoCadre
            )[0].done ?? 0,
          ],
          backgroundColor: 'rgba(106, 243, 138, 0.7)', // Red
        },
        {
          label: t('InterviewStatusTable.notDone'),
          data: [
            companyData?.interviewsStatusData?.filter(
              (x) => x.employeeType === EmployeeGradeType.Cadre
            )[0].notDone ?? 0,
            companyData?.interviewsStatusData?.filter(
              (x) => x.employeeType === EmployeeGradeType.NoCadre
            )[0].notDone ?? 0,
          ],
          backgroundColor: 'rgba(246, 97, 206, 0.7)', // Blue
        },
      ],
    };
  }, [companyData?.interviewsStatusData]);

  const RatingChartData = useMemo(() => {
    return {
      labels: [
        t('RatingTable.SatisfiedEmployees'),
        t('RatingTable.DissatisfiedEmployees'),
        t('RatingTable.PassiveEmployees'),
      ],
      datasets: [
        {
          label: 'Dataset',
          data: [
            companyData?.ratingData[0].count?.toString(),
            companyData?.ratingData[1].count?.toString(),
            companyData?.ratingData[2].count?.toString(),
          ],
          backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red
        },
      ],
    };
  }, [companyData?.ratingData]);
  const resetFilter = () => {
    setSelectedBranch(undefined);
    setSelectedTeam(undefined);
    setSelectedDepartment(undefined);
    setSelectedYear(undefined);
  };

  const options: ChartWrapperOptions['options'] = {
    title: 'My Daily Activities',
    width: 450,
    height: 400,
    is3D: true,
    // legend: {
    //   position: 'bottom',
    //   textStyle: {
    //     color: '#000000', // Changing legend text color
    //   },
    // },
    animation: {
      startup: true,
      easing: 'inAndOut',
      duration: 1500,
    },
    backgroundColor: 'transparent',
    // titleTextStyle: {
    //   color: '#000000', // Changing title text color
    // },
  };
  const projectExpensegraphData: GraphDataItem[] =
    companyData?.projectExpensesData?.map((item) => ({
      value: item.total,
      label:
        t(`ExpenseCategory.${ExpenseCategory[item.category]}`) +
        ' ' +
        `(${Currency[item.currency]})`,
      color: getRandomColor(),
    })) ?? [];
  function getRandomColor() {
    // Generate random values for red, green, and blue channels
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Convert RGB values to hexadecimal format
    const hexColor = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;

    return hexColor;
  }

  return isLoading && companyData ? (
    <Spinner />
  ) : (
    <div className="flex flex-col gap-2">
      <div className="space-x-auto mb-5 grid grid-cols-2 gap-2 md:grid-cols-5">
        {(UserResponsibility[user?.responsibilityClaim] ==
          UserResponsibility.GestionDesRH.toString() ||
          user?.role === UserRoles.Admin) && (
          <>
            <Dropdown
              optionValue="id"
              optionLabel="title"
              label={t('AbsenceManagement.selectBranch')}
              options={branches}
              labelClassName="w-[70%]"
              value={selectedBranch?.id ?? ''}
              onChange={(e) => handleBranchChange(e)}
            />

            <Dropdown
              label={t('AbsenceManagement.selectDepartment')}
              optionValue="id"
              optionLabel="title"
              options={departements}
              labelClassName="w-[70%]"
              value={selectedDepartment?.id ?? ''}
              onChange={(e) => handleDepartementChange(e)}
            />
            <Dropdown
              label={t('AbsenceManagement.selectTeam')}
              optionValue="id"
              optionLabel="title"
              options={teams}
              labelClassName="w-[70%]"
              value={selectedTeam?.id ?? ''}
              onChange={(e) => handleTeamChange(e)}
            />
          </>
        )}
        <Dropdown
          label={t('Year')}
          optionValue="id"
          optionLabel="name"
          options={yearOptions}
          labelClassName="w-[70%]"
          value={selectedYear?.id ?? ''}
          onChange={(e) => handleYearChange(e)}
        />
        <div className="mt-[27px]">
          <PrimaryButton onClick={resetFilter}>
            {t('AbsenceManagement.reset')}
          </PrimaryButton>
        </div>
      </div>

      <BorderWrapper title={t('ResourceHumains.VacationName')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <DynamicTable
              hideToolbar={true}
              dataCount={3}
              data={companyData?.vacationBalanceData ?? []}
              columns={columns}
              setSearch={setSearch}
              exportPdf={false}
            />
          </div>

          <StackedBarChart data={CompanyVacationChartData} />
        </div>
      </BorderWrapper>
      <BorderWrapper title={t('ResourceHumains.EmaprgnVacationName')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <DynamicTable
              hideToolbar={true}
              dataCount={3}
              data={companyData?.emparnVacationBalance ?? []}
              columns={columnsEmpargn}
              setSearch={setSearch}
              exportPdf={false}
            />
          </div>
          <StackedBarChart data={CompanyEmargnVacationChartData} />
        </div>
      </BorderWrapper>

      <BorderWrapper title={t('sidebar.Training')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <TrainingDataTable data={companyData?.trainingData ?? []} />
          </div>
          <div>
            <TrainingByTempDataTable data={companyData?.trainingByTempData} />
          </div>
        </div>
      </BorderWrapper>
      <BorderWrapper title={t('SickLeaveTable.metric')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <SickLeaveDataTable data={companyData?.sickLeaveData} />
          </div>

          <StackedBarChart data={SicknessLeaveDaysChartData} />
        </div>
      </BorderWrapper>
      <BorderWrapper title={t('AbsenteeismRateTable.metric')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <AbsenteeismRateDataTable data={companyData?.absenteeismRateData} />
          </div>
          <StackedBarChart data={AbsentismeRateChartData} />
        </div>
      </BorderWrapper>

      <BorderWrapper title={t('EmployeeContractDataTable.metric')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <EmployeeContractDataTable
              data={companyData?.employeeContractData}
            />
          </div>

          <StackedBarChart data={EmployeeContractChartData} />
        </div>
      </BorderWrapper>

      <BorderWrapper title={t('EmployeeTurnOverTable.metric')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <EmployeeTurnOverDataTable
              data={companyData?.employeeTurnOverData}
            />
          </div>
          <StackedBarChart data={EmployeeTurnOverChartData} />
        </div>
      </BorderWrapper>

      <BorderWrapper title={t('InterviewStatusTable.employeeType')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <InterviewStatusTable
              data={companyData?.interviewsStatusData ?? []}
            />
          </div>
          <StackedBarChart data={interviewChartData} />
        </div>
      </BorderWrapper>

      <BorderWrapper title={t('RatingTable.employeeMetric')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <RatingTable data={companyData?.ratingData ?? []} />
          </div>
          <StackedBarChart data={RatingChartData} />
        </div>
      </BorderWrapper>

      <BorderWrapper title={t('ProjectExpensesDataTable.metric')}>
        <div className=" grid gap-2 md:grid md:grid-cols-2 ">
          <div>
            <ProjectExpensesDataTable
              data={companyData?.projectExpensesData ?? []}
            />
          </div>
          <PieChart
            data={projectExpensegraphData ?? []}
            options={options}
            title={t('ExpenseCategoryPage.ExpenseCategoryType')}
          />
        </div>
      </BorderWrapper>
      <div className=" grid gap-2 md:grid md:grid-cols-1 ">
        <BorderWrapper title={t('ExperienceTable.metric')}>
          <div className=" grid gap-2 2xl:grid-cols-2 ">
            <ExperienceTable data={companyData?.experienceByGenderData ?? []} />

            <MultiLineChart graphData={ExperienceRangechartData} />
          </div>
        </BorderWrapper>
        <BorderWrapper title={t('SeniorityTable.metric')}>
          <div className=" grid gap-2 2xl:grid-cols-2 ">
            <div>
              <SeniorityTable data={companyData?.seniorityByGenderData ?? []} />
            </div>
            <div>
              <MultiLineChart graphData={SeniorityRangechartData} />
            </div>
          </div>
        </BorderWrapper>
        <BorderWrapper title={t(`AgeSlice.metrics`)}>
          <div className=" grid gap-2  2xl:grid-cols-2">
            <AgesTable data={companyData?.seniorityByAgeSliceData ?? []} />

            <MultiLineChart graphData={employeeAgeSlicechartData} />
          </div>
        </BorderWrapper>
      </div>
    </div>
  );
}

export default CompanyResourceHumainsData;
