import { XIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import DefaultInput from '@/components/Input';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store';
import {
  PersonnelCostsResponse,
  getPersonnelCosts,
} from '@/services/PersonnelCostService';
import Dropdown from '@/components/DropDown';
import TextArea from '@/components/TextArea';
import { useQuery } from '@tanstack/react-query';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Currency } from '@/enums/Currency';
import { getCurrencyExchange } from '@/services/ExchangeRateService';
import moment from 'moment';
import { formatCurrency } from '@/utils/helpers';
import {
  addSubProjectPersonnel,
  updateSubProjectPersonnel,
} from '@/state/slices/subProjectMethodSlice';
import { uniqueId } from 'lodash';

export interface ModalProps {
  id: string;
  subProjectMethodId: string;
  personnelCostId: string;
  expat: number;
  local: number;
  sousTraitant: number;
  description: string;
  total: number;
  hourlyRate: number;
  totalPerShift: number;
  totalCost: number;
  totalHours: number;
  comments: string;
  exchangeRate: number;
  personnelCurrency?: Currency;
  totalToCurrency?: number;
}

interface SubProjectPersonnelModalProps {
  closeModal: () => void;
  initialData?: ModalProps;
}

const validationSchema = Yup.object().shape({
  personnelCostId: Yup.string().required(
    'SubProjectPersonnelModel.PersonnelCostIdRequired'
  ),
  expat: Yup.number().required('SubProjectPersonnelModel.ExpatRequired'),
  local: Yup.number().required('SubProjectPersonnelModel.LocalRequired'),
  sousTraitant: Yup.number().required(
    'SubProjectPersonnelModel.SousTraitantRequired'
  ),

  total: Yup.number().required('SubProjectPersonnelModel.TotalRequired'),

  comments: Yup.string(),
});

const SubProjectPersonnelModal: React.FC<SubProjectPersonnelModalProps> = ({
  closeModal,
  initialData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

  const initialSubprojectPersonnel: ModalProps = {
    id: initialData?.id ?? '',
    subProjectMethodId: initialData?.subProjectMethodId ?? '',
    personnelCostId: initialData?.personnelCostId ?? '',
    expat: initialData?.expat ?? 0,
    local: initialData?.local ?? 0,
    sousTraitant: initialData?.sousTraitant ?? 0,
    description: initialData?.description ?? '',
    total: initialData?.total ?? 0,
    hourlyRate: initialData?.hourlyRate ?? 0,
    totalPerShift: initialData?.totalPerShift ?? 0,
    totalCost: initialData?.totalCost ?? 0,
    totalHours: initialData?.totalHours ?? 0,
    comments: initialData?.comments ?? '',
    exchangeRate: initialData?.exchangeRate ?? 1,
  };

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { data, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );

  const projectCurrency = data?.currency ?? Currency.EUR;
  const hoursPerShift = data?.workingHoursPerShift ?? 8;
  console.log('🚀 ~ hoursPerShift:', hoursPerShift);
  const projectDuration = data?.durationInDays ?? 1;
  console.log('🚀 ~ projectDuration:', projectDuration);
  const shiftsPerDay = data?.shiftsPerDay ?? 1;
  console.log('🚀 ~ shiftsPerDay:', shiftsPerDay);

  const handleSave = (values: ModalProps) => {
    setLoading(true);
    try {
      const personal = personals?.personnelCosts.filter(
        (x) => x.id == values.personnelCostId
      )[0]!;
      const subMeth = {
        ...values,
        id: initialData ? initialData.id : 'newP' + uniqueId(),
        exchangeRate: values.exchangeRate,
        name: personal.function,
        totalCost: values.totalPerShift * projectDuration,
        personnelCurrency: personal.currency,
        totalToCurrency:
          (values.totalPerShift * projectDuration) / values.exchangeRate,
        totalHours:
          values.total * projectDuration * hoursPerShift * shiftsPerDay,
        ref: null,
      };
      if (initialData) {
        dispatch(updateSubProjectPersonnel(subMeth));
      } else {
        dispatch(addSubProjectPersonnel(subMeth));
      }
      closeModal();
    } catch (error) {
      toast.error(t('SubProjectPersonnelModel.ErrorMessage'));
    } finally {
      setLoading(false);
    }
  };

  const {
    isLoading,
    isError,
    data: personals,
  } = useQuery<PersonnelCostsResponse | undefined>(
    ['personals'],
    () =>
      getPersonnelCosts({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const { data: exchangeRates, isLoading: isExchLoading } = useQuery(
    [
      'exchangeRates',
      projectCurrency,
      moment(selectedDate).format('YYYY-MM-DD'),
    ],
    () => getCurrencyExchange(Currency[projectCurrency], selectedDate),
    {
      staleTime: 60 * (60 * 1000), // 1 hour
      cacheTime: 120 * (60 * 1000), // 2 hours
    }
  );

  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="fixed left-0 right-0 top-0 z-[1055] flex h-full w-full items-center justify-center overflow-y-auto bg-gray-800 bg-opacity-75"
    >
      <div className="w-full max-w-[35rem] rounded-lg bg-white p-6 shadow-lg dark:bg-gray-700">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold">
            {initialData
              ? t('SubProjectPersonnelModel.EditSubProjectPersonnel')
              : t('SubProjectPersonnelModel.AddSubProjectPersonnel')}
          </h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        {loading || isLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={initialSubprojectPersonnel}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize={true}
          >
            {({ handleChange, errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="grid gap-2 md:grid-cols-3">
                  <Dropdown
                    id="personnelCostId"
                    name="personnelCostId"
                    labelClassName="w-[50%]"
                    optionValue="id"
                    optionLabel="function"
                    onChange={(e) => {
                      const personal = personals?.personnelCosts.filter(
                        (x) => x.id == e.target.value
                      )[0];
                      if (!personal) {
                        setFieldValue('personnelCostId', -1);
                        return;
                      }
                      setFieldValue('personnelCostId', e.target.value);
                      setFieldValue('hourlyRate', personal.hourlyRate);
                      setFieldValue(
                        'totalPerShift',
                        hoursPerShift * personal.hourlyRate
                      );
                    }}
                    options={personals?.personnelCosts ?? []}
                    label={t('SubProjectPersonnelModel.PersonnelCostId')}
                    labelClassName="w-[50%]"
                    error={errors.personnelCostId}
                    value={values.personnelCostId}
                  />
                  <DefaultInput
                    name="expat"
                    id="expat"
                    value={values.expat}
                    type="number"
                    label={t('SubProjectPersonnelModel.Expat')}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue('expat', e.target.value);
                      var total =
                        values.local + values.sousTraitant + e.target.value;
                      setFieldValue('total', total);
                    }}
                  />
                  <DefaultInput
                    name="local"
                    id="local"
                    value={values.local}
                    type="number"
                    label={t('SubProjectPersonnelModel.Local')}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue('local', e.target.value);
                      var total =
                        values.expat + values.sousTraitant + e.target.value;
                      setFieldValue('total', total);
                    }}
                  />
                  <DefaultInput
                    name="sousTraitant"
                    id="sousTraitant"
                    value={values.sousTraitant}
                    labelClassName="w-[50%]"
                    type="number"
                    label={t('SubProjectPersonnelModel.SousTraitant')}
                    errors={errors}
                    onChange={(e) => {
                      setFieldValue('sousTraitant', e.target.value);
                      var total = values.expat + values.local + e.target.value;
                      setFieldValue('total', total);
                    }}
                  />
                  <DefaultInput
                    name="description"
                    id="description"
                    value={values.description}
                    labelClassName="w-[50%]"
                    type="text"
                    label={t('SubProjectPersonnelModel.Description')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="total"
                    id="total"
                    value={values.total}
                    labelClassName="w-[50%]"
                    type="number"
                    label={t('SubProjectPersonnelModel.Total')}
                    errors={errors}
                    onChange={handleChange}
                    readOnly
                  />
                  <DefaultInput
                    name="hourlyRate"
                    id="hourlyRate"
                    value={values.hourlyRate}
                    labelClassName="w-[50%]"
                    type="number"
                    label={t('SubProjectPersonnelModel.HourlyRate')}
                    errors={errors}
                    onChange={handleChange}
                    readOnly
                  />
                  <DefaultInput
                    name="totalPerShift"
                    id="totalPerShift"
                    labelClassName="w-[50%]"
                    value={values.totalPerShift}
                    type="number"
                    label={t('SubProjectPersonnelModel.TotalPerShift')}
                    errors={errors}
                    onChange={handleChange}
                    readOnly
                  />
                  <DefaultInput
                    name="totalCost"
                    id="totalCost"
                    value={values.totalPerShift * projectDuration}
                    labelClassName="w-[50%]"
                    type="number"
                    label={t('SubProjectPersonnelModel.TotalCost')}
                    errors={errors}
                    onChange={handleChange}
                    readOnly
                  />
                  <DefaultInput
                    name="totalHours"
                    id="totalHours"
                    value={
                      values.total *
                      projectDuration *
                      hoursPerShift *
                      shiftsPerDay
                    }
                    type="number"
                    label={t('SubProjectPersonnelModel.TotalHours')}
                    errors={errors}
                    onChange={handleChange}
                    labelClassName="w-[50%]"
                    readOnly
                  />
                  <DefaultInput
                    name="exchangeRate"
                    id="exchangeRate"
                    value={values.exchangeRate}
                    type="number"
                    label={t('SubProjectPersonnelModel.ExchangeRate')}
                    errors={errors}
                    onChange={handleChange}
                    labelClassName="w-[50%]"
                  />
                </div>
                <TextArea
                  id="comments"
                  name="comments"
                  label={t('SubProjectPersonnelModel.Comments')}
                  errors={errors}
                  onChange={handleChange}
                  value={values.comments}
                />
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    {t('SubProjectPersonnelModel.Cancel')}
                  </button>
                  <button
                    type="submit"
                    className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                  >
                    {t('SubProjectPersonnelModel.Save')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default SubProjectPersonnelModal;
