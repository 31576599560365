import React, { useState } from 'react';
import { LanguageSkillModel } from '@/services/JobDescriptionService';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { removeLanguageSkill } from '@/state/slices/jobdescriptionSlice';
import LanguageSkillsForm from './LanguageSkillForm';

interface LanguageSkillsTableProps {
  languageSkills: LanguageSkillModel[];
  readonly?: boolean;
}

const LanguageSkillsTable: React.FC<LanguageSkillsTableProps> = ({
  languageSkills,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editLanguageSkill, setEditLanguageSkill] =
    useState<LanguageSkillModel | null>(null);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const handleAddClick = () => {
    setEditLanguageSkill(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (languageSkill: LanguageSkillModel) => {
    setEditLanguageSkill(languageSkill);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (languageSkillId: string) => {
    dispatch(removeLanguageSkill({ languageSkillId: languageSkillId }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditLanguageSkill(null);
  };

  return (
    <div className="w-full overflow-auto">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
        {/* Table headers */}
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3">{t('languageSkills.language')}</th>
            <th className="px-6 py-3">{t('languageSkills.canSpeak')}</th>
            <th className="px-6 py-3">{t('languageSkills.canRead')}</th>
            <th className="px-6 py-3">{t('languageSkills.canWrite')}</th>
            <th className="px-6 py-3">{t('languageSkills.hasTest')}</th>
            <th className="px-6 py-3">{t('languageSkills.testScore')}</th>
            {!readonly && (
              <th className="px-6 py-3">{t('languageSkills.actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {languageSkills.map((languageSkill) => (
            <tr
              key={languageSkill.id}
              className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <td className="px-6 py-3">
                {t(`LanguageNames.${languageSkill.language}`)}
              </td>
              <td className="px-6 py-3">
                <input
                  type="checkbox"
                  checked={languageSkill.canSpeak}
                  disabled
                />
              </td>
              <td className="px-6 py-3">
                <input
                  type="checkbox"
                  checked={languageSkill.canRead}
                  disabled
                />
              </td>
              <td className="px-6 py-3">
                <input
                  type="checkbox"
                  checked={languageSkill.canWrite}
                  disabled
                />
              </td>
              <td className="px-6 py-3">
                <input
                  type="checkbox"
                  checked={languageSkill.hasTest}
                  disabled
                />
              </td>
              <td className="px-6 py-3">{languageSkill.testScore}</td>
              {!readonly && (
                <td className="flex px-6 py-3">
                  <button
                    type="button"
                    onClick={() => handleEditClick(languageSkill)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {t('languageSkills.edit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteClick(languageSkill.id!)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    {t('languageSkills.delete')}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {!readonly && (
        <div className="my-4 flex justify-end">
          <button
            type="button"
            onClick={handleAddClick}
            className="mb-2 mr-2 rounded-lg bg-green-500 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {t('languageSkills.add')}
          </button>
        </div>
      )}

      {isModalOpen && (
        <LanguageSkillsForm
          initialValues={editLanguageSkill || undefined}
          onSubmit={closeModal}
          handleClose={closeModal}
        />
      )}
    </div>
  );
};

export default LanguageSkillsTable;
