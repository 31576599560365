import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { IsAuth } from '@/context/AuthContext';
import { useLicenseContext } from '@/context/LicenseContext';

interface CustomLinkProps extends React.ComponentProps<typeof RouterLink> {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, onClick, ...rest }) => {
  const signed = IsAuth();
  const { validLicense } = useLicenseContext();
  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!signed || !validLicense) {
      event.preventDefault();
      navigate('/');
    } else {
      if (onClick) {
        onClick(event);
      }
    }
  };

  return <RouterLink to={to} onClick={handleClick} {...rest} />;
};

export default CustomLink;
