export enum PaymentStatus {
  Paid,
  Pending,
  PartiallyPaid,
}

export const PaymentStatusOptions = Object.keys(PaymentStatus)
  .filter((key) => isNaN(Number(PaymentStatus[key])))
  .map((key) => ({
    id: key,
    name: PaymentStatus[key],
  }));
