import { CompaniesUrl, EmployeesUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { WorkCalendar } from './WorkCalendarsService';
import { MaritalStatusEnum } from '@/enums/maritialStatusEnum';
import { GenderEnum } from '@/enums/genderEnum';
import { ContractType } from '@/enums/contractType';
import { UserRoles } from '@/enums/UsersRole';
import { UserResponsibility } from '@/enums/UserResponsibility';
import { SeniorityRange } from '@/enums/SeniorityRange';

export interface Employee {
  id?: string;
  firstName?: string;
  lastName?: string;
  gender?: GenderEnum;
  email: string;
  grade?: string;
  idNumber: string;
  jobTitle?: string;
  leaveCarriedOver?: number;
  birthDate: Date | string;
  nationality?: string;
  birthCountry?: string;
  maritalStatus?: MaritalStatusEnum;
  childNumber?: string;
  profileImgUrl?: string;
  departmentId: string;
  companyId: string;
  stateId: string;
  countryId?: string;
  branchId?: string;
  employeeId?: string;
  workCalendarId?: string;
  address: {
    street: string;
    zipCode: string;
    postalCode: string;
  };
  phoneNumber1?: string;
  phoneNumber2?: string;
  teamId: string | null;
  branchName?: string;
  workCalendar?: WorkCalendar;
  employeeFullName?: string;
  jobDescriptionId?: string;
  contractType?: ContractType;
  hiringDate?: Date;
  lastAnnualInterviewDate?: Date;
  EmployeeResponsibility?: UserResponsibility;
  seniorityRange?: SeniorityRange;
  experience?: SeniorityRange;
  disabled?: boolean;
}

export interface EmployeesResponse {
  employees: Employee[];
  count: number;
}
const apiEndpoint = EmployeesUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  teamId?: string;
  notTeamMember?: boolean;
  departmentId?: string;
  branchId?: string;
  teamLeaderId?: string;
  employeeId?: string;
}
export async function getEmployees({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  notTeamMember = undefined,
  departmentId,
  branchId,
  teamId,
}: PaginatingParmas) {
  try {
    const companyId = getCompanyId();
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: companyId,
        notTeamMember: notTeamMember,
        departmentId,
        branchId,
        teamId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/get', config);
    return data as EmployeesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
export async function getEmployeeTeamMemebers({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  notTeamMember = undefined,
  departmentId,
  branchId,
  teamId,
  employeeId,
}: PaginatingParmas) {
  try {
    const companyId = getCompanyId();
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetTeamMemebers', config);
    return data as EmployeesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
export async function getEmployeeByDepartmentId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  notTeamMember = undefined,
  departmentId,
  branchId,
  teamId,
  employeeId,
}: PaginatingParmas) {
  try {
    const companyId = getCompanyId();
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByDepartmentId', config);
    return data as EmployeesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}

export async function getEmployee(employeeId: string) {
  try {
    const { data } = await api.get<Employee>(apiEndpoint + '/GetById', {
      params: { employeeId: employeeId },
    });

    return data as Employee;
  } catch (error) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', error);
  }
}

export async function saveEmployee(employee: Employee) {
  const companyId = getCompanyId();
  employee.companyId = companyId!;
  employee.birthDate = new Date(employee.birthDate);
  if (employee.id) {
    const body = { ...employee };

    try {
      const response = await api.put(apiEndpoint + '/put', body);
      return response;
    } catch (error) {
      console.log(
        '🚀 ~ file: CompanyService.ts:83 ~ saveCompany ~ error:',
        error
      );
    }
  }
  try {
    const response_1 = await api.post(apiEndpoint + '/post', employee);
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function deleteEmployee(employeeId: string) {
  try {
    const responce = await api.delete(apiEndpoint + '/delete', {
      data: { id: employeeId },
    });
    return responce;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}
export async function disableEmployee(employeeId: string) {
  try {
    const responce = await api.post(apiEndpoint + '/disable', {
      id: employeeId,
      disabled: true,
    });
    return responce;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}

export async function activeEmployee(employeeId: string) {
  try {
    const responce = await api.post(apiEndpoint + '/disable', {
      id: employeeId,
      disabled: false,
    });
    return responce;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}

export async function getEmployeesByTeamId({
  fromvalue = 0,
  takevalue = 0,
  search = '',
  teamId,
}: PaginatingParmas) {
  try {
    const companyId = getCompanyId();
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        teamId: teamId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/getByTeamId', config);
    return data as EmployeesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
export async function getEmployeesByTeamLeaderId({
  fromvalue = 0,
  takevalue = 0,
  search = '',
  teamLeaderId,
}: PaginatingParmas) {
  try {
    const companyId = getCompanyId();
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        teamLeaderId: teamLeaderId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByTeamLeader', config);
    return data as EmployeesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
export async function updateEmployeeTeam(teamId: string, employeeId: string) {
  try {
    const body = {
      employeeId: employeeId,
      teamId: teamId,
    };

    const response_1 = await api.post(apiEndpoint + '/updateTeam', body);
    return response_1;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
