import {
  Event,
  OffDaysType,
  ResourceType,
  Timeline,
  WeekendsType,
} from '@/components/TimeLine';
import { PrimaryButton } from '@/components/shared/Button';
import { Employee } from '@/services/EmployeeService';
import {
  convertEmployeesToResources,
  convertRequestLeavesToEvents,
} from '@/utils/CalendarHelper';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RequestALeaveModal from './RequestALeaveModal';
import { useAuth } from '@/context/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { getRequestLeavesByEmployee } from '@/services/RequestLeavesService';
import { RequestLeaveState } from '@/enums/RequestLeaveState';
import { OffDay, WorkCalendar } from '@/services/WorkCalendarsService';
import { DaysCalendarColor } from '@/variables/const';
import Spinner from '@/components/Spinner';

export interface EmployeeCalendarProps {
  employeeData: Employee;
  workCalendar: WorkCalendar;
}
const EmployeeCalendar = ({
  employeeData,
  workCalendar,
}: EmployeeCalendarProps) => {
  const { t } = useTranslation();
  const [weekendDays, setWeekendDays] = useState<WeekendsType[]>([]);
  const [filterdresourcesList, setResourcesList] = useState<ResourceType[]>([]);
  const [searchResource, setSearchResource] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<Event[]>([]);
  const [processData, setProcessData] = useState(false);
  const { user, loading } = useAuth();

  const {
    data: requestLeavesData,
    isLoading: requestLeaveLoading,
    isSuccess: isEventSucees,
  } = useQuery(
    ['requestLeaves', employeeData?.id],
    () => {
      const startOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      );
      /// we nned to add te last minute ofday
      endOfMonth.setHours(23, 59, 59, 999);
      return getRequestLeavesByEmployee({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        employeeId: employeeData?.id,
        requestLeaveState: RequestLeaveState.Approved,
        fromDate: startOfMonth,
        toDate: endOfMonth,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    // ! set Resource List
    setResourcesList(convertEmployeesToResources([employeeData]));
    //! set Events List
  }, [employeeData]);

  useEffect(() => {
    if (requestLeavesData && isEventSucees) {
      setProcessData(true);
      const eventsData = convertRequestLeavesToEvents(
        requestLeavesData.requestLeaves!
      );

      setEvents(eventsData);
      //! set Events List
      setProcessData(false);
    }
  }, [requestLeavesData, isEventSucees]);

  useEffect(() => {
    if (!employeeData) {
      setWeekendDays([]);
    }
    const daysMapping = {
      monday: t('Timeline.Mon'),
      tuesday: t('Timeline.Tue'),
      wednesday: t('Timeline.Wed'),
      thursday: t('Timeline.Thu'),
      friday: t('Timeline.Fri'),
      saturday: t('Timeline.Sat'),
      sunday: t('Timeline.Sun'),
    };
    const translatedDays = Object.keys(daysMapping)
      .filter((day) => employeeData?.workCalendar[day])
      .map((day) => daysMapping[day]);

    let weekends = [
      {
        employeeId: employeeData.id!,
        days: translatedDays,
      },
    ];

    setWeekendDays((prev) => (prev = weekends));
  }, [employeeData, t]);

  const holidays: OffDaysType[] = useMemo(() => {
    const employeeHolidays: OffDay[] =
      employeeData?.workCalendar?.offDays?.filter((x) => x.isPayed) ?? [];
    return employeeHolidays.map((holiday: OffDay) => ({
      employeeId: employeeData.id!,
      type: 'holiday',
      ...holiday,
    }));
  }, [employeeData]);

  const enterpriseClosing: OffDaysType[] = useMemo(() => {
    const employeeHolidays: OffDay[] =
      employeeData?.workCalendar?.offDays?.filter((x) => !x.isPayed) ?? [];
    return employeeHolidays.map((holiday: OffDay) => ({
      employeeId: employeeData.id!,
      type: 'enterpriseClosing',
      ...holiday,
    }));
  }, [employeeData]);

  return (
    <div className="flex flex-col">
      <div className=" flex   dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
        {
          <Timeline
            resourcesLength={filterdresourcesList.length - 1}
            resourcesList={filterdresourcesList}
            searchResource={searchResource}
            setSearchResource={setSearchResource}
            weekendDays={weekendDays}
            holidays={holidays}
            enterpriseClosing={enterpriseClosing}
            myEvents={events}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        }
      </div>
      <div className=" mt-5 flex align-bottom   dark:bg-boxdark dark:text-bodydark">
        <div className="flex gap-4 px-2">
          <div
            className={`h-4 w-4 rounded-md ${DaysCalendarColor.holiday}`}
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Holiday')}</p>
        </div>
        <div className="flex gap-4 px-2">
          <div
            className={`h-4 w-4 rounded-md ${DaysCalendarColor.weekend}`}
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Weekend')}</p>
        </div>
        <div className="flex gap-4 px-2">
          <div
            className={`h-4 w-4 rounded-md ${DaysCalendarColor.enterpriseClosing}`}
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Enterprise Closing')}</p>
        </div>
      </div>
      {isModalVisible && (
        <RequestALeaveModal
          isOpen={isModalVisible}
          onSaveOnly={() => {}}
          onSaveWithSendingToValidate={() => {}}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      <div className="mt-4 flex justify-end ">
        <PrimaryButton onClick={() => setIsModalVisible(true)}>
          {t('AbsensePanel.RequestAleave')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EmployeeCalendar;
