import Breadcrumb from '@/components/Breadcrumb';
import CardOne from '@/components/CardOne';
import CardTwo from '@/components/CardTwo';
import DefaultLayout from '@/layout/DefaultLayout';
import React, { useMemo, useState } from 'react';
import { ProjectAOStatus } from '@/enums/ProjectAOStatus';
import ProjectCard, { ProjectCardProps } from './Components/ProjectCard';
import { useTranslation } from 'react-i18next';
import Dropdown from '@/components/DropDown';
import Pagination from '@/components/Pagination';
import { useQuery } from '@tanstack/react-query';
import {
  ProjectsBriefResponse,
  getProjectsBrief,
} from '@/services/ProjectService';
import Spinner from '@/components/Spinner';

// const projectCards: ProjectCardProps[] = [
//   {
//     state: ProjectAOStatus.New,
//     bidManagerName: 'John Doe',
//     title: 'Acme Website Redesign',
//     date: '2023-04-15',
//     id: '1234',
//   },
//   {
//     state: ProjectAOStatus.Won,
//     bidManagerName: 'Jane Smith',
//     title: 'Gadget Manufacturing Contract',
//     date: '2023-06-01',
//     id: '5678',
//   },
//   {
//     state: ProjectAOStatus.Lost,
//     bidManagerName: 'Michael Johnson',
//     title: 'Enterprise Software Integration',
//     date: '2023-03-30',
//     id: '9012',
//   },
//   {
//     state: ProjectAOStatus.Cancelled,
//     bidManagerName: 'Sarah Lee',
//     title: 'Mobile App Development',
//     date: '2023-05-20',
//     id: '3456',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade',
//     date: '2023-07-10',
//     id: '7891',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade',
//     date: '2023-07-10',
//     id: '7852',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade 5',
//     date: '2023-07-10',
//     id: '7850',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade 6',
//     date: '2023-07-10',
//     id: '7800',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade 7 ',
//     date: '2023-07-10',
//     id: '7810',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade 8 ',
//     date: '2023-07-10',
//     id: '7510',
//   },
//   {
//     state: ProjectAOStatus.Deferred,
//     bidManagerName: 'David Kim',
//     title: 'Industrial Automation Upgrade 11',
//     date: '2023-07-10',
//     id: '7570',
//   },
// ];

function ProjectsPOA() {
  const [value, setValue] = React.useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');

  const {
    isLoading,
    isError,
    data: projects,
  } = useQuery<ProjectsBriefResponse | undefined>(
    ['projects', pageIndex, pageSize, search],
    () =>
      getProjectsBrief({
        from: pageIndex,
        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((projects?.projects.length ?? 0) / pageSize);
  }, [pageSize, projects?.projects]);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };
  return (
    <div>
      <DefaultLayout>
        <Breadcrumb pageName="Projects" addbtn={true} addRouter="new" />

        <div className="   rounded-sm border border-stroke bg-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="flex w-full justify-between py-10">
            <SearchBar value={search} setValue={setSearch} dataCount={0} />
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-3 gap-2">
              {projects?.projects.map((p) => (
                <ProjectCard
                  key={p.id}
                  title={p.title}
                  id={p.id}
                  bidManagerName={p.bidManagerName}
                  date={p.date}
                  state={p.state}
                />
              ))}
            </div>
          )}

          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      </DefaultLayout>
    </div>
  );
}

export default ProjectsPOA;

export const SearchBar = ({ value, setValue, dataCount }) => {
  const { t } = useTranslation();
  return (
    <label className="flex  items-baseline gap-x-4">
      <span className="px-2 text-gray-700 dark:text-bodydark">
        {t('DynamicTable.Search')}
      </span>
      <input
        type="text"
        className="w-100 rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-boxdark-2 dark:text-bodydark"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={`${dataCount} ${t('DynamicTable.records')}...`}
      />
    </label>
  );
};
