import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import {
  CountriesResponse,
  Country,
  State,
  getCountries,
} from '@/services/CountryService';
import { Company, getCompany, saveCompany } from '@/services/CompanyService';
import ImageUploader from '@/components/ImagePicker';
import Dropdown from '@/components/DropDown';
import CheckboxGroup from '@/components/CheckboxGroup';
import { convertEnumToCheckboxes } from '@/utils/EnumUtils';
import { ModuleType } from '@/models/ModuleTypeEnum';
import Spinner from '@/components/Spinner';
import { useParams } from 'react-router-dom';
import DatePickerDefault from '@/components/DatePicker';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    'Passwords must match'
  ),
  issueDate: Yup.date()
    .min(new Date(), 'Issue date must not be in the future')
    .required('Issue date is required'),
  expirationDate: Yup.date()
    .min(
      Yup.ref('issueDate'),
      'Expiration date must be after or equal to the issue date'
    )
    .test(
      'is-after-issue-date',
      'Expiration date must be at least one day after the issue date',
      function (value) {
        const issueDate = this.resolve(Yup.ref('issueDate'));
        if (!issueDate || !value) return true; // Let the required validation handle empty values

        const oneDayMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
        return value.getTime() >= issueDate.getTime() + oneDayMilliseconds;
      }
    )
    .required('Expiration date is required'),
});

const validateEditShema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  stateId: Yup.string().required('State is required'),

  issueDate: Yup.date().required('Issue date is required'),
  expirationDate: Yup.date()
    .min(
      Yup.ref('issueDate'),
      'Expiration date must be after or equal to the issue date'
    )
    .test(
      'is-after-issue-date',
      'Expiration date must be at least one day after the issue date',
      function (value) {
        const issueDate = this.resolve(Yup.ref('issueDate'));
        if (!issueDate || !value) return true; // Let the required validation handle empty values

        const oneDayMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
        return value.getTime() >= issueDate.getTime() + oneDayMilliseconds;
      }
    )
    .required('Expiration date is required'),
});
interface DatePickerOptions {
  title?: string;
  autoHide?: boolean;
  todayBtn?: boolean;
  clearBtn?: boolean;
  maxDate?: Date;
  minDate?: Date;
  theme?: {
    background?: string;
    todayBtn?: string;
    clearBtn?: string;
    icons?: string;
    text?: string;
    disabledText?: string;
    input?: string;
    inputIcon?: string;
    selected?: string;
  };
  icons?: {
    prev?: () => React.ReactElement;
    next?: () => React.ReactElement;
  };
  datepickerClassNames?: string;
  defaultDate?: Date;
  language?: string;
}
let options: DatePickerOptions = {
  maxDate: new Date('2030-01-01'),
  minDate: new Date('1950-01-01'),
};
interface FormValues extends Company {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  phoneNumber: string;
  country: Country;
  state: State;
}

const checkboxOptions = convertEnumToCheckboxes(ModuleType);

function CompanyForm() {
  const { id } = useParams();
  const editForm = id !== 'new';

  const [states, setStates] = React.useState<State[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [formikValuesState, setFormikValuesState] = React.useState<FormValues>({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    phoneNumber: '',
    country: {} as Country,
    state: {} as State,
    id: '',
    logoURL: '',
    issueDate: new Date(),
    expirationDate: new Date(),
    zipCode: '',
    street: '',
    postalCode: '',
    moduleTypes: '',
  });
  const [loading, setLoading] = useState(false); // Add loading state

  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      if (!editForm) {
        setStates(data?.countries[0].states!);
      }
      setCountries(data?.countries!);
    },
  });
  const {
    data: companyData,
    isLoading,
    refetch: refetchCompany,
  } = useQuery<Company | undefined>(
    ['company', id],
    async () => await getCompany(id!),

    {
      enabled: editForm && !countryLoading, // Only fetch the 'company' data if editForm is true and 'countries' query is not loading
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (company) => {
        let selectedCountryObj = data!.countries.find(
          (country) => country.id === company!.countryId?.toString()
        );
        setStates((prev) => selectedCountryObj!.states!);
        // const selectedStateObj = company?.state;
        // setFormikValuesState((prev) => {
        //   return {
        //     ...prev,
        //     ...company,
        //     country: selectedCountryObj!,
        //     state: selectedStateObj!,
        //   };
        // });
      },
      // Only fetch the data when editing (not for 'new' form)
    }
  );

  useEffect(() => {
    if (editForm && companyData && data != null) {
      // get Country

      // get State

      // update form

      let selectedCountryObj = data!.countries.find(
        (country) => country.id === companyData!.countryId?.toString()
      );

      setStates(selectedCountryObj!.states!);
      const selectedStateObj = companyData?.state;

      setFormikValuesState((prev) => {
        return {
          ...prev,
          ...companyData,
          country: selectedCountryObj!,
          state: selectedStateObj!,
        };
      });
    }
  }, [editForm, companyData]);

  const handleSaveCompany = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true); // Set loading to true before making the API request

    try {
      const company: Company = {
        ...values,
        stateId: values.state.id,
      };
      if (values.state != null) {
        const data = await saveCompany(company);

        if (data && editForm) {
          refetchCompany();
        }
      } else {
        // Trigger validation for the 'state' field
        await validateForm();
        return; // Don't proceed with saving if the 'state' field is not selected
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm(); // Set loading to false after the API request is complete
    }
  };
  const handleCountryChange = (event: any, formikHandleChange: any) => {
    const selectedCountryName = event.target.value;
    const selectedCountryObj = data?.countries?.find(
      (country) => country.name === selectedCountryName
    );

    if (selectedCountryObj !== null && selectedCountryObj !== undefined) {
      setStates(selectedCountryObj.states!);
    } else {
      setStates([]);
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Add Company" />
      {loading && countryLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={editForm ? validateEditShema : validationSchema}
          onSubmit={handleSaveCompany}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            resetForm,
            validateForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6 ">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="name"
                    name="name"
                    id="name"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.name}
                    label="Name"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.email}
                    label="Email"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder=""
                    onChange={handleChange}
                    errors={errors}
                    value={values.phoneNumber}
                    label="PhoneNumber"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="street"
                    id="street"
                    placeholder=""
                    onChange={handleChange}
                    errors={errors}
                    value={values.street}
                    label="Street"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    placeholder=""
                    onChange={handleChange}
                    errors={errors}
                    value={values.zipCode}
                    label="ZipCode"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    placeholder=""
                    onChange={handleChange}
                    errors={errors}
                    value={values.postalCode}
                    label="Postal Code"
                  />
                </div>
              </div>
              {!editForm && (
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="password"
                      name="password"
                      id="password"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.password}
                      label="Password"
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.confirmPassword}
                      label="Confirm password"
                    />
                  </div>
                </div>
              )}
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="country"
                    name="country"
                    value={values.country ?? ''}
                    optionValue="name"
                    onChange={(e) => handleCountryChange(e, handleChange)}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={countries ?? []}
                    label="Select a country"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="state"
                    name="state"
                    value={values.state ?? ''}
                    optionValue="name"
                    onChange={(e) => {
                      const selectedState = states.find(
                        (state) => state.name === e.target.value
                      );
                      handleChange({
                        target: {
                          name: 'state',
                          value: selectedState || {},
                        },
                      });
                    }}
                    options={states ?? []}
                    label="Select a state"
                  />
                </div>
              </div>

              <div className="grid  md:grid-cols-2 md:gap-6">
                <div className="group relative mb-6 w-full">
                  <DatePickerDefault
                    id="issueDate"
                    value={new Date(values.issueDate)}
                    name="issueDate"
                    label="Licence Start Date"
                    defaultDate={values.issueDate}
                    onChange={(date: any) => {
                      console.error(
                        '🚀 ~ file: CompanyForm.tsx:422 ~ CompanyForm ~ date:',
                        date
                      );
                      handleChange({
                        target: {
                          name: 'issueDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.issueDate}
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  <DatePickerDefault
                    id="issueDate"
                    value={new Date(values.expirationDate)}
                    name="expirationDate"
                    label="Licence expiration Date"
                    defaultDate={values.expirationDate}
                    onChange={(date: any) => {
                      console.error(
                        '🚀 ~ file: CompanyForm.tsx:422 ~ CompanyForm ~ date:',
                        date
                      );
                      handleChange({
                        target: {
                          name: 'expirationDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.expirationDate}
                  />
                </div>
              </div>
              <div className="group relative z-0 mb-6 w-full  ">
                <CheckboxGroup
                  title="Modules"
                  checkboxes={checkboxOptions}
                  selectedCheckboxes={
                    values.moduleTypes && values.moduleTypes !== ''
                      ? values.moduleTypes.split(',')
                      : []
                  }
                  onChange={(selectedCheckboxes) => {
                    handleChange({
                      target: {
                        name: 'moduleTypes',
                        value: selectedCheckboxes.join(','),
                      },
                    });
                  }}
                />
              </div>
              <div className="relative z-20 col-span-2 grid w-[90%] px-5 py-2">
                <ImageUploader
                  name="logoURL"
                  handleChange={handleChange}
                  id="logoURL"
                  multiple={false}
                  value={values.logoURL}
                />
              </div>

              <button
                type="submit"
                className="focus:shadow-outline
                     float-right
                     mb-4
                     rounded-sm
                    bg-blue-700
                    px-3 py-1
                    text-white hover:bg-blue-500 focus:outline-none

                    "
                disabled={loading}
              >
                Submit
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm  px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                }}
              >
                Cancel
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default CompanyForm;

// function initializeFormValues(): FormValues {
//   const defaultValues: FormValues = {
//     email: '',
//     password: '',
//     confirmPassword: '',
//     name: '',
//     phoneNumber: '',
//     country: {} as Country,
//     state: {} as State,
//     id: '',
//     logoURL: '',
//     issueDate: new Date(),
//     expirationDate: new Date(),
//     zipCode: '',
//     street: '',
//     postalCode: '',
//     moduleTypes: '',
//   };

//   if (editForm && companyData) {
//     // get country and state

//     const newValues = {
//       ...defaultValues,
//       email: companyData.email ?? '',
//       name: companyData.name ?? '',
//       phoneNumber: companyData.phoneNumber ?? '',

//       id: companyData.id ?? '',
//       logoURL: companyData.logoURL ?? '',
//       issueDate: companyData.issueDate
//         ? new Date(companyData.issueDate)
//         : new Date(),
//       expirationDate: companyData.expirationDate
//         ? new Date(companyData.expirationDate)
//         : new Date(),
//       zipCode: companyData.zipCode ?? '',
//       street: companyData.street ?? '',
//       postalCode: companyData.postalCode ?? '',
//       moduleTypes: companyData.moduleTypes ?? '',
//     };
//     console.log(
//       '🚀 ~ file: CompanyForm.tsx:232 ~ defaultFomr ~ country:',
//       newValues
//     );
//     return newValues;
//   }

//   return defaultValues;
// }
