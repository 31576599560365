import {
  EmployeeGradeType,
  EmployeeGradeTypeOptions,
} from '@/enums/EmployeeGradeType';
import { getEnumNames, getTranslatedEnumNames } from '@/enums/SeniorityRange';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'tailwindcss/tailwind.css'; // Make sure to import Tailwind CSS styles

interface InterviewDataModel {
  employeeType: EmployeeGradeType;
  done: number;
  notDone: number;
}

const InterviewStatusTable: React.FC<{ data: InterviewDataModel[] }> = ({
  data,
}) => {
  // Create an array with both 'Cadre' and 'NoCadre' for rows

  const { t } = useTranslation();
  const employeeTypes = getTranslatedEnumNames(
    EmployeeGradeType,
    t,
    'EmployeeGradeType'
  );

  return (
    <div className="mt-4 flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('InterviewStatusTable.employeeType')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('InterviewStatusTable.done')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('InterviewStatusTable.notDone')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
                {EmployeeGradeTypeOptions.map((employeeType) => {
                  // Find the corresponding data for the current employeeType
                  const rowData = data.find(
                    (item) => item.employeeType === employeeType.id
                  ) || {
                    employeeType: employeeType.id,
                    done: 0,
                    notDone: 0,
                  };
                  console.log(
                    '🚀 ~ {EmployeeGradeTypeOptions.map ~ employeeType:',
                    employeeType,
                    rowData
                  );
                  return (
                    <tr key={employeeType.id}>
                      <td className="whitespace-nowrap px-6 py-4">
                        {employeeType.name}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {rowData.done}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {rowData.notDone}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewStatusTable;
