export enum PersonnelCategory {
  Management,
  Indivisible,
  Subcontractor,
  Freelancer,
  Operator,
}


export const PersonnelCategoryOptions = Object.keys(PersonnelCategory)
  .filter((key) => isNaN(Number(PersonnelCategory[key])))
  .map((key) => ({
    id: key,
    name: PersonnelCategory[key],
  }));

