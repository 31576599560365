import { TFunction } from 'i18next';

export enum SeniorityRange {
  _0_5,
  _5_10,
  _10_15,
  _15_20,
  _20_25,
  _25_30,
}

export function getEnumNames(enumObject: any): string[] {
  return Object.keys(enumObject).filter(
    (key) => !isNaN(Number(enumObject[key]))
  );
}

export function getTranslatedEnumNames(
  enumObject: any,
  t: TFunction,
  prefix: string
): string[] {
  return Object.keys(enumObject)
    .filter((key) => !isNaN(Number(enumObject[key])))
    .map((title) => t(`${prefix}.${title}`));
}

export const seniorityRangeOptions = [
  { id: SeniorityRange._0_5, name: '_0_5' },
  { id: SeniorityRange._5_10, name: '_5_10' },
  { id: SeniorityRange._10_15, name: '_10_15' },
  { id: SeniorityRange._15_20, name: '_15_20' },
  { id: SeniorityRange._20_25, name: '_20_25' },
  { id: SeniorityRange._25_30, name: '_25_30' },
];

export const translateOptionName = (
  t: TFunction,
  namespace: string,
  optionName: string
) => {
  // Assuming you have translations for option names in your i18n namespaces
  const translationKey = `${namespace}.${optionName}`;
  return t(translationKey) as string;
};
