import { AgeSlice } from '@/enums/AgeSlice';
import { SeniorityRange, getEnumNames } from '@/enums/SeniorityRange';
import { GenderEnum } from '@/enums/genderEnum';
import {
  EmployeesByAgeSliceDataModel,
  SeniorityByGenderDataModel,
} from '@/models/CompanyRHDataModel';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AgesTableProps {
  data: EmployeesByAgeSliceDataModel[];
}

const AgesTable: React.FC<AgesTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const agesTitles = getEnumNames(AgeSlice);
  const genderTitles = getEnumNames(GenderEnum);
  return (
    <div className="mt-4 flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t(`AgeSlice.metrics`)}
                  </th>
                  {agesTitles.map((v) => (
                    <th
                      key={v}
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t(`AgeSlice.${v}`)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark  ">
                {genderTitles.map((gender) => (
                  <tr key={gender}>
                    <td className="whitespace-nowrap px-6 py-4">
                      {t(`GenderEnum.${gender}`)}
                    </td>
                    {agesTitles.map((range) => {
                      const cellData = data.find(
                        (item) =>
                          item.gender === GenderEnum[gender] &&
                          item.ageSlice === AgeSlice[range]
                      );
                      const employeeCount = cellData
                        ? cellData.employeeCount
                        : 0;

                      return (
                        <td
                          key={`${gender}-${range}`}
                          className="whitespace-nowrap px-6 py-4"
                        >
                          {employeeCount}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgesTable;
