import React, { useState } from 'react';
import { DeliverableCodificationDto } from '@/services/DeliverablesService';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next for translations
import { XIcon, PencilIcon, PlusIcon } from '@heroicons/react/outline'; // Importing Heroicons
import { default as DefaultInput } from '@/components/Input';
import { PrimaryButton, PurpleButton } from '@/components/shared/Button';
import { getCompanyId } from '@/services/AuthService';

interface IDeliverableCodificationsModal {
  deliverablesCodings: DeliverableCodificationDto[];
  setDeliverablesCodings: React.Dispatch<
    React.SetStateAction<DeliverableCodificationDto[]>
  >;
  closeModal: () => void;
}

function DeliverableCodificationsModal({
  deliverablesCodings,
  setDeliverablesCodings,
  closeModal,
}: IDeliverableCodificationsModal) {
  const { t } = useTranslation(); // Hook for translation

  const [codifications, setCodifications] =
    useState<DeliverableCodificationDto[]>(deliverablesCodings);

  const handleChange = (index: number, field: string, value: string) => {
    const updatedCodifications = [...codifications];
    updatedCodifications[index] = {
      ...updatedCodifications[index],
      [field]: value,
    };
    setCodifications(updatedCodifications);
  };

  const handleAddCodification = () => {
    setCodifications([
      ...codifications,
      {
        description: '',
        appreviation: '',
        companyId: getCompanyId()!.toString(),
      },
    ]);
  };

  const handleRemoveCodification = (index: number) => {
    const updatedCodifications = [...codifications];
    updatedCodifications.splice(index, 1);
    setCodifications(updatedCodifications);
  };

  const handleSaveChanges = () => {
    setDeliverablesCodings(codifications);
    closeModal();
  };

  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="fixed left-0 right-0 top-0 z-[1055] flex h-full w-full  items-center justify-center overflow-y-auto bg-gray-800 bg-opacity-75"
    >
      <div className="w-full  max-w-[35rem] rounded-lg bg-white p-6 shadow-lg dark:bg-gray-700">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold">
            {t('EditDeliverableCodifications')}
          </h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            <XIcon className="h-6 w-6" /> {/* Close icon */}
          </button>
        </div>
        {codifications.map((codification, index) => (
          <div
            key={index}
            className="mb-4  grid items-center gap-4 md:grid-cols-3  "
          >
            <DefaultInput
              label={t('Description')}
              type="text"
              id={`description-${index}`}
              name={`description-${index}`}
              value={codification.description}
              onChange={(e) =>
                handleChange(index, 'description', e.target.value)
              }
              placeholder={t('Description')}
            />
            <DefaultInput
              type="text"
              id={`appreviation-${index}`}
              name={`appreviation-${index}`}
              value={codification.appreviation}
              onChange={(e) =>
                handleChange(index, 'appreviation', e.target.value)
              }
              placeholder={t('Abbreviation')}
              label={t('Abbreviation')}
            />
            <button
              type="button"
              className="pt-5 text-gray-500 hover:text-red-600"
              onClick={() => handleRemoveCodification(index)}
            >
              <XIcon className="h-6 w-6" /> {/* Remove icon */}
            </button>
          </div>
        ))}
        <div className="flex justify-end">
          <PurpleButton type="button" onClick={handleAddCodification}>
            {t('AddCodification')}
          </PurpleButton>
          <PrimaryButton
            type="button"
            onClick={handleSaveChanges}
            className="rounded-md bg-green-500 px-4 py-2 text-white hover:bg-green-600 focus:bg-green-600 focus:outline-none"
          >
            {t('SaveChanges')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default DeliverableCodificationsModal;
