import { CompaniesUrl } from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';

export interface Company {
  phoneNumber: string;
  id: string;
  email: string;
  note?: string;
  name: string;
  password: string;
  logoURL: string;
  oldPassword?: string;
  newPassword?: string;
  street?: string;
  zipCode?: string;
  postalCode?: string;
  issueDate: Date;
  expirationDate: Date;
  moduleTypes?: string;
  isDisabled?: boolean;
  isValidLicense?: boolean;
  stateId?: string;
  countryId?: string;
  userId?: string;
  country: Country;
  state: State;
  message?:string;
}

export interface CompaniesResponse {
  companies: Company[];
  count: number;
}
const apiEndpoint = CompaniesUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getCompanies({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
      },
    };
    const { data } = await api.get(apiEndpoint + '/get', config);
    return data;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}

export async function getCompany(companyId: string) {
  try {
    const { data } = await api.get<Company>(apiEndpoint + '/GetById', {
      params: { id: companyId },
    });

    return data as Company;
  } catch (error) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', error);
  }
}

export async function saveCompany(company: Company) {
  if (company.id) {
    const body = { ...company };

    try {
      const response = await api.put(apiEndpoint + '/put', body);
      return response;
    } catch (error) {
      console.log(
        '🚀 ~ file: CompanyService.ts:83 ~ saveCompany ~ error:',
        error
      );
    }
  }
  try {
    const response_1 = await api.post(apiEndpoint + '/post', company);
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export function deleteCompany(companyId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: companyId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      console.log(error.response);
      return error;
    });
}
