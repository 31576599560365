import DynamicTable, {
  EnumRequestLeaveStatesFilter,
} from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import {
  TransferRequestLeaveStateChange,
  VacationBalanceTransferRequest,
  cancelTransferRequestLeave,
  getTransferRequestsByEmployeeId,
  validateDraftTransferRequest,
} from '@/services/VacationBalanceTransferRequestService';
import { useQuery } from '@tanstack/react-query';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RequestALeaveModal from './RequestALeaveModal';
import TransferRequestALeaveModal from './TransferRequestALeaveModal';
import { RequestLeaveState, stateColors } from '@/enums/RequestLeaveState';
import {
  RequestLeave,
  RequestLeaveStateChange,
  cancelRequestLeave,
  validateDraftRequest,
} from '@/services/RequestLeavesService';
import { setLoading } from '@/state/slices/employeeSlice';
import toast from 'react-hot-toast';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { trackForMutations } from '@reduxjs/toolkit/dist/immutableStateInvariantMiddleware';

const EmployeeTransferRequestsVacationTable = () => {
  const { t } = useTranslation(); // Use the default namespace
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [selectedRow, setSelectedRow] =
    useState<VacationBalanceTransferRequest | null>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  const { user } = useAuth();
  const {
    data: transferRequestsData,
    isLoading: transferRequestsLoading,
    refetch: refetchREmployee,
    isSuccess,
  } = useQuery(
    ['TransferRequestVacations', user?.id, pageIndex, pageSize, search],
    () =>
      getTransferRequestsByEmployeeId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
        employeeId: user?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  /* change states function  */

  const handleCancelRequest = async () => {
    setLoading(true);
    const reqState: TransferRequestLeaveStateChange = {
      id: selectedRow?.id!,
      state: RequestLeaveState.Canceled,
    };
    const data = await cancelTransferRequestLeave(selectedRow?.id!);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('RequestLeaveState.Rejected'),
        })
      );
      refetchREmployee();
    }
    setLoading(false);
    setIsModalVisible(false);
    refetchREmployee();
  };
  const handleSendRequestToValidation = async (trequestLeave) => {
    setLoading(true);
    const reqState: TransferRequestLeaveStateChange = {
      id: selectedRow?.id!,
      state: RequestLeaveState.Pending,
    };
    const data = await validateDraftTransferRequest(trequestLeave?.id!);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('AbsensePanel.SendforValidation'),
        })
      );
      refetchREmployee();
    }
    setLoading(false);
    setIsModalVisible(false);
    refetchREmployee();
  };
  const ActionButtonsCell = ({ value }: any) => {
    const [trequestLeave, setTRequestLeave] =
      useState<VacationBalanceTransferRequest>();

    useEffect(() => {
      if (isSuccess && transferRequestsData) {
        let res = transferRequestsData?.transferRequests!.find(
          (r) => r.id === value
        )!;
        setTRequestLeave(res);
      }
    }, [isSuccess, transferRequestsData, value, selectedRow]);

    return (
      trequestLeave &&
      trequestLeave.state! == RequestLeaveState.Draft.toString() && (
        <div>
          <DeleteButton
            onClick={() => {
              setSelectedRow(trequestLeave);
              setIsModalVisible(true);
            }}
          >
            Cancel
          </DeleteButton>

          {/* Edit button */}
          <EditButton
            type="button"
            onClick={() => handleSendRequestToValidation(trequestLeave)}
          >
            Send for Validation
          </EditButton>

          {/* <button onClick={() => handleEdit(row.original.id)}>Edit</button> */}
        </div>
      )
    );
  };

  const pageCount = useMemo(() => {
    return Math.ceil((transferRequestsData?.count ?? 0) / pageSize);
  }, [pageSize, transferRequestsData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('transferRequestVacation.VacationName'),
        accessor: 'vacationName',
      },
      {
        Header: t('transferRequestVacation.RequestedDays'),
        accessor: 'days',
      },

      {
        Header: t('RequestLeaves.comment'),
        accessor: 'comment',
      },
      {
        Header: t('transferRequestVacation.State'),
        accessor: 'state',
        Filter: EnumRequestLeaveStatesFilter,
        Cell: ({ cell: { value } }) => {
          const frontendState = RequestLeaveState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => <ActionButtonsCell value={value} />,
      },
    ],
    [t, isSuccess, transferRequestsData]
  );
  return (
    <div className="flex flex-col">
      <DynamicTable
        dataCount={transferRequestsData?.count ?? 0}
        data={transferRequestsData?.transferRequests ?? []}
        columns={columns}
        setSearch={setSearch}
        exportPdf={false}
      />
      {isModalVisible && (
        <DeleteConfirmationModal
          isOpen={isModalVisible}
          onDelete={handleCancelRequest}
          onCancel={() => setIsModalVisible(false)}
          textContent="Requestleave.CancelRequestMSG"
        />
      )}

      {isTransferModalVisible && (
        <TransferRequestALeaveModal
          isOpen={isModalVisible}
          onCancel={() => setIsTransferModalVisible(false)}
        />
      )}
      <Pagination
        pageIndex={pageCount === 0 ? -1 : pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <div className="mt-4 flex justify-end ">
        <PrimaryButton onClick={() => setIsTransferModalVisible(true)}>
          {t('AbsensePanel.RequestAleave')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EmployeeTransferRequestsVacationTable;
