import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { InterviewState } from '@/enums/InterviewState';
import { SkillLevel } from '@/enums/SkillLevel';
import { AttitudeModel } from '@/models/AnnualInterviewModel';
import { RootState } from '@/state';
import {
  saveAnnualInterviewAsync,
  updateOverallEvoutlion,
} from '@/state/slices/annualInterviewSlice';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

const init = {
  overallEvaluation: SkillLevel.Bien,
};
function GlobalEvolution() {
  const { user } = useAuth();

  const { t } = useTranslation();
  // const isTeamLeader = user?.isTeamLeader == 'True';
  const overallEvaluation = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.overallEvaluation
  );
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const disabled =
    annualInerview.interviewState == InterviewState.Completed || !isTeamLeader;
  const attitudeKeys = Object.keys(overallEvaluation) as Array<string>;
  const nonNumericKeys = Object.keys(SkillLevel).filter(
    (key) => !isNaN(Number(SkillLevel[key]))
  ) as Array<keyof typeof SkillLevel>;
  const formik = useFormik({
    initialValues: {
      ...init,
      overallEvaluation: overallEvaluation,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(
        updateOverallEvoutlion({ overallEvaluation: values.overallEvaluation })
      );
    },
  });
  const handleSkillLevelChange = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3"></th>
              {nonNumericKeys.map((level: keyof typeof SkillLevel) => (
                <th key={level} scope="col" className="px-6 py-3">
                  {t(`SkillLevel.${level}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              <tr
                key={'overallEvaluation'}
                className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <th scope="row" className="px-6 py-4 font-medium">
                  {t(`annualInterviewForm.overallEvaluation`)}
                </th>
                {nonNumericKeys.map((level: keyof typeof SkillLevel) => (
                  <td key={level} className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.overallEvaluation === SkillLevel[level]
                      }
                      onChange={() =>
                        handleSkillLevelChange(
                          'overallEvaluation',
                          SkillLevel[level]
                        )
                      }
                      disabled={disabled}
                    />
                  </td>
                ))}
              </tr>
            }
          </tbody>
        </table>

        {isTeamLeader && !disabled && (
          <div className="flex justify-between">
            <LabelButton type="button" onClick={() => resetForm()}>
              {t('annualInterviewForm.Attitude.reset')}
            </LabelButton>
            <PrimaryButton
              className="border-0 bg-purple-300 dark:bg-purple-500"
              type="button"
              onClick={handleSyncData}
            >
              <div className="flex items-center gap-2">
                <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
                {t('annualInterviewForm.Attitude.Sync')}
              </div>
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              className="border-0 bg-primary-300 dark:bg-primary-500"
            >
              {t('annualInterviewForm.Attitude.submit')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </form>
  );
}

export default GlobalEvolution;
