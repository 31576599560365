import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import {
  RequestLeave,
  RequestLeaveStateChange,
  changeRequestLeaveState,
  getRequestLeave,
} from '@/services/RequestLeavesService'; // Import your service
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '@/components/Breadcrumb';
import Spinner from '@/components/Spinner';
import DefaultLayout from '@/layout/DefaultLayout';
import Input from '@/components/Input';
import TextArea from '@/components/TextArea';
import {
  RequestLeaveState,
  mapBackendStateToFrontendState,
  stateColors,
} from '@/enums/RequestLeaveState';
import { EditButton, DeleteButton } from '@/components/shared/Button';
import { setLoading } from '@/state/slices/employeeSlice';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  TransferRequestLeaveStateChange,
  VacationBalanceTransferRequest,
  changeTransferRequestLeaveState,
  getTransferRequest,
} from '@/services/VacationBalanceTransferRequestService';
import { UserRoles } from '@/enums/UsersRole';
import { useAuth } from '@/context/AuthContext';

function TransferVacationRequestDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [commentText, setCommentText] = useState('');
  const { user } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    data: transferRequestLeaveDetail,
    isLoading,
    refetch,
    isSuccess,
  } = useQuery<VacationBalanceTransferRequest | undefined>(
    ['transferRequestLeave', id],
    async () => await getTransferRequest(id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isSuccess && transferRequestLeaveDetail) {
      setCommentText(transferRequestLeaveDetail?.comment ?? '');
    }
  }, [isSuccess, transferRequestLeaveDetail]);

  const handleCancelReject = () => {
    setIsModalVisible(false);
  };

  const handleRejectConfirmation = async () => {
    // !!!handle reject request
    setLoading(true);
    const reqState: TransferRequestLeaveStateChange = {
      id: id!,
      state: RequestLeaveState.Rejected,
      comment: commentText,
    };
    const data = await changeTransferRequestLeaveState(reqState);
    setLoading(false);
    setIsModalVisible(false);
    refetch();
  };

  const handleApproveRequest = async () => {
    // !!!handle approve request
    setLoading(true);
    const reqState: TransferRequestLeaveStateChange = {
      id: id!,
      state: RequestLeaveState.Approved,
      comment: commentText,
    };
    const data = await changeTransferRequestLeaveState(reqState);
    setLoading(false);
    setIsModalVisible(false);
    refetch();
  };
  const ActionButtonsCell = () => {
    return (
      <div className="flex items-end justify-end">
        <EditButton onClick={() => handleApproveRequest()}>
          {t('Requestleave.Approve')}
        </EditButton>

        <DeleteButton
          type="button"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          {t('Requestleave.Reject')}
        </DeleteButton>
      </div>
    );
  };

  const getColorizedState = (state: string) => {
    const frontendState = RequestLeaveState[state];
    const bgColor = stateColors[frontendState] || 'bg-gray-300';

    return (
      <div
        className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
      >
        {frontendState !== null ? t(`RequestLeaveState.${frontendState}`) : ''}
      </div>
    );
  };
  return isLoading || loading ? (
    <Spinner />
  ) : (
    <DefaultLayout>
      <Breadcrumb pageName={t('VacationTransferRequestDetails')} />
      <div className="p-8">
        {isModalVisible && (
          <DeleteConfirmationModal
            isOpen={isModalVisible}
            onDelete={handleRejectConfirmation}
            onCancel={handleCancelReject}
          />
        )}
        <div className="group relative z-0 mb-6 w-30">
          {getColorizedState(transferRequestLeaveDetail?.state!)}
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <Input
              name="fullNameColumn"
              type="text"
              value={transferRequestLeaveDetail?.employeeFullName}
              label={t('RequestLeaves.fullNameColumn')}
              readOnly
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <div className="group relative z-0 mb-6 w-full">
              <Input
                name="decisionDate"
                value={moment(transferRequestLeaveDetail?.decisionDate).format(
                  'L'
                )}
                label={t('DecisionDate')}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <Input
              name="title"
              type="text"
              value={transferRequestLeaveDetail?.fromVacationName}
              label={t('FromVacation')}
              readOnly
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <Input
              name="title"
              type="text"
              value={transferRequestLeaveDetail?.toVacationName}
              label={t('ToVacation')}
              readOnly
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <TextArea
              name="comment"
              id="comment"
              value={commentText}
              label={t('RequestLeaves.comment')}
              onChange={(e) => setCommentText(e.target.value)}
              readOnly={
                user?.isTeamLeader != 'True' ||
                transferRequestLeaveDetail?.state !=
                  RequestLeaveState.Pending.toString()
              }
            />
          </div>
          {/* <div className="group relative z-0 mb-6 w-full">
            <TextArea
              name="note"
              value={requestLeaveDetail?.note}
              label={t('RequestLeaves.note')}
              readOnly
            />
          </div> */}
        </div>
        {transferRequestLeaveDetail?.state! ==
          RequestLeaveState.Pending.toString() && <ActionButtonsCell />}
        {/* Display other properties */}
      </div>
    </DefaultLayout>
  );
}

export default TransferVacationRequestDetails;
