import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  getSubProjectMethod,
  saveSubProjectMethod,
  SubProjectMethodDto,
} from '@/services/SubProjectMethodsService';
import Dropdown, { Option } from '@/components/DropDown';
import { Currency, currencyOptions } from '@/enums/Currency';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@/state';
import {
  addSubProjectMethod,
  fetchSubProjectMethod,
  resetSubProjectForm,
  saveSubProjectMethodAsync,
  updateSubProjectMethod,
} from '@/state/slices/subProjectMethodSlice';
import { MultiStepFormProps } from '@/pages/Employee/EmployeeForm';
import Checkbox from '@/components/Checkbox';
import EditableDropdown, {
  EditableOption,
} from '@/components/EditableDropdown';
import {
  getSubProjectGroups,
  SubProjectGroup,
  SubProjectGroupResponse,
} from '@/services/SubProjectGroupsService';
import { resetForm } from '@/state/slices/employeeSlice';
import { getCompanyId } from '@/services/AuthService';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';

const validationSchema = Yup.object().shape({
  activity: Yup.string().required('SubProjectMethodForm.activityRequired'),
  description: Yup.string().required(
    'SubProjectMethodForm.descriptionRequired'
  ),
  cadences: Yup.string().required('SubProjectMethodForm.cadencesRequired'),
  workingHoursPerShift: Yup.number().required(
    'SubProjectMethodForm.workingHoursPerShiftRequired'
  ),
  shiftsPerDay: Yup.number().required(
    'SubProjectMethodForm.shiftsPerDayRequired'
  ),
  shiftsPerWeek: Yup.number().required(
    'SubProjectMethodForm.shiftsPerWeekRequired'
  ),
  weatherDowntimePerMonth: Yup.number().required(
    'SubProjectMethodForm.weatherDowntimePerMonthRequired'
  ),
  numberOfMachinesPerShift: Yup.number().required(
    'SubProjectMethodForm.numberOfMachinesPerShiftRequired'
  ),
  quantity: Yup.number().required('SubProjectMethodForm.quantityRequired'),
  productionRatePerMachine: Yup.number().required(
    'SubProjectMethodForm.productionRatePerMachineRequired'
  ),
  workshopStartDate: Yup.date().required(
    'SubProjectMethodForm.workshopStartDateRequired'
  ),
  workshopFinishDate: Yup.date().required(
    'SubProjectMethodForm.workshopFinishDateRequired'
  ),
  durationInDays: Yup.number().required(
    'SubProjectMethodForm.durationInDaysRequired'
  ),
  comments: Yup.string().required('SubProjectMethodForm.commentsRequired'),
  currency: Yup.string().required('SubProjectMethodForm.currencyRequired'),
  isSubConstructor: Yup.boolean().required(
    'SubProjectMethodForm.isSubConstructorRequired'
  ),
  subConstructorPrice: Yup.number().optional(),
  TotalHours: Yup.number().optional(),
});

const userTimeZone = moment.tz.guess();

function SubProjectDetailsForm({
  currentStep,
  setCurrentStep,
  stepsLength,
  setIsSubContructor,
}: MultiStepFormProps) {
  const { subProjectId, id } = useParams();
  const editForm = subProjectId !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { data: subProjectMethod, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );
  const [selectedGroupOption, setSelectedGroupOption] =
    useState<SubProjectGroup>({});

  const [initialValues, setInitialValues] = useState<SubProjectMethodDto>({
    id: subProjectMethod!.id,
    projectId: id!,

    activity: subProjectMethod!.activity,
    description: subProjectMethod!.description,
    cadences: subProjectMethod!.cadences,
    workingHoursPerShift: subProjectMethod!.workingHoursPerShift,
    shiftsPerDay: subProjectMethod!.shiftsPerDay,
    shiftsPerWeek: subProjectMethod!.shiftsPerWeek,
    weatherDowntimePerMonth: subProjectMethod!.weatherDowntimePerMonth,
    numberOfMachinesPerShift: subProjectMethod!.numberOfMachinesPerShift,
    quantity: subProjectMethod!.quantity,
    productionRatePerMachine: subProjectMethod!.productionRatePerMachine,
    workshopStartDate: subProjectMethod!.workshopStartDate,
    workshopFinishDate: subProjectMethod!.workshopFinishDate,
    durationInDays: subProjectMethod!.durationInDays,
    comments: subProjectMethod!.comments,
    currency: subProjectMethod!.currency, // Adjust as per your currency enum or string values
    subProjectGroupId: subProjectMethod!.subProjectGroupId,
    isSubConstructor: subProjectMethod!.isSubConstructor,
    subConstructorPrice: subProjectMethod!.subConstructorPrice,
    TotalHours: subProjectMethod!.TotalHours,
    groupTitle: subProjectMethod!.groupTitle,
    companyId: getCompanyId()!.toString(),
  });

  const { data: subProjectGroups, isLoading } = useQuery<
    SubProjectGroupResponse | undefined
  >(['subProjectGroups'], async () => {
    const response = await getSubProjectGroups({
      page: 0,
      take: 0,
      search: '',
    });
    return response;
  });

  const fetchSubProject = async (id?: string) => {
    if (editForm && subProjectId) {
      const response = await dispatch(fetchSubProjectMethod(subProjectId));
      console.log('🚀 ~ fetchSubProject ~ response:', response);
      var values = response.payload as SubProjectMethodDto;
      setInitialValues(values);
      setSelectedGroupOption({
        title: values.groupTitle,
        id: values.subProjectGroupId ?? values.groupTitle,
      });
      setIsSubContructor(values?.isSubConstructor ?? false);
      //setSelectedGroupOption(values.subProjectGroupId!);
    } else {
      // it is new
      const response = await dispatch(fetchSubProjectMethod(id));
      console.log('🚀 ~ fetchSubProject ~ response:', response);
      var values = response.payload as SubProjectMethodDto;
      setInitialValues(values);
      setSelectedGroupOption({
        title: values.groupTitle,
        id: values.subProjectGroupId ?? values.groupTitle,
      });
      setIsSubContructor(values?.isSubConstructor ?? false);
    }
  };
  useEffect(() => {
    fetchSubProject();
    return () => {
      // dispatch(resetSubProjectForm());
    };
  }, [dispatch, subProjectId, editForm]);

  const handleSaveSubProjectMethod = async (
    values: SubProjectMethodDto,
    { resetForm }: any
  ) => {
    try {
      const supP: SubProjectMethodDto = {
        ...values,
        subProjectGroupId: selectedGroupOption.id.includes('new')
          ? null
          : selectedGroupOption.id,
        companyId: getCompanyId()!.toString(),
      };
      console.log('🚀 ~ values:', supP);
      dispatch(updateSubProjectMethod(supP!));

      var subPMId = await dispatch(saveSubProjectMethodAsync(supP!));
      if (editForm) {
        await fetchSubProject();
      } else {
        toast.success(
          t('SuccessMessage', {
            name: t('ToastMsg.SubProjectMethod'),
          })
        );
        await fetchSubProject(subPMId.payload as string);
      }

      //dispatch(resetForm());

      // navigate('/gestion-resources-humains/gestion-des-absences/employees');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleClickNext = async () => {
    // save to backend with dispatch
    try {
      const values = {
        ...subProjectMethod!,
        subProjectGroupId: selectedGroupOption.includes('new')
          ? null
          : selectedGroupOption,
      };
      console.log('🚀 ~ handleClickNext ~ values:', values);

      // await dispatch(saveSubProjectMethodAsync(values!));

      toast.success(
        t('SuccessMessage', {
          name: t('ToastMsg.Employee'),
        })
      );
      //dispatch(resetForm());
      // navigate('/gestion-resources-humains/gestion-des-absences/employees');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const [selectedOption, setSelectedOption] = useState<any | null>(null);

  const handleDropdownChange = (option: any | null) => {
    console.log('🚀 ~ handleDropdownChange ~ option:', option);

    setSelectedOption(option);
  };

  return status == 'loading' ? (
    <Spinner />
  ) : (
    <div className="my-5">
      {
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveSubProjectMethod}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="activity"
                    id="activity"
                    label={t('SubProjectMethodForm.activityLabel')}
                    value={values.activity}
                    onChange={handleChange}
                    errors={errors.activity}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="description"
                    id="description"
                    label={t('SubProjectMethodForm.descriptionLabel')}
                    value={values.description}
                    onChange={handleChange}
                    errors={errors.description}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="cadences"
                    id="cadences"
                    label={t('SubProjectMethodForm.cadencesLabel')}
                    value={values.cadences}
                    onChange={handleChange}
                    errors={errors.cadences}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="workingHoursPerShift"
                    id="workingHoursPerShift"
                    label={t('SubProjectMethodForm.workingHoursPerShiftLabel')}
                    value={values.workingHoursPerShift.toString()}
                    onChange={handleChange}
                    errors={errors.workingHoursPerShift}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="shiftsPerDay"
                    id="shiftsPerDay"
                    label={t('SubProjectMethodForm.shiftsPerDayLabel')}
                    value={values.shiftsPerDay.toString()}
                    onChange={(e) => {
                      setFieldValue('shiftsPerDay', e.target.value);
                      var duraton =
                        values.quantity /
                        (parseInt(e.target.value) *
                          values.numberOfMachinesPerShift *
                          values.productionRatePerMachine);

                      setFieldValue('durationInDays', duraton);
                    }}
                    errors={errors.shiftsPerDay}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="shiftsPerWeek"
                    id="shiftsPerWeek"
                    label={t('SubProjectMethodForm.shiftsPerWeekLabel')}
                    value={values.shiftsPerWeek.toString()}
                    onChange={handleChange}
                    errors={errors.shiftsPerWeek}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="weatherDowntimePerMonth"
                    id="weatherDowntimePerMonth"
                    label={t(
                      'SubProjectMethodForm.weatherDowntimePerMonthLabel'
                    )}
                    value={values.weatherDowntimePerMonth.toString()}
                    onChange={handleChange}
                    errors={errors.weatherDowntimePerMonth}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="numberOfMachinesPerShift"
                    id="numberOfMachinesPerShift"
                    label={t(
                      'SubProjectMethodForm.numberOfMachinesPerShiftLabel'
                    )}
                    value={values.numberOfMachinesPerShift.toString()}
                    onChange={(e) => {
                      setFieldValue('numberOfMachinesPerShift', e.target.value);
                      var duraton =
                        values.quantity /
                        (values.shiftsPerDay *
                          parseInt(e.target.value) *
                          values.productionRatePerMachine);

                      console.log('🚀 ~ duraton:', duraton);
                      setFieldValue('durationInDays', duraton);
                      var endDate: Date = moment(values.workshopStartDate)
                        .add(Math.round(duraton), 'days')
                        .toDate();

                      setFieldValue('workshopFinishDate', endDate);
                    }}
                    errors={errors.numberOfMachinesPerShift}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="quantity"
                    id="quantity"
                    label={t('SubProjectMethodForm.quantityLabel')}
                    value={values.quantity.toString()}
                    onChange={(e) => {
                      setFieldValue('quantity', e.target.value);
                      var duraton =
                        parseInt(e.target.value) /
                        (values.shiftsPerDay *
                          values.numberOfMachinesPerShift *
                          values.productionRatePerMachine);

                      setFieldValue('durationInDays', duraton);
                      var endDate: Date = moment(values.workshopStartDate)
                        .add(Math.round(duraton), 'days')
                        .toDate();

                      setFieldValue('workshopFinishDate', endDate);
                    }}
                    errors={errors.quantity}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="productionRatePerMachine"
                    id="productionRatePerMachine"
                    label={t(
                      'SubProjectMethodForm.productionRatePerMachineLabel'
                    )}
                    value={values.productionRatePerMachine.toString()}
                    onChange={(e) => {
                      setFieldValue('productionRatePerMachine', e.target.value);
                      var duraton =
                        values.quantity /
                        (values.shiftsPerDay *
                          values.numberOfMachinesPerShift *
                          parseInt(e.target.value));

                      setFieldValue('durationInDays', duraton);

                      var endDate: Date = moment(values.workshopStartDate)
                        .add(Math.round(duraton), 'days')
                        .toDate();

                      setFieldValue('workshopFinishDate', endDate);
                    }}
                    errors={errors.productionRatePerMachine}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  {/* <DefaultInput
                    type="date"
                    name="workshopStartDate"
                    id="workshopStartDate"
                    label={t('SubProjectMethodForm.workshopStartDateLabel')}
                    value={moment(values.workshopStartDate).format(
                      'YYYY-MM-DD'
                    )}
                    onChange={(e) => {
                      setFieldValue('workshopStartDate', e.target.value);
                      var endDate: Date = moment(e.target.value)
                        .add(Math.round(values.durationInDays), 'days')
                        .toDate();
                      console.log('🚀 ~ endDate:', endDate);
                      setFieldValue('workshopEndDate', endDate);
                    }}
                    errors={errors.workshopStartDate}
                    labelClassName="pl-0 w-full"
                  /> */}

                  <ReactDatePickerDefault
                    name="workshopStartDate"
                    id="workshopStartDate"
                    labelClassName="w-[40%]"
                    label={t('SubProjectMethodForm.workshopStartDateLabel')}
                    value={values.workshopStartDate}
                    onChange={(date: Date) => {
                      // handleChange({
                      //   target: {
                      //     name: 'deadline',
                      //     value: deadline,
                      //   },
                      // });

                      setFieldValue('workshopStartDate', date);
                      var endDate: Date = moment(date)
                        .add(Math.round(values.durationInDays), 'days')
                        .toDate();

                      setFieldValue('workshopFinishDate', endDate);
                    }}
                    errors={errors['workshopStartDate']}
                  />
                </div>
                <div className="mb-4">
                  {/* <DefaultInput
                    type="date"
                    name="workshopFinishDate"
                    id="workshopFinishDate"
                    label={t('SubProjectMethodForm.workshopFinishDateLabel')}
                    value={moment(values.workshopFinishDate).format(
                      'YYYY-MM-DD'
                    )}
                    onChange={handleChange}
                    readOnly
                    errors={errors.workshopFinishDate}
                    labelClassName="pl-0 w-full"
                  /> */}
                  <ReactDatePickerDefault
                    name="workshopFinishDate"
                    id="workshopFinishDate"
                    labelClassName="w-[40%]"
                    label={t('SubProjectMethodForm.workshopFinishDateLabel')}
                    value={values.workshopFinishDate}
                    readOnly
                    errors={errors['workshopFinishDate']}
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="durationInDays"
                    id="durationInDays"
                    label={t('SubProjectMethodForm.durationInDaysLabel')}
                    value={values.durationInDays.toFixed(3)}
                    onChange={handleChange}
                    errors={errors.durationInDays}
                    readOnly
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="comments"
                    id="comments"
                    label={t('SubProjectMethodForm.commentsLabel')}
                    value={values.comments}
                    onChange={handleChange}
                    errors={errors.comments}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <Dropdown
                    options={currencyOptions}
                    name="currency"
                    id="currency"
                    label={t('SubProjectMethodForm.currencyLabel')}
                    value={values.currency}
                    onChange={handleChange}
                    error={errors.currency}
                    labelClassName="pl-0 w-full"
                  />
                </div>

                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="subConstructorPrice"
                    id="subConstructorPrice"
                    label={t('SubProjectMethodForm.subConstructorPriceLabel')}
                    value={values.subConstructorPrice?.toString() ?? ''}
                    onChange={handleChange}
                    errors={errors.subConstructorPrice}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  {/* <DefaultInput
                    type="number"
                    name="TotalHours"
                    id="TotalHours"
                    label={t('SubProjectMethodForm.TotalHoursLabel')}
                    value={values.TotalHours.toString()}
                    onChange={handleChange}
                    errors={errors.TotalHours}
                    labelClassName="pl-0 w-full"
                  /> */}
                </div>
                <div className="mb-4">
                  {/* <DefaultInput
                    type="checkbox"
                    name="isSubConstructor"
                    id="isSubConstructor"
                    label={t('SubProjectMethodForm.isSubConstructorLabel')}
                    checked={values.isSubConstructor}
                    onChange={handleChange}
                    error={errors.isSubConstructor}
                    labelClassName="pl-0 w-full"
                  /> */}
                  <div className="my-8 flex items-center ">
                    <Checkbox
                      name="isSubConstructor"
                      id="isSubConstructor"
                      label={t('SubProjectMethodForm.isSubConstructorLabel')}
                      checked={values.isSubConstructor}
                      onChange={(e) => {
                        setFieldValue('isSubConstructor', e.target.checked);
                        if (setIsSubContructor) {
                          setIsSubContructor(e.target.checked);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <EditableDropdown
                    name="subProjectGroupId"
                    id="subProjectGroupId"
                    label="Select an Option"
                    options={subProjectGroups?.groups ?? []}
                    optionLabel="title"
                    optionValue="id"
                    value={selectedGroupOption?.id ?? ''}
                    onChange={(id, title) => {
                      console.log('🚀 ~ title:', title, id);
                      setSelectedGroupOption({
                        title: title,
                        id: id!,
                      });
                      setFieldValue('groupTitle', title);
                    }}
                    error={errors.subProjectGroupId}
                  />
                </div>
              </div>
              <div className="mt-4 flex justify-end gap-5">
                <button
                  type="button"
                  className="mr-4 rounded bg-gray-300 px-4 py-2 text-gray-800 hover:bg-gray-400"
                  onClick={() => navigate(-1)}
                >
                  {t('SubProjectMethodForm.cancelButton')}
                </button>
                {/* <button
                  type="submit"
                  className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
                >
                  {t('stepper.next')}
                </button> */}

                <button
                  type="submit"
                  className="rounded bg-purple-600 px-4 py-2 text-white hover:bg-purple-700"
                >
                  {t('subProjectMaterialModel.Save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      }
    </div>
  );
}

export default SubProjectDetailsForm;
