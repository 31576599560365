import api from '@/utils/api';
import { DocumentRequestsUrl } from '@/variables/Urls';
import { getCompanyId } from './AuthService';
import { DocumentTypeEnum } from '@/enums/DocumentTypeEnum';
import { DocumentRequestStatus } from '@/enums/DocumentRequestStatus';

const apiEndpoint = DocumentRequestsUrl;

export interface DocumentRequest {
  id?: string;
  employeeId: string;
  employeeFullName: string;
  documentType: DocumentTypeEnum;
  sendingDate?: Date;
  decisionDate?: Date;
  comments: string;
  status: DocumentRequestStatus;
  companyId?: string;
}

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  employeeId?: string;
  year?: number;
  documentType?: DocumentTypeEnum;
  status?: DocumentRequestStatus;
}

export interface DocumentRequestResponse {
  documentRequests: DocumentRequest[];
  count: number;
}
export interface ChangeDocumentRequestStateProps {
  state: DocumentRequestStatus;
  id: string;
  comment?: string;
  //   adminId?: string;
}
export async function getDocumentRequests({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  year,
  documentType,
  status,
  employeeId,
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
      year: year,
      documentType: documentType,
      employeeId: employeeId,
      status: status,
    },
  };
  const { data } = await api.get(apiEndpoint + '/get', config);
  return data as DocumentRequestResponse;
}

// export async function getTrainingRequestsByEmployeeId({
//   fromvalue = 0,
//   takevalue = 10,
//   search = '',
//   employeeId,
// }: PaginatingParmas) {
//   let config = {
//     params: {
//       page: fromvalue,
//       take: takevalue,
//       search: search,
//       employeeId: employeeId,
//       year: new Date().getFullYear(),
//       // isFinished: true,
//     },
//   };
//   const { data } = await api.get(apiEndpoint + '/getByEmployeeId', config);
//   return data as TrainingRequestResponse;
// }
export async function getDocumentRequest(docRequestId: string) {
  const { data } = await api.get<DocumentRequest>(apiEndpoint + '/GetById', {
    params: { id: docRequestId },
  });

  return data;
}
export async function saveDocRequest(docRequest: DocumentRequest) {
  if (docRequest.id) {
    const body = { ...docRequest };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', docRequest);
  return response_1;
}

export async function changeDocRequestState({
  state,
  id,
  comment,
}: ChangeDocumentRequestStateProps) {
  try {
    const body = {
      id: id,
      comment: comment,
      state: state,
    };
    const response_1 = await api.post(apiEndpoint + '/ChangeState', body);
    return response_1;
    {
    }
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function deleteDocRequest(docRequestId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: docRequestId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
