import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import DefaultLayout from '@/layout/DefaultLayout';
import Spinner from '@/components/Spinner';
import OrgChartComponent from '@/components/OrgChartComponent';
import { EmployeesOrgChart, getEmpOrgChart } from '@/services/RapportsServices';
import { useQuery } from '@tanstack/react-query';
import { siteName } from '@/variables/Urls';

const isDevelopment = import.meta.env.MODE === 'development';

const OrganisationChart = () => {
  const [data, setData] = useState<any[]>([]);

  const { data: employeeOrgChart, isLoading } = useQuery(
    ['employeeOrgChart'],
    () => getEmpOrgChart(),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (employeeOrgChart) {
      // Convert the data to the specified format
      const convertedData = employeeOrgChart.employeesOrgCharts.map(
        (employee: EmployeesOrgChart) => ({
          id: employee.id,
          parentId: employee.parentId,
          name: employee.fullName,
          lastName: '', // Add your logic to extract last name if available
          position: employee.jobTitle,
          image: isDevelopment
            ? employee.profileImgUrl.includes('default')
              ? employee.profileImgUrl
              : siteName + employee.profileImgUrl
            : employee.profileImgUrl,
          email: employee.email,
          phone_number: '',
          hire_date: '', // Add your logic to extract hire date if available
          job_id: '', // Add your logic to extract job ID if available
          salary: '', // Add your logic to extract salary if available
          commission_pct: '', // Add your logic to extract commission percentage if available
          department_id: '', // Add your logic to extract department ID if available
          job_min_salary: '', // Add your logic to extract minimum job salary if available
          location_state: '', // Add your logic to extract location state if available
          job_max_salary: '', // Add your logic to extract maximum job salary if available
          department_name: employee.departmentName,
          department_location_id: '', // Add your logic to extract department location ID if available
          department_location_street_address: '', // Add your logic to extract department location street address if available
          department_location_postal_code: '', // Add your logic to extract department location postal code if available
          department_location_country_id: '', // Add your logic to extract department location country ID if available
          department_location_country_name: '', // Add your logic to extract department location country name if available
          department_location_country_region_id: '', // Add your logic to extract department location country region ID if available
          department_location_country_region_name: '', // Add your logic to extract department location country region name if available
        })
      );

      setData(convertedData);
    }
  }, [employeeOrgChart]);

  return (
    <DefaultLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        data && (
          <OrgChartComponent data={employeeOrgChart?.employeesOrgCharts} />
        )
      )}
    </DefaultLayout>
  );
};
export default OrganisationChart;
