import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Currency } from '@/enums/Currency';
import { PaymentStatus } from '@/enums/PaymentStatus';
import { TaskStatus } from '@/enums/TaskStatus';
import { companySubcontractorsURl } from '@/variables/Urls';

export interface CompanySubcontractor {
  name: string;
  contact: string;
  companyName: string;
  email: string;
  phone: string;
  address: string;
  countryId: number;
  taskDetail: string;
  amountHT: number;
  TVA: number;
  amountTTC: number;
  currency: Currency;
  startDate: Date;
  endDate: Date;
  status: TaskStatus;
  invoice: string;
  paymentStatus: PaymentStatus;
  serviceType: string;
  comment: string;
  companyId: string;
  id?: string;
}

export interface CompanySubcontractorsResponse {
  companySubcontractors: CompanySubcontractor[];
  count: number;
}

const apiEndpoint = companySubcontractorsURl; // Replace with your actual API endpoint

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}

export async function getCompanySubcontractors({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams): Promise<CompanySubcontractorsResponse> {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as CompanySubcontractorsResponse;
}

export async function getCompanySubcontractor(
  subcontractorId: string
): Promise<CompanySubcontractor> {
  const { data } = await api.get<CompanySubcontractor>(
    apiEndpoint + '/GetById',
    {
      params: { id: subcontractorId },
    }
  );

  return data;
}

export async function saveCompanySubcontractor(
  subcontractor: CompanySubcontractor
): Promise<AxiosResponse<any>> {
  if (subcontractor.id) {
    const body = { ...subcontractor };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', subcontractor);
  return response_1;
}

export function deleteCompanySubcontractor(
  subcontractorId: string
): Promise<AxiosResponse<any>> {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: subcontractorId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
