import { useMemo, useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  GreenButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';

import { useTranslation } from 'react-i18next';
import { RootState } from '@/state';
import {
  deleteSubProjectConsumable,
  saveSubProjectConsumablesAsync,
} from '@/state/slices/subProjectMethodSlice';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import SubProjectConsumableModal from './SubProjectConsumableModal';
import { formatCurrency } from '@/utils/helpers';
import { Currency } from '@/enums/Currency';
import {
  SubProjectConsumable,
  SubProjectConsumablesPayload,
} from '@/services/SubProjectMethodsService';

function SubProjectConsumables() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConsumableModal, setIsConsumableModal] = useState(false);
  const [initConsumableData, setInitConsumableDate] =
    useState<SubProjectConsumable>();
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { data, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );
  let projectCurrency = data?.currency ?? Currency.EUR;
  let subProjectId = data?.id;
  let subProjectConsumables = useMemo(
    () => data?.subProjectConsumables ?? [],
    [data, status]
  );

  const ActionButtonsCell = ({ row, value }: any) => (
    <div>
      <DeleteButton
        onClick={() => {
          setSelectedRow(value);
          setIsModalVisible(true);
        }}
      >
        {t('SubProjectConsumables.deleteButton')}
      </DeleteButton>
      <EditButton
        type="button"
        onClick={() => {
          setInitConsumableDate((pr) => row.original);
          setIsConsumableModal(true);
        }}
      >
        {t('SubProjectConsumables.editButton')}
      </EditButton>
    </div>
  );

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteSubProjectConsumable.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('SubProjectConsumables.consumableIdColumn'),
        accessor: 'name',
        Footer: (infos) => {
          return <>Total</>;
        },
      },
      {
        Header: t('SubProjectConsumables.descriptionColumn'),
        accessor: 'description',
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectConsumables.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell value={value} row={row} />
        ),
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectConsumables.isLocatedColumn'),
        accessor: 'isLocated',
        Cell: ({ cell: { value } }: any) => (
          <span>
            {value
              ? t('SubProjectConsumables.yes')
              : t('SubProjectConsumables.no')}
          </span>
        ),
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectConsumables.categoryColumn'),
        accessor: 'category',
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectConsumables.quantityColumn'),
        accessor: 'quantity',
        Footer: (infos) => {
          var total = useMemo(
            () => infos.rows.reduce((sum, row) => row.values.quantity + sum, 0),
            [infos.rows]
          );
          return <>{total}</>;
        },
      },
      {
        Header: t('SubProjectConsumables.unitPriceColumn'),
        accessor: 'unitPrice',
        Cell: ({ row, cell: { value } }: any) => {
          var currency = row.original['equipmentCurrency'];
          return <span> {formatCurrency(value, currency)} </span>;
        },
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectConsumables.totalPriceColumn'),
        accessor: 'totalPrice',
        Cell: ({ row, cell: { value } }: any) => {
          var currency = row.original['equipmentCurrency'];
          return <span> {formatCurrency(value, currency)} </span>;
        },
        Footer: (infos) => {
          var total = useMemo(
            () =>
              infos.rows.reduce(
                (sum, row) =>
                  parseFloat(row.values.totalPrice) + parseFloat(sum),
                0
              ),
            [infos.rows]
          );
          return <>{total.toFixed(4)}</>;
        },
      },
      {
        Header: t('SubProjectConsumables.exchangeRateColumn'),
        accessor: 'exchangeRate',
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectConsumables.TotalWithCurrencyColumn'),
        accessor: 'totalToCurrency',
        Cell: ({ cell: { value } }: any) => (
          <span> {formatCurrency(value, projectCurrency)} </span>
        ),
        Footer: (infos) => {
          var total = useMemo(
            () =>
              infos.rows.reduce(
                (sum, row) =>
                  parseFloat(row.values.totalToCurrency) + parseFloat(sum),
                0
              ),
            [infos.rows]
          );
          return <>{formatCurrency(total.toFixed(4), projectCurrency)}</>;
        },
      },
      {
        Header: t('SubProjectConsumables.commentsColumn'),
        accessor: 'comment',
      },
    ],
    [t, projectCurrency]
  );

  const queryClient = useQueryClient();

  const handleDeleteSubProjectConsumable = useMutation(
    async (id: string) => {
      await dispatch(deleteSubProjectConsumable(id));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'subProjectConsumables',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((subProjectConsumables?.length ?? 0) / pageSize);
  }, [pageSize, subProjectConsumables]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const handleSaveConsumables = async () => {
    var payload: SubProjectConsumablesPayload = {
      subProjectConsumables: subProjectConsumables.map((sm) => ({
        ...sm,
        id: sm.id?.includes('new') ? null : sm.id,
      })),
      subProjectMethodId: data?.id!,
    };
    const result = await dispatch(saveSubProjectConsumablesAsync(payload));
  };

  return (
    <div>
      <PrimaryButton
        type="button"
        onClick={() => {
          setInitConsumableDate(undefined);
          setIsConsumableModal(true);
        }}
        className="mt-5"
      >
        {t('expenses.add')}
      </PrimaryButton>

      {subProjectId && (
        <GreenButton
          type="button"
          onClick={handleSaveConsumables}
          className="mt-5"
        >
          {t('stepper.save')}
        </GreenButton>
      )}
      {status == 'loading' ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={subProjectConsumables?.length}
            data={subProjectConsumables ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
            exportExcel={false}
            hideToolbar={true}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {isConsumableModal && (
            <SubProjectConsumableModal
              closeModal={() => setIsConsumableModal(false)}
              initialData={initConsumableData}
            />
          )}
        </div>
      )}
      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        gotoPage={onPageChange}
        canNextPage={pageIndex < pageCount - 1}
        canPreviousPage={pageIndex > 0}
        nextPage={nextPage}
        previousPage={previousPage}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
}

export default SubProjectConsumables;
