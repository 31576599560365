import api from '@/utils/api';
import { PlanningAOTasksUrl } from '@/variables/Urls';

const apiEndpoint = PlanningAOTasksUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface PlanningTaskResponse {
  planningTasks: PlanningTask[];
  count: number;
}

export interface PlanningTask {
  id?: string;
  taskName: string;
  startDate: Date;
  endDate: Date;
  duration: number;
  progress?: number;
  responsibleId: string;
  responsibleName?: string;
  comments?: string;
  projectId: string;
}

export async function getPlanningTasksByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<PlanningTaskResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export async function savePlanningTask(task: PlanningTask) {
  if (task.id) {
    const response = await api.put(apiEndpoint + '/put', task);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', task);
  return response;
}

export async function getPlanningTask(id: string) {
  const { data } = await api.get<PlanningTask>(apiEndpoint + '/GetById', {
    params: { id: id },
  });

  return data;
}

export function deletePlanningTask(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
