export enum ProjectContractType {
  Base,
  Forfait,
  Bordereau,
  PriceUnit,
  Other,
}

export const ProjectContractTypeOptions = Object.keys(ProjectContractType)
  .filter((key) => isNaN(Number(ProjectContractType[key])))
  .map((key) => ({
    id: key,
    name: ProjectContractType[key],
  }));
