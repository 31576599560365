import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Breadcrumb from '../components/Breadcrumb';
import DefaultLayout from '../layout/DefaultLayout';
import Spinner from '@/components/Spinner';
import DefaultInput from '@/components/Input';
import {
  UserProfile,
  getUser,
  saveUser,
  updateUser,
} from '@/services/UserService';
import { useQuery } from '@tanstack/react-query';
import { getJwt } from '@/services/AuthService';
import { useTranslation } from 'react-i18next';
import ImageUploader from '@/components/ImagePicker';
import { Image, getImageByUserId, updateImage } from '@/services/ImageService';
import { useAuth } from '@/context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  email: string;
  oldPassword?: string;
  newPassword?: string;
  userName: string;
  id: string;
  profilePicture?: string;
}

const validationSchema = Yup.object().shape({
  userName: Yup.string().required('profile.UserNameIsRequired'),
  email: Yup.string()
    .email('profile.InvalidEmail')
    .required('profile.EmailIsRequired'),
});

const Profile = () => {
  const { t } = useTranslation(); // Initialize the i18n translation hook with the 'profile' namespace
  const jwt = getJwt();
  const [loading, setLoading] = useState(false);
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState<string | undefined>('');
  const {
    isLoading,
    isError,
    data: userProfileData,
    isSuccess: isUserSuccess,
  } = useQuery<UserProfile | undefined>(
    ['user', jwt],
    () => {
      return getUser(jwt!);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    data: imageData,
    refetch,
    isSuccess,
  } = useQuery<string | undefined>(
    ['image', userProfileData?.id],
    () => {
      return getImageByUserId(userProfileData?.id!, currentUser?.role!);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  // useEffect(() => {
  //   if (isUserSuccess && currentUser) {
  //     if (currentUser.role && userProfileData?.id) {
  //       console.error('iamges data ', imageData);
  //       refetch();
  //     }
  //   }
  // }, [isUserSuccess, currentUser, userProfileData]);

  useEffect(() => {
    if (isSuccess && imageData) {
      setProfilePicture(imageData);
    }
  }, [isSuccess, imageData]);

  const handleSaveUser = async (values: FormValues, { setSubmitting }: any) => {
    try {
      setLoading(true);

      const data = await updateUser(values);
      // update image
      if (data) {
        const imageData = updateImage({
          role: currentUser?.role!,
          url: values.profilePicture!,
          userId: currentUser?.id! ?? data,
        });
      }
      setLoading(false);
      refetch();
      navigate(-1);
    } catch (error) {
      console.error('Error updating user:', error);
      setLoading(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('profile.Profile')} />
      {loading && isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={{
            email: userProfileData?.email || '',
            oldPassword: '',
            newPassword: '',
            userName: userProfileData?.userName || '',
            id: userProfileData?.id || '',
            profilePicture: profilePicture ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSaveUser}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            resetForm,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6 ">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="userName"
                    id="userName"
                    placeholder=" "
                    label={t('profile.UserName')}
                    onChange={handleChange}
                    errors={errors}
                    value={values.userName}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder=" "
                    label={t('profile.Email')}
                    onChange={handleChange}
                    errors={errors}
                    value={values.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6 ">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    placeholder=""
                    label={t('profile.OldPassword')}
                    onChange={handleChange}
                    errors={errors}
                    value={values.oldPassword}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    placeholder=" "
                    label={t('profile.NewPassword')}
                    onChange={handleChange}
                    errors={errors}
                    value={values.newPassword}
                  />
                </div>
              </div>
              <div className="relative z-20 col-span-2 grid w-[90%] px-5 py-2">
                <ImageUploader
                  name="profilePicture"
                  handleChange={handleChange}
                  id="profilePicture"
                  multiple={false}
                  value={values.profilePicture ?? ''}
                />
              </div>
              <button
                type="submit"
                className="focus:shadow-outline
                  float-right
                  mb-4
                  rounded-sm
                  bg-blue-700
                  px-3 py-1
                  text-white hover:bg-blue-500 focus:outline-none"
                disabled={isSubmitting}
              >
                {t('profile.Submit')}
              </button>
              <button
                type="button"
                id="cancelProfile"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('profile.Cancel')}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
};

export default Profile;
