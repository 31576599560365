import { ExpenseCategory } from '@/enums/ExpenseCategory';
import { ProjectExpensesDataModel } from '@/models/CompanyRHDataModel';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectExpensesDataTable: React.FC<{
  data: ProjectExpensesDataModel[] | undefined;
}> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4 flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ProjectExpensesDataTable.metric')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ProjectExpensesDataTable.total')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
                {data?.map((item, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-6 py-4">
                      {t(`ExpenseCategory.${ExpenseCategory[item.category]}`)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {item.total}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectExpensesDataTable;
