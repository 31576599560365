export enum MeetingType {
  ExternalWithSuppliers,
  ExternalWithSubcontractors,
  ExternalWithPartners,
  ExternalWithClient,
  InternalService,
  InternalEntity,
  Internal,
}

export const MeetingTypeOptions = Object.keys(MeetingType)
  .filter((key) => isNaN(Number(MeetingType[key])))
  .map((key) => ({
    id: key,
    name: MeetingType[key],
  }));
