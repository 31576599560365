import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { JobHistoryModel } from '@/services/JobDescriptionService';
import { useTranslation } from 'react-i18next';
import DefaultInput from '@/components/Input'; // Update the path
import DatePickerDefault from '@/components/DatePicker';
import { LabelButton } from '@/components/shared/Button';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  addJobHistory,
  updateJobHistory,
} from '@/state/slices/jobdescriptionSlice';

interface JobHistoriesFormProps {
  initialValues?: JobHistoryModel;
  onSubmit: (values: JobHistoryModel) => void;
  handleClose: () => void;
  edit?: boolean;
}

const currentDate = new Date();
const startDateDefault = new Date(currentDate);
startDateDefault.setDate(currentDate.getDate() - 2); // Two days before the current date
const endDateDefault = new Date(currentDate);
endDateDefault.setDate(currentDate.getDate() - 1);
const initialStateValues = {
  position: '',
  startDate: startDateDefault || new Date(),
  endDate: endDateDefault || new Date(),
  employerName: '',
  employerAddress: '',
  reasonForLeaving: '',
};

const JobHistoriesForm: React.FC<JobHistoriesFormProps> = ({
  initialValues,
  onSubmit,
  handleClose,
  edit = false,
}) => {
  console.log('🚀 ~ initialValues:', initialValues);
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const validationSchema = Yup.object().shape({
    position: Yup.string().required(t('jobHistories.errors.required')),
    startDate: Yup.date()

      .required(t('jobHistories.errors.startDateRequired'))
      .max(new Date(), t('jobHistories.errors.startDateFuture')), // Start date should not be in the future
    endDate: Yup.date()
      .required(t('jobHistories.errors.endDateRequired'))
      .when('startDate', (startDate, schema) => {
        return schema.test({
          name: 'endDateAfterStartDate',
          exclusive: true,
          message: t('jobHistories.errors.endDateAfterStartDate'),
          test: function (endDate) {
            // Check if endDate is after startDate
            return new Date(endDate) > new Date(startDate);
          },
        });
      })
      .max(new Date(), t('jobHistories.errors.endDateFuture')),
    employerName: Yup.string().required(t('jobHistories.errors.required')),
    employerAddress: Yup.string().required(t('jobHistories.errors.required')),
  });

  const handleSubmit = (values: JobHistoryModel) => {
    if (!!initialValues) {
      dispatch(updateJobHistory({ jobHistory: values }));
    } else {
      dispatch(addJobHistory({ jobHistory: values }));
    }
    onSubmit(values);
  };

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white bg-opacity-20 dark:bg-gray-700 dark:bg-opacity-20">
      <div className=" mt-20 max-h-full w-[50%] overflow-y-auto rounded bg-white p-6 shadow-md dark:bg-gray-700">
        <div className="mb-4 text-2xl font-semibold">
          {!!initialValues
            ? t('jobHistories.labels.edit')
            : t('jobHistories.labels.add')}
        </div>

        <Formik
          initialValues={initialValues || initialStateValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isValid }) => (
            <Form>
              <DefaultInput
                id="position"
                type="text"
                name="position"
                label={t('jobHistories.placeholders.position')}
                onChange={handleChange}
                value={values.position ?? ''}
                errors={errors.position}
              />

              <div className="mt-2 grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-50 mb-6 w-full">
                  <DatePickerDefault
                    id="startDate"
                    name="startDate"
                    label={t('jobHistories.startDate')}
                    value={values.startDate}
                    onChange={(date: any) => {
                      handleChange({
                        target: {
                          name: 'startDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.startDate}
                  />
                </div>
                <div className="group relative z-50  mb-6 w-full">
                  <DatePickerDefault
                    id="endDate"
                    name="endDate"
                    label={t('jobHistories.endDate')}
                    value={values.endDate}
                    onChange={(date: any) => {
                      handleChange({
                        target: {
                          name: 'endDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.endDate}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    id="employerName"
                    type="text"
                    labelClassName="w-[50%]"
                    name="employerName"
                    label={t('jobHistories.placeholders.employerName')}
                    onChange={handleChange}
                    value={values.employerName ?? ''}
                    errors={errors.employerName}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    id="employerAddress"
                    labelClassName="w-[50%]"
                    type="text"
                    name="employerAddress"
                    label={t('jobHistories.placeholders.employerAddress')}
                    onChange={handleChange}
                    value={values.employerAddress ?? ''}
                    errors={errors.employerAddress}
                  />
                </div>
              </div>

              <DefaultInput
                id="reasonForLeaving"
                type="text"
                name="reasonForLeaving"
                label={t('jobHistories.placeholders.reasonForLeaving')}
                onChange={handleChange}
                value={values.reasonForLeaving ?? ''}
                errors={errors.reasonForLeaving}
              />

              <div className="mt-2 flex items-center justify-between">
                <button
                  type="button"
                  onClick={(e) => handleSubmit(values)}
                  disabled={!isValid}
                  className="mb-2 mr-2 rounded-lg border border-primary-700 px-5 py-2.5 text-center text-sm font-medium text-primary-700 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300 dark:border-primary-500 dark:text-primary-500 dark:hover:bg-primary-600 dark:hover:text-white dark:focus:ring-primary-800"
                >
                  {t('jobHistories.buttons.save')}
                </button>

                <LabelButton type="button" onClick={handleClose}>
                  {t('jobHistories.buttons.cancel')}
                </LabelButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default JobHistoriesForm;
