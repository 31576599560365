export enum InterfaceType {
  Technical,
  Contractual,
  Logistical,
  Financial,
  Organizational,
  Communication,
  Quality,
  Safety,
  Client,
  Environmental,
  Regulatory,
  Planning,
  Commercial,
  Design,
}

export const InterfaceTypeOptions = Object.keys(InterfaceType)
  .filter((key) => isNaN(Number(InterfaceType[key])))
  .map((key) => ({
    id: key,
    name: InterfaceType[key],
  }));
