import React, { useEffect, useMemo, useState } from 'react';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import Accordion from '@/components/Accordian';
import { useAuth } from '@/context/AuthContext';
import {
  Employee,
  EmployeesResponse,
  getEmployee,
  getEmployees,
} from '@/services/EmployeeService';
import { ResourceType, Timeline } from '@/components/TimeLine';
import { useTranslation } from 'react-i18next';
import { convertEmployeesToResources } from '@/utils/CalendarHelper';
// Rest of your imports...
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import {
  getRequestLeavesByEmployee,
  getRequestLeavesByTeamLeaderId,
} from '@/services/RequestLeavesService';
import { getVacationBalances } from '@/services/VacationBalanceService';
import { getTransferRequestsByEmployeeId } from '@/services/VacationBalanceTransferRequestService';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { UserRoles } from '@/enums/UsersRole';
import {
  DepartemntCalendar,
  EmployeeRequestLeavesTable,
  VacationsBalancesTable,
  TransferRequestsVacationTable,
  RequestsLeaveValidateTable,
  EmployeeCalendar,
} from './components';
import {
  getWorkCalendar,
  getWorkCalendarByEmployeeId,
} from '@/services/WorkCalendarsService';
import EmployeeTransferRequestsVacationTable from './components/EmployeeTransferRequestsVacationTable';
import ValidateTransferRequestsVacationTable from './components/ValidateTransferRequestsVacationTable';
import TabNavigation, { Tab } from '@/components/TabNavigation';

function AbsensePannel() {
  const { user, loading } = useAuth();
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
  };
  const { data: employeeData, isLoading: employeeLoading } = useQuery(
    ['employee', user?.id],
    () => getEmployee(user?.id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: !!user?.id,
    }
  );
  const { data: empWorkCalendar, isLoading: empWorkCalendarLoading } = useQuery(
    ['empWorkCalendar', user?.id],
    () => getWorkCalendarByEmployeeId(user?.id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: !!user?.id,
    }
  );

  const tabContents: Tab[] = [
    {
      index: 0,
      title: t('AbsensePanel.MyCalendar'),
      content: (
        <>
          {employeeLoading || empWorkCalendarLoading || !employeeData ? (
            <Spinner />
          ) : (
            <EmployeeCalendar
              employeeData={employeeData}
              workCalendar={empWorkCalendar!}
            />
          )}
        </>
      ),
    },
    {
      index: 1,
      title: t('AbsensePanel.StatusOfMyRequests'),
      content: <EmployeeRequestLeavesTable />,
    },
    {
      index: 2,
      title: t('AbsensePanel.VacationBalance'),
      content: <VacationsBalancesTable />,
    },
    {
      index: 3,
      title: t('AbsensePanel.RequestStaffLeaveCarriedOver'),
      content: <EmployeeTransferRequestsVacationTable />,
    },

    {
      index: 4,
      title: t('AbsensePanel.DepartementCalendars'),
      content: <DepartemntCalendar />,
    },
  ];
  const teamleaderTab: Tab[] = [
    {
      index: 0,
      title: t('AbsensePanel.MyCalendar'),
      content: (
        <>
          {employeeLoading || empWorkCalendarLoading ? (
            <Spinner />
          ) : (
            <EmployeeCalendar
              employeeData={employeeData!}
              workCalendar={empWorkCalendar!}
            />
          )}
        </>
      ),
    },
    {
      index: 1,
      title: t('AbsensePanel.StatusOfMyRequests'),
      content: <EmployeeRequestLeavesTable />,
    },
    {
      index: 2,

      title: t('AbsensePanel.VacationBalance'),
      content: <VacationsBalancesTable />,
    },
    {
      index: 3,
      title: t('AbsensePanel.RequestStaffLeaveCarriedOver'),
      content: <EmployeeTransferRequestsVacationTable />,
    },
    {
      index: 4,
      title: t('AbsensePanel.StatusOfTeamRequests'),
      content: <RequestsLeaveValidateTable />,
    },
    {
      index: 5,
      title: t('AbsensePanel.StatusOfTeamTransferRequests'),
      content: <ValidateTransferRequestsVacationTable />,
    },
    {
      index: 6,
      title: t('AbsensePanel.DepartementCalendars'),
      content: (
        <>
          {employeeLoading || empWorkCalendarLoading ? (
            <Spinner />
          ) : (
            <DepartemntCalendar workCalendar={empWorkCalendar!} />
          )}
        </>
      ),
    },
  ];
  const activeTabs = user?.isTeamLeader == 'True' ? teamleaderTab : tabContents;

  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('AbsensePanel.pageName')} />
      <div className="min-h-screen dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark ">
        <div className=" mx-auto py-8">
          <div className="space-y-4">
            <TabNavigation
              tabs={activeTabs}
              activeIndex={activeTabIndex}
              onTabChange={handleTabChange}
            />
            {user?.isTeamLeader == 'True'
              ? teamleaderTab[activeTabIndex].content
              : tabContents[activeTabIndex].content}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default AbsensePannel;
