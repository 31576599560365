import React, { useState } from 'react';
import { RelationnelModel } from '@/models/RelationnelModel';
import { SkillLevel } from '@/enums/SkillLevel';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRepresentation,
  saveAnnualInterviewAsync,
  updateRelationnel,
} from '@/state/slices/annualInterviewSlice';
import { useFormik } from 'formik';
import TextArea from '@/components/TextArea';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { InterviewState } from '@/enums/InterviewState';

const init: RelationnelModel = {
  id: '',
  internalRelations: SkillLevel.Bien,
  availabilityToOthers: SkillLevel.Correct,
  overallContributionToAmbiance: SkillLevel.Excellent,
  responsivenessToRequests: SkillLevel.Excellent,
  understandingOfGlobalIssues: SkillLevel.Correct,
};

const RelationnelTable = () => {
  const { user } = useAuth();

  const { t } = useTranslation();
  const relationnel = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview.relationnel
  );
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const [relationnelLevels, setRelationnelLevels] = useState(
    relationnel ?? init
  );

  const disabled = annualInerview.interviewState == InterviewState.Completed;
  const relationnelKeys = Object.keys(relationnelLevels) as Array<
    keyof RelationnelModel
  >;
  const nonNumericKeys = Object.keys(SkillLevel).filter(
    (key) => !isNaN(Number(SkillLevel[key]))
  ) as Array<keyof typeof SkillLevel>;

  const formik = useFormik({
    initialValues: {
      ...init,
      ...relationnel,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(updateRelationnel({ relationnel: values }));
    },
  });
  const handleSkillLevelChange = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3"></th>
              {nonNumericKeys.map((level: keyof typeof SkillLevel) => (
                <th key={level} scope="col" className="px-6 py-3">
                  {t(`SkillLevel.${level}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {relationnelKeys
              .filter(
                (objective) =>
                  objective !== 'id' &&
                  objective !== 'annualInterviewId' &&
                  objective !== 'annualInterview' &&
                  objective !== 'specificAbilities' &&
                  objective !== 'comments'
              )
              .map((rel: keyof RelationnelModel) => (
                <tr
                  key={rel}
                  className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <th scope="row" className="px-6 py-4 font-medium">
                    {t(`annualInterviewForm.Relationnel.${rel}`)}
                  </th>
                  {nonNumericKeys.map((level: keyof typeof SkillLevel) => (
                    <td key={level} className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={formik.values[rel] === SkillLevel[level]}
                        onChange={() =>
                          handleSkillLevelChange(rel, SkillLevel[level])
                        }
                        disabled={!isTeamLeader || disabled}
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        <div className="group relative mb-6 w-full">
          <TextArea
            id="specificAbilities"
            name="specificAbilities"
            label={t('annualInterviewForm.Relationnel.specificAbilities')}
            value={formik.values.specificAbilities ?? ''}
            onChange={formik.handleChange}
            disabled={!isTeamLeader || disabled}
          />
        </div>
        <div className="group relative mb-6 w-full">
          <TextArea
            id="comments"
            name="comments"
            label={t('annualInterviewForm.Relationnel.comments')}
            value={formik.values.comments ?? ''}
            onChange={formik.handleChange}
            disabled={isTeamLeader || disabled}
          />
        </div>
        {!disabled && (
          <div className="flex justify-between">
            <LabelButton type="button" onClick={() => resetForm()}>
              {t('annualInterviewForm.Relationnel.reset')}
            </LabelButton>
            <PrimaryButton
              className="border-0 bg-purple-300 dark:bg-purple-500"
              type="button"
              onClick={handleSyncData}
            >
              <div className="flex items-center gap-2">
                <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
                {t('annualInterviewForm.Relationnel.Sync')}
              </div>
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              className="border-0 bg-primary-300 dark:bg-primary-500"
            >
              {t('annualInterviewForm.Relationnel.submit')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </form>
  );
};

export default RelationnelTable;
