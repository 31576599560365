import React, { useState } from 'react';
import JobHistoriesForm from './JobHistoriesForm';
import { JobHistoryModel } from '@/services/JobDescriptionService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { removeJobHistory } from '@/state/slices/jobdescriptionSlice';

interface JobHistoriesTableProps {
  jobHistories: JobHistoryModel[];
  readonly?: boolean;
}

const JobHistoriesTable: React.FC<JobHistoriesTableProps> = ({
  jobHistories,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editJobHistory, setEditJobHistory] = useState<JobHistoryModel | null>(
    null
  );
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const handleAddClick = () => {
    setEditJobHistory(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (jobHistory: JobHistoryModel) => {
    setEditJobHistory(jobHistory);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (jobHistoryId: string) => {
    dispatch(removeJobHistory({ jobHistoryId: jobHistoryId }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditJobHistory(null);
  };

  const handleSaveJobHistory = (jobHistory: JobHistoryModel) => {
    // Handle save logic
    // Call the API or dispatch an action to save the job history
    closeModal();
  };

  return (
    <div className="w-full overflow-auto">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
        {/* Table headers */}
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3">{t('jobHistories.jobTitle')}</th>
            <th className="px-6 py-3">{t('jobHistories.startDate')}</th>
            <th className="px-6 py-3">{t('jobHistories.endDate')}</th>
            <th className="px-6 py-3">{t('jobHistories.employerName')}</th>
            <th className="px-6 py-3">{t('jobHistories.employerAddress')}</th>
            <th className="px-6 py-3">{t('jobHistories.reasonForLeaving')}</th>
            {!readonly && (
              <th className="px-6 py-3">{t('jobHistories.actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {jobHistories.map((jobHistory) => (
            <tr
              key={jobHistory.id}
              className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <td className="px-6 py-3">{jobHistory.position}</td>
              <td className="px-6 py-3">
                {moment(jobHistory.startDate ?? new Date()).format(
                  'DD/MM/YYYY'
                )}
              </td>
              <td className="px-6 py-3">
                {moment(jobHistory.endDate ?? new Date()).format('DD/MM/YYYY')}
              </td>
              <td className="px-6 py-3">{jobHistory.employerName}</td>
              <td className="px-6 py-3">{jobHistory.employerAddress}</td>
              <td className="px-6 py-3">{jobHistory.reasonForLeaving}</td>
              {!readonly && (
                <td className="flex px-6 py-3">
                  <button
                    type="button"
                    onClick={() => handleEditClick(jobHistory)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {t('jobHistories.edit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteClick(jobHistory.id!)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    {t('jobHistories.delete')}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {!readonly && (
        <div className="my-4 flex justify-end">
          <button
            type="button"
            onClick={handleAddClick}
            className="mb-2 mr-2 rounded-lg bg-green-500 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {t('jobHistories.add')}
          </button>
        </div>
      )}

      {isModalOpen && (
        <JobHistoriesForm
          initialValues={editJobHistory || undefined}
          onSubmit={handleSaveJobHistory}
          handleClose={closeModal}
        />
      )}
    </div>
  );
};

export default JobHistoriesTable;
