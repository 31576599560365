import { configureStore } from '@reduxjs/toolkit';
import employeeSlice from './slices/employeeSlice';
import jobdescriptionSlice from './slices/jobdescriptionSlice';
import annualInterviewSlice from './slices/annualInterviewSlice';
import projectExpensesSlice from './slices/projectExpensesSlice';
import subProjectMethodSlice from './slices/subProjectMethodSlice';

export const store = configureStore({
  reducer: {
    employees: employeeSlice,
    jobDescriptions: jobdescriptionSlice,
    annualInterviews: annualInterviewSlice,
    projectExpenses: projectExpensesSlice,
    subProjectMethod: subProjectMethodSlice,
    // users: usersReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
