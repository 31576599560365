import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  BranchesResponse,
  deleteBranch,
  getBranches,
} from '@/services/BranchService';
import { useTranslation } from 'react-i18next';

function Branches() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('Branches.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('Branches.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteBranch.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Branches.titleColumn'),
        accessor: 'title',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('Branches.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell value={value} handleDelete={handleDeleteBranch} />
        ),
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: branches,
  } = useQuery<BranchesResponse | undefined>(
    ['branches', pageIndex, pageSize, search],
    () =>
      getBranches({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const handleDeleteBranch = useMutation(
    async (id: string) => {
      await deleteBranch(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'branches',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((branches?.count ?? 0) / pageSize);
  }, [pageSize, branches]);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('Branches.pageName')}
        addbtn={true}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={branches?.count}
            data={branches?.branches ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default Branches;
