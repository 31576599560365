import { SubjectStatus } from '@/enums/SubjectStatus';
import api from '@/utils/api';
import { MeetingSubjectUrl } from '@/variables/Urls';

const apiEndpoint = MeetingSubjectUrl;

export interface PaginatingParmas {
  page?: number;
  take?: number;
  search?: string;
  meetingId: string;
}
export interface MeetingSubject {
  id: string;
  responsibleId: string;
  responsibleName: string;
  projectMeetingId: string;
  description: string;
  responsibleComments: string;
  responsibleSubjectProgress: number;
  deadline: Date;
  comments: string;
  progressPercentage: number;
  status: SubjectStatus;
}

export interface MeetingSubjectsResponse {
  meetingSubjects: MeetingSubject[];
  count: number;
}

export async function saveMeetingSubject(meetingSubject: MeetingSubject) {
  if (meetingSubject.id) {
    const body = { ...meetingSubject };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', meetingSubject);
  return response_1;
}

export async function getMeetingSubject(id: string) {
  const { data } = await api.get<MeetingSubject>(apiEndpoint + '/GetById', {
    params: { id },
  });

  return data;
}

export async function getMeetingSubjectsByProjectMeetingId({
  meetingId,
  page,
  take,
  search,
}: PaginatingParmas) {
  let config = {
    params: {
      meetingId,
      page,
      take,
      search,
    },
  };
  const { data } = await api.get<MeetingSubjectsResponse>(
    apiEndpoint + '/GetByMeetingId',
    config
  );

  return data;
}

export function deleteMeetingSubject(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
