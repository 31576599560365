import { ResourceType } from '@/components/TimeLine';
import { getVacationColor } from '@/enums/VacationTypeEnum';
import { Employee } from '@/services/EmployeeService';
import { PlanningTask } from '@/services/PlanningAOTasksService';
import { RequestLeave } from '@/services/RequestLeavesService';

export function getDaysData(date: Date, language: string) {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const days = [];
  const today = new Date();

  for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
    const dayNumber = day.getDate();
    const dayOfWeek = day
      .toLocaleDateString(language, { weekday: 'short' })
      .replace(/\.$/, '');

    const capitalizedAbbreviatedWeekday =
      dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1); // Use the selected language for day names

    const isToday = day.toDateString() === today.toDateString();

    days.push({
      day: dayNumber,
      dayOfWeek: capitalizedAbbreviatedWeekday,
      isToday,
    });
  }

  return days;
}

export const convertEmployeesToResources = (
  employees: Employee[]
): ResourceType[] => {
  return employees.map((emp: Employee, index) => {
    return {
      id: emp.id!,
      title: emp.email || '',
      name: emp.firstName + ' ' + emp.lastName || '',
      avatar: emp.profileImgUrl || '',
    };
  });
};

export const convertRequestLeavesToEvents = (requestLeaves: RequestLeave[]) => {
  return requestLeaves.map((requestLeave) => ({
    start: new Date(requestLeave.fromDate!),
    end: new Date(requestLeave.toDate!),
    title: requestLeave.vacationName!,
    resourceId: requestLeave.employeeId!,
    color: getVacationColor(requestLeave.vacationName!),
    tooltip: requestLeave.note,
  }));
};
