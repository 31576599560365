import { BranchesUrl, CompaniesUrl, DeparetmentsUrl } from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Departement } from './DepartementService';

export interface Branch {
  title: string;
  id: string;
  companyId?: string;
  departments?: Departement[];
}

export interface BranchesResponse {
  branches: Branch[];
  count: number;
}
const apiEndpoint = BranchesUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getBranches({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetByCompanyId', config);
  return data as BranchesResponse;
}

export async function getBranch(branchId: string) {
  const { data } = await api.get<Branch>(apiEndpoint + '/GetById', {
    params: { id: branchId },
  });

  return data;
}

export async function saveBranch(branch: Branch) {
  if (branch.id) {
    const body = { ...branch };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', branch);
  return response_1;
}

export function deleteBranch(branchId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: branchId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
