import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import {
  RequestLeave,
  saveRequestLeave,
} from '@/services/RequestLeavesService';
import { useAuth } from '@/context/AuthContext';
import { getVacationBalancesByEmployeeId } from '@/services/VacationBalanceService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import {
  EditButton,
  LabelButton,
  PrimaryButton,
} from '@/components/shared/Button';
import TextArea from '@/components/TextArea';
import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays } from 'date-fns';
import { default as DefaultInput } from '@/components/Input';
import { toast } from 'react-hot-toast';
import { Appointment, saveAppointment } from '@/services/AppointmentService';

const ApppointmentResultSchema = Yup.object().shape({});
interface AppointmentResultProps {
  onSave: () => void;
  isOpen: boolean;
  onCancel: () => void;
  appointment: Appointment;
}

export interface AppointmentResult {
  comment: string | null;
  visiteMedicaleDone: boolean;
  apte: boolean;
}

const AppointmentResultModal = ({
  onSave,
  isOpen,
  onCancel,
  appointment,
}: AppointmentResultProps) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [modalClass, setModalClass] = useState('modal-hide');
  useEffect(() => {
    setModalClass((prev) => (prev ? 'modal-show' : 'modal-hide'));
  }, [isOpen]);
  {
    /* Modal props */
  }

  type AppoitmentResultType = Pick<
    Appointment,
    'comment' | 'visiteMedicaleDone' | 'apte'
  >;
  const initialValues: AppoitmentResultType = {
    comment: appointment.comment ?? '',
    visiteMedicaleDone: appointment.visiteMedicaleDone ?? false,
    apte: appointment.apte ?? false,
  };

  const { user } = useAuth();

  const handleSubmit = async (
    values: AppoitmentResultType,
    { resetForm, validateForm, submitForm, action }: any
  ) => {};

  const handleSave = async (
    values: AppoitmentResultType,
    { resetForm, validateForm, submitForm }: any
  ) => {
    setLoading(true);
    const errors = await validateForm();
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      return;
    }

    try {
      let appointmentWithResult: Appointment = {
        ...appointment,
        ...values,
      };

      const data = await saveAppointment(appointmentWithResult);
      if (data) {
        toast.success(
          t('SuccessMessage', {
            name: t('AppointmentResultModal.appointment'),
          })
        );
        queryClient.invalidateQueries(['appointments']);
      }
      onSave();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm(); // Set loading to false after the API request is complete
    }
  };

  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className={`fixed left-0  right-0 top-0  z-[1055]  flex h-full w-full items-center  justify-center overflow-y-auto  bg-gray-800 bg-opacity-75 ${modalClass} `}
    >
      <div className=" max-h-200 relative  w-150 overflow-y-scroll  rounded-lg bg-white  shadow-md dark:bg-gray-700">
        <button
          type="button"
          className="absolute right-2.5 top-2  inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={onCancel}
        >
          <svg
            className="h-3 w-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">{t('AppointmentResultModal.close')}</span>
        </button>
        <div className="rounded-t border-b px-6 py-2 dark:border-gray-600">
          <h3 className="text-base font-semibold text-gray-900 dark:text-white lg:text-xl">
            {t('AppointmentResultModal.appointmentResult')}
          </h3>
        </div>
        {
          <Formik
            initialValues={initialValues}
            validationSchema={ApppointmentResultSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              resetForm,
              validateForm,
              setFieldValue,
              submitForm,
            }) => (
              <Form className="p-5">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group mb-6 w-full">
                    <div className="inline-flex items-baseline gap-4">
                      <label
                        htmlFor="visiteMedicaleDone"
                        className="mb-2 block w-full text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t('AppointmentResultModal.visiteMedicaleDone')}
                      </label>
                      <input
                        id="visiteMedicaleDone"
                        name="visiteMedicaleDone"
                        type="checkbox"
                        checked={values.visiteMedicaleDone ?? false}
                        onChange={(e) => {
                          handleChange(e);
                          const newValue = e.target.checked;
                          setFieldValue('visiteMedicaleDone', newValue);
                        }}
                      />
                    </div>
                  </div>
                  <div className="group relative mb-6 w-full">
                    <div className="inline-flex items-baseline gap-4 ">
                      <label
                        htmlFor="apte"
                        className="mb-2 block w-full text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t('AppointmentResultModal.apte')}
                      </label>
                      <input
                        id="apte"
                        name="apte"
                        type="checkbox"
                        checked={values.apte ?? false}
                        onChange={(e) => {
                          handleChange(e);
                          const newValue = e.target.checked;
                          setFieldValue('apte', newValue);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="group relative mb-6 w-full">
                  <TextArea
                    id="comment"
                    name="comment"
                    label={t('AppointmentResultModal.comment')}
                    errors={errors}
                    value={values.comment ?? ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex justify-between">
                  <LabelButton type="button" onClick={onCancel}>
                    {t('AppointmentResultModal.close')}
                  </LabelButton>

                  <PrimaryButton
                    className="mb-0"
                    type="submit"
                    onClick={(e) =>
                      handleSave(values, {
                        resetForm,
                        validateForm,
                        submitForm,
                        action: 'save',
                      })
                    }
                  >
                    {t('AppointmentResultModal.save')}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        }
      </div>
    </div>
  );
};

export default AppointmentResultModal;
