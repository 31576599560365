import React, { useState } from 'react';
import DegreesTableForm from './DegreesTableForm'; // Import your DegreesTableForm component
import { DegreeModel } from '@/services/JobDescriptionService'; // Import your DegreeModel
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { removeDegree } from '@/state/slices/jobdescriptionSlice';

interface DegreesTableProps {
  degrees: DegreeModel[];
  readonly?: boolean;
}

const DegreesTable: React.FC<DegreesTableProps> = ({
  degrees,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editDegree, setEditDegree] = useState<DegreeModel | null>(null);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const handleAddClick = () => {
    setEditDegree(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (degree: DegreeModel) => {
    setEditDegree(degree);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (degreeId: string) => {
    dispatch(removeDegree({ degreeId: degreeId }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditDegree(null);
  };

  const handleSaveDegree = (degree: DegreeModel) => {
    // Handle save logic
    // Call the API or dispatch an action to save the degree
    closeModal();
  };

  return (
    <div className="w-full overflow-auto">
      <table className="w-full max-w-full overflow-x-scroll text-left text-sm text-gray-500 dark:text-gray-400">
        {/* Table headers */}
        <thead className="text-md bg-gray-50 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3">{t('degrees.yearOfCompletion')}</th>
            <th className="px-6 py-3">{t('degrees.title')}</th>
            <th className="px-6 py-3">{t('degrees.institution')}</th>
            {!readonly && <th className="px-6 py-3">{t('degrees.actions')}</th>}
          </tr>
        </thead>
        <tbody>
          {degrees.map((degree) => (
            <tr
              key={degree.id}
              className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <td className="px-6 py-3">{degree.yearOfCompletion}</td>
              <td>{degree.title}</td>
              <td className="px-6 py-3">{degree.institution}</td>
              {!readonly && (
                <td className="flex px-6 py-3">
                  <button
                    type="button"
                    onClick={() => handleEditClick(degree)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {t('degrees.edit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteClick(degree.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    {t('degrees.delete')}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {!readonly && (
        <div className="my-4 flex justify-end">
          <button
            type="button"
            onClick={handleAddClick}
            className="mb-2 mr-2 rounded-lg bg-green-500 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {t('degrees.add')}
          </button>
        </div>
      )}

      {isModalOpen && (
        <DegreesTableForm
          initialValues={editDegree || undefined}
          onSubmit={handleSaveDegree}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default DegreesTable;
