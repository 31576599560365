import RoleException from 'src/utils/RoleException';
import {
  AuthUserKey,
  CompaniesUrl,
  CompanyKey,
  LicenseKey,
  languageKey,
  loginUrl,
  tokenKeys,
} from 'src/variables/Urls';
import { createContext, useState, useContext, useEffect } from 'react';
import api from '@/utils/api';
import { UserRoles } from '@/enums/UsersRole';
import { getCompany } from '@/services/CompanyService';
import { getLicense, getLicenseByUserId } from '@/services/LicenseService';
import { toast } from 'react-hot-toast';
import secureLocalStorage from 'react-secure-storage';
import { getUserSetting } from '@/services/UserSettingsService';
import useLocalStorage from '@/hooks/useLocalStorage';
import { ThemeEnum } from '@/enums/ThemeEnum';
import { changeLanguage } from '@/i18n/config';

// interface AuthContextType {
//   token: string | null;
//   login: (email: string, password: string) => void;
//   logout: () => void;
//   signed: boolean;
// }

export type LoginType = {
  email: string;
  password: string;
};
export interface AuthContextData {
  signed: boolean;
  user: User | null;
  loading: boolean;
  Login: ({}: LoginType) => Promise<boolean>;
  logout: () => void;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [moduleTypes, setModuleTypes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [colorMode, setColorMode] = useLocalStorage('color-theme', 'light');

  useEffect(() => {
    async function loadStorageData() {
      const storageUser = secureLocalStorage.getItem(AuthUserKey);
      const storageToken = secureLocalStorage.getItem(tokenKeys);

      if (storageUser && storageToken) {
        setUser(JSON.parse(storageUser));
      }
      setLoading(false);
    }

    loadStorageData();
  }, []);

  const Login = async ({ email, password }: any) => {
    try {
      const { data: userData } = await api.post(loginUrl, {
        email: email,
        password: password,
      });

      let roleName = userData.role;
      let userId = userData.id;
      const claims = userData.claims;
      //? we have two cases : emplyee , company
      // if (roleName === UserRoles.Admin) {
      //   setUser(userData);
      //   //secureLocalStorage.setItem(refreshTokens, jwt.refreshToken);
      //   //hubOpenConnection(jwt.token);

      // } else {
      //   if (roleName === UserRoles.Employee) {
      //   }
      // }
      // check validity of license
      let licenses = await getLicenseByUserId(userId, roleName);
      if (!licenses || !licenses?.isValidLicense) {
        toast.error('your License has Been expired please call the support ');
        // window.location.href = '/auth/login';
      }
      await fetchSetting(userId);
      // get the list of module types
      secureLocalStorage.setItem(tokenKeys, userData.token);
      secureLocalStorage.setItem(LicenseKey, licenses!.id);
      secureLocalStorage.setItem(CompanyKey, licenses!.companyId);
      secureLocalStorage.setItem(AuthUserKey, JSON.stringify(userData));

      setUser(user);
      window.location.href = '/';

      return true;
      //return true;
    } catch (err) {
      return false;
    }
  };

  const fetchSetting = async (userId: string) => {
    try {
      let setting = await getUserSetting(userId);
      if (setting) {
        const theme =
          ThemeEnum[setting.theme]?.toLowerCase() || 'default-theme';

        setColorMode(theme);
        changeLanguage(setting.languageCode);
      } else {
        console.error('Failed to fetch user settings.');
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };
  const logout = () => {
    secureLocalStorage.clear();
    setUser(null);
    window.location.href = '/auth/login';
  };

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, loading, Login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
export const IsAuth = () => {
  const storageToken = secureLocalStorage.getItem(tokenKeys);

  if (storageToken) {
    return true;
  }
  return false;
};
