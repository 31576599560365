import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  PlanningTask,
  getPlanningTask,
  savePlanningTask,
} from '@/services/PlanningAOTasksService';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import ReactTimePickerDefault from '@/components/ReactTimePickerDefault';
import MultiSelect from '@/components/MultiSelect';
import moment from 'moment';
import { AsUTC } from '@/components/shared/Utils';
import {
  ProjectTeamMemberResponse,
  getProjectTeamMembersByProjectId,
} from '@/services/ProjectTeamMemberService';

const validationSchema = Yup.object().shape({
  taskName: Yup.string().required('PlanningAOTaskForm.taskNameRequired'),
  startDate: Yup.date().required('PlanningAOTaskForm.startDateRequired'),
  endDate: Yup.date().required('PlanningAOTaskForm.endDateRequired'),
  responsibleId: Yup.string().required(
    'PlanningAOTaskForm.responsibleIdRequired'
  ),
});

const userTimeZone = moment.tz.guess();

interface FormValues extends PlanningTask {}

function PlanningAOTaskForm() {
  const { taskId, id } = useParams();
  const editForm = taskId !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    taskName: '',
    comments: '',
    startDate: new Date(),
    endDate: new Date(),
    responsibleId: '',
    projectId: id!,
    duration: 0,
    progress: 0,
    id: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: planningAOTaskData,
    isLoading,
    refetch: refetchPlanningAOTask,
  } = useQuery<PlanningTask | undefined>(
    ['planningAOTask', taskId],
    async () => await getPlanningTask(taskId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (planningAOTask) => {
        if (planningAOTask) {
          setFormikValuesState((prev) => ({ ...prev, ...planningAOTask }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && planningAOTaskData) {
      setFormikValuesState((prev) => ({
        ...prev,
        ...planningAOTaskData,
        startDate: AsUTC(planningAOTaskData.startDate),
        endDate: AsUTC(planningAOTaskData.endDate),
      }));
    }
  }, [editForm, planningAOTaskData]);

  const handleSavePlanningAOTask = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const planningAOTask: PlanningTask = {
        ...values,
      };

      const data = await savePlanningTask(planningAOTask);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.PlanningAOTask'),
            })
          );

          queryClient.invalidateQueries(['planningTasks']);
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.PlanningAOTask'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchPlanningAOTask();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const { data: projectTeamMemebers, isLoading: isLoadingEmployees } = useQuery<
    ProjectTeamMemberResponse | undefined
  >(
    ['projectTeamMemebers', id],
    async () =>
      await getProjectTeamMembersByProjectId({
        page: 0,
        take: 0,
        search: '',
        projectId: id!,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('PlanningAOTaskForm.editPlanningAOTask')
            : t('PlanningAOTaskForm.addPlanningAOTask')
        }
      />
      {loading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSavePlanningAOTask}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-2 md:grid-cols-2">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="taskName"
                    id="taskName"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.taskName}
                    label={t('PlanningAOTaskForm.taskNameLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="number"
                      name="progress"
                      id="progress"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.progress}
                      label={t('PlanningAOTaskForm.progressLabel')}
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-2 md:grid-cols-2">
                <div className="group relative mb-6 w-full">
                  <ReactDatePickerDefault
                    name="startDate"
                    id="startDate"
                    labelClassName="w-[40%]"
                    label={t('PlanningAOTaskForm.startDateLabel')}
                    value={values.startDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'startDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['startDate']}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <ReactDatePickerDefault
                    name="endDate"
                    id="endDate"
                    labelClassName="w-[40%]"
                    label={t('PlanningAOTaskForm.endDateLabel')}
                    value={values.endDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'endDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['endDate']}
                  />
                </div>
              </div>
              <div className="grid gap-2 md:grid-cols-2">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="responsibleId"
                    name="responsibleId"
                    optionValue="employeeId"
                    optionLabel="email"
                    options={projectTeamMemebers?.projectTeamMembers ?? []}
                    onChange={handleChange}
                    label={t('PlanningAOTaskForm.responsibleLabel')}
                    labelClassName="w-[50%]"
                    error={errors.responsibleId}
                    value={values.responsibleId}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="comments"
                    id="comments"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.comments}
                    label={t('DocRequest.comments')}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('PlanningAOTaskForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('PlanningAOTaskForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default PlanningAOTaskForm;
