import { XIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import DefaultInput from '@/components/Input';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store';
import { EquipmentResponse, getEquipments } from '@/services/EquipmentService';

import { EquipmentCategory } from '@/enums/EquipmentCategory';
import Dropdown from '@/components/DropDown';
import TextArea from '@/components/TextArea';
import { useQuery } from '@tanstack/react-query';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Currency } from '@/enums/Currency';
import { getCurrencyExchange } from '@/services/ExchangeRateService';
import moment from 'moment';
import { formatCurrency } from '@/utils/helpers';
import {
  addSubProjectMaterial,
  updateSubProjectMaterial,
} from '@/state/slices/subProjectMethodSlice';
import { uniqueId } from 'lodash';

export interface ModalProps {
  id: string;
  subProjectMethodId: string;

  ref?: number;
  equipmentId: string;

  description: string;
  quantity: number;
  capacity: number;
  unitPrice: number;
  totalPrice: number;
  comments: string;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
}
interface SubProjectMaterialModalProps {
  closeModal: () => void;
  initialData?: ModalProps;
}

const validationSchema = Yup.object().shape({
  //ref: Yup.number().required('subProjectMaterialModel.Ref is required'),
  equipmentId: Yup.string().required(
    'subProjectMaterialModel.EquipmentID is required'
  ),
  description: Yup.string().required(
    'subProjectMaterialModel.Description is required'
  ),
  quantity: Yup.number().required(
    'subProjectMaterialModel.Quantity is required'
  ),
  capacity: Yup.number().required(
    'subProjectMaterialModel.Capacity is required'
  ),
  unitPrice: Yup.number().required(
    'subProjectMaterialModel.UnitPrice is required'
  ),
  totalPrice: Yup.number().required(
    'subProjectMaterialModel.TotalPrice is required'
  ),
  comments: Yup.string(),
  exchangeRate: Yup.number().required(
    'subProjectMaterialModel.ExchangeRate is required'
  ),
});

const SubProjectMaterialModal: React.FC<SubProjectMaterialModalProps> = ({
  closeModal,
  initialData,
}) => {
  const { t } = useTranslation();
  const [equipmentCurrency, setEquipmentCurrency] = useState(Currency.EUR);

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  console.warn('🚀 ~ initialData:', initialData);
  const initialValues: ModalProps = {
    id: initialData?.id ?? '',
    subProjectMethodId: initialData?.id ?? '',
    ref: initialData?.ref ?? 0,
    equipmentId: initialData?.equipmentId ?? '',
    description: initialData?.description ?? '',
    quantity: initialData?.quantity ?? 0,
    capacity: initialData?.capacity ?? 0,
    unitPrice: initialData?.unitPrice ?? 0,
    totalPrice: initialData?.totalPrice ?? 0,
    comments: initialData?.comments ?? '',
    exchangeRate: initialData?.exchangeRate ?? 1,
  };

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { data, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );

  const projectCurreny = data?.currency ?? Currency.EUR;

  const handleSave = async (values: ModalProps) => {
    setLoading(true);
    try {
      //  await saveSubProjectMaterial(values);

      var equipment = equipments?.equipments.filter(
        (x) => x.id == values.equipmentId
      )[0]!;
      var subMeth = {
        ...values,
        id: initialData ? initialData.id : 'newM' + uniqueId(),
        totalPrice: values.unitPrice * values.quantity,
        exchangeRate: values.exchangeRate,
        name: equipment.equipmentName,
        totalToCurrency:
          (values.unitPrice * values.quantity) / values.exchangeRate,
        equipmentCurrency: equipment.currency,
      };

      console.log('🚀 ~ handleSave ~ subMeth:', subMeth);
      if (initialData) {
        dispatch(updateSubProjectMaterial(subMeth));
      } else {
        dispatch(addSubProjectMaterial(subMeth));
      }

      // toast.success(t('subProjectMaterialModel.SuccessMessage'));
      closeModal();
    } catch (error) {
      // toast.error(t('subProjectMaterialModel.ErrorMessage'));
    } finally {
      setLoading(false);
    }
  };

  const {
    isLoading,
    isError,
    data: equipments,
  } = useQuery<EquipmentResponse | undefined>(
    ['equipments'],
    () =>
      getEquipments({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        category: EquipmentCategory.Equipment,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const { data: exchangeRates, isLoading: isExchLoading } = useQuery(
    [
      'exchangeRates',
      projectCurreny,
      moment(selectedDate).format('YYYY-MM-DD'),
    ],
    () => getCurrencyExchange(Currency[projectCurreny], selectedDate),
    {
      staleTime: 60 * (60 * 1000), // 5 mins
      cacheTime: 120 * (60 * 1000),
    }
  );

  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="fixed left-0 right-0 top-0 z-[1055] flex h-full w-full items-center justify-center overflow-y-auto bg-gray-800 bg-opacity-75"
    >
      <div className="w-full max-w-[35rem] rounded-lg bg-white p-6 shadow-lg dark:bg-gray-700">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold">
            {initialData
              ? t('subProjectMaterialModel.EditSubProjectMaterial')
              : t('subProjectMaterialModel.AddSubProjectMaterial')}
          </h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        {loading || isLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize={true}
          >
            {({ handleChange, errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="grid gap-2 md:grid-cols-2">
                  {/* <DefaultInput
                    id="ref"
                    value={values.ref}
                    name="ref"
                    type="number"
                    label={t('subProjectMaterialModel.Ref')}
                    error={errors.ref}
                    touched={touched.ref}
                  /> */}
                  {/* <DefaultInput
                    name="equipmentId"
                    id="ref"
                    value={values.ref}
                    type="text"
                    label={t('subProjectMaterialModel.EquipmentID')}
                    error={errors.equipmentId}
                    onChange={handleChange}
                  /> */}

                  <Dropdown
                    id="equipmentId"
                    name="equipmentId"
                    optionValue="id"
                    optionLabel="equipmentName"
                    onChange={(e) => {
                      var eq = equipments?.equipments.filter(
                        (x) => x.id == e.target.value
                      )[0];
                      if (!eq) {
                        setFieldValue('equipmentId', -1);
                        return;
                      }
                      setEquipmentCurrency(eq?.currency as Currency);

                      setFieldValue('equipmentId', e.target.value);

                      console.log(
                        'this is data ',
                        exchangeRates,
                        exchangeRates[Currency[eq?.currency as Currency]].value
                      );
                      setFieldValue(
                        'exchangeRate',
                        exchangeRates[Currency[eq?.currency as Currency]].value
                      );
                    }}
                    options={equipments?.equipments ?? []}
                    label={t('subProjectMaterialModel.EquipmentID')}
                    labelClassName="w-[50%]"
                    error={errors.equipmentId}
                    value={values.equipmentId}
                  />
                  <DefaultInput
                    name="description"
                    id="description"
                    value={values.description}
                    type="text"
                    label={t('subProjectMaterialModel.Description')}
                    errors={errors}
                    onChange={handleChange}
                  />

                  <DefaultInput
                    name="quantity"
                    id="quantity"
                    value={values.quantity}
                    type="number"
                    label={t('subProjectMaterialModel.Quantity')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="capacity"
                    id="capacity"
                    value={values.capacity}
                    type="number"
                    label={t('subProjectMaterialModel.Capacity')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="unitPrice"
                    id="unitPrice"
                    value={values.unitPrice}
                    type="number"
                    label={t('subProjectMaterialModel.UnitPrice')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="totalPrice"
                    id="totalPrice"
                    value={formatCurrency(
                      values.unitPrice * values.quantity,
                      equipmentCurrency
                    )}
                    type="text"
                    label={t('subProjectMaterialModel.TotalPrice')}
                    errors={errors}
                    disabled={true}
                    onChange={handleChange}
                  />
                  {/* <DefaultInput
                    name="totalPrice"
                    id="totalPrice"
                    value={formatCurrency(
                      values?.totalPrice,
                      equipmentCurrency
                    )}
                    type="number"
                    label={t('subProjectMaterialModel.TotalPrice')}
                    errors={errors}
                    onChange={handleChange}
                  /> */}
                  <TextArea
                    name="comments"
                    id="comments"
                    value={values.comments}
                    label={t('subProjectMaterialModel.Comments')}
                    errors={errors}
                    onChange={handleChange}
                  />

                  {isExchLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <DefaultInput
                        id="exchangeRate"
                        type="number"
                        name="exchangeRate"
                        // disabled={false}
                        label={t('expenseForm.placeholders.exhangeRate')}
                        labelClassName="w-[80%]"
                        value={values.exchangeRate}
                        readOnly
                      />
                    </>
                  )}
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    type="button"
                    className="mr-4 rounded bg-gray-300 px-4 py-2 text-gray-800 hover:bg-gray-400"
                    onClick={closeModal}
                  >
                    {t('subProjectMaterialModel.Cancel')}
                  </button>
                  <button
                    type="submit"
                    className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
                  >
                    {t('subProjectMaterialModel.Save')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default SubProjectMaterialModal;
