import moment from 'moment';
import { forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface IDateAndMonthPicker {
  selectedDate: any;
  setSelectedDate: any;
  selectedLanguage?: string;
}
export const DataAndMonthPicker = ({
  selectedDate,
  setSelectedDate,
  selectedLanguage,
}: IDateAndMonthPicker) => {
  const formatDate = (date: any) => {
    return date.toLocaleString(selectedLanguage ?? 'en', { month: 'long' });
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <span ref={ref} onClick={onClick}>
      {value}
    </span>
  ));

  return (
    <DatePicker
      locale={selectedLanguage ?? 'en'}
      calendarClassName="dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark"
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      showMonthYearPicker
      dateFormat="MMMM  yyyy"
      customInput={<CustomInput value={moment(selectedDate).format('MMMM')} />}
    />
  );
};
