import api from '@/utils/api';
import { PlanningAOTasksUrl, ProjectTasksUrl } from '@/variables/Urls';
import { PlanningTask } from './PlanningAOTasksService';

const apiEndpoint = ProjectTasksUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface ProjectTaskResponse {
  projectTasks: ProjectTask[];
  count: number;
}

export interface ProjectTask extends PlanningTask {}

export async function getProjectTasksByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<ProjectTaskResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export async function saveProjectTask(task: ProjectTask) {
  if (task.id) {
    const response = await api.put(apiEndpoint + '/put', task);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', task);
  return response;
}

export async function getProjectTask(id: string) {
  const { data } = await api.get<ProjectTask>(apiEndpoint + '/GetById', {
    params: { id: id },
  });

  return data;
}

export function deleteProjectTask(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
