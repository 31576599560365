export enum InterfaceRequestStatus {
  InProgress,
  Pending,
  Completed,
  Delayed,
  Cancelled,
  ToBeValidated,
  NotStarted,
  Blocked,
  Reviewed,
  ToBeReviewed,
  Validated,
}

export const InterfaceRequestStatusOptions = Object.keys(InterfaceRequestStatus)
  .filter((key) => isNaN(Number(InterfaceRequestStatus[key])))
  .map((key) => ({
    id: key,
    name: InterfaceRequestStatus[key],
  }));
