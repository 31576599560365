import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AsUserTimeZone } from './shared/Utils';

interface IDateAndTimePicker {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  selectedLanguage?: string;
  label?: string;
  errors?: string;
  name: string;
  id: string;
  disabled?: boolean;
}

export const DateAndTimePicker = ({
  selectedDate,
  setSelectedDate,
  selectedLanguage,
  id,
  errors,
  name,
  label,
  disabled,
}: IDateAndTimePicker) => {
  console.log('this is errors data ', errors);
  const { t } = useTranslation();
  const formatDate = (date: Date) => {
    return date.toLocaleString(selectedLanguage ?? 'en', { month: 'long' });
  };

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <span ref={ref} onClick={onClick}>
      {value}
    </span>
  ));

  return (
    <div className="relative">
      <label
        htmlFor={id}
        className={`
          mb-2 block text-sm font-medium text-gray-900 dark:text-white
          ${errors ? 'text-red-700 dark:text-red-500' : ''}
        `}
      >
        {label}
      </label>
      <DatePicker
        disabled={disabled}
        locale={selectedLanguage ?? 'en'}
        calendarClassName="dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark"
        selected={selectedDate}
        onChange={(date: Date) => setSelectedDate(AsUserTimeZone(date))}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="MMMM d, yyyy h:mm aa"
        dayClassName={(date) => 'dark:text-bodydark'}
        timeClassName={() =>
          'dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark'
        }
        wrapperClassName=" dark:text-bodydark outline-1 block w-full rounded-lg border border-gray-300  bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 "
        customInput={
          <CustomInput
            value={moment(selectedDate).format('MMMM D, YYYY h:mm A')}
          />
        }
      />
      {errors && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors)}
        </p>
      )}
    </div>
  );
};
