import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  WorkHour,
  WorkHourResponse,
  WorkhoursRequest,
  deleteWorkHour,
  getWorkHoursByProjectId,
  saveWorkHour,
} from '@/services/WorkHoursService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  ProjectTeamMemberWorkChargesResponse,
  getProjectTeamMemberWorkCharges,
} from '@/services/ProjectTeamMemberService';
import { AssignedTask } from '@/enums/AssignedTask';
import { InputTimeline } from '@/components/InputTimeLine';
import { OffDaysType, WeekendsType } from '@/components/TimeLine';
import { OffDay } from '@/services/WorkCalendarsService';
import { DaysCalendarColor } from '@/variables/const';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import { AsUTC } from '@/components/shared/Utils';

const userTimeZone = moment.tz.guess();

function WorkCharges() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [weekendDays, setWeekendDays] = useState<WeekendsType[]>([]);
  const [allWeekendDays, setAllWeekendDays] = useState<WeekendsType[]>([]);

  const { t, i18n } = useTranslation();

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('WorkCharges.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('WorkCharges.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteWorkHour.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('WorkCharges.dateColumn'),
        accessor: 'date',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD');
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('WorkCharges.hoursColumn'),
        accessor: 'hours',
      },
      {
        Header: t('WorkCharges.nameColumn'),
        accessor: 'name',
      },
      {
        Header: t('WorkCharges.postColumn'),
        accessor: 'post',
      },
      {
        Header: t('WorkCharges.internColumn'),
        accessor: 'intern',
        Cell: ({ cell: { value } }: any) => (value ? 'Yes' : 'No'),
      },
      {
        Header: t('WorkCharges.assignedTaskColumn'),
        accessor: 'assignedTask',
        Cell: ({ cell: { value } }) => {
          const frontendState = AssignedTask[value];

          return (
            <div
              className={`min-w-50 text-md w-40 rounded-md  px-2.5  py-2.5 text-center `}
            >
              {t(`AssignedTask.${frontendState}`)}
            </div>
          );
        },
      },
      {
        Header: t('WorkCharges.departmentNameColumn'),
        accessor: 'departmentName',
      },
      {
        Header: t('WorkCharges.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => <ActionButtonsCell value={value} />,
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: workHours,
  } = useQuery<ProjectTeamMemberWorkChargesResponse | undefined>(
    ['workHours', pageIndex, pageSize, search, id],
    () =>
      getProjectTeamMemberWorkCharges({
        projectId: id!,
        page: pageIndex,
        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  //? ?!work calnder
  useEffect(() => {
    let daysMapping = {
      monday: t('Timeline.Mon'),
      tuesday: t('Timeline.Tue'),
      wednesday: t('Timeline.Wed'),
      thursday: t('Timeline.Thu'),
      friday: t('Timeline.Fri'),
      saturday: t('Timeline.Sat'),
      sunday: t('Timeline.Sun'),
    };

    const weekends =
      workHours?.workCharges?.map((employee) => ({
        employeeId: employee.id!,
        days: Object.keys(daysMapping)
          .filter((day) => employee?.workCalendar[day])
          .map((day) => daysMapping[day]),
      })) ?? [];

    setWeekendDays((prv) => weekends);
  }, [workHours, t]);

  useEffect(() => {
    let daysMapping = {
      monday: t('Timeline.Mon'),
      tuesday: t('Timeline.Tue'),
      wednesday: t('Timeline.Wed'),
      thursday: t('Timeline.Thu'),
      friday: t('Timeline.Fri'),
      saturday: t('Timeline.Sat'),
      sunday: t('Timeline.Sun'),
    };

    const weekends =
      workHours?.workCharges?.map((employee) => ({
        employeeId: employee.id!,
        days: Object.keys(daysMapping)
          .filter((day) => employee?.workCalendar[day])
          .map((day) => daysMapping[day]),
      })) ?? [];

    setAllWeekendDays((prv) => weekends);
  }, [workHours, t]);

  const holidays: OffDaysType[] = useMemo(() => {
    return (
      workHours?.workCharges
        ?.map((employee) => {
          const employeeHolidays: OffDay[] =
            employee?.workCalendar?.offDays?.filter((x) => x.isPayed) ?? [];
          return employeeHolidays.map((holiday: OffDay) => ({
            employeeId: employee.id!,
            type: 'holiday',
            ...holiday,
          }));
        })
        .flat() ?? []
    );
  }, [workHours, selectedDate]);

  const enterpriseClosing: OffDaysType[] = useMemo(() => {
    return (
      workHours?.workCharges
        ?.map((employee) => {
          const employeeClosings: OffDay[] =
            employee?.workCalendar?.offDays?.filter((x) => !x.isPayed) ?? [];
          return employeeClosings.map((closing: OffDay) => ({
            employeeId: employee.id!,
            type: 'enterpriseClosing',
            ...closing,
          }));
        })
        .flat() ?? []
    );
  }, [workHours, selectedDate]);

  // ? end ----------------

  const handleDeleteWorkHour = useMutation(
    async (id: string) => {
      await deleteWorkHour(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'workHours',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((workHours?.workCharges.length ?? 0) / pageSize);
  }, [pageSize, workHours]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const handleSaveWorkCharges = async (e) => {
    const workHoursData = workHours?.workCharges
      ?.map((wh) => wh.workHours)
      .flat()
      .map(({ id, date, ...rest }) => ({
        date: AsUTC(date),
        ...rest,
      }));
    console.log('this si event ', workHoursData);
    if (workHoursData) {
      var whModel: WorkhoursRequest = {
        workHours: workHoursData,
        projectId: id,
      };
      const data = await saveWorkHour(whModel);
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('WorkCharges.pageName')}
        addbtn={false}
        buttons={[
          <button
            className=" text-md ml-3 inline-flex items-center rounded border border-green-500 bg-transparent px-2 py-1 text-black hover:bg-green-500 disabled:border-0 disabled:bg-gray-400  disabled:text-white disabled:hover:bg-none dark:text-white"
            onClick={handleSaveWorkCharges}
          >
            {i18n.language == 'ar' && (
              <BadgeCheckIcon
                color="#109b43"
                className="w- ml-1 inline h-5 "
                alt="save"
              />
            )}
            {t('EquipmentForm.submitButton')}

            {i18n.language != 'ar' && (
              <BadgeCheckIcon
                color="#109b43"
                className="w- ml-1 inline h-5 "
                alt="save"
              />
            )}
          </button>,
        ]}
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <InputTimeline
            resourcesLength={workHours?.workCharges.length ?? 0}
            resourcesList={workHours?.workCharges ?? []}
            searchResource={search}
            setSearchResource={setSearch}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            holidays={holidays}
            enterpriseClosing={enterpriseClosing}
            weekendDays={weekendDays}
          />

          <div className=" mt-5 flex align-bottom   dark:bg-boxdark dark:text-bodydark">
            <div className="flex gap-4 px-2">
              <div
                className={`h-4 w-4 rounded-md ${DaysCalendarColor.holiday}`}
                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
              <p className="text-sm">{t('Holiday')}</p>
            </div>
            <div className="flex gap-4 px-2">
              <div
                className={`h-4 w-4 rounded-md ${DaysCalendarColor.weekend}`}
                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
              <p className="text-sm">{t('Weekend')}</p>
            </div>
            <div className="flex gap-4 px-2">
              <div
                className={`h-4 w-4 rounded-md ${DaysCalendarColor.enterpriseClosing}`}
                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
              <p className="text-sm">{t('Enterprise Closing')}</p>
            </div>
          </div>
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default WorkCharges;
