import { XIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DefaultInput from '@/components/Input';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store';
import {
  addSubProjectOtherEquipment,
  updateSubProjectOtherEquipment,
} from '@/state/slices/subProjectMethodSlice';
import { uniqueId } from 'lodash';
import Checkbox from '@/components/Checkbox';
import TextArea from '@/components/TextArea';
import Dropdown from '@/components/DropDown';
import { useQuery } from '@tanstack/react-query';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { EquipmentResponse, getEquipments } from '@/services/EquipmentService';

import { Currency } from '@/enums/Currency';
import { getCurrencyExchange } from '@/services/ExchangeRateService';
import moment from 'moment';
import { formatCurrency } from '@/utils/helpers';
import { EquipmentCategory } from '@/enums/EquipmentCategory';

export interface OtherEquipmentFormValues {
  id?: string;
  subProjectMethodId?: string;
  equipmentId: string; // Changed to equipmentId
  description: string;
  isLocated: boolean;
  category: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  comment?: string;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  equipmentCurrency?: Currency;
}

interface SubProjectOtherEquipmentModalProps {
  initialData?: OtherEquipmentFormValues;

  closeModal: () => void;
}

const validationSchema = Yup.object().shape({
  equipmentId: Yup.string().required(
    'SubProjectOtherEquipmentModal.EquipmentIDRequired'
  ),
  isLocated: Yup.boolean().required(
    'SubProjectOtherEquipmentModal.IsLocatedRequired'
  ),
  category: Yup.string().required(
    'SubProjectOtherEquipmentModal.CategoryRequired'
  ),
  quantity: Yup.number().required(
    'SubProjectOtherEquipmentModal.QuantityRequired'
  ),
  unitPrice: Yup.number().required(
    'SubProjectOtherEquipmentModal.UnitPriceRequired'
  ),
  comment: Yup.string(),
});

const SubProjectOtherEquipmentModal = ({
  initialData,
  closeModal,
}: SubProjectOtherEquipmentModalProps) => {
  const { t } = useTranslation();
  const [equipmentCurrency, setEquipmentCurrency] = useState(Currency.EUR);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const initialValues: OtherEquipmentFormValues = {
    id: initialData?.id ?? '',
    subProjectMethodId: initialData?.subProjectMethodId ?? '',
    equipmentId: initialData?.equipmentId ?? '',
    description: initialData?.description ?? '',
    isLocated: initialData?.isLocated ?? false,
    category: initialData?.category ?? '',
    quantity: initialData?.quantity ?? 0,
    unitPrice: initialData?.unitPrice ?? 0,
    totalPrice: initialData?.totalPrice ?? 0,
    comment: initialData?.comment ?? '',
    exchangeRate: initialData?.exchangeRate ?? 1,
  };

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { data, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );

  const projectCurrency = data?.currency ?? Currency.EUR;

  const handleSave = async (values: OtherEquipmentFormValues) => {
    setLoading(true);
    try {
      const equipment = equipments?.equipments.find(
        (x) => x.id === values.equipmentId
      );
      if (!equipment) {
        toast.error(t('SubProjectOtherEquipmentModal.EquipmentNotFound'));
        return;
      }

      const subMeth = {
        ...values,
        id: initialData ? initialData.id : 'newO' + uniqueId(),
        totalPrice: values.unitPrice * values.quantity,
        exchangeRate: values.exchangeRate,
        name: equipment.equipmentName,
        totalToCurrency:
          (values.unitPrice * values.quantity) / values.exchangeRate,
        equipmentCurrency: equipment.currency,
      };

      if (initialData) {
        dispatch(updateSubProjectOtherEquipment(subMeth));
      } else {
        dispatch(addSubProjectOtherEquipment(subMeth));
      }

      toast.success(t('SubProjectOtherEquipmentModal.SuccessMessage'));
      closeModal();
    } catch (error) {
      toast.error(t('SubProjectOtherEquipmentModal.ErrorMessage'));
    } finally {
      setLoading(false);
    }
  };

  const {
    isLoading,
    isError,
    data: equipments,
  } = useQuery<EquipmentResponse | undefined>(
    ['otherEquipments'],
    () =>
      getEquipments({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        category: EquipmentCategory.Others,
      }),
    { refetchOnWindowFocus: false, staleTime: 6000, keepPreviousData: true }
  );

  const { data: exchangeRates, isLoading: isExchLoading } = useQuery(
    [
      'exchangeRates',
      projectCurrency,
      moment(selectedDate).format('YYYY-MM-DD'),
    ],
    () => getCurrencyExchange(Currency[projectCurrency], selectedDate),
    { staleTime: 60 * (60 * 1000), cacheTime: 120 * (60 * 1000) }
  );

  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="fixed left-0 right-0 top-0 z-[1055] flex h-full w-full items-center justify-center overflow-y-auto bg-gray-800 bg-opacity-75"
    >
      <div className="w-full max-w-[35rem] rounded-lg bg-white p-6 shadow-lg dark:bg-gray-700">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold">
            {/* {initialData
              ? t('SubProjectOtherEquipmentModal.EditEquipment')
              : t('SubProjectOtherEquipmentModal.AddEquipment')} */}
          </h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        {loading || isLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize={true}
          >
            {({ handleChange, errors, values, setFieldValue }) => (
              <Form>
                <div className="grid gap-2 md:grid-cols-2">
                  <Dropdown
                    id="equipmentId"
                    name="equipmentId"
                    optionValue="id"
                    optionLabel="equipmentName"
                    onChange={(e) => {
                      const eq = equipments?.equipments.find(
                        (x) => x.id === e.target.value
                      );
                      if (!eq) {
                        setFieldValue('equipmentId', '');
                        return;
                      }
                      setEquipmentCurrency(eq.currency as Currency);
                      setFieldValue('equipmentId', e.target.value);
                      setFieldValue(
                        'exchangeRate',
                        exchangeRates[Currency[eq.currency as Currency]].value
                      );
                    }}
                    options={equipments?.equipments ?? []}
                    label={t('SubProjectOtherEquipmentModal.EquipmentID')}
                    labelClassName="w-[50%]"
                    error={errors.equipmentId}
                    value={values.equipmentId}
                  />
                  <DefaultInput
                    name="description"
                    id="description"
                    value={values.description}
                    type="text"
                    label={t('SubProjectOtherEquipmentModal.Description')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="category"
                    id="category"
                    value={values.category}
                    type="text"
                    label={t('SubProjectOtherEquipmentModal.Category')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="quantity"
                    id="quantity"
                    value={values.quantity}
                    type="number"
                    label={t('SubProjectOtherEquipmentModal.Quantity')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="unitPrice"
                    id="unitPrice"
                    value={values.unitPrice}
                    type="number"
                    label={t('SubProjectOtherEquipmentModal.UnitPrice')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="totalPrice"
                    id="totalPrice"
                    value={formatCurrency(
                      values.unitPrice * values.quantity,
                      equipmentCurrency
                    )}
                    type="text"
                    label={t('SubProjectOtherEquipmentModal.TotalPrice')}
                    errors={errors}
                    disabled={true}
                  />
                  <DefaultInput
                    name="exchangeRate"
                    id="exchangeRate"
                    value={values.exchangeRate}
                    type="number"
                    label={t('SubProjectOtherEquipmentModal.ExchangeRate')}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <DefaultInput
                    name="totalToCurrency"
                    id="totalToCurrency"
                    value={formatCurrency(
                      (values.unitPrice * values.quantity) /
                        values.exchangeRate,
                      projectCurrency
                    )}
                    type="text"
                    label={t('SubProjectOtherEquipmentModal.TotalToCurrency')}
                    errors={errors}
                    disabled={true}
                  />

                  <Checkbox
                    id="isLocated"
                    name="isLocated"
                    checked={values.isLocated}
                    onChange={handleChange}
                    label={t('SubProjectOtherEquipmentModal.IsLocated')}
                    error={errors.isLocated}
                  />
                  <TextArea
                    name="comment"
                    id="comment"
                    value={values.comment}
                    label={t('SubProjectOtherEquipmentModal.Comment')}
                    errors={errors}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-6 flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="rounded-lg bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                  >
                    {t('SubProjectOtherEquipmentModal.Cancel')}
                  </button>
                  <button
                    type="submit"
                    className="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
                    disabled={loading}
                  >
                    {initialData
                      ? t('SubProjectOtherEquipmentModal.SaveChanges')
                      : t('SubProjectOtherEquipmentModal.Save')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default SubProjectOtherEquipmentModal;
