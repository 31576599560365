import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { getLicense, getLicenseId } from '../services/LicenseService';
import { logout } from '../services/AuthService';
import { IsAuth } from './AuthContext';

interface LicenseContextData {
  loading: boolean;
  validLicense: boolean;
  moduleType: string;
}

const LicenseContext = createContext<LicenseContextData | undefined>(undefined);

export function useLicenseContext() {
  const context = useContext(LicenseContext);
  if (!context) {
    throw new Error('useLicenseContext must be used within a LicenseProvider');
  }
  return context;
}

export function LicenseProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState<boolean>(true);
  const isLogged = IsAuth();
  const [validLicense, setValidLicense] = useState<boolean>(true);
  const [moduleType, setModuleType] = useState('');

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    const licenseId = getLicenseId();
    if (licenseId === null) {
      toast.error('No license has been found for this account');
      logout();
      window.location.href = '/auth/login';
    } else {
      getLicense(licenseId)
        .then((licenseData) => {
          setLoading(false);
          if (licenseData && !licenseData.isValidLicense) {
            setValidLicense(false);
          } else {
            // save module types
            setValidLicense(true);
            setModuleType(licenseData?.moduleTypes!);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error('Failed to fetch license data.');
        });
    }
  }, [isLogged]);

  const contextValue: LicenseContextData = {
    loading,
    validLicense,
    moduleType,
  };

  return (
    <LicenseContext.Provider value={contextValue}>
      {children}
    </LicenseContext.Provider>
  );
}
