import { SupplierOrdersUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { OfferStatus } from '@/enums/OfferStatus';
import { PaymentStatus } from '@/enums/PaymentStatus';
import { Currency } from '@/enums/Currency';

export interface SupplierOrder {
  supplier: string;
  contact: string;
  companyName: string;
  address: string;
  email: string;
  phone: string;
  orderDetail: string;
  amountHT: number;
  tva: number;
  amountTTC: number;
  submissionDate: Date;
  offerStatus: OfferStatus;
  invoice: string;
  paymentStatus: PaymentStatus;
  serviceType: string;
  comment: string;
  companyId: string;
  id?: string;
  currency: Currency;
}

export interface SupplierOrdersResponse {
  supplierOrders: SupplierOrder[];
  count: number;
}

const apiEndpoint = SupplierOrdersUrl;

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}

export async function getSupplierOrders({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as SupplierOrdersResponse;
}

export async function getSupplierOrder(supplierOrderId: string) {
  const { data } = await api.get<SupplierOrder>(apiEndpoint + '/GetById', {
    params: { id: supplierOrderId },
  });

  return data;
}

export async function saveSupplierOrder(supplierOrder: SupplierOrder) {
  if (supplierOrder.id) {
    const body = { ...supplierOrder };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', supplierOrder);
  return response_1;
}

export function deleteSupplierOrder(supplierOrderId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: supplierOrderId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
