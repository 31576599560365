export enum UserRoles {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  Employee = 'Employee',
  TeamLeader = 'TeamLeader',
  // AdminGestionDesAbsences = 'AdminGestionDesAbsences',
  // AdminGestionNoteDeFrais = 'AdminGestionNoteDeFrais',
  // AdminGestionDeVisiteMedicale = 'AdminGestionDeVisiteMedicale',
  // AdminGestionEntretienAnnuel = 'AdminGestionEntretienAnnuel',
  // AdminGestionDesFormations = 'AdminGestionDesFormations',
  // AdminGestionDesFichesDePostesCV = 'AdminGestionDesFichesDePostesCV',
  // AdminGestionDesProjectAO = 'AdminGestionDesProjectAO',
  // AdminGestionDesProjectREA = 'AdminGestionDesProjectREA',
  // AdminGestionDesRH = 'AdminGestionDesRH',
  // AdminGestionDesRapports = 'AdminGestionDesRapports',
}

export enum UserRolesEnum {
  Employee = 2,
  AdminGestionDesAbsences = 4,
  AdminGestionNoteDeFrais = 5,
  AdminGestionDeVisiteMedicale = 6,
  AdminGestionEntretienAnnuel = 7,
  AdminGestionDesFormations = 8,
  AdminGestionDesFichesDePostesCV = 9,
  AdminGestionDesProjectAO,
  AdminGestionDesProjectREA,
  AdminGestionDesRH,
  AdminGestionDesRapports,
}
export const userRolesArray = Object.entries(UserRoles)
  .filter(([key, value]) => typeof value === 'string')
  .map(([key, value]) => ({
    id: value as string,
    label: UserRoles[key].toString(),
  }));

export const nonAdminUserRole = userRolesArray.filter(
  (x) =>
    x.id !== UserRoles.Admin &&
    x.id !== UserRoles.SuperAdmin &&
    x.id !== UserRoles.TeamLeader
);

export const UserRolesValues = Object.values(UserRoles);
