import { DeliverableCategory } from '@/enums/DeliverableCategory';
import { DeliverableStatus } from '@/enums/DeliverableStatus';
import { Priority } from '@/enums/Priority';
import api from '@/utils/api';
import { DeliverablesUrl } from '@/variables/Urls';

const apiEndpoint = DeliverablesUrl;

export interface DeliverableDto {
  id?: string;
  deliverableNumber: string;
  description: string;
  responsibleAuthor: string;
  category: DeliverableCategory;
  plannedStartDate: Date;
  plannedEndDate: Date;
  actualStartDate?: Date;
  actualEndDate?: Date;
  estimatedDuration: number;
  actualDuration: number;
  progressStatus: string;
  comments: string;
  dependencies: string;
  priority: Priority;
  estimatedHours: number;
  estimatedCost: number;
  actualHours: number;
  actualCost: number;
  status: DeliverableStatus;
  risks: string;
  actionPlan: string;
  draftState: DeliverableStatus;
  verificationState: DeliverableStatus;
  approvalState: DeliverableStatus;
  observations: string;
  verifiersIds: string;
  approversIds: string;
  codificationJoinerSymbol: string;
  deliverableCodifications: DeliverableCodificationDto[];
  projectId: string;
}

export interface DeliverableCodificationDto {
  description: string;
  appreviation: string;
  companyId: string;
  id?: string;
}

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface DeliverableResponse {
  deliverables: DeliverableDto[];
  count: number;
}

export async function saveDeliverable(deliverable: DeliverableDto) {
  if (deliverable.id) {
    const response = await api.put(apiEndpoint + '/put', deliverable);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', deliverable);
  return response;
}

export async function getDeliverable(id: string) {
  const { data } = await api.get<DeliverableDto>(apiEndpoint + '/GetById', {
    params: { id },
  });

  return data;
}

export async function getDeliverablesByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  const config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<DeliverableResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export function deleteDeliverable(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
