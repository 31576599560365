import { CountriesUrl } from '@/variables/Urls';
import axios from 'axios';

export interface State {
  name: string;
  id: string;
}

export interface Country {
  id: string;
  name: string;
  iso3?: string;
  iso2?: string;
  phoneCode?: string;
  capital?: string;
  currency?: string;
  states?: State[] | null;
}

export interface CountriesResponse {
  countries: Country[];
  count: number;
}
const apiEndpoint = CountriesUrl;

export interface PaginatingParmas {
  from?: number;
  take?: number;
}
export async function getCountries({ from = 0, take = 0 }: PaginatingParmas) {
  try {
    const { data } = await axios.get(apiEndpoint + '/get', {
      params: {
        from,
        take,
      },
    });
    return data as CountriesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
