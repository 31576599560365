import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Employee,
  getEmployee,
  saveEmployee,
} from '@/services/EmployeeService'; // Assuming this import exists
import { Contract } from '@/models/contract';
import { EmployeeUser } from '@/models/empUser';
import { UserRoles } from '@/enums/UsersRole';
import {
  getContract,
  getContractByEmployeeId,
  saveContract,
  updateContractByEmployeeId,
} from '@/services/ContractService';
import {
  getUser,
  getUserById,
  saveUser,
  updateUser,
} from '@/services/UserService';
import { toast } from 'react-hot-toast';
import { GenderEnum } from '@/enums/genderEnum';
import { MaritalStatusEnum } from '@/enums/maritialStatusEnum';
import { SeniorityRange } from '@/enums/SeniorityRange';

interface EmployeeCreateType {
  employee: Employee;
  contract: Contract;
  user: EmployeeUser;
  loading: boolean;
}
interface EmployeeUpdatedType extends EmployeeCreateType {
  id: string;
}

const initialState: EmployeeCreateType = {
  employee: {
    email: '',
    firstName: '',
    lastName: '',
    gender: GenderEnum.NoAnswer,
    maritalStatus: MaritalStatusEnum.NoAnswer,
    jobTitle: '',
    grade: '',
    idNumber: '',
    stateId: '',
    birthCountry: '',
    nationality: '',
    childNumber: '0',
    profileImgUrl: '',
    departmentId: '',
    companyId: '',
    birthDate: new Date(),
    leaveCarriedOver: 0,
    address: {
      street: '',
      zipCode: '',
      postalCode: '',
    },
    phoneNumber1: '',
    phoneNumber2: '',
    teamId: null,
    experience: SeniorityRange._0_5,
  },
  contract: {
    startDate: new Date(),
    endDate: new Date(),
    contractType: '',
    professionalStatus: '',
    qualification: '',
    coefficient: 0,
    contractReason: '',
    comment: '',
    employeeId: '',
  },
  user: {
    email: '',
    password: '',
    employeeRole: UserRoles.Default,
  },
  loading: false,
};

export const saveEmployeeDataToBackend = createAsyncThunk(
  'Employeform/saveEmployeeDataToBackend',
  async (data: EmployeeCreateType) => {
    try {
      const { employee, contract, user } = data;
      const serializedData = {
        ...data,
        employee: {
          ...data.employee,
          birthDate: new Date(data.employee.birthDate).toISOString(),
        },
      };
      // Make API calls to save employee, contract, and user data
      const employeeResponse = await saveEmployee(serializedData.employee);

      const contractResponse = await saveContract({
        ...contract,
        employeeId: employeeResponse?.data,
      });
      const userResponse = await updateUser({
        ...user,
        id: employeeResponse?.data,
        disabled: false,
      });

      return {
        employee: employeeResponse?.data,
        contract: contractResponse.data,
        user: userResponse.data,
      };
    } catch (error) {
      throw new Error('Failed to save employee data.'); // Handle errors appropriately
    }
  }
);
export const updateEmployeeDataToBackend = createAsyncThunk(
  'Employeform/saveEmployeeDataToBackend',
  async (data: EmployeeUpdatedType) => {
    try {
      const { employee, contract, user, id } = data;
      const serializedData = {
        ...data,
        employee: {
          ...data.employee,
          id: id,
          birthDate: new Date(data.employee.birthDate).toISOString(),
        },
      };
      // Make API calls to save employee, contract, and user data
      const employeeResponse = await saveEmployee(serializedData.employee);

      const contractResponse = await updateContractByEmployeeId({
        ...contract,
        employeeId: employeeResponse?.data,
      });
      const userResponse = await updateUser({
        ...user,
        id: employeeResponse?.data,
        firstName: employee.firstName,
        lastName: employee.lastName,
      });

      return {
        employee: employeeResponse?.data,
        contract: contractResponse.data,
        user: userResponse.data,
      };
    } catch (error) {
      throw new Error('Failed to save employee data.'); // Handle errors appropriately
    }
  }
);

export const getEmployeefrombackend = createAsyncThunk(
  'Employeform/getEmployeefrombackend',
  async (id: string) => {
    try {
      const userResponse = await getUserById(id);

      // Check if the user was created successfully
      if (!userResponse) {
        throw new Error('User not found.');
      }

      const employeeResponse = await getEmployee(id);

      // Serialize the birthDate field to ISO 8601 string
      const serializedEmployee = {
        ...employeeResponse!,
        birthDate: new Date(employeeResponse!.birthDate).toISOString(),
      };

      const contractResponse = await getContractByEmployeeId(id);

      return {
        employee: serializedEmployee,
        contract: contractResponse,
        user: userResponse,
      };
    } catch (error) {
      console.log('Failed to fetch employee data.');
    }
  }
);

const EmpformSlice = createSlice({
  name: 'Employeform',
  initialState,
  reducers: {
    updateEmployeeValue(
      state,
      action: PayloadAction<{ employeeForm: Employee }>
    ) {
      state.employee = action.payload.employeeForm;
    },
    updateContractValue(
      state,
      action: PayloadAction<{ contractForm: Contract }>
    ) {
      state.contract = action.payload.contractForm;
    },
    updateUserValue(state, action: PayloadAction<{ userForm: EmployeeUser }>) {
      state.user = action.payload.userForm;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetForm(state) {
      state.employee = { ...initialState.employee };
      state.contract = { ...initialState.contract };
      state.user = { ...initialState.user };
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveEmployeeDataToBackend.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveEmployeeDataToBackend.fulfilled, (state, action) => {
        state.loading = false;
        // You can update the state with the response data if needed
        // state.employee = action.payload.employee;
        // state.contract = action.payload.contract;
        // state.user = action.payload.user;
      })
      .addCase(saveEmployeeDataToBackend.rejected, (state) => {
        state.loading = false;
        // Handle the error state if needed
      })
      .addCase(getEmployeefrombackend.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeefrombackend.fulfilled, (state, action) => {
        state.loading = false;

        state.employee = action.payload!.employee;
        state.contract = action.payload!.contract;
        state.user = action.payload!.user;
      })
      .addCase(getEmployeefrombackend.rejected, (state) => {
        state.loading = false;
        // Handle the error state if needed
      });
  },
});

export const {
  updateEmployeeValue,
  updateContractValue,
  updateUserValue,
  setLoading,
  resetForm,
} = EmpformSlice.actions;

export default EmpformSlice.reducer;
