import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { RequestLeavesUrl } from '@/variables/Urls';
import { RequestLeaveState } from '@/enums/RequestLeaveState';

const apiEndpoint = RequestLeavesUrl;

export enum RequestLeaveStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export interface RequestLeaveStateChange {
  requestLeaveId: string; // Assuming Guid maps to a string in TypeScript
  state: RequestLeaveState;
  comment?: string;
}
export interface RequestLeave {
  id?: string;
  state?: string;
  comment?: string;
  note?: string;
  fromDate: Date;
  toDate: Date;
  vacationId: string;
  employeeId?: string;
  isDraft?: boolean;
  vacationName?: string;
  employeeFullName?: string;
}

export interface RequestLeavesResponse {
  requestLeaves: RequestLeave[];
  count: number;
}

export interface PaginatingParams {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
  companyId?: string;
  employeeId?: string;
  employeeFullName?: string;
  teamLeaderId?: string;
  requestLeaveState?: RequestLeaveState;
  fromDate?: Date;
  toDate?: Date;
  departmentId?: string;
}

export async function getRequestLeaves({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  requestLeaveState,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
        requestLeaveState,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByCompanyId', config);
    return data as RequestLeavesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}
export async function getRequestLeave(requestLeaveId: string) {
  try {
    const config = {
      params: {
        id: requestLeaveId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetById', config);
    return data as RequestLeave;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}
export async function getRequestLeavesByEmployee({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  employeeId,
  fromDate,
  toDate,
  requestLeaveState,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
        fromDate: fromDate,
        toDate: toDate,
        requestLeaveState: requestLeaveState,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByEmployeeId', config);

    return data as RequestLeavesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}
export async function getRequestLeavesByTeamLeaderId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  teamLeaderId,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        teamLeaderId: teamLeaderId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByTeamLeaderId', config);

    return data as RequestLeavesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}
export async function getRequestLeavesByTeam({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  employeeId,
  fromDate,
  toDate,
  requestLeaveState,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
        fromDate: fromDate,
        toDate: toDate,
        requestLeaveState: requestLeaveState,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByTeam', config);

    return data as RequestLeavesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}

export async function saveRequestLeave(Requestleave: RequestLeave) {
  if (Requestleave.id) {
    const body = { ...Requestleave };

    try {
      const response = await api.put(apiEndpoint + '/put', body);
      return response;
    } catch (error) {
      console.log(
        '🚀 ~ file: CompanyService.ts:83 ~ leaveRequest ~ error:',
        error
      );
    }
  }
  try {
    const response_1 = await api.post(apiEndpoint + '/post', Requestleave);
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export function deleteRequestLeave(RequestleaveId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: RequestleaveId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      console.log(error.response);
      return error;
    });
}

export async function cancelRequestLeave(RequestleaveId: string) {
  try {
    const response_1 = await api.post(apiEndpoint + '/Cancel', {
      RequestleaveId: RequestleaveId,
    });
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function changeRequestLeaveState(
  RequestleaveState: RequestLeaveStateChange
) {
  try {
    const response_1 = await api.post(
      apiEndpoint + '/ChangeState',
      RequestleaveState
    );
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function getRequestLeavesByDepartmentId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  departmentId,
  fromDate,
  toDate,
  requestLeaveState,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        departmentId: departmentId,
        fromDate: fromDate,
        toDate: toDate,
        requestLeaveState: requestLeaveState,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByDepartmentId', config);

    return data as RequestLeavesResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}

export async function validateDraftRequest(requestLeaveId: string) {
  try {
    const response_1 = await api.post(apiEndpoint + '/ValidateDraft', {
      requestLeaveId: requestLeaveId,
    });
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}
