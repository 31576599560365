import TextArea from '@/components/TextArea';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { InterviewState } from '@/enums/InterviewState';
import { UserRoles } from '@/enums/UsersRole';
import { LastYearReviewModel } from '@/models/AnnualInterviewModel';
import { RootState } from '@/state';
import {
  saveAnnualInterviewAsync,
  updateAttitude,
  updateLastYearReview,
  updateTrainingNeeded,
} from '@/state/slices/annualInterviewSlice';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
const init: LastYearReviewModel = {
  lastYearReviewByEmployee: '',
  lastYearReviewByResponsable: '',
};
function LastYearReview() {
  const { t } = useTranslation();
  const { user } = useAuth();
  // const isTeamLeader = user?.isTeamLeader == 'True';

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const lastYearReviewByEmployee = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.lastYearReviewByEmployee
  );
  const lastYearReviewByResponsable = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.lastYearReviewByResponsable
  );
  const disabled = annualInerview.interviewState == InterviewState.Completed;

  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  useEffect(() => {
    console.error('Component re-rendered:', lastYearReviewByResponsable);
  }, [lastYearReviewByResponsable]);
  const formik = useFormik({
    initialValues: {
      ...init,
      lastYearReviewByEmployee: lastYearReviewByEmployee,
      lastYearReviewByResponsable: lastYearReviewByResponsable,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(updateLastYearReview({ lastYearReview: values }));
    },
  });
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <div className="flex justify-between gap-4">
          <div className="group relative mb-6 w-full">
            <TextArea
              id="lastYearReviewByEmployee"
              name="lastYearReviewByEmployee"
              label={t(
                'annualInterviewForm.LastYearReview.lastYearReviewByEmployee'
              )}
              value={formik.values.lastYearReviewByEmployee ?? ''}
              onChange={formik.handleChange}
              disabled={isTeamLeader || disabled}
            />
          </div>
          <div className="group relative mb-6 w-full">
            <TextArea
              id="lastYearReviewByResponsable"
              name="lastYearReviewByResponsable"
              label={t(
                'annualInterviewForm.LastYearReview.lastYearReviewByResponsable'
              )}
              value={formik.values.lastYearReviewByResponsable ?? ''}
              onChange={formik.handleChange}
              disabled={!isTeamLeader || disabled}
            />
          </div>
        </div>
      </div>

      {!disabled && (
        <div className="flex justify-between">
          <LabelButton type="button" onClick={() => resetForm()}>
            {t('annualInterviewForm.LastYearReview.reset')}
          </LabelButton>
          <PrimaryButton
            className="border-0 bg-purple-300"
            type="button"
            onClick={handleSyncData}
          >
            <div className="flex items-center gap-2">
              <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
              {t('annualInterviewForm.LastYearReview.Sync')}
            </div>
          </PrimaryButton>
          <PrimaryButton type="submit" className="border-0 bg-primary-300">
            {t('annualInterviewForm.LastYearReview.submit')}
          </PrimaryButton>
        </div>
      )}
    </form>
  );
}

export default LastYearReview;
