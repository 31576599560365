import { TeamsUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Employee } from './EmployeeService';

export interface Team {
  title: string;
  id?: string;
  teamLeaderId: string;
  departmentId?: string;
  companyId: string;
  branchId?: string;
  employeeIds?: string[];
  employees?: Employee[];
  teamLeadReplacementId: string;
}

export interface TeamsResponse {
  teams: Team[];
  count: number;
}

const apiEndpoint = TeamsUrl;

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  departmentId?: string;
}

export async function getTeams({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByCompanyId', config);
    return data as TeamsResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: TeamService.ts:26 ~ getTeams ~ ex:', ex);
  }
}
export async function getTeamsByDepartment({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  departmentId,
}: PaginatingParams) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
        departmentId: departmentId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByDepartmentId', config);
    return data as TeamsResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: TeamService.ts:26 ~ getTeams ~ ex:', ex);
  }
}

export async function getTeam(teamId: string) {
  try {
    let config = {
      params: {
        teamId: teamId,
      },
    };
    const { data } = await api.get<Team>(`${apiEndpoint}/GetById`, config);

    return data;
  } catch (error) {
    console.log('🚀 ~ file: TeamService.ts:38 ~ getTeam ~ error:', error);
  }
}
export async function getTeamByTeamLeaderId(teamLeaderId: string) {
  try {
    let config = {
      params: {
        teamLeaderId: teamLeaderId,
      },
    };
    const { data } = await api.get<Team>(
      `${apiEndpoint}/GetByTeamLeaderId`,
      config
    );

    return data;
  } catch (error) {
    console.log('🚀 ~ file: TeamService.ts:38 ~ getTeam ~ error:', error);
  }
}

export async function saveTeam(team: Team) {
  if (team.id) {
    const body = { ...team };

    try {
      const response = await api.put(`${apiEndpoint}/put`, body);
      return response.data;
    } catch (error) {
      console.log('🚀 ~ file: TeamService.ts:50 ~ saveTeam ~ error:', error);
    }
  }

  try {
    const response = await api.post(apiEndpoint + '/post', team);
    return response.data;
  } catch (error) {
    console.log('🚀 ~ file: TeamService.ts:57 ~ saveTeam ~ error:', error);
  }
}

export function deleteTeam(teamId: string) {
  return api
    .delete(`${apiEndpoint}/delete`, { data: { id: teamId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      return error;
    });
}
