import {
  TrainingSatisfactionForm,
  getTrainingSatisfactionForm,
  updateTrainingSatisfactionForm,
} from '@/services/TrainingSatisfactionFormService';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Update with the correct path
import debounce from 'lodash/debounce';
import Spinner from '@/components/Spinner';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import DefaultLayout from '@/layout/DefaultLayout';
import TextArea from '@/components/TextArea';
import Input from '@/components/Input';
import toast from 'react-hot-toast';
import { TrainingSatisfactionFormState } from '@/enums/TrainingSatisfactionFormState';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { UserResponsibility } from '@/enums/UserResponsibility';

const calculateAverages = (formData: TrainingSatisfactionForm) => {
  // Section 1: Training Provider Review
  const trainingProviderReviewTotal =
    (formData.communicationBeforeTraining +
      formData.trainingStartDelay +
      formData.trainingDuration +
      formData.commitmentAdherence) /
    4;

  // Section 2: Resource Review
  const resourceReviewTotal: number =
    (formData.generalWorkspace +
      formData.facilities +
      formData.providedMaterials +
      formData.equipmentQuality) /
    4;

  // Section 3: Pedagogy Review
  const pedagogyReviewTotal: number =
    (formData.difficultyLevel +
      formData.themeArticulation +
      formData.theoreticalContentQuality +
      formData.practicalContentQuality +
      formData.progressionPace +
      formData.instructorPedagogyQuality +
      formData.instructorAvailability +
      formData.instructionQuality) /
    8;

  // Section 4: Workplace Review
  const workplaceReviewTotal: number =
    (formData.generalAmbiance +
      formData.attendanceAndMotivation +
      formData.heterogeneity +
      formData.attentionAndParticipation) /
    4;

  // Section 5: Benefit Review
  const benefitReviewTotal: number =
    (formData.trainingExpectationsAlignment + formData.trainingUsefulness) / 2;

  return {
    trainingProvider: trainingProviderReviewTotal,
    resource: resourceReviewTotal,
    pedagogy: pedagogyReviewTotal,
    workplace: workplaceReviewTotal,
    benefit: benefitReviewTotal,
  };
};

const CustomInput = ({
  type = 'number',
  value,
  min,
  max,
  name,
  handleInputChange,
  disabled,
}: any) => {
  // Use useCallback to memoize the debounced function
  const debouncedInputChange = useCallback(
    debounce((newValue: any) => {
      handleInputChange(name, newValue);
    }, 100), // 300ms delay, adjust as needed
    [handleInputChange, name]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      type === 'number'
        ? debouncedInputChange(Number(event.target.value))
        : handleInputChange(name, event.target.value);
  };

  let errorMessage = '';
  if (type === 'number') {
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      errorMessage = 'Please enter a valid number.';
    } else if (numericValue < 0 || numericValue > 10) {
      errorMessage = `Please enter a number between ${0} and ${10}.`;
    }
  }

  if ((name as string).includes('Comments')) {
    return (
      <TextArea
        label=""
        key={name}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
      />
    );
  }
  return (
    <div>
      <Input
        label=""
        key={name}
        name={name}
        type={type}
        min={min || 0}
        max={max || 10}
        value={value}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </div>
  );
};
const TrainingSatisfactionFormTable = ({}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [validationStates, setValidationStates] = useState({});
  const { user } = useAuth();

  const isTrainingRequestAdmin =
    user?.responsibilityClaim ===
    UserResponsibility[UserResponsibility.GestionDesFormations].toString();
  const [formData, setFormData] = useState<TrainingSatisfactionForm>({
    communicationBeforeTraining: 0,
    trainingStartDelay: 0,
    trainingDuration: 0,
    commitmentAdherence: 0,
    trainingProviderComments: '',
    generalWorkspace: 0,
    facilities: 0,
    providedMaterials: 0,
    equipmentQuality: 0,
    resourcesComments: '',
    difficultyLevel: 0,
    themeArticulation: 0,
    theoreticalContentQuality: 0,
    practicalContentQuality: 0,
    progressionPace: 0,
    instructorPedagogyQuality: 0,
    instructorAvailability: 0,
    instructionQuality: 0,
    pedagogyComments: '',
    generalAmbiance: 0,
    attendanceAndMotivation: 0,
    heterogeneity: 0,
    attentionAndParticipation: 0,
    workPlaceComments: '',
    trainingExpectationsAlignment: 0,
    trainingUsefulness: 0,
    benefitComments: '',
    generalAverageRating: 0,
    employeeId: '',
  });
  const [trainingProviderReviewAvg, setTrainingProviderReviewAvg] =
    useState<number>(0);
  let reviewAverages = useMemo(() => calculateAverages(formData), [formData]);
  let totalReviewAvg = useMemo(
    () =>
      (reviewAverages.benefit +
        reviewAverages.resource +
        reviewAverages.pedagogy +
        reviewAverages.trainingProvider +
        reviewAverages.workplace) /
      5,
    [reviewAverages]
  );
  const {
    data: trainingSFData,
    isLoading,
    refetch,
  } = useQuery<TrainingSatisfactionForm>(
    ['trainingSF', id],
    async () => await getTrainingSatisfactionForm(id!),
    {
      onSuccess: (data) => {
        setFormData(data);
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
    }
  );

  if (isLoading || trainingSFData === undefined) {
    return <Spinner />;
  }
  const valdiateInput = (fieldName: string, fieldValue: any) => {
    const numericValue = Number(fieldValue);

    if (!isNaN(numericValue)) {
      if (numericValue < 0 || numericValue > 10) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const handleInputChange = (fieldName: string, fieldValue: any) => {
    const isValid = valdiateInput(fieldName, fieldValue);

    setValidationStates((prevStates) => ({
      ...prevStates,
      [fieldName]: isValid,
    }));
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  const handleSaveSRFForm = async () => {
    const tRSFvalues = {
      ...formData,
      satisfactionFormId: id,
      generalAverageRating: totalReviewAvg,
    };
    const data = await updateTrainingSatisfactionForm(tRSFvalues);
    if (data) {
      toast.success(
        t('SuccessMessage', {
          name: t('ToastMsg.trainingRequestSatisfactionForm'),
        })
      );
      refetch();
    }
  };
  return (
    <DefaultLayout>
      <div className="relative overflow-x-auto">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">{t('TrainingSatisfaction.Type')}</th>
              <th className="px-6 py-3">
                {t('TrainingSatisfaction.Criteria')}
              </th>
              <th className="w-50 px-6 py-3">
                {t('TrainingSatisfaction.Rating')}
              </th>
              <th className="px-6 py-3">{t('TrainingSatisfaction.Note')}</th>
            </tr>
          </thead>
          <tbody>
            {/* Training Provider Review Form */}
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <th
                className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                rowSpan={5}
              >
                {t('TrainingSatisfaction.TrainingProviderReview')}
              </th>
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.CommunicationBeforeTraining')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  value={formData.communicationBeforeTraining}
                  name="communicationBeforeTraining"
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
              <td className="border-b px-4 py-2" rowSpan={5}>
                <Input
                  readOnly={user?.id !== formData.employeeId}
                  name="trainingProvider"
                  label=""
                  type="text"
                  value={reviewAverages.trainingProvider}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.TrainingStartDelay')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  value={formData.trainingStartDelay}
                  name="trainingStartDelay"
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.TrainingDuration')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  value={formData.trainingDuration}
                  name="trainingDuration"
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.CommitmentAdherence')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  value={formData.commitmentAdherence}
                  name="commitmentAdherence"
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.TrainingProviderComments')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  type="text"
                  value={formData.trainingProviderComments}
                  name="trainingProviderComments"
                  handleInputChange={handleInputChange}
                />
              </td>
            </tr>

            {/* Resource form  */}
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <th
                className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                rowSpan={5}
              >
                {t('TrainingSatisfaction.ResourcesReview')}
              </th>
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.GeneralWorkspace')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.generalWorkspace}
                  name="generalWorkspace"
                />
              </td>
              <td className="border-b px-4 py-2" rowSpan={5}>
                <Input
                  name="resource"
                  label=""
                  type="text"
                  disabled={user?.id !== formData.employeeId}
                  value={reviewAverages.resource}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.Facilities')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  value={formData.facilities}
                  name="facilities"
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.ProvidedMaterials')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  value={formData.providedMaterials}
                  name="providedMaterials"
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.EquipmentQuality')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  value={formData.equipmentQuality}
                  name="equipmentQuality"
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.ResourcesComments')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  type="text"
                  value={formData.resourcesComments}
                  name="resourcesComments"
                />
              </td>
            </tr>

            {/* Pedagogy Review Form  */}
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <th
                className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                rowSpan={9}
              >
                {t('TrainingSatisfaction.PedagogyReview')}
              </th>
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.DifficultyLevel')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.difficultyLevel}
                  name="difficultyLevel"
                />
              </td>
              <td className="border-b px-4 py-2" rowSpan={9}>
                <Input
                  name="pedagogy"
                  label=""
                  disabled={user?.id !== formData.employeeId}
                  type="text"
                  value={reviewAverages.pedagogy}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.ThemeArticulation')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.themeArticulation}
                  name="themeArticulation"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.TheoreticalContentQuality')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.theoreticalContentQuality}
                  name="theoreticalContentQuality"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.PracticalContentQuality')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.practicalContentQuality}
                  name="practicalContentQuality"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.ProgressionPace')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.progressionPace}
                  name="progressionPace"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.InstructorPedagogyQuality')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.instructorPedagogyQuality}
                  name="instructorPedagogyQuality"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.InstructorAvailability')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.instructorAvailability}
                  name="instructorAvailability"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.InstructionQuality')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  value={formData.instructionQuality}
                  name="instructionQuality"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.PedagogyComments')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  disabled={user?.id !== formData.employeeId}
                  handleInputChange={handleInputChange}
                  type="text"
                  value={formData.pedagogyComments}
                  name="pedagogyComments"
                />
              </td>
            </tr>

            {/* Work Place Review Form*/}
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <th
                className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                rowSpan={5}
              >
                {t('TrainingSatisfaction.WorkPlaceReview')}
              </th>
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.GeneralAmbiance')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  value={formData.generalAmbiance}
                  name="generalAmbiance"
                />
              </td>
              <td className="border-b px-4 py-2" rowSpan={5}>
                <Input
                  name="workplace"
                  label=""
                  type="text"
                  value={reviewAverages.workplace}
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.AttendanceAndMotivation')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  value={formData.attendanceAndMotivation}
                  name="attendanceAndMotivation"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.Heterogeneity')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  value={formData.heterogeneity}
                  name="heterogeneity"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.AttentionAndParticipation')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  value={formData.attentionAndParticipation}
                  name="attentionAndParticipation"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.WorkPlaceComments')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  type="text"
                  value={formData.workPlaceComments}
                  name="workPlaceComments"
                />
              </td>
            </tr>

            {/* Benefit Review Form*/}
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <th
                className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                rowSpan={3}
              >
                {t('TrainingSatisfaction.BenefitReview')}
              </th>
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.TrainingExpectationsAlignment')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  value={formData.trainingExpectationsAlignment}
                  name="trainingExpectationsAlignment"
                />
              </td>
              <td className="border-b px-4 py-2" rowSpan={3}>
                <Input
                  name="benefit"
                  label=""
                  type="text"
                  value={reviewAverages.benefit}
                  disabled={user?.id !== formData.employeeId}
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.TrainingUsefulness')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  value={formData.trainingUsefulness}
                  name="trainingUsefulness"
                />
              </td>
            </tr>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="border-b px-4 py-2">
                {t('TrainingSatisfaction.BenefitComments')}
              </td>
              <td className="border-b px-4 py-2">
                <CustomInput
                  handleInputChange={handleInputChange}
                  disabled={user?.id !== formData.employeeId}
                  type="text"
                  value={formData.benefitComments}
                  name="benefitComments"
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="font-semibold text-gray-900 dark:text-white">
              <th scope="row" className="px-6 py-3 text-base" colSpan={3}>
                Total
              </th>

              <td className="px-6 py-3">{totalReviewAvg}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      {formData.trainingSatisfactionFormState !==
        TrainingSatisfactionFormState.Submitted &&
        user?.id == formData.employeeId && (
          <button
            type="submit"
            className="  float-right mb-5 mr-2 inline-flex items-center justify-end rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
            disabled={
              isLoading ||
              Object.values(validationStates).some((isValid) => !isValid)
            }
            onClick={handleSaveSRFForm}
          >
            {t('TrainingSatisfaction.Send')}
          </button>
        )}
    </DefaultLayout>
  );
};

export default TrainingSatisfactionFormTable;
