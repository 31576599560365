export enum AssignedTask {
  ActionFollowUp,
  DeliverablesFollowUp,
  MeetingFollowUp,
  Planning,
  Methods,
  Quality,
  HSE,
  PriceStudy,
  ContractManager,
  Purchases,
  Finance,
  Commercial,
}

export const AssignedTaskOptions = Object.keys(AssignedTask)
  .filter((key) => isNaN(Number(AssignedTask[key])))
  .map((key) => ({
    id: key,
    name: AssignedTask[key],
  }));
