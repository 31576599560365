import DynamicTable, {
  EnumRequestLeaveStatesFilter,
} from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import {
  TransferRequestLeaveStateChange,
  VacationBalanceTransferRequest,
  cancelTransferRequestLeave,
  changeTransferRequestLeaveState,
  getTransferRequestLeavesByTeamLeaderId,
  getTransferRequestsByEmployeeId,
  validateDraftTransferRequest,
} from '@/services/VacationBalanceTransferRequestService';
import { useQuery } from '@tanstack/react-query';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RequestALeaveModal from './RequestALeaveModal';
import TransferRequestALeaveModal from './TransferRequestALeaveModal';
import { RequestLeaveState, stateColors } from '@/enums/RequestLeaveState';
import {
  RequestLeave,
  RequestLeaveStateChange,
  cancelRequestLeave,
  validateDraftRequest,
} from '@/services/RequestLeavesService';
import { setLoading } from '@/state/slices/employeeSlice';
import toast from 'react-hot-toast';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';

const ValidateTransferRequestsVacationTable = () => {
  const { t } = useTranslation(); // Use the default namespace
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [selectedRow, setSelectedRow] =
    useState<VacationBalanceTransferRequest | null>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user } = useAuth();
  const {
    data: transferRequestsData,
    isLoading: transferRequestsLoading,
    refetch: refetchREmployee,
    isSuccess,
  } = useQuery(
    ['TransferRequestVacationsByTeamId', user?.id, pageIndex, pageSize, search],
    () =>
      getTransferRequestLeavesByTeamLeaderId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  /* change states function  */
  const handleCancelReject = () => {
    setIsModalVisible(false);
  };

  const handleRejectConfirmation = async () => {
    // !!!handle reject request
    setLoading(true);
    const reqState: TransferRequestLeaveStateChange = {
      id: selectedRow?.id!,
      state: RequestLeaveState.Approved,
      comment: selectedRow?.comment,
    };
    const data = await changeTransferRequestLeaveState(reqState);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('RequestLeaveState.Rejected'),
        })
      );
    }
    setLoading(false);
    setIsModalVisible(false);
  };
  const handleApproveRequest = async (
    reqLeave: VacationBalanceTransferRequest
  ) => {
    // !!!handle approve request
    setLoading(true);
    const reqState: TransferRequestLeaveStateChange = {
      id: reqLeave?.id!,
      state: RequestLeaveState.Approved,
      comment: reqLeave.comment,
    };
    const data = await changeTransferRequestLeaveState(reqState);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('Approved'),
        })
      );
    }
    setLoading(false);
    setIsModalVisible(false);
  };

  const ActionButtonsCell = ({ value }: any) => {
    const [trequestLeave, setTRequestLeave] =
      useState<VacationBalanceTransferRequest>();

    useEffect(() => {
      if (isSuccess && transferRequestsData) {
        let res = transferRequestsData?.transferRequests!.find(
          (r) => r.id === value
        )!;
        setTRequestLeave(res);
      }
    }, [isSuccess, transferRequestsData, value, selectedRow]);

    const frontendState =
      RequestLeaveState[parseInt(trequestLeave?.state!) ?? 0];
    const bgColor = stateColors[frontendState] || 'bg-gray-300';

    const trequestStateComp = (
      <div
        className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
      >
        {frontendState !== null ? t(`RequestLeaveState.${frontendState}`) : ''}
      </div>
    );

    return trequestLeave?.state == RequestLeaveState.Pending.toString() ? (
      <div>
        <EditButton onClick={() => handleApproveRequest(trequestLeave!)}>
          {t('Requestleave.Approve')} {/* Translate the delete button */}
        </EditButton>

        <DeleteButton
          type="button"
          onClick={() => {
            setSelectedRow(trequestLeave);
            setIsModalVisible(true);
          }}
        >
          {t('Requestleave.Reject')} {/* Translate the edit button */}
        </DeleteButton>
      </div>
    ) : (
      trequestStateComp
    );
  };

  const pageCount = useMemo(() => {
    return Math.ceil((transferRequestsData?.count ?? 0) / pageSize);
  }, [pageSize, transferRequestsData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('transferRequestVacation.VacationName'),
        accessor: 'vacationName',
      },
      {
        Header: t('transferRequestVacation.RequestedDays'),
        accessor: 'days',
      },

      {
        Header: t('RequestLeaves.comment'),
        accessor: 'comment',
      },
      {
        Header: t('transferRequestVacation.State'),
        accessor: 'state',
        Filter: EnumRequestLeaveStatesFilter,
        Cell: ({ cell: { value } }) => {
          const frontendState = RequestLeaveState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => <ActionButtonsCell value={value} />,
      },
    ],
    [t, isSuccess]
  );
  return (
    <div className="flex flex-col">
      <DynamicTable
        dataCount={transferRequestsData?.count ?? 0}
        data={transferRequestsData?.transferRequests ?? []}
        columns={columns}
        setSearch={setSearch}
        exportPdf={false}
      />
      {isModalVisible && (
        <DeleteConfirmationModal
          isOpen={isModalVisible}
          onDelete={handleRejectConfirmation}
          onCancel={handleCancelReject}
          textContent="Requestleave.CancelRequestMSG"
        />
      )}

      <Pagination
        pageIndex={pageCount === 0 ? -1 : pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

export default ValidateTransferRequestsVacationTable;
