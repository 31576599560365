import { UserRoles } from '@/enums/UsersRole';
import { ModuleType } from '@/models/ModuleTypeEnum';
import {
  AdjustmentsIcon,
  UserGroupIcon,
  ClockIcon,
  CalendarIcon,
  CurrencyEuroIcon,
  ClipboardIcon,
  AcademicCapIcon,
  UsersIcon,
  IdentificationIcon,
  ChartSquareBarIcon,
  ChartPieIcon,
  CogIcon,
  ClipboardListIcon,
  ClipboardCheckIcon,
  UserIcon,
  DocumentIcon,
} from '@heroicons/react/solid';
import BidIcon from '/src/images/icon/bid.svg';
import Teamwork from '/src/images/icon/teamwork.svg';
import ManageAbsence from '/src/images/icon/calendar.svg';
import BranchIcon from '/src/images/icon/branch.svg';
import TeamIcon from '/src/images/icon/department.svg';
import DepartmentIcon from '/src/images/icon/office.svg';
import EmployeesIcon from '/src/images/icon/team.svg';
import AbsentsIcon from '/src/images/icon/absent.svg';
import CashFlowIcon from '/src/images/icon/cash-flow.svg';
import doctorVisitIcon from '/src/images/icon/doctor-visit.svg';
import ApplicationIcon from '/src/images/icon/application.svg';
import InterviewIcon from '/src/images/icon/interview.svg';
import TrainingIcon from '/src/images/icon/training.svg';
import PresentationIcon from '/src/images/icon/presentation.svg';
import FeedBackFormIcon from '/src/images/icon/feedback-form.svg';
import TrainingProviderIcon from '/src/images/icon/graduate.svg';
import jobProfileIcon from '/src/images/icon/jobProfile.svg';
import RapportIcon from '/src/images/icon/report.svg';
import RapportTrainingIcon from '/src/images/icon/reportTraining.svg';
import AnalysisIcon from '/src/images/icon/analysis.svg';
import HealthReportIcon from '/src/images/icon/health-report.svg';
import MechanicalGearIcon from '/src/images/icon/mechanical-gears-.svg';
import TimetableIcon from '/src/images/icon/timetable.svg';
import HolidayIcon from '/src/images/icon/holiday.svg';
import DashboardIcon from '/src/images/icon/dashboard.svg';
import UserSpaceIcon from '/src/images/icon/user.svg';
import OrgChartIcon from '/src/images/icon/chart.svg';
import CVFileIcon from '/src/images/icon/cv.svg';
import PlanREAIcon from '/src/images/icon/plan.svg';
import PartnersAIcon from '/src/images/icon/partner.svg';
import SubcontractingIcon from '/src/images/icon/subcontracting.svg';
import CompetitorsIcon from 'src/images/icon/competitor-analysis.svg';
import SupplierIcon from 'src/images/icon/supplier.svg';
import MaterialIcon from 'src/images/icon/construction.svg';
import PersonalCostIcon from 'src/images/icon/save-money.svg';
import SubcontractorIcon from 'src/images/icon/subcontractor.svg';
import ProjectOwnerIcon from 'src/images/icon/manager.svg';
import ProjectExecutorIcon from 'src/images/icon/agent.svg';

import { UserResponsibility } from '@/enums/UserResponsibility';
export interface MenuType {
  to: string;
  pathname: string;
  title: string;
  isMenu?: boolean;
  IconSvg?: any;
  menuItems?: MenuType[];
  isDisabled?: boolean;
  isAllowed?: boolean;
  isResponsibiliy?: boolean;
  moduleType?: string | number;
  allowedRoles?: string[];
  allowedResponsibility?: string[];
}

export const MenusItem: MenuType[] = [
  {
    to: '/dashbord',
    pathname: 'dashbord',
    title: 'dashbord',
    isAllowed: true,
    allowedRoles: [UserRoles.Admin, UserRoles.TeamLeader, UserRoles.Employee],
    IconSvg: DashboardIcon,
  },

  {
    to: '/settings',
    pathname: 'settings',
    title: 'settings',
    isAllowed: true,
    isMenu: true,
    allowedRoles: [UserRoles.Admin],
    IconSvg: MechanicalGearIcon,
    menuItems: [
      {
        to: '/settings/WorkCalendars',
        pathname: 'WorkCalendars',
        title: 'WorkCalendars',
        IconSvg: TimetableIcon,
        allowedRoles: [UserRoles.Admin],
      },
      {
        to: '/settings/vacations',
        pathname: 'vacations',
        title: 'Vacations',
        IconSvg: HolidayIcon,
        allowedRoles: [UserRoles.Admin],
      },
      {
        to: '/settings/partners',
        pathname: 'partners',
        title: 'partners',
        IconSvg: PartnersAIcon,
        allowedRoles: [UserRoles.Admin],
      },
      // {
      //   to: '/settings/Subcontractes',
      //   pathname: 'Subcontractes',
      //   title: 'Subcontractes',
      //   IconSvg: SubcontractingIcon,
      //   allowedRoles: [UserRoles.Admin],
      // },
      {
        to: '/settings/Competitors',
        pathname: 'Competitors',
        title: 'Competitors',
        IconSvg: CompetitorsIcon,
        allowedRoles: [UserRoles.Admin],
      },
      {
        to: '/settings/ProjectOwners',
        pathname: 'ProjectOwners',
        title: 'ProjectOwners',
        IconSvg: ProjectOwnerIcon,
        allowedRoles: [UserRoles.Admin],
      },
      {
        to: '/settings/ProjectExecutors',
        pathname: 'ProjectExecutors',
        title: 'ProjectExecutors',
        IconSvg: ProjectExecutorIcon,
        allowedRoles: [UserRoles.Admin],
      },
    ],
  },

  {
    to: '/gestion-resources-humains',
    pathname: 'gestion-resources-humains',
    title: 'Resources Humains',
    moduleType: ModuleType.GestionDesRH,
    allowedRoles: [UserRoles.Admin, UserRoles.TeamLeader, UserRoles.Employee],
    isMenu: true,
    IconSvg: Teamwork,

    menuItems: [
      {
        to: '/gestion-resources-humains/gestion-des-absences',
        pathname: 'gestion-des-absences',
        title: 'Gestion des absences',
        moduleType: ModuleType.GestionDesAbsences,
        IconSvg: ManageAbsence,
        allowedRoles: [
          UserRoles.Admin,
          UserRoles.TeamLeader,
          UserRoles.Employee,
        ],

        isMenu: true,
        menuItems: [
          {
            to: '/gestion-resources-humains/gestion-des-absences/branches',
            pathname: 'branches',
            title: 'Branch',
            allowedRoles: [UserRoles.Admin],
            isResponsibiliy: true,
            IconSvg: BranchIcon,
            allowedResponsibility: [
              UserResponsibility[UserResponsibility.GestionDesRH].toString(),
            ],
          },
          {
            to: '/gestion-resources-humains/gestion-des-absences/departements',
            pathname: 'departements',
            title: 'Departement',
            allowedRoles: [UserRoles.Admin],
            isResponsibiliy: true,
            IconSvg: DepartmentIcon,
            allowedResponsibility: [
              UserResponsibility[UserResponsibility.GestionDesRH].toString(),
            ],
          },

          {
            to: '/gestion-resources-humains/gestion-des-absences/employees',
            pathname: 'employees',
            title: 'Employees',
            IconSvg: EmployeesIcon,
            allowedRoles: [UserRoles.Admin],
            isResponsibiliy: true,
            allowedResponsibility: [
              UserResponsibility[UserResponsibility.GestionDesRH].toString(),
            ],
          },
          {
            to: '/gestion-resources-humains/gestion-des-absences/teams',
            pathname: 'teams',
            title: 'Teams',
            IconSvg: TeamIcon,
            allowedRoles: [UserRoles.Admin],
            isResponsibiliy: true,
            allowedResponsibility: [
              UserResponsibility[UserResponsibility.GestionDesRH].toString(),
            ],
          },
          {
            to: '/gestion-resources-humains/gestion-des-absences/absense-management',
            pathname: 'absense-management',
            title: 'Absense Management',
            allowedRoles: [UserRoles.Admin],
            IconSvg: AbsentsIcon,
            isResponsibiliy: true,
            allowedResponsibility: [
              UserResponsibility[UserResponsibility.GestionDesRH].toString(),
            ],
          },
          {
            to: '/gestion-resources-humains/gestion-des-absences/absense-pannel',
            pathname: 'absense-pannel',
            title: 'Absense Pannel',
            allowedRoles: [UserRoles.Employee, UserRoles.TeamLeader],
          },
        ],
      },
      {
        to: '/gestion-resources-humains/gestion-note-de-frais',
        pathname: 'gestionnotedefrais',
        title: 'Gestion note de frais',
        moduleType: ModuleType.GestionNoteDeFrais,
        allowedRoles: [
          UserRoles.Admin,
          UserRoles.TeamLeader,
          UserRoles.Employee,
        ],
        IconSvg: CashFlowIcon,
      },
      {
        to: '/gestion-resources-humains/gestion-de-visite-medicale',
        pathname: 'gestiondevisitemedicale',
        title: 'Gestion de visite médicale',
        IconSvg: doctorVisitIcon,
        moduleType: ModuleType.GestionDeVisiteMedicale,
        allowedRoles: [
          UserRoles.Admin,
          UserRoles.TeamLeader,
          UserRoles.Employee,
        ],

        //  IconSvg: <ClipboardIcon />,
      },
      {
        to: '/gestion-resources-humains/gestion-des-documents',
        pathname: 'gestiondesdocuments',
        title: 'Gestion de documents',
        moduleType: ModuleType.GestionDesDocuments,
        allowedRoles: [
          UserRoles.Admin,
          UserRoles.TeamLeader,
          UserRoles.Employee,
        ],

        IconSvg: ApplicationIcon,
      },
      {
        to: '/gestion-resources-humains/gestion-entretien-annuel',
        pathname: 'gestionentretienannuel',
        title: 'Gestion entretien annuel',
        allowedRoles: [
          UserRoles.Admin,
          UserRoles.TeamLeader,
          UserRoles.Employee,
        ],
        moduleType: ModuleType.GestionEntretienAnnuel,
        IconSvg: InterviewIcon,
      },
      {
        to: '/gestion-resources-humains/gestion-des-formations',
        pathname: 'gestiondesformations',
        title: 'Gestion des formations',
        moduleType: ModuleType.GestionDesFormations,
        IconSvg: TrainingIcon,
        isMenu: true,
        allowedRoles: [
          UserRoles.Admin,
          UserRoles.TeamLeader,
          UserRoles.Employee,
        ],
        menuItems: [
          {
            to: '/gestion-resources-humains/gestion-des-formations/formations',
            pathname: 'Formation',
            title: 'Training',
            IconSvg: PresentationIcon,
            allowedRoles: [
              UserRoles.Admin,
              UserRoles.TeamLeader,
              UserRoles.Employee,
            ],
          },
          {
            to: '/gestion-resources-humains/gestion-des-formations/satification-forms',
            pathname: 'SatifactionForm',
            title: 'SatifactionForm',
            allowedRoles: [UserRoles.Admin],
            isResponsibiliy: true,
            IconSvg: FeedBackFormIcon,
            allowedResponsibility: [
              UserResponsibility[
                UserResponsibility.GestionDesFormations
              ].toString(),
            ],
          },
          {
            to: '/gestion-resources-humains/gestion-des-formations/training-providers',
            pathname: 'TrainingProviders',
            title: 'TrainingProviders',
            isResponsibiliy: true,
            allowedRoles: [UserRoles.Admin],
            IconSvg: TrainingProviderIcon,
            allowedResponsibility: [
              UserResponsibility[
                UserResponsibility.GestionDesFormations
              ].toString(),
            ],
          },
        ],
      },
      {
        to: '/gestion-resources-humains/gestion-des-fiches-de-postes-cv',
        pathname: 'gestiondesfichesdepostescv',
        title: 'Gestion des fiches de postes / CV',
        moduleType: ModuleType.GestionDesFichesDePostesCV,
        IconSvg: jobProfileIcon,
        allowedRoles: [UserRoles.Admin],
        isResponsibiliy: true,
        allowedResponsibility: [
          UserResponsibility[
            UserResponsibility.GestionDesFichesDePostesCV
          ].toString(),
        ],
      },
    ],
  },

  {
    to: '/gestion-project-ao',
    pathname: 'gestion-project-ao',
    title: 'Project AO',
    moduleType: ModuleType.GestionDesPOA,
    allowedRoles: [UserRoles.Admin, UserRoles.TeamLeader, UserRoles.Employee],
    isMenu: false,
    IconSvg: BidIcon,
    // menuItems: [
    //   {
    //     to: '/gestion-project-ao/gestion-des-st',
    //     pathname: 'gestiondesst',
    //     title: 'Gestion des S/T',

    //     moduleType: ModuleType.GestionDesSTPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-des-intervenants',
    //     pathname: 'gestiondesintervenants',
    //     title: 'Gestion des intervenants',
    //     moduleType: ModuleType.GestionDesIntervenantsPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-des-plannings',
    //     pathname: 'gestiondesplannings',
    //     title: 'Gestion des plannings',
    //     moduleType: ModuleType.GestionDesPlanningsPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-des-livrables',
    //     pathname: 'gestiondeslivrables',
    //     title: 'Gestion des livrables',
    //     moduleType: ModuleType.GestionDesLivrablesPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-de-l-etude-de-prix',
    //     pathname: 'gestionetudedePrix',
    //     title: "Gestion de l'étude de prix",
    //     moduleType: ModuleType.GestionEtudeDePrixPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-methodes',
    //     pathname: 'gestionmethodes',
    //     title: 'Gestion Méthodes',
    //     moduleType: ModuleType.GestionMethodesPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-qualite',
    //     pathname: 'gestionqualite',
    //     title: 'Gestion Qualité',
    //     moduleType: ModuleType.GestionQualitePOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-hse',
    //     pathname: 'gestionhse',
    //     title: 'Gestion HSE',
    //     moduleType: ModuleType.GestionHSEPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-des-etudes',
    //     pathname: 'gestiondesetudes',
    //     title: 'Gestion des Etudes',
    //     moduleType: ModuleType.GestionDesEtudesPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-des-risques',
    //     pathname: 'gestiondesrisques',
    //     title: 'Gestion des Risques',
    //     moduleType: ModuleType.GestionDesRisquesPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    //   {
    //     to: '/gestion-project-ao/gestion-des-reunions',
    //     pathname: 'gestiondesreunions',
    //     title: 'Gestion des Réunions',
    //     moduleType: ModuleType.GestionDesReunionsPOA,
    //     allowedRoles: [
    //       UserRoles.Admin,
    //       UserRoles.TeamLeader,
    //       UserRoles.Employee,
    //     ],
    //   },
    // ],
  },
  {
    to: '/gestion-project-rea',
    pathname: 'gestion-project-rea',
    title: 'Project REA',
    moduleType: ModuleType.GestionDesPREA,
    allowedRoles: [UserRoles.Admin, UserRoles.TeamLeader, UserRoles.Employee],
    isMenu: true,
    IconSvg: PlanREAIcon,
    menuItems: [
      {
        to: '/gestion-project-rea/gestion-des-st',
        pathname: 'gestiondesst',
        title: 'Gestion des S/T',
        //moduleType: 'GestionDesST',
      },
      {
        to: '/gestion-project-rea/gestion-des-intervenants',
        pathname: 'gestiondesintervenants',
        title: 'Gestion des intervenants',
        //moduleType: 'GestionDesIntervenants',
      },
      {
        to: '/gestion-project-rea/gestion-des-plannings',
        pathname: 'gestiondesplannings',
        title: 'Gestion des plannings',
        //moduleType: 'GestionDesPlannings',
      },
      {
        to: '/gestion-project-rea/gestion-des-livrables',
        pathname: 'gestiondeslivrables',
        title: 'Gestion des livrables',
        //moduleType: 'GestionDesLivrables',
      },
      {
        to: '/gestion-project-rea/gestion-de-l-etude-de-prix',
        pathname: 'gestionetudedePrix',
        title: "Gestion de l'étude de prix",
        //moduleType: 'GestionEtudeDePrix',
      },
      {
        to: '/gestion-project-rea/gestion-methodes',
        pathname: 'gestionmethodes',
        title: 'Gestion Méthodes',
        //moduleType: 'GestionMethodes',
      },
      {
        to: '/gestion-project-rea/gestion-qualite',
        pathname: 'gestionqualite',
        title: 'Gestion Qualité',
        //moduleType: 'GestionQualite',
      },
      {
        to: '/gestion-project-rea/gestion-hse',
        pathname: 'gestionhse',
        title: 'Gestion HSE',
        //moduleType: 'GestionHSE',
      },
      {
        to: '/gestion-project-rea/gestion-des-etudes',
        pathname: 'gestiondesetudes',
        title: 'Gestion des Etudes',
        //moduleType: 'GestionDesEtudes',
      },
      {
        to: '/gestion-project-rea/gestion-des-risques',
        pathname: 'gestiondesrisques',
        title: 'Gestion des Risques',
        //moduleType: 'GestionDesRisques',
      },
      {
        to: '/gestion-project-rea/gestion-des-reunions',
        pathname: 'gestiondesreunions',
        title: 'Gestion des Réunions',
        //moduleType: 'GestionDesReunions',
      },
    ],
  },
  {
    to: '/gestion-Materials',
    pathname: 'gestion-Materials',
    title: 'GestionDesMaterials',
    moduleType: ModuleType.GestionDesMaterials,
    allowedRoles: [UserRoles.Admin, UserRoles.TeamLeader, UserRoles.Employee],
    isMenu: false,
    IconSvg: MaterialIcon,
    isResponsibiliy: true,
    allowedResponsibility: [
      UserResponsibility[UserResponsibility.GestionDesMaterials].toString(),
    ],
  },
  {
    to: '/gestion-supplier-Orders',
    pathname: 'gestion-supplier-Orders',
    title: 'GestionDesSupplierOrders',
    moduleType: ModuleType.GestionDesFournisseurs,
    allowedRoles: [UserRoles.Admin],

    isResponsibiliy: true,
    allowedResponsibility: [
      UserResponsibility[UserResponsibility.GestionDesFournisseurs].toString(),
    ],
    isMenu: false,
    IconSvg: SupplierIcon,
  },
  {
    to: '/gestion-company-SubContractor',
    pathname: 'gestion-company-SubContractor',
    title: 'GestionDesCompanySubcontractors',
    moduleType: ModuleType.GestionDesST,
    allowedRoles: [UserRoles.Admin],
    isMenu: false,
    isResponsibiliy: true,
    allowedResponsibility: [
      UserResponsibility[UserResponsibility.GestionDesST].toString(),
    ],
    IconSvg: SubcontractorIcon,
  },
  {
    to: '/gestion-personnel-cost',
    pathname: 'gestion-personnel-cost',
    title: 'GestionDesPersonnelCout',
    // moduleType: ModuleType.Gestio,
    allowedRoles: [UserRoles.Admin, UserRoles.TeamLeader, UserRoles.Employee],
    isAllowed: true,
    isMenu: false,
    IconSvg: PersonalCostIcon,
  },
  {
    to: '/gestion-des-rapports',
    pathname: 'gestiondesrapports',
    title: 'Gestion des rapports',
    moduleType: ModuleType.GestionDesRapports,
    isMenu: true,
    allowedRoles: [UserRoles.Admin],
    IconSvg: RapportIcon,
    menuItems: [
      {
        to: '/gestion-des-rapports/RhIndicators',
        pathname: 'RhIndicators',
        title: 'RH Indicators',
        IconSvg: AnalysisIcon,
        allowedRoles: [UserRoles.Admin],
      },
      {
        to: '/gestion-des-rapports/FormationsIndicators',
        pathname: 'FormationsIndicators',
        IconSvg: RapportTrainingIcon,
        title: 'Formations Indicators',
        allowedRoles: [UserRoles.Admin],
      },
      {
        to: '/gestion-des-rapports/AppointmentsIndicators',
        pathname: 'AppointmentsIndicators',
        IconSvg: HealthReportIcon,
        title: 'Appointments Indicators',
        allowedRoles: [UserRoles.Admin],
      },
    ],
  },
  // {
  //   to: '/data',
  //   pathname: 'data',
  //   title: 'Data',
  //   isMenu: true,
  //   menuItems: [
  //     {
  //       to: '/data/course',
  //       pathname: 'course',
  //       title: 'Course',
  //     },
  //     {
  //       to: '/data/course',
  //       pathname: 'course',
  //       title: 'Course',
  //     },
  //   ],
  // },

  {
    to: '/mySpace',
    pathname: 'mySpace',
    title: 'mySpace',
    isMenu: true,
    isAllowed: true,
    IconSvg: UserSpaceIcon,
    allowedRoles: [UserRoles.Employee, UserRoles.TeamLeader, UserRoles.Admin],
    menuItems: [
      {
        to: '/mySpace/post-file',
        pathname: 'post-file',
        title: 'post-file',
        IconSvg: CVFileIcon,
        allowedRoles: [UserRoles.Employee, UserRoles.TeamLeader],
      },
      {
        to: '/mySpace/org-dataChart',
        pathname: 'org-dataChart',
        title: 'Organisation Chart',
        IconSvg: OrgChartIcon,
        allowedRoles: [
          UserRoles.Employee,
          UserRoles.TeamLeader,
          UserRoles.Admin,
        ],
      },
    ],
  },
];
