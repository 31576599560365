import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { UserResponsibility } from '@/enums/UserResponsibility';
import { UserRoles } from '@/enums/UsersRole';
import DefaultLayout from '@/layout/DefaultLayout';
import {
  TrainingSatisfactionFormsBreifResponse,
  getBriefTrainingSatisfactionFormsByTrainingAdminId,
} from '@/services/TrainingSatisfactionFormService';
import { EyeIcon } from '@heroicons/react/solid';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function TrainingSatisfactionForms() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');

  const { user } = useAuth();

  const navigate = useNavigate();

  const isTrainingRequestAdmin =
    user?.responsibilityClaim ===
      UserResponsibility[UserResponsibility.GestionDesFormations].toString() ||
    user?.role === UserRoles.Admin;
  const { t } = useTranslation();
  // const isTeamLeader = useMemo(
  //   () => user?.role === UserRoles.TeamLeader,
  //   [user]
  // );

  const ActionButtonsCell = ({ value }: any) => {
    const renderDetailButton = () => (
      <EyeIcon
        onClick={() => navigate(`details/${value}`)}
        className="h-5 w-5 text-gray-600 dark:text-gray-300"
      />

      // <PrimaryButton
      //   type="button"
      //   onClick={() => {
      //    ;
      //   }}
      // >
      //   {t('TrainingRequests.Details')}
      // </PrimaryButton>
    );

    return renderDetailButton();
  };

  const columns = useMemo(
    () => [
      {
        Header: t('TrainingRequests.employeeFullName'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.requestedTrainingTitleColumn'),
        accessor: 'requestedTrainingTitle',
      },
      {
        Header: t('TrainingRequests.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            submittedByEmployee={row.original['']}
          />
        ),
      },
      {
        Header: t('TrainingRequests.trainingProviderName'),
        accessor: 'trainingProviderName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.trainingManagerName'),
        accessor: 'trainingManagerName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.trainingProviderPhoneNumber'),
        accessor: 'trainingProviderPhoneNumber',
      },
      {
        Header: t('TrainingRequests.trainingProviderAddress'),
        accessor: 'trainingProviderAddress',
      },
      {
        Header: t('TrainingRequests.trainingStartDate'),
        accessor: 'trainingStartDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('TrainingRequests.trainingEndDate'),
        accessor: 'trainingEndDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('TrainingRequests.generalAverageRating'),
        accessor: 'generalAverageRating',
      },
    ],
    [t]
  );

  const { isLoading, data: trainingSFRequests } = useQuery<
    TrainingSatisfactionFormsBreifResponse | undefined
  >(
    ['trainingSFRequests', pageIndex, pageSize, search],
    () =>
      getBriefTrainingSatisfactionFormsByTrainingAdminId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: isTrainingRequestAdmin || user?.role === UserRoles.Admin,
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((trainingSFRequests?.count ?? 0) / pageSize);
  }, [pageSize, trainingSFRequests]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('sidebar.SatifactionForm')}
        addbtn={false}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={trainingSFRequests?.count}
            data={trainingSFRequests?.trainingSatisfactionForms ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {/* {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )} */}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}
export default TrainingSatisfactionForms;
