import React, { useState, useTransition } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '@/state';

import { GoalAchievement } from '@/enums/GoalAchievement';
import { ObjectivesAndTasksModel } from '@/models/AnnualInterviewModel';
import TextArea from '@/components/TextArea';
import {
  saveAnnualInterviewAsync,
  updateObjectivesAndTasks,
} from '@/state/slices/annualInterviewSlice';
import { TaskDifficulty } from '@/enums/TaskDifficulty';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { useTranslation } from 'react-i18next';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { InterviewState } from '@/enums/InterviewState';
const init: ObjectivesAndTasksModel = {
  id: '',
  globalTaskDifficulty: TaskDifficulty.Easy,
  globalContextDifficulty: TaskDifficulty.Normal,
  previousGoalAchievement: GoalAchievement.Partially,
  annualInterviewId: '',
  comments: '',
};

const ObjectivesAndTasksTable = () => {
  const { user } = useAuth();
  // const isTeamLeader = user?.isTeamLeader == 'True';
  const { t } = useTranslation();
  const taskLevels = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.objectivesAndTasks
  );
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const nonNumericTaskKeys = Object.keys(TaskDifficulty).filter(
    (key) => !isNaN(Number(TaskDifficulty[key]))
  ) as Array<keyof typeof TaskDifficulty>;

  const disabled = annualInerview.interviewState == InterviewState.Completed;
  const nonNumericGoalKeys = Object.keys(GoalAchievement).filter(
    (key) => !isNaN(Number(GoalAchievement[key]))
  ) as Array<keyof typeof GoalAchievement>;

  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const formik = useFormik({
    initialValues: {
      ...init,
      ...taskLevels,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(updateObjectivesAndTasks({ objectivesAndTasks: values }));
    },
  });

  const handleCheckboxChange = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2 ">
        <div className="grid grid-cols-3 gap-2 md:grid-cols-4">
          {Object.keys(formik.values)
            .filter(
              (objective) =>
                objective !== 'id' &&
                objective !== 'annualInterviewId' &&
                objective !== 'annualInterview' &&
                objective !== 'specificAbilities' &&
                objective !== 'previousGoalAchievement' &&
                objective !== 'comments'
            )
            .map((objective: string) => (
              <div key={objective} className="flex flex-col">
                <label className="my-4 mr-2 font-semibold">
                  {t(`annualInterviewForm.ObjectivesAndTasks.${objective}`)}
                </label>
                {nonNumericTaskKeys.map(
                  (level: keyof typeof TaskDifficulty) => (
                    <div key={level} className="mx-4 flex items-center gap-2">
                      <label className="mx-2 w-[40%]">
                        {t(`TaskDifficulty.${level}`)}
                      </label>
                      <input
                        type="checkbox"
                        className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                        onChange={() =>
                          handleCheckboxChange(objective, TaskDifficulty[level])
                        }
                        checked={
                          formik.values[objective] === TaskDifficulty[level]
                        }
                        disabled={!isTeamLeader || disabled}
                      />
                    </div>
                  )
                )}
              </div>
            ))}

          <div key="previousGoalAchievement" className=" flex flex-col ">
            <label className="my-4 mr-2 font-semibold">
              {t(
                'annualInterviewForm.ObjectivesAndTasks.previousGoalAchievement'
              )}
            </label>
            {nonNumericGoalKeys.map(
              (achievement: keyof typeof GoalAchievement) => (
                <div key={achievement} className="mx-4 flex gap-2">
                  <label className="mx-2 w-[50%]">
                    {t(`GoalAchievement.${achievement}`)}
                  </label>
                  <input
                    type="checkbox"
                    className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    onChange={() =>
                      handleCheckboxChange(
                        'previousGoalAchievement',
                        GoalAchievement[achievement]
                      )
                    }
                    checked={
                      formik.values.previousGoalAchievement ===
                      GoalAchievement[achievement]
                    }
                    disabled={!isTeamLeader || disabled}
                  />
                </div>
              )
            )}
          </div>
        </div>

        <div className="group relative mb-6 w-full">
          <TextArea
            id="comments"
            name="comments"
            label={t('annualInterviewForm.ObjectivesAndTasks.comments')}
            value={formik.values.comments ?? ''}
            onChange={formik.handleChange}
            disabled={isTeamLeader || disabled}
          />
        </div>

        {!disabled && (
          <div className="flex justify-between">
            <LabelButton type="button" onClick={() => resetForm()}>
              {t('annualInterviewForm.ObjectivesAndTasks.reset')}
            </LabelButton>
            <PrimaryButton
              className="border-0 bg-purple-300 dark:bg-purple-500"
              type="button"
              onClick={handleSyncData}
            >
              <div className="flex items-center gap-2">
                <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
                {t('annualInterviewForm.ObjectivesAndTasks.Sync')}
              </div>
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              className="border-0 bg-primary-300 dark:bg-primary-500"
            >
              {t('annualInterviewForm.ObjectivesAndTasks.submit')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </form>
  );
};

export default ObjectivesAndTasksTable;
