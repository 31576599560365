import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import Input, { default as DefaultInput } from '@/components/Input';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import ImageUploader from '@/components/ImagePicker';
import Dropdown from '@/components/DropDown';
import CheckboxGroup from '@/components/CheckboxGroup';
import DatePickerDefault from '@/components/DatePicker';
import { useParams } from 'react-router-dom';
import {
  JobDescriptionModel,
  getJobDescriptionById,
  getJobDescriptionEmployeeById,
} from '@/services/JobDescriptionService';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import { siteName } from '@/variables/Urls';
import Accordion from '@/components/Accordian';
import moment from 'moment';
import { genderOptions } from '@/variables/const';
import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';
import JobHistoriesTable from './components/JobHistoriesTable';
import LanguageSkillsTable from './components/LanguageSkillsTable';
import ChildrenTable from './components/ChildrenTable';
import { useTranslation } from 'react-i18next';
import { GenderEnum } from '@/enums/genderEnum';
import { MaritalStatusEnum } from '@/enums/maritialStatusEnum';

const JobDescriptionDetails = () => {
  const { id } = useParams();
  const isDevelopment = import.meta.env.MODE === 'development';
  const { t } = useTranslation();
  const {
    data: jobDescription,
    isLoading,
    refetch,
  } = useQuery<JobDescriptionModel>(
    ['JobDescriptionDetail', id],
    async () => await getJobDescriptionById(id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
    }
  );
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('jobDescriptionDetails.breadcrumb')} />

      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Accordion
            title={t('jobDescriptionDetails.generalInformation.title')}
          >
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-1">
                <img
                  src={
                    isDevelopment
                      ? siteName + jobDescription?.profileImgUrl
                      : jobDescription?.profileImgUrl
                  }
                  className="mx-2 h-40 w-40 rounded-lg"
                />
              </div>
              <div className="col-span-3 ">
                <div className="flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-2">
                  <div className="flex flex-col gap-4">
                    <Input
                      name="start"
                      value={
                        jobDescription?.firstName +
                        ' ' +
                        jobDescription?.lastName
                      }
                      label={t(
                        'jobDescriptionDetails.generalInformation.fullName'
                      )}
                      labelClassName="pl-0"
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={moment(jobDescription?.birthDate).format(
                        'DD/MM/YYYY'
                      )}
                      label={t(
                        'jobDescriptionDetails.generalInformation.birthDate'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      labelClassName="pl-0"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={t(
                        `GenderEnum.${GenderEnum[jobDescription?.gender ?? 2]}`
                      )}
                      label={t(
                        'jobDescriptionDetails.generalInformation.gender'
                      )}
                      labelDir="inLine"
                      containerClass="w-full self-end"
                      labelClassName="pl-0"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={t(
                        `MaritalStatusEnum.${
                          MaritalStatusEnum[jobDescription?.maritalStatus ?? 4]
                        }`
                      )}
                      label={t(
                        'jobDescriptionDetails.generalInformation.maritalStatus'
                      )}
                      labelDir="inLine"
                      containerClass="w-full "
                      readOnly
                    />
                    <Input
                      name="start"
                      value={
                        jobDescription?.spouseDateOfBirth
                          ? moment(jobDescription?.spouseDateOfBirth).format(
                              'DD/MM/YYYY'
                            )
                          : ''
                      }
                      label={t(
                        'jobDescriptionDetails.generalInformation.spouseDateOfBirth'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <Input
                      name="start"
                      value={
                        jobDescription?.firstName +
                        ' ' +
                        jobDescription?.lastName
                      }
                      label={t(
                        'jobDescriptionDetails.generalInformation.maidenName'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={jobDescription?.birthCountryName ?? ''}
                      label={t(
                        'jobDescriptionDetails.generalInformation.birthCountry'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={jobDescription?.nationalityName ?? ''}
                      label={t(
                        'jobDescriptionDetails.generalInformation.nationality'
                      )}
                      labelDir="inLine"
                      containerClass="w-full "
                      readOnly
                    />
                    <Input
                      name="start"
                      value={jobDescription?.spouseName ?? ''}
                      label={t(
                        'jobDescriptionDetails.generalInformation.spouseName'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={
                        jobDescription?.marriageDate
                          ? moment(
                              jobDescription?.marriageDate ?? new Date()
                            ).format('DD/MM/YYYY')
                          : ''
                      }
                      label={t(
                        'jobDescriptionDetails.generalInformation.marriageDate'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>

          <Accordion
            title={t('jobDescriptionDetails.contactInformation.title')}
          >
            <>
              <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-2">
                <div className="flex flex-col gap-4">
                  <Input
                    name="start"
                    value={jobDescription?.address?.street}
                    label={t('jobDescriptionDetails.contactInformation.street')}
                    labelDir="inLine"
                    containerClass="w-full"
                    readOnly
                  />
                  <Input
                    name="start"
                    value={jobDescription?.address?.zipCode}
                    label={t(
                      'jobDescriptionDetails.contactInformation.zipCode'
                    )}
                    labelDir="inLine"
                    containerClass="w-full"
                    readOnly
                  />
                  <Input
                    name="start"
                    value={jobDescription?.countryId ?? ''}
                    label={t(
                      'jobDescriptionDetails.contactInformation.country'
                    )}
                    labelDir="inLine"
                    containerClass="w-full"
                    readOnly
                  />
                  <Input
                    name="start"
                    value={jobDescription?.phoneNumber1}
                    label={t(
                      'jobDescriptionDetails.contactInformation.phoneNumber'
                    )}
                    labelDir="inLine"
                    containerClass="w-full "
                    readOnly
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <Input
                    name="start"
                    value={jobDescription?.address?.postalCode}
                    label={t('jobDescriptionDetails.contactInformation.street')}
                    labelDir="inLine"
                    containerClass="w-full"
                    readOnly
                  />
                  <Input
                    name="start"
                    value={jobDescription?.stateName ?? ''}
                    label={t('jobDescriptionDetails.contactInformation.state')}
                    labelDir="inLine"
                    containerClass="w-full"
                    readOnly
                  />
                  <Input
                    name="start"
                    value={jobDescription?.email ?? ''}
                    label={t('jobDescriptionDetails.contactInformation.email')}
                    labelDir="inLine"
                    containerClass="w-full "
                    readOnly
                  />
                  <Input
                    name="start"
                    value={jobDescription?.phoneNumber2}
                    label={t(
                      'jobDescriptionDetails.contactInformation.phoneNumber2'
                    )}
                    labelDir="inLine"
                    containerClass="w-full "
                    readOnly
                  />
                </div>
              </div>
              <Accordion
                title={t('jobDescriptionDetails.emergencyContact.title')}
              >
                <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-2">
                  <div className="flex flex-col gap-4">
                    <Input
                      name="start"
                      value={jobDescription?.emergencyContact?.firstName ?? ''}
                      label={t(
                        'jobDescriptionDetails.emergencyContact.firstName'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={
                        jobDescription?.emergencyContact?.phoneNumber ?? ''
                      }
                      label={t(
                        'jobDescriptionDetails.emergencyContact.phoneNumber'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <Input
                      name="start"
                      value={jobDescription?.emergencyContact?.lastName ?? ''}
                      label={t(
                        'jobDescriptionDetails.emergencyContact.lastName'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={jobDescription?.emergencyContact?.email ?? ''}
                      label={t('jobDescriptionDetails.emergencyContact.email')}
                      labelDir="inLine"
                      containerClass="w-full "
                      readOnly
                    />
                  </div>
                </div>
              </Accordion>
            </>
          </Accordion>

          <Accordion title={t('jobDescriptionDetails.children.title')}>
            <ChildrenTable
              children={jobDescription?.children ?? []}
              readonly={true}
            />
          </Accordion>

          <Accordion title={t('jobDescriptionDetails.languageSkills.title')}>
            <LanguageSkillsTable
              languageSkills={jobDescription?.languageSkills ?? []}
              readonly={true}
            />
          </Accordion>

          <Accordion title={t('jobDescriptionDetails.jobHistory.title')}>
            <JobHistoriesTable
              jobHistories={jobDescription?.jobHistories ?? []}
              readonly={true}
            />
          </Accordion>

          <Accordion
            title={t('jobDescriptionDetails.disabilityInformation.title')}
          >
            <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-2">
              <div className="flex flex-col gap-4 ">
                <Input
                  name="ippRate"
                  value={jobDescription?.disabilityInformation?.ippRate}
                  label={t(
                    'jobDescriptionDetails.disabilityInformation.ippRate'
                  )}
                  labelDir="inLine"
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="rqthDate"
                  value={moment(
                    jobDescription?.disabilityInformation?.rqthDate
                  ).format('DD/MM/YYYY')}
                  label={t(
                    'jobDescriptionDetails.disabilityInformation.rqthDate'
                  )}
                  labelDir="inLine"
                  containerClass="w-full"
                  readOnly
                />
              </div>
              <div className="flex flex-col gap-4 ">
                <TextArea
                  name="workRestrictions"
                  value={
                    jobDescription?.disabilityInformation?.workRestrictions ??
                    ''
                  }
                  label={t(
                    'jobDescriptionDetails.disabilityInformation.workRestrictions'
                  )}
                  labelDir="inLine"
                  containerClass="w-full"
                  readOnly
                />
              </div>
            </div>
          </Accordion>

          <Accordion title={t('jobDescriptionDetails.identityPapers.title')}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="md:col-span-1">
                <Input
                  name="socialSecurityNumber"
                  value={jobDescription?.identityPapers?.socialSecurityNumber}
                  label={t(
                    'jobDescriptionDetails.identityPapers.socialSecurityNumber'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] "
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="identityCardNumber"
                  value={jobDescription?.identityPapers?.identityCardNumber}
                  label={t(
                    'jobDescriptionDetails.identityPapers.identityCardNumber'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] pt-2"
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="identityCardIssueDate"
                  value={moment(
                    jobDescription?.identityPapers?.identityCardIssueDate
                  ).format('DD/MM/YYYY')}
                  label={t(
                    'jobDescriptionDetails.identityPapers.identityCardIssueDate'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] pt-2"
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="passportNumber"
                  value={jobDescription?.identityPapers?.passportNumber}
                  label={t(
                    'jobDescriptionDetails.identityPapers.passportNumber'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] pt-2"
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="passportIssueDate"
                  value={moment(
                    jobDescription?.identityPapers?.passportIssueDate
                  ).format('DD/MM/YYYY')}
                  label={t(
                    'jobDescriptionDetails.identityPapers.passportIssueDate'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] pt-2"
                  containerClass="w-full"
                  readOnly
                />
              </div>
              <div className="md:col-span-1">
                <Input
                  name="drivingLicenseNumber"
                  value={jobDescription?.identityPapers?.drivingLicenseNumber}
                  label={t(
                    'jobDescriptionDetails.identityPapers.drivingLicenseNumber'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%]"
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="drivingLicenseIssueDate"
                  value={moment(
                    jobDescription?.identityPapers?.drivingLicenseIssueDate
                  ).format('DD/MM/YYYY')}
                  label={t(
                    'jobDescriptionDetails.identityPapers.drivingLicenseIssueDate'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] pt-2"
                  containerClass="w-full"
                  readOnly
                />
                <Input
                  name="drivingLicenseCategory"
                  value={
                    jobDescription?.identityPapers?.drivingLicenseCategory ?? ''
                  }
                  label={t(
                    'jobDescriptionDetails.identityPapers.drivingLicenseCategory'
                  )}
                  labelDir="Above"
                  labelClassName="w-[60%] pt-2"
                  containerClass="w-full"
                  readOnly
                />
              </div>
            </div>
          </Accordion>

          {/* <button type="submit">{t('jobDescriptionDetails.submit')}</button> */}
        </div>
      )}
    </DefaultLayout>
  );
};

export default JobDescriptionDetails;
