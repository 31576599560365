import api from '@/utils/api';
import { TrainingSatisfactionFormsUrl } from '@/variables/Urls';
import { getCompanyId } from './AuthService';
import { TrainingSatisfactionFormState } from '@/enums/TrainingSatisfactionFormState';

const apiEndpoint = TrainingSatisfactionFormsUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  employeeId?: string;
}

export interface TrainingSatisfactionForm {
  employeeId: string;
  trainingRequestId?: string;
  satisfactionFormId?: string;
  // Training Provider Review Form
  communicationBeforeTraining: number;
  trainingStartDelay: number;
  trainingDuration: number;
  commitmentAdherence: number;
  trainingProviderComments?: string;

  // Resources Review Form
  generalWorkspace: number;
  facilities: number;
  providedMaterials: number;
  equipmentQuality: number;
  resourcesComments?: string;

  // Pedagogy Review Form
  difficultyLevel: number;
  themeArticulation: number;
  theoreticalContentQuality: number;
  practicalContentQuality: number;
  progressionPace: number;
  instructorPedagogyQuality: number;
  instructorAvailability: number;
  instructionQuality: number;
  pedagogyComments?: string;

  // Work Place Review Form
  generalAmbiance: number;
  attendanceAndMotivation: number;
  heterogeneity: number;
  attentionAndParticipation: number;
  workPlaceComments?: string;

  // Benefit Review Form
  trainingExpectationsAlignment: number;
  trainingUsefulness: number;
  benefitComments?: string;

  // Overall Form
  generalComments?: string;
  generalAverageRating: number;
  trainingSatisfactionFormState?: TrainingSatisfactionFormState;
}
export interface TrainingSatisfactionFormBriefModel {
  trainingRequestId: string;
  id: string;
  employeeFullName: string;
  requestedTrainingTitle: string;
  trainingProviderName: string;
  trainingProviderAddress: string;
  trainingProviderPhoneNumber: string;
  trainingProviderEmail: string;
  trainingProviderWebsite: string;
  trainingStartDate: Date;
  trainingEndDate: Date;
  generalComments?: string;
  generalAverageRating: number;
}

export interface TrainingSatisfactionFormsResponse {
  trainingSatisfactionForms: TrainingSatisfactionForm[];
  count: number;
}
export interface TrainingSatisfactionFormsBreifResponse {
  trainingSatisfactionForms: TrainingSatisfactionFormBriefModel[];
  count: number;
}

export async function getTrainingSatisfactionFormsByTrainingAdminId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  trainingAdminId,
}: PaginatingParmas & { trainingAdminId: string }) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      trainingAdminId: trainingAdminId,
    },
  };
  const { data } = await api.get(apiEndpoint + '/get', config);
  return data as TrainingSatisfactionFormsResponse;
}

export async function getBriefTrainingSatisfactionFormsByTrainingAdminId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetAllBrief', config);
  return data as TrainingSatisfactionFormsBreifResponse;
}

export async function getTrainingSatisfactionForm(
  trainingSatisfactionFormId: string
) {
  const { data } = await api.get<TrainingSatisfactionForm>(
    apiEndpoint + '/getById',
    {
      params: { trainingSatisfactionFormId: trainingSatisfactionFormId },
    }
  );
  return data;
}

export async function saveTrainingSatisfactionForm(trainingRequestId: string) {
  const body = {
    trainingRequestId: trainingRequestId,
  };
  const response_1 = await api.post(apiEndpoint + '/post', body);
  return response_1;
}
export async function updateTrainingSatisfactionForm(
  trainingRSF: TrainingSatisfactionForm
) {
  const body = {
    ...trainingRSF,
  };
  const response_1 = await api.put(apiEndpoint + '/put', body);
  return response_1;
}
// Add other functions as needed based on your requirements
