import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PurpleButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  ProjectMeetingResponse,
  deleteProjectMeeting,
  getProjectMeetingsByProjectId,
} from '@/services/ProjectMeetingsService';
import { useTranslation } from 'react-i18next';
import { MeetingType } from '@/enums/MeetingType';
import moment from 'moment';
import { AssignedTask } from '@/enums/AssignedTask';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
const userTimeZone = moment.tz.guess();
function ProjectMeetings() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { user } = useAuth();

  const isAllowed = useMemo(
    () =>
      user?.assignedTask == AssignedTask.DeliverablesFollowUp ||
      user?.role == UserRoles.Admin,
    [user]
  );

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('ProjectMeetings.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('ProjectMeetings.editButton')}
        </EditButton>
        <PurpleButton
          type="button"
          onClick={() => {
            navigate(`/gestion-project-ao/${value}/subjects`);
          }}
        >
          {t('ProjectMeetings.subjectsButton')}
        </PurpleButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteProjectMeeting.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('ProjectMeetings.dateColumn'),
        accessor: 'date',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('ProjectMeetings.startTimeColumn'),
        accessor: 'startTime',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('HH:mm'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('ProjectMeetings.endTimeColumn'),
        accessor: 'endTime',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('HH:mm'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('ProjectMeetings.meetingTypeColumn'),
        accessor: 'meetingType',
        Cell: ({ cell: { value } }) => t(`MeetingType.${MeetingType[value]}`),
      },
      {
        Header: t('ProjectMeetings.objectivesColumn'),
        accessor: 'objectives',
      },
      {
        Header: t('ProjectMeetings.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            handleDelete={handleDeleteProjectMeeting}
          />
        ),
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: projectMeetings,
  } = useQuery<ProjectMeetingResponse | undefined>(
    ['projectMeetings', pageIndex, pageSize, search],
    () =>
      getProjectMeetingsByProjectId({
        projectId: id!,
        page: pageIndex,
        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: id !== null,
    }
  );

  const handleDeleteProjectMeeting = useMutation(
    async (id: string) => {
      await deleteProjectMeeting(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'projectMeetings',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((projectMeetings?.count ?? 0) / pageSize);
  }, [pageSize, projectMeetings]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('ProjectMeetings.pageName')}
        addbtn={isAllowed}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={projectMeetings?.count}
            data={projectMeetings?.projectMeetings ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default ProjectMeetings;
