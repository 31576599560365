import { Currency } from '@/enums/Currency';
import { ExpensesState } from '@/enums/ExpensesState';
import { GenderEnum } from '@/enums/genderEnum';
import { MaritalStatusEnum } from '@/enums/maritialStatusEnum';
import {
  ExpenseModel,
  ProjectExpenseModel,
} from '@/models/ProjectExpenseModel';
import {
  ChildModel,
  DegreeModel,
  JobDescriptionModel,
  JobHistoryModel,
  LanguageSkillModel,
  getJobDescriptionEmployeeById,
  saveJobDescription,
} from '@/services/JobDescriptionService';
import {
  getProjectExpense,
  saveProjectExpense,
} from '@/services/ProjectExpensesService';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

// Async Thunk for fetching job description by employee ID
export const fetchProjectExpenseById = createAsyncThunk<initStateProps, string>(
  'ProjectExpenses/fetchById',
  async (pExId: string, thunkAPI) => {
    try {
      const response: ProjectExpenseModel = await getProjectExpense(pExId);

      return {
        projectExpense: response,
        status: 'succeeded',
        error: undefined,
      };
    } catch (error: any) {
      // Handle errors here if needed
      return thunkAPI.rejectWithValue({
        projectExpense: null,
        status: 'failed',
        error: error.message,
      });
    }
  }
);

// Async Thunk for saving/updating job description
export const saveProjectExpenseAsync = createAsyncThunk(
  'ProjectExpenses/save',
  async (projectExpense: ProjectExpenseModel) => {
    const response = await saveProjectExpense(projectExpense);
    return response;
  }
);
const initialProjectExpenseState: ProjectExpenseModel = {
  projectExpenseCurrency: Currency.USD, // Assuming USD is the default currency
  state: ExpensesState.Pending, // Assuming PENDING is the default state
  employeeId: '',

  missionStartDate: new Date(),
  missionEndDate: new Date(),
  comments: '',
  total: 0,
  expenseList: [],
  projectTitleId: '',
  projectId: '',
  projectExpenseTitle: '',
};
export interface initStateProps {
  projectExpense: ProjectExpenseModel;
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  error: string | undefined;
}
// Initial state
const initialState: initStateProps = {
  projectExpense: initialProjectExpenseState,
  status: 'idle',
  error: undefined,
};

// Create the slice
const projectExpenseSlice = createSlice({
  name: 'projectExpenses',
  initialState,
  reducers: {
    updateProjectExpenseValues(
      state,
      action: PayloadAction<{ projectExpense: ProjectExpenseModel }>
    ) {
      state.projectExpense = action.payload.projectExpense;
    },
    addExpense(state, action: PayloadAction<{ expense: ExpenseModel }>) {
      let expenseList = [...(state.projectExpense.expenseList ?? [])];
      var neEx = { ...action.payload.expense, id: 'newEx' + uniqueId() };
      state.projectExpense.expenseList = [...expenseList, neEx];
      state.projectExpense.total += neEx.totalEx ?? 0;
      return state;
    },
    updateExpense(state, action: PayloadAction<{ expense: ExpenseModel }>) {
      const index = state.projectExpense.expenseList?.findIndex(
        (ls) => ls.id === action.payload.expense.id
      );
      if (index !== -1 && state.projectExpense.expenseList && index) {
        state.projectExpense.expenseList[index] = action.payload.expense;
      }

      return state;
    },
    removeExpense(state, action: PayloadAction<{ expenseId: string }>) {
      let removedEX = state.projectExpense.expenseList?.filter(
        (x) => x.id == action.payload.expenseId
      )[0];
      let newExpenses =
        state.projectExpense.expenseList?.filter(
          (x) => x.id !== action.payload.expenseId
        ) ?? [];
      state.projectExpense.expenseList = [...newExpenses];
      if (removedEX) {
        state.projectExpense.total -= removedEX.totalEx ?? 0;
      }

      return state;
    },
    resetPEXState(state) {
      state.projectExpense = { ...initialProjectExpenseState };
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetching job description by employee ID
    builder.addCase(fetchProjectExpenseById.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchProjectExpenseById.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.projectExpense = action.payload.projectExpense;
    });
    builder.addCase(fetchProjectExpenseById.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });

    // Reducer for saving/updating job description
    builder.addCase(saveProjectExpenseAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(saveProjectExpenseAsync.fulfilled, (state, action) => {
      state.status = 'succeeded';
    });
    builder.addCase(saveProjectExpenseAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

// Export actions and reducer
export const {
  addExpense,
  updateExpense,
  removeExpense,
  updateProjectExpenseValues,
  resetPEXState,
} = projectExpenseSlice.actions;
export default projectExpenseSlice.reducer;
