import useColorMode from '@/hooks/useColorMode';
import { useDarkMode } from '@/hooks/useDarkMode';
import useLocalStorage from '@/hooks/useLocalStorage';
import { GraphDataItem } from '@/services/RapportsServices';
import { time } from 'console';
import React, { useEffect, useMemo, useState } from 'react';
import { Chart, ChartWrapperOptions } from 'react-google-charts'; // Or the relevant chart library
import { useTranslation } from 'react-i18next';
interface PieChartProps {
  data: GraphDataItem[];
  options: ChartWrapperOptions['options'];
  title: string;
  translationNameSpace?: string;
}
const tailwindColorsHex = [
  '#F87171',
  '#60A5EB',
  '#34D399',
  '#F59E0B',
  '#7C3AED',
  '#8B5CF6',
  '#EC4899',
  '#718096',
  '#EF4444',
  '#3B82F6',
  '#10B981',
  '#F59E0B',
  '#7C3AED',
  '#8B5CF6',
  '#EC4899',
  '#718096',
  '#DC2626',
  '#2563EB',
  '#047857',
  '#D97706',
  '#4338CA',
  '#6D28D9',
  '#DB2777',
  '#4B5563',
  '#14B8A6',
  '#047857',
  '#065F46',
  '#EA580C',
  '#9D2C28',
  '#742A2A',
];
const PieChart = ({
  data,
  options,
  title,
  translationNameSpace,
}: PieChartProps) => {
  const { t } = useTranslation();

  const isDarkMode = useDarkMode();

  const textColor = useMemo(
    () => (isDarkMode ? '#ffffff' : '#000000'),
    [isDarkMode]
  );
  const transformedData = useMemo(
    () =>
      data?.map((item) => {
        const translatedLabel = t(
          translationNameSpace
            ? `${translationNameSpace}.${item.label}`
            : `rhIndicators.${item.label}`
        );
        const label =
          translatedLabel !== `${translationNameSpace}.${item.label}` &&
          translatedLabel !== `rhIndicators.${item.label}`
            ? translatedLabel
            : item.label;
        return [label, item.value];
      }),
    [data, t, translationNameSpace]
  );
  let chartData = [['Task', 'Hours per Day'], ...transformedData];
  if (transformedData.length === 0) {
    var noData = t('Indicators.NoData');
    chartData = [
      ['Task', 'Hours per Day'],
      [noData, '100'],
    ];
    return (
      <div className=" rounded-sm border border-stroke bg-white bg-opacity-25 px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark ">
        <Chart
          chartType="PieChart"
          data={chartData}
          options={{
            ...options,
            title: title,

            colors: tailwindColorsHex,
            titleTextStyle: {
              color: textColor,

              // Changing title text color
            },
            legend: {
              position: 'bottom',
              textStyle: {
                color: textColor,
              },
            },
          }}
          width={'100%'}
          height={'400px'}
        />
      </div>
    );
  }
  return (
    <div className=" rounded-sm border border-stroke bg-white bg-opacity-25 px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark ">
      <Chart
        chartType="PieChart"
        data={chartData}
        options={{
          ...options,
          title: title,

          colors: tailwindColorsHex,
          titleTextStyle: {
            color: textColor,

            // Changing title text color
          },
          legend: {
            position: 'bottom',
            textStyle: {
              color: textColor, // Changing legend text color
            },
          },
        }}
        width={'100%'}
        height={'400px'}
      />
    </div>
  );
};

export default PieChart;
