import moment from 'moment';
import CurrencyAPI from '@everapi/currencyapi-js';
import { useCallback } from 'react';

export interface ExchangeRateData {
  [currency: string]: number;
}

const currencyApi = new CurrencyAPI(process.env.VITE_EXCHANGE_RATE_API_KEY);

export const getExchangeRate = async (toCurrency: string, datetime: Date) => {
  const apiKey = process.env.VITE_EXCHANGE_RATE_API_KEY;
  const formatedDate = moment(datetime).format('YYYY-MM-DD');
  console.log('🚀 ~ getExchangeRate ~ formatedDate:', apiKey);
  if (!apiKey) {
    console.error('Exchange rate API key is missing.');
    return;
  }

  try {
    const response = await fetch(
      `https://api.exchangeratesapi.io/v1/exchange-rates/${formatedDate}?access_key=${apiKey}&base=${toCurrency}
      `,
      { mode: 'no-cors' }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch exchange rates: ${response.statusText}`);
    }

    const data = await response.json();
    return data.rates;
  } catch (error: any) {
    console.error('Error fetching exchange rates:', error.message);
    return null;
  }
};

export const getCurrencyExchange = async (
  toCurrency: string,
  datetime: Date
) => {
  const formatedDate = moment(datetime).format('YYYY-MM-DD');
  var response = await currencyApi.historical({
    date: '2022-01-01',
    base_currency: toCurrency,
  });

  return response.data;
};
