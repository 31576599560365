import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const MultiLineChart = ({ graphData }: any) => {
  // Sample data

  //options
  var options = {
    responsive: true,
    title: {
      display: true,
      position: 'top',
      text: 'Line Graph',
      fontSize: 18,
      fontColor: '#111',
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#333',
        fontSize: 16,
      },
    },
  };

  return (
    <div>
      <Line data={graphData} options={options} />
    </div>
  );
};

export default MultiLineChart;
