import { AppointmentState } from '@/enums/AppointmentState';
import { VisitReason } from '@/enums/VisiteReason';
import api from '@/utils/api';
import { AppointmentsUrl } from '@/variables/Urls';
import { getCompanyId } from './AuthService';

export interface Appointment {
  id?: string;
  employeeId: string;
  medicalVisitAdminId?: string;
  teamLeaderId?: string;
  rendezvousDateTime: Date;
  convocationDate: Date;
  dateEnvoiConvocation: Date;
  convocationValideParSalarie: boolean;
  visiteMedicaleDone: boolean;
  motif: VisitReason;
  requiredDocuments?: string | null;
  importantMessage?: string | null;
  comment?: string | null;
  doctorName: string;
  doctorFamilyName: string;
  doctorPhone?: string | null;
  organismeName: string;
  organismeAddress?: string | null;
  departmentName?: string;
  branchName?: string;
  employeeName?: string;
  appointmentState: AppointmentState;
  apte?: boolean;
}

export interface AppointmentsResponse {
  appointments: Appointment[];
  count: number;
}

const apiEndpoint = AppointmentsUrl;
export async function getAppointments(params: {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
}) {
  const { fromvalue = 0, takevalue = 10, search = '' } = params;
  const response = await api.get<AppointmentsResponse>(apiEndpoint + '/Get', {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  });
  return response.data;
}
export async function getAppointmentsByUserId(params: {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
  userId: string;
}) {
  const { fromvalue = 0, takevalue = 10, search = '', userId } = params;
  const response = await api.get<AppointmentsResponse>(
    apiEndpoint + '/GetByUserId',
    {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        userId: userId,
      },
    }
  );
  return response.data;
}

export async function getAppointment(appointmentId: string) {
  const response = await api.get<Appointment>(apiEndpoint + '/GetById', {
    params: { id: appointmentId },
  });
  return response.data;
}

export async function saveAppointment(appointment: Appointment) {
  if (appointment.id) {
    // Update existing appointment.
    const response = await api.put(apiEndpoint + '/put', appointment);
    return response.data;
  } else {
    // Create a new appointment.
    const response = await api.post(apiEndpoint + '/post', appointment);
    return response.data;
  }
}

export interface ChangeAppointmentStateProps {
  appointmentState: AppointmentState;
  id: string;
  comment?: string;
}

export async function changeAppointmentState({
  appointmentState,
  id,
  comment,
}: ChangeAppointmentStateProps) {
  try {
    const body = {
      id: id,
      comment: comment,
      state: appointmentState,
    };
    const response_1 = await api.post(apiEndpoint + '/ChangeState', body);
    return response_1;
    {
    }
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function deleteAppointment(appointmentId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: appointmentId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
