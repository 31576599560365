export enum ExpenseCategory {
  Travel,
  Accommodation,
  Meals,
  Equipment,
  Others,
}

export const expenseCategoryOptions = Object.keys(ExpenseCategory)
  .filter((key) => isNaN(Number(ExpenseCategory[key])))
  .map((key) => ({
    id: key,
    name: ExpenseCategory[key],
  }));
