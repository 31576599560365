import { UserRoles } from '@/enums/UsersRole';
import { EmployeeUser } from '@/models/empUser';
import api from '@/utils/api';
import { putUserserUrl, userUrl, usersUrl } from '@/variables/Urls';
import { AxiosResponse } from 'axios';
import { getCompanyId, getJwt } from './AuthService';
const apiEndpoint = userUrl;

export interface EmployeesResponisibiltiesResponse {
  data: UserRoles[];
}
export interface UserProfile {
  phoneNumber?: string;
  id: string;
  email: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  password: string;
  employeeRole?: UserRoles;
}

export const getUser = async (jwt: string) => {
  let config = {
    params: {
      jwt: jwt,
    },
  };
  const response: AxiosResponse<UserProfile> = await api.get(userUrl, config);

  return response.data;
};
export const getResponsibility = async () => {
  let config = {
    params: {
      jwt: getJwt(),
      companyId: getCompanyId(),
    },
  };
  const response: AxiosResponse<EmployeesResponisibiltiesResponse> =
    await api.get(usersUrl + '/GetAvialableResponsibility', config);

  return response.data;
};

export async function saveUser(user: EmployeeUser) {
  const body = { ...user };

  try {
    const response = await api.post(usersUrl + '/post', body);
    return response.data;
  } catch (error) {
    console.log('🚀 ~ file: UserService.ts:17 ~ saveUser ~ error:', error);
    throw error;
  }
}

export const updateUser = async (user: EmployeeUser) => {
  try {
    const response = await api.put(usersUrl + '/put', user);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    throw error;
  }
};

export const checkEmailAvailability = async (email: string) => {
  try {
    const { data } = await api.get<boolean>(usersUrl + '/CheckExistByEmail', {
      params: { email: email },
    });

    return data;
  } catch (error) {
    console.log(
      '🚀 ~ file: ContractService.ts:43 ~ getContract ~ error:',
      error
    );
    throw error;
  }
};
export const checkIDNumberAvailability = async (idNumber: string) => {
  try {
    const { data } = await api.get<boolean>(
      usersUrl + '/CheckExistByIdNumber',
      {
        params: { idNumber: idNumber },
      }
    );

    return data;
  } catch (error) {
    console.log(
      '🚀 ~ file: ContractService.ts:43 ~ getContract ~ error:',
      error
    );
    throw error;
  }
};

export const getUserById = async (userId: string) => {
  try {
    const { data } = await api.get<UserProfile>(usersUrl + '/GetById', {
      params: { id: userId },
    });

    return data;
  } catch (error) {
    console.log(
      '🚀 ~ file: ContractService.ts:43 ~ getContract ~ error:',
      error
    );
    throw error;
  }
};
export const updateUserRole = async (userId: string, role: string) => {
  const body = {
    userId: userId,
    userRole: role,
  };

  try {
    const response = await api.post(usersUrl + '/UpdateUserRole', body);
    return response.data;
  } catch (error) {
    console.log('🚀 ~ file: UserService.ts:17 ~ saveUser ~ error:', error);
    throw error;
  }
};
export const AddResponsibilityToUser = async (userId: string, role: string) => {
  const body = {
    userId: userId,
    role: role,
    companyId: getCompanyId(),
  };

  try {
    const response = await api.post(usersUrl + '/AddResponsibility', body);
    return response.data;
  } catch (error) {
    console.log('🚀 ~ file: UserService.ts:17 ~ saveUser ~ error:', error);
    throw error;
  }
};
