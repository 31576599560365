export enum GenderEnum {
  Male,
  Female,
  NoAnswer,
}

// export const genderOptions = [
//   { id: 1, name: 'Male' },
//   { id: 2, name: 'Female' },
//   { id: 3, name: 'No Answer' },
// ];

// Array of options for GenderEnum
export const genderOptions = [
  { id: GenderEnum.Male, name: 'Male' },
  { id: GenderEnum.Female, name: 'Female' },
  { id: GenderEnum.NoAnswer, name: 'No Answer' },
];
