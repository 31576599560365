import { Currency } from '@/enums/Currency';
import api from '@/utils/api';
import { SubProjectMethodUrl } from '@/variables/Urls';

const apiEndpoint = SubProjectMethodUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

//! sub project data
export interface SubProjectMaterialsPayload {
  subProjectMaterials: SubProjectMaterial[];
  subProjectMethodId: string;
}
export interface SubProjectPersonnelsPayload {
  subProjectPersonnels: SubprojectPersonnel[];
  subProjectMethodId: string;
}
export interface SubProjectEquipmentsPayload {
  subProjectEquipments: SubProjectEquipment[];
  subProjectMethodId: string;
}
export interface SubProjectConsumablesPayload {
  subProjectConsumables: SubProjectConsumable[];
  subProjectMethodId: string;
}
export interface SubProjectInstallationsPayload {
  subProjectInstallations: SubProjectInstallation[];
  subProjectMethodId: string;
}
export interface SubProjectOtherEquipmentsPayload {
  subProjectOtherEquipments: SubProjectOtherEquipment[];
  subProjectMethodId: string;
}

export interface SubProjectMethodDto {
  id: string;
  projectId: string;
  subProjectGroupId?: string | null;
  groupTitle: string;
  activity: string;
  description: string;
  cadences: string;
  workingHoursPerShift: number;
  shiftsPerDay: number;
  shiftsPerWeek: number;
  weatherDowntimePerMonth: number;
  numberOfMachinesPerShift: number;
  quantity: number;
  productionRatePerMachine: number;
  workshopStartDate: Date;
  workshopFinishDate: Date;
  durationInDays: number;
  comments: string;
  currency: Currency;
  isSubConstructor: boolean;
  subConstructorPrice?: number;
  TotalHours?: number;
  companyId?: string;
  subProjectMaterials?: SubProjectMaterial[];
  subProjectPersonnels?: SubprojectPersonnel[];
  subProjectEquipments?: SubProjectEquipment[];
  subProjectConsumables?: SubProjectConsumable[];
  subProjectInstallations?: SubProjectInstallation[];
  subProjectOtherEquipments?: SubProjectOtherEquipment[];
}

export interface SubProjectMaterial {
  id?: string;
  subProjectMethodId: string;

  ref?: number;
  equipmentId: string;

  description: string;
  quantity: number;
  capacity: number;
  unitPrice: number;
  totalPrice: number;
  comments: string;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  equipmentCurrency?: Currency;
}

export interface SubprojectPersonnel {
  id: string;
  subProjectMethodId: string;

  ref: number;
  personnelCostId: string;

  expat: number;
  local: number;
  sousTraitant: number;
  description: string;
  total: number;
  hourlyRate: number;
  totalPerShift: number;
  totalCost: number;
  totalHours: number;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  personnelCurrency?: Currency;
}
export interface SubProjectEquipment {
  id: string;
  subProjectMethodId: string;

  equipmentId: string;

  description: string;
  isLocated: boolean;
  category: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  comment: string;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  equipmentCurrency?: Currency;
}

export interface SubProjectConsumable {
  id: string;
  subProjectMethodId: string;

  equipmentId: string;

  description: string;
  isLocated: boolean;
  category: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  equipmentCurrency?: Currency;
}
export interface SubProjectInstallation {
  id: string;
  subProjectMethodId: string;

  equipmentId: string;

  description: string;
  isLocated: boolean;
  category: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  equipmentCurrency?: Currency;
}

export interface SubProjectOtherEquipment {
  id: string;
  subProjectMethodId: string;

  equipmentId: string;

  description: string;
  isLocated: boolean;
  category: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  exchangeRate: number;
  name?: string;
  totalToCurrency?: number;
  equipmentCurrency?: Currency;
}
export interface SubProjectMethodDtoList {
  count: number;
  subProjectMethods: SubProjectMethodDto[];
}

export async function saveSubProjectMaterials(
  subProjectMaterials: SubProjectMaterialsPayload
) {
  const response_1 = await api.post(
    apiEndpoint + '/PostMaterials',
    subProjectMaterials
  );
  return response_1;
}
export async function saveSubProjectPersonnels(
  subProjectPersonnels: SubProjectPersonnelsPayload
) {
  const response_1 = await api.post(
    apiEndpoint + '/PostPersonnels',
    subProjectPersonnels
  );
  return response_1;
}
export async function saveSubProjectEquipments(
  subProjectEquipments: SubProjectEquipmentsPayload
) {
  const response_1 = await api.post(
    apiEndpoint + '/PostEquipments',
    subProjectEquipments
  );
  return response_1;
}
export async function saveSubProjectConsumables(
  subProjectConsumables: SubProjectConsumablesPayload
) {
  const response_1 = await api.post(
    apiEndpoint + '/PostConsumables',
    subProjectConsumables
  );
  return response_1;
}
export async function saveSubProjectInstallations(
  subProjectInstallations: SubProjectInstallationsPayload
) {
  const response_1 = await api.post(
    apiEndpoint + '/PostInstallations',
    subProjectInstallations
  );
  return response_1;
}
export async function saveSubProjectOtherEquipments(
  subProjectOtherEquipments: SubProjectOtherEquipmentsPayload
) {
  const response_1 = await api.post(
    apiEndpoint + '/PostOtherEquipments',
    subProjectOtherEquipments
  );
  return response_1;
}

export async function saveSubProjectMethod(
  subProjectMethod: SubProjectMethodDto
) {
  if (subProjectMethod.id) {
    const body = { ...subProjectMethod };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', subProjectMethod);
  return response_1;
}

export async function getSubProjectMethod(id: string) {
  const { data } = await api.get<SubProjectMethodDto>(
    apiEndpoint + '/GetById',
    {
      params: { id },
    }
  );

  return data;
}

export async function getSubProjectMethodsByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };
  const { data } = await api.get<SubProjectMethodDtoList>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export function deleteSubProjectMethod(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
