import React, { useState } from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  TableState,
  useGroupBy,
  useExpanded,
  Cell,
} from 'react-table';
import 'regenerator-runtime';
import { classNames } from './shared/Utils';
import { SortIcon, SortUpIcon, SortDownIcon } from './shared/Icons';
import PdfIcon from '/src/images/icon/pdf.svg';
import ExcelIcon from '/src/images/icon/excel.svg';

import { useTranslation } from 'react-i18next';
import { useExportData } from 'react-table-plugins';
import { getExportFileBlob } from '@/utils/fileHelper';
import { RequestLeaveState } from '@/enums/RequestLeaveState';
import { ModuleType } from '@/models/ModuleTypeEnum';
export interface DynamicTableProps {
  columns: any;
  data: any[];
  dataCount?: number;
  setSearch?: any;
  initialeStateColumn?: Partial<TableState<object>> | undefined;
  hideToolbar?: boolean;
  exportExcel?: boolean;
  exportPdf?: boolean;
  groupedBy?: string;
}

// Define a default UI for filtering
function GlobalFilter({
  // preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  dataCount,
}: any) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const { t } = useTranslation();
  const onChange = (value: any) => {
    setGlobalFilter(value);
  };

  return (
    <label className="flex items-baseline gap-x-4">
      <span className="px-2 text-gray-700 dark:text-bodydark">
        {t('DynamicTable.Search')}{' '}
      </span>
      <input
        type="text"
        className=" rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-boxdark-2 dark:text-bodydark"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${dataCount} ${t('DynamicTable.records')}...`}
      />
    </label>
  );
}

export function EnumRequestLeaveStatesFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}: any) {
  const options = React.useMemo(() => {
    const uniqueOptions = new Set();
    preFilteredRows.forEach((row: any) => {
      uniqueOptions.add(row.values[id]);
    });
    return [...uniqueOptions.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex items-baseline gap-x-4 dark:bg-boxdark-2 dark:text-bodydark ">
      <span className="text-gray-700 dark:text-bodydark">
        {render('Header')}:{' '}
      </span>
      <select
        value={filterValue}
        className="dark:bg-boxdark-2 dark:text-bodydark"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option: any, index) => (
          <option key={index} value={option}>
            {RequestLeaveState[option]}
          </option>
        ))}
      </select>
    </label>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="mx-2 flex items-baseline gap-x-4 ">
      <span className="text-gray-700 dark:text-bodydark">
        {render('Header')}:{' '}
      </span>
      <select
        className="rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-boxdark-2 dark:text-bodydark"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option: any, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusLicense({ value }: any) {
  const status = value ? 'active' : 'inactive';

  return (
    <span
      className={classNames(
        'leading-wide rounded-full px-3 py-1 text-xs font-bold uppercase shadow-sm',
        status.startsWith('active') ? 'bg-green-100 text-green-800' : '',
        status.startsWith('inactive') ? 'bg-yellow-100 text-yellow-800' : '',
        status.startsWith('offline') ? 'bg-red-100 text-red-800' : ''
      )}
    >
      {status}
    </span>
  );
}
export function ModuleTypeCell({ value }: any) {
  const valuesArray = value.split(','); // Split the values by comma into an array

  return (
    <span
      className="leading-wide flex  flex-wrap  "
      style={{
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        maxHeight: '100px',
        minWidth: '100px', // Set the maximum height to control the scroll area
      }}
    >
      {valuesArray.map((val: any, index: any) => (
        <div
          key={index}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {ModuleType[val]}
        </div>
      ))}
    </span>
  );
}

export function AvatarCell({ value, column, row }: any) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-10 flex-shrink-0">
        <img
          className="h-10 w-10 rounded-full"
          src={row.original[column.imgAccessor]}
          alt=""
        />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">
          {row.original[column.emailAccessor]}
        </div>
      </div>
    </div>
  );
}

const getTrProps = (state, rowInfo, instance): any => {
  if (rowInfo) {
    return {
      style: {
        background: rowInfo.row.age > 20 ? 'red' : 'green',
        color: 'white',
      },
    };
  }
  return {};
};
function DynamicTable({
  columns,
  data,
  dataCount,
  setSearch,
  hideToolbar = false,
  exportPdf = true,
  exportExcel = true,
  groupedBy,
}: // initialeStateColumn,
DynamicTableProps) {
  // Use the state and functions returned from useTable to build your UI

  const table = useTable(
    {
      columns,
      data: data,
      //@ts-ignore
      getExportFileBlob,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    useExportData
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,

    rows, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    setHiddenColumns,
    state,
    preGlobalFilteredRows,
    exportData,
  }: any = useTable(
    {
      columns,
      data: data,
      //@ts-ignore
      getExportFileBlob,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    useExportData
  );
  const [footerIsVisible, setFooterIsVisible] = useState(false);
  React.useEffect(() => {
    const hiddenColumns = columns
      .filter((column: any) => column.show === false)
      .map((column: any) => column.accessor);

    setHiddenColumns(hiddenColumns);
  }, []);

  // Render the UI for your table
  return (
    <>
      {!hideToolbar && (
        <div className="flex-col gap-4 sm:flex  sm:gap-x-2">
          <div className="flex  gap-4 self-end  p-4">
            <button
              hidden={!exportExcel}
              onClick={() => {
                exportData('csv', true);
              }}
            >
              <img src={ExcelIcon} className="ml-1 inline h-10 w-10" />
            </button>

            <button
              hidden={!exportPdf}
              onClick={() => {
                exportData('pdf', true);
              }}
            >
              <img src={PdfIcon} className="ml-1 inline h-10 w-10" />
            </button>
          </div>
          <div className="flex overflow-x-scroll md:overflow-x-hidden ">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setSearch}
              dataCount={dataCount}
            />
            <div className="flex ">
              {headerGroups.map((headerGroup: any) =>
                headerGroup.headers.map((column: any) =>
                  column.Filter ? (
                    <div className="" key={column.id}>
                      {column.render('Filter')}
                    </div>
                  ) : null
                )
              )}
            </div>
          </div>
        </div>
      )}

      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead>
                  {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-between">
                            {column.canGroupBy && column.id === groupedBy ? (
                              <span {...column.getGroupByToggleProps()}>
                                {column.isGrouped ? '+' : '-'}
                              </span>
                            ) : null}
                            {column.render('Header')}

                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="h-4 w-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="h-4 w-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark   "
                >
                  {rows.map((row: any, i: any) => {
                    prepareRow(row);
                    var isDisabled = row.original['disabled'] ?? false;
                    return (
                      <React.Fragment key={row.id}>
                        <tr
                          {...row.getRowProps()}
                          className={`${
                            isDisabled &&
                            'bg-red-200 dark:bg-slate-200 dark:text-gray-400'
                          }`}
                        >
                          {row.cells.map((cell, index) => {
                            if (
                              (cell.isGrouped || cell.isAggregated) &&
                              !footerIsVisible
                            ) {
                              setFooterIsVisible(true);
                            }
                            return (
                              <td
                                {...cell.getCellProps()}
                                key={index + row.id}
                                className="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {cell.isGrouped ? (
                                  <>
                                    <span {...row.getToggleRowExpandedProps()}>
                                      {row.isExpanded ? '-' : '+'}
                                    </span>
                                    &nbsp;{cell.render('Cell')}&nbsp; (
                                    {row.subRows.length})
                                  </>
                                ) : cell.isAggregated ? (
                                  cell.render('Aggregated')
                                ) : (
                                  cell.render('Cell')
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>

                <Footer table={{ footerGroups }} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DynamicTable;

export function Footer({ table }) {
  const { footerGroups } = table;

  const footerIsExisted = footerGroups
    .map((group) =>
      group.headers.map((header) => {
        return header?.Footer.length === 0 ? false : header?.Footer;
      })
    )
    .flat()
    .filter(Boolean);
  if (
    !footerGroups ||
    footerGroups.length === 0 ||
    footerIsExisted.length === 0
  )
    return null;

  return (
    <tfoot className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
      {footerGroups.map((group) => (
        <tr {...group.getFooterGroupProps()} key={group.id}>
          {group.headers.map((column) => (
            <td
              {...column.getFooterProps()}
              key={column.id}
              className="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-500"
            >
              {column.render('Footer')}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}

{
  /* Pagination */
}
{
  /* <div className="flex items-center justify-between py-3 ">
        <div className="flex flex-1 justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div className="flex items-baseline gap-x-2">
            <span className="text-sm text-gray-700 dark:text-bodydark">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of{' '}
              <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-boxdark-2 dark:text-bodydark"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm "
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md dark:bg-boxdark-2 dark:text-bodydark"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div> */
}
