import { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import { PaperAirplaneIcon } from '@heroicons/react/solid';

import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  getTrainingRequests,
  TrainingRequestResponse,
  getTrainingRequestsByEmployeeId,
  deleteTrainingRequest,
} from '@/services/TrainingRequestService';
import { useTranslation } from 'react-i18next';
import { TableState } from 'react-table';
import moment from 'moment';
import { UserRoles } from '@/enums/UsersRole';
import { useAuth } from '@/context/AuthContext';
import { TrainingRequestState } from '@/enums/TrainingRequestState';
import { stateColors } from '@/enums/RequestLeaveState';
import { saveTrainingSatisfactionForm } from '@/services/TrainingSatisfactionFormService';
import { RootState } from '@/state';
import { useSelector } from 'react-redux';

function EmployeeTrainingTable() {
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');

  const { user } = useAuth();

  const [isTRLoading, setIsTRLoading] = useState(true);

  const navigate = useNavigate();

  const { t } = useTranslation();
  // const isTeamLeader = annualInterview.responsibleId == user?.id;
  // const isEmployee = annualInterview.employeeId == user?.id;
  const isTeamLeader = useMemo(
    () => annualInerview.responsibleId == user?.id,
    [user]
  );

  const columns = useMemo(
    () => [
      {
        Header: t('TrainingRequests.employeeFullName'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.requestedTrainingTitleColumn'),
        accessor: 'requestedTrainingTitle',
      },
      {
        Header: t('TrainingRequests.trainingProviderName'),
        accessor: 'trainingProviderName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.trainingManagerName'),
        accessor: 'trainingManagerName',
      },
      {
        Header: t('TrainingRequests.trainingProviderPhoneNumber'),
        accessor: 'trainingProviderPhoneNumber',
      },
      {
        Header: t('TrainingRequests.trainingProviderAddress'),
        accessor: 'trainingProviderAddress',
      },
      {
        Header: t('TrainingRequests.trainingStartDate'),
        accessor: 'trainingStartDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('TrainingRequests.trainingEndDate'),
        accessor: 'trainingEndDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('TrainingRequests.remarks'),
        accessor: 'remarks',
      },
      {
        Header: t('TrainingRequests.obtainedDiplomaTitle'),
        accessor: 'obtainedDiplomaTitle',
      },

      {
        Header: t('TrainingRequests.approvedByTeamLeader'),
        accessor: 'approvedByTeamLeader',
        Cell: ({ cell: { value } }) => {
          const frontendState = TrainingRequestState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: t('TrainingRequests.approvedByTrainingAdmin'),
        accessor: 'approvedByTrainingAdmin',
        Cell: ({ cell: { value } }) => {
          const frontendState = TrainingRequestState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const { isLoading, data: trainignRequestsData } = useQuery<
    TrainingRequestResponse | undefined
  >(
    ['UsertrainingRequests', pageIndex, pageSize, search],
    () =>
      getTrainingRequestsByEmployeeId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
        employeeId: annualInerview.employeeId,
        year: new Date().getFullYear(),
        isFinished: true,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (trainignRequestsData !== null && trainignRequestsData !== undefined) {
      setIsTRLoading((prv) => false);
    }
  }, [trainignRequestsData]);

  const handleDeleteTrainingRequest = useMutation(
    async (id: string) => {
      await deleteTrainingRequest(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'trainingRequests',
          pageIndex,
          pageSize,
          search,
        ]);
        queryClient.invalidateQueries([
          'UsertrainingRequests',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((trainignRequestsData?.count ?? 0) / pageSize);
  }, [pageSize, trainignRequestsData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={trainignRequestsData?.count}
            data={trainignRequestsData?.trainingRequests ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />

          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </>
  );
}

export default EmployeeTrainingTable;
