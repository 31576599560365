import { IsAuth, useAuth } from '@/context/AuthContext';
import { useLicenseContext } from '@/context/LicenseContext';
import { useEffect } from 'react';
import {
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
} from 'react-router-dom';

export const ProtectedRoute = ({ children }: any) => {
  const signed = IsAuth();
  const { loading, validLicense } = useLicenseContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!signed) {
      navigate('/auth/login');
    }
  }, [signed]);

  useEffect(() => {
    if (!validLicense) {
      navigate('/');
    }
  }, [validLicense]);

  // You can show a preloader here while checking the authentication status
  if (!signed) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};
