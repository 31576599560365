import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import { EditButton, DeleteButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useAuth } from '@/context/AuthContext';
import { RequestLeaveState, stateColors } from '@/enums/RequestLeaveState';
import {
  RequestLeave,
  RequestLeaveStateChange,
  changeRequestLeaveState,
  getRequestLeavesByTeamLeaderId,
} from '@/services/RequestLeavesService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const RequestsLeaveValidateTable = () => {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RequestLeave | null>();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const handleCancelReject = () => {
    setIsModalVisible(false);
  };

  const handleRejectConfirmation = async () => {
    // !!!handle reject request
    setLoading(true);
    const reqState: RequestLeaveStateChange = {
      requestLeaveId: selectedRow?.id!,
      state: RequestLeaveState.Rejected,
    };
    const data = await changeRequestLeaveState(reqState);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('RequestLeaveState.Rejected'),
        })
      );
      queryClient.invalidateQueries([
        'requestLeaves',
        user?.id,
        pageIndex,
        pageSize,
        search,
      ]);
    }
    setLoading(false);
    setIsModalVisible(false);
  };
  const handleApproveRequest = async (reqLeave: RequestLeave) => {
    // !!!handle approve request
    setLoading(true);
    const reqState: RequestLeaveStateChange = {
      requestLeaveId: reqLeave?.id!,
      state: RequestLeaveState.Approved,
    };
    const data = await changeRequestLeaveState(reqState);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('Approved'),
        })
      );
      queryClient.invalidateQueries([
        'requestLeaves',
        user?.id,
        pageIndex,
        pageSize,
        search,
      ]);
    }
    setLoading(false);
    setIsModalVisible(false);
  };

  const { user } = useAuth();
  const {
    data: requestLeavesData,
    isLoading: requestLeaveLoading,
    isSuccess,
  } = useQuery(
    ['requestLeaves', user?.id, pageIndex, pageSize, search],
    () =>
      getRequestLeavesByTeamLeaderId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
        teamLeaderId: user?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const ActionButtonsCell = ({ value }: any) => {
    const [requestLeave, setRequestLeave] = useState<RequestLeave>();

    useEffect(() => {
      if (isSuccess && requestLeavesData) {
        let res = requestLeavesData?.requestLeaves!.find(
          (r) => r.id === value
        )!;
        setRequestLeave(res);
      }
    }, [isSuccess, requestLeavesData, value, selectedRow]);

    const frontendState =
      RequestLeaveState[parseInt(requestLeave?.state!) ?? 0];
    const bgColor = stateColors[frontendState] || 'bg-gray-300';

    const requestStateComp = (
      <div
        className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
      >
        {frontendState !== null ? t(`RequestLeaveState.${frontendState}`) : ''}
      </div>
    );
    return (
      <div>
        {requestLeave?.state == RequestLeaveState.Pending.toString() ? (
          <>
            <EditButton onClick={() => handleApproveRequest(requestLeave!)}>
              {t('Requestleave.Approve')} {/* Translate the delete button */}
            </EditButton>

            <DeleteButton
              type="button"
              onClick={() => {
                setSelectedRow(requestLeave);
                setIsModalVisible(true);
              }}
            >
              {t('Requestleave.Reject')} {/* Translate the edit button */}
            </DeleteButton>
          </>
        ) : (
          requestStateComp
        )}
      </div>
    );
  };
  const pageCount = useMemo(() => {
    return Math.ceil((requestLeavesData?.count ?? 0) / pageSize);
  }, [pageSize, requestLeavesData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Requestleave.EmployeeFullName'),
        accessor: 'employeeFullName',
      },
      {
        Header: t('RequestLeaves.emailColumn'),
        accessor: 'email',
      },
      {
        Header: t('Requestleave.vacationName'),
        accessor: 'vacationName',
      },
      {
        Header: t('Requestleave.startDate'),
        accessor: 'fromDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD');
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('Requestleave.endDate'),
        accessor: 'toDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD');
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('transferRequestVacation.RequestedDays'),
        accessor: 'days',
      },

      {
        Header: t('Requestleave.notes'),
        accessor: 'note',
      },
      {
        Header: t('Requestleave.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            handleDelete={handleRejectConfirmation}
          />
        ),
      },
    ],
    [t, isSuccess, requestLeavesData]
  );
  return (
    <div className="flex flex-col">
      <DynamicTable
        dataCount={requestLeavesData?.count ?? 0}
        data={requestLeavesData?.requestLeaves ?? []}
        columns={columns}
        setSearch={setSearch}
        exportPdf={false}
      />
      {isModalVisible && (
        <DeleteConfirmationModal
          isOpen={isModalVisible}
          onDelete={handleRejectConfirmation}
          onCancel={handleCancelReject}
        />
      )}
      <Pagination
        pageIndex={pageCount === 0 ? -1 : pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};
export default RequestsLeaveValidateTable;
