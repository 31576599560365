import TextArea from '@/components/TextArea';
import { PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { InterviewState } from '@/enums/InterviewState';
import { UserRoles } from '@/enums/UsersRole';
import { RootState } from '@/state';
import {
  saveAnnualInterviewAsync,
  updateNextYearObjectives,
} from '@/state/slices/annualInterviewSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function NextYearObjectives() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const nextYearObjectives = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.nextYearObjectives
  );
  const disabled = annualInerview.interviewState == InterviewState.Completed;
  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateNextYearObjectives({ nextYearObjectives: e.target.value }));
  };
  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };
  return (
    <div className="mx-2 flex flex-col gap-2">
      <div className="group relative mb-6 w-full">
        <TextArea
          id="nextYearObjectives"
          name="nextYearObjectives"
          label={t('annualInterviewForm.nextYearObjectives')}
          value={nextYearObjectives ?? ''}
          onChange={handleChangeText}
          disabled={!isTeamLeader || disabled}
        />
      </div>
      {isTeamLeader && !disabled && (
        <div className="flex justify-between">
          <PrimaryButton
            className="border-0 bg-purple-300 dark:bg-purple-500"
            type="button"
            onClick={handleSyncData}
          >
            <div className="flex items-center gap-2">
              <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
              {t('annualInterviewForm.LastYearReview.Sync')}
            </div>
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            className="border-0 bg-primary-300 dark:bg-primary-500"
          >
            {t('annualInterviewForm.LastYearReview.submit')}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default NextYearObjectives;
