import { VacationsUrl } from '@/variables/Urls'; // Replace with actual URL
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { CalendarType } from './WorkCalendarsService';
import { getCompanyId } from './AuthService';
import { VacationType } from '@/enums/VacationTypeEnum';

export interface Vacation {
  id?: string;
  name: string;
  totalDays: number;
  year?: number;
  expirationYears?: number;
  companyId?: string;
  RemainingDays?: number;
  isNotLimited?: boolean;
  type?: VacationType;
}

export interface VacationsResponse {
  vacations: Vacation[];
  count: number;
}

const apiEndpoint = VacationsUrl; // Replace with actual URL

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  employeeId?: string;
}

export async function getVacations({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
        // Add any other necessary params
      },
    };
    const { data } = await api.get(apiEndpoint + '/Get', config); // Adjust the API endpoint
    return data as VacationsResponse;
  } catch (ex: any) {
    console.log('Error:', ex);
    throw ex;
  }
}
export async function getVacationsByEmployeeId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  employeeId,
}: PaginatingParams) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetAllByEmployeeId', config);
    return data as VacationsResponse;
  } catch (ex: any) {
    console.log('Error:', ex);
    throw ex;
  }
}

export async function getVacation(vacationId: string) {
  try {
    const { data } = await api.get<Vacation>(apiEndpoint + '/GetById', {
      params: { id: vacationId },
    }); // Adjust the API endpoint
    return data;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}

export async function saveVacation(vacation: Vacation) {
  if (vacation.id) {
    const body = { ...vacation };
    try {
      const response = await api.put(apiEndpoint + '/put', body); // Adjust the API endpoint
      return response;
    } catch (error) {
      console.log('Error:', error);
      throw error;
    }
  }

  try {
    const response = await api.post(apiEndpoint + '/post', vacation); // Adjust the API endpoint
    return response;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}

export async function deleteVacation(vacationId: string) {
  try {
    const response = await api.delete(apiEndpoint + '/delete', {
      data: { id: vacationId },
    }); // Adjust the API endpoint
    return response;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}
