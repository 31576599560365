export enum PersonnelType {
  Local,
  Expatriate,
}

export const PersonnelTypeOptions = Object.keys(PersonnelType)
  .filter((key) => isNaN(Number(PersonnelType[key])))
  .map((key) => ({
    id: key,
    name: PersonnelType[key],
  }));
