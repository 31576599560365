export enum JournalEntryType {
  Comptabilité,
  Achat,
  Statégie,
  HSE,
  Qualité,
  Contrat,
  Méthodes,
  Commercial,
  Technique,
  Financier,
  Couts,
  Planning,
}
export const JournalEntryTypeOptions = Object.keys(JournalEntryType)
  .filter((key) => isNaN(Number(JournalEntryType[key])))
  .map((key) => ({
    id: key,
    name: JournalEntryType[key],
  }));
