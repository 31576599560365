import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  InterfaceRequest,
  getInterfaceRequest,
  saveInterfaceRequest,
} from '@/services/InterfaceRequestService';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import { InterfaceType, InterfaceTypeOptions } from '@/enums/InterfaceType';
import {
  InterfaceRequestStatus,
  InterfaceRequestStatusOptions,
} from '@/enums/InterfaceRequestStatus';
import MultiSelect from '@/components/MultiSelect';
import moment from 'moment';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';

const validationSchema = Yup.object().shape({
  inputDataRequesterCompany: Yup.string().required(
    'InterfaceRequestForm.inputDataRequesterCompanyRequired'
  ),
  inputDataRequesterCompanyContact: Yup.string().required(
    'InterfaceRequestForm.inputDataRequesterCompanyContactRequired'
  ),

  interfaceType: Yup.string().required(
    'InterfaceRequestForm.interfaceTypeRequired'
  ),
  dueDate: Yup.date().required('InterfaceRequestForm.dueDateRequired'),
  dataProviderCompany: Yup.string().required(
    'InterfaceRequestForm.dataProviderCompanyRequired'
  ),
  dataProviderContactCompany: Yup.string().required(
    'InterfaceRequestForm.dataProviderContactCompanyRequired'
  ),
  dependencies: Yup.string().required(
    'InterfaceRequestForm.dependenciesRequired'
  ),

  progress: Yup.number().required('InterfaceRequestForm.progressRequired'),
});

const userTimeZone = moment.tz.guess();

interface FormValues extends InterfaceRequest {}

function InterfaceRequestForm() {
  const { requestId, id } = useParams();
  const editForm = requestId !== 'new';

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    inputDataRequesterCompany: '',
    inputDataRequesterCompanyContact: '',
    interfaceDescription: '',
    interfaceType: InterfaceType.Technical,
    dueDate: new Date(),
    dataProviderCompany: '',
    dataProviderContactCompany: '',
    dependencies: '',
    issuesAndRisks: '',
    correctiveActions: '',
    comments: '',
    progress: 0,
    status: InterfaceRequestStatus.InProgress,
    projectId: id!,
  });

  const [loading, setLoading] = useState(false);

  const {
    data: interfaceRequestData,
    isLoading,
    refetch: refetchInterfaceRequest,
  } = useQuery<InterfaceRequest | undefined>(
    ['interfaceRequest', requestId],
    async () => await getInterfaceRequest(requestId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (interfaceRequest) => {
        if (interfaceRequest) {
          setFormikValuesState((prev) => ({ ...prev, ...interfaceRequest }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && interfaceRequestData) {
      setFormikValuesState((prev) => ({
        ...prev,
        ...interfaceRequestData,
        dueDate: moment
          .utc(interfaceRequestData.dueDate)
          .tz(userTimeZone)
          .toDate(),
      }));
    }
  }, [editForm, interfaceRequestData]);

  const handleSaveInterfaceRequest = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const interfaceRequest: InterfaceRequest = {
        ...values,
      };

      const data = await saveInterfaceRequest(interfaceRequest);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.InterfaceRequest'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.InterfaceRequest'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchInterfaceRequest();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  // const { data: projectTeamMemebers, isLoading: isLoadingEmployees } = useQuery<
  //   ProjectTeamMemberResponse | undefined
  // >(
  //   ['projectTeamMemebers', id],
  //   async () =>
  //     await getProjectTeamMembersByProjectId({
  //       page: 0,
  //       take: 0,
  //       search: '',
  //       projectId: id!,
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     keepPreviousData: true,
  //   }
  // );

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('InterfaceRequestForm.editInterfaceRequest')
            : t('InterfaceRequestForm.addInterfaceRequest')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveInterfaceRequest}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-2 md:grid-cols-3">
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    labelClassName="w-full"
                    name="inputDataRequesterCompany"
                    id="inputDataRequesterCompany"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.inputDataRequesterCompany}
                    label={t(
                      'InterfaceRequestForm.inputDataRequesterCompanyLabel'
                    )}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    labelClassName="w-full"
                    name="inputDataRequesterCompanyContact"
                    id="inputDataRequesterCompanyContact"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.inputDataRequesterCompanyContact}
                    label={t(
                      'InterfaceRequestForm.inputDataRequesterCompanyContactLabel'
                    )}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="interfaceDescription"
                    labelClassName="w-full"
                    id="interfaceDescription"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.interfaceDescription}
                    label={t('InterfaceRequestForm.interfaceDescriptionLabel')}
                  />
                </div>
              </div>
              <div className="grid gap-2 md:grid-cols-3">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="interfaceType"
                    id="interfaceType"
                    label={t('InterfaceRequestForm.interfaceTypeLabel')}
                    value={values.interfaceType}
                    translatedNameSpace="InterfaceType"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    onChange={handleChange}
                    labelClassName="pl-0 w-full"
                    error={errors.interfaceType}
                    options={InterfaceTypeOptions ?? []}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <ReactDatePickerDefault
                    name="dueDate"
                    id="dueDate"
                    labelClassName="w-[40%]"
                    label={t('InterfaceRequestForm.dueDateLabel')}
                    value={values.dueDate ?? new Date()}
                    onChange={(dueDate: Date) => {
                      handleChange({
                        target: {
                          name: 'dueDate',
                          value: dueDate,
                        },
                      });
                    }}
                    errors={errors['dueDate']}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="dependencies"
                    id="dependencies"
                    placeholder=" "
                    labelClassName="w-[40%]"
                    onChange={handleChange}
                    errors={errors}
                    value={values.dependencies}
                    label={t('InterfaceRequestForm.dependenciesLabel')}
                  />
                </div>
              </div>
              <div className="grid gap-2 md:grid-cols-3">
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="dataProviderCompany"
                    id="dataProviderCompany"
                    placeholder=" "
                    onChange={handleChange}
                    labelClassName="w-full"
                    errors={errors}
                    value={values.dataProviderCompany}
                    label={t('InterfaceRequestForm.dataProviderCompanyLabel')}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="dataProviderContactCompany"
                    id="dataProviderContactCompany"
                    placeholder=" "
                    labelClassName="w-full"
                    onChange={handleChange}
                    errors={errors}
                    value={values.dataProviderContactCompany}
                    label={t(
                      'InterfaceRequestForm.dataProviderContactCompanyLabel'
                    )}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="progress"
                    id="progress"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.progress}
                    label={t('InterfaceRequestForm.progressLabel')}
                  />
                </div>
              </div>
              <div className="grid gap-2 md:grid-cols-3">
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="issuesAndRisks"
                    id="issuesAndRisks"
                    labelClassName="w-[40%]"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.issuesAndRisks}
                    label={t('InterfaceRequestForm.issuesAndRisksLabel')}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="correctiveActions"
                    id="correctiveActions"
                    placeholder=" "
                    labelClassName="w-[40%]"
                    onChange={handleChange}
                    errors={errors}
                    value={values.correctiveActions}
                    label={t('InterfaceRequestForm.correctiveActionsLabel')}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="comments"
                    id="comments"
                    labelClassName="w-[40%]"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.comments}
                    label={t('InterfaceRequestForm.commentsLabel')}
                  />
                </div>
              </div>
              <div className="grid gap-2 md:grid-cols-3">
                <div className="group relative mb-6 w-full">
                  <Dropdown
                    name="status"
                    id="status"
                    label={t('InterfaceRequestForm.statusLabel')}
                    value={values.status}
                    translatedNameSpace="InterfaceRequestStatus"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    onChange={handleChange}
                    labelClassName="pl-0 w-[50%]"
                    error={errors.status}
                    options={InterfaceRequestStatusOptions ?? []}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('InterfaceRequestForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('InterfaceRequestForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default InterfaceRequestForm;
