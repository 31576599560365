import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { default as DefaultInput } from '@/components/Input';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { resetPassword } from '@/services/AuthService';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    'Passwords must match'
  ),
});

function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const { t } = useTranslation();
  const handleSubmit = async (values) => {
    // Handle password reset logic here

    const data = await resetPassword({
      password: values.password,
      confirmPassword: values.confirmPassword,
      token: encodeURIComponent(token!),
      email: email!,
    });

    if (data) {
      navigate('/auth/login');
    }
  };

  return (
    <main id="content" role="main" className="mx-auto w-full max-w-md ">
      <div className="mt-7 rounded-xl bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            <h1 className="mb-3 block text-2xl font-bold text-gray-800 dark:text-white">
              {t('ResetPassword.resetPassword')}
            </h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="password"
                      name="password"
                      id="password"
                      placeholder=" "
                      onChange={handleChange}
                      labelClassName="w-[100%] "
                      errors={errors}
                      value={values.password}
                      label={t('ResetPassword.newPassword')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder=" "
                      labelClassName="w-[100%] "
                      onChange={handleChange}
                      errors={errors}
                      value={values.confirmPassword}
                      label={t('ResetPassword.confirmPassword')}
                    />
                  </div>
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-blue-500 px-4 py-3 text-sm font-semibold text-white transition-all hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
                  >
                    {t('ResetPassword.resetButton')}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPasswordForm;
