import { ProjectExpensesUrl } from '@/variables/Urls';

import api from '@/utils/api';
import { getCompanyId } from './AuthService';
import { ProjectExpenseModel } from '@/models/ProjectExpenseModel';
import { ExpensesState } from '@/enums/ExpensesState';

export interface ProjectExStateChange {
  id: string; // Assuming Guid maps to a string in TypeScript
  state: ExpensesState;
  comment?: string;
}
export interface ProjectExpensesResponse {
  projectExpenses: ProjectExpenseModel[];
  count: number;
}
const apiEndpoint = ProjectExpensesUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  teamLeaderId?: string;
  employeeId?: string;
}
export async function getProjectExpenses({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  employeeId,
  teamLeaderId,
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
      teamLeaderId: teamLeaderId,
      employeeId: employeeId,
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as ProjectExpensesResponse;
}

export async function getProjectExpense(projectExpId: string) {
  const { data } = await api.get<ProjectExpenseModel>(
    apiEndpoint + '/GetById',
    {
      params: { id: projectExpId },
    }
  );

  return data;
}

export async function saveProjectExpense(projectExp: ProjectExpenseModel) {
  if (projectExp.id) {
    const body = { ...projectExp };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', projectExp);
  return response_1;
}

export function deleteBranch(projectExpId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: projectExpId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
export async function changeProjectExState(
  ProjectExState: ProjectExStateChange
) {
  try {
    const response_1 = await api.post(
      apiEndpoint + '/ChangeState',
      ProjectExState
    );
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}
