import CardOne from '@/components/CardOne';
import Stepper from '@/components/Stepper';
import DefaultLayout from '@/layout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import GeneralEmployeeInformation from './components/GeneralEmployeeInformation';
import ContractEmployeeInformation from './components/ContractEmployeeInformation';
import UserEmployeeInformation from './components/UserEmployeeInformation';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmployeefrombackend,
  resetForm,
} from '@/state/slices/employeeSlice';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const steps = [
  {
    stepNumber: 1,
    title: 'EmployeeForm.generalInfoStepTitle',
    description: 'EmployeeForm.generalInfoStepDescription',
  },
  {
    stepNumber: 2,
    title: 'EmployeeForm.contractInfoStepTitle',
    description: 'EmployeeForm.contractInfoStepDescription',
  },
  {
    stepNumber: 3,
    title: 'EmployeeForm.userInfoStepTitle',
    description: 'EmployeeForm.userInfoStepDescription',
  },
];

export interface MultiStepFormProps {
  currentStep: number;
  stepsLength: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setIsSubContructor?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmployeeForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { id } = useParams();
  const employeeId = id !== 'new';
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { employee, contract, user, loading } = useSelector(
    (state: RootState) => state.employees
  );

  useEffect(() => {
    dispatch(resetForm());
    if (employeeId && id) {
      dispatch(getEmployeefrombackend(id));
    }
    return () => {
      // Clear the state when the component unmounts
      dispatch(resetForm());
    };
  }, [dispatch, employeeId]);

  return (
    <DefaultLayout>
      <div className="rounded-sm border border-stroke bg-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark">
        {/* Stepper */}
        <Stepper steps={steps} currentStep={currentStep} />

        {/* Your content for each step */}
        {currentStep === 1 && (
          <GeneralEmployeeInformation
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 2 && (
          <ContractEmployeeInformation
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 3 && (
          <UserEmployeeInformation
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {/* Button to navigate to the next step */}
        {/* {currentStep < steps.length && (
          <button
            onClick={() => setCurrentStep((prevStep) => prevStep + 1)}
            className="mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
          >
            {t('EmployeeForm.nextButton')}
          </button>
        )} */}
      </div>
    </DefaultLayout>
  );
};

export default EmployeeForm;
