export enum Priority {
  Low,
  Medium,
  High,
}
export const PriorityOptions = Object.keys(Priority)
  .filter((key) => isNaN(Number(Priority[key])))
  .map((key) => ({
    id: key,
    name: Priority[key],
  }));
