import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Input from '@/components/Input';
import TextArea from '@/components/TextArea';
import { PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { UserResponsibility } from '@/enums/UserResponsibility';
import { UserRoles } from '@/enums/UsersRole';
import { VacationTypeEnum } from '@/enums/VacationTypeEnum';
import StackedBarChart from '@/pages/Analytics/components/StackedBarChart';
import { Publication, savePublication } from '@/services/PublicationService';
import { EmployeeDataModel } from '@/services/RapportsServices';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectExpensesDataTable from './ProjectExpensesDataTable';
import PieChart from '@/pages/Analytics/components/PieChart';
import { ExpenseCategory } from '@/enums/ExpenseCategory';
import { ChartWrapperOptions } from 'react-google-charts';

export interface IGeneralResourceHumainDataProps {
  employee: EmployeeDataModel;
  publication: Publication;
}
function GeneralResourceHumainData({
  employee,
  publication,
}: IGeneralResourceHumainDataProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [_, setSearch] = useState<string>('');
  const [message, setMessage] = useState<string>(publication?.message ?? '');
  const columns = useMemo(
    () => [
      {
        Header: t('ResourceHumains.VacationName'),
        accessor: 'vacationName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('vacationBalances.VacationTotal'),
        accessor: 'vacationTotal',
        Cell: ({ value }: any) => {
          return <span>{value.toFixed(2)}</span>;
        },
      },
      {
        Header: t('vacationBalances.TokedDays'),
        accessor: 'tokedDays',
        // Cell: ({ row }) => {
        //   const totalDays = row.original.TotalDays || 0;
        //   const remainingDays = row.original.RemainingDays || 0;
        //   const tokedDays = totalDays - remainingDays;
        //   return <span>{tokedDays}</span>;
        // },
      },
      {
        Header: t('vacationBalances.RemainingDays'),
        accessor: 'remainingDays',
      },
    ],
    [t]
  );
  const columnsEmpargn = useMemo(
    () => [
      {
        Header: t('ResourceHumains.EmaprgnVacationName'),
        accessor: 'vacationName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('vacationBalances.VacationTotal'),
        accessor: 'vacationTotal',
        Cell: ({ value }: any) => {
          return <span>{value.toFixed(2)}</span>;
        },
      },
      // {
      //   Header: t('vacationBalances.TokedDays'),
      //   accessor: 'tokedDays',
      //   // Cell: ({ row }) => {
      //   //   const totalDays = row.original.TotalDays || 0;
      //   //   const remainingDays = row.original.RemainingDays || 0;
      //   //   const tokedDays = totalDays - remainingDays;
      //   //   return <span>{tokedDays}</span>;
      //   // },
      // },
      // {
      //   Header: t('vacationBalances.RemainingDays'),
      //   accessor: 'remainingDays',
      // },
    ],
    [t]
  );
  const CpVacations = employee?.vacactionBalanceData?.filter(
    (x) => x.vacationName === VacationTypeEnum.CP
  )[0];

  const RttVacations = employee?.vacactionBalanceData?.filter(
    (x) => x.vacationName == VacationTypeEnum.RTT
  )[0];
  const TtVacations = employee?.vacactionBalanceData?.filter(
    (x) => x.vacationName == VacationTypeEnum.TT
  )[0];
  const EmargnRTTVacations = employee?.emparnVacationBalance?.filter(
    (x) => x.vacationName == VacationTypeEnum.CET_RTT
  )[0];
  const EmargnCPVacations = employee?.emparnVacationBalance?.filter(
    (x) => x.vacationName == VacationTypeEnum.CET_CP
  )[0];
  // const data = useMemo(
  //   () => [
  //     ['', 'Pris', 'Restant'],
  //     [
  //       'CP',
  //       CpVacations?.tokedDays?.toString(),
  //       CpVacations?.remainingDays?.toString(),
  //     ],
  //     [
  //       'RTT',
  //       RttVacations?.tokedDays?.toString(),
  //       RttVacations?.remainingDays?.toString(),
  //     ],
  //     [
  //       'TT',
  //       TtVacations?.tokedDays?.toString(),
  //       TtVacations?.remainingDays?.toString(),
  //     ],
  //   ],
  //   [employee, CpVacations, RttVacations, TtVacations]
  // );

  const CompanyVacationChartData = useMemo(() => {
    return {
      labels: ['CP', 'RTT', 'TT'],
      datasets: [
        {
          label: t('vacationBalances.TokedDays'),
          data: [
            CpVacations?.tokedDays?.toString(),
            RttVacations?.tokedDays?.toString(),
            TtVacations?.tokedDays?.toString(),
          ],
          backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red
        },
        {
          label: t('vacationBalances.RemainingDays'),
          data: [
            CpVacations?.remainingDays?.toString(),
            RttVacations?.remainingDays?.toString(),
            TtVacations?.remainingDays?.toString(),
          ],
          backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue
        },
        {
          label: t('vacationBalances.VacationTotal'),
          data: [
            CpVacations?.vacationTotal?.toFixed(2),
            RttVacations?.vacationTotal?.toFixed(2),
            TtVacations?.vacationTotal?.toFixed(2),
          ],
          backgroundColor: 'rgba(245, 111, 245, 0.7)', // Green
        },
      ],
    };
  }, [CpVacations, RttVacations, TtVacations]);

  const CompanyEmargnVacationChartData = useMemo(() => {
    return {
      labels: ['CP', 'RTT'],
      datasets: [
        // {
        //   label: t('vacationBalances.TokedDays'),
        //   data: [
        //     EmargnCPVacations?.tokedDays?.toString(),
        //     EmargnRTTVacations?.tokedDays?.toString(),
        //   ],
        //   backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red
        // },
        // {
        //   label: t('vacationBalances.RemainingDays'),
        //   data: [
        //     EmargnCPVacations?.remainingDays?.toString(),
        //     EmargnRTTVacations?.remainingDays?.toString(),
        //   ],
        //   backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue
        // },
        {
          label: t('vacationBalances.VacationTotal'),
          data: [
            EmargnCPVacations?.vacationTotal?.toString(),
            EmargnRTTVacations?.vacationTotal?.toString(),
          ],
          backgroundColor: 'rgba(245, 111, 245, 0.7)', // Green
        },
      ],
    };
  }, [EmargnRTTVacations, EmargnCPVacations]);

  const handleSaveMessage = () => {
    savePublication({
      ...publication,
      message: message,
    });
  };

  const options: ChartWrapperOptions['options'] = {
    title: 'My Daily Activities',
    width: 450,
    height: 400,
    is3D: true,
    // legend: {
    //   position: 'bottom',
    //   textStyle: {
    //     color: '#000000', // Changing legend text color
    //   },
    // },
    animation: {
      startup: true,
      easing: 'inAndOut',
      duration: 1500,
    },
    backgroundColor: 'transparent',
    // titleTextStyle: {
    //   color: '#000000', // Changing title text color
    // },
  };
  const projectExpensegraphData: GraphDataItem[] =
    employee?.projectExpensesData?.map((item) => ({
      value: item.total,
      label: t(`ExpenseCategory.${ExpenseCategory[item.category]}`),
      color: getRandomColor(),
    })) ?? [];

  function getRandomColor() {
    // Generate random values for red, green, and blue channels
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Convert RGB values to hexadecimal format
    const hexColor = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;

    return hexColor;
  }
  return (
    <div className="flex flex-col gap-2">
      <Input
        label={t('GeneralProfileResource.lastAnnualInterview')}
        readOnly
        name="lastAnnualInterviewDate"
        id="lastAnnualInterviewDate"
        value={
          moment(employee.lastAnnualInterviewDate).isValid() &&
          moment(employee.lastAnnualInterviewDate).year() > 1
            ? moment(employee.lastAnnualInterviewDate).format('DD/MM/YYYY')
            : t('GeneralProfileResource.notApplicable')
        }
      />
      <Input
        label={t('GeneralProfileResource.lastTraining')}
        readOnly
        name="lastTrainingDate"
        id="lastTrainingDate"
        value={
          moment(employee.lastTrainingDate).isValid() &&
          moment(employee.lastTrainingDate).year() > 1
            ? moment(employee.lastTrainingDate).format('DD/MM/YYYY')
            : t('GeneralProfileResource.notApplicable')
        }
      />
      <Input
        label={t('GeneralProfileResource.lastMedicalVisit')}
        readOnly
        name="lastAppointmentDate"
        id="lastAppointmentDate"
        value={
          moment(employee?.lastAppointmentDate).isValid() &&
          moment(employee?.lastAppointmentDate).year() > 1
            ? moment(employee?.lastAppointmentDate).format('DD/MM/YYYY')
            : t('GeneralProfileResource.notApplicable')
        }
      />

      <div className=" grid gap-2 md:grid md:grid-cols-2 ">
        <div>
          <DynamicTable
            hideToolbar={true}
            dataCount={3}
            data={employee?.vacactionBalanceData ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
        </div>
        <StackedBarChart data={CompanyVacationChartData} />
        {/* <div>
          <DynamicTable
            hideToolbar={true}
            dataCount={3}
            data={employee?.emparnVacationBalance ?? []}
            columns={columns}
            setSearch={setSearch}
          />
        </div> */}
      </div>

      <div className=" grid gap-2 md:grid md:grid-cols-2 ">
        <div>
          <DynamicTable
            hideToolbar={true}
            dataCount={3}
            data={employee?.emparnVacationBalance ?? []}
            columns={columnsEmpargn}
            setSearch={setSearch}
            exportPdf={false}
          />
        </div>
        <StackedBarChart data={CompanyEmargnVacationChartData} />
      </div>

      <div className=" grid gap-2 md:grid md:grid-cols-2 ">
        <div>
          <ProjectExpensesDataTable
            data={employee?.projectExpensesData ?? []}
          />
        </div>
        <PieChart
          data={projectExpensegraphData ?? []}
          options={options}
          title={t('ExpenseCategoryPage.ExpenseCategoryType')}
        />
      </div>

      {(user?.responsibilityClaim ===
        UserResponsibility[UserResponsibility.GestionDesRH].toString() ||
        user?.role === UserRoles.Admin) && (
        <div className="flex flex-col gap-2">
          <TextArea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            id="message"
            label={t('GeneralDashboard.publication')}
            textAreaClassName="min-h-[350px] max-h-[350px] "
          />
          <div className="w-[100px] self-end">
            <PrimaryButton type="button" onClick={handleSaveMessage}>
              {t('annualInterviewForm.TrainingNeeded.submit')}
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default GeneralResourceHumainData;
