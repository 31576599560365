import { AssignedTask } from '@/enums/AssignedTask';
import api from '@/utils/api';
import { ProjectTeamMemberUrl } from '@/variables/Urls';
import { WorkHour } from './WorkHoursService';
import { WorkCalendar } from './WorkCalendarsService';

const apiEndpoint = ProjectTeamMemberUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface ProjectTeamMemberResponse {
  projectTeamMembers: ProjectTeamMember[];
  count: number;
}
export interface ProjectTeamMemberWorkChargesResponse {
  workCharges: ProjectTeamMemberWorkCharges[];
  count: number;
}

export interface ProjectTeamMemberWorkCharges extends ProjectTeamMember {
  workHours: WorkHour[];
  workCalendar?: WorkCalendar;
}

export interface ProjectTeamMember {
  id?: string;
  employeeId: string;
  employeeName?: string;
  departmentName?: string;
  branchName?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
  assignedTask: AssignedTask;
  projectId: string;
  intern?: boolean;
}

export async function getProjectTeamMembersByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<ProjectTeamMemberResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export async function getProjectTeamMemberWorkCharges({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<ProjectTeamMemberWorkChargesResponse>(
    apiEndpoint + '/GetWorkCharges',
    config
  );

  return data;
}
// Example of a save function, assuming there is an endpoint for saving/updating team members
export async function saveProjectTeamMember(teamMember: ProjectTeamMember) {
  if (teamMember.id) {
    const response = await api.put(apiEndpoint + '/put', teamMember);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', teamMember);
  return response;
}

export async function getProjectTeamMember(id: string) {
  const { data } = await api.get<ProjectTeamMember>(apiEndpoint + '/GetById', {
    params: { id },
  });

  return data;
}

export function deleteProjectTeamMember(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
