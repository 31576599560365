import { useLicenseContext } from '@/context/LicenseContext.tsx';
import CardFour from '../../components/CardFour.tsx';
import CardOne from '../../components/CardOne.tsx';
import CardThree from '../../components/CardThree.tsx';
import CardTwo from '../../components/CardTwo.tsx';
import DefaultLayout from '../../layout/DefaultLayout.tsx';
import { useTranslation } from 'react-i18next';
import { SupportPhoneNumber } from '@/variables/const.ts';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { Suspense } from 'react';
import Spinner from '@/components/Spinner.tsx';
import { useAuth } from '@/context/AuthContext.tsx';
import GeneralProfile from './components/GeneralProfile.tsx';
import Accordion from '@/components/Accordian.tsx';
import GeneralResourceHumainData from './components/GeneralResourceHumainData.tsx';
const DashboradAnalytics = () => {
  const { loading, validLicense, moduleType } = useLicenseContext(); // Use the useLicenseContext hook to access the license-related data
  const { user } = useAuth();
  const { t } = useTranslation();
  return (
    <DefaultLayout>
      {loading ? (
        <Spinner />
      ) : validLicense ? (
        <div className="flex flex-col">
          <GeneralProfile />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            gap: '10px',
          }}
        >
          {/* Replace 'your-svg-image.svg' with the path to your SVG image */}
          <ExclamationCircleIcon className="h-20 w-20 text-red-500" />
          <h2 className="text-xl">{t('license.expiredMessage')}</h2>
          <p className="text-xl">
            {t('license.contactSupportMessage', { phone: SupportPhoneNumber })}
          </p>
        </div>
      )}
    </DefaultLayout>
  );
};

export default DashboradAnalytics;
