import {
  BranchesUrl,
  CompaniesUrl,
  DeparetmentsUrl,
  PartnersUrl,
} from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Departement } from './DepartementService';

export interface Partner {
  name: string;
  id: string;
  companyId?: string;
}

export interface PartnersResponse {
  partners: Partner[];
  count: number;
}
const apiEndpoint = PartnersUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getPartners({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as PartnersResponse;
}

export async function getPartner(partnerId: string) {
  const { data } = await api.get<Partner>(apiEndpoint + '/GetById', {
    params: { id: partnerId },
  });

  return data;
}

export async function savePartner(partner: Partner) {
  if (partner.id) {
    const body = { ...partner };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', partner);
  return response_1;
}

export function deletePartner(partnerId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: partnerId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
