import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  saveMeetingSubject,
  getMeetingSubject,
  MeetingSubject,
} from '@/services/MeetingSubjectsService';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import moment from 'moment';
import { SubjectStatus, SubjectStatusOptions } from '@/enums/SubjectStatus';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import TextArea from '@/components/TextArea';
import { useAuth } from '@/context/AuthContext';
import {
  ProjectTeamMemberResponse,
  getProjectTeamMembersByProjectId,
} from '@/services/ProjectTeamMemberService';

const validationSchema = Yup.object().shape({
  description: Yup.string().required('MeetingSubjectsForm.descriptionRequired'),
  responsibleId: Yup.string().required(
    'MeetingSubjectsForm.responsibleIdRequired'
  ),
  deadline: Yup.date().required('MeetingSubjectsForm.deadlineRequired'),
  progressPercentage: Yup.number().required(
    'MeetingSubjectsForm.progressPercentageRequired'
  ),
});

const userTimeZone = moment.tz.guess();

function MeetingSubjectForm() {
  const { meetingId, subjectId } = useParams();
  const editForm = subjectId !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [initialValues, setInitialValues] = useState<MeetingSubjectDto>({
    responsibleId: '',
    responsibleName: '',
    projectMeetingId: meetingId,
    description: '',
    responsibleComments: '',
    responsibleSubjectProgress: 0,
    deadline: new Date(),
    comments: '',
    progressPercentage: 0,
    status: SubjectStatus.InProgress,
  });

  const {
    data: meetingSubjectData,
    isLoading,
    refetch: refetchMeetingSubject,
    isSuccess,
  } = useQuery<MeetingSubject | undefined>(
    ['meetingSubject', subjectId],
    async () => {
      if (editForm) {
        const response = await getMeetingSubject(subjectId!);
        return response;
      }
      return undefined;
    }
  );

  var isMeetingRes = useMemo(
    () =>
      editForm && meetingSubjectData
        ? meetingSubjectData?.responsibleId == user?.id
        : false,
    [meetingSubjectData, user, editForm]
  );
  useEffect(() => {
    if (editForm && meetingSubjectData) {
      setInitialValues((prev) => ({
        ...prev,
        ...meetingSubjectData,
        deadline: moment.utc(meetingSubjectData.deadline).toDate(),
      }));
    }
  }, [editForm, meetingSubjectData]);

  const handleSaveMeetingSubject = async (
    values: MeetingSubject,
    { resetForm }: any
  ) => {
    try {
      const response = await saveMeetingSubject(values);
      if (response) {
        toast.success(
          t('SuccessMessage', {
            name: t('ToastMsg.MeetingSubject'),
          })
        );
        navigate(-1);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const { data: projectTeamMemebers, isLoading: isLoadingEmployees } = useQuery<
    ProjectTeamMemberResponse | undefined
  >(
    ['projectTeamMemebers', id],
    async () =>
      await getProjectTeamMembersByProjectId({
        page: 0,
        take: 0,
        search: '',
        projectId: id!,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('MeetingSubjectsForm.editMeetingSubject')
            : t('MeetingSubjectsForm.addMeetingSubject')
        }
      />
      {isLoading || isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveMeetingSubject}
          enableReinitialize={true}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="description"
                    id="description"
                    label={t('MeetingSubjectsForm.descriptionLabel')}
                    value={values.description}
                    onChange={handleChange}
                    error={errors.description}
                  />
                </div>
                <div className="mb-4">
                  <Dropdown
                    id="responsibleId"
                    name="responsibleId"
                    label={t('MeetingSubjectsForm.responsibleLabel')}
                    value={values.responsibleId}
                    onChange={handleChange}
                    error={errors.responsibleId}
                    optionValue="employeeId"
                    optionLabel="email"
                    options={projectTeamMemebers?.projectTeamMembers ?? []}
                    labelClassName="w-[50%]"
                  />
                </div>
                <div className="mb-4">
                  {/* <DefaultInput
                    type="date"
                    name="deadline"
                    id="deadline"
                    label={t('MeetingSubjectsForm.deadlineLabel')}
                    value={moment(values.deadline).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    error={errors.deadline}
                  /> */}

                  <ReactDatePickerDefault
                    name="deadline"
                    id="deadline"
                    labelClassName="w-[40%]"
                    label={t('MeetingSubjectsForm.deadlineLabel')}
                    value={values.deadline ?? new Date()}
                    onChange={(deadline: Date) => {
                      handleChange({
                        target: {
                          name: 'deadline',
                          value: deadline,
                        },
                      });
                    }}
                    errors={errors['deadline']}
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="progressPercentage"
                    id="progressPercentage"
                    label={t('MeetingSubjectsForm.progressPercentageLabel')}
                    value={values.progressPercentage.toString()}
                    onChange={handleChange}
                    labelClassName=" w-full pl-0"
                    error={errors.progressPercentage}
                  />
                </div>
                <div className="mb-4">
                  <TextArea
                    name="comments"
                    id="comments"
                    label={t('DocRequest.comments')}
                    value={values.comments}
                    onChange={handleChange}
                    error={errors.progressPercentage}
                  />
                </div>

                {isMeetingRes && (
                  <>
                    <div className="mb-4">
                      <Dropdown
                        name="status"
                        id="status"
                        label={t('EquipmentForm.statusLabel')}
                        value={values.status}
                        translatedNameSpace="SubjectStatus"
                        isTranslated={true}
                        optionTransalatedKey="name"
                        optionValue="id"
                        labelClassName="pl-0"
                        error={errors.status}
                        options={SubjectStatusOptions ?? []}
                      />
                    </div>
                    <div className="mb-4">
                      <DefaultInput
                        type="number"
                        name="responsibleSubjectProgress"
                        id="responsibleSubjectProgress"
                        label={t(
                          'MeetingSubjectsForm.responsibleSubjectProgressLabel'
                        )}
                        value={values.responsibleSubjectProgress.toString()}
                        onChange={handleChange}
                        labelClassName=" w-full pl-0"
                        error={errors.responsibleSubjectProgress}
                      />
                    </div>
                    <div className="mb-4">
                      <TextArea
                        name="responsibleComments"
                        id="responsibleComments"
                        label={t(
                          'MeetingSubjectsForm.responsibleCommentsLabel'
                        )}
                        value={values.responsibleComments}
                        onChange={handleChange}
                        error={errors.responsibleComments}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                >
                  {t('MeetingSubjectsForm.submitButton')}
                </button>
                <button
                  type="button"
                  className="focus:shadow-outline ml-4 rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400 focus:outline-none"
                  onClick={() => {
                    resetForm();
                    navigate(-1);
                  }}
                >
                  {t('MeetingSubjectsForm.cancelButton')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default MeetingSubjectForm;
