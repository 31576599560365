import React, { useState } from 'react';
import ChildrenTableForm from './ChildrenTableForm';
import { ChildModel } from '@/services/JobDescriptionService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { removeChild } from '@/state/slices/jobdescriptionSlice';

interface ChildrenTableProps {
  children: ChildModel[];
  readonly?: boolean;
}

const ChildrenTable: React.FC<ChildrenTableProps> = ({
  children,
  readonly = false,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editChild, setEditChild] = useState<ChildModel | null>(null);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const handleAddClick = () => {
    setEditChild(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (child: ChildModel) => {
    setEditChild(child);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (childId: string) => {
    dispatch(removeChild({ childId: childId }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditChild(null);
  };

  const handleSaveChild = (child: ChildModel) => {
    // Handle save logic
    // Call the API or dispatch an action to save the child
    closeModal();
  };

  return (
    <div className="w-full overflow-auto">
      <table className="w-full max-w-full overflow-x-scroll text-left text-sm text-gray-500 dark:text-gray-400">
        {/* Table headers */}
        <thead className="text-md bg-gray-50 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3">{t('children.firstName')}</th>
            <th className="px-6 py-3">{t('children.lastName')}</th>
            <th className="px-6 py-3">{t('children.gender')}</th>
            <th className="px-6 py-3">{t('children.birthDate')}</th>
            <th className="px-6 py-3">{t('children.isDependent')}</th>
            {!readonly && (
              <th className="px-6 py-3">{t('children.actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {children.map((child) => (
            <tr
              key={child.id}
              className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <td className="px-6 py-3">{child.firstName}</td>
              <td>{child.lastName}</td>
              <td className="px-6 py-3">{child.gender}</td>
              <td className="px-6 py-3">
                {child?.dateOfBirth
                  ? moment(child?.dateOfBirth ?? new Date()).format(
                      'DD/MM/YYYY'
                    )
                  : ''}
              </td>
              <td className="px-6 py-3">
                <input type="checkbox" checked={child.isDependent} disabled />
              </td>
              {!readonly && (
                <td className="flex px-6 py-3">
                  <button
                    type="button"
                    onClick={() => handleEditClick(child)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {t('children.edit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteClick(child.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    {t('children.delete')}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {!readonly && (
        <div className="my-4 flex justify-end">
          <button
            type="button"
            onClick={handleAddClick}
            className="mb-2 mr-2 rounded-lg bg-green-500 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {t('children.add')}
          </button>
        </div>
      )}

      {isModalOpen && (
        <ChildrenTableForm
          initialValues={editChild || undefined}
          onSubmit={handleSaveChild}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ChildrenTable;
