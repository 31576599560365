import {
  BranchesUrl,
  CompaniesUrl,
  DeparetmentsUrl,
  PublicationsUrl,
} from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Departement } from './DepartementService';

export interface Publication {
  message: string;
  id: string;
  companyId?: string;
}

const apiEndpoint = PublicationsUrl;

export async function getPublication(companyId: string) {
  const { data } = await api.get<Publication>(apiEndpoint + '/GetByCompanyId', {
    params: { companyId: companyId },
  });

  return data;
}

export async function savePublication(publication: Publication) {
  if (publication.id) {
    const body = { ...publication };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', publication);
  return response_1;
}
