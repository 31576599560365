import api from '@/utils/api';
import { getCompanyId } from './AuthService';
import { BranchesResponse, Branch } from './BranchService';
import { RatingDataModel } from '@/models/CompanyRHDataModel';
import { RatingState } from '@/enums/RatingState';
import { RatingsUrl } from '@/variables/Urls';

const apiEndpoint = RatingsUrl;

export interface Rating {
  employeeId: string;
  ratingValue: RatingState;
}
export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getAllRating() {
  let config = {
    params: {
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetByCompanyId', config);
  return data as Rating;
}

export async function saveRating(rating: Rating) {
  const response_1 = await api.post(apiEndpoint + '/post', rating);
  return response_1;
}
