import { useEffect } from 'react';
import Input from '@/components/Input';
import { siteName } from '@/variables/Urls';
import Accordion from '@/components/Accordian';
import { GenderEnum } from '@/enums/genderEnum';
import { useAuth } from '@/context/AuthContext';
import {
  EmployeeDataModel,
  getEmployeeData,
} from '@/services/RapportsServices';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import Spinner from '@/components/Spinner';
import GeneralResourceHumainData from './GeneralResourceHumainData';
import { UserResponsibility } from '@/enums/UserResponsibility';
import CompanyResourceHumainsData from './CompanyResourceHumainsData';
import TextArea from '@/components/TextArea';
import { Publication, getPublication } from '@/services/PublicationService';
import { getCompanyId } from '@/services/AuthService';
import SignalRService from '@/services/SignalRService';
import { UserRoles } from '@/enums/UsersRole';
import RatingCompany from './RatingCompany';

function GeneralProfile() {
  const { user } = useAuth();

  const {
    isLoading,
    refetch: refetchEmployeeQuery,
    data: employee,
  } = useQuery<EmployeeDataModel | undefined>(
    ['employeeData', user?.id],
    () => {
      if (user?.role !== UserRoles.Admin) {
        // Fetch data only for non-admin users
        return getEmployeeData(user?.id!);
      } else {
        // Return undefined if query is not enabled
        return undefined;
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const { data: publication, refetch: refetchPublication } = useQuery<
    Publication | undefined
  >(
    ['publication', user?.id],
    () => {
      var companyId = getCompanyId();
      if (companyId) {
        return getPublication(companyId as string);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  //@ts-ignore
  const isDevelopment = import.meta.env.MODE === 'development';

  useEffect(() => {
    SignalRService.onReceivePublicationsSync(() => {
      refetchPublication();
    });
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="flex flex-col">
      {/* profile */}
      <Accordion title={t('GeneralDashboard.publication')}>
        <TextArea
          name="message"
          value={publication?.message}
          id="message"
          label=""
          textAreaClassName="min-h-[350px] max-h-[350px] "
        />
      </Accordion>
      {/* profile */}
      {user?.role !== UserRoles.Admin && (
        <>
          <Accordion title={t('GeneralDashboard.Rating')}>
            {employee && (
              <RatingCompany
                employee={employee}
                onSave={refetchEmployeeQuery}
              />
            )}
          </Accordion>
          <Accordion title={t('GeneralDashboard.profile')}>
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-1 flex  items-center justify-center">
                <img
                  src={
                    isDevelopment
                      ? siteName + employee?.profileImgUrl
                      : employee?.profileImgUrl
                  }
                  className="h-40 w-40  rounded-lg"
                />
              </div>
              <div className="col-span-3">
                <div className="flex flex-col gap-3 md:grid md:grid-cols-2 md:gap-2">
                  <div className="flex flex-col gap-4">
                    <Input
                      name="start"
                      value={employee?.firstName + ' ' + employee?.lastName}
                      label={t(
                        'jobDescriptionDetails.generalInformation.fullName'
                      )}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={employee?.age ?? '0'}
                      label={t('GeneralDashboard.age')}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={
                        //@ts-ignore
                        t(`GenderEnum.${GenderEnum[employee?.gender ?? 2]}`)
                      }
                      label={t(
                        'jobDescriptionDetails.generalInformation.gender'
                      )}
                      labelDir="inLine"
                      containerClass="w-full self-end"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={employee?.teamName}
                      label={t('GeneralDashboard.team')}
                      labelDir="inLine"
                      containerClass="w-full self-end"
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <Input
                      name="start"
                      value={employee?.idNumber}
                      label={t('GeneralDashboard.idNumber')}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={employee?.jobTitle ?? ''}
                      label={t('GeneralDashboard.post')}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                    <Input
                      name="start"
                      value={employee?.branchName ?? ''}
                      label={t('GeneralDashboard.branch')}
                      labelDir="inLine"
                      containerClass="w-full "
                      readOnly
                    />
                    <Input
                      name="start"
                      value={employee?.teamLeaderFullName ?? 'N/A'}
                      label={t('GeneralDashboard.teamLeader')}
                      labelDir="inLine"
                      containerClass="w-full"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
          {/* vacations balances  */}
          <Accordion title={t('GeneralDashboard.personalAccount')}>
            <GeneralResourceHumainData
              employee={employee!}
              publication={publication!}
            />
          </Accordion>
        </>
      )}
      {(user?.responsibilityClaim ===
        UserResponsibility[UserResponsibility.GestionDesRH].toString() ||
        user?.role === UserRoles.Admin) && (
        <Accordion title={t('GeneralDashboard.companyIndicators')}>
          <CompanyResourceHumainsData />
        </Accordion>
      )}
    </div>
  );
}

export default GeneralProfile;
