export enum AgeSlice {
  _20_25,
  _25_30,
  _30_35,
  _35_40,
  _40_45,
  _45_50,
  _50_55,
  _55_60,
  _60_65,
}
