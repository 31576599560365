export enum DeliverableStatus {
  Available,
  DOE,
  InProgress,
  ToRevise,
  ToValidate,
  Abandoned,
  BPE,
  BPS,
  BPV,
  MOEApproval,
  AwaitingApproval,
  VAO,
  VAC,
  VSO,
  Deleted,
  Rejected,
  NoObservation,
}
export const DeliverableStatusOptions = Object.keys(DeliverableStatus)
  .filter((key) => isNaN(Number(DeliverableStatus[key])))
  .map((key) => ({
    id: key,
    name: DeliverableStatus[key],
  }));
