export enum EquipmentStatus {
  InService,
  OutOfService,
  UnderMaintenance,
  Decommissioned,
  Reserved,
  UnderInspection,
  AwaitingParts,
  BeingConfigured,
  UnderTest,
  InTraining,
  BeingDismantled,
  BeingTransferred,
  BeingCalibrated,
  SafetyReserve,
  UnderRefurbishment,
}

export const EquipmentStatusOptions = Object.keys(EquipmentStatus)
  .filter((key) => isNaN(Number(EquipmentStatus[key])))
  .map((key) => ({
    id: key,
    name: EquipmentStatus[key],
  }));
