import {
  BranchesUrl,
  CompaniesUrl,
  DeparetmentsUrl,
  ProjectsUrl,
} from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Departement } from './DepartementService';
import { ProjectType } from '@/enums/ProjectType';
import { Currency } from '@/enums/Currency';
import { JVType } from '@/enums/JVType';
import { ProjectContractType } from '@/enums/ProjectContractType';
import { ProjectAOStatus } from '@/enums/ProjectAOStatus';

export interface ProjectBrief {
  state: ProjectAOStatus;
  bidManagerName: string;
  title: string;
  date: string;
  id: string;
}
export interface Project {
  id?: string;
  companyId: string;
  title: string;
  description: string;
  offerNumber: number;
  submissionDate: Date;
  name: string;
  siteAddress: string;
  countryId?: number;
  competitorsIds: string[];
  startDate: Date;
  endDate: Date;
  durationMonths: number;
  type: ProjectType;
  partnersIds?: string[];
  client: string;
  engineer: string;
  subContractorsIds?: string[];
  projectAmount: number;
  projectCurrency: Currency;
  isConsortium: boolean;
  consortiumPercentage: number;
  jointVentureType: JVType;
  position: string;
  contractType: ProjectContractType;
  isVariantAccepted: boolean;
  isDesignStudyIncluded: boolean;
  hasExperienceInCountry: boolean;
  experienceStatus: boolean | null;
  advancePaymentStatus: boolean;
  advancePaymentAmount: number;
  retentionStatus: boolean;
  retentionAmount: number;
  priceRating: number;
  technicalRating: number;
  deadlineRating: number;
  bidManagerId: string;
  projectOwnerId: string;
  projectExecutorId: string;
}

export interface ProjectsResponse {
  projects: Project[];
  count: number;
}
export interface ProjectsBriefResponse {
  projects: ProjectBrief[];
  count: number;
}

export interface PaginatingParams {
  take?: number;
  from?: number;
  search?: string;
}

const apiEndpoint = ProjectsUrl;

export async function getProjects({
  from = 0,
  take = 10,
  search = '',
}: PaginatingParams): Promise<ProjectsResponse> {
  let config = {
    params: {
      page: from,
      take: take,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as ProjectsResponse;
}

export async function getProjectsBrief({
  from = 0,
  take = 10,
  search = '',
}: PaginatingParams): Promise<ProjectsBriefResponse> {
  let config = {
    params: {
      page: from,
      take: take,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetBriefs', config);
  return data as ProjectsBriefResponse;
}

export async function getProjectById(projectId: string): Promise<Project> {
  const { data } = await api.get<Project>(`${apiEndpoint}/GetById`, {
    params: { id: projectId },
  });
  return data;
}

export async function saveProject(
  project: Project
): Promise<AxiosResponse<string>> {
  if (project.id) {
    const body = { ...project };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', project);
  return response_1;
}

export async function updateProject(
  project: Project
): Promise<AxiosResponse<string>> {
  const response = await api.put(`${apiEndpoint}/Put`, project);
  return response;
}

export function deleteProject(projectId: string): Promise<AxiosResponse> {
  return api
    .delete(apiEndpoint + '/Delete', { data: { id: projectId } })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}
