import { NotificationState, NotificationType } from '@/enums/NotificationEnum';
import api from '@/utils/api';
import { notificationURL } from '@/variables/Urls';

import { AxiosResponse } from 'axios';

export interface NotificationsResponse {
  notifications: NotificationModel[];
  count: number;
}
export interface NotificationModel {
  id: string;
  message: string;
  createdDate: Date;
  notificationType: NotificationType;
  isSeen?: boolean;
  entityId?: string;
}
export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  isSeen: boolean;
}
// Function to get notifications
export async function getNotifications({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  isSeen,
}: PaginatingParmas): Promise<NotificationsResponse> {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        isSeen: isSeen,
      },
    };
    const response: AxiosResponse<NotificationsResponse> = await api.get(
      notificationURL + '/GetByReceptionId',
      config
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
}

// Function to mark a notification as seen
export async function markNotificationAsSeen(
  notificationId: string
): Promise<void> {
  try {
    const response: AxiosResponse<void> = await api.put(
      `${notificationURL}/Seen`,
      { notificationId: notificationId }
    );
  } catch (error) {
    console.error('Error marking notification as seen:', error);
    throw error;
  }
}
export async function markAllNotificationAsSeen(): Promise<void> {
  try {
    const response: AxiosResponse<void> = await api.put(
      `${notificationURL}/MarkAllAsSeen`,
      {}
    );
  } catch (error) {
    console.error('Error marking notification as seen:', error);
    throw error;
  }
}
