import { TrainingRequestState } from '@/enums/TrainingRequestState';
import api from '@/utils/api';
import { TrainingRequestsUrl } from '@/variables/Urls';
import { getCompanyId } from './AuthService';
import { TrainingType } from '@/enums/TrainingType';
import { TrainingRequestType } from '@/enums/TrainingRequestType';

const apiEndpoint = TrainingRequestsUrl;

export interface TrainingRequest {
  id?: string;
  employeeId?: string;
  companyId?: string;
  approvedByTeamLeader?: TrainingRequestState;
  approvedByTrainingAdmin?: TrainingRequestState;
  requestedTrainingTitle: string;
  trainingProviderName: string;
  trainingProviderAddress: string;
  trainingProviderPhoneNumber: string;
  trainingProviderEmail: string;
  trainingProviderWebsite: string;
  trainingManagerName: string;
  trainingObjectives?: string;
  trainingStartDate: Date;
  trainingEndDate: Date;
  totalTrainingHours: number;
  rating?: number;
  trainingCost: number;
  cpfFinanced: boolean;
  diplomaIssued: boolean;
  obtainedDiplomaTitle: string;
  trainingLevel: string;
  internalTraining: boolean;
  externalTraining: boolean;
  employeeRequestedTraining: boolean;
  managerRequestedTraining: boolean;
  workApplicationTraining: boolean;
  satisfactionFormSent: boolean;
  remarks?: string;
  employeeFullName?: string;
  teamLeaderFullName?: string;
  teamLeaderId?: string;
  trainingAdminId?: string;
  trainingType?: TrainingType;
  trainingRequestType?: TrainingRequestType;
}

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  employeeId?: string;
  teamLeaderId?: string;
  year?: number;
  isFinished?: boolean;
}

export interface TrainingRequestResponse {
  trainingRequests: TrainingRequest[];
  count: number;
}
export interface ChangeTrainingRequestStateProps {
  trainingRequestState: TrainingRequestState;
  id: string;
  comment?: string;
  adminId: string;
}
export async function getTrainingRequests({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  year,
  isFinished,
  teamLeaderId,
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
      year: year,
      isFinished: isFinished,
      teamLeaderId,
    },
  };
  const { data } = await api.get(apiEndpoint + '/get', config);
  return data as TrainingRequestResponse;
}

export async function getTrainingRequestsByEmployeeId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  employeeId,
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      employeeId: employeeId,
      year: new Date().getFullYear(),
      // isFinished: true,
    },
  };
  const { data } = await api.get(apiEndpoint + '/getByEmployeeId', config);
  return data as TrainingRequestResponse;
}
export async function getTrainingRequest(trainingRequestId: string) {
  const { data } = await api.get<TrainingRequest>(apiEndpoint + '/GetById', {
    params: { id: trainingRequestId },
  });

  return data;
}
export async function saveTrainingRequest(trainingRequest: TrainingRequest) {
  if (trainingRequest.id) {
    const body = { ...trainingRequest };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', trainingRequest);
  return response_1;
}

export async function changeTrainingRequestState({
  trainingRequestState,
  id,
  comment,
  adminId,
}: ChangeTrainingRequestStateProps) {
  try {
    const body = {
      id: id,
      comment: comment,
      state: trainingRequestState,
      adminId: adminId,
    };
    const response_1 = await api.post(apiEndpoint + '/ChangeState', body);
    return response_1;
    {
    }
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function deleteTrainingRequest(trainingRequestId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: trainingRequestId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
