import React from 'react';
import 'tailwindcss/tailwind.css'; // Make sure to import Tailwind CSS styles
import { TrainingDataModel } from '@/models/CompanyRHDataModel'; // Replace with the correct path
import { TrainingType, TrainingTypeOptions } from '@/enums/TrainingType';
import { getEnumNames, getTranslatedEnumNames } from '@/enums/SeniorityRange';
import { useTranslation } from 'react-i18next';

const TrainingDataTable: React.FC<{
  data: TrainingDataModel[];
}> = ({ data }) => {
  // Array of all TrainingType values

  const { t } = useTranslation();
  const trainingTypes = getTranslatedEnumNames(
    TrainingType,
    t,
    'TrainingTypes'
  );
  return (
    <div className="mt-4 flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('TrainingDataByType.trainingType')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('TrainingDataByType.hours')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('TrainingDataByType.cost')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark  ">
                {
                  (console.log(
                    'this is teainig ',
                    trainingTypes,
                    TrainingType['Internal']
                  ),
                  TrainingTypeOptions.map((type) => {
                    const training = data.find(
                      (item) => item.trainingType === type.id
                    ) || {
                      trainingType: type.id,
                      hours: 0,
                      cost: 0,
                    };

                    return (
                      <tr key={type.id}>
                        <td className="whitespace-nowrap px-6 py-4">
                          {t(`TrainingTypes.${type.name}`)}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {training.hours}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {training.cost}
                        </td>
                      </tr>
                    );
                  }))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingDataTable;
