import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { SubProjectGroupsUrl } from '@/variables/Urls';

export interface SubProjectGroup {
  id: string;
  title: string;
  companyId?: string;
}

export interface SubProjectGroupResponse {
  groups: SubProjectGroup[];
  count: number;
}

const apiEndpoint = SubProjectGroupsUrl;

export interface PaginatingParams {
  take?: number;
  page?: number;
  search?: string;
}

export async function getSubProjectGroups({
  page,
  take,
  search,
}: PaginatingParams): Promise<SubProjectGroupResponse> {
  const config = {
    params: {
      page,
      take,
      search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get<SubProjectGroupResponse>(
    `${apiEndpoint}/get`,
    config
  );
  return data;
}

export async function getSubProjectGroup(
  subProjectGroupId: string
): Promise<SubProjectGroup> {
  const { data } = await api.get<SubProjectGroup>(`${apiEndpoint}/getbyid`, {
    params: { id: subProjectGroupId },
  });
  return data;
}

export async function saveSubProjectGroup(
  subProjectGroup: SubProjectGroup
): Promise<AxiosResponse> {
  if (subProjectGroup.id) {
    const body = { ...subProjectGroup };
    const response = await api.put(`${apiEndpoint}/update`, body);
    return response;
  }
  const response = await api.post(`${apiEndpoint}/create`, subProjectGroup);
  return response;
}
