import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, {
  ModuleTypeCell,
  SelectColumnFilter,
  StatusLicense,
} from '@/components/DynamicTable';
import DefaultLayout from '@/layout/DefaultLayout';
import {
  CompaniesResponse,
  deleteCompany,
  getCompanies,
} from '@/services/CompanyService';

import { Toast, initTE } from 'tw-elements';

import { useMemo, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useNavigate } from 'react-router-dom';
function Company() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        {/* Delete button */}
        {/* <button onClick={() => handleDelete(row.original.id)}></button> */}
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          Delete
        </DeleteButton>

        {/* Edit button */}
        <EditButton
          type="button"
          onClick={() => {
            navigate(`/companies/${value}`);
          }}
        >
          Edit
        </EditButton>

        {/* <button onClick={() => handleEdit(row.original.id)}>Edit</button> */}
      </div>
    );
  };

  const handleCancelDelete = () => {
    // Close the modal without performing the delete operation
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    // Perform the delete operation
    handleDeleteCompany.mutate(selectedRow);
    // Close the modal
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      // First group columns
      {
        Header: 'Name',
        accessor: 'name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Phone Number ',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Modules',
        accessor: 'moduleTypes',
        Cell: ({ cell: { value } }: any) => <ModuleTypeCell value={value} />,
      },
      {
        Header: 'Licence Status',
        accessor: 'isValidLicense',

        Cell: ({ cell: { value } }: any) => <StatusLicense value={value} />,
      },
      // Action buttons column
      {
        Header: 'Actions',
        accessor: 'id', // Assuming you have an 'id' field in your data to identify rows
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell value={value} handleDelete={handleDeleteCompany} />
        ),
      },
    ],
    []
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: companies,
  } = useQuery<CompaniesResponse | undefined>(
    ['companies', pageIndex, pageSize, search],
    () =>
      getCompanies({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const handleDeleteCompany = useMutation(
    async (id: string) => {
      await deleteCompany(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'companies',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  let pageCount = Math.ceil((companies?.count ?? 0) / pageSize);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName="Companies"
        addbtn={true}
        addRouter="/companies/new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={companies?.count}
            data={companies?.companies ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default Company;
