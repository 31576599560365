import api from '@/utils/api';
import { getCompanyId } from './AuthService';
import { TrainingProvidersUrl } from '@/variables/Urls';

const apiEndpoint = TrainingProvidersUrl;

export interface TrainingProvider {
  name: string;
  address: string;
  phoneNumber: string;
  email: string;
  website: string;
  note: number;
}

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  employeeId?: string;
}

export interface TrainingProviderResponse {
  trainingProviders: TrainingProvider[];
  count: number;
}

export async function getTrainingProviders({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/get', config);
  return data as TrainingProviderResponse;
}
