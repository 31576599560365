export enum ProjectAOStatus {
  New,
  Won,
  Lost,
  Cancelled,
  Deferred,
}

export const ProjectAOStatusOptions = Object.keys(ProjectAOStatus)
  .filter((key) => isNaN(Number(ProjectAOStatus[key])))
  .map((key) => ({
    id: key,
    name: ProjectAOStatus[key],
  }));
export const ProjectAOStatusColor = {
  New: '#d1d5db',
  Won: '#86efac',
  Lost: '#fca5a5',
  Cancelled: '#fdba74',
  Deferred: '#94a3b8',
};
export const ProjectAOStatusTextColor = {
  New: '#6b7280',
  Won: '#16a34a',
  Lost: '#ef4444',
  Cancelled: '#f97316',
  Deferred: '#64748b',
};
