import { MeetingType } from '@/enums/MeetingType';
import api from '@/utils/api';
import { ProjectMeetingsUrl } from '@/variables/Urls';

const apiEndpoint = ProjectMeetingsUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface ProjectMeetingResponse {
  projectMeetings: ProjectMeeting[];
  count: number;
}

export interface ProjectMeeting {
  id: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  meetingType: MeetingType;
  objectives: string;
  agenda: string;
  topics: string;
  nextMeetingDate: Date;
  nextMeetingTime?: Date;
  participantIds: string[];
  organizerId: string;
  projectId: string;
}

export async function getProjectMeetingsByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<ProjectMeetingResponse>(
    `${apiEndpoint}/GetByProjectId`,
    config
  );

  return data;
}

export async function saveProjectMeeting(meeting: ProjectMeeting) {
  if (meeting.id) {
    const response = await api.put(`${apiEndpoint}/put`, meeting);
    return response.data;
  }

  const response = await api.post(`${apiEndpoint}/post`, meeting);
  return response.data;
}

export async function getProjectMeeting(id: string) {
  const { data } = await api.get<ProjectMeeting>(`${apiEndpoint}/GetById`, {
    params: { id },
  });

  return data;
}

export function deleteProjectMeeting(id: string) {
  return api
    .delete(`${apiEndpoint}/delete`, { data: { id } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
