import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './satoshi.css';
import { AuthProvider } from '@/context/AuthContext.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { store } from './state';
import ErrorBoundary from './pages/Error/ErrorBoundary';

const queryClient = new QueryClient();

function AppWithTranslation() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <App />
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
}
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AppWithTranslation />
  </React.StrictMode>
);
