import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/solid';
import { DataAndMonthPicker } from './DateAndMonthPicker';
import { useTranslation } from 'react-i18next';
import { getDaysData } from '@/utils/CalendarHelper';
import { locales, weekdays } from 'moment';
import { format } from 'date-fns';
import arLocale from 'date-fns/locale/ar'; // Import the Arabic locale
import enLocale from 'date-fns/locale/en-US'; // Import the Arabic locale
import esLocale from 'date-fns/locale/es'; // Import the Arabic locale
import deLocale from 'date-fns/locale/de'; // Import the Arabic locale
import frLocale from 'date-fns/locale/fr'; // Import the Arabic locale
import { registerLocale } from 'react-datepicker';
import { OffDay } from '@/services/WorkCalendarsService';
import { DaysCalendarColor } from '@/variables/const';
import moment from 'moment';
import useColorMode from '@/hooks/useColorMode';
import Avatar from './Avatar';
import { siteName } from '@/variables/Urls';

registerLocale('ar', arLocale);
registerLocale('en', enLocale);
registerLocale('es', esLocale);
registerLocale('de', deLocale);
registerLocale('fr', frLocale);

/* Timeline Interfaces */
export interface ResourceType {
  name: string;
  avatar: string;
  title?: string;
  id: string;
}

export interface Event {
  start: Date;
  end: Date;
  title: string;
  resourceId: string;
  tooltip?: string;
  color: string;
}

export interface OffDaysType extends OffDay {
  employeeId: string;
}

export interface WeekendsType {
  employeeId: string;
  days: string[];
}
/* --- End Timeline Interfaces ---*/
export interface TimelineProps {
  resourcesLength: number;
  daysOfWeek?: { day: number; dayOfWeek: string; isToday: boolean }[];
  resourcesList: ResourceType[];
  searchResource: any;
  setSearchResource?: any;
  handleSearchResource?: any;
  selectedDate?: any;
  setSelectedDate?: any;
  isTodayinMonth?: any;
  selectedLanguage?: string;
  myEvents: Event[];

  weekendDays?: WeekendsType[];
  holidays: OffDaysType[];
  enterpriseClosing: OffDaysType[];
}
export const Timeline = React.forwardRef(
  (
    {
      resourcesLength,
      resourcesList,
      searchResource,
      setSearchResource,
      handleSearchResource,
      weekendDays,
      selectedDate,
      setSelectedDate,
      myEvents,
      holidays,
      enterpriseClosing,
    }: TimelineProps,
    ref
  ) => {
    const { i18n, t } = useTranslation();
    // const [isDarkMode, setIsDarkMode] = useState(false);
    const [colorMode, setColorMode] = useColorMode();
    const [, updateDummyState] = useState();

    const [daysOfWeek, setDaysOfWeek] = useState([]);
    //const [specialEventsData, setSpecialEventsData] = useState(new Map());
    const [isTodayinMonth, setIsTodayinMonth] = useState<any>(true);
    const specialDays: OffDay[] = [...holidays, ...enterpriseClosing];

    const locales: any = {
      en: enLocale,
      ar: arLocale,
      es: esLocale,
      de: deLocale,
      fr: frLocale,
    };

    useEffect(() => {
      let daysOfWeek1 = getDaysData(selectedDate, i18n.language);
      setDaysOfWeek(daysOfWeek1);
      const isToday = selectedDate.toDateString() === new Date().toDateString();
      setIsTodayinMonth((prev: boolean) => (prev = isToday));
    }, [selectedDate, i18n.language]);

    useEffect(() => {
      const className = 'dark';
      const bodyClass = window.document.body.classList;

      colorMode === 'dark'
        ? bodyClass.add(className)
        : bodyClass.remove(className);
    }, [colorMode]);

    // Create refs for both sections
    const resourcesRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);

    // State to track the scroll positions of both sections
    const [resourcesScrollTop, setResourcesScrollTop] = useState(0);
    const [calendarScrollTop, setCalendarScrollTop] = useState(0);

    // // Event handler to update the scroll positions
    // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    //   const { scrollTop } = event.currentTarget;
    //   if (event.currentTarget === resourcesRef.current) {
    //     //setResourcesScrollTop(scrollTop);
    //     if (calendarRef.current) {
    //       calendarRef.current.scrollTop = scrollTop;
    //     }
    //   } else if (event.currentTarget === calendarRef.current) {
    //     setCalendarScrollTop(scrollTop);
    //     if (resourcesRef.current) {
    //       resourcesRef.current.scrollTop = scrollTop;
    //     }
    //   }
    // };

    // // Add scroll event listeners to both sections
    // useEffect(() => {
    //   if (resourcesRef.current) {
    //     resourcesRef.current.addEventListener('scroll', handleScroll);
    //   }
    //   if (calendarRef.current) {
    //     calendarRef.current.addEventListener('scroll', handleScroll);
    //   }
    //   return () => {
    //     if (resourcesRef.current) {
    //       resourcesRef.current.removeEventListener('scroll', handleScroll);
    //     }
    //     if (calendarRef.current) {
    //       calendarRef.current.removeEventListener('scroll', handleScroll);
    //     }
    //   };
    // }, [resourcesRef.current, calendarRef.current]);

    const decreaseMonth = () => {
      const currentDate = new Date(selectedDate);
      currentDate.setMonth(currentDate.getMonth() - 1);
      setSelectedDate(currentDate);
    };

    const increaseMonth = () => {
      const currentDate = new Date(selectedDate);
      currentDate.setMonth(currentDate.getMonth() + 1);
      setSelectedDate(currentDate);
    };
    // Generate cell ID based on resource ID and day number
    const generateCellId = (
      resourceId: string,
      dayOfWeek: string,
      month: number,
      year: number
    ) => `${resourceId}-${dayOfWeek}-${month}-${year}`;

    const currentMonth = selectedDate.getMonth() + 1; // Months are 0-indexed, so we add 1.
    const currentYear = selectedDate.getFullYear();

    // Function to create an array of events with single days
    const createSingleDayEvents = (events: any) => {
      const singleDayEventsMap = new Map();

      events.forEach((event: any) => {
        const { start, end, resourceId, ...rest } = event;
        const currentDay = new Date(start);

        while (currentDay <= end) {
          const eventId = generateCellId(
            resourceId,
            currentDay.getDate().toString(),
            currentDay.getMonth() + 1,
            currentDay.getFullYear()
          );

          const singleDayEvent = {
            ...rest,
            start: new Date(currentDay),
            end: new Date(currentDay),
            resourceId: resourceId,
            id: eventId,
          };

          //singleDayEvents.push(singleDayEvent);
          singleDayEventsMap.set(eventId, singleDayEvent);
          currentDay.setDate(currentDay.getDate() + 1);
        }
      });

      return singleDayEventsMap;
    };

    // Usage
    const myEventsData = useMemo(() => {
      // Your original events array
      // ...

      const singleDayEvents = createSingleDayEvents(myEvents);

      return singleDayEvents;
    }, [myEvents]);

    // events for weekends and special days

    const createSpecialEvents = (specialDays: OffDaysType[]) => {
      const specialEventsMap = new Map();

      specialDays.forEach((specialDay) => {
        const { fromDate, toDate, isPayed, employeeId } = specialDay;
        const start = moment(fromDate).startOf('day').toDate();
        const end = moment(toDate).endOf('day').toDate();

        const currentDay = new Date(start);
        while (currentDay <= end) {
          const type = isPayed ? 'holiday' : 'enterpriseClosing';

          const eventId = `${employeeId}-${currentDay.getDate()}-${
            currentDay.getMonth() + 1
          }-${currentDay.getFullYear()}`;
          const specialEvent = {
            title: type,
            start: new Date(currentDay),
            end: new Date(currentDay),
            resourceId: employeeId,
            id: eventId,
            color: DaysCalendarColor[type],
          };

          specialEventsMap.set(eventId, specialEvent);
          currentDay.setDate(currentDay.getDate() + 1);
        }
      });
      // for (const weekend of weekendDays) {
      //   for (const day of weekend.days) {
      //     const dayIndex = daysOfWeek.findIndex(
      //       (weekDay) => weekDay.dayOfWeek === day
      //     );

      //     if (dayIndex !== -1) {
      //       const currentDate = new Date(selectedDate);
      //       currentDate.setDate(currentDate.getDate() + dayIndex);

      //       for (let i = 0; i < resourcesList.length; i++) {
      //         const eventId = `${
      //           weekend.employeeId
      //         }-${day}-${currentDate.getMonth()}-${currentDate.getFullYear()}`;

      //         const weekendEvent = {
      //           title: 'Weekend',
      //           start: new Date(currentDate),
      //           end: new Date(currentDate),
      //           resourceId: weekend.employeeId,
      //           id: eventId,
      //           color: DaysCalendarColor.weekend,
      //         };

      //         specialEventsMap.set(eventId, weekendEvent);
      //       }
      //     }
      //   }
      // }

      return specialEventsMap;
    };

    const specialEventsData = useMemo(() => {
      const allSpecialDays = [...holidays, ...enterpriseClosing];

      return createSpecialEvents(allSpecialDays);

      // setSpecialEventsData(special);
    }, [t, selectedDate]);

    // Function to get the resource index in the grid
    const calendarGridRef = useRef<HTMLDivElement>(null);

    const updateGridTemplateRows = () => {
      if (calendarGridRef.current) {
        const grid = calendarGridRef.current;
        const gridTemplateRows = `repeat(${resourcesList.length + 1}, 52px)`;
        grid.style.gridTemplateRows = gridTemplateRows;
      }
    };

    useEffect(() => {
      updateGridTemplateRows();
    }, [resourcesList.length, specialEventsData]);

    const isDevelopment = import.meta.env.MODE === 'development';

    const url = (imageData: string) => {
      return isDevelopment ? siteName + imageData : imageData;
    };
    return (
      <div
        ref={ref}
        className="  flex overflow-x-scroll  bg-white p-0 dark:border-strokedark dark:bg-boxdark dark:text-bodydark"
      >
        {/* Legend */}

        {/* //! Resources Pannel */}
        <div
          className="bg-red flex 
              min-w-[270px] border-r-2 border-[#dbe5ec]"
        >
          <div ref={resourcesRef} className="  w-full overflow-y-scroll">
            <div
              className="sticky top-0 flex flex-col items-center  justify-between
           bg-white dark:border-strokedark dark:bg-boxdark dark:text-bodydark"
            >
              <div className="flex w-full justify-between px-2">
                <div>
                  <p>{t('Timeline.employees')} </p>
                  <p>
                    {resourcesList.length < 0 ? 0 : resourcesList.length}
                    of
                    {resourcesList.length < 0 ? 0 : resourcesList.length}
                  </p>
                </div>
                <div>
                  <span
                    onClick={() => setSearchResource('')}
                    className="cursor-pointer font-medium text-[#0db5df]"
                  >
                    <p>{t('Timeline.clearAll')}</p>
                  </span>
                </div>
              </div>

              <div
                key={'whitespace'}
                className="flex h-[52px]
                                w-full items-center border-b border-[#dbe5ec]
                               pl-4   "
              >
                <SearchIcon
                  className={`text-heading 
                  rounded-mddark:text-white
                   h-7
                    w-7
                     -rotate-180
                     cursor-pointer 
                     `}
                />
                <input
                  className="text-md  outline-none hover:ring-0 focus:ring-0 dark:border-strokedark dark:bg-boxdark dark:text-bodydark "
                  value={searchResource}
                  onChange={(e) => {
                    setSearchResource(e.target.value);
                  }}
                />
              </div>
            </div>

            {/* list of resources  */}
            <ul className="m-0 list-none p-0">
              {resourcesList.map(({ id, avatar, name, title }, index) => (
                <li
                  key={id}
                  className="flex h-[52px]
                                items-center border-b border-[#dbe5ec]
                               pl-4"
                >
                  <a href="#" className="inline-flex bg-none p-0 ">
                    <img
                      src={url(avatar)}
                      alt={name}
                      className="mr-4 h-[40px] w-[40px] rounded-full object-cover "
                    />

                    <div className="mb-0 mt-0">
                      <p className="text-sm font-semibold">{name}</p>
                      {/* <p className="text-grey text-sm">{title}</p> */}
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* //? calander Module  */}
        <div
          ref={calendarRef}
          className="flex w-full flex-col overflow-x-hidden "
        >
          {/* //!header */}
          <div
            className="z-13   flex min-h-[50px]
                   w-[100%] items-center gap-2 bg-[#F4F7F9]    dark:border-strokedark dark:bg-boxdark dark:text-bodydark
                  "
          >
            <div className="flex items-center gap-2   dark:border-strokedark dark:bg-boxdark dark:text-bodydark">
              <button
                className="flex cursor-pointer items-center justify-center "
                onClick={decreaseMonth}
              >
                <ArrowRightIcon
                  className={`text-heading 
                  h-5 w-5 -rotate-180 cursor-pointer rounded-md`}
                />
              </button>
              <button
                className="flex cursor-pointer items-center justify-center  "
                onClick={increaseMonth}
              >
                <ArrowRightIcon
                  className={`text-heading 
                  h-5 w-5 cursor-pointer rounded-md`}
                />
              </button>
            </div>
            <DataAndMonthPicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedLanguage={i18n.language}
            />

            {!isTodayinMonth && (
              <button
                className="ml-6 text-sm font-medium text-[#0db5df]"
                onClick={() => setSelectedDate(new Date())}
              >
                {t('Timeline.today')}
              </button>
            )}
          </div>

          <div
            ref={calendarGridRef}
            className=" relative  grid h-full w-full overflow-x-scroll "
            style={{
              gridTemplateColumns: `repeat(${daysOfWeek.length}, minmax(50px, 1fr))`,
              gridTemplateRows: `repeat(${resourcesList.length}, 52px)`,
              gridAutoFlow: 'column',
            }}
          >
            {daysOfWeek.map(({ day, dayOfWeek, isToday }, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className=" sticky top-0 
              flex flex-col  items-center
               justify-center  border-b border-gray-300  bg-[#F4F7F9]  p-2
                px-2 dark:border-strokedark dark:bg-boxdark dark:text-white
               "
                    style={{
                      gridArea: `1 / ${day} / span 1 / span 1`,
                      color: isToday ? '#0db5df' : undefined,
                      boxShadow: isToday
                        ? 'inset 0 -4px 0 0 #0db5df'
                        : undefined,
                    }}
                  >
                    <p className="text-sm">{day}</p>
                    <p className="text-sm">{dayOfWeek}</p>
                  </div>
                </React.Fragment>
              );
            })}

            {resourcesList.map((resource, resourceIndex) => {
              return (
                <React.Fragment key={resource.id}>
                  {daysOfWeek.map(({ day, dayOfWeek }, index) => {
                    const cellId = generateCellId(
                      resource.id,
                      day.toString(),
                      currentMonth,
                      currentYear
                    );
                    // const eventId = generateCellId(
                    //   resourceId,
                    //   currentDay.getDate().toString(),
                    //   currentDay.getMonth() + 1,
                    //   currentDay.getFullYear()
                    // );
                    const specialCellId = `${
                      resource.id
                    }-${day.toString()}-${currentMonth}-${currentYear}`;
                    // const weekendCellId = `${resource.id}-${dayOfWeek}-${currentMonth}-${currentYear}`;
                    const isWeekend = weekendDays?.some(
                      (weekend) =>
                        weekend &&
                        weekend.days &&
                        weekend.days.includes(dayOfWeek) &&
                        weekend.employeeId == resource.id
                    );

                    return (
                      <div
                        key={cellId}
                        className="linear-gradient(#dbe5ec 1px,transparent 0) dark:text-black"
                        style={{
                          backgroundPosition: '-1px -1px',
                          backgroundSize: ' 52px 52px',
                          borderBottom: '1px solid #dbe5ec',
                          borderRight: '1px solid #dbe5ec',
                          gridArea: `${resourceIndex + 2} / ${
                            index + 1
                          } / span 1 / span 1`,
                          position: 'relative', // Add this to allow absolute positioning inside
                        }}
                      >
                        {isWeekend && (
                          <div
                            className={`${DaysCalendarColor.weekend}`}
                            style={{
                              textAlign: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              position: 'absolute', // Position the colored div absolutely
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          ></div>
                        )}
                        {specialEventsData.get(specialCellId) && (
                          <div
                            className={`${
                              specialEventsData.get(specialCellId).color
                            }`}
                            style={{
                              zIndex: 10,
                              textAlign: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              position: 'absolute', // Position the colored div absolutely
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          ></div>
                        )}

                        {myEventsData.get(cellId) && (
                          <div
                            style={{
                              backgroundColor: myEventsData.get(cellId).color,
                              paddingInline: '8px',
                              zIndex: 10,
                              textAlign: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              position: 'absolute', // Position the colored div absolutely
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              overflow: 'hidden', // Clip overflow content
                              whiteSpace: 'nowrap', // Prevent text from wrapping
                              textOverflow: 'ellipsis',
                            }}
                            title={t(
                              `VacationType.${myEventsData.get(cellId).title}`
                            )}
                          >
                            {/* You can add any content you want here */}
                            {myEventsData.get(cellId).title}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

{
  /* <div className="flex w-full border-t-2 border-[#dbe5ec] bg-white p-4 dark:bg-boxdark dark:text-bodydark">
        <p className="mb-2 text-sm">{t('Legend')}</p>
        <div className="flex gap-2">
          <div
            className="h-4 w-4 rounded-md bg-red-500"
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Holiday')}</p>
        </div>
        <div className="flex gap-2">
          <div
            className="h-4 w-4 rounded-md bg-blue-500"
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Weekend')}</p>
        </div>
        <div className="flex gap-2">
          <div
            className="h-4 w-4 rounded-md bg-green-500"
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Enterprise Closing')}</p>
        </div>
      </div> */
}
