import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import {
  ProjectCharacteristics,
  getProjectCharacteristics,
  saveProjectCharacteristics,
} from '@/services/ProjectCharacteristicsService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

const validationSchema = Yup.object().shape({
  positivePoints: Yup.string().required(
    'ProjectCharacteristicsForm.positivePointsRequired'
  ),
  negativePoints: Yup.string().required(
    'ProjectCharacteristicsForm.negativePointsRequired'
  ),
  pointsOfVigilance: Yup.string().required(
    'ProjectCharacteristicsForm.pointsOfVigilanceRequired'
  ),
});

interface FormValues extends ProjectCharacteristics {}

function ProjectCharacteristicsForm() {
  const { charId, id } = useParams();
  const editForm = charId !== 'new';
  console.log('🚀 ~ ProjectCharacteristicsForm ~ charId:', charId, editForm);

  const navigate = useNavigate();

  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    positivePoints: '',
    negativePoints: '',
    pointsOfVigilance: '',
    projectId: id!,
  });
  const [loading, setLoading] = useState(false);

  const {
    data: projectCharacteristicsData,
    isLoading,
    refetch: refetchProjectCharacteristics,
  } = useQuery<ProjectCharacteristics | undefined>(
    ['projectCharacteristicsSingle', charId],
    async () => await getProjectCharacteristics(charId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: true,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (projectCharacteristics) => {
        if (projectCharacteristics) {
          setFormikValuesState((prev) => ({
            ...prev,
            ...projectCharacteristics,
          }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && projectCharacteristicsData) {
      // Update form values with project characteristics data
      setFormikValuesState((prev) => ({
        ...prev,
        ...projectCharacteristicsData,
      }));
    }
  }, [editForm, projectCharacteristicsData]);

  const handleSaveProjectCharacteristics = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const companyId = getCompanyId();
      const projectCharacteristics: ProjectCharacteristics = {
        ...values,
      };

      const data = await saveProjectCharacteristics(projectCharacteristics);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.ProjectCharacteristics'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.ProjectCharacteristics'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchProjectCharacteristics();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('ProjectCharacteristicsForm.editProjectCharacteristics')
            : t('ProjectCharacteristicsForm.addProjectCharacteristics')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveProjectCharacteristics}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="positivePoints"
                  id="positivePoints"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.positivePoints}
                  label={t('ProjectCharacteristicsForm.positivePointsLabel')}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="negativePoints"
                  id="negativePoints"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.negativePoints}
                  label={t('ProjectCharacteristicsForm.negativePointsLabel')}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="pointsOfVigilance"
                  id="pointsOfVigilance"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.pointsOfVigilance}
                  label={t('ProjectCharacteristicsForm.pointsOfVigilanceLabel')}
                />
              </div>

              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('ProjectCharacteristicsForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('ProjectCharacteristicsForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default ProjectCharacteristicsForm;
