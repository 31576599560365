import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { classNames } from './shared/Utils';
import { uniqueId } from 'lodash';

export interface EditableOption {
  value: string;
  label: string;
}

interface EditableDropdownProps {
  label: string;
  options: any[];
  optionValue?: string;
  optionLabel?: string;
  value?: string | null;
  onChange?: (selectedValue: string | null, title: string | null) => void;
  error?: string | undefined;
  labelDir?: 'inLine' | 'Above';
  containerClass?: string;
  labelClassName?: string;
  isTranslated?: boolean;
  translatedNameSpace?: string;
  optionTransalatedKey?: string;
  name?: string;
  id?: string;
}

const EditableDropdown = ({
  label,
  options,
  value,
  onChange,
  optionValue = 'id',
  optionLabel = 'name',
  labelDir = 'Above',
  containerClass,
  labelClassName,
  error,
  name,
  id,
  isTranslated,
  translatedNameSpace,
  optionTransalatedKey,
}: EditableDropdownProps) => {
  const { t } = useTranslation();
  const [internalOptions, setInternalOptions] = useState(
    options.map((option) => ({
      value: option[optionValue],
      label: isTranslated
        ? t(
            `${translatedNameSpace}.${
              option[optionTransalatedKey ?? optionLabel]
            }`
          )
        : option[optionLabel],
    }))
  );

  const handleChange = (selectedOption: any) => {
    console.log('🚀 ~ handleChange ~ selectedOption:', selectedOption);
    if (onChange) {
      onChange(selectedOption ? selectedOption.value : null, null);
    }
  };

  const handleCreate = (inputValue: string) => {
    console.log('🚀 ~ handleCreate ~ inputValue:', inputValue);
    const newOption = {
      value: 'newO' + uniqueId(),
      label: inputValue,
    };

    setInternalOptions((prevOptions) => [...prevOptions, newOption]);

    if (onChange) {
      onChange(newOption.value, inputValue);
    }
  };

  const selectedValue = internalOptions.find(
    (option) => option.value === value
  );

  return (
    <div
      className={classNames(
        containerClass ?? '',
        labelDir === 'Above'
          ? 'relative'
          : 'relative inline-flex w-full items-baseline gap-4'
      )}
    >
      <label
        htmlFor={id}
        className={classNames(
          labelClassName ?? '',
          `mb-2 block w-[30%] ${
            labelDir === 'Above' && 'pl-0'
          } text-sm font-medium
          ${
            error
              ? 'border-red-500 text-red-700 dark:text-red-500'
              : 'text-gray-900 dark:text-white'
          }`
        )}
      >
        {label}
      </label>
      <div className="w-full">
        <CreatableSelect
          id={id}
          name={name}
          isClearable
          onChange={handleChange}
          onCreateOption={handleCreate}
          options={internalOptions}
          value={selectedValue}
          classNamePrefix="react-select"
          className={`block w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-black dark:placeholder-gray-400 
          ${error ? 'border-red-500 dark:border-red-500' : ''}`}
        />
        {error && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {t(error)}
          </p>
        )}
      </div>
    </div>
  );
};

export default EditableDropdown;
