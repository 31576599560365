import happy from '@/images/emoji/happy.png';
import sad from '@/images/emoji/sad.png';
import neutral from '@/images/emoji/neutral.png';

export enum RatingState {
  SatisfiedEmployees,
  PassiveEmployees,
  DissatisfiedEmployees,
}

export const getRatingImage = (rating: RatingState) => {
  switch (rating) {
    case RatingState.SatisfiedEmployees:
      return happy;
      break;
    case RatingState.DissatisfiedEmployees:
      return sad;
      break;
    case RatingState.PassiveEmployees:
      return neutral;
      break;
    default:
      return neutral;
      break;
  }
};
