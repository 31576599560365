import React, { useState } from 'react';

export interface Tab {
  title: string;
  content: React.ReactNode;
  index: number;
}

export interface TabNavigationProps {
  tabs: Tab[];
  activeIndex: number;
  onTabChange: (index: number) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({
  tabs,
  activeIndex,
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].title); // Set the first tab as active

  const handleTabClick = (index: number) => {
    onTabChange(index);
  };

  return (
    <div>
      <div className="border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
        <ul className="-mb-px flex flex-wrap">
          {tabs.map((tab, index) => (
            <li key={index} className="mr-2">
              <a
                href="#"
                className={`inline-block rounded-t-lg border-b-2 border-transparent p-4 ${
                  index === activeIndex
                    ? 'border-primary-600 text-primary hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300'
                    : ''
                }`}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="p-4">
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{ display: index === activeIndex ? 'block' : 'none' }}
          >
            {tab.content}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default TabNavigation;
