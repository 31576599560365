// MultiSelect.tsx

import React, { useMemo } from 'react';
import Select from 'react-select';

export interface Options {
  label: string;
  value: string;
}
interface MultiSelectProps {
  id: string;
  name: string;
  options: { [key: string]: any }[]; // Array of objects with keys for value and label
  selectedValue: string[];
  onChange: (selectedOptions: string[]) => void;
  optionValue: string;
  optionLabel: string;
  label: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  name,
  options,
  selectedValue,
  onChange,
  optionValue,
  optionLabel,
  label,
}) => {
  const selectedOptionsValues = useMemo(
    () =>
      options
        .filter((option) => selectedValue.includes(option[optionValue]))
        .map((option) => ({
          value: option[optionValue],
          label: option[optionLabel],
        })),
    [selectedValue, options]
  );

  return (
    <div className="group relative z-0 mb-6 w-full">
      <label
        htmlFor={id}
        className={`mb-2 block text-sm font-medium text-gray-900 dark:text-white`}
      >
        {label}
      </label>
      <Select
        id={id}
        name={name}
        classNames={{
          control: () => `block w-full rounded-lg border
            border-gray-300   text-sm
             text-gray-900 focus:border-blue-500
              focus:ring-blue-500 dark:border-gray-600
               dark:bg-gray-700 dark:text-white
                dark:placeholder-gray-400
                 dark:focus:border-blue-500
                  dark:focus:ring-blue-500 
                  font-bold text-lg dark:text-white
                  `,
          menu: () => 'bg-white dark:bg-gray-700',
        }}
        options={options.map((option) => ({
          value: option[optionValue],
          label: option[optionLabel],
        }))}
        isMulti
        value={selectedOptionsValues}
        onChange={(selectedOptions, { action }) => {
          if (action === 'select-option' || action === 'remove-value') {
            onChange(selectedOptions.map((o) => o.value));
          }
        }}
      />
    </div>
  );
};

export default MultiSelect;
