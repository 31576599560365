export enum DocumentTypeEnum {
  AttestationMutuelle,
  EmploymentContract,
  Payslips,
  EmployerCertificates,
  WorkCertificates,
  WithholdingTaxNotices,
  HoursWorkedStatements,
  TrainingCertificates,
  PerformanceEvaluations,
  InternalRegulationsAndCollectiveAgreements,
  WorkPermitsForForeignEmployees,
  MedicalRecords,
  CompanyAgreements,
  ProfessionalTrainingJustifications,
  MinutesOfWorksCouncilMeetingsOrEmployeeRepresentatives,
}

export const DocTypeOptions = Object.keys(DocumentTypeEnum)
  .filter((key) => isNaN(Number(DocumentTypeEnum[key])))
  .map((key) => ({
    id: key,
    name: DocumentTypeEnum[key],
  }));
