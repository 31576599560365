import { QuestionStatus } from '@/enums/QuestionStatus';
import api from '@/utils/api';
import { QuestionResponseUrl } from '@/variables/Urls';

const apiEndpoint = QuestionResponseUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface QuestionResponseDto {
  id?: string;
  questionDate: Date;
  questionAuthor: string;
  question: string;
  responseDate?: Date;
  response: string;
  responderId: string;
  responderName?: string;
  status: QuestionStatus;
  comments?: string;
  projectId: string;
}

export interface QuestionResponseResponse {
  questionResponses: QuestionResponseDto[];
  count: number;
}

export async function getQuestionResponsesByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<QuestionResponseResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export async function saveQuestionResponse(
  questionResponse: QuestionResponseDto
) {
  if (questionResponse.id) {
    const response = await api.put(apiEndpoint + '/put', questionResponse);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', questionResponse);
  return response;
}

export async function getQuestionResponse(id: string) {
  const { data } = await api.get<QuestionResponseDto>(
    apiEndpoint + '/GetById',
    {
      params: { id },
    }
  );

  return data;
}

export function deleteQuestionResponse(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
