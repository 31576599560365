import { PersonnelCostsUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Currency } from '@/enums/Currency';
import { PersonnelCategory } from '@/enums/PersonnelCategory';
import { PersonnelType } from '../enums/PersonnelType';

export interface PersonnelCost {
  function: string;
  countryId: number;
  category: PersonnelCategory;
  type: PersonnelType;
  hourlyRate: number;
  currency: Currency;
  companyId: string;
  id?: string;
}

export interface PersonnelCostsResponse {
  personnelCosts: PersonnelCost[];
  count: number;
}

const apiEndpoint = PersonnelCostsUrl;

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}

export async function getPersonnelCosts({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as PersonnelCostsResponse;
}

export async function getPersonnelCost(personnelCostId: string) {
  const { data } = await api.get<PersonnelCost>(apiEndpoint + '/GetById', {
    params: { id: personnelCostId },
  });

  return data;
}

export async function savePersonnelCost(personnelCost: PersonnelCost) {
  if (personnelCost.id) {
    const body = { ...personnelCost };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', personnelCost);
  return response_1;
}

export function deletePersonnelCost(personnelCostId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: personnelCostId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
