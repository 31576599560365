import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  GreenButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';

import { useTranslation } from 'react-i18next';
import { RootState, store } from '@/state';
import {
  deleteSubProjectOtherEquipment,
  saveSubProjectOtherEquipmentsAsync,
} from '@/state/slices/subProjectMethodSlice';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleIcon } from '@heroicons/react/solid';

import { formatCurrency } from '@/utils/helpers';
import { Currency } from '@/enums/Currency';
import {
  SubProjectOtherEquipment,
  SubProjectOtherEquipmentsPayload,
} from '@/services/SubProjectMethodsService';
import SubProjectOtherEquipmentModal from './SubProjectOtherEquipmentModal';

function SubProjectOtherEquipments() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEquipmentModal, setIsEquipmentModal] = useState(false);
  const [initEquipmentData, setInitEquipmentData] =
    useState<SubProjectOtherEquipment>();
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { data, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );
  let projectCurrency = data?.currency ?? Currency.EUR;
  let subProjectId = data?.id;
  let subProjectOtherEquipments = useMemo(
    () => data?.subProjectOtherEquipments ?? [],
    [data, status]
  );

  const ActionButtonsCell = ({ row, value }: any) => (
    <div>
      <DeleteButton
        onClick={() => {
          setSelectedRow(value);
          setIsModalVisible(true);
        }}
      >
        {t('SubProjectOtherEquipments.deleteButton')}
      </DeleteButton>
      <EditButton
        type="button"
        onClick={() => {
          setInitEquipmentData(row.original);
          setIsEquipmentModal(true);
        }}
      >
        {t('SubProjectOtherEquipments.editButton')}
      </EditButton>
    </div>
  );

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteSubProjectOtherEquipment.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('SubProjectOtherEquipments.equipmentIdColumn'),
        accessor: 'name',
        Footer: (infos) => <>Total</>,
      },
      {
        Header: t('SubProjectOtherEquipments.descriptionColumn'),
        accessor: 'description',
        Footer: () => <></>,
      },
      {
        Header: t('SubProjectOtherEquipments.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell value={value} row={row} />
        ),
        Footer: () => <></>,
      },
      {
        Header: t('SubProjectOtherEquipments.isLocatedColumn'),
        accessor: 'isLocated',
        Cell: ({ cell: { value } }: any) => (
          <span>
            {value
              ? t('SubProjectOtherEquipments.yes')
              : t('SubProjectOtherEquipments.no')}
          </span>
        ),
        Footer: () => <></>,
      },
      {
        Header: t('SubProjectOtherEquipments.trendColumn'),
        accessor: 'trend',
        Footer: () => <></>,
      },
      {
        Header: t('SubProjectOtherEquipments.quantityColumn'),
        accessor: 'quantity',
        Footer: (infos) => {
          const total = useMemo(
            () => infos.rows.reduce((sum, row) => row.values.quantity + sum, 0),
            [infos.rows]
          );
          return <>{total}</>;
        },
      },
      {
        Header: t('SubProjectOtherEquipments.unitPriceColumn'),
        accessor: 'unitPrice',
        Cell: ({ row, cell: { value } }: any) => {
          const currency = row.original['equipmentCurrency'];
          return <span> {formatCurrency(value, currency)} </span>;
        },
        Footer: () => <></>,
      },
      {
        Header: t('SubProjectOtherEquipments.totalPriceColumn'),
        accessor: 'totalPrice',
        Cell: ({ row, cell: { value } }: any) => {
          const currency = row.original['equipmentCurrency'];
          return <span> {formatCurrency(value, currency)} </span>;
        },
        Footer: (infos) => {
          const total = useMemo(
            () =>
              infos.rows.reduce(
                (sum, row) =>
                  parseFloat(row.values.totalPrice) + parseFloat(sum),
                0
              ),
            [infos.rows]
          );
          return <>{total.toFixed(4)}</>;
        },
      },
      {
        Header: t('SubProjectOtherEquipments.exchangeRateColumn'),
        accessor: 'exchangeRate',
        Footer: () => <></>,
      },
      {
        Header: t('SubProjectOtherEquipments.totalWithCurrencyColumn'),
        accessor: 'totalToCurrency',
        Cell: ({ cell: { value } }: any) => (
          <span> {formatCurrency(value, projectCurrency)} </span>
        ),
        Footer: (infos) => {
          const total = useMemo(
            () =>
              infos.rows.reduce(
                (sum, row) =>
                  parseFloat(row.values.totalToCurrency) + parseFloat(sum),
                0
              ),
            [infos.rows]
          );
          return <>{formatCurrency(total.toFixed(4), projectCurrency)}</>;
        },
      },
      {
        Header: t('SubProjectOtherEquipments.commentsColumn'),
        accessor: 'comment',
      },
    ],
    [t, projectCurrency]
  );

  const queryClient = useQueryClient();

  const handleDeleteSubProjectOtherEquipment = useMutation(
    async (id: string) => {
      await dispatch(deleteSubProjectOtherEquipment(id));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'subProjectOtherEquipments',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((subProjectOtherEquipments?.length ?? 0) / pageSize);
  }, [pageSize, subProjectOtherEquipments]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const handleSaveOtherEquipments = async () => {
    var payload: SubProjectOtherEquipmentsPayload = {
      subProjectOtherEquipments: subProjectOtherEquipments.map((sm) => ({
        ...sm,
        id: sm.id?.includes('new') ? null : sm.id,
      })),
      subProjectMethodId: data?.id!,
    };
    const result = await dispatch(saveSubProjectOtherEquipmentsAsync(payload));
  };

  return (
    <div>
      <PrimaryButton
        type="button"
        onClick={() => {
          setInitEquipmentData(undefined);
          setIsEquipmentModal(true);
        }}
        className="mt-5"
      >
        {t('SubProjectOtherEquipments.add')}
      </PrimaryButton>

      {subProjectId && (
        <GreenButton
          type="button"
          onClick={handleSaveOtherEquipments}
          className="mt-5"
        >
          {t('SubProjectOtherEquipments.save')}
        </GreenButton>
      )}
      {status === 'loading' ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={subProjectOtherEquipments?.length}
            data={subProjectOtherEquipments ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
            exportExcel={false}
            hideToolbar={true}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {isEquipmentModal && (
            <SubProjectOtherEquipmentModal
              closeModal={() => setIsEquipmentModal(false)}
              initialData={initEquipmentData}
            />
          )}
        </div>
      )}
      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        gotoPage={onPageChange}
        canNextPage={pageIndex < pageCount - 1}
        canPreviousPage={pageIndex > 0}
        nextPage={nextPage}
        previousPage={previousPage}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
}

export default SubProjectOtherEquipments;
