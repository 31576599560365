export enum ProfessionalStatus {
  WithoutLevel,
  Certificate,
  HighSchoolDiploma,
  BachelorPlus2,
  BachelorPlus3,
  BachelorPlus5,
  BachelorPlus4,
  BachelorPlus8AndAbove,
}

export const professionalStatusOptions = [
  { id: ProfessionalStatus.WithoutLevel, name: 'WithoutLevel' },
  { id: ProfessionalStatus.Certificate, name: 'Certificate' },
  { id: ProfessionalStatus.HighSchoolDiploma, name: 'HighSchoolDiploma' },
  { id: ProfessionalStatus.BachelorPlus2, name: 'BachelorPlus2' },
  { id: ProfessionalStatus.BachelorPlus3, name: 'BachelorPlus3' },
  { id: ProfessionalStatus.BachelorPlus4, name: 'BachelorPlus4' },
  { id: ProfessionalStatus.BachelorPlus5, name: 'BachelorPlus5' },
  {
    id: ProfessionalStatus.BachelorPlus8AndAbove,
    name: 'BachelorPlus8AndAbove',
  },
];
