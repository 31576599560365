import TextArea from '@/components/TextArea';
import { PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { InterviewState } from '@/enums/InterviewState';
import { UserRoles } from '@/enums/UsersRole';
import { CommentsAndSignedModel } from '@/models/AnnualInterviewModel';
import { RootState } from '@/state';
import {
  updateCommentsAndSigned,
  saveAnnualInterviewAsync,
} from '@/state/slices/annualInterviewSlice';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const init: CommentsAndSignedModel = {
  signedByResponsable: false,
  signedByEmployee: false,
  employeeComments: '',
  responsableComments: '',
};

function SignedAndComments() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { user } = useAuth();
  // const isTeamLeader = user?.isTeamLeader == 'True';

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const employeeComments = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.employeeComments
  );
  const employeefullName = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.employeeFullName
  );
  const responsibleFullName = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.responsibleFullName
  );
  const responsableComments = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.responsableComments
  );
  const signedByEmployee = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.signedByEmployee
  );
  const signedByResponsable = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.signedByResponsable
  );
  const disabled = annualInerview.interviewState == InterviewState.Completed;
  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const formik = useFormik({
    initialValues: {
      ...init,
      employeeComments: employeeComments,
      responsableComments: responsableComments,
      signedByEmployee: signedByEmployee,
      signedByResponsable: signedByResponsable,
    },
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      dispatch(
        updateCommentsAndSigned({
          commentsAndSigned: {
            employeeComments: values.employeeComments,
            responsableComments: values.responsableComments,
            signedByEmployee: values.signedByEmployee,
            signedByResponsable: values.signedByResponsable,
          },
        })
      );
      setIsSubmiting(true);
    },
  });

  useEffect(() => {
    if (annualInerview && isSubmiting) {
      dispatch(saveAnnualInterviewAsync(annualInerview));
      setIsSubmiting(false);
    }
  }, [isSubmiting, annualInerview]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex justify-between gap-4 px-10 py-5">
        <div className="group relative mb-6 w-full">
          <TextArea
            id="employeeComments"
            name="employeeComments"
            label={t('annualInterviewForm.employeeComments')}
            value={formik.values.employeeComments ?? ''}
            onChange={formik.handleChange}
            disabled={isTeamLeader || disabled}
          />
        </div>
        <div className="group relative mb-6 w-full">
          <TextArea
            id="responsableComments"
            name="responsableComments"
            label={t('annualInterviewForm.responsableComments')}
            value={formik.values.responsableComments ?? ''}
            onChange={formik.handleChange}
            disabled={!isTeamLeader || disabled}
          />
        </div>
      </div>
      <div className="px-10 py-5">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className=" px-6 py-3">
                {t('annualInterviewForm.SignedByEmployee') +
                  ' : ' +
                  employeefullName}
              </th>
              <th scope="col" className=" px-6 py-3">
                {t('annualInterviewForm.SignedByResponsable') +
                  ' : ' +
                  responsibleFullName}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
              <td className="px-6 py-4 pl-10">
                <input
                  id="signedByEmployee"
                  name="signedByEmployee"
                  type="checkbox"
                  checked={formik.values.signedByEmployee ?? false}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const newValue = e.target.checked;
                    formik.setFieldValue('signedByEmployee', newValue);
                  }}
                  disabled={isTeamLeader || disabled}
                />
              </td>
              <td className="px-6 py-4  pl-10">
                <input
                  id="signedByResponsable"
                  name="signedByResponsable"
                  type="checkbox"
                  checked={formik.values.signedByResponsable ?? false}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const newValue = e.target.checked;
                    formik.setFieldValue('signedByResponsable', newValue);
                  }}
                  disabled={!isTeamLeader || disabled}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-end px-10 py-5">
        {/* <PrimaryButton
            className="border-0 bg-purple-300"
            type="button"
            onClick={() => {}}
          >
            <div className="flex items-center gap-2">
              <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
              {t('annualInterviewForm.Mobility.Sync')}
            </div>
          </PrimaryButton> */}
        {annualInerview.interviewState === InterviewState.InProgress && (
          <PrimaryButton
            type="submit"
            className="border-0 bg-primary-300 dark:bg-primary-500"
          >
            {t('annualInterviewForm.Mobility.submit')}
          </PrimaryButton>
        )}
      </div>
    </form>
  );
}

export default SignedAndComments;
