export enum QuestionStatus {
  Pending,
  Answered,
  Closed,
}

export const QuestionStatusOptions = Object.keys(QuestionStatus)
  .filter((key) => isNaN(Number(QuestionStatus[key])))
  .map((key) => ({
    id: key,
    name: QuestionStatus[key],
  }));
