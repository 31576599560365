import { useMemo, useState } from 'react';
import DefaultInput from '@/components/Input';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import {
  changeTrainingRequestState,
  ChangeTrainingRequestStateProps,
  getTrainingRequest,
  TrainingRequest,
} from '@/services/TrainingRequestService';
import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';
import { useAuth } from '@/context/AuthContext';
import { TrainingRequestState } from '@/enums/TrainingRequestState';
import { UserRoles } from '@/enums/UsersRole';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultLayout from '@/layout/DefaultLayout';
import { stateColors } from '@/enums/RequestLeaveState';
import { UserResponsibility } from '@/enums/UserResponsibility';

const TrainingRequestDetails = () => {
  const { id } = useParams();
  const [trainingDetails, setTrainingDetails] = useState<TrainingRequest>();
  const { t } = useTranslation();
  const readonly = true;
  const { user } = useAuth();
  const navigate = useNavigate();
  const isTrainingRequestAdmin =
    user?.responsibilityClaim ===
    UserResponsibility[UserResponsibility.GestionDesFormations].toString();

  const {
    data: trainingData,
    isLoading,
    refetch,
  } = useQuery<TrainingRequest>(
    ['training', id],
    async () => await getTrainingRequest(id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
    }
  );
  const isTeamLeader = useMemo(
    () => user?.id === trainingData?.teamLeaderId,
    [user, trainingData]
  );
  if (isLoading || trainingData === undefined) {
    return <Spinner />;
  }

  const handleRejectAppointment = async (
    values: TrainingRequest,
    adminId: string
  ) => {
    const tRState: ChangeTrainingRequestStateProps = {
      id: values.id!,
      trainingRequestState: TrainingRequestState.Rejected,
      comment: '',
      adminId: adminId,
    };

    const data = await changeTrainingRequestState(tRState);
    if (data) {
      refetch();
    }
  };
  const handleAccepteAppointment = async (
    values: TrainingRequest,
    adminId: string
  ) => {
    const tRState: ChangeTrainingRequestStateProps = {
      id: values.id!,
      trainingRequestState: TrainingRequestState.Approved,
      comment: '',
      adminId: adminId,
    };

    const data = await changeTrainingRequestState(tRState);
    if (data) {
      refetch();
    }
  };

  const formButtons = () => {
    if (
      trainingData.approvedByTeamLeader != TrainingRequestState.Pending &&
      trainingData.approvedByTrainingAdmin != TrainingRequestState.Pending
    ) {
      return (
        <>
          <button
            type="button"
            id="cancelForm"
            className="focus:shadow-outline ml-3 rounded-sm bg-gray-400 px-3 py-1 text-white hover:bg-blue-500 hover:text-white focus:outline-none dark:text-boxdark-2"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('appointmentsForm.Cancel')}
          </button>
        </>
      );
    }

    if (isTeamLeader) {
      return trainingData.approvedByTeamLeader !=
        TrainingRequestState.Pending ? (
        <>
          <button
            type="button"
            id="cancelForm"
            className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('appointmentsForm.Cancel')}
          </button>
        </>
      ) : (
        <div className=" flex items-end justify-end">
          <button
            type="button"
            id="cancelForm"
            className="mr-2 inline-flex items-center rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
            onClick={() =>
              handleRejectAppointment(trainingData, trainingData.teamLeaderId!)
            }
          >
            {t('TrainingRequest.Reject')}
          </button>
          <button
            type="submit"
            className="mr-2 inline-flex items-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
            disabled={isLoading}
            onClick={() =>
              handleAccepteAppointment(trainingData, trainingData.teamLeaderId!)
            }
          >
            {t('TrainingRequest.Approve')}
          </button>
        </div>
      );
    }

    if (isTrainingRequestAdmin) {
      return trainingData.approvedByTrainingAdmin !=
        TrainingRequestState.Pending ? (
        <>
          <button
            type="button"
            id="cancelForm"
            className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('appointmentsForm.Cancel')}
          </button>
        </>
      ) : (
        <div className=" flex items-end justify-end">
          <button
            type="button"
            id="cancelForm"
            className="mr-2 inline-flex items-center rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
            onClick={() =>
              handleRejectAppointment(
                trainingData,
                trainingData.trainingAdminId!
              )
            }
          >
            {t('TrainingRequest.Reject')}
          </button>
          <button
            type="submit"
            className="mr-2 inline-flex items-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
            disabled={isLoading}
            onClick={() =>
              handleAccepteAppointment(
                trainingData,
                trainingData.trainingAdminId!
              )
            }
          >
            {t('TrainingRequest.Approve')}
          </button>
        </div>
      );
    }

    return (
      <button
        type="button"
        id="cancelForm"
        className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
        onClick={() => {
          navigate(-1);
        }}
      >
        {t('appointmentsForm.Cancel')}
      </button>
    );
  };

  const getColorizedState = (trainingData: TrainingRequest) => {
    const frontendStateTeamLeader =
      TrainingRequestState[trainingData.approvedByTeamLeader!];
    const frontendStateTrainingAdmin =
      TrainingRequestState[trainingData.approvedByTrainingAdmin!];

    const bgColorTL = stateColors[frontendStateTeamLeader] || 'bg-gray-300';
    const bgColorTA = stateColors[frontendStateTrainingAdmin] || 'bg-gray-300';

    return (
      <div className="flex gap-2">
        <div
          className={`rounded-md text-center ${bgColorTL} px-2.5  py-2.5 text-sm text-white`}
        >
          {frontendStateTeamLeader !== null
            ? `${t('TrainingRequest.TeamLeaderState')} ${t(
                `RequestLeaveState.${frontendStateTeamLeader}`
              )}`
            : ''}
        </div>
        <div
          className={`rounded-md text-center ${bgColorTA} px-2.5  py-2.5 text-sm text-white`}
        >
          {frontendStateTrainingAdmin !== null
            ? `${t('TrainingRequest.TrainingAdminState')} ${t(
                `RequestLeaveState.${frontendStateTrainingAdmin}`
              )}`
            : ''}
        </div>
      </div>
    );
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('TrainingRequest.TrainingRequestDetails')} />

      <div className="group relative z-0 mb-6  ">
        {getColorizedState(trainingData)}
      </div>
      <div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="employeeFullName"
              labelClassName="w-[50%]"
              id="employeeFullName"
              value={trainingData!.employeeFullName}
              label={t('Requestleave.EmployeeFullName')}
              disabled={readonly}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="teamLeaderFullName"
              id="teamLeaderFullName"
              value={trainingData!.teamLeaderFullName}
              label={t('Employees.TeamLeaderName')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="requestedTrainingTitle"
              labelClassName="w-[50%]"
              id="requestedTrainingTitle"
              value={trainingData!.requestedTrainingTitle}
              label={t('TrainingRequest.requestedTrainingTitle')}
              disabled={readonly}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="trainingProviderName"
              id="trainingProviderName"
              value={trainingData!.trainingProviderName}
              label={t('TrainingRequest.trainingProviderName')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="email"
              name="trainingProviderEmail"
              id="trainingProviderEmail"
              value={trainingData!.trainingProviderEmail}
              label={t('TrainingRequest.trainingProviderEmail')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="trainingProviderPhoneNumber"
              id="trainingProviderPhoneNumber"
              value={trainingData!.trainingProviderPhoneNumber}
              label={t('TrainingRequest.trainingProviderPhoneNumber')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="trainingProviderAddress"
              id="trainingProviderAddress"
              value={trainingData!.trainingProviderAddress}
              label={t('TrainingRequest.trainingProviderAddress')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="trainingProviderWebsite"
              id="trainingProviderWebsite"
              value={trainingData!.trainingProviderWebsite}
              label={t('TrainingRequest.trainingProviderWebsite')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="trainingManagerName"
              id="trainingManagerName"
              value={trainingData!.trainingManagerName}
              label={t('TrainingRequest.trainingManagerName')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <TextArea
              id="trainingObjectives"
              name="trainingObjectives"
              label={t('TrainingRequest.trainingObjectives')}
              value={trainingData!.trainingObjectives}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative mb-6 w-full">
            <DefaultInput
              id="trainingStartDate"
              name="trainingStartDate"
              value={new Date(
                trainingData!.trainingStartDate
              ).toLocaleDateString()}
              label={t('TrainingRequest.trainingManagerName')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
          <div className="group relative  mb-6 w-full">
            <DefaultInput
              id="trainingStartDate"
              name="trainingStartDate"
              value={new Date(
                trainingData!.trainingEndDate
              ).toLocaleDateString()}
              label={t('TrainingRequest.trainingManagerName')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="number"
              name="totalTrainingHours"
              id="totalTrainingHours"
              value={trainingData!.totalTrainingHours}
              label={t('TrainingRequest.totalTrainingHours')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="number"
              name="trainingCost"
              id="trainingCost"
              value={trainingData!.trainingCost}
              label={t('TrainingRequest.trainingCost')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="trainingLevel"
              id="trainingLevel"
              value={trainingData!.trainingLevel}
              label={t('TrainingRequest.trainingLevel')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <DefaultInput
              type="text"
              name="obtainedDiplomaTitle"
              id="obtainedDiplomaTitle"
              value={trainingData!.obtainedDiplomaTitle}
              label={t('TrainingRequest.obtainedDiplomaTitle')}
              disabled={readonly}
              labelClassName="w-[50%]"
            />
          </div>
        </div>
        <div className="grid md:grid-cols-4 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="diplomaIssued"
              name="diplomaIssued"
              checked={trainingData!.diplomaIssued}
              label={t('TrainingRequest.diplomaIssued')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="cpfFinanced"
              name="cpfFinanced"
              checked={trainingData!.cpfFinanced}
              label={t('TrainingRequest.cpfFinanced')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="internalTraining"
              name="internalTraining"
              checked={trainingData!.internalTraining}
              label={t('TrainingRequest.internalTraining')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="externalTraining"
              name="externalTraining"
              checked={trainingData!.externalTraining}
              label={t('TrainingRequest.externalTraining')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-4 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="employeeRequestedTraining"
              name="employeeRequestedTraining"
              checked={trainingData!.employeeRequestedTraining}
              label={t('TrainingRequest.employeeRequestedTraining')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="managerRequestedTraining"
              name="managerRequestedTraining"
              checked={trainingData!.managerRequestedTraining}
              label={t('TrainingRequest.managerRequestedTraining')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <Checkbox
              id="workApplicationTraining"
              name="workApplicationTraining"
              checked={trainingData!.workApplicationTraining}
              label={t('TrainingRequest.workApplicationTraining')}
              disabled={readonly}
              labelClassName="w-[50%]"
              onChange={() => {}}
            />
          </div>
        </div>

        <div className="group relative z-0 mb-6 w-full">
          <TextArea
            id="remarks"
            name="remarks"
            label={t('TrainingRequest.remarks')}
            value={trainingData!.remarks}
            disabled={readonly}
            labelClassName="w-[50%]"
          />
        </div>
        {formButtons()}
      </div>
    </DefaultLayout>
  );
};

export default TrainingRequestDetails;
