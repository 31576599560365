import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from './shared/Button';
import { UploadFilesUrl } from '@/variables/Urls';
import api from '@/utils/api';
import Spinner from './Spinner';
import { EyeIcon, UploadIcon } from '@heroicons/react/solid';

interface FileUploaderProps {
  onChange?: (fileUrl: string) => void;
  folderPathName: string[];
  id: string;
  name: string;
  fileUrl: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onChange,
  folderPathName,
  id,
  name,
  fileUrl,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fileUrl) {
      setUploaded(true);
    }
  }, [fileUrl]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      setFile(fileList[0]);
      setUploaded(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileList = Array.from(e.dataTransfer.files);
    if (fileList.length > 0) {
      setFile(fileList[0]);
      setUploaded(false);
    }
  };

  const handleUploadFile = async () => {
    if (!file) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folderPathName', folderPathName.join('//'));

      const response = await api.post(UploadFilesUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 200) {
        const fullUrl = response.data;
        setUploaded(true);
        if (onChange) {
          onChange(fullUrl);
        }
      }
    } catch (error) {
      console.error('Error handling file upload:', error);
    }

    setLoading(false);
  };

  const handlePreviewFile = () => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <div className="mt-4 w-full">
      <div
        className="cursor-pointer rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:bg-gray-100"
        onClick={() => fileInputRef.current?.click()}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <UploadIcon className="mx-auto h-12 w-12 text-gray-500" />
        <p className="mt-2 text-gray-600">
          {file
            ? `${t('FileUpload.chosenFile')}: ${file.name}`
            : fileUrl
            ? t('FileUpload.fileAlreadyUploaded')
            : t('FileUpload.clickToUpload')}
        </p>
        <input
          id={id}
          name={name}
          ref={fileInputRef}
          type="file"
          disabled={loading}
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-4 flex flex-col items-center">
          {fileUrl && (
            <button
              type="button"
              onClick={handlePreviewFile}
              className="mb-2 flex items-center text-blue-500 hover:text-blue-700"
            >
              <EyeIcon className="mr-1 h-6 w-6" />
              {t('FileUpload.previewFile')}
            </button>
          )}
          <PrimaryButton
            type="button"
            id="uploadFile"
            onClick={handleUploadFile}
            className="mt-5 bg-blue-300"
            disabled={!file}
          >
            {t('FileUpload.uploadFile')}
          </PrimaryButton>
          {uploaded && !file && (
            <p className="mt-2 text-green-500">
              {t('FileUpload.fileUploaded')}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUploader;
