import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DefaultInput from '@/components/Input';
import Spinner from '@/components/Spinner';
import ImageUploader from '@/components/ImagePicker';
import CheckboxGroup from '@/components/CheckboxGroup';
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import {
  CountriesResponse,
  Country,
  State,
  getCountries,
} from '@/services/CountryService';
import { useQuery } from '@tanstack/react-query';
import { Employee } from '@/services/EmployeeService';
import {
  Departement,
  DepartementResponse,
  getDepartement,
  getDepartementsByBranchId,
  getDepartementsByCompanyId,
} from '@/services/DepartementService';
import { Branch, getBranches } from '@/services/BranchService';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeeValue } from '@/state/slices/employeeSlice';
import { MultiStepFormProps } from '../EmployeeForm';
import { RootState } from '@/state';
import { useParams, useNavigate } from 'react-router-dom';
import {
  checkEmailAvailability,
  checkIDNumberAvailability,
} from '@/services/UserService';
import { useDebounce } from 'use-debounce';
import { isValidEmail } from '@/utils/helpers';
import {
  WorkCalendar,
  getWorkCalendars,
} from '@/services/WorkCalendarsService';
import { getTeam, getTeamByTeamLeaderId } from '@/services/TeamService';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { GenderEnum, genderOptions } from '@/enums/genderEnum';
import {
  MaritalStatusEnum,
  maritalStatusOptions,
} from '@/enums/maritialStatusEnum';
import { SeniorityRange, seniorityRangeOptions } from '@/enums/SeniorityRange';

interface FormValues extends Employee {
  country: Country;
  state: State;
}

function GeneralEmployeeInformation({
  currentStep,
  setCurrentStep,
  stepsLength,
}: MultiStepFormProps) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const [states, setStates] = React.useState<State[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [departements, setDepartements] = React.useState<Departement[]>([]);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [workCalendars, setWorkCalendars] = React.useState<WorkCalendar[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const employeeId = id !== 'new';
  const [email, setEmail] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Employee.invalidEmail')
      .required('Employee.emailRequired'),
    firstName: Yup.string().required('Employee.firstNameRequired'),
    lastName: Yup.string().required('Employee.lastNameRequired'),
    idNumber: Yup.string().required('Employee.idNumberRequired'),
    branchId: Yup.string().nullable().required('Employee.branchIdRequired'),
    jobTitle: Yup.string().required('Employee.jobTitleRequired'),
    departmentId: Yup.string().required('Employee.departmentIdRequired'),
    stateId: Yup.string().required('Employee.stateRequired'),
    phoneNumber1: Yup.string().test(
      'phone-format',
      t('Employee.invalidPhoneNumberFormat'),
      (value) => {
        if (!value || value.trim() === '') {
          return true; // Allow empty values
        }
        return /^\+[0-9]+$/.test(value);
      }
    ),

    phoneNumber2: Yup.string().test(
      'phone-format',
      t('Employee.invalidPhoneNumberFormat'),
      (value) => {
        if (!value || value.trim() === '') {
          return true; // Allow empty values
        }
        return /^\+[0-9]+$/.test(value);
      }
    ),
    // Add other validations as needed
  });
  const navigate = useNavigate(); // Initialize useHistory

  const {
    employee,
    contract,
    user,
    loading: loadingRedux,
  } = useSelector((state: RootState) => state.employees);

  const [initialValues, setInitialValues] = useState<FormValues>({
    email: employee.email,
    firstName: employee.firstName,
    lastName: employee.lastName,
    gender: employee.gender,
    maritalStatus: employee.maritalStatus,
    jobTitle: employee.jobTitle,
    grade: employee.grade,
    idNumber: employee.idNumber,
    stateId: employee.stateId,
    birthCountry: employee.birthCountry,
    nationality: employee.nationality,
    childNumber: employee.childNumber?.toString() ?? '',
    profileImgUrl: employee.profileImgUrl,
    departmentId: employee.departmentId,
    workCalendarId: employee.workCalendarId,
    companyId: employee.companyId,
    countryId: employee.countryId,
    birthDate: employee.birthDate
      ? new Date(employee.birthDate).toISOString() // Convert to ISO 8601 string
      : '',
    branchId: employee.branchId,
    address: employee.address,
    phoneNumber1: employee.phoneNumber1,
    phoneNumber2: employee.phoneNumber2,
    teamId: employee.teamId,
    country: {} as Country,
    state: {} as State,
    seniorityRange: employee.seniorityRange,
  });
  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      setCountries(data?.countries!);
    },
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });

  const { data: branchesData, isLoading: branchesLoading } = useQuery(
    ['branches'],
    () => getBranches({ fromvalue: 0, takevalue: 0 }),
    {
      onSuccess: (data) => {
        setBranches(data!.branches);

        return data?.branches;
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  const { data: workCalendarsData, isLoading: workCalendarsLoading } = useQuery(
    ['workCalendars'],
    () => getWorkCalendars({ fromvalue: 0, takevalue: 0 }),
    {
      onSuccess: (data) => {
        setWorkCalendars(data!);

        return data;
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  const {
    data: teamData,
    isLoading: teamDataLoading,
    isSuccess: isTeamSuccess,
  } = useQuery(
    ['teams' + currentUser?.id],
    () => getTeamByTeamLeaderId(currentUser?.id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: currentUser?.id !== null && currentUser?.isTeamLeader == 'True',
    }
  );

  useEffect(() => {
    if (isTeamSuccess && teamData && currentUser?.isTeamLeader == 'True') {
      setInitialValues({
        ...initialValues,
        teamId: teamData.id!,
      });
    }
  }, [isTeamSuccess, teamData, currentUser]);

  useEffect(() => {
    if (employeeId && employee) {
      const formattedBirthDate = employee.birthDate
        ? new Date(employee.birthDate).toISOString() // Convert to ISO 8601 string
        : '';

      setInitialValues({
        ...initialValues,
        email: employee.email,
        firstName: employee.firstName,
        lastName: employee.lastName,
        gender: employee.gender,
        maritalStatus: employee.maritalStatus,
        jobTitle: employee.jobTitle,
        grade: employee.grade,
        idNumber: employee.idNumber,
        stateId: employee.stateId,
        birthCountry: employee.birthCountry,
        nationality: employee.nationality,
        childNumber: employee.childNumber?.toString() ?? '',
        profileImgUrl: employee.profileImgUrl,
        departmentId: employee.departmentId,
        workCalendarId: employee.workCalendarId,
        companyId: employee.companyId,
        countryId: employee.countryId,
        birthDate: formattedBirthDate,
        branchId: employee.branchId,
        address: employee.address,
        phoneNumber1: employee.phoneNumber1,
        phoneNumber2: employee.phoneNumber2,
        teamId: employee.teamId,
        experience: employee.experience,
      });

      if (!branchesLoading && !countryLoading) {
        setDepartements(
          (prev) =>
            (prev = branchesData?.branches.find(
              (x) => x.id === employee.branchId
            )?.departments! as Departement[])
        );

        setStates(countries.find((x) => x.id === employee.countryId!)?.states!);
      }
    }
  }, [
    employee,
    employeeId,
    loadingRedux,
    branchesData,
    branchesLoading,
    countryLoading,
    countries,
  ]);

  const handleMoveNext = (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    dispatch(updateEmployeeValue({ employeeForm: values }));
    setCurrentStep((prevStep) => prevStep + 1);
    resetForm();
  };

  const handleCountryChange = (event: any, handleChange: any) => {
    const selectedCountryName = event.target.value;

    const selectedCountryObj = data?.countries?.find(
      (country) => country.id === selectedCountryName
    );

    if (selectedCountryObj !== null && selectedCountryObj !== undefined) {
      setStates(selectedCountryObj.states!);
    } else {
      setStates([]);
    }
    handleChange({
      target: {
        name: 'countryId',
        value: selectedCountryObj!.id || {},
      },
    });
  };

  const handleBranchChange = (event: any, handleChange: any) => {
    const selectedBranchId = event.target.value;
    const selectedBranchObj = branchesData?.branches.find(
      (branch) => branch.id === selectedBranchId
    );

    if (selectedBranchObj) {
      setDepartements(selectedBranchObj.departments! as Departement[]);
    } else {
      setDepartements([]);
    }

    handleChange({
      target: {
        name: 'branchId',
        value: selectedBranchId || {},
      },
    });
  };
  const { isLoading: checkingEmail, data: emailExists } = useQuery(
    ['checkEmailAvailability', email],
    () => checkEmailAvailability(email), // Replace with the actual API call to check email availability
    {
      enabled: !!email && isValidEmail(email) && !employeeId,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true, // Enable the query only when debouncedEmail has a value
    }
  );
  const handleChangeEmail = (e: any, setFieldValue: any) => {
    setFieldValue('email', e.target.value);
    setEmail(e.target.value);
  };

  const { isLoading: checkingIDNB, data: idNBExists } = useQuery(
    ['checkIDNumberAvailability', idNumber],
    () => checkIDNumberAvailability(idNumber), // Replace with the actual API call to check email availability
    {
      enabled: !!idNumber && idNumber !== '' && !employeeId,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true, // Enable the query only when debouncedEmail has a value
    }
  );
  const handleChangeIDNumber = (e: any, setFieldValue: any) => {
    setFieldValue('idNumber', e.target.value);
    setIdNumber(e.target.value);
  };

  return (
    <div className="m-4 pt-5">
      {countryLoading || branchesLoading || loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleMoveNext} // Replace handleMoveNext with your submit function
          enableReinitialize={employeeId}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            resetForm,
            validateForm,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {/* Input fields */}
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.firstName}
                    label={t('Employee.firstName')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.lastName}
                    label={t('Employee.lastName')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="idNumber"
                    id="idNumber"
                    placeholder=" "
                    labelClassName="w-[40%]"
                    onChange={(e) => handleChangeIDNumber(e, setFieldValue)}
                    errors={errors}
                    value={values.idNumber}
                    label={t('Employee.idNumber')}
                  />
                  {checkingIDNB && values.idNumber && !employeeId && (
                    <Spinner />
                  )}
                  {/* Display a loading spinner while checking */}
                  {idNBExists && (
                    <span className="text-red-500">
                      {t('validationMessages.idNumberAlreadyExists')}
                    </span>
                  )}
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder=" "
                    onChange={(e) => handleChangeEmail(e, setFieldValue)}
                    errors={errors}
                    value={values.email}
                    label={t('Employee.email')}
                  />
                  {checkingEmail &&
                    isValidEmail(values.email) &&
                    !employeeId && <Spinner />}
                  {/* Display a loading spinner while checking */}
                  {emailExists && (
                    <span className="text-red-500">
                      {t('validationMessages.emailAlreadyExists')}
                    </span>
                  )}
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="branchId"
                    name="branchId"
                    optionValue="id"
                    optionLabel="title"
                    onChange={(e) => handleBranchChange(e, handleChange)}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={branchesData?.branches ?? []}
                    label={t('Employee.branches')}
                    labelClassName="w-[40%] pl-0"
                    error={errors.branchId}
                    value={values.branchId}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="departmentId"
                    name="departmentId"
                    optionValue="id"
                    optionLabel="title"
                    labelClassName="w-[40%] pl-0"
                    onChange={(e) => {
                      setFieldValue('departmentId', e.target.value);
                    }}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={departements ?? []}
                    label={t('Employee.department')}
                    error={errors.departmentId}
                    value={values.departmentId}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.jobTitle}
                    label={t('Employee.jobTitle')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="workCalendarId"
                    name="workCalendarId"
                    optionValue="id"
                    optionLabel="name"
                    onChange={(e) => {
                      setFieldValue('workCalendarId', e.target.value);
                    }}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={workCalendars ?? []}
                    labelClassName="w-[40%] pl-0"
                    label={t('Employee.workCalendars')}
                    error={errors.workCalendarId}
                    value={values.workCalendarId}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="countryId"
                    name="countryId"
                    value={values.countryId}
                    optionValue="id"
                    optionLabel="name"
                    labelClassName="w-[40%] pl-0"
                    onChange={(e) => handleCountryChange(e, handleChange)}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={countries ?? []}
                    label={t('Employee.selectCountry')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="stateId"
                    name="stateId"
                    optionValue="id"
                    labelClassName="w-[40%] pl-0"
                    optionLabel="name"
                    onChange={(e) => {
                      setFieldValue('stateId', e.target.value);
                    }}
                    options={states ?? []}
                    label={t('Employee.selectState')}
                    error={errors.stateId}
                    value={values.stateId}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-3 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="address.street"
                    id="address.street"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.address?.street ?? ''}
                    label={t('Employee.address.street')}
                  />
                </div>

                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="address.postalCode"
                    id="address.postalCode"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.address?.postalCode ?? ''}
                    label={t('Employee.address.postalCode')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="address.zipCode"
                    id="address.zipCode"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.address?.zipCode ?? ''}
                    label={t('Employee.address.zipCode')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="phoneNumber1"
                    id="phoneNumber1"
                    placeholder=" "
                    onChange={handleChange}
                    labelClassName="w-[40%]"
                    errors={errors}
                    value={values.phoneNumber1 ?? ''}
                    label={t('Employee.phoneNumber')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="phoneNumber2"
                    id="phoneNumber2"
                    placeholder=" "
                    onChange={handleChange}
                    labelClassName="w-[45%]"
                    errors={errors}
                    value={values.phoneNumber2 ?? ''}
                    label={t('Employee.mobilePhone')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="maritalStatus"
                    name="maritalStatus"
                    optionValue="id"
                    value={values.maritalStatus ?? MaritalStatusEnum.NoAnswer}
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={maritalStatusOptions.map((g) => {
                      return {
                        ...g,
                        name: t(
                          `MaritalStatusEnum.${MaritalStatusEnum[g.id ?? 4]}`
                        ),
                      };
                    })}
                    label={t('Employee.maritalStatus')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="gender"
                    name="gender"
                    value={values.gender ?? GenderEnum.NoAnswer}
                    optionValue="id"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={genderOptions.map((g) => {
                      return {
                        ...g,
                        name: t(`GenderEnum.${GenderEnum[g.id ?? 2]}`),
                      };
                    })}
                    label={t('Employee.gender')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="childNumber"
                    id="childNumber"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.childNumber}
                    label={t('Employee.childNumber')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="nationality"
                    name="nationality"
                    value={values.nationality}
                    optionValue="id"
                    optionLabel="name"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={countries ?? []}
                    label={t('Employee.nationality')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="experience"
                    name="experience"
                    labelClassName="w-[50%]"
                    value={values.experience}
                    optionValue="id"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={seniorityRangeOptions.map((g) => {
                      return {
                        ...g,
                        name: t(`SenoritySlice.${SeniorityRange[g.id ?? 2]}`),
                      };
                    })}
                    label={t('Employee.experience')}
                  />
                </div>
              </div>
              {/* Other input fields go here */}

              {/* <div className="relative z-20 col-span-2 grid w-[90%] px-5 py-2">
                <ImageUploader
                  name="profileImgUrl"
                  handleChange={handleChange}
                  id="profileImgUrl"
                  multiple={false}
                  value={values.profileImgUrl}
                />
              </div> */}

              {/* Buttons */}
              {/* <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('Employee.submit')}
              </button> */}

              <div className="relative z-20 col-span-2 grid w-[90%] py-2 md:px-5">
                <ImageUploader
                  name="profileImgUrl"
                  handleChange={handleChange}
                  id="profileImgUrl"
                  multiple={false}
                  value={values.profileImgUrl!}
                />
              </div>

              {currentStep < stepsLength && (
                <button
                  disabled={loading}
                  type="submit"
                  className="float-right mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
                >
                  {t('stepper.next')}
                </button>
              )}

              <button
                type="button"
                onClick={() => navigate(-1)}
                className=" mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
              >
                {t('stepper.back')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default GeneralEmployeeInformation;
