// Import necessary libraries
import React from 'react';
import { Bar } from 'react-chartjs-2';

// Sample data for the stacked bar chart
const data1 = {
  labels: ['Category 1', 'Category 2', 'Category 3'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 20, 30],
      backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red
    },
    {
      label: 'Dataset 2',
      data: [15, 25, 35],
      backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue
    },
    {
      label: 'Dataset 3',
      data: [20, 30, 40],
      backgroundColor: 'rgba(75, 192, 192, 0.7)', // Green
    },
  ],
};

// Options for the chart
const options = {
  scales: {
    x: {
      grouped: true, // Group bars side by side
    },
    y: {
      beginAtZero: true,
      // ticks: {
      //   stepSize: 100,
      // },
    },
  },
};

// StackedBarChart component
const StackedBarChart = ({ data, title }: any) => {
  return (
    <div className="flex h-[100%] w-[100%] items-center justify-center">
      <h2>{title}</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default StackedBarChart;
