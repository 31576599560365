export const mapBackendStateToFrontendState = (backendState) => {
  switch (backendState) {
    case 0:
      return RequestLeaveState.Pending;
    case 1:
      return RequestLeaveState.Canceled;
    case 2:
      return RequestLeaveState.Approved;
    case 3:
      return RequestLeaveState.Rejected;
    default:
      return '';
  }
};

export enum RequestLeaveState {
  Draft,
  Pending,
  Canceled,
  Approved,
  Rejected,
}

export const stateColors = {
  Pending: 'bg-yellow-500',
  Canceled: 'bg-purple-400',
  Approved: 'bg-green-400',
  Processed: 'bg-green-400',
  Rejected: 'bg-red-400',
  Scheduled: 'bg-cyan-400',
  InProgress: 'bg-violet-400',
  Completed: 'bg-green-400',
  Planned: 'bg-yellow-500',
  Cancelled: 'bg-purple-400',
  Paid: 'bg-green-400',
  PartiallyPaid: 'bg-cyan-400',
  Received: 'bg-cyan-400',
  InPreparation: 'bg-violet-400',
};
