import { VacationBalanceTransferRequestUrl } from '@/variables/Urls'; // Replace with actual URL
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { VacationBalance } from './VacationBalanceService';
import { RequestLeaveStateChange } from './RequestLeavesService';
import { RequestLeaveState } from '@/enums/RequestLeaveState';

export interface TransferRequestLeaveStateChange {
  id: string; // Assuming Guid maps to a string in TypeScript
  state: RequestLeaveState;
  comment?: string;
}
export interface VacationBalanceTransferRequest {
  id?: string;
  fromVacationBalanceId?: string;
  toVacationBalanceId?: string;
  days: number;
  state?: TransferRequestState;
  isDraft?: boolean;
  decisionDate?: string;
  comment?: string;
  fromVacationBalance?: VacationBalance;
  toVacationBalance?: VacationBalance;
  fromVacationName?: string;
  toVacationName?: string;
  employeeFullName?: string;
}

export interface TransferRequestsResponse {
  transferRequests: VacationBalanceTransferRequest[];
  count: number;
}

export enum TransferRequestState {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

const apiEndpoint = VacationBalanceTransferRequestUrl; // Replace with actual URL

export interface PaginatingParams {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
  getValid?: boolean;
  employeeId?: string;
}

export async function getTransferRequests({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        // Add any other necessary params
      },
    };
    const { data } = await api.get(apiEndpoint + '/Get', config);
    return data as TransferRequestsResponse;
  } catch (ex: any) {
    console.log('Error:', ex);
    throw ex;
  }
}
export async function getTransferRequestsByEmployeeId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  getValid,
  employeeId,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId,
        getValid,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByEmployeeId', config);
    return data as TransferRequestsResponse;
  } catch (ex: any) {
    console.log('Error:', ex);
    throw ex;
  }
}

export async function getTransferRequest(transferRequestId: string) {
  try {
    const { data } = await api.get<VacationBalanceTransferRequest>(
      apiEndpoint + '/Get',
      {
        params: { id: transferRequestId },
      }
    );
    return data;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}

export async function saveTransferRequest(
  transferRequest: VacationBalanceTransferRequest
) {
  // if (transferRequest.FromVacationBalanceId) {
  //   const body = { ...transferRequest };
  //   try {
  //     const response = await api.put(apiEndpoint + '/put', body);
  //     return response;
  //   } catch (error) {
  //     console.log('Error:', error);
  //     throw error;
  //   }
  // }

  try {
    const response = await api.post(apiEndpoint + '/Create', transferRequest);
    return response;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}

export async function deleteTransferRequest(transferRequestId: string) {
  try {
    const response = await api.delete(apiEndpoint + '/delete', {
      data: { id: transferRequestId },
    });
    return response;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}
export async function validateDraftTransferRequest(
  TransferrequestLeaveId: string
) {
  try {
    const response_1 = await api.post(apiEndpoint + '/ValidateDraft', {
      id: TransferrequestLeaveId,
    });
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function changeTransferRequestLeaveState(
  TransferRequestleaveState: any
) {
  try {
    const response_1 = await api.post(
      apiEndpoint + '/ChangeState',
      TransferRequestleaveState
    );
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function cancelTransferRequestLeave(id: string) {
  try {
    const response_1 = await api.post(apiEndpoint + '/Cancel', {
      id: id,
    });
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export async function getTransferRequestLeavesByTeamLeaderId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByTeamLeaderId', config);
   
    return data as TransferRequestsResponse;
  } catch (ex: any) {
    console.log('🚀 ~ file: LeaveService.ts:25 ~ getLeaveRequests ~ ex:', ex);
  }
}
