import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  JournalEntry,
  getJournalEntry,
  saveJournalEntry,
} from '@/services/JournalEntriesService';
import DefaultInput from '@/components/Input';
import DatePicker from '@/components/ReactDatePickerDefault';
import moment from 'moment';
import Dropdown from '@/components/DropDown';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import {
  JournalEntryType,
  JournalEntryTypeOptions,
} from '@/enums/JournalEntryType';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import {
  ProjectTeamMemberResponse,
  getProjectTeamMembersByProjectId,
} from '@/services/ProjectTeamMemberService';

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('JournalEntryForm.subjectRequired'),
  type: Yup.string().required('JournalEntryForm.typeRequired'),
  dateAdded: Yup.date().required('JournalEntryForm.dateAddedRequired'),
  comments: Yup.string().optional(),
});

const userTimeZone = moment.tz.guess();

interface FormValues extends JournalEntry {}

function JournalEntryForm() {
  const { entryId, id } = useParams();
  const editForm = entryId !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    subject: '',
    type: JournalEntryType.Technique,
    dateAdded: new Date(),
    comments: '',
    projectTitle: '',
    authorId: '',
    authorName: '',
    projectId: id,
  });
  const [loading, setLoading] = useState(false);

  const {
    data: journalEntryData,
    isLoading,
    refetch: refetchJournalEntry,
  } = useQuery<JournalEntry | undefined>(
    ['journalEntry', entryId],
    async () => await getJournalEntry(entryId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (journalEntry) => {
        if (journalEntry) {
          setFormikValuesState((prev) => ({ ...prev, ...journalEntry }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && journalEntryData) {
      setFormikValuesState((prev) => ({
        ...prev,
        ...journalEntryData,

        dateAdded: moment
          .utc(journalEntryData.dateAdded)
          .tz(userTimeZone)
          .toDate(),
      }));
    }
  }, [editForm, journalEntryData]);

  const handleSaveJournalEntry = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const journalEntry: JournalEntry = {
        ...values,
      };

      const data = await saveJournalEntry(journalEntry);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.JournalEntry'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.JournalEntry'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchJournalEntry();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const { data: projectTeamMemebers, isLoading: isLoadingEmployees } = useQuery<
    ProjectTeamMemberResponse | undefined
  >(
    ['projectTeamMemebers', id],
    async () =>
      await getProjectTeamMembersByProjectId({
        page: 0,
        take: 0,
        search: '',
        projectId: id!,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('JournalEntryForm.editJournalEntry')
            : t('JournalEntryForm.addJournalEntry')
        }
      />
      {loading && isLoading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveJournalEntry}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-2 md:grid-cols-2">
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.subject}
                    label={t('JournalEntryForm.subjectLabel')}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  {/* <Dropdown
                    name="type"
                    id="type"
                    label={t('JournalEntryForm.typeLabel')}
                    value={values.type}
                    onChange={handleChange}
                    error={errors.type}
                    options={[
                      { id: 'type1', name: 'Type 1' },
                      { id: 'type2', name: 'Type 2' },
                      { id: 'type3', name: 'Type 3' },
                    ]}
                  /> */}

                  <Dropdown
                    name="type"
                    id="type"
                    label={t('JournalEntryForm.typeLabel')}
                    value={values.type}
                    onChange={handleChange}
                    translatedNameSpace="JournalEntryType"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    labelClassName="pl-0"
                    error={errors.type}
                    options={JournalEntryTypeOptions ?? []}
                  />
                </div>
              </div>
              <div className="group relative mb-6 w-full">
                <ReactDatePickerDefault
                  name="dateAdded"
                  id="dateAdded"
                  label={t('JournalEntryForm.dateAddedLabel')}
                  value={values.dateAdded ?? new Date()}
                  onChange={(date: Date) => {
                    handleChange({
                      target: {
                        name: 'dateAdded',
                        value: date,
                      },
                    });
                  }}
                  error={errors.dateAdded}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <Dropdown
                  id="authorId"
                  name="authorId"
                  optionValue="employeeId"
                  optionLabel="email"
                  options={projectTeamMemebers?.projectTeamMembers ?? []}
                  onChange={handleChange}
                  // options={employeesData?.employees ?? []}
                  label={t('JournalEntryForm.authorLabel')}
                  labelClassName="w-[50%]"
                  error={errors.authorId}
                  value={values.authorId}
                />
              </div>
              <div className="group relative mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="comments"
                  id="comments"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.comments}
                  label={t('JournalEntryForm.commentsLabel')}
                />
              </div>

              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('JournalEntryForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('JournalEntryForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default JournalEntryForm;
