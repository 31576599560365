export enum SubjectStatus {
  Closed,
  InProgress,
  Cancelled,
  Delayed,
}

export const SubjectStatusOptions = Object.keys(SubjectStatus)
  .filter((key) => isNaN(Number(SubjectStatus[key])))
  .map((key) => ({
    id: key,
    name: SubjectStatus[key],
  }));
