import { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik, FormikState } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import {
  Appointment,
  ChangeAppointmentStateProps,
  changeAppointmentState,
  getAppointment,
  saveAppointment,
} from '@/services/AppointmentService';
import {
  Employee,
  EmployeesResponse,
  getEmployees,
} from '@/services/EmployeeService';
import {
  VisitReason,
  getVisitReasonsArray,
  mapVisiteReasonToFrontendType,
} from '@/enums/VisiteReason';
import { DateAndTimePicker } from '@/components/DateAndTimePicker';
import TextArea from '@/components/TextArea';
import DatePickerDefault from '@/components/DatePicker';
import { useAuth } from '@/context/AuthContext';
import toast from 'react-hot-toast';
import { UserRoles } from '@/enums/UsersRole';
import { AppointmentState } from '@/enums/AppointmentState';
import moment from 'moment-timezone';
import {
  FreeDaysModel,
  getFreeDaysByEmployeeId,
  getWorkCalendarByEmployeeId,
} from '@/services/WorkCalendarsService';
import { UserResponsibility } from '@/enums/UserResponsibility';

const userTimeZone = moment.tz.guess();
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required('appointmentsForm.employeeIsRequired'),
  motif: Yup.string().required('appointmentsForm.motifIsRequired'),
  organismeName: Yup.string().required(
    'appointmentsForm.organismeNameIsRequired'
  ),
  organismeAddress: Yup.string().required(
    'appointmentsForm.organismeAddressIsRequired'
  ),
  doctorFamilyName: Yup.string().required(
    'appointmentsForm.doctorFamilyNameIsRequired'
  ),
  doctorName: Yup.string().required('appointmentsForm.doctorNameIsRequired'),
  doctorPhone: Yup.string().test(
    'phone-format',
    'appointmentsForm.doctorPhoneIsRequired',
    (value) => {
      if (!value || value.trim() === '') {
        return true; // Allow empty values
      }
      return /^\+[0-9]+$/.test(value);
    }
  ),
  rendezvousDateTime: Yup.date()
    .required('appointmentsForm.rendezvousDateTimeIsRequired')
    .min(new Date(), 'appointmentsForm.rendezvousDateTimeShouldBeInFuture'),
  requiredDocuments: Yup.string().required(
    'appointmentsForm.requiredDocumentsIsRequired'
  ),
  importantMessage: Yup.string().required(
    'appointmentsForm.importantMessageIsRequired'
  ),
});

interface FormValues extends Appointment {}

function MedicalAppointmentForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState();

  const { t, i18n } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    employeeId: '',
    rendezvousDateTime: new Date(),
    convocationDate: new Date(),
    dateEnvoiConvocation: new Date(),
    convocationValideParSalarie: false,
    visiteMedicaleDone: false,
    appointmentState: AppointmentState.Pending,
    motif: 1,
    doctorName: '',
    doctorFamilyName: '',
    organismeName: '',
    importantMessage:
      'If you have a problem, please alert us 48 hours before the appointment date',
  });
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const isMedicalVisiteAdmin =
    currentUser?.responsibilityClaim ===
    UserResponsibility[UserResponsibility.GestionDeVisiteMedicale].toString();

  const readonly =
    !isMedicalVisiteAdmin ||
    formikValuesState.appointmentState != AppointmentState.Pending;

  const { data: employeesData, isLoading: employeesLoading } = useQuery<
    EmployeesResponse | undefined
  >(
    ['employees', currentUser?.id],
    async () => await getEmployees({ fromvalue: 0, takevalue: 0 }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (employee) => {
        setEmployees(employee?.employees!);
      },
    }
  );

  const {
    data: appointmentData,
    isLoading,

    refetch: refetchAppointment,
  } = useQuery<Appointment>(
    ['appointment', id, editForm],
    async () => await getAppointment(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (appointment) => {
        if (appointment) {
          setFormikValuesState((prev) => ({ ...prev, ...appointment }));
        }
      },
    }
  );

  const {
    data: freeDaysData,
    isLoading: isFreeDaysLoading,
    isSuccess,
    refetch: refetchFreeDays,
  } = useQuery<FreeDaysModel | undefined>(
    [
      'FreeDays',
      {
        month: new Date(selectedDate).getMonth() + 1,
        year: new Date(selectedDate).getFullYear(),
        employeeId: selectedEmployee,
      },
    ],
    //@ts-noCheck
    async () => {
      return await getFreeDaysByEmployeeId({
        employeeId: selectedEmployee,
        month: selectedDate.getMonth() + 1,
        year: selectedDate.getFullYear(),
      });
    },
    {
      enabled: !!selectedEmployee,
      refetchOnWindowFocus: false,
      staleTime: 8000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (editForm && appointmentData) {
      // Update form values with branch data
      setFormikValuesState((prev) => ({ ...prev, ...appointmentData }));
    }
  }, [editForm, appointmentData]);

  const handleEnvoyeeAppointment = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const appointment: Appointment = {
        ...values,
        medicalVisitAdminId: currentUser!.id,
      };

      const data = await saveAppointment(appointment);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.appointmentsForm'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.appointmentsForm'),
            })
          );
        }
      }
      if (data && editForm) {
        //refetchBranch();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const handleRejectAppointment = async (values: FormValues) => {
    const appointmentState: ChangeAppointmentStateProps = {
      id: values.id!,
      appointmentState: AppointmentState.Rejected,
      comment: values.comment ?? '',
    };

    const data = await changeAppointmentState(appointmentState);
    navigate(-1);
  };
  const handleAccepteAppointment = async (values: FormValues) => {
    const appointmentState: ChangeAppointmentStateProps = {
      id: values.id!,
      appointmentState: AppointmentState.Approved,
      comment: values.comment ?? '',
    };

    const data = await changeAppointmentState(appointmentState);
    navigate(-1);
  };
  const formButtons = (
    resetForm: (
      nextState?: Partial<FormikState<FormValues>> | undefined
    ) => void,
    values: FormValues
  ) => {
    if (values.appointmentState != AppointmentState.Pending) {
      return;
    }

    if (isMedicalVisiteAdmin) {
      return (
        <>
          <button
            type="submit"
            className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
            disabled={loading}
          >
            {t('appointmentsForm.Envoyee')}
          </button>
          <button
            type="button"
            id="cancelForm"
            className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
            onClick={() => {
              resetForm();
              navigate(-1);
            }}
          >
            {t('appointmentsForm.Cancel')}
          </button>
        </>
      );
    } else {
      return (
        <div className=" flex items-end justify-end">
          <button
            type="button"
            id="cancelForm"
            className="mr-2 inline-flex items-center rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
            onClick={() => handleRejectAppointment(values)}
          >
            {t('appointmentsForm.Reject')}
          </button>
          <button
            type="button"
            className="mr-2 inline-flex items-center rounded-lg bg-green-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
            disabled={loading}
            onClick={() => handleAccepteAppointment(values)}
          >
            {t('appointmentsForm.Accepte')}
          </button>
        </div>
      );
    }
  };

  // useEffect(() => {
  //   console.warn(
  //     '🚀 ~ file: MedicalAppointmentForm.tsx:276 ~ MedicalAppointmentForm ~ isLoading , employeesLoading:',
  //     isLoading,
  //     employeesLoading
  //   );
  // }, [isLoading, employeesLoading]);

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          isMedicalVisiteAdmin
            ? editForm
              ? t('Appointments.EditAppointment')
              : t('Appointments.AddAppointment')
            : t('appointmentsForm.AppointmentDetails')
        } // Translate the page name
      />
      {employeesLoading || (isLoading && editForm) ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleEnvoyeeAppointment}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            console.log('this is datas ', errors),
            (
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="employeeId"
                      name="employeeId"
                      value={values.employeeId ?? ''}
                      optionValue="id"
                      onChange={(e) => {
                        setSelectedEmployee(e.target.value);
                        setFieldValue('employeeId', e.target.value);
                      }}
                      options={employees ?? []}
                      label={t('appointmentsForm.Employee')}
                      optionLabel="employeeFullName"
                      error={errors.employeeId}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="visiteReason"
                      name="motif"
                      value={mapVisiteReasonToFrontendType(values.motif)}
                      onChange={handleChange}
                      options={getVisitReasonsArray(t) ?? []}
                      label={t('appointmentsForm.VisiteReason')}
                      error={errors.motif}
                      optionLabel="label"
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="text"
                      name="organismeName"
                      id="organismeName"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.organismeName}
                      label={t('appointmentsForm.organismeName')}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="text"
                      name="organismeAddress"
                      id="organismeAddress"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.organismeAddress ?? ''}
                      label={t('appointmentsForm.organismeAddress')}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="text"
                      name="doctorFamilyName"
                      id="doctorFamilyName"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.doctorFamilyName}
                      label={t('appointmentsForm.doctorFamilyName')}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="text"
                      name="doctorName"
                      id="doctorName"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.doctorName ?? ''}
                      label={t('appointmentsForm.doctorName')}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="text"
                      name="doctorPhone"
                      id="doctorPhone"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.doctorPhone ?? ''}
                      label={t('appointmentsForm.doctorPhone')}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    {readonly ? (
                      <DefaultInput
                        type="text"
                        name="rendezvousDateTime"
                        id="rendezvousDateTime"
                        placeholder=" "
                        onChange={handleChange}
                        errors={errors}
                        value={moment
                          .utc(values.rendezvousDateTime)
                          .tz(userTimeZone)
                          .format('YYYY-MM-DD hh:mm')}
                        label={t('appointmentsForm.DateAndTimeofRDV')}
                        disabled={true}
                      />
                    ) : (
                      <DateAndTimePicker
                        id="rendezvousDateTime"
                        label={t('appointmentsForm.DateAndTimeofRDV')}
                        selectedDate={
                          new Date(values.rendezvousDateTime) ?? new Date()
                        }
                        selectedLanguage={i18n.language}
                        setSelectedDate={(e) => {
                          const date = new Date(e);
                          setFieldValue('rendezvousDateTime', date);

                          setSelectedDate((pe) => date);
                        }}
                        name="rendezvousDateTime"
                        freeDays={freeDaysData}
                        errors={errors.rendezvousDateTime}
                        disabled={
                          !isMedicalVisiteAdmin ||
                          values.appointmentState != AppointmentState.Pending
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="group relative  mb-6 w-full">
                  <DatePickerDefault
                    id="convocationDate"
                    value={new Date(values.convocationDate)}
                    name="convocationDate"
                    label={t('appointmentsForm.convocationDate')}
                    defaultDate={values.convocationDate}
                    freeDays={freeDaysData}
                    onChange={(date: any) => {
                      handleChange({
                        target: {
                          name: 'convocationDate',
                          value: date,
                        },
                      });
                      var newDate = new Date(date);
                      setSelectedDate((pe) => newDate);
                    }}
                    errors={errors.convocationDate}
                    disabled={
                      !isMedicalVisiteAdmin ||
                      values.appointmentState != AppointmentState.Pending
                    }
                  />
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <TextArea
                      id="requiredDocuments"
                      name="requiredDocuments"
                      label={t('appointmentsForm.Documents')}
                      errors={errors}
                      value={values.requiredDocuments ?? ''}
                      onChange={handleChange}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <TextArea
                      id="importantMessage"
                      name="importantMessage"
                      label={t('appointmentsForm.importantMessage')}
                      errors={errors}
                      value={values.importantMessage ?? ''}
                      onChange={handleChange}
                      disabled={
                        !isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <TextArea
                      id="comment"
                      name="comment"
                      label={t('appointmentsForm.comment')}
                      errors={errors}
                      value={values.comment ?? ''}
                      onChange={handleChange}
                      disabled={
                        isMedicalVisiteAdmin ||
                        values.appointmentState != AppointmentState.Pending
                      }
                    />
                  </div>
                </div>

                {formButtons(resetForm, values)}
              </form>
            )
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default MedicalAppointmentForm;
