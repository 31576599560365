export enum JVType {
  Consortium,
  WithSolidarity,
  JVIntegre,
  Alliance,
  Other,
}

export const JVTypeOptions = Object.keys(JVType)
  .filter((key) => isNaN(Number(JVType[key])))
  .map((key) => ({
    id: key,
    name: JVType[key],
  }));
