import { HubUrl } from '@/variables/Urls';
import {
  LogLevel,
  HubConnectionBuilder,
  HubConnection,
  HttpTransportType,
  HubConnectionState,
} from '@microsoft/signalr';
import { getJwt } from './AuthService';

class SignalRService {
  private static hubConnection: HubConnection | null = null;
  private jwtToken = getJwt();

  startConnection = async () => {
    const hubConnectionBuilder = new HubConnectionBuilder().withUrl(
      `${HubUrl}?access_token=${this.jwtToken}`,
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      }
    );

    if (import.meta.env.DEV) {
      hubConnectionBuilder.configureLogging(LogLevel.Information);
    }

    SignalRService.hubConnection = hubConnectionBuilder
      .withAutomaticReconnect()
      .build();

    await SignalRService.hubConnection.start();
  };

  stopConnection = () => {
    if (SignalRService.hubConnection?.state === HubConnectionState.Connected) {
      SignalRService.hubConnection?.stop();
      console.log('SignalR Connection stopped');
    }
  };

  onReceiveNotification(callback: (notification: any) => void) {
    SignalRService.hubConnection?.off('UserReceiveNotification', callback);
    SignalRService.hubConnection?.on('UserReceiveNotification', callback);
  }
  onReceiveAnnualInterviewSync(callback: () => void) {
    SignalRService.hubConnection?.on('SyncAnnualInterviewData', callback);
  }

  onReceivePublicationsSync(callback: () => void) {
    SignalRService.hubConnection?.on('SyncPublicationMessageData', callback);
  }
}

export default new SignalRService();
