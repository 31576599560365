// export enum ContractType {
//   FixedTerm = 'Fixed Term',
//   Permanent = 'Permanent',
//   Freelance = 'Freelance',
//   Internship = 'Internship',
//   Other = 'Other',
// }
export enum ContractType {
  PermanentContract, // CDI (Contrat à Durée Indéterminée)
  FixedTermContract, // CDD (Contrat à Durée Déterminée)
  Internship, // Stage
  Apprentice, // Alternant
  TemporaryWork, // Intérim
  Consultant, // Consultant
  Other,
}

export const ContractTypeOptions = [
  { id: ContractType.PermanentContract, name: 'Permanent Contract' },
  { id: ContractType.FixedTermContract, name: 'Fixed Term Contract' },
  { id: ContractType.Internship, name: 'Internship' },
  { id: ContractType.Apprentice, name: 'Apprentice' },
  { id: ContractType.TemporaryWork, name: 'Temporary Work' },
  { id: ContractType.Consultant, name: 'Consultant' },
  { id: ContractType.Other, name: 'Other' },
];
