import React, { useEffect } from 'react';
import { MultiStepFormProps } from '../EmployeeForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePickerDefault from '@/components/DatePicker';
import DefaultInput from '@/components/Input';
import Dropdown from '@/components/DropDown';
import { contractTypeOptions } from '@/enums/contractType';
import { useTranslation } from 'react-i18next';
import TextArea from '@/components/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetForm,
  saveEmployeeDataToBackend,
  updateContractValue,
  updateEmployeeDataToBackend,
  updateUserValue,
} from '@/state/slices/employeeSlice';
import { EmployeeUser } from '@/models/empUser';
import { RootState } from '@/state';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { generateEmployeePassword } from '@/utils/helpers';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CheckboxGroup from '@/components/CheckboxGroup';
import {
  UserResponsibility,
  getAccessRightsPOAArray,
  getAccessRightsPREAArray,
  getAccessRightsRHArray,
  getAccessRightsRapportArray,
} from '@/enums/UserResponsibility';
import {
  convertEnumToCheckboxes,
  convertEnumToStringArray,
} from '@/utils/EnumUtils';
const checkboxOptions = convertEnumToCheckboxes(UserResponsibility);

const validationSchema = Yup.object().shape({
  email: Yup.string().email('User.emailInvalid').required('User.emailRequired'),
  password: Yup.string()
    .required('validationMessages.passwordRequired')
    .min(8, 'validationMessages.passwordMinLength')
    .matches(/[A-Z]/, 'validationMessages.passwordCapitalLetters')
    .matches(/[a-z]/, 'validationMessages.passwordLowercaseLetters')
    .matches(/\d/, 'validationMessages.passwordDigits')
    .matches(
      /[\[\]!"!@$%^&*(){}:;<>,.?/+_=|'~\\-]/,
      'validationMessages.passwordSpecialCharacters'
    )

    .matches(/^[^£# “”]*$/, 'validationMessages.passwordNoInvalidCharacters'),
});
const validationEditSchema = Yup.object().shape({
  email: Yup.string().email('User.emailInvalid').required('User.emailRequired'),
  // password: Yup.string()
  //   .required('validationMessages.passwordRequired')
  //   .min(8, 'validationMessages.passwordMinLength')
  //   .matches(/[A-Z]/, 'validationMessages.passwordCapitalLetters')
  //   .matches(/[a-z]/, 'validationMessages.passwordLowercaseLetters')
  //   .matches(/\d/, 'validationMessages.passwordDigits')
  //   .matches(
  //     /[\[\]!"!@$%^&*(){}:;<>,.?/+_=|'~\\-]/,
  //     'validationMessages.passwordSpecialCharacters'
  //   )

  //   .matches(/^[^£# “”]*$/, 'validationMessages.passwordNoInvalidCharacters'),
});

function UserEmployeeInformation({
  currentStep,
  setCurrentStep,
}: MultiStepFormProps) {
  const { t } = useTranslation();
  //! Errors here in way of using think
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { employee, contract, user, loading } = useSelector(
    (state: RootState) => state.employees
  );
  const { id } = useParams();
  const employeeId = id !== 'new';

  const navigate = useNavigate();

  const handleMoveNext = async (values: EmployeeUser) => {
    try {
      if (employeeId && id) {
        await dispatch(
          updateEmployeeDataToBackend({
            id,
            employee,
            contract,
            user: { ...values },
            loading: loading,
          })
        );
        toast.success(
          t('SuccessUpdateMessage', {
            name: t('ToastMsg.Employee'),
          })
        );
        navigate('/gestion-resources-humains/gestion-des-absences/employees');
      } else {
        dispatch(updateUserValue({ userForm: values }));

        await dispatch(
          saveEmployeeDataToBackend({
            employee,
            contract,
            user: { ...values },
            loading: loading,
          })
        );
      }
      toast.success(
        t('SuccessMessage', {
          name: t('ToastMsg.Employee'),
        })
      );
      dispatch(resetForm());
      navigate('/gestion-resources-humains/gestion-des-absences/employees');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (
    <div className="m-4 pt-5">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: employee.email,
          password: employeeId ? '' : generateEmployeePassword(employee.email),
          accessRights: user.accessRights ?? [],
        }}
        validationSchema={
          employeeId && id ? validationEditSchema : validationSchema
        }
        onSubmit={handleMoveNext}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="email"
                  name="email"
                  id="email"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.email}
                  label={t('Employee.email')}
                  errors={errors}
                  readOnly
                />
              </div>
              {
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="password"
                    name="password"
                    id="password"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.password}
                    label={t('User.password')}
                    errors={errors}
                    autoComplete="new-password"
                  />
                </div>
              }
            </div>
            <div className="group relative z-0 mb-6 w-full  ">
              <CheckboxGroup
                title={t('AccessRight.RH')}
                checkboxes={getAccessRightsRHArray(t)}
                selectedCheckboxes={values.accessRights!.map((e) =>
                  e.toString()
                )}
                onChange={(selectedCheckboxes) => {
                  handleChange({
                    target: {
                      name: 'accessRights',
                      value: selectedCheckboxes,
                    },
                  });
                }}
              />
            </div>
            <div className="group relative z-0 mb-6 w-full  ">
              <CheckboxGroup
                title={t('AccessRight.Rapports')}
                checkboxes={getAccessRightsRapportArray(t)}
                selectedCheckboxes={values.accessRights!.map((e) =>
                  e.toString()
                )}
                onChange={(selectedCheckboxes) => {
                  handleChange({
                    target: {
                      name: 'accessRights',
                      value: selectedCheckboxes,
                    },
                  });
                }}
              />
            </div>
            <div className="group relative z-0 mb-6 w-full  ">
              <CheckboxGroup
                title={t('AccessRight.POA')}
                checkboxes={getAccessRightsPOAArray(t)}
                selectedCheckboxes={values.accessRights!.map((e) =>
                  e.toString()
                )}
                onChange={(selectedCheckboxes) => {
                  handleChange({
                    target: {
                      name: 'accessRights',
                      value: selectedCheckboxes,
                    },
                  });
                }}
              />
            </div>
            <div className="group relative z-0 mb-6 w-full  ">
              <CheckboxGroup
                title={t('AccessRight.PREA')}
                checkboxes={getAccessRightsPREAArray(t)}
                selectedCheckboxes={values.accessRights!.map((e) =>
                  e.toString()
                )}
                onChange={(selectedCheckboxes) => {
                  handleChange({
                    target: {
                      name: 'accessRights',
                      value: selectedCheckboxes,
                    },
                  });
                }}
              />
            </div>

            {/* Buttons */}
            <button
              type="submit"
              className="float-right mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
            >
              {t('stepper.save')}
            </button>
            <button
              type="button"
              onClick={() => setCurrentStep(currentStep - 1)}
              className=" mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
            >
              {t('stepper.back')}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default UserEmployeeInformation;
