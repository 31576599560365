import { ProjectAOStatus } from '@/enums/ProjectAOStatus';
import api from '@/utils/api';
import { ProjectAOStatesUrl } from '@/variables/Urls';

const apiEndpoint = ProjectAOStatesUrl;

export interface ProjectAOStatusModel {
  id: string;
  description?: string;
  status: ProjectAOStatus;
  comments?: string;
  reasonForLoss?: string;
  projectId: string;
}

export async function saveProjectAOCase(projectAOC: ProjectAOStatusModel) {
  if (projectAOC.id) {
    const body = { ...projectAOC };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', projectAOC);
  return response_1;
}
export async function getProjectAOState(Id: string) {
  const { data } = await api.get<ProjectAOStatusModel>(
    apiEndpoint + '/GetById',
    {
      params: { id: Id },
    }
  );

  return data;
}
export async function getProjectAOStateByProjectId(ProjectId: string) {
  const { data } = await api.get<ProjectAOStatusModel>(
    apiEndpoint + '/GetByProjectId',
    {
      params: { projectId: ProjectId },
    }
  );

  return data;
}
