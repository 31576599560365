import React from 'react';

import { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultInput from '@/components/Input';
import { Formik, FormikState } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import DatePickerDefault from '@/components/DatePicker';
import Checkbox from '@/components/Checkbox';
import TextArea from '@/components/TextArea';
import { useAuth } from '@/context/AuthContext';
import toast from 'react-hot-toast';
import moment from 'moment-timezone';
import {
  TrainingRequest,
  getTrainingRequest,
  saveTrainingRequest,
} from '@/services/TrainingRequestService';
import { getCompanyId } from '@/services/AuthService';
import Dropdown from '@/components/DropDown';
import { TrainingTypeOptions } from '@/enums/TrainingType';
import { TrainingRequestTypeOptions } from '@/enums/TrainingRequestType';
import {
  DocumentRequest,
  getDocumentRequest,
  saveDocRequest,
} from '@/services/DocumentRequestsService';
import { DocTypeOptions, DocumentTypeEnum } from '@/enums/DocumentTypeEnum';
import { DocumentRequestStatus } from '@/enums/DocumentRequestStatus';

const validationSchema = Yup.object().shape({
  documentType: Yup.string().required('DocRequest.DocumentTypeRequired'),
});

interface FormValues extends DocumentRequest {}

function DocumentRequestForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    comments: '',
    status: DocumentRequestStatus.Pending,
    employeeId: '',
    documentType: DocumentTypeEnum.AttestationMutuelle,
    employeeFullName: '',
  });

  const {
    data: DocRequestData,
    isLoading,
    isError,
    refetch: refetchTraining,
  } = useQuery<FormValues>(
    ['DocRequest', id],
    async () => await getDocumentRequest(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (docReq) => {
        if (docReq) {
          setFormikValuesState((prev) => ({ ...prev, ...docReq }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && DocRequestData) {
      // Update form values with DocRequest data
      setFormikValuesState((prev) => ({ ...prev, ...DocRequestData }));
    }
  }, [editForm, DocRequestData]);

  const handleSaveDocRequest = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    // Handle saving training data
    try {
      const docReq: DocumentRequest = {
        ...values,
        employeeId: user?.id!,
        companyId: getCompanyId()!.toString(),
      };
      // Use the saveTraining function to save the data
      const data = await saveDocRequest(docReq);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.docRequestForm'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.docRequestForm'),
            })
          );
        }
      }
      // Additional logic after saving if needed
      // Navigate back to the previous page
      navigate(-1);
    } catch (error) {
      console.error('Error saving Document Request:', error);
    } finally {
      resetForm();
    }
  };

  const formButtons = (
    resetForm: (
      nextState?: Partial<FormikState<FormValues>> | undefined
    ) => void,
    values: FormValues
  ) => {
    return (
      <>
        <button
          type="submit"
          className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
        >
          {editForm
            ? t('DocRequest.SaveChanges')
            : t('DocRequest.SubmitRequest')}
        </button>
        <button
          type="button"
          id="cancelForm"
          className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
          onClick={() => {
            resetForm();
            navigate(-1);
          }}
        >
          {t('DocRequest.Cancel')}
        </button>
      </>
    );
  };
  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('DocRequest.EditDocRequest')
            : t('DocRequest.AddDocRequest')
        }
      />

      {editForm && isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveDocRequest}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="documentType"
                      name="documentType"
                      optionValue="id"
                      onChange={(e) => {
                        setFieldValue('documentType', e.target.value);
                      }}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={DocTypeOptions}
                      label={t('DocRequest.DocType')}
                      error={errors.documentType}
                      value={values.documentType}
                      labelClassName="w-[50%]"
                      isTranslated={true}
                      translatedNameSpace="DocumentTypes"
                      optionTransalatedKey="name"
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <TextArea
                      id="comments"
                      name="comments"
                      label={t('DocRequest.comments')}
                      errors={errors}
                      value={values.comments}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {formButtons(resetForm, values)}
              </form>
            );
          }}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default DocumentRequestForm;
