import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from './shared/Utils';

export interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  errors?: any;
  name: string;
  labelDir?: 'inLine' | 'Above';
  containerClass?: string;
  textAreaClassName?: string;
}

function TextArea({
  id,
  name,
  label,
  errors,
  labelDir = 'Above',
  containerClass,
  textAreaClassName,
  ...rest
}: ITextAreaProps) {
  const { t } = useTranslation();

  const [, setIsFocused] = React.useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={classNames(
        containerClass ?? '',
        labelDir == 'Above' ? 'relative' : 'inline-flex items-baseline  gap-4 '
      )}
    >
      <label
        htmlFor={id}
        className={`
          mb-2 block text-sm font-medium text-gray-900 dark:text-white 
          ${errors && errors[name] ? 'text-red-700 dark:text-red-500' : ''}
        `}
      >
        {label}
      </label>
      <textarea
        id={id}
        rows={4}
        className={classNames(
          textAreaClassName ?? '',
          `
          block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
        
          ${
            errors && errors[name]
              ? 'block w-full rounded-lg border border-red-500  p-2.5 text-sm text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 '
              : ''
          }
        `
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      {errors && errors[name] && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors[name])}
        </p>
      )}
    </div>
  );
}

export default TextArea;
