export enum Currency {
  AFN,
  ZAR,
  ALL,
  DZD,
  EUR,
  AOA,
  XCD,
  SAR,
  ARS,
  AMD,
  AWG,
  AUD,
  AZN,
  BSD,
  BHD,
  BDT,
  BBD,
  BYN,
  BZD,
  XOF,
  BMD,
  INR,
  BTN,
  BOB,
  BOV,
  USD,
  BAM,
  BWP,
  BRL,
  BND,
  BGN,
  BIF,
  KHR,
  XAF,
  CAD,
  CVE,
  CLP,
  CLF,
  CNY,
  COP,
  COU,
  KMF,
  CDF,
  KRW,
  KPW,
  CRC,
  CUP,
  CUC,
  ANG,
  DKK,
  DJF,
  EGP,
  SVC,
  AED,
  ERN,
  SZL,
  USN,
  ETB,
  RUB,
  FJD,
  XDR,
  GMD,
  GEL,
  GHS,
  GIP,
  GYD,
  HTG,
  HNL,
  HKD,
  HUF,
  NOK,
  KYD,
  NZD,
  FKP,
  SBD,
  IDR,
  IRR,
  IQD,
  ISK,
  ILS,
  JMD,
  JPY,
  JOD,
  KZT,
  KES,
  KGS,
  KWD,
  LAK,
  LSL,
  LBP,
  LRD,
  LYD,
  CHF,
  MOP,
  MKD,
  MGA,
  MYR,
  MWK,
  MVR,
  MAD,
  MUR,
  MRU,
  MXN,
  MDL,
  MNT,
  MZN,
  MMK,
  NAD,
  NPR,
  NIO,
  NGN,
  XPF,
  OMR,
  UGX,
  UZS,
  PKR,
  PAB,
  PGK,
  PYG,
  XUA,
  PEN,
  PHP,
  PLN,
  QAR,
  SYP,
  DOP,
  RON,
  RWF,
  SHP,
  STN,
  RSD,
  SCR,
  SLL,
  SGD,
  SOS,
  SDG,
  SEK,
  CHE,
  CHW,
  SRD,
  TJS,
  TWD,
  TZS,
  CZK,
  THB,
  TOP,
  TTD,
  TND,
  TMT,
  TRY,
  UAH,
  UYU,
  UYI,
  UYW,
  VUV,
  VES,
  VED,
  VND,
  YER,
  ZMW,
  ZWL,
}

export const currencyOptions = Object.keys(Currency)
  .filter((key) => isNaN(Number(Currency[key])))
  .map((key) => ({
    id: key,
    name: Currency[key],
  }));
