import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DefaultInput from '@/components/Input';
import { useTranslation } from 'react-i18next';
import { LabelButton } from '@/components/shared/Button';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { DegreeModel } from '@/services/JobDescriptionService';
import { updateDegree, addDegree } from '@/state/slices/jobdescriptionSlice';

interface DegreesTableFormProps {
  initialValues?: DegreeModel;
  onSubmit: (values: DegreeModel) => void;
  handleClose: () => void;
}

const DegreesTableForm: React.FC<DegreesTableFormProps> = ({
  initialValues,
  onSubmit,
  handleClose,
}) => {
  const { t } = useTranslation(); // Using the 'DegreeForm' namespace
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const validationSchema = Yup.object().shape({
    yearOfCompletion: Yup.number()
      .required(t('DegreeForm.errors.yearOfCompletion'))
      .positive(t('DegreeForm.errors.yearOfCompletion'))
      .integer(t('DegreeForm.errors.yearOfCompletion')),
    title: Yup.string().required(t('DegreeForm.errors.title')),
    institution: Yup.string().required(t('DegreeForm.errors.institution')),
  });

  const handleSubmit = (values: DegreeModel) => {
    if (!!initialValues) {
      dispatch(updateDegree({ degree: values }));
    } else {
      dispatch(addDegree({ degree: values }));
    }
    onSubmit(values);
  };

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white bg-opacity-20  dark:bg-gray-700 dark:bg-opacity-20">
      <div className=" mt-20 max-h-full  w-96 overflow-y-auto rounded bg-white p-6 shadow-md dark:bg-gray-700">
        <div className="mb-4 text-2xl font-semibold">
          {!!initialValues
            ? t('DegreeForm.editDegree')
            : t('DegreeForm.addDegree')}
        </div>

        <Formik
          initialValues={
            initialValues || {
              yearOfCompletion: 0,
              title: '',
              institution: '',
              jobDescriptionId: '',
            }
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isValid }) => (
            <Form>
              <div className="mb-4">
                <DefaultInput
                  id="yearOfCompletion"
                  type="number"
                  name="yearOfCompletion"
                  label={t('DegreeForm.yearOfCompletion')}
                  onChange={handleChange}
                  value={values.yearOfCompletion}
                  errors={errors.yearOfCompletion}
                />
              </div>
              <div className="mb-4">
                <DefaultInput
                  id="title"
                  type="text"
                  name="title"
                  label={t('DegreeForm.title')}
                  onChange={handleChange}
                  value={values.title}
                  errors={errors.title}
                />
              </div>
              <div className="mb-4">
                <DefaultInput
                  id="institution"
                  type="text"
                  name="institution"
                  label={t('DegreeForm.institution')}
                  onChange={handleChange}
                  value={values.institution}
                  errors={errors.institution}
                />
              </div>
              <div className="flex items-center justify-between">
                <LabelButton type="button" onClick={handleClose}>
                  {t('DegreeForm.cancel')}
                </LabelButton>
                <button
                  type="button"
                  onClick={(e) => handleSubmit(values)}
                  disabled={!isValid}
                  className="mb-2 mr-2 rounded-lg border border-primary-700 px-5 py-2.5 text-center text-sm font-medium text-primary-700 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300 dark:border-primary-500 dark:text-primary-500 dark:hover:bg-primary-600 dark:hover:text-white dark:focus:ring-primary-800 "
                >
                  {t('DegreeForm.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DegreesTableForm;
