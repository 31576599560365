import Papa from 'papaparse';
//import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useTranslation } from 'react-i18next';
import { RequestLeaveState } from '@/enums/RequestLeaveState';
import i18n from 'i18next';
import moment from 'moment';
import { Event } from '@/components/TimeLine';
import { Employee } from '@/services/EmployeeService';
import { getDaysData } from './CalendarHelper';
import * as XLSX from 'xlsx-js-style';

interface Column {
  exportValue: string;
  Header: string;
  id: string;
}

interface DataItem {
  [key: string]: any;
}
const formatDateValue = (value: any) => {
  if (value instanceof Date) {
    return moment(value).format('L');
  }
  return value;
};

export function getExportFileBlob({
  columns,
  data,
  fileType,
  fileName,
}: {
  columns: Column[];
  data: DataItem[];
  fileType: 'csv' | 'xlsx' | 'pdf';
  fileName: string;
}): Blob | false {
  let modifiedData: DataItem[] = data;
  const isThereImg = columns.some((x) => x.id === 'profileImgUrl');
  const filteredColumns = columns.filter(
    (column) =>
      column.Header !== 'Actions' &&
      column.id !== 'profileImgUrl' &&
      column.Header !== '' &&
      !column.id.includes('state')
  );

  if (isThereImg) {
    modifiedData = data.map((subArray) => subArray.slice(1));
  } else {
    modifiedData = data;
  }

  if (fileType === 'csv') {
    // CSV example
    const headerNames = filteredColumns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data: modifiedData });
    return new Blob([csvString], { type: 'text/csv' });
  } else if (fileType === 'xlsx') {
    // XLSX example
    const header = filteredColumns.map((c) => c.exportValue);

    const compatibleData = modifiedData.map((row) => {
      const obj: { [key: string]: any } = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of the file is already taken care of
    return false;
  } else if (fileType === 'pdf') {
    // PDF example
    const headerNames = filteredColumns.map((column) => column.exportValue);

    const doc = new jsPDF();

    autoTable(doc, {
      head: [headerNames],
      body: modifiedData.map((row) => {
        const translatedRow = row.map((value, index) => {
          const column = columns[index];

          const header = column.Header;

          if (header === i18n.t('Requestleave.statusColumn')) {
            // Adjust this header value
            const frontendState = RequestLeaveState[value];
            return frontendState !== null
              ? i18n.t(`RequestLeaveState.${frontendState}`)
              : '';
          }
          const formattedRow = formatDateValue(value); // Format date values
          return formattedRow;
          // Add other translation logic for other columns if needed
        });
        return translatedRow;
      }),
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: 'left',
        valign: 'middle',
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    // Returning false as downloading of the file is already taken care of
    return false;
  }

  // Other formats go here
  return false;
}

const generateExcel = async (
  employees: Employee[],
  events: Event[],
  selectedDate: Date,
  daysInMonth: number,
  currentLanguage: string,
  exporterName?: string,
  t?: any
): Promise<Uint8Array> => {
  const ws: XLSX.WorkSheet = {};

  const dateRow = [t('Date'), moment(selectedDate).format('DD/MM/YYYY')];
  const exporterRow = [t('Exporter'), exporterName || 'N/A'];

  XLSX.utils.sheet_add_aoa(ws, [dateRow, exporterRow], {
    origin: 'A1', // Add header information at the start of the sheet
  });
  let daysData = getDaysData(selectedDate, currentLanguage);

  var daysColumns: string[] = [t('sidebar.Employees')];
  daysData.forEach((dayData) => {
    const dayHeader = `${dayData.dayOfWeek} ${dayData.day}`;
    daysColumns.push(dayHeader);
  });

  // Create a data object with employee data
  const data: { [key: string]: { [key: string]: string } } = {};
  employees.forEach((employee) => {
    const employeeFullName = `${employee.firstName} ${employee.lastName}`;
    const employeeData: { [key: string]: string } = {};

    for (let day = 1; day <= daysInMonth; day++) {
      employeeData[day.toString()] = ''; // Initialize empty data for each day
    }

    events
      .filter((event) => event.resourceId === employee.id)
      .forEach((event) => {
        const startDay = event.start.getDate();
        const endDay = event.end.getDate();

        for (let day = startDay; day <= endDay; day++) {
          employeeData[day.toString()] = event.title; // Set event title for the day
        }
      });

    data[employeeFullName] = employeeData;
  });
  const employeesArray = Object.keys(data).map((fullName) => {
    const employeeDataRow = [fullName];
    for (let day = 1; day <= daysInMonth; day++) {
      employeeDataRow.push(data[fullName][day.toString()]);
    }
    return employeeDataRow;
  });

  XLSX.utils.sheet_add_aoa(ws, [daysColumns, ...employeesArray], {
    origin: 'A4', // Start populating data from A2 cell
  });

  let workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const columnWidths = Array(daysInMonth + 1).fill({
    width: 15,
  }); // Adjust the width as needed
  ws['!cols'] = columnWidths;

  XLSX.utils.book_append_sheet(workbook, ws, 'Timeline');
  const borderStyles = {
    top: { style: 'solid', color: 'black' },
    bottom: { style: 'solid', color: 'black' },
    left: { style: 'solid', color: 'black' },
    right: { style: 'solid', color: 'black' },
  };
  for (let col = 0; col <= daysInMonth + 2; col++) {
    const cellAddress = XLSX.utils.encode_cell({ r: 3, c: col });
    if (!ws[cellAddress]) continue;

    ws[cellAddress].s = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { rgb: '80DEEA' },
      },
      font: {
        color: 'FFCC00',
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
      },
    };
  }
  // add style to eachraw from next row after header
  for (let row = 4; row <= employees.length + 3; row++) {
    for (let col = 0; col <= daysInMonth + 2; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      if (!ws[cellAddress]) continue;

      ws[cellAddress].s = {
        // Cell value

        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { rgb: 'FFFFFF' },
        },
        font: {
          color: 'FFFFFF',
        },
        alignment: { horizontal: 'center', vertical: 'center' },

        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
        },
      };
    }
  }

  const buffer: Uint8Array = await XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
    cellDates: true,
    cellStyles: true,
  });
  return buffer;
};

// export const addStyle = (workbook: XLSX.WorkBook) => {
//   return XlsxPopulate.fromDataAsync(workbook).then((workbook) => {
//     workbook.sheets().forEach((sheet) => {
//       sheet.usedRange().style({
//         fontFamily: 'Arial',
//         verticalAlignment: 'center',
//       });

//       sheet.column('A').width(25);
//       sheet.column('B').width(15);
//       sheet.column('C').width(15);
//       sheet.column('E').width(15);
//       sheet.column('G').width(15);
//     });

//     return workbook.outputAsync().then((dt) => dt);
//   });
// };

function fillMerges(ws: XLSX.WorkSheet) {
  if (ws['!merges']) {
    for (let merge of ws['!merges']) {
      for (let i1 = merge.s.c; i1 <= merge.e.c; i1++) {
        for (let i2 = merge.s.r; i2 <= merge.e.r; i2++) {
          XLSX.utils.sheet_add_aoa(
            ws,
            [
              [
                ws[
                  XLSX.utils.encode_col(merge.s.c) +
                    XLSX.utils.encode_row(merge.s.r)
                ].v,
              ],
            ],
            { origin: XLSX.utils.encode_col(i1) + XLSX.utils.encode_row(i2) }
          );
        }
      }
    }
  }
}

export const generatePDF = async (
  employees: Employee[],
  events: Event[],
  selectedDate: Date,
  daysInMonth: number,
  currentLanguage: string,
  exporterName?: string,
  t?: any
): Promise<Uint8Array> => {
  const doc = new jsPDF({
    orientation: 'landscape',
  });

  // Add header information using doc.text()
  doc.text(
    t('Date') + ': ' + moment(selectedDate).format('DD/MM/YYYY'),
    10,
    10
  );
  doc.text(t('Exporter') + ': ' + (exporterName || 'N/A'), 10, 20);
  let daysData = getDaysData(selectedDate, currentLanguage);

  var daysColumns: string[] = [];
  daysData.forEach((dayData) => {
    const dayHeader = `${dayData.dayOfWeek} ${dayData.day}`;
    daysColumns.push(dayHeader);
  });
  // Create table header and data arrays
  let tableHeader = [t('sidebar.Employees')];
  tableHeader = tableHeader.concat(daysColumns);
  const tableData: any[][] = [];

  // Create data object similar to your XLSX code
  employees.forEach((employee) => {
    const employeeDataRow: any[] = [];
    employeeDataRow.push(`${employee.firstName} ${employee.lastName}`);

    for (let day = 1; day <= daysInMonth; day++) {
      const eventsForDay = events.filter((event) => {
        const eventStartDate = event.start.getDate();
        const eventEndDate = event.end.getDate();

        return (
          event.resourceId === employee.id &&
          day >= eventStartDate && // Check if the day is on or after the start date
          day <= eventEndDate // Check if the day is on or before the end date
        );
      });

      if (eventsForDay.length > 0) {
        const eventTitles = eventsForDay.map((event) => event.title).join(', ');
        employeeDataRow.push(eventTitles);
      } else {
        employeeDataRow.push(''); // Empty cell if no event for the day
      }
    }

    // Push employeeDataRow to tableData
    tableData.push(employeeDataRow);
  });

  // Push headers and tableData to doc.autoTable()
  autoTable(doc, {
    head: [tableHeader],
    body: tableData,
    startY: 30,
    margin: { left: 5 },
    theme: 'grid',
    styles: {
      valign: 'middle',
      halign: 'center',
      fontSize: 10,
    },
    columnStyles: {
      0: { cellWidth: 25 },
    },
  });

  // Save the PDF to a Uint8Array buffer
  const buffer: Uint8Array = doc.output('arraybuffer');

  return buffer;
};

export default generateExcel;
