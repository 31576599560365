import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useFormik } from 'formik';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { RootState } from '@/state';
import {
  updateMobility,
  saveAnnualInterviewAsync,
} from '@/state/slices/annualInterviewSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import Input from '@/components/Input';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { InterviewState } from '@/enums/InterviewState';
// Replace with actual import paths

const init = {
  workplaceChange: false,
  reasonForJobChange: false,
  expatriation: false,
};

function Mobility() {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { user } = useAuth();

  const annualInterview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const disabled = annualInterview.interviewState == InterviewState.Completed;

  const reasonForJobChange = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.reasonForJobChange
  );
  const workplaceChange = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview.workplaceChange
  );
  const expatriation = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview.expatriation
  );
  const isTeamLeader = annualInterview.responsibleId == user?.id;
  const isEmployee = annualInterview.employeeId == user?.id;
  const formik = useFormik({
    initialValues: {
      ...init,
      workplaceChange: workplaceChange,
      reasonForJobChange: reasonForJobChange,
      expatriation: expatriation,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(updateMobility({ mobility: values }));
    },
  });
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInterview));
  };

  const resetForm = () => {
    formik.resetForm();
  };
  const handleCheckboxChange = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <div className="group relative mb-6 w-full">
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t('annualInterviewForm.Mobility.workplaceChange')}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t('annualInterviewForm.Mobility.reasonForJobChange')}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t('annualInterviewForm.Mobility.expatriation')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                <td className="px-6 py-4 pl-10">
                  <input
                    id="workplaceChange"
                    name="workplaceChange"
                    type="checkbox"
                    checked={formik.values.workplaceChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                      const newValue = e.target.checked;
                      formik.setFieldValue('workplaceChange', newValue);
                    }}
                    disabled={isTeamLeader || disabled}
                  />
                </td>
                <td className="px-6 py-4  pl-10">
                  <input
                    id="reasonForJobChange"
                    name="reasonForJobChange"
                    type="checkbox"
                    checked={formik.values.reasonForJobChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                      const newValue = e.target.checked;
                      formik.setFieldValue('reasonForJobChange', newValue);
                    }}
                    disabled={isTeamLeader || disabled}
                  />
                </td>
                <td className="px-6 py-4  pl-10">
                  <input
                    id="expatriation"
                    name="expatriation"
                    type="checkbox"
                    checked={formik.values.expatriation}
                    onChange={(e) => {
                      formik.handleChange(e);
                      const newValue = e.target.checked;
                      formik.setFieldValue('expatriation', newValue);
                    }}
                    disabled={isTeamLeader || disabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {!isTeamLeader && !disabled && (
          <div className="flex justify-between">
            <LabelButton type="button" onClick={resetForm}>
              {t('annualInterviewForm.Mobility.reset')}
            </LabelButton>
            <PrimaryButton
              className="border-0 bg-purple-300"
              type="button"
              onClick={handleSyncData}
            >
              <div className="flex items-center gap-2">
                <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
                {t('annualInterviewForm.Mobility.Sync')}
              </div>
            </PrimaryButton>
            <PrimaryButton type="submit" className="border-0 bg-primary-300">
              {t('annualInterviewForm.Mobility.submit')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </form>
  );
}

export default Mobility;
