import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ChildModel } from '@/services/JobDescriptionService';
import { useTranslation } from 'react-i18next';
// Update the path
import Dropdown from '@/components/DropDown';
import { genderOptions } from '@/variables/const';
import DatePickerDefault from '@/components/DatePicker';
import Checkbox from '@/components/Checkbox';
import DefaultInput from '@/components/Input'; // Assuming you have the DefaultInput component
import { LabelButton } from '@/components/shared/Button';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { addChild, updateChild } from '@/state/slices/jobdescriptionSlice';

interface ChildrenTableFormProps {
  initialValues?: ChildModel;
  onSubmit: (values: ChildModel) => void;
  handleClose: () => void;
  edit?: boolean;
}

const ChildrenTableForm: React.FC<ChildrenTableFormProps> = ({
  initialValues,
  onSubmit,
  handleClose,
  edit = false,
}) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('childrenForm.errors.required')),
    lastName: Yup.string().required(t('childrenForm.errors.required')),
    gender: Yup.string().required(t('childrenForm.errors.required')),
    dateOfBirth: Yup.date().required(t('childrenForm.errors.required')),
    isDependent: Yup.boolean().required(t('childrenForm.errors.required')),
  });

  const handleSubmit = (values: ChildModel) => {
    if (!!initialValues) {
      dispatch(updateChild({ child: values }));
    } else {
      dispatch(addChild({ child: values }));
    }

    handleClose();
  };

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white bg-opacity-20  dark:bg-gray-700 dark:bg-opacity-20">
      <div className=" mt-20 max-h-full  w-96 overflow-y-auto rounded bg-white p-6 shadow-md dark:bg-gray-700">
        <div className="mb-4 text-2xl font-semibold">
          {!!initialValues
            ? t('childrenForm.labels.edit')
            : t('childrenForm.labels.add')}
        </div>

        <Formik
          initialValues={
            initialValues || {
              firstName: '',
              lastName: '',
              gender: '',
              dateOfBirth: null,
              isDependent: false,
            }
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isValid }) => (
            <Form>
              <div className="mb-4">
                <DefaultInput
                  id="firstName"
                  type="text"
                  name="firstName"
                  label={t('childrenForm.placeholders.firstName')}
                  onChange={handleChange}
                  value={values.firstName}
                  errors={errors.firstName}
                />
              </div>
              <div className="mb-4">
                <DefaultInput
                  id="lastName"
                  type="text"
                  name="lastName"
                  label={t('childrenForm.placeholders.lastName')}
                  onChange={handleChange}
                  value={values.lastName}
                  errors={errors.lastName}
                />
              </div>
              <div className="mb-4">
                {/* <DefaultInput
              as="select"
              name="gender"
              label={t('childrenForm.labels.gender')}
              onChange={handleChange}
              value={values.gender}
              errors={errors.gender}
            >
              <option
                value=""
                label={t('childrenForm.placeholders.selectGender')}
              />
              <option value="male" label={t('childrenForm.genders.male')} />
              <option value="female" label={t('childrenForm.genders.female')} />
            </DefaultInput> */}
                <Dropdown
                  id="gender"
                  name="gender"
                  label={t('childrenForm.labels.gender')}
                  onChange={handleChange}
                  value={values.gender}
                  errors={errors.gender}
                  optionValue="name"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  options={genderOptions ?? []}
                />
              </div>
              <div className="mb-4">
                {/* <label className="mb-2 block font-bold text-gray-700">
              {t('childrenForm.labels.dateOfBirth')}
            </label> */}
                <DatePickerDefault
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                  defaultDate={values.dateOfBirth}
                  onChange={(date: any) => {
                    handleChange({
                      target: {
                        name: 'dateOfBirth',
                        value: date,
                      },
                    });
                  }}
                  errors={errors.dateOfBirth}
                />
              </div>
              <div className="mb-4">
                <label className="mb-2 block font-bold text-gray-700">{}</label>
                <Checkbox
                  id="isDependent"
                  name="isDependent"
                  checked={values.isDependent}
                  onChange={handleChange}
                  errors={errors.isDependent}
                  label={t('childrenForm.labels.isDependent')}
                />
              </div>
              <div className="flex items-center justify-between">
                <LabelButton type="button" onClick={handleClose}>
                  {t('childrenForm.buttons.cancel')}
                </LabelButton>
                <button
                  type="button"
                  onClick={(e) => handleSubmit(values)}
                  disabled={!isValid}
                  className="mb-2 mr-2 rounded-lg border border-primary-700 px-5 py-2.5 text-center text-sm font-medium text-primary-700 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300 dark:border-primary-500 dark:text-primary-500 dark:hover:bg-primary-600 dark:hover:text-white dark:focus:ring-primary-800 "
                >
                  {t('childrenForm.buttons.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChildrenTableForm;
