export enum RiskType {
  Risk,
  Opportunity,
}

export const RiskTypeOptions = Object.keys(RiskType)
  .filter((key) => isNaN(Number(RiskType[key])))
  .map((key) => ({
    id: key,
    name: RiskType[key],
  }));
