import { CompaniesUrl, DeparetmentsUrl } from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';

export interface Departement {
  title: string;
  id?: string;
  branchId: string;
}

export interface DepartementResponse {
  departments: Departement[];
  count: number;
}
const apiEndpoint = DeparetmentsUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getDepartements({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
      },
    };
    const { data } = await api.get(apiEndpoint + '/get', config);
    return data;
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}
export async function getDepartementsByBranchId(branchId: string) {
  try {
    let config = {
      params: {
        branchId: branchId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/getByBranchId', config);
    return data as Departement[];
  } catch (ex: any) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', ex);
  }
}

export async function getDepartement(departementId: string) {
  try {
    const { data } = await api.get<Departement>(apiEndpoint + '/GetById', {
      params: { id: departementId },
    });

    return data as Departement;
  } catch (error) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', error);
  }
}
export async function getDepartementsByCompanyId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
      },
    };

    const { data } = await api.get<DepartementResponse>(
      apiEndpoint + '/GetByCompanyId',
      config
    );

    return data;
  } catch (error) {
    console.log('🚀 ~ file: CompanyService.ts:43 ~ getCompanies ~ ex:', error);
  }
}

export async function saveDepartement(departement: Departement) {
  if (departement.id) {
    const body = { ...departement };

    try {
      const response = await api.put(apiEndpoint + '/put', body);
      return response;
    } catch (error) {
      console.log(
        '🚀 ~ file: CompanyService.ts:83 ~ departement ~ error:',
        error
      );
    }
  }
  try {
    const response_1 = await api.post(apiEndpoint + '/post', departement);
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export function deleteDepartement(departementId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { departmentId: departementId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      console.log(error.response);
      return error;
    });
}
