import { Currency } from '@/enums/Currency';
import {
  SubProjectConsumable,
  SubProjectConsumablesPayload,
  SubProjectEquipment,
  SubProjectEquipmentsPayload,
  SubProjectInstallation,
  SubProjectInstallationsPayload,
  SubProjectMaterial,
  SubProjectMaterialsPayload,
  SubProjectMethodDto,
  SubProjectOtherEquipment,
  SubProjectOtherEquipmentsPayload,
  SubProjectPersonnelsPayload,
  SubprojectPersonnel,
  deleteSubProjectMethod,
  getSubProjectMethod,
  saveSubProjectConsumables,
  saveSubProjectEquipments,
  saveSubProjectInstallations,
  saveSubProjectMaterials,
  saveSubProjectMethod,
  saveSubProjectOtherEquipments,
  saveSubProjectPersonnels,
} from '@/services/SubProjectMethodsService';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface SubProjectMethodState {
  data: SubProjectMethodDto | null;
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  error: string | undefined;
}

const initialSubProjectMaterial: SubProjectMaterial = {
  id: '',
  subProjectMethodId: '',
  ref: 0,
  equipmentId: '',
  description: '',
  quantity: 0,
  capacity: 0,
  unitPrice: 0,
  totalPrice: 0,
  comments: '',
  exchangeRate: 0,
  equipmentCurrency: Currency.EUR,
};

const initialSubprojectPersonnel: SubprojectPersonnel = {
  id: '',
  subProjectMethodId: '',
  ref: 0,
  personnelCostId: '',
  expat: 0,
  local: 0,
  sousTraitant: 0,
  description: '',
  total: 0,
  hourlyRate: 0,
  totalPerShift: 0,
  totalCost: 0,
  totalHours: 0,
  exchangeRate: 0,
};

const initialSubProjectEquipment: SubProjectEquipment = {
  id: '',
  subProjectMethodId: '',
  equipmentId: '',
  description: '',
  isLocated: false,
  category: '',
  quantity: 0,
  unitPrice: 0,
  totalPrice: 0,
  comment: '',
  exchangeRate: 0,
};

const initialSubProjectConsumable: SubProjectConsumable = {
  id: '',
  subProjectMethodId: '',
  equipmentId: '',
  description: '',
  isLocated: false,
  category: '',
  quantity: 0,
  unitPrice: 0,
  totalPrice: 0,
  exchangeRate: 0,
};

const initialSubProjectInstallation: SubProjectInstallation = {
  id: '',
  subProjectMethodId: '',
  equipmentId: '',
  description: '',
  isLocated: false,
  category: '',
  quantity: 0,
  unitPrice: 0,
  totalPrice: 0,
  exchangeRate: 0,
};

const initialSubProjectOtherEquipment: SubProjectOtherEquipment = {
  id: '',
  subProjectMethodId: '',
  equipmentId: '',
  description: '',
  isLocated: false,
  category: '',
  quantity: 0,
  unitPrice: 0,
  totalPrice: 0,
  exchangeRate: 0,
};
const initialSubProjectMethod: SubProjectMethodDto = {
  id: '',
  projectId: '',
  subProjectGroupId: '',
  activity: '',
  description: '',
  cadences: '',
  workingHoursPerShift: 0,
  shiftsPerDay: 0,
  shiftsPerWeek: 0,
  weatherDowntimePerMonth: 0,
  numberOfMachinesPerShift: 0,
  quantity: 0,
  productionRatePerMachine: 0,
  workshopStartDate: new Date(),
  workshopFinishDate: new Date(),
  durationInDays: 0,
  comments: '',
  currency: Currency.EUR,

  isSubConstructor: false,
  subConstructorPrice: 0,
  TotalHours: 0,
  subProjectMaterials: [],
  subProjectPersonnels: [],
  subProjectEquipments: [],
  subProjectConsumables: [],
  subProjectInstallations: [],
  subProjectOtherEquipments: [],
  groupTitle: '',
};

export const fetchSubProjectMethod = createAsyncThunk<
  SubProjectMethodDto,
  string
>(
  'subProjectMethod/fetchById',
  async (subProjectMethodId: string, thunkAPI) => {
    try {
      const response = await getSubProjectMethod(subProjectMethodId);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const saveSubProjectMethodAsync = createAsyncThunk(
  'subProjectMethod/save',
  async (subProjectMethod: SubProjectMethodDto, thunkAPI) => {
    try {
      const response = await saveSubProjectMethod(subProjectMethod);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const deleteSubProjectMethodAsync = createAsyncThunk(
  'subProjectMethod/delete',
  async (subProjectMethodId: string, thunkAPI) => {
    try {
      await deleteSubProjectMethod(subProjectMethodId);
      return subProjectMethodId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const saveSubProjectMaterialsAsync = createAsyncThunk(
  'saveSubProjectMaterials/save',
  async (subProjectMaterials: SubProjectMaterialsPayload, thunkAPI) => {
    try {
      const response = await saveSubProjectMaterials(subProjectMaterials);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
export const saveSubProjectPersonnelsAsync = createAsyncThunk(
  'saveSubProjectPersonnels/save',
  async (subProjectPersonnels: SubProjectPersonnelsPayload, thunkAPI) => {
    try {
      const response = await saveSubProjectPersonnels(subProjectPersonnels);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
export const saveSubProjectEquipmentsAsync = createAsyncThunk(
  'saveSubProjectEquipments/save',
  async (subProjectEquipments: SubProjectEquipmentsPayload, thunkAPI) => {
    try {
      const response = await saveSubProjectEquipments(subProjectEquipments);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
export const saveSubProjectConsumablesAsync = createAsyncThunk(
  'saveSubProjectConsumables/save',
  async (subProjectConsumables: SubProjectConsumablesPayload, thunkAPI) => {
    try {
      const response = await saveSubProjectConsumables(subProjectConsumables);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
export const saveSubProjectInstallationsAsync = createAsyncThunk(
  'saveSubProjectInstallations/save',
  async (subProjectInstallations: SubProjectInstallationsPayload, thunkAPI) => {
    try {
      const response = await saveSubProjectInstallations(
        subProjectInstallations
      );
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
export const saveSubProjectOtherEquipmentsAsync = createAsyncThunk(
  'saveSubProjectOtherEquipments/save',
  async (
    subProjectOtherEquipments: SubProjectOtherEquipmentsPayload,
    thunkAPI
  ) => {
    try {
      const response = await saveSubProjectOtherEquipments(
        subProjectOtherEquipments
      );
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

const initialState: SubProjectMethodState = {
  data: initialSubProjectMethod,
  status: 'idle',
  error: undefined,
};
const subProjectMethodSlice = createSlice({
  name: 'subProjectMethods',
  initialState,
  reducers: {
    addSubProjectMethod(state, action: PayloadAction<SubProjectMethodDto>) {
      state.data = action.payload;
    },
    updateSubProjectMethod(state, action: PayloadAction<SubProjectMethodDto>) {
      state.data = { ...action.payload };
    },
    // updateIsSubConstructor(state, action: PayloadAction<boolean>) {
    //   state.data?.isSubConstructor = action.payload;
    // },

    // Reducers for SubProjectMaterial
    addSubProjectMaterial(state, action: PayloadAction<SubProjectMaterial>) {
      if (state.data) {
        console.log(
          '🚀 ~ addSubProjectMaterial ~ state.data.subProjectMaterials:',
          '🚀 ~ addSubProjectMaterial ~ state.data.subProjectMaterials:',
          '🚀 ~ addSubProjectMaterial ~ state.data.subProjectMaterials:',
          state.data
        );
        state.data!.subProjectMaterials?.push(action.payload);
      }
    },
    updateSubProjectMaterial(state, action: PayloadAction<SubProjectMaterial>) {
      state.status = 'loading';
      if (state.data && state.data.subProjectMaterials) {
        const index = state.data.subProjectMaterials.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data.subProjectMaterials[index] = action.payload;
        }
      }
      state.status = 'idle';
      return state;
    },
    deleteSubProjectMaterial(state, action: PayloadAction<string>) {
      if (state.data && state.data.subProjectMaterials) {
        state.data.subProjectMaterials = state.data.subProjectMaterials.filter(
          (item) => item.id !== action.payload
        );
      }
    },

    // Reducers for SubprojectPersonnel
    addSubProjectPersonnel(state, action: PayloadAction<SubprojectPersonnel>) {
      if (state.data && state.data.subProjectPersonnels) {
        state.data.subProjectPersonnels.push(action.payload);
      }
    },
    updateSubProjectPersonnel(
      state,
      action: PayloadAction<SubprojectPersonnel>
    ) {
      if (state.data && state.data.subProjectPersonnels) {
        const index = state.data.subProjectPersonnels.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data.subProjectPersonnels[index] = action.payload;
        }
      }
    },
    deleteSubProjectPersonnel(state, action: PayloadAction<string>) {
      if (state.data && state.data.subProjectPersonnels) {
        state.data.subProjectPersonnels =
          state.data.subProjectPersonnels.filter(
            (item) => item.id !== action.payload
          );
      }
    },

    // Reducers for SubProjectEquipment
    addSubProjectEquipment(state, action: PayloadAction<SubProjectEquipment>) {
      if (state.data && state.data.subProjectEquipments) {
        state.data.subProjectEquipments.push(action.payload);
      }
    },
    updateSubProjectEquipment(
      state,
      action: PayloadAction<SubProjectEquipment>
    ) {
      if (state.data && state.data.subProjectEquipments) {
        const index = state.data.subProjectEquipments.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data.subProjectEquipments[index] = action.payload;
        }
      }
    },
    deleteSubProjectEquipment(state, action: PayloadAction<string>) {
      if (state.data && state.data.subProjectEquipments) {
        state.data.subProjectEquipments =
          state.data.subProjectEquipments.filter(
            (item) => item.id !== action.payload
          );
      }
    },

    // Reducers for SubProjectConsumable
    addSubProjectConsumable(
      state,
      action: PayloadAction<SubProjectConsumable>
    ) {
      if (state.data && state.data.subProjectConsumables) {
        state.data.subProjectConsumables.push(action.payload);
      }
    },
    updateSubProjectConsumable(
      state,
      action: PayloadAction<SubProjectConsumable>
    ) {
      if (state.data && state.data.subProjectConsumables) {
        const index = state.data.subProjectConsumables.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data.subProjectConsumables[index] = action.payload;
        }
      }
    },
    deleteSubProjectConsumable(state, action: PayloadAction<string>) {
      if (state.data && state.data.subProjectConsumables) {
        state.data.subProjectConsumables =
          state.data.subProjectConsumables.filter(
            (item) => item.id !== action.payload
          );
      }
    },

    // Reducers for SubProjectInstallation
    addSubProjectInstallation(
      state,
      action: PayloadAction<SubProjectInstallation>
    ) {
      if (state.data && state.data.subProjectInstallations) {
        state.data.subProjectInstallations.push(action.payload);
      }
    },
    updateSubProjectInstallation(
      state,
      action: PayloadAction<SubProjectInstallation>
    ) {
      if (state.data && state.data.subProjectInstallations) {
        const index = state.data.subProjectInstallations.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data.subProjectInstallations[index] = action.payload;
        }
      }
    },
    deleteSubProjectInstallation(state, action: PayloadAction<string>) {
      if (state.data && state.data.subProjectInstallations) {
        state.data.subProjectInstallations =
          state.data.subProjectInstallations.filter(
            (item) => item.id !== action.payload
          );
      }
    },

    // Reducers for SubProjectOtherEquipment
    addSubProjectOtherEquipment(
      state,
      action: PayloadAction<SubProjectOtherEquipment>
    ) {
      if (state.data && state.data.subProjectOtherEquipments) {
        state.data.subProjectOtherEquipments.push(action.payload);
      }
    },
    updateSubProjectOtherEquipment(
      state,
      action: PayloadAction<SubProjectOtherEquipment>
    ) {
      if (state.data && state.data.subProjectOtherEquipments) {
        const index = state.data.subProjectOtherEquipments.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.data.subProjectOtherEquipments[index] = action.payload;
        }
      }
    },
    deleteSubProjectOtherEquipment(state, action: PayloadAction<string>) {
      if (state.data && state.data.subProjectOtherEquipments) {
        state.data.subProjectOtherEquipments =
          state.data.subProjectOtherEquipments.filter(
            (item) => item.id !== action.payload
          );
      }
    },

    resetSubProjectForm(state) {
      state.data = initialSubProjectMethod;

      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubProjectMethod.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchSubProjectMethod.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchSubProjectMethod.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message ?? 'Unknown error';
    });

    builder.addCase(saveSubProjectMethodAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(saveSubProjectMethodAsync.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(saveSubProjectMethodAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message ?? 'Unknown error';
    });

    builder.addCase(deleteSubProjectMethodAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteSubProjectMethodAsync.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (state.data) {
        // Example: Remove data from state if needed
        state.data = null;
      }
    });
    builder.addCase(deleteSubProjectMethodAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message ?? 'Unknown error';
    });
  },
});

export const {
  updateSubProjectMethod,
  addSubProjectMaterial,
  updateSubProjectMaterial,
  deleteSubProjectMaterial,
  addSubProjectPersonnel,
  updateSubProjectPersonnel,
  deleteSubProjectPersonnel,
  addSubProjectEquipment,
  updateSubProjectEquipment,
  deleteSubProjectEquipment,
  addSubProjectConsumable,
  updateSubProjectConsumable,
  deleteSubProjectConsumable,
  addSubProjectInstallation,
  updateSubProjectInstallation,
  deleteSubProjectInstallation,
  addSubProjectOtherEquipment,
  updateSubProjectOtherEquipment,
  deleteSubProjectOtherEquipment,
  addSubProjectMethod,
  resetSubProjectForm,
  //updateIsSubConstructor,
  // Add other exported actions as needed
} = subProjectMethodSlice.actions;

export default subProjectMethodSlice.reducer;
