export enum MaritalStatusEnum {
  Single,
  Married,
  Divorced,
  Widowed,
  NoAnswer,
}

// export const maritalStatusOptions = [
//   { id: 1, name: 'Single' },
//   { id: 2, name: 'Married' },
//   { id: 3, name: 'Divorced' },
//   { id: 4, name: 'Widowed' },
// ];

// Array of options for MaritalStatusEnum
export const maritalStatusOptions = [
  { id: MaritalStatusEnum.Single, name: 'Single' },
  { id: MaritalStatusEnum.Married, name: 'Married' },
  { id: MaritalStatusEnum.Divorced, name: 'Divorced' },
  { id: MaritalStatusEnum.Widowed, name: 'Widowed' },
  { id: MaritalStatusEnum.NoAnswer, name: 'No Answer' },
];
