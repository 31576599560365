const tailwindColorsHex = [
  '#fcc6c6',
  '#aed7f9',
  '#9de6c0',
  '#fdd78b',
  '#c5a4f4',
  '#c8b9ff',
  '#f4b4cf',
  '#c0ccd1',
  '#f6a5a5',
  '#add7f7',
  '#9edebf',
  '#fdd78b',
  '#c5a4f4',
  '#c8b9ff',
  '#f4b4cf',
  '#c0ccd1',
  '#e98f8f',
  '#7ea6f0',
  '#59b999',
  '#f8bf8f',
  '#7d69d3',
  '#b883e7',
  '#f6a4be',
  '#8d9ea3',
  '#6fd2cd',
  '#59b999',
  '#68a078',
  '#f59d8c',
  '#c3938c',
  '#a37474',
];

const colors = {
  vacationCP: tailwindColorsHex[1],
  vacationRTT: tailwindColorsHex[2],
  vacationRTTR: tailwindColorsHex[0],
  vacationEmpagn: tailwindColorsHex[3],
  vacationTT: tailwindColorsHex[4],
  vacationAM: tailwindColorsHex[5],
  vacationFOR: tailwindColorsHex[6],
  vacationDEP: tailwindColorsHex[7],
  vacationREC: tailwindColorsHex[8],
  vacationVM: tailwindColorsHex[9],
  vacationSEM: tailwindColorsHex[10],
  vacationEVE: tailwindColorsHex[11],
  vacationSS: tailwindColorsHex[12],
  vacationCOP: tailwindColorsHex[13],
  vacationCOM: tailwindColorsHex[14],
  vacationCOS: tailwindColorsHex[15],
  vacationCOD: tailwindColorsHex[16],
  vacationCOF: tailwindColorsHex[17],
  vacationCOM_CHILD: tailwindColorsHex[18],
  vacationCOA: tailwindColorsHex[19],
};
export const VacationTypeEnum = {
  RTT: 'RTT',
  RTTR: 'RTTR',
  CP: 'CP',
  TT: 'TT',
  AM: 'AM',
  FOR: 'FOR',
  DEP: 'DEP',
  REC: 'REC',
  VM: 'VM',
  SEM: 'SEM',
  EVE: 'EVE',
  SS: 'SS',
  COP: 'COP',
  COM: 'COM',
  COS: 'COS',
  COD: 'COD',
  COF: 'COF',
  COM_CHILD: 'COM_CHILD',
  COA: 'COA',
  Empagn: 'Empagn',
  CET_CP: 'CET_CP',
  CET_RTT: 'CET_RTT',
};

export const getVacationColor = (vacationType) => {
  switch (vacationType) {
    case VacationTypeEnum.CP:
      return colors.vacationCP;
    case VacationTypeEnum.RTT:
      return colors.vacationRTT;
    case VacationTypeEnum.RTTR:
      return colors.vacationRTTR;
    case VacationTypeEnum.CET_CP:
    case VacationTypeEnum.CET_RTT:
      return colors.vacationEmpagn;
    case VacationTypeEnum.TT:
      return colors.vacationTT;
    case VacationTypeEnum.AM:
      return colors.vacationAM;
    case VacationTypeEnum.FOR:
      return colors.vacationFOR;
    case VacationTypeEnum.DEP:
      return colors.vacationDEP;
    case VacationTypeEnum.REC:
      return colors.vacationREC;
    case VacationTypeEnum.VM:
      return colors.vacationVM;
    case VacationTypeEnum.SEM:
      return colors.vacationSEM;
    case VacationTypeEnum.EVE:
      return colors.vacationEVE;
    case VacationTypeEnum.SS:
      return colors.vacationSS;
    case VacationTypeEnum.COP:
      return colors.vacationCOP;
    case VacationTypeEnum.COM:
      return colors.vacationCOM;
    case VacationTypeEnum.COS:
      return colors.vacationCOS;
    case VacationTypeEnum.COD:
      return colors.vacationCOD;
    case VacationTypeEnum.COF:
      return colors.vacationCOF;
    case VacationTypeEnum.COM_CHILD:
      return colors.vacationCOM_CHILD;
    case VacationTypeEnum.COA:
      return colors.vacationCOA;
    case VacationTypeEnum.Empagn:
      return colors.vacationEmpagn;
    default:
      return 'gray-500';
  }
};

export enum VacationType {
  RTT,
  RTTR,
  CP,
  TT,
  AM,
  FOR,
  DEP,
  REC,
  VM,
  SEM,
  EVE,
  SS,
  COP,
  COM,
  COS,
  COD,
  COF,
  COM_CHILD,
  COA,
  CET_CP,
  CET_RTT,
}
