import { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQuery } from '@tanstack/react-query';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  TrainingProviderResponse,
  getTrainingProviders,
} from '@/services/TrainingProviderService';
import { useTranslation } from 'react-i18next';

function TrainingProviders() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('TrainingProviders.nameColumn'),
        accessor: 'name',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingProviders.addressColumn'),
        accessor: 'address',
      },
      {
        Header: t('TrainingProviders.phoneNumberColumn'),
        accessor: 'phoneNumber',
      },
      {
        Header: t('TrainingProviders.emailColumn'),
        accessor: 'email',
      },
      {
        Header: t('TrainingProviders.websiteColumn'),
        accessor: 'website',
      },
      {
        Header: t('TrainingProviders.noteColumn'),
        accessor: 'note',
      },
    ],
    [t]
  );

  const { isLoading, data: trainingProviders } = useQuery<
    TrainingProviderResponse | undefined
  >(
    ['trainingProviders', pageIndex, pageSize, search],
    async () =>
      await getTrainingProviders({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((trainingProviders?.count ?? 0) / pageSize);
  }, [pageSize, trainingProviders]);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('TrainingProviders.pageName')}
        addbtn={true}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={trainingProviders?.count}
            data={trainingProviders?.trainingProviders ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />

          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default TrainingProviders;
