import { ContractsUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Contract } from '@/models/contract';

export interface ContractResponse {
  contracts: Contract[];
  count: number;
}

const apiEndpoint = ContractsUrl;

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}

export async function getContracts({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    const companyId = getCompanyId();
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: companyId,
      },
    };
    const { data } = await api.get<ContractResponse>(
      apiEndpoint + '/get',
      config
    );
    return data;
  } catch (error: any) {
    console.log(
      '🚀 ~ file: ContractService.ts:43 ~ getContracts ~ error:',
      error
    );
    throw error;
  }
}

export async function getContract(contractId: string) {
  const { data } = await api.get<Contract>(apiEndpoint + '/GetById', {
    params: { contractId: contractId },
  });

  return data;
}
export async function getContractByEmployeeId(employeeId: string) {
  try {
    const { data } = await api.get<Contract>(apiEndpoint + '/GetByEmployeeId', {
      params: { employeeId: employeeId },
    });

    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateContractByEmployeeId(contract: Contract) {
  const body = { ...contract };

  try {
    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  } catch (error) {
    console.log(
      '🚀 ~ file: ContractService.ts:83 ~ saveContract ~ error:',
      error
    );
    throw error;
  }
}

export async function saveContract(contract: Contract) {
  if (contract.id) {
    const body = { ...contract };

    try {
      const response = await api.put(apiEndpoint + '/put', body);
      return response;
    } catch (error) {
      throw error;
    }
  }

  try {
    const response = await api.post(apiEndpoint + '/post', contract);
    return response;
  } catch (error: any) {
    console.log(error.response);
    throw error;
  }
}

export function deleteContract(contractId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: contractId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
