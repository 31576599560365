import { ThemeEnum } from '@/enums/ThemeEnum';
import api from '@/utils/api';
import { UserSettingsUrl } from '@/variables/Urls';

const apiEndpoint = UserSettingsUrl;

export interface UserSetting {
  employeeId?: string;
  theme: ThemeEnum;
  languageCode: string;
}
export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getUserSetting(employeeId: string) {
  let config = {
    params: {
      employeeId: employeeId,
    },
  };
  const { data } = await api.get(
    apiEndpoint + '/GetSettingByEmployeeId',
    config
  );
  return data as UserSetting;
}

export async function saveUserSetting(userSetting: UserSetting) {
  const response_1 = await api.post(apiEndpoint + '/post', userSetting);
  return response_1;
}
