import { EmployeesResponse } from '@/services/EmployeeService';
import { ApexOptions } from 'apexcharts';
import React, { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Event, OffDaysType, ResourceType, WeekendsType } from './TimeLine';
import { useTranslation } from 'react-i18next';
import { eachDayOfInterval, format } from 'date-fns';
import moment from 'moment';

const options: ApexOptions = {
  colors: ['#3C50E0', '#80CAEE'],
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'bar',
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '25%',
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: '25%',
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'last',
    },
  },
  dataLabels: {
    enabled: false,
  },

  xaxis: {
    categories: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    fontFamily: 'Satoshi',
    fontWeight: 500,
    fontSize: '14px',

    markers: {
      radius: 99,
    },
  },
  fill: {
    opacity: 1,
  },
};

export interface ChartTwoState {
  series: {
    name: string;
    data: number[];
  }[];
}

export interface EmployeesAbsenseChart {
  data: ChartTwoState;
  categoriesXaxis: any[];
}
export interface EmployeesAbsenseChartProps {
  employees: EmployeesResponse | undefined;
  resources: ResourceType[];
  events: Event[];
  selectedDate: Date;
  holidays?: OffDaysType[];
  enterpriseClosing?: OffDaysType[];
  weekendDays?: WeekendsType[];
}

const EmployeesAbsenseChart = ({
  employees,
  resources,
  events,
  selectedDate,
  holidays,
  enterpriseClosing,
  weekendDays,
}: EmployeesAbsenseChartProps) => {
  const { t } = useTranslation();
  const daysInMonth = useMemo(
    () =>
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      ).getDate(),

    [selectedDate]
  );

  const daysArray = useMemo(
    () =>
      new Array(daysInMonth).fill(null).map((_, index) => {
        const currentDate = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          index + 1
        );
        return {
          date: currentDate,
          index: index + 1,
        };
      }),
    [selectedDate, daysInMonth]
  );

  const shortDayNames = useMemo(() => {
    return [
      t('Timeline.Sun'),
      t('Timeline.Mon'),
      t('Timeline.Tue'),
      t('Timeline.Wed'),
      t('Timeline.Thu'),
      t('Timeline.Fri'),
      t('Timeline.Sat'),
    ];
  }, [t]);

  const getDaysIndexFromDayString = (dayName: string) => {
    const daysIndex: any[] = [];

    daysArray.forEach((day) => {
      if (day.date) {
        const currentDayName = shortDayNames[day.date.getDay()];

        // Check if the current day name matches the provided input
        if (currentDayName === dayName) {
          daysIndex.push(day.index);
        }
      }
    });

    return daysIndex;
  };

  const counts = new Array(daysInMonth).fill(0);
  // Logic to calculate event counts for each day
  const employeeAbsenseCountsPerDay = useMemo(() => {
    if (employees && events) {
      resources.forEach((resource) => {
        const countedDays = new Set();

        const employeeHolidays = holidays?.filter(
          (x) => x.employeeId === resource.id
        );

        const enterpriseClosings = enterpriseClosing?.filter(
          (x) => x.employeeId === resource.id
        );

        // console.log('Enterprise Closings:', enterpriseClosings);

        employeeHolidays?.forEach((holiday) => {
          const startDate = new Date(holiday.fromDate);
          const endDate = new Date(holiday.toDate);

          const daysInRange = eachDayOfInterval({
            start: startDate,
            end: endDate,
          });

          daysInRange.forEach((currentDate) => {
            const day = currentDate.getDate();

            // Check if currentDate is in the same month as selectedDate
            if (
              currentDate.getMonth() === selectedDate.getMonth() &&
              currentDate.getFullYear() === selectedDate.getFullYear()
            ) {
              if (!countedDays.has(day)) {
                counts[day - 1]++;
                countedDays.add(day);
              }
            }
          });
        });

        enterpriseClosings?.forEach((closing) => {
          const startDate = new Date(closing.fromDate);
          const endDate = new Date(closing.toDate);

          const daysInRange = eachDayOfInterval({
            start: startDate,
            end: endDate,
          });

          daysInRange.forEach((currentDate) => {
            const day = currentDate.getDate();

            // Check if currentDate is in the same month as selectedDate
            if (
              currentDate.getMonth() === selectedDate.getMonth() &&
              currentDate.getFullYear() === selectedDate.getFullYear()
            ) {
              if (!countedDays.has(day)) {
                counts[day - 1]++;
                countedDays.add(day);
              }
            }
          });
        });

        const resourceEvents = events.filter(
          (ev) => ev.resourceId === resource.id
        );
        if (resourceEvents.length > 0) {
        }
        resourceEvents.forEach((event) => {
          const startDate = event.start;
          const endDate = event.end;
          const daysInRange = eachDayOfInterval({
            start: startDate,
            end: endDate,
          });
          console.warn(
            'this is data of events ',
            startDate,
            endDate,
            daysInRange,
            event.resourceId
          );
          daysInRange.forEach((currentDate) => {
            const day = currentDate.getDate();

            // Check if currentDate is in the same month as selectedDate
            if (
              currentDate.getMonth() === selectedDate.getMonth() &&
              currentDate.getFullYear() === selectedDate.getFullYear()
            ) {
              if (!countedDays.has(day)) {
                counts[day - 1]++;
                countedDays.add(day);
              }
            }
          });
        });

        weekendDays?.forEach((weekend) => {
          if (weekend.employeeId === resource.id) {
            weekend.days.forEach((dayName) => {
              // Find the index of the dayName in the weekDays array

              const dayIndex = getDaysIndexFromDayString(dayName);

              for (let index = 0; index < dayIndex.length; index++) {
                if (!countedDays.has(dayIndex[index])) {
                  counts[dayIndex[index] - 1]++;
                  countedDays.add(dayIndex[index]);
                }
              }
            });
          }
        });
      });
    }

    return counts;
  }, [
    employees,
    events,
    selectedDate,
    holidays,
    enterpriseClosing,
    daysInMonth,
    weekendDays,
    resources,
    counts,
  ]);

  // Logic to calculate event counts for each day
  const eventCountsPerDay = useMemo(() => {
    let count: any[] = [];
    if (employees && employeeAbsenseCountsPerDay) {
      count = employeeAbsenseCountsPerDay.map((e) => employees?.count - e);
    }
    return count;
  }, [employees, employeeAbsenseCountsPerDay]);

  // Logic to generate X-axis labels
  const xAxisLabels = useMemo(
    () =>
      new Array(daysInMonth).fill(null).map((_, index) => {
        const currentDate = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          index + 1
        );
        const dayName = shortDayNames[currentDate.getDay()];
        return `${dayName} ${index + 1}`;
      }),
    [selectedDate, t]
  );

  const dataSeries: ChartTwoState = useMemo(() => {
    return {
      series: [
        {
          name: 'Present',
          data: eventCountsPerDay,
        },
        {
          name: 'Absent',
          data: employeeAbsenseCountsPerDay,
        },
      ],
    };
  }, [selectedDate, events, eventCountsPerDay, employeeAbsenseCountsPerDay]);

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
      <div>
        {
          <div id="chartTwo" className="-mb-9 -ml-5">
            <ReactApexChart
              options={{
                ...options,
                xaxis: {
                  categories: xAxisLabels,
                },
                colors: ['#3366FF', '#FF5733'],
              }}
              series={dataSeries.series}
              type="bar" // You can change this to "area" for the area graph
              height={350}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default EmployeesAbsenseChart;
