import { VacationBalancesUrl } from '@/variables/Urls'; // Replace with actual URL
import api from '@/utils/api';
import { AxiosResponse } from 'axios';

export interface VacationBalance {
  VacationId?: string;
  EmployeeId?: string;
  currentDate: string;
  remainingDays: number;
  Comment?: string;
  tokedDays: number;
  totalDays: number;
  vacationTotal?: number;
  vacationName?: string;
}

export interface VacationBalancesResponse {
  leaveBalances: VacationBalance[];
  count: number;
}

const apiEndpoint = VacationBalancesUrl; // Replace with actual URL

export interface PaginatingParams {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
  employeeId?: string;
}

export async function getVacationBalances({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        // Add any other necessary params
      },
    };
    const { data } = await api.get(apiEndpoint + '/Get', config); // Adjust the API endpoint
    return data as VacationBalancesResponse;
  } catch (ex: any) {
    console.log('Error:', ex);
    throw ex;
  }
}
export async function getVacationBalancesByEmployeeId({
  fromvalue = 0,
  takevalue = 10,
  search = '',
  employeeId,
}: PaginatingParams) {
  try {
    const config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetByEmployeeId', config); // Adjust the API endpoint
    return data as VacationBalancesResponse;
  } catch (ex: any) {
    console.log('Error:', ex);
    throw ex;
  }
}

export async function getVacationBalance(vacationId: string) {
  try {
    const { data } = await api.get<VacationBalance>(apiEndpoint + '/GetById', {
      params: { id: vacationId },
    }); // Adjust the API endpoint
    return data;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}

export async function saveVacationBalance(vacationBalance: VacationBalance) {
  if (vacationBalance.VacationId) {
    const body = { ...vacationBalance };
    try {
      const response = await api.put(apiEndpoint + '/put', body); // Adjust the API endpoint
      return response;
    } catch (error) {
      console.log('Error:', error);
      throw error;
    }
  }

  try {
    const response = await api.post(apiEndpoint + '/post', vacationBalance); // Adjust the API endpoint
    return response;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}

export async function deleteVacationBalance(vacationId: string) {
  try {
    const response = await api.delete(apiEndpoint + '/delete', {
      data: { id: vacationId },
    }); // Adjust the API endpoint
    return response;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
}
