import Input from '@/components/Input';
import Spinner from '@/components/Spinner';
import { getEmployee } from '@/services/EmployeeService';
import { RootState } from '@/state';
import { siteName } from '@/variables/Urls';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const userTimeZone = moment.tz.guess();
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
function EmployeeCard() {
  const { t } = useTranslation();
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const isDevelopment = import.meta.env.MODE === 'development';

  const { data: employeeData, isLoading: employeeLoading } = useQuery(
    ['employee', annualInerview?.employeeId],
    () => getEmployee(annualInerview.employeeId),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: !!annualInerview?.employeeId,
    }
  );
  var url = useMemo(
    () =>
      isDevelopment
        ? siteName + employeeData?.profileImgUrl
        : employeeData?.profileImgUrl,
    [employeeData]
  );
  return employeeLoading ? (
    <Spinner />
  ) : (
    <div className="flex w-full ">
      <div className=" w-50">
        <img src={url} className="h-40 w-40 object-contain" />
      </div>
      <div className="grid gap-4 md:grid-cols-2 ">
        <div className="flex flex-col gap-4">
          <Input
            id="fullname"
            name="fullname"
            value={employeeData?.employeeFullName}
            label={t('EmployeeCard.FullName')}
            labelDir="inLine"
            disabled
            labelClassName="w-[40%]"
          />
          <Input
            id="email"
            name="email"
            value={employeeData?.email}
            label={t('EmployeeCard.email')}
            labelDir="inLine"
            disabled
            labelClassName="w-[40%]"
          />
          <Input
            id="branchName"
            name="branchName"
            value={employeeData?.branchName}
            label={t('EmployeeCard.branchName')}
            labelDir="inLine"
            disabled
            labelClassName="w-[40%]"
          />
        </div>
        <div className="flex flex-col gap-4">
          <Input
            id="interviewDate"
            name="interviewDate"
            value={moment
              .utc(annualInerview?.interviewDate ?? new Date())
              .tz(userTimeZone)
              .format('DD/MM/YYYY hh:mm')}
            label={t('EmployeeCard.interviewDate')}
            labelDir="inLine"
            disabled
            labelClassName="w-[40%]"
          />
          <Input
            id="lastinterviewDate"
            name="lastinterviewDate"
            value={moment
              .utc(employeeData?.lastAnnualInterviewDate ?? new Date())
              .tz(userTimeZone)
              .format('DD/MM/YYYY hh:mm')}
            label={t('EmployeeCard.lastinterviewDate')}
            labelDir="inLine"
            labelClassName="w-[40%]"
            disabled
          />
          <Input
            id="hiringDate"
            name="hiringDate"
            value={moment
              .utc(employeeData?.hiringDate)
              .tz(userTimeZone)
              .format('DD/MM/YYYY')}
            label={t('EmployeeCard.hiringDate')}
            labelDir="inLine"
            disabled
            labelClassName="w-[40%]"
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeeCard;
