import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import {
  RequestLeave,
  saveRequestLeave,
} from '@/services/RequestLeavesService';
import { useAuth } from '@/context/AuthContext';
import { getVacationBalancesByEmployeeId } from '@/services/VacationBalanceService';
import {
  QueryClient,
  QueryKey,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import {
  EditButton,
  LabelButton,
  PrimaryButton,
} from '@/components/shared/Button';
import TextArea from '@/components/TextArea';
import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays } from 'date-fns';
import { default as DefaultInput } from '@/components/Input';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { dateWithoutTimezone } from '@/utils/helpers';
import { kebabCase } from 'lodash';
import {
  FreeDaysModel,
  getFreeDaysByEmployeeId,
} from '@/services/WorkCalendarsService';

const RequestALeaveSchema = Yup.object().shape({
  fromDate: Yup.date().required('RequestLeaveModal.fromDateRequired'),
  toDate: Yup.date()
    .min(Yup.ref('fromDate'), 'RequestLeaveModal.endDateMin')
    .required('RequestLeaveModal.endDateRequired'),
  vacationId: Yup.string().required('RequestLeaveModal.vacationRequired'),
});
interface RequestALeaveModalProps {
  onSaveWithSendingToValidate: () => void;
  onSaveOnly: () => void;
  isOpen: boolean;
  onCancel: () => void;
  isTransferRequest?: boolean;
}

const RequestALeaveModal = ({
  onSaveWithSendingToValidate,
  onSaveOnly,
  isOpen,
  onCancel,
  isTransferRequest = false,
}) => {
  {
    /* Modal props */
  }
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [modalClass, setModalClass] = useState('modal-hide');

  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    setModalClass((prev) => (prev ? 'modal-show' : 'modal-hide'));
  }, [isOpen]);
  {
    /* Modal props */
  }
  const initialValues: RequestLeave = {
    fromDate: new Date(),
    toDate: new Date(),
    vacationId: '',
  };

  const { user } = useAuth();

  const { data: vacationBalancesData, isLoading: vacationBalancesLoading } =
    useQuery(
      ['vacationsBalances', user?.id],
      () =>
        getVacationBalancesByEmployeeId({
          fromvalue: 0,
          takevalue: 0,
          search: '',
          employeeId: user?.id,
        }),
      {
        refetchOnWindowFocus: false,
        staleTime: 6000,
        keepPreviousData: true,
      }
    );

  const handleSubmit = async (
    values: RequestLeave,
    { resetForm, validateForm, submitForm, action }: any
  ) => {};

  const handleSaveAndSend = async (
    values: RequestLeave,
    { resetForm, validateForm, submitForm, action }: any
  ) => {
    setLoading(true);
    const errors = await validateForm();
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      return;
    }
    try {
      let requestLeave: RequestLeave = {
        ...values,
        employeeId: user?.id,
        isDraft: false,
        fromDate: moment(values.fromDate)
          .utcOffset(0, true)
          .startOf('day')
          .toDate(),
        toDate: moment(values.toDate)
          .utcOffset(0, true)
          .startOf('day')
          .toDate(),
      };

      const data = await saveRequestLeave(requestLeave);
      if (data) {
        toast.success(
          t('SuccessMessage', { name: t('RequestLeaveModal.requestLeave') })
        );

        queryClient.invalidateQueries(['EmployeeRequestLeaves']);
      }
    } catch (error) {
      toast.error(
        t('ErrorMessage', { name: t('RequestLeaveModal.requestLeave') })
      );
    } finally {
      setLoading(false);
      resetForm();
      onCancel();
    }
  };

  const handleSave = async (
    values: RequestLeave,
    { resetForm, validateForm, submitForm }: any
  ) => {
    setLoading(true);
    const errors = await validateForm();
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      return;
    }

    try {
      let requestLeave: RequestLeave = {
        ...values,
        employeeId: user?.id,
        isDraft: true,
        fromDate: moment(values.fromDate)
          .utcOffset(0, true)
          .startOf('day')
          .toDate(),
        toDate: moment(values.toDate)
          .utcOffset(0, true)
          .startOf('day')
          .toDate(),
      };

      const data = await saveRequestLeave(requestLeave);
      if (data) {
        toast.success(
          t('SuccessMessage', {
            name: t('RequestLeaveModal.requestLeave'),
          })
        );
        queryClient.invalidateQueries(['EmployeeRequestLeaves']);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
      onCancel();
    }
  };

  const {
    data: freeDaysData,
    isLoading,
    isSuccess,
    refetch: refetchFreeDays,
  } = useQuery<FreeDaysModel | undefined>(
    [
      'FreeDays',
      {
        month: new Date(selectedDate).getMonth() + 1,
        year: new Date(selectedDate).getFullYear(),
        employeeId: user?.id,
      },
    ],
    //@ts-noCheck
    async () => {
      return await getFreeDaysByEmployeeId({
        employeeId: user?.id!,
        month: selectedDate.getMonth() + 1,
        year: selectedDate.getFullYear(),
      });
    },
    {
      enabled: !!user,
      refetchOnWindowFocus: false,
      staleTime: 8000,
      keepPreviousData: true,
    }
  );

  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className={`fixed left-0  right-0 top-0  z-[1055]  flex h-full w-full items-center  justify-center overflow-y-auto  bg-gray-800 bg-opacity-75 ${modalClass} `}
    >
      <div className=" max-h-200 relative  w-150 overflow-y-scroll  rounded-lg bg-white  shadow-md dark:bg-gray-700">
        <button
          type="button"
          className="absolute right-2.5 top-2  inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={onCancel}
        >
          <svg
            className="h-3 w-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">{t('RequestLeaveModal.closeModal')}</span>
        </button>
        <div className="rounded-t border-b px-6 py-2 dark:border-gray-600">
          <h3 className="text-base font-semibold text-gray-900 dark:text-white lg:text-xl">
            {t('RequestLeaveModal.requestLeave')}
          </h3>
        </div>
        {vacationBalancesLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={RequestALeaveSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              resetForm,
              validateForm,
              setFieldValue,
              submitForm,
            }) => (
              <Form className="p-5">
                <div className="group  mb-6 w-full">
                  <Dropdown
                    id="vacationId"
                    name="vacationId"
                    optionValue="vacationId"
                    optionLabel="vacationName"
                    onChange={(e) => {
                      setFieldValue('vacationId', e.target.value);
                    }}
                    optionTransalatedKey="vacationName"
                    isTranslated={true}
                    translatedNameSpace="VacationType"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={vacationBalancesData?.leaveBalances ?? []}
                    label={t('RequestLeaveModal.vacation')}
                    error={errors.vacationId}
                    value={values.vacationId}
                  />
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group  mb-6 w-full">
                    <DatePickerDefault
                      id="fromDate"
                      value={values.fromDate}
                      name="fromDate"
                      label={t('RequestLeaveModal.fromDate')}
                      defaultDate={values.fromDate}
                      onChange={(date: any) => {
                        handleChange({
                          target: {
                            name: 'fromDate',
                            value: date,
                          },
                        });
                        var newDate = new Date(date);
                        setSelectedDate((pe) => newDate);
                      }}
                      errors={errors.fromDate}
                      freeDays={freeDaysData}
                    />
                  </div>
                  <div className="group  mb-6 w-full">
                    <DatePickerDefault
                      id="toDate"
                      value={values.toDate}
                      name="toDate"
                      label={t('RequestLeaveModal.toDate')}
                      defaultDate={values.toDate}
                      onChange={(date: any) => {
                        handleChange({
                          target: {
                            name: 'toDate',
                            value: date,
                          },
                        });
                        var newDate = new Date(date);

                        setSelectedDate(newDate);
                      }}
                      freeDays={freeDaysData}
                      errors={errors.toDate}
                    />
                  </div>
                </div>
                <div className="group relative  mb-6 w-full">
                  <DefaultInput
                    id="amount"
                    name="amount"
                    labelClassName="w-[40%]"
                    label={t('RequestLeaveModal.amount')}
                    value={
                      differenceInCalendarDays(
                        new Date(values.toDate),
                        new Date(values.fromDate)
                      ) + 1
                    }
                    readOnly
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  <TextArea
                    id="note"
                    name="note"
                    label={t('RequestLeaveModal.note')}
                    errors={errors}
                    value={values.note}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex justify-between">
                  <LabelButton type="button" onClick={onCancel}>
                    {t('RequestLeaveModal.close')}
                  </LabelButton>
                  <div className="m-0 flex items-center justify-between p-0">
                    <PrimaryButton
                      disabled={loading}
                      type="button"
                      onClick={(e) =>
                        handleSaveAndSend(values, {
                          resetForm,
                          validateForm,
                          submitForm,
                          action: 'saveAndSend',
                        })
                      }
                    >
                      {t('RequestLeaveModal.saveAndSend')}
                    </PrimaryButton>
                    <EditButton
                      className="mb-0"
                      type="button"
                      onClick={(e) =>
                        handleSave(values, {
                          resetForm,
                          validateForm,
                          submitForm,
                          action: 'save',
                        })
                      }
                    >
                      {t('RequestLeaveModal.save')}
                    </EditButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default RequestALeaveModal;
