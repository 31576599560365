import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { saveRisk, getRisk, Risk } from '@/services/RisksService';
import Dropdown from '@/components/DropDown';
import moment from 'moment';
import TextArea from '@/components/TextArea';
import { useAuth } from '@/context/AuthContext';
import { RiskType, RiskTypeOptions } from '@/enums/RiskType';
import { InterfaceType, InterfaceTypeOptions } from '@/enums/InterfaceType';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';

const validationSchema = Yup.object().shape({
  riskCategory: Yup.string().required('RiskForm.riskCategoryRequired'),
  riskType: Yup.string().required('RiskForm.riskTypeRequired'),
  preMitigationProbability: Yup.number().required(
    'RiskForm.preMitigationProbabilityRequired'
  ),
  preMitigationImpact: Yup.number().required(
    'RiskForm.preMitigationImpactRequired'
  ),
  preMitigationRiskScore: Yup.number().required(
    'RiskForm.preMitigationRiskScoreRequired'
  ),
  preMitigationRiskMatrix: Yup.string().required(
    'RiskForm.preMitigationRiskMatrixRequired'
  ),
  mitigationMeasures: Yup.string().required(
    'RiskForm.mitigationMeasuresRequired'
  ),
  postMitigationProbability: Yup.number().required(
    'RiskForm.postMitigationProbabilityRequired'
  ),
  postMitigationImpact: Yup.number().required(
    'RiskForm.postMitigationImpactRequired'
  ),
  postMitigationRiskScore: Yup.number().required(
    'RiskForm.postMitigationRiskScoreRequired'
  ),
  postMitigationRiskMatrix: Yup.string().required(
    'RiskForm.postMitigationRiskMatrixRequired'
  ),
  responsiblePersonId: Yup.string().required(
    'RiskForm.responsiblePersonRequired'
  ),
  currentState: Yup.string().required('RiskForm.currentStateRequired'),
  identificationDate: Yup.date().required(
    'RiskForm.identificationDateRequired'
  ),
  reevaluationDate: Yup.date().required('RiskForm.reevaluationDateRequired'),
});

const userTimeZone = moment.tz.guess();

function RiskForm() {
  const { riskId, id } = useParams();
  const editForm = riskId !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [initialValues, setInitialValues] = useState<Risk>({
    riskDescription: '',
    riskCategory: InterfaceType.Organizational,
    riskType: RiskType.Risk,
    preMitigationProbability: 0,
    preMitigationImpact: 0,
    preMitigationRiskScore: 0,
    preMitigationRiskMatrix: '',
    mitigationMeasures: '',
    postMitigationProbability: 0,
    postMitigationImpact: 0,
    postMitigationRiskScore: 0,
    postMitigationRiskMatrix: '',
    responsiblePersonId: '',
    currentState: '',
    identificationDate: new Date(),
    reevaluationDate: new Date(),
    comments: '',
    projectId: id!,
  });

  const {
    data: riskData,
    isLoading,
    refetch: refetchRisk,
    isSuccess,
  } = useQuery<Risk | undefined>(['risk', riskId], async () => {
    if (editForm) {
      const response = await getRisk(riskId!);
      return response;
    }
    return undefined;
  });

  var isResponsible = useMemo(
    () =>
      editForm && riskData ? riskData?.responsiblePersonId === user?.id : false,
    [riskData, user, editForm]
  );

  useEffect(() => {
    if (editForm && riskData) {
      setInitialValues((prev) => ({
        ...prev,
        ...riskData,
        identificationDate: moment.utc(riskData.identificationDate).toDate(),
        reevaluationDate: moment.utc(riskData.reevaluationDate).toDate(),
      }));
    }
  }, [editForm, riskData]);

  const handleSaveRisk = async (values: Risk, { resetForm }: any) => {
    try {
      const response = await saveRisk(values);
      if (response) {
        toast.success(
          t('SuccessMessage', {
            name: t('ToastMsg.Risk'),
          })
        );
        navigate(-1);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const { data: employeesData, isLoading: isLoadingEmployees } = useQuery<
    EmployeesResponse | undefined
  >(
    ['employees'],
    async () =>
      await getEmployees({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={editForm ? t('RiskForm.editRisk') : t('RiskForm.addRisk')}
      />
      {isLoading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveRisk}
          enableReinitialize={true}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="riskDescription"
                    id="riskDescription"
                    label={t('RiskForm.riskDescriptionLabel')}
                    value={values.riskDescription}
                    onChange={handleChange}
                    error={errors.riskDescription}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  {/* <Dropdown
                    id="riskCategory"
                    name="riskCategory"
                    label={t('RiskForm.riskCategoryLabel')}
                    value={values.riskCategory}
                    onChange={handleChange}
                    error={errors.riskCategory}
                    options={[]}
                    optionValue="id"
                    optionLabel="name"
                    labelClassName="w-[50%]"
                  /> */}
                  <Dropdown
                    id="riskCategory"
                    name="riskCategory"
                    label={t('RiskForm.riskCategoryLabel')}
                    value={values.riskCategory}
                    translatedNameSpace="InterfaceType"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    onChange={handleChange}
                    labelClassName="pl-0 w-full"
                    error={errors.riskCategory}
                    options={InterfaceTypeOptions ?? []}
                  />
                </div>
                <div className="mb-4">
                  {/* <Dropdown
                    id="riskType"
                    name="riskType"
                    label={t('RiskForm.riskTypeLabel')}
                    value={values.riskType}
                    onChange={handleChange}
                    error={errors.riskType}
                    options={[]}
                    optionValue="id"
                    optionLabel="name"
                    labelClassName="w-[50%]"
                  /> */}
                  <Dropdown
                    id="riskType"
                    name="riskType"
                    label={t('RiskForm.riskTypeLabel')}
                    value={values.riskType}
                    translatedNameSpace="RiskType"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    onChange={handleChange}
                    labelClassName="pl-0 w-full"
                    error={errors.riskType}
                    options={RiskTypeOptions ?? []}
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="preMitigationProbability"
                    id="preMitigationProbability"
                    label={t('RiskForm.preMitigationProbabilityLabel')}
                    value={values.preMitigationProbability.toString()}
                    onChange={handleChange}
                    error={errors.preMitigationProbability}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="preMitigationImpact"
                    id="preMitigationImpact"
                    label={t('RiskForm.preMitigationImpactLabel')}
                    value={values.preMitigationImpact.toString()}
                    onChange={handleChange}
                    error={errors.preMitigationImpact}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="preMitigationRiskScore"
                    id="preMitigationRiskScore"
                    label={t('RiskForm.preMitigationRiskScoreLabel')}
                    value={values.preMitigationRiskScore.toString()}
                    onChange={handleChange}
                    error={errors.preMitigationRiskScore}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="preMitigationRiskMatrix"
                    id="preMitigationRiskMatrix"
                    label={t('RiskForm.preMitigationRiskMatrixLabel')}
                    value={values.preMitigationRiskMatrix}
                    onChange={handleChange}
                    error={errors.preMitigationRiskMatrix}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <TextArea
                    name="mitigationMeasures"
                    id="mitigationMeasures"
                    label={t('RiskForm.mitigationMeasuresLabel')}
                    value={values.mitigationMeasures}
                    onChange={handleChange}
                    error={errors.mitigationMeasures}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="postMitigationProbability"
                    id="postMitigationProbability"
                    label={t('RiskForm.postMitigationProbabilityLabel')}
                    value={values.postMitigationProbability.toString()}
                    onChange={handleChange}
                    error={errors.postMitigationProbability}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="postMitigationImpact"
                    id="postMitigationImpact"
                    label={t('RiskForm.postMitigationImpactLabel')}
                    value={values.postMitigationImpact.toString()}
                    onChange={handleChange}
                    error={errors.postMitigationImpact}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="number"
                    name="postMitigationRiskScore"
                    id="postMitigationRiskScore"
                    label={t('RiskForm.postMitigationRiskScoreLabel')}
                    value={values.postMitigationRiskScore.toString()}
                    onChange={handleChange}
                    error={errors.postMitigationRiskScore}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="postMitigationRiskMatrix"
                    id="postMitigationRiskMatrix"
                    label={t('RiskForm.postMitigationRiskMatrixLabel')}
                    value={values.postMitigationRiskMatrix}
                    onChange={handleChange}
                    error={errors.postMitigationRiskMatrix}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <Dropdown
                    id="responsiblePersonId"
                    name="responsiblePersonId"
                    label={t('RiskForm.responsiblePersonLabel')}
                    value={values.responsiblePersonId}
                    onChange={handleChange}
                    error={errors.responsiblePersonId}
                    options={employeesData?.employees ?? []}
                    optionValue="id"
                    optionLabel="email"
                    labelClassName="w-[50%]"
                  />

                  {/* <DefaultInput
                    type="text"
                    id="responsiblePerson"
                    name="responsiblePerson"
                    label={t('RiskForm.responsiblePersonLabel')}
                    value={values.responsiblePerson}
                    onChange={handleChange}
                    error={errors.responsiblePerson}
                    labelClassName="pl-0 w-full"
                  /> */}

                  {/* <Dropdown
                    id="responsiblePerson"
                    name="responsiblePerson"
                    label={t('RiskForm.responsiblePersonLabel')}
                    value={values.responsiblePerson}
                    optionValue="id"
                    optionLabel="email"
                    onChange={handleChange}
                    options={employeesData?.employees ?? []}
                    labelClassName="w-[50%]"
                    error={errors.responsiblePerson}
                    labelClassName="pl-0 w-full"
                  /> */}
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="text"
                    name="currentState"
                    id="currentState"
                    label={t('RiskForm.currentStateLabel')}
                    value={values.currentState}
                    onChange={handleChange}
                    error={errors.currentState}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="date"
                    name="identificationDate"
                    id="identificationDate"
                    label={t('RiskForm.identificationDateLabel')}
                    value={moment(values.identificationDate).format(
                      'YYYY-MM-DD'
                    )}
                    onChange={handleChange}
                    error={errors.identificationDate}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <DefaultInput
                    type="date"
                    name="reevaluationDate"
                    id="reevaluationDate"
                    label={t('RiskForm.reevaluationDateLabel')}
                    value={moment(values.reevaluationDate).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    error={errors.reevaluationDate}
                    labelClassName="pl-0 w-full"
                  />
                </div>
                <div className="mb-4">
                  <TextArea
                    name="comments"
                    id="comments"
                    label={t('RiskForm.commentsLabel')}
                    value={values.comments}
                    onChange={handleChange}
                    error={errors.comments}
                    labelClassName="pl-0 w-full"
                  />
                </div>
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                >
                  {t('RiskForm.submitButton')}
                </button>
                <button
                  type="button"
                  className="focus:shadow-outline ml-4 rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400 focus:outline-none"
                  onClick={() => {
                    resetForm();
                    navigate(-1);
                  }}
                >
                  {t('RiskForm.cancelButton')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default RiskForm;
