import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  JournalEntryResponse,
  deleteJournalEntry,
  getJournalEntriesByProjectId,
} from '@/services/JournalEntriesService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const userTimeZone = moment.tz.guess();

function JournalEntries() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('JournalEntries.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('JournalEntries.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteJournalEntry.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('JournalEntries.subjectColumn'),
        accessor: 'subject',
      },
      {
        Header: t('JournalEntries.typeColumn'),
        accessor: 'type',
      },
      {
        Header: t('JournalEntries.dateAddedColumn'),
        accessor: 'dateAdded',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD HH:mm');
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('JournalEntries.commentsColumn'),
        accessor: 'comments',
      },
      {
        Header: t('JournalEntries.projectTitleColumn'),
        accessor: 'projectTitle',
      },
      {
        Header: t('JournalEntries.authorNameColumn'),
        accessor: 'authorName',
      },
      {
        Header: t('JournalEntries.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => <ActionButtonsCell value={value} />,
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: journalEntries,
  } = useQuery<JournalEntryResponse | undefined>(
    ['journalEntries', pageIndex, pageSize, search, id],
    () =>
      getJournalEntriesByProjectId({
        projectId: id!,
        page: pageIndex,
        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      //   enabled: id !== null,
    }
  );

  const handleDeleteJournalEntry = useMutation(
    async (id: string) => {
      await deleteJournalEntry(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'journalEntries',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((journalEntries?.journalEntries.length ?? 0) / pageSize);
  }, [pageSize, journalEntries]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('JournalEntries.pageName')}
        addbtn={true}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={journalEntries?.journalEntries.length ?? 0}
            data={journalEntries?.journalEntries ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default JournalEntries;
