import { RatingState, getRatingImage } from '@/enums/RatingState';
import { EmployeeDataModel } from '@/services/RapportsServices';
import React, { useMemo } from 'react';
import happy from '@/images/emoji/happy.png';
import sad from '@/images/emoji/sad.png';
import neutral from '@/images/emoji/neutral.png';
import { siteName } from '@/variables/Urls';
import { useAuth } from '@/context/AuthContext';
import { saveRating } from '@/services/RatingService';
// Adjust the import path as needed

interface IRatingCompanyProps {
  employee: EmployeeDataModel;
  onSave: Function;
}

function RatingCompany({ employee, onSave }: IRatingCompanyProps) {
  const { user } = useAuth();
  // Sample avatar and small images (replace with your image paths)
  const avatarImage = employee.profileImgUrl;
  const smallImg = getRatingImage(
    employee.rating ?? RatingState.PassiveEmployees
  );
  const isDevelopment = import.meta.env.MODE === 'development';

  var url = useMemo(
    () => (isDevelopment ? siteName + avatarImage : avatarImage),
    [avatarImage]
  );
  const handleChooseRating = async (rate: RatingState) => {
    if (user) {
      const data = await saveRating({
        ratingValue: rate,
        employeeId: user.id,
      });
      if (data) {
        onSave();
      }
    }
  };

  return (
    <div className="flex items-center justify-between ">
      {/* Left side with avatar and small rounded image */}
      <div className="relative flex-shrink-0">
        <img
          className="h-25 w-25 rounded-full border-2 border-white object-cover dark:border-0"
          src={url}
          alt="Employee Avatar"
        />
        <img
          src={smallImg}
          className="absolute right-0 top-0 h-6 w-6 rounded-full border-2 border-white bg-green-500 dark:border-0"
        ></img>
      </div>

      {/* Right side with small images as buttons */}
      <div className="ml-4 flex space-x-2">
        <img
          className="h-18 w-18 cursor-pointer rounded border-2 border-white object-cover dark:border-0 "
          src={sad}
          alt={`sad`}
          onClick={() => handleChooseRating(RatingState.DissatisfiedEmployees)}
        />
        <img
          className="h-18 w-18 cursor-pointer rounded border-2 border-white object-cover dark:border-0"
          src={neutral}
          alt={`neutral`}
          onClick={() => handleChooseRating(RatingState.PassiveEmployees)}
        />
        <img
          className="h-18 w-18 cursor-pointer rounded border-2 border-white object-cover dark:border-0"
          src={happy}
          alt={`happy`}
          onClick={() => handleChooseRating(RatingState.SatisfiedEmployees)}
        />
      </div>
    </div>
  );
}

export default RatingCompany;
