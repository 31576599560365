import {
  BranchesUrl,
  CompaniesUrl,
  CompetitorsUrl,
  DeparetmentsUrl,
} from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Departement } from './DepartementService';
import { Partner } from './PartnerService';

export interface Competitor extends Partner {}

export interface CompetitorsResponse {
  competitors: Competitor[];
  count: number;
}
const apiEndpoint = CompetitorsUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getCompetitors({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as CompetitorsResponse;
}

export async function getCompetitor(competitorId: string) {
  const { data } = await api.get<Competitor>(apiEndpoint + '/GetById', {
    params: { id: competitorId },
  });

  return data;
}

export async function saveCompetitor(Competitor: Competitor) {
  if (Competitor.id) {
    const body = { ...Competitor };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', Competitor);
  return response_1;
}

export function deleteCompetitor(competitorId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: competitorId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
