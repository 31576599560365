export enum EquipmentCategory {
  Installation,
  Equipment,
  Tools,
  Consommables,
  Others,
}

export const EquipmentCategoryOptions = Object.keys(EquipmentCategory)
  .filter((key) => isNaN(Number(EquipmentCategory[key])))
  .map((key) => ({
    id: key,
    name: EquipmentCategory[key],
  }));
