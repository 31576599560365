import Accordion from '@/components/Accordian';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import { UserRoles } from '@/enums/UsersRole';
import DefaultLayout from '@/layout/DefaultLayout';
import { Pie3D } from 'react-pie3d';

import {
  EmployeesResponse,
  getEmployeesByTeamLeaderId,
  getEmployees,
} from '@/services/EmployeeService';
import { siteName } from '@/variables/Urls';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { GenderEnum } from '@/enums/genderEnum';
import { ContractType } from '@/enums/contractType';
import {
  RHRapportsModel,
  TrainingRapports,
  getRHRapports,
  getTrainingsRapports,
} from '@/services/RapportsServices';
import Spinner from '@/components/Spinner';
import { Chart, ChartWrapperOptions } from 'react-google-charts';
import PieChart from './components/PieChart';
import {
  TrainingRequest,
  TrainingRequestResponse,
  getTrainingRequests,
} from '@/services/TrainingRequestService';
import moment from 'moment';
import Dropdown from '@/components/DropDown';
import { getYearOptions } from '@/variables/const';

function FormationIndicators() {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedYear, setSelectedYear] = useState<Option>();

  const [yearOptions, setYearOptions] = useState(getYearOptions());

  const [search, setSearch] = useState<string>('');
  const isDevelopment = import.meta.env.MODE === 'development';

  const AvatarCell = ({ cell: { value } }: any) => {
    var url = isDevelopment ? siteName + value : value;
    return (
      <div className="w-10">
        <img
          className="h-10 w-10 rounded-full object-cover"
          src={url}
          alt="Profile Avatar"
        />
      </div>
    );
  };
  const {
    isLoading,
    isError,
    data: trainingRequest,
  } = useQuery<TrainingRequestResponse | undefined>(
    ['trainingsRequest', selectedYear?.id],
    () => {
      return getTrainingRequests({
        fromvalue: 0,
        takevalue: 0,
        search: search,
        year: selectedYear?.id,
        isFinished: true,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  const {
    isLoading: isRapportsLaoding,
    isError: isRapportError,
    data: rapports,
  } = useQuery<TrainingRapports | undefined>(
    ['TrainignRapports', selectedYear?.id],
    () => {
      return getTrainingsRapports({ year: selectedYear?.id });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: t('TrainingRequests.employeeFullName'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.requestedTrainingTitleColumn'),
        accessor: 'requestedTrainingTitle',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.trainingProviderName'),
        accessor: 'trainingProviderName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.trainingManagerName'),
        accessor: 'trainingManagerName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.trainingProviderPhoneNumber'),
        accessor: 'trainingProviderPhoneNumber',
      },
      {
        Header: t('TrainingRequests.trainingProviderAddress'),
        accessor: 'trainingProviderAddress',
      },
      {
        Header: t('TrainingRequests.trainingStartDate'),
        accessor: 'trainingStartDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('TrainingRequests.trainingEndDate'),
        accessor: 'trainingEndDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('TrainingRequests.remarks'),
        accessor: 'remarks',
      },
      {
        Header: t('TrainingRequests.obtainedDiplomaTitle'),
        accessor: 'obtainedDiplomaTitle',
      },
      {
        Header: t('TrainingRequests.satisfactionFormSent'),
        accessor: 'satisfactionFormSent',
        Cell: ({ cell: { value } }) => {
          const frontendState = value ? 'bg-green-400' : 'bg-red-400';

          return (
            <div
              className={`rounded-md text-center ${frontendState} px-2.5  py-2.5 text-sm text-white`}
            >
              {value ? 'Yes' : 'No'}
            </div>
          );
        },
      },
    ],
    [t]
  );
  const handleYearChange = (event: any) => {
    const selectedYearId = event.target.value;

    const selectedYearObj = yearOptions.find((tem) => tem.id == selectedYearId);

    // Update the inputRef value
    setSelectedYear(selectedYearObj);
  };
  // const [genderGraphData, setGenderGraphData] = useState<GraphDataItem[]>([
  //   { value: 0, label: 'M', color: '#8E80FF' },
  //   { value: 0, label: 'F', color: '#F669F5' },
  //   { value: 0, label: 'NA', color: '#B6A6B5' },
  // ]);
  // const [nationalitiesData, setNationalitiesData] = useState<GraphDataItem[]>(
  //   []
  // );

  // const generateRandomColor = () => {
  //   return '#' + Math.floor(Math.random() * 16777215).toString(16);
  // };

  // const [contractTypes, setContractType] = useState<GraphDataItem[]>(
  //   Object.keys(ContractType).map((key, index) => ({
  //     value: 0,
  //     label: key,
  //     color: generateRandomColor(), // You need to define/get a function like this for generating random colors
  //   }))
  // );
  // const genderIndicatorGraph = useMemo(() => {
  //   const updatedData = [...genderGraphData]; // Create a copy of the state
  //   const updatedNationalityData = [...nationalitiesData];
  //   const updatedContractTypeData = [...contractTypes];

  //   employees?.employees.forEach((employee) => {
  //     const nationality = employee.nationality;

  //     if (nationality) {
  //       const foundNationality = updatedNationalityData.find(
  //         (x) => x.label === nationality
  //       );
  //       if (!foundNationality) {
  //         updatedNationalityData.push({
  //           value: 1,
  //           label: nationality,
  //           color: generateRandomColor(),
  //         });
  //       } else {
  //         foundNationality.value++;
  //       }
  //     }
  //     switch (employee.contractType) {
  //       case ContractType.FixedTermContract:
  //         updatedContractTypeData.find(
  //           (x) => x.label === ContractType.FixedTermContract.toString()
  //         )!.value += 1;
  //         break;
  //       case ContractType.TemporaryWork:
  //         updatedContractTypeData.find(
  //           (x) => x.label === ContractType.TemporaryWork.toString()
  //         )!.value += 1;
  //         break;
  //       case ContractType.Apprentice:
  //         updatedContractTypeData.find(
  //           (x) => x.label === ContractType.Apprentice.toString()
  //         )!.value += 1;
  //         break;
  //       case ContractType.Consultant:
  //         updatedContractTypeData.find(
  //           (x) => x.label === ContractType.Consultant.toString()
  //         )!.value += 1;
  //         break;
  //       case ContractType.Internship:
  //         updatedContractTypeData.find(
  //           (x) => x.label === ContractType.Internship.toString()
  //         )!.value += 1;
  //         break;
  //       case ContractType.Other:
  //         updatedContractTypeData.find(
  //           (x) => x.label === ContractType.Other.toString()
  //         )!.value += 1;
  //         break;

  //       default:
  //         break;
  //     }

  //     switch (employee.gender) {
  //       case GenderEnum.Male:
  //         updatedData.find((x) => x.label === 'M')!.value += 1;
  //         break;
  //       case GenderEnum.Female:
  //         updatedData.find((x) => x.label === 'F')!.value += 1;
  //         break;
  //       case GenderEnum.NoAnswer:
  //         updatedData.find((x) => x.label === 'NA')!.value += 1;
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   setGenderGraphData(updatedData);
  //   setNationalitiesData(updatedNationalityData);
  //   setContractType(updatedContractTypeData);
  // }, [employees]);

  const data = [
    { value: 10, label: 'apples', color: 'red' },
    { value: 20, label: 'bananas', color: 'green' },
    { value: 30, label: 'oranges', color: 'blue' },
  ];
  const pieConfig = {
    ir: 0,
    strokeWidth: 0,
    angle: 50,
    size: 1,
    showLabelPercentage: true,
    showLabels: true,
    showTooltips: true,
  };
  const data14 = [
    ['Task', 'Hours per Day'],
    ['Work', 11],
    ['Eat', 2],
    ['Commute', 2],
    ['Watch TV', 2],
    ['Sleep', 7],
  ];

  const options: ChartWrapperOptions['options'] = {
    title: 'My Daily Activities',
    width: 450,
    height: 400,
    is3D: true,
    // legend: {
    //   position: 'bottom',
    //   textStyle: {
    //     color: '#000000', // Changing legend text color
    //   },
    // },
    animation: {
      startup: true,
      easing: 'inAndOut',
      duration: 1500,
    },
    backgroundColor: 'transparent',
    // titleTextStyle: {
    //   color: '#000000', // Changing title text color
    // },
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('TrainingIndicatorsPage.breadcrumb')} />
      <Accordion title={t('sidebar.Training')}>
        <DynamicTable
          dataCount={trainingRequest?.count ?? 0}
          data={trainingRequest?.trainingRequests ?? []}
          columns={columns}
          setSearch={setSearch}
          exportPdf={false}
        />
      </Accordion>
      <Accordion title={t('TrainingIndicatorsPage.indicators')}>
        {isRapportsLaoding ? (
          <Spinner />
        ) : (
          <div>
            {/* Dropdown years  */}

            <Dropdown
              label={t('Year')}
              optionValue="id"
              optionLabel="name"
              options={yearOptions}
              labelClassName="w-[50%]"
              containerClass="w-[20%] mx-auto my-5"
              value={selectedYear?.id ?? ''}
              onChange={(e) => handleYearChange(e)}
            />
            <div className="grid gap-2 md:grid-cols-2">
              <PieChart
                data={rapports?.trainingTypeData ?? []}
                options={options}
                title={t('TrainingIndicatorsPage.trainingType')}
                translationNameSpace="TrainingTypes"
              />

              <div>
                <PieChart
                  data={rapports?.trainingHourPerYearData ?? []}
                  options={options}
                  title={t('TrainingIndicatorsPage.trainingHourPerYearData')}
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.trainingCostPerYearData ?? []}
                  options={options}
                  title={t('TrainingIndicatorsPage.trainingCostPerYearData')}
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.trainingCPFData ?? []}
                  options={options}
                  title={t('TrainingIndicatorsPage.trainingCPF')}
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.trainingDemanderData ?? []}
                  options={options}
                  title={t('TrainingIndicatorsPage.trainingDemander')}
                  translationNameSpace="TrainingDemender"
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.trainingDiplomaTitleData ?? []}
                  options={options}
                  title={t('TrainingIndicatorsPage.trainingDiplomaTitle')}
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.trainingForJobApplicationData ?? []}
                  options={options}
                  title={t('TrainingIndicatorsPage.trainingForJobApplication')}
                />
              </div>
            </div>
          </div>
        )}
      </Accordion>
    </DefaultLayout>
  );
}

export default FormationIndicators;
