import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import Input from '@/components/Input';
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import Accordion from '@/components/Accordian';

import TextArea from '@/components/TextArea';

import { useTranslation } from 'react-i18next';
import { useAuth } from '@/context/AuthContext';
import { RootState } from '@/state';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  State,
  Country,
  CountriesResponse,
  getCountries,
} from '@/services/CountryService';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import { fetchProjectExpenseById } from '@/state/slices/projectExpensesSlice';
import { currencyOptions } from '@/enums/Currency';
import ExpensesTable from './components/ExpensesTable';
import { ProjectsResponse, getProjects } from '@/services/ProjectService';
import { ExpensesState } from '@/enums/ExpensesState';
import { stateColors } from '@/enums/RequestLeaveState';
import {
  ProjectExStateChange,
  changeProjectExState,
} from '@/services/ProjectExpensesService';
import { UserRoles } from '@/enums/UsersRole';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { formatCurrency } from '@/utils/helpers';

const ProjectExpenseDetails = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const editForm = id !== 'new';
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { projectExpense, status } = useSelector(
    (state: RootState) => state.projectExpenses
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [states, setStates] = React.useState<State[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [loading, setLoading] = useState(false);

  // const translatedGenderOptions = genderOptions.map((option) => ({
  //   id: option.id,
  //   name: translateOptionName(
  //     t,
  //     'GenderEnum',
  //     option.name.trim().replace(/\s+/g, '')
  //   ),
  // }));

  // const translatedMaritalStatusOptions = maritalStatusOptions.map((option) => ({
  //   id: option.id,
  //   name: translateOptionName(
  //     t,
  //     'MaritalStatusEnum',
  //     option.name.trim().replace(/\s+/g, '')
  //   ),
  // }));

  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      setCountries(data?.countries!);
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000),
    keepPreviousData: true,
  });
  const { data: projects, isLoading: projectsLoading } = useQuery<
    ProjectsResponse | undefined
  >(['projects'], async () => await getProjects({ from: 0, take: 0 }), {
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (editForm && id) {
      //@ts-noCheck
      dispatch(fetchProjectExpenseById(id));
    }
  }, [dispatch, editForm, id]);
  useEffect(() => {
    if (editForm && id) {
      setCommentText(projectExpense?.comments);
    }
  }, [projectExpense?.comments]);

  const getColorizedState = (state: string) => {
    const frontendState = ExpensesState[state];
    const bgColor = stateColors[frontendState] || 'bg-gray-300';

    return (
      <div
        className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
      >
        {frontendState !== null ? t(`RequestLeaveState.${frontendState}`) : ''}
      </div>
    );
  };

  const handleRejectConfirmation = async () => {
    setLoading(true);
    const pxState: ProjectExStateChange = {
      id: id!,
      state: ExpensesState.Rejected,
      comment: commentText,
    };
    const data = await changeProjectExState(pxState);
    setLoading(false);
    setIsModalVisible(false);
    dispatch(fetchProjectExpenseById(id!));
  };
  const handleCancelReject = () => {
    setIsModalVisible(false);
  };
  const handleApproveRequest = async () => {
    // !!!handle approve request
    setLoading(true);
    const pxState: ProjectExStateChange = {
      id: id!,
      state: ExpensesState.Approved,
      comment: commentText,
    };
    const data = await changeProjectExState(pxState);
    setLoading(false);
    setIsModalVisible(false);
    dispatch(fetchProjectExpenseById(id!));
  };
  const ActionButtonsCell = () => {
    return (
      <div className="mt-2 flex items-end justify-end">
        <EditButton onClick={() => handleApproveRequest()}>
          {t('Requestleave.Approve')}
        </EditButton>

        <DeleteButton
          type="button"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          {t('Requestleave.Reject')}
        </DeleteButton>
      </div>
    );
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('projectExpenses.breadcrumb')} />

      {status === 'loading' ||
      user == null ||
      countryLoading ||
      projectsLoading ? (
        <Spinner />
      ) : (
        <>
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleRejectConfirmation}
              onCancel={handleCancelReject}
              textContent={t('doyouwantToRejectThisElement')}
            />
          )}
          <div className="group relative z-0 mb-6 w-30">
            {getColorizedState(projectExpense?.state!)}
          </div>

          <div>
            <div>
              <Accordion
                title={t('projectExpenses.generalInformation.title')}
                isAlwaysOpen={true}
              >
                <div className="flex flex-col gap-2 md:grid md:grid-cols-2 md:gap-2">
                  <Input
                    name="projectExpenseTitle"
                    id="projectExpenseTitle"
                    value={projectExpense?.projectExpenseTitle}
                    label={t('projectExpenses.title')}
                    labelDir="Above"
                    containerClass="w-full"
                    disabled={true}
                  />

                  <div className="group relative  mb-6 w-full">
                    <Dropdown
                      labelDir="Above"
                      id="projectId"
                      name="projectId"
                      labelClassName="pl-0 w-[20%]"
                      label={t('projectExpenses.projectTitleId')}
                      optionLabel="title"
                      value={projectExpense.projectId}
                      optionValue="id"
                      disabled={true}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={projects?.projects ?? []}
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    <DatePickerDefault
                      labelDir="Above"
                      label={t('projectExpenses.missionStartDate')}
                      id="missionStartDate"
                      name="missionStartDate"
                      value={new Date(projectExpense?.missionStartDate)}
                      defaultDate={new Date(projectExpense?.missionStartDate)}
                      disabled={true}
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    <DatePickerDefault
                      label={t('projectExpenses.missionEndDate')}
                      id="missionEndDate"
                      name="missionEndDate"
                      value={new Date(projectExpense?.missionEndDate)}
                      defaultDate={new Date(projectExpense?.missionEndDate)}
                      disabled={true}
                    />
                  </div>

                  <Dropdown
                    labelDir="Above"
                    id="projectExpenseCurrency"
                    name="projectExpenseCurrency"
                    label={t('projectExpenses.currency')}
                    value={projectExpense.projectExpenseCurrency!}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={currencyOptions ?? []}
                    disabled={true}
                  />

                  <Input
                    name="total"
                    id="total"
                    value={formatCurrency(
                      projectExpense?.total,
                      projectExpense.projectExpenseCurrency
                    )}
                    label={t('projectExpenses.total')}
                    labelDir="Above"
                    containerClass="w-full"
                    disabled={true}
                  />
                </div>

                <div className="group relative z-0 mb-6 w-full">
                  <TextArea
                    name="comment"
                    id="comment"
                    value={commentText}
                    label={t('RequestLeaves.comment')}
                    onChange={(e) => setCommentText(e.target.value)}
                    readOnly={
                      user?.isTeamLeader != 'True' &&
                      user.role !== UserRoles.Admin &&
                      projectExpense?.state !== ExpensesState.Pending
                    }
                  />
                </div>
              </Accordion>

              <Accordion title={t('projectExpenses.expenses')}>
                <ExpensesTable
                  expenses={projectExpense?.expenseList ?? []}
                  readonly={true}
                />
              </Accordion>
            </div>
            {projectExpense?.state! === ExpensesState.Pending && (
              <ActionButtonsCell />
            )}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default ProjectExpenseDetails;
