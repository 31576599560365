import { InterviewState } from '@/enums/InterviewState';
import api from '@/utils/api';
import { AnnualInterviewsUrl } from '@/variables/Urls';
import { getCompanyId } from './AuthService';
import {
  AnnualInterviewBriefModel,
  AnnualInterviewModel,
} from '@/models/AnnualInterviewModel';

export interface AnnualInterviewsResponse {
  annualInterviews: AnnualInterviewModel[];
  count: number;
}
export interface AnnualInterviewsBriefResponse {
  annualInterviews: AnnualInterviewBriefModel[];
  count: number;
}
const apiEndpoint = AnnualInterviewsUrl;

export async function getAnnualInterviews(params: {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
}) {
  const { fromvalue = 0, takevalue = 10, search = '' } = params;
  const response = await api.get<AnnualInterviewsResponse>(
    apiEndpoint + '/Get',
    {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
      },
    }
  );
  return response.data;
}
export async function getAnnualInterviewsBrief(params: {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
}) {
  const { fromvalue = 0, takevalue = 10, search = '' } = params;
  const response = await api.get<AnnualInterviewsBriefResponse>(
    apiEndpoint + '/GetBrief',
    {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
      },
    }
  );
  return response.data;
}

export async function getAnnualInterviewsByResponsibleId(params: {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
  responsibleId: string;
}) {
  const { fromvalue = 0, takevalue = 10, search = '', responsibleId } = params;
  const response = await api.get<AnnualInterviewsBriefResponse>(
    apiEndpoint + '/GetByResponsibleId',
    {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        responsibleId: responsibleId,
      },
    }
  );
  return response.data;
}

export async function getAnnualInterviewsByEmployeeId(params: {
  fromvalue?: number;
  takevalue?: number;
  search?: string;
  employeeId: string;
}) {
  const { fromvalue = 0, takevalue = 10, search = '', employeeId } = params;
  const response = await api.get<AnnualInterviewsBriefResponse>(
    apiEndpoint + '/GetByEmployeeId',
    {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        employeeId: employeeId,
      },
    }
  );
  return response.data;
}

export async function getAnnualInterviewById(interviewId: string) {
  const response = await api.get<AnnualInterviewModel>(
    apiEndpoint + '/GetById',
    {
      params: { id: interviewId },
    }
  );
  return response.data;
}
export async function getAnnualInterviewBriefById(interviewId: string) {
  const response = await api.get<AnnualInterviewModel>(
    apiEndpoint + '/GetBriefById',
    {
      params: { id: interviewId },
    }
  );
  return response.data;
}

export async function saveAnnualInterview(
  annualInterview: AnnualInterviewModel
) {
  if (annualInterview.id) {
    // Update existing annual interview.
    const response = await api.put(apiEndpoint + '/put', annualInterview);
    return response.data;
  } else {
    // Create a new annual interview.
    const response = await api.post(apiEndpoint + '/schedule', annualInterview);
    return response.data;
  }
}

export interface ChangeInterviewStateProps {
  interviewState: InterviewState;
  id: string;
  comment?: string;
}

export async function changeInterviewState({
  interviewState,
  id,
  comment,
}: ChangeInterviewStateProps) {
  try {
    const body = {
      id: id,
      comment: comment,
      state: interviewState,
    };
    const response = await api.post(apiEndpoint + '/ChangeState', body);
    return response.data;
  } catch (error) {
    console.error(error.response);
  }
}

export async function deleteAnnualInterview(interviewId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: interviewId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
