import Stepper from '@/components/Stepper';
import DefaultLayout from '@/layout/DefaultLayout';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TabNavigation, { Tab } from '@/components/TabNavigation';
import Breadcrumb from '@/components/Breadcrumb';
import { t } from 'i18next';
import { SubProjectMethodUrl } from '@/variables/Urls';
import SubProjectDetailsForm from './SubProjectDetailsForm';
import SubProjectStepper from './SubProjectStepper';
import SubProjectMaterialForm from './SubProjectMaterials';
import SubProjectMaterials from './SubProjectMaterials';
import SubProjectPersonnels from './SubProjectPersonnels';
import SubProjectEquipments from './SubProjectEquipments';
import SubProjectConsumables from './SubProjectConsumables';
import SubProjectInstallations from './SubProjectInstallations';
import SubProjectOtherEquipments from './SubProjectOtherEquipments';

const steps = [
  {
    stepNumber: 1,
    title: 'EmployeeForm.generalInfoStepTitle',
    description: '',
  },
  {
    stepNumber: 2,
    title: 'Material',
    description: 'EmployeeForm.contractInfoStepDescription',
  },
  {
    stepNumber: 3,
    title: 'Personnels (personne/shift)',
    description: '',
  },
  {
    stepNumber: 4,
    title: 'Tools',
    description: '',
  },
  {
    stepNumber: 5,
    title: 'Consommables',
    description: '',
  },
  {
    stepNumber: 6,
    title: 'Installations',
    description: '',
  },
  {
    stepNumber: 7,
    title: 'Autres',
    description: '',
  },
];

function SubProjectFormStepper() {
  const [currentStep, setCurrentStep] = useState(1);
  const { id } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isSubContructor, setIsSubContructor] = useState(false);
  const handleStepChange = (stepNumber: number) => {
    setCurrentStep(stepNumber);
  };
  return (
    <DefaultLayout>
      <div className="rounded-sm border border-stroke bg-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark">
        {/* Stepper */}
        <SubProjectStepper
          steps={steps}
          currentStep={currentStep}
          onStepChange={handleStepChange}
          isDisabled={isSubContructor}
        />

        {/* Your content for each step */}
        {currentStep === 1 && (
          <SubProjectDetailsForm
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
            setIsSubContructor={setIsSubContructor}
          />
        )}
        {currentStep === 2 && (
          <SubProjectMaterials
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 3 && (
          <SubProjectPersonnels
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 4 && (
          <SubProjectEquipments
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 5 && (
          <SubProjectConsumables
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 6 && (
          <SubProjectInstallations
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 7 && (
          <SubProjectOtherEquipments
            currentStep={currentStep}
            stepsLength={steps.length}
            setCurrentStep={setCurrentStep}
          />
        )}

        {/* Button to navigate to the next step */}
        {/* {currentStep < steps.length && (
        <button
          onClick={() => setCurrentStep((prevStep) => prevStep + 1)}
          className="mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
        >
          {t('EmployeeForm.nextButton')}
        </button>
      )} */}
      </div>
    </DefaultLayout>
  );
}

export default SubProjectFormStepper;
