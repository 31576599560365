import { InterfaceRequestStatus } from '@/enums/InterfaceRequestStatus';
import { InterfaceType } from '@/enums/InterfaceType';
import api from '@/utils/api';
import { InterfaceRequestsUrl } from '@/variables/Urls';

const apiEndpoint = InterfaceRequestsUrl;

export interface InterfaceRequest {
  id: string;
  inputDataRequesterCompany: string;
  inputDataRequesterCompanyContact: string;
  interfaceDescription: string;
  details?: string;
  interfaceType: InterfaceType;
  dueDate: Date;
  dataProviderCompany: string;
  dataProviderContactCompany: string;
  dependencies: string;
  issuesAndRisks?: string;
  correctiveActions?: string;
  comments?: string;
  progress: number;
  status: InterfaceRequestStatus;
  projectId: string;
  projectTitle?: string;
}

export interface InterfaceRequestResponse {
  interfaceRequests: InterfaceRequest[];
  count: number;
}

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

// export enum InterfaceType {}
// // Add your enum values here

// export enum InterfaceRequestStatus {}
// // Add your enum values here

export async function getInterfaceRequestsByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<InterfaceRequestResponse>(
    `${apiEndpoint}/GetByProjectId`,
    config
  );

  return data;
}

export async function saveInterfaceRequest(request: InterfaceRequest) {
  if (request.id) {
    const response = await api.put(apiEndpoint + `/put`, request);
    return response.data;
  }

  const response = await api.post(apiEndpoint + `/post`, request);
  return response.data;
}

export async function getInterfaceRequest(id: string) {
  const { data } = await api.get<InterfaceRequest>(`${apiEndpoint}/GetById`, {
    params: { id },
  });

  return data;
}

export function deleteInterfaceRequest(id: string) {
  return api
    .delete(`${apiEndpoint}/delete`, { data: { id } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
