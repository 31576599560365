export const mapNotificationStateToFrontendState = (backendState: any) => {
  switch (backendState) {
    case 0:
      return NotificationState.Seen;
    case 1:
      return NotificationState.NotSeen;
    default:
      return null;
  }
};

export const mapNotificationTypeToFrontendType = (backendType: any) => {
  switch (backendType) {
    case 0:
      return NotificationType[NotificationType.LeaveRequested];
    case 1:
      return NotificationType[NotificationType.LeaveRequestCanceled];
    case 2:
      return NotificationType[NotificationType.LeaveRequestApproved];
    case 3:
      return NotificationType[NotificationType.LeaveRequestRejected];
    case 4:
      return NotificationType[
        NotificationType.VacationBalanceTransferRequested
      ];
    case 5:
      return NotificationType[
        NotificationType.VacationBalanceTransferRequestCanceled
      ];
    case 6:
      return NotificationType[
        NotificationType.VacationBalanceTransferRequestApproved
      ];
    case 7:
      return NotificationType[
        NotificationType.VacationBalanceTransferRequestRejected
      ];

    case 8:
      return NotificationType[
        NotificationType.MedicalAppointmentConvacationSent
      ];
    case 9:
      return NotificationType[
        NotificationType.MedicalAppointmentConvacationApproved
      ];
    case 10:
      return NotificationType[
        NotificationType.MedicalAppointmentConvacationRejected
      ];
    case 11:
      return NotificationType[NotificationType.TrainingRequestSent];
    case 12:
      return NotificationType[NotificationType.TrainingRequestApproved];
    case 13:
      return NotificationType[NotificationType.TrainingRequestRejected];
    case 14:
      return NotificationType[NotificationType.TrainingSatisfactionFormSent];
    case 15:
      return NotificationType[
        NotificationType.TrainingSatisfactionFormSubmitted
      ];
    case 16:
      return NotificationType[NotificationType.AnnualInterviewHasBeenScheduled];
    case 17:
      return NotificationType[NotificationType.AnnualInterviewIsInProgress];
    case 18:
      return NotificationType[NotificationType.AnnualInterviewHasCompleted];

    case 19:
      return NotificationType[NotificationType.ProjectExpenseSent];

    case 20:
      return NotificationType[NotificationType.ProjectExpenseApproved];

    case 21:
      return NotificationType[NotificationType.ProjectExpenseRejected];
    case 22:
      return NotificationType[NotificationType.ContractEndWithinMonth];
    case 23:
      return NotificationType[NotificationType.ContractEndWithinTwoWeek];
    case 24:
      return NotificationType[NotificationType.ContractEnded];
    case 25:
      return NotificationType[NotificationType.DocumentRequestProcessed];
    case 26:
      return NotificationType[NotificationType.DocumentRequestRejected];
    case 27:
      return NotificationType[NotificationType.DocumentRequested];
    case 28:
      return NotificationType[NotificationType.DocumentRequestInProgress];
    case 29:
      return NotificationType[NotificationType.QuestionsHasArrived];
    case 30:
      return NotificationType[NotificationType.QuestionsHasBeenAnswerd];
    default:
      return null;
  }
};

export enum NotificationType {
  LeaveRequested,
  LeaveRequestCanceled,
  LeaveRequestApproved,
  LeaveRequestRejected,
  VacationBalanceTransferRequested,
  VacationBalanceTransferRequestCanceled,
  VacationBalanceTransferRequestApproved,
  VacationBalanceTransferRequestRejected,
  MedicalAppointmentConvacationSent,
  MedicalAppointmentConvacationApproved,
  MedicalAppointmentConvacationRejected,
  TrainingRequestSent,
  TrainingRequestApproved,
  TrainingRequestRejected,
  TrainingSatisfactionFormSent,
  TrainingSatisfactionFormSubmitted,
  AnnualInterviewHasBeenScheduled,
  AnnualInterviewIsInProgress,
  AnnualInterviewHasCompleted,
  ProjectExpenseSent,
  ProjectExpenseApproved,
  ProjectExpenseRejected,
  ContractEndWithinMonth,
  ContractEndWithinTwoWeek,
  ContractEnded,
  DocumentRequestProcessed,
  DocumentRequestRejected,
  DocumentRequested,
  DocumentRequestInProgress,
  QuestionsHasArrived,
  QuestionsHasBeenAnswerd,
}

export enum NotificationState {
  Seen,
  NotSeen,
}

interface NotificationMenu {
  type: NotificationType[];
  path: string;
}

export const notificationsTypeMenus: NotificationMenu[] = [
  {
    type: [
      NotificationType.VacationBalanceTransferRequestRejected,
      NotificationType.VacationBalanceTransferRequestApproved,
      NotificationType.VacationBalanceTransferRequestCanceled,
      NotificationType.VacationBalanceTransferRequested,
    ],
    path: '/gestion-resources-humains/vacation-transfer-request-details/',
  },
  {
    type: [
      NotificationType.LeaveRequestApproved,
      NotificationType.LeaveRequestCanceled,
      NotificationType.LeaveRequestRejected,
      NotificationType.LeaveRequested,
    ],
    path: '/gestion-resources-humains/request-leave-details/',
  },
  {
    type: [
      NotificationType.MedicalAppointmentConvacationSent,
      NotificationType.MedicalAppointmentConvacationApproved,
      NotificationType.MedicalAppointmentConvacationRejected,
    ],
    path: '/gestion-resources-humains/gestion-de-visite-medicale/',
  },

  {
    type: [
      NotificationType.TrainingRequestSent,
      NotificationType.TrainingRequestApproved,
      NotificationType.TrainingRequestRejected,
    ],
    path: '/gestion-resources-humains/gestion-des-formations/formations/details/',
  },

  {
    type: [
      NotificationType.TrainingSatisfactionFormSent,
      NotificationType.TrainingSatisfactionFormSubmitted,
    ],
    path: '/gestion-resources-humains/gestion-des-formations/satification-forms/details/',
  },
  {
    type: [NotificationType.AnnualInterviewHasBeenScheduled],
    path: '/gestion-resources-humains/gestion-entretien-annuel/details/',
  },
  {
    type: [NotificationType.AnnualInterviewIsInProgress],
    path: '/gestion-resources-humains/gestion-entretien-annuel/start-annual-interview/',
  },
  {
    type: [NotificationType.AnnualInterviewHasCompleted],
    path: '/gestion-resources-humains/gestion-entretien-annuel/details/',
  },
  {
    type: [
      NotificationType.ProjectExpenseSent,
      NotificationType.ProjectExpenseApproved,
      NotificationType.ProjectExpenseRejected,
    ],
    path: '/gestion-resources-humains/gestion-note-de-frais/details/',
  },

  {
    type: [
      NotificationType.DocumentRequestProcessed,
      NotificationType.DocumentRequestRejected,
      NotificationType.DocumentRequested,
      NotificationType.DocumentRequestInProgress,
    ],
    path: '/gestion-resources-humains/gestion-des-documents/details/',
  },
  {
    type: [
      NotificationType.QuestionsHasArrived,
      NotificationType.QuestionsHasBeenAnswerd,
    ],
    path: '/gestion-project-ao/QuestionResponses/',
  },
];
