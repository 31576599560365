import Breadcrumb from '@/components/Breadcrumb';
import {
  Event,
  ResourceType,
  Timeline,
  WeekendsType,
} from '@/components/TimeLine.tsx';
import DefaultLayout from '@/layout/DefaultLayout';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import Dropdown from '@/components/DropDown';
import { Branch, getBranches } from '@/services/BranchService';
import { Departement } from '@/services/DepartementService';
import {
  Team,
  TeamsResponse,
  getTeamsByDepartment,
} from '@/services/TeamService';
import { PrimaryButton } from '@/components/shared/Button';
import RequestLeaves from '../RequestLeaves/RequestLeavesTable';
import Pagination from '@/components/Pagination';
import Accordion from '@/components/Accordian';
import {
  convertEmployeesToResources,
  convertRequestLeavesToEvents,
} from '@/utils/CalendarHelper';
import { getRequestLeaves } from '@/services/RequestLeavesService';
import { RequestLeaveState } from '@/enums/RequestLeaveState';
import { getWorkCalendarByEmployeeId } from '@/services/WorkCalendarsService';
import { useAuth } from '@/context/AuthContext';

const AbsenceManagement = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterdresourcesList, setResourcesList] = useState<ResourceType[]>([]);
  const [searchResource, setSearchResource] = useState<string>('');
  const [departements, setDepartements] = React.useState<Departement[]>([]);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>(); // State for selected team
  const [selectedDepartment, setSelectedDepartment] = useState<Departement>(); // State for selected department
  const [selectedBranch, setSelectedBranch] = useState<Branch>(); // State for selected branch
  const { t } = useTranslation();
  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<Event[]>([]);
  const [weekendDays, setWeekendDays] = useState<WeekendsType[]>([]);

  // const myEvents = React.useMemo(() => {
  //   return [
  //     {
  //       start: new Date('2023-07-05T00:00'),
  //       end: new Date('2023-07-08T23:00'),
  //       title: 'CC',
  //       resource: 1,
  //       tooltip: ' ',
  //       color: '#ff55ff',
  //     },
  //     {
  //       start: new Date('2023-07-05T00:00'),
  //       end: new Date('2023-07-07T23:00'),
  //       title: 'TT',
  //       resource: 3,
  //       color: '#241de9',
  //     },
  //     {
  //       start: new Date('2023-07-03T00:00'),
  //       end: new Date('2023-07-04T23:00'),
  //       title: 'CC',
  //       resource: 5,
  //       color: '#ff55ff',
  //     },
  //     {
  //       start: new Date('2023-07-26T00:00'),
  //       end: new Date('2023-07-26T23:00'),
  //       title: 'CC',
  //       resource: 26,
  //       color: '#ff55ff',
  //     },
  //     {
  //       start: new Date('2023-08-04T00:00'),
  //       end: new Date('2023-08-04T23:00'),
  //       title: 'CC',
  //       resource: 2,
  //       color: '#ff55ff',
  //     },
  //   ];
  // }, []);

  //? qouery for team , branch , department
  const { data: branchesData, isSuccess: isBranchSuccess } = useQuery(
    ['branches'],
    () => getBranches({ fromvalue: 0, takevalue: 0 }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (branchesData) {
      setBranches(branchesData?.branches);
    }
  }, [isBranchSuccess]);
  const handleDepartementChange = (event: any) => {
    const selectedDepartmentId = event.target.value;
    const selectedDepartemntObj = departements.find(
      (dep) => dep.id === selectedDepartmentId
    );

    setSelectedDepartment(selectedDepartemntObj);
    refetchTeam();
  };
  const handleTeamChange = (event: any) => {
    const selectedTeamId = event.target.value;
    const selectedTeamObj = teams.find((tem) => tem.id === selectedTeamId);

    setSelectedTeam(selectedTeamObj);
    refetchEmployee();
  };
  const handleBranchChange = (event: any) => {
    const selectedBranchId = event.target.value;

    const selectedBranchObj = branchesData?.branches.find(
      (branch) => branch.id === selectedBranchId
    );

    if (selectedBranchObj) {
      setDepartements(selectedBranchObj.departments! as Departement[]);
    } else {
      setDepartements([]);
    }

    setSelectedBranch(selectedBranchObj);
  };

  const {
    isSuccess: isTeamSuccess,
    data: teamsData,
    refetch: refetchTeam,
  } = useQuery<TeamsResponse | undefined>(
    ['teams', selectedDepartment],
    () =>
      selectedDepartment
        ? getTeamsByDepartment({
            fromvalue: 0,
            takevalue: 0,
            departmentId: selectedDepartment.id,
          })
        : undefined,
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (teamsData) {
      setTeams(teamsData.teams);
    }
  }, [isTeamSuccess, teamsData]);

  // ?query for employee
  const {
    data: employees,
    isSuccess,
    isLoading,
    refetch: refetchEmployee,
  } = useQuery<EmployeesResponse | undefined>(
    [
      'employees',
      pageIndex,
      pageSize,
      searchResource,
      selectedBranch?.id,
      selectedDepartment?.id,
      selectedTeam?.id,
    ],
    () =>
      getEmployees({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: searchResource,
        departmentId: selectedDepartment?.id,
        branchId: selectedBranch?.id,
        teamId: selectedTeam?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (isSuccess && employees) {
      setResourcesList(convertEmployeesToResources(employees.employees!));
    }
  }, [isSuccess, employees]);

  /* pagination*/
  let pageCount = useMemo(() => {
    return Math.ceil((employees?.count ?? 0) / pageSize);
  }, [pageSize, employees]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };
  /*  */
  const { data: empWorkCalendar, isLoading: empWorkCalendarLoading } = useQuery(
    ['empWorkCalendar', user?.id],
    () => getWorkCalendarByEmployeeId(user?.id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: false,
    }
  );

  const { data: requestLeavesData, isSuccess: isEventSucees } = useQuery(
    ['requestLeavesByCompanyId', user?.id],
    () => {
      const startOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      );
      /// we nned to add te last minute ofday
      endOfMonth.setHours(23, 59, 59, 999);
      return getRequestLeaves({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        requestLeaveState: RequestLeaveState.Approved,
        fromDate: startOfMonth,
        toDate: endOfMonth,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: isSuccess,
    }
  );
  useEffect(() => {
    if (isSuccess && isSuccess && employees) {
      setResourcesList(convertEmployeesToResources(employees.employees!));
    }
  }, [isSuccess, employees, isSuccess]);

  useEffect(() => {
    if (requestLeavesData && isEventSucees) {
      const eventsData = convertRequestLeavesToEvents(
        requestLeavesData.requestLeaves!
      );

      setEvents(eventsData);
      //! set Events List
    }
  }, [requestLeavesData, isEventSucees]);
  useEffect(() => {
    let daysMapping = {
      monday: t('Timeline.Mon'),
      tuesday: t('Timeline.Tue'),
      wednesday: t('Timeline.Wed'),
      thursday: t('Timeline.Thu'),
      friday: t('Timeline.Fri'),
      saturday: t('Timeline.Sat'),
      sunday: t('Timeline.Sun'),
    };

    const weekends =
      employees?.employees?.map((employee) => ({
        employeeId: employee.id!,
        days: Object.keys(daysMapping)
          .filter((day) => employee?.workCalendar[day])
          .map((day) => daysMapping[day]),
      })) ?? [];

    setWeekendDays(() => weekends);
  }, [employees, t]);

  const resetFilter = () => {
    setSelectedBranch(undefined);
    setSelectedTeam(undefined);
    setSelectedDepartment(undefined);
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('AbsenceManagement.pageName')} />
      <div className="min-h-screen dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark ">
        <div className=" mx-auto py-8">
          <div className="space-y-4">
            <Accordion title={t('AbsenceManagement.requestedLeaveList')}>
              {/* Dropdowns for selecting team, department, and branch */}
              <div className="space-x-auto mb-5 grid grid-cols-2 gap-2 md:grid-cols-5">
                <Dropdown
                  optionValue="id"
                  optionLabel="title"
                  labelClassName="w-[70%]"
                  label={t('AbsenceManagement.selectBranch')}
                  options={branches}
                  value={selectedBranch?.id ?? ''}
                  onChange={(e) => handleBranchChange(e)}
                />

                <Dropdown
                  label={t('AbsenceManagement.selectDepartment')}
                  optionValue="id"
                  optionLabel="title"
                  labelClassName="w-[70%]"
                  options={departements}
                  value={selectedDepartment?.id ?? ''}
                  onChange={(e) => handleDepartementChange(e)}
                />
                <Dropdown
                  label={t('AbsenceManagement.selectTeam')}
                  optionValue="id"
                  optionLabel="title"
                  options={teams}
                  labelClassName="w-[70%]"
                  value={selectedTeam?.id ?? ''}
                  onChange={(e) => handleTeamChange(e)}
                />

                <div>
                  <PrimaryButton className="mt-[27px]" onClick={resetFilter}>
                    {t('AbsenceManagement.reset')}
                  </PrimaryButton>
                </div>
              </div>
              {/* Calendar content */}
              <div className="flex  overflow-x-auto dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Timeline
                    resourcesLength={filterdresourcesList.length - 1}
                    resourcesList={filterdresourcesList}
                    searchResource={searchResource}
                    setSearchResource={setSearchResource}
                    weekendDays={weekendDays}
                    myEvents={events}
                    holidays={[]}
                    enterpriseClosing={[]}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                )}
              </div>
              <Pagination
                pageIndex={pageCount === 0 ? -1 : pageIndex}
                pageCount={pageCount}
                pageSize={pageSize}
                onNextPage={nextPage}
                onPreviousPage={previousPage}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
            </Accordion>

            <Accordion title={t('AbsenceManagement.leaveDemandsList')}>
              {/* List content */}
              <RequestLeaves />
            </Accordion>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AbsenceManagement;

// const getLocalizedDayName = useCallback(
//   (dayNumber: number) => {
//     const date = new Date(2023, 6, dayNumber);
//     return format(date, 'EEE', { locale: locales[i18n.language] }); // Use format from date-fns
//   },
//   [i18n.language]
// );

// useEffect(() => {
//   const currentLanguageWeekendDays = [1, 2].map(getLocalizedDayName);
//   setWeekends((prev) => {
//     return currentLanguageWeekendDays.length === 2
//       ? currentLanguageWeekendDays
//       : ['Sat', 'Sun'];
//   });
// }, [i18n.language]);

// const toggleDarkMode = () => {
//   setIsDarkMode(!isDarkMode);
// };

// useEffect(() => {
//   let daysOfWeek1 = getDaysData(selectedDate, i18n.language);
//   setDaysOfWeek(daysOfWeek1);
//   const isToday = selectedDate.toDateString() === new Date().toDateString();
//   setIsTodayinMonth((prev: boolean) => (prev = isToday));
// }, [selectedDate, i18n.language]);

// useEffect(() => {
//   const className = 'dark';
//   const bodyClass = window.document.body.classList;

//   isDarkMode ? bodyClass.add(className) : bodyClass.remove(className);
// }, [isDarkMode]);
