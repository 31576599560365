//@ts-noCheck

import { useAuth } from '@/context/AuthContext';
import { useSignalR } from '@/context/SignalRContext';
import {
  NotificationModel,
  getNotifications,
  markAllNotificationAsSeen,
  markNotificationAsSeen,
} from '@/services/NotificationService';
import SignalRService from '@/services/SignalRService';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigation } from 'react-router-dom';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';
import {
  NotificationState,
  NotificationType,
  mapNotificationTypeToFrontendType,
  notificationsTypeMenus,
} from '@/enums/NotificationEnum';

import moment from 'moment';
import 'moment/locale/pt-br';
import CustomLink from './CustomLink';

// ar

const DropdownNotification = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { startConnection, stopConnection } = useSignalR();
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  const [isNotSeen, setIsNotSeen] = useState(false);
  const {
    data: notificationsData,
    isLoading: isNotificaitosLaoding,
    isSuccess,
    refetch,
  } = useQuery(
    ['Notifications', user?.id!, i18n.language],
    () =>
      getNotifications({
        fromvalue: 0,
        takevalue: 0,
      }),

    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: user?.id !== null,
    }
  );

  useEffect(() => {
    if (isSuccess && notificationsData.notifications) {
      setNotifications(notificationsData.notifications);
      const isNotSeen = notificationsData.notifications.some((x) => !x.isSeen);
      setIsNotSeen(isNotSeen);
    }
  }, [isSuccess, notificationsData]);
  // useEffect(() => {
  //   const handleNotification = (notification) => {
  //     console.log('this is notification', notification);
  //     toast.success(t('newNotficationArrived'));
  //     refetch();
  //     // setNotifications((prevNotifications) => [...prevNotifications, notification]);
  //   };

  //   // Ensure that the callback is registered only once
  //   if (SignalRService.hubConnection) {
  //     SignalRService.hubConnection.off(
  //       'UserReceiveNotification',
  //       handleNotification
  //     );
  //     SignalRService.hubConnection.on(
  //       'UserReceiveNotification',
  //       handleNotification
  //     );
  //   }

  //   // Cleanup function to remove the event listener when the component is unmounted
  //   return () => {
  //     if (SignalRService.hubConnection) {
  //       SignalRService.hubConnection.off(
  //         'UserReceiveNotification',
  //         handleNotification
  //       );
  //     }
  //   };
  // }, []);

  useEffect(() => {
    //startConnection();
    SignalRService.onReceiveNotification((notification) => {
      // Handle the received notification

      toast.success(t('newNotficationArrived'));
      refetch();
      // setNotifications((prevNotifications) => [...prevNotifications, notification]);
    });

    return () => {
      if (SignalRService.hubConnection) {
        SignalRService.hubConnection.off(
          'UserReceiveNotification',
          handleNotification
        );
      }
    };
  }, []);
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  const handleClickNotification = async (notificationId: string) => {
    // call seen

    await markNotificationAsSeen(notificationId);
    refetch();
  };
  const handleMarkAllAsSeen = async () => {
    // call seen

    await markAllNotificationAsSeen();
  };

  const isVacationTransferRequest = (notificationType) => {
    return (
      notificationType === NotificationType.VacationBalanceTransferRequested ||
      notificationType ===
        NotificationType.VacationBalanceTransferRequestCanceled ||
      notificationType ===
        NotificationType.VacationBalanceTransferRequestApproved ||
      notificationType ===
        NotificationType.VacationBalanceTransferRequestRejected
    );
  };

  const getNotificationPath = (
    notificationType: NotificationType
  ): string | null => {
    const menu = notificationsTypeMenus.find((menu) =>
      menu.type.includes(notificationType)
    );

    return menu ? menu.path : null;
  };

  const formattedLocalDate = (date: Date | null) => {
    return date ? moment(date).format('DD/MM/YYYY h:mmA') : '';
  };

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const mapStrings = useCallback(
    (t, notification: NotificationModel) => {
      if (notification) {
        return t(
          `NotificationTypes.${mapNotificationTypeToFrontendType(
            notification.notificationType
          )}`
        );
      }
    },
    [t]
  );

  return (
    <li className="relative">
      {
        <Link
          ref={trigger}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          to="#"
          className="bg-gray relative flex h-8.5 w-8.5 items-center justify-center rounded-full border-[0.5px] border-stroke hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white"
        >
          {isNotSeen && (
            <span className="absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1">
              <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
            </span>
          )}

          <svg
            className=" fill-current duration-300 ease-in-out "
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1999 14.9343L15.6374 14.0624C15.5249 13.8937 15.4687 13.7249 15.4687 13.528V7.67803C15.4687 6.01865 14.7655 4.47178 13.4718 3.31865C12.4312 2.39053 11.0812 1.7999 9.64678 1.6874V1.1249C9.64678 0.787402 9.36553 0.478027 8.9999 0.478027C8.6624 0.478027 8.35303 0.759277 8.35303 1.1249V1.65928C8.29678 1.65928 8.24053 1.65928 8.18428 1.6874C4.92178 2.05303 2.4749 4.66865 2.4749 7.79053V13.528C2.44678 13.8093 2.39053 13.9499 2.33428 14.0343L1.7999 14.9343C1.63115 15.2155 1.63115 15.553 1.7999 15.8343C1.96865 16.0874 2.2499 16.2562 2.55928 16.2562H8.38115V16.8749C8.38115 17.2124 8.6624 17.5218 9.02803 17.5218C9.36553 17.5218 9.6749 17.2405 9.6749 16.8749V16.2562H15.4687C15.778 16.2562 16.0593 16.0874 16.228 15.8343C16.3968 15.553 16.3968 15.2155 16.1999 14.9343ZM3.23428 14.9905L3.43115 14.653C3.5999 14.3718 3.68428 14.0343 3.74053 13.6405V7.79053C3.74053 5.31553 5.70928 3.23428 8.3249 2.95303C9.92803 2.78428 11.503 3.2624 12.6562 4.2749C13.6687 5.1749 14.2312 6.38428 14.2312 7.67803V13.528C14.2312 13.9499 14.3437 14.3437 14.5968 14.7374L14.7655 14.9905H3.23428Z"
              fill=""
            />
          </svg>
        </Link>
      }

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute -right-27 mt-2.5 flex h-90 w-75 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-80 ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <div className="flex flex-col  px-4.5 py-3 md:flex-row md:justify-between">
          <h5 className="text-sm font-medium text-bodydark2">
            {t('Notification')}
          </h5>
          <h5
            onClick={handleMarkAllAsSeen}
            className="cursor-pointer text-sm font-medium text-bodydark1 hover:text-bodydark2"
          >
            {t('MarkAllAsSeen')}
          </h5>
        </div>

        <ul className="flex h-auto flex-col overflow-y-auto">
          {notifications &&
            notifications?.map((notification: NotificationModel) =>
              notification ? (
                <li key={notification.id}>
                  <CustomLink
                    to={
                      getNotificationPath(notification.notificationType)
                        ? `${getNotificationPath(
                            notification.notificationType
                          )}${notification.entityId}`
                        : '/'
                    }
                    className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4 "
                    onClick={() => handleClickNotification(notification.id)}
                  >
                    <span className="text-sm">
                      <p
                        className={`text-black dark:text-white ${
                          !notification.isSeen && 'font-bold'
                        }`}
                      >
                        {mapStrings(t, notification)}
                      </p>
                      {notification?.message}
                    </span>

                    {/* <p className="text-xs">12 May, 2025</p> */}
                    <p className="text-xs">
                      {formattedLocalDate(new Date(notification?.createdDate))}
                    </p>
                  </CustomLink>
                </li>
              ) : (
                <></>
              )
            )}

          {/* <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              to="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  It is a long established fact
                </span>{' '}
                that a reader will be distracted by the readable.
              </p>

              <p className="text-xs">24 Feb, 2025</p>
            </Link>
          </li>
          <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              to="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  There are many variations
                </span>{' '}
                of passages of Lorem Ipsum available, but the majority have
                suffered
              </p>

              <p className="text-xs">04 Jan, 2025</p>
            </Link>
          </li>
          <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              to="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  There are many variations
                </span>{' '}
                of passages of Lorem Ipsum available, but the majority have
                suffered
              </p>

              <p className="text-xs">01 Dec, 2024</p>
            </Link>
          </li> */}
        </ul>
      </div>
    </li>
  );
};

export default DropdownNotification;
