import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import {
  CountriesResponse,
  Country,
  State,
  getCountries,
} from '@/services/CountryService';
import {
  Branch,
  BranchesResponse,
  getBranch,
  getBranches,
  saveBranch,
} from '@/services/BranchService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import { getCompanyId } from '@/services/AuthService';
import {
  Departement,
  getDepartement,
  saveDepartement,
} from '@/services/DepartementService';
import { toast } from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('DepartementForm.Titleisrequired'),
  branchId: Yup.string().required('DepartementForm.Branchisrequired'),
});

interface FormValues extends Departement {
  title: string;
}

function DepartementForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const [branches, setBranches] = useState<Branch[]>([]);

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    title: '',
    branchId: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation(); // Use the default namespace

  const { data: branchesData, isLoading: branchLoading } = useQuery<
    BranchesResponse | undefined
  >(
    ['branches'],
    async () => await getBranches({ fromvalue: 0, takevalue: 0 }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (branches) => {
        setBranches(branches?.branches!);
      },
    }
  );

  const {
    data: departementData,
    isLoading,
    refetch: refetchDepartement,
  } = useQuery<Departement | undefined>(
    ['departement', id],
    async () => await getDepartement(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (departement) => {
        if (departement) {
          setFormikValuesState((prev) => ({ ...prev, ...departement }));
        }
      },
    }
  );

  const handleSaveBranch = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const departement: Departement = {
        ...values,
      };

      const data = await saveDepartement(departement);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.Departement'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.Departement'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchDepartement();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('DepartementForm.editPageName')
            : t('DepartementForm.addPageName')
        }
      />
      {loading && branchLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveBranch}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="title"
                    id="title"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.title}
                    label={t('DepartementForm.titleLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="branchId"
                    name="branchId"
                    value={values.branchId ?? ''}
                    optionValue="id"
                    onChange={handleChange}
                    options={branches ?? []}
                    label={t('DepartementForm.branchLabel')}
                    optionLabel="title"
                    labelClassName="w-[40%]"
                    error={errors.branchId}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('DepartementForm.submitButton')}{' '}
                {/* Translate the submit button */}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('DepartementForm.cancelButton')}{' '}
                {/* Translate the cancel button */}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default DepartementForm;
