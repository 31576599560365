import React from 'react';
import 'tailwindcss/tailwind.css'; // Make sure to import Tailwind CSS styles
import { EmployeeContractDataModel } from '@/models/CompanyRHDataModel'; // Replace with the correct path
import { useTranslation } from 'react-i18next';

const EmployeeContractDataTable: React.FC<{
  data: EmployeeContractDataModel | undefined;
}> = ({ data }) => {
  const calculatePercentage = () => {
    if (!data || data.totalEmployeesInYear === 0) {
      return 0;
    }

    return ((data.newEmployees / data.totalEmployeesInYear) * 100).toFixed(2);
  };

  const { t } = useTranslation();

  return (
    <div className="mt-4 flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('EmployeeContractDataTable.metric')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('EmployeeContractDataTable.value')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
                <tr>
                  <td className="whitespace-nowrap px-6 py-4">
                    {t('EmployeeContractDataTable.newEmployees')}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    {data?.newEmployees ?? 0}
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap px-6 py-4">
                    {t('EmployeeContractDataTable.existingEmployees')}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    {data?.existingEmployees ?? 0}
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap px-6 py-4">
                    {t('EmployeeContractDataTable.percentage')}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    {calculatePercentage()}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeContractDataTable;
