import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  RisksResponse,
  deleteRisk,
  getRisksByProjectId,
} from '@/services/RisksService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { InterfaceType } from '@/enums/InterfaceType';
import { RiskType } from '@/enums/RiskType';

const userTimeZone = moment.tz.guess();

function Risks() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('Risks.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('Risks.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteRisk.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Risks.riskDescriptionColumn'),
        accessor: 'riskDescription',
      },
      {
        Header: t('Risks.riskCategoryColumn'),
        accessor: 'riskCategory',
        Cell: ({ cell: { value } }) =>
          t(`InterfaceType.${InterfaceType[value]}`),
      },
      {
        Header: t('Risks.riskTypeColumn'),
        accessor: 'riskType',
        Cell: ({ cell: { value } }) => t(`RiskType.${RiskType[value]}`),
      },
      {
        Header: t('Risks.preMitigationProbabilityColumn'),
        accessor: 'preMitigationProbability',
      },
      {
        Header: t('Risks.preMitigationImpactColumn'),
        accessor: 'preMitigationImpact',
      },
      {
        Header: t('Risks.postMitigationProbabilityColumn'),
        accessor: 'postMitigationProbability',
      },
      {
        Header: t('Risks.postMitigationImpactColumn'),
        accessor: 'postMitigationImpact',
      },
      {
        Header: t('Risks.currentStateColumn'),
        accessor: 'currentState',
      },
      {
        Header: t('Risks.identificationDateColumn'),
        accessor: 'identificationDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD');
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('Risks.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell value={value} handleDelete={handleDeleteRisk} />
        ),
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: risks,
  } = useQuery<RisksResponse | undefined>(
    ['risks', pageIndex, pageSize, search, id],
    () =>
      getRisksByProjectId({
        projectId: id!,
        page: pageIndex,
        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const handleDeleteRisk = useMutation(
    async (id: string) => {
      await deleteRisk(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['risks', pageIndex, pageSize, search]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((risks?.risks.length ?? 0) / pageSize);
  }, [pageSize, risks?.risks]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('Risks.pageName')}
        addbtn={true}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={risks?.risks.length}
            data={risks?.risks ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default Risks;
