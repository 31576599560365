import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Dropdown from '@/components/DropDown';
import {
  Employee,
  EmployeesResponse,
  getEmployees,
  getEmployeesByTeamLeaderId,
} from '@/services/EmployeeService';
import {
  getAnnualInterviewBriefById,
  saveAnnualInterview,
} from '@/services/AnnualInterviewService';
import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { AnnualInterviewModel } from '@/models/AnnualInterviewModel';
import DefaultLayout from '@/layout/DefaultLayout';
import Spinner from '@/components/Spinner';
import Breadcrumb from '@/components/Breadcrumb';
import { getCompanyId } from '@/services/AuthService';
import toast from 'react-hot-toast';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { DateAndTimePicker } from '@/components/DateAndTimePicker';
import moment from 'moment-timezone';
import Input from '@/components/Input';
import { AsUserTimeZone } from '@/components/shared/Utils';

// Import your necessary components from your project
const today = new Date();
today.setHours(0, 0, 0, 0);
// Define your validation schema
const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required(
    'annualInterviewForm.employeeIdValidation.required'
  ),

  interviewDate: Yup.date()
    .required('annualInterviewForm.interviewDateValidation.required')
    .min(today, 'annualInterviewForm.interviewDateValidation.min'),

  // You should add validation for other fields as needed
});

function AnnualInterviewForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const userTimeZone = moment.tz.guess();
  const [formikValuesState, setFormikValuesState] = useState({
    employeeId: '',
    responsibleId: '',
    interviewDate: new Date(),
    // Add other necessary fields
  });
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const isAnnualinterviewAdmin =
    user?.isTeamLeader == 'True' || user?.role == UserRoles.Admin;
  const { isLoading: employeesLoading } = useQuery<
    EmployeesResponse | undefined
  >(
    ['employees', user?.id],
    async () => {
      if (user?.isTeamLeader == 'True') {
        return await getEmployeesByTeamLeaderId({
          fromvalue: 0,
          takevalue: 0,
          teamLeaderId: user.id,
        });
      } else {
        return await getEmployees({ fromvalue: 0, takevalue: 0 });
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: !!user?.id,
      onSuccess: (employee) => {
        setEmployees(employee?.employees!);
      },
    }
  );
  const { data: annualInterviewData, refetch: refetchAnnualInterview } =
    useQuery<AnnualInterviewModel>(
      ['annaualInterview', id],
      async () => await getAnnualInterviewBriefById(id!),
      {
        enabled: editForm,
        refetchOnWindowFocus: false,
        staleTime: 6000,
        keepPreviousData: true,
        onSuccess: (annaualInterviewData) => {
          if (annaualInterviewData) {
            setFormikValuesState((prev) => ({
              ...prev,
              ...annaualInterviewData,
            }));
          }
        },
      }
    );
  useEffect(() => {
    if (editForm && annualInterviewData) {
      // Update form values with branch data
      setFormikValuesState((prev) => ({ ...prev, ...annualInterviewData }));
    }
  }, [editForm, annualInterviewData]);

  const handleSaveInterview = async (values: any, { resetForm }: any) => {
    setLoading(true);

    try {
      var companyId = getCompanyId();
      const ai: AnnualInterviewModel = {
        ...values,
        responsibleId: user?.id,
        companyId: companyId!,
      };

      const data = await saveAnnualInterview(ai);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.AnnualInterview'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.AnnualInterview'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchAnnualInterview();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? isAnnualinterviewAdmin
              ? t('annualInterviewForm.edit')
              : t('annualInterviewForm.interviewDetails')
            : t('annualInterviewForm.add')
        }
      />
      {loading || employeesLoading ? <Spinner /> : getBody()}
    </DefaultLayout>
  );

  function getBody(): React.ReactNode {
    if (!isAnnualinterviewAdmin) {
      return (
        <>
          <Dropdown
            id="employeeId"
            name="employeeId"
            value={formikValuesState.employeeId ?? ''}
            optionValue="id"
            options={employees ?? []}
            onChange={() => {}}
            label={t('appointmentsForm.Employee')}
            optionLabel="employeeFullName"
            disabled={true}
          />
          <Input
            id="interviewDate"
            value={moment
              .utc(formikValuesState.interviewDate)
              .tz(userTimeZone)
              .format('YYYY-MM-DD hh:mm')}
            name="interviewDate"
            label={t('AnnualInterviews.interviewDate')}
            disabled={true}
          />
        </>
      );
    }

    return (
      <Formik
        initialValues={formikValuesState}
        validationSchema={validationSchema}
        onSubmit={handleSaveInterview}
        enableReinitialize={editForm}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Employee ID input */}
            <Dropdown
              id="employeeId"
              name="employeeId"
              value={values.employeeId ?? ''}
              optionValue="id"
              onChange={handleChange}
              options={employees ?? []}
              label={t('appointmentsForm.Employee')}
              optionLabel="employeeFullName"
              error={errors.employeeId}
            />

            {/* Interview date datepicker */}
            {/* <DatePickerDefault
              id="interviewDate"
              value={values.interviewDate}
              name="interviewDate"
              label={'interviewDate'}
              onChange={(date: Date) => {
                date = new Date(date.setHours(23, 0, 0, 0));
                handleChange({
                  target: {
                    name: 'interviewDate',
                    value: date.toISOString(),
                  },
                });
              }}
              errors={errors.interviewDate}
            /> */}

            <DateAndTimePicker
              id="interviewDate"
              label={t('AnnualInterviews.interviewDate')}
              selectedDate={AsUserTimeZone(
                new Date(values.interviewDate) ?? new Date()
              )}
              selectedLanguage={i18n.language}
              setSelectedDate={(e) => {
                const date = new Date(e);

                setFieldValue('interviewDate', date);
              }}
              name="interviewDate"
              errors={errors.interviewDate}
            />
            <button
              type="submit"
              className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
              disabled={loading}
            >
              {t('BranchForm.submitButton')}
            </button>
            <button
              type="button"
              id="cancelForm"
              className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
              onClick={() => {
                resetForm();
                navigate(-1);
              }}
            >
              {t('BranchForm.cancelButton')}
            </button>
          </form>
        )}
      </Formik>
    );
  }
}

export default AnnualInterviewForm;
