import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  AppointmentsResponse,
  deleteAppointment,
  getAppointments,
  Appointment,
  getAppointmentsByUserId,
} from '@/services/AppointmentService';
import { useAuth } from '@/context/AuthContext';
import { UserRoles, UserRolesEnum } from '@/enums/UsersRole';
import { AppointmentState } from '@/enums/AppointmentState';
import { stateColors } from '@/enums/RequestLeaveState';
import moment from 'moment-timezone';
import AppointmentResultModal from './components/ResultModal';
import { UserResponsibility } from '@/enums/UserResponsibility';
import { EyeIcon } from '@heroicons/react/solid';
const userTimeZone = moment.tz.guess();
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

function MedicalAppointments() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>(
    {}
  );
  const [selectedRow, setSelectedRow] = useState('');
  const [appointmentsData, setAppointmentsData] =
    useState<AppointmentsResponse>();
  const [isAppointmentsLoading, setAppointmentsLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const isMedicalVisiteAdmin =
    user?.responsibilityClaim ===
    UserResponsibility[UserResponsibility.GestionDeVisiteMedicale].toString();
  const ActionButtonsCell = ({ value, state, appointment }: any) => {
    return isMedicalVisiteAdmin && state == AppointmentState.Pending ? (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('Appointments.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('Appointments.editButton')}
        </EditButton>
      </div>
    ) : (
      <div>
        <EyeIcon
          onClick={() => navigate(`${value}`)}
          className="h-5 w-5 text-gray-600 dark:text-gray-300"
        />
        {/* <PrimaryButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('Appointments.Details')}
        </PrimaryButton> */}
        {isMedicalVisiteAdmin && (
          <PrimaryButton
            type="button"
            onClick={() => {
              setResultModal(true);
              setSelectedAppointment(appointment);
            }}
          >
            {t('Appointments.Results')}
          </PrimaryButton>
        )}
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteAppointment.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Appointments.employeeName'),
        accessor: 'employeeName',
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value, row } }: any) => (
          <input
            type="text"
            className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
      {
        Header: t('Appointments.department'),
        accessor: 'departmentName',
      },
      {
        Header: t('Appointments.branch'),
        accessor: 'branchName',
      },
      {
        Header: t('Appointments.teamLeaderfullName'),
        accessor: 'teamLeaderName',
        Cell: ({ cell: { value, row } }: any) => (
          <input
            type="text"
            className="min-w-50 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },

      {
        Header: t('Appointments.dateEnvoiConvocation'),
        accessor: 'dateEnvoiConvocation',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('YYYY-MM-DD'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('Appointments.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => {
          return (
            <ActionButtonsCell
              value={value}
              handleDelete={handleDeleteAppointment}
              state={row.original['appointmentState']}
              appointment={row.original}
            />
          );
        },
      },
      {
        Header: t('Appointments.convocationValideParSalarie'),
        accessor: 'appointmentState',
        Cell: ({ cell: { value } }) => {
          const frontendState = AppointmentState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`min-w-50 w-40 rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: t('Appointments.visiteMedicaleDone'),
        accessor: 'visiteMedicaleDone',
        Cell: ({ cell: { value, row } }: any) => (
          <input
            type="checkbox"
            className="min-w-50 dark:bg-boxdark-2 dark:text-bodydark"
            checked={value}
            readOnly
            disabled
          />
        ),
      },
      {
        Header: t('Appointments.rendezvousDateTime'),
        accessor: 'rendezvousDateTime',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('YYYY-MM-DD hh:mm'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('Appointments.Apte'),
        accessor: 'apte',

        Cell: ({ cell: { value, row } }: any) => (
          <input type="checkbox" checked={value} readOnly disabled />
        ),
      },
      {
        Header: t('Appointments.comment'),
        accessor: 'comment',
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: appointments,
  } = useQuery<AppointmentsResponse | undefined>(
    ['appointments', pageIndex, pageSize, search],
    () =>
      getAppointments({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),

    {
      onSuccess: (data) => {
        if (data) {
          setAppointmentsData(data);
          setAppointmentsLoading(false);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 3000,
      keepPreviousData: true,
      enabled: isMedicalVisiteAdmin || user?.role === UserRoles.Admin,
    }
  );

  const { isLoading: isUserAppointmentsLoading, data: userAppointments } =
    useQuery<AppointmentsResponse | undefined>(
      ['UserAppointments', pageIndex, pageSize, search],
      () =>
        getAppointmentsByUserId({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
          userId: user!.id,
        }),
      {
        refetchOnWindowFocus: false,
        staleTime: 6000,
        keepPreviousData: true,
        enabled: !isMedicalVisiteAdmin && user?.role !== UserRoles.Admin,
        onSuccess: (data) => {
          if (data) {
            setAppointmentsData(data);
            setAppointmentsLoading(false);
          }
        },
      }
    );

  const handleDeleteAppointment = useMutation(
    async (id: string) => {
      await deleteAppointment(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'appointments',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((appointmentsData?.count ?? 0) / pageSize);
  }, [pageSize, appointmentsData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('Appointments.pageName')}
        addbtn={isMedicalVisiteAdmin ? true : false}
        addRouter="new"
      />
      {isAppointmentsLoading && user === null ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={appointmentsData?.count ?? 0}
            data={appointmentsData?.appointments ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {resultModal && (
            <AppointmentResultModal
              isOpen={isModalVisible}
              onSave={() => {
                setResultModal(false);
              }}
              onCancel={() => {
                setResultModal(false);
              }}
              appointment={selectedAppointment}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default MedicalAppointments;
