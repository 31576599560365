import {
  BranchesUrl,
  CompaniesUrl,
  DeparetmentsUrl,
  RapportsURL,
} from '@/variables/Urls';

import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { VacationBalance } from './VacationBalanceService';
import { GenderEnum } from '@/enums/genderEnum';
import { CompanyRHDataModel } from '@/models/CompanyRHDataModel';
import { RatingState } from '@/enums/RatingState';

export interface EmployeeDataModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImgUrl: string;
  departmentName?: string;
  branchName?: string;
  teamName?: string;
  gender: GenderEnum;
  teamLeaderFullName?: string;
  idNumber?: string;
  age?: number | null;
  jobTitle?: string;
  lastAnnualInterviewDate?: Date | null;
  lastTrainingDate?: Date | null;
  lastAppointmentDate?: Date | null;
  cpBalance?: VacationBalance | null;
  rttBalance?: VacationBalance | null;
  ttBalance?: VacationBalance | null;
  vacactionBalanceData?: VacationBalance[] | null;
  emparnVacationBalance?: VacationBalance[] | null;
  rating?: RatingState;
}
export interface GraphDataItem {
  value: number;
  label: string;
  color: string;
}

export interface RHRapportsModel {
  genderGraphData: GraphDataItem[];
  nationalitiesData: GraphDataItem[];
  contractTypeData: GraphDataItem[];
  languageSkillsData: GraphDataItem[];
  visiteMedicalsData: GraphDataItem[];
  disabilityData: GraphDataItem[];
  maritalStateData: GraphDataItem[];
  annualInterviewsData: GraphDataItem[];
  educationLevels: GraphDataItem[];
  enterAndExitData: GraphDataItem[];
  projectExpensesData: GraphDataItem[];
}

export interface TrainingRapports {
  trainingDemanderData: GraphDataItem[];
  trainingCPFData: GraphDataItem[];
  trainingCostPerYearData: GraphDataItem[];
  trainingDiplomaTitleData: GraphDataItem[];
  trainingForJobApplicationData: GraphDataItem[];
  trainingHourPerYearData: GraphDataItem[];
  trainingTypeData: GraphDataItem[];
}
export interface AppointmentRapports {
  apteStateData: GraphDataItem[];
  appointmentDoneStateData: GraphDataItem[];
}

export interface EmployeesOrgChart {
  email: string;
  fullName: string;
  profileImgUrl: string;
  jobTitle?: string;
  branchName?: string;
  departmentName?: string;
  teamName?: string;
  id: string;
  // here we named parentId because we need it in front end with this name
  // here parent is teamLeader
  parentId?: string;
}

export interface EmployeesOrgChartResponse {
  employeesOrgCharts: EmployeesOrgChart[];
  count: number;
}
const apiEndpoint = RapportsURL;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
  year?: number;
  departmentId?: string;
  branchId?: string;
  teamId?: string;
  teamLeaderId?: string;
}
export async function getRHRapports({
  year = new Date().getFullYear(),
}: PaginatingParmas) {
  let config = {
    params: {
      year,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetRHRapports', config);
  return data as RHRapportsModel;
}
export async function getEmpOrgChart() {
  let config = {
    params: {
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetEmployeeOrgChart', config);
  return data as EmployeesOrgChartResponse;
}

export async function getAppointmentRapports({
  year = new Date().getFullYear(),
}: PaginatingParmas) {
  let config = {
    params: {
      year,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetAppointmentsData', config);
  return data as AppointmentRapports;
}
export async function getTrainingsRapports({
  year = new Date().getFullYear(),
}: PaginatingParmas) {
  let config = {
    params: {
      year,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/getTrainingsRapports', config);
  return data as TrainingRapports;
}
export async function getEmployeeData(employeeId: string) {
  let config = {
    params: {
      employeeId: employeeId,
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetEmployeeData', config);
  return data as EmployeeDataModel;
}

export async function getCompanyRHRapports({
  year = new Date().getFullYear(),
  departmentId,
  branchId,
  teamId,
  teamLeaderId,
}: PaginatingParmas) {
  let config = {
    params: {
      year,
      companyId: getCompanyId(),
      branchId,
      departmentId,
      teamId,
      teamLeaderId,
    },
  };
  const { data } = await api.get(apiEndpoint + '/GetCompanyRHRapports', config);
  return data as CompanyRHDataModel;
}
