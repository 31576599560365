import TextArea from '@/components/TextArea';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { InterviewState } from '@/enums/InterviewState';
import { UserRoles } from '@/enums/UsersRole';
import { TrainingNeededModel } from '@/models/AnnualInterviewModel';
import { RootState } from '@/state';
import {
  saveAnnualInterviewAsync,
  updateAttitude,
  updateTrainingNeeded,
} from '@/state/slices/annualInterviewSlice';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
const init: TrainingNeededModel = {
  trainingNeededByEmployee: '',
  trainingNeededByResponsable: '',
};
function TrainingNeeded() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const trainingNeededByEmployee = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.trainingNeededByEmployee
  );
  const trainingNeededByResponsable = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.trainingNeededByResponsable
  );

  const disabled = annualInerview.interviewState == InterviewState.Completed;

  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const formik = useFormik({
    initialValues: {
      ...init,
      trainingNeededByEmployee: trainingNeededByEmployee,
      trainingNeededByResponsable: trainingNeededByResponsable,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(updateTrainingNeeded({ trainingNeeded: values }));
    },
  });
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <div className="flex justify-between gap-4">
          <div className="group relative mb-6 w-full">
            <TextArea
              id="trainingNeededByEmployee"
              name="trainingNeededByEmployee"
              label={t(
                'annualInterviewForm.TrainingNeeded.trainingNeededByEmployee'
              )}
              value={formik.values.trainingNeededByEmployee ?? ''}
              onChange={formik.handleChange}
              disabled={isTeamLeader || disabled}
            />
          </div>
          <div className="group relative mb-6 w-full">
            <TextArea
              id="trainingNeededByResponsable"
              name="trainingNeededByResponsable"
              label={t(
                'annualInterviewForm.TrainingNeeded.trainingNeededByResponsable'
              )}
              value={formik.values.trainingNeededByResponsable ?? ''}
              onChange={formik.handleChange}
              disabled={!isTeamLeader || disabled}
            />
          </div>
        </div>
      </div>

      {!disabled && (
        <div className="flex justify-between">
          <LabelButton type="button" onClick={() => resetForm()}>
            {t('annualInterviewForm.TrainingNeeded.reset')}
          </LabelButton>
          <PrimaryButton
            className="border-0 bg-purple-300 dark:bg-purple-500"
            type="button"
            onClick={handleSyncData}
          >
            <div className="flex items-center gap-2">
              <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
              {t('annualInterviewForm.TrainingNeeded.Sync')}
            </div>
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            className="border-0 bg-primary-300 dark:bg-primary-500"
          >
            {t('annualInterviewForm.TrainingNeeded.submit')}
          </PrimaryButton>
        </div>
      )}
    </form>
  );
}

export default TrainingNeeded;
