import {
  BranchesUrl,
  CompaniesUrl,
  DeparetmentsUrl,
  PartnersUrl,
  SubContractorsUrl,
} from '@/variables/Urls';

import { Country, State } from './CountryService';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { Departement } from './DepartementService';
import { Partner } from './PartnerService';

export interface SubContractor extends Partner {}

export interface SubContractorsResponse {
  subContractors: SubContractor[];
  count: number;
}
const apiEndpoint = SubContractorsUrl;

export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}
export async function getSubContractors({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(apiEndpoint + '/Get', config);
  return data as SubContractorsResponse;
}

export async function getSubContractor(subContractorId: string) {
  const { data } = await api.get<SubContractor>(apiEndpoint + '/GetById', {
    params: { id: subContractorId },
  });

  return data;
}

export async function saveSubContractor(SubContractor: SubContractor) {
  if (SubContractor.id) {
    const body = { ...SubContractor };

    const response = await api.put(apiEndpoint + '/put', body);
    return response;
  }

  const response_1 = await api.post(apiEndpoint + '/post', SubContractor);
  return response_1;
}

export function deleteSubContractor(subContractorId: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: subContractorId } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
