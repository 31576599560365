import React, { useEffect, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
// Update the path
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import DefaultInput from '@/components/Input'; // Assuming you have the DefaultInput component
import { LabelButton } from '@/components/shared/Button';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { addExpense, updateExpense } from '@/state/slices/projectExpensesSlice';
import {
  CountriesResponse,
  Country,
  State,
  getCountries,
} from '@/services/CountryService';
import { useQuery } from '@tanstack/react-query';
import {
  ExpenseCategory,
  expenseCategoryOptions,
} from '@/enums/ExpenseCategory';
import { ExpenseModel } from '@/models/ProjectExpenseModel';
import { Currency, currencyOptions } from '@/enums/Currency';
import { PaymentMethod, paymentMethodOptions } from '@/enums/PaymentMethod';
import ProjectExpenses from '..';
import {
  getCurrencyExchange,
  getExchangeRate,
} from '@/services/ExchangeRateService';
import Spinner from '@/components/Spinner';
import moment from 'moment';

interface ExpenseTableFormProps {
  initialValues?: ExpenseModel;
  onSubmit: (values: ExpenseModel) => void;
  handleClose: (e: any) => void;
  edit?: boolean;
  readOnly?: boolean;
}

const ExpenseTableForm: React.FC<ExpenseTableFormProps> = ({
  initialValues,
  onSubmit,
  handleClose,
  edit = false,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { projectExpense, status } = useSelector(
    (state: RootState) => state.projectExpenses
  );

  const startDate = projectExpense.missionStartDate; // Example startDate
  const endDate = projectExpense.missionEndDate;
  const [states, setStates] = React.useState<State[]>([]);
  const [selectedFromCurrency, setSelectedFromCurrency] =
    React.useState<string>();
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

  const [countries, setCountries] = React.useState<Country[]>([]);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('expenseForm.errors.required')),
    stateId: Yup.string().required(t('expenseForm.errors.required')),
    nature: Yup.string().required(t('expenseForm.errors.required')),
    category: Yup.string().required(t('expenseForm.errors.required')),
    fromCurrency: Yup.string().required(t('expenseForm.errors.required')),
    amountExcludingTax: Yup.number().required(t('expenseForm.errors.required')),
    vat: Yup.number().required(t('expenseForm.errors.required')),
    taxRate: Yup.number().required(t('expenseForm.errors.required')),
    total: Yup.number().required(t('expenseForm.errors.required')),
    expenditureDate: Yup.date()
      .required(t('expenseForm.errors.required'))
      .when([], (value, schema) => {
        return schema.test({
          test: async (expenditureDate) => {
            return (
              (await Yup.date()
                .min(startDate, t('expenseForm.errors.dateRange'))
                .isValid(expenditureDate)) &&
              Yup.date()
                .max(endDate, t('expenseForm.errors.dateRange'))
                .isValid(expenditureDate)
            );
          },
          message: t('expenseForm.errors.dateRange'),
        });
      }),
    paymentMethod: Yup.string().required(t('expenseForm.errors.required')),
  });

  const handleSubmit = (values: ExpenseModel) => {
    const stateName = states.find((x) => x.id == values.stateId)?.name;
    const countryName = countries.find((x) => x.id == values.countryId)?.name;
    var expense = {
      ...values,
      totalEx: totalAfterExchange(exchangeRates, values),
      exchangeRate: exchangeRates[Currency[values.fromCurrency]].value,
      stateName: stateName,
      countryName: countryName,
      total: values.amountExcludingTax + values.vat,
      taxRate: values.vat / values.amountExcludingTax ?? 0,
    };
    if (!!initialValues) {
      dispatch(updateExpense({ expense: expense }));
    } else {
      dispatch(addExpense({ expense: expense }));
    }

    handleClose();
  };

  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      setCountries(data?.countries!);
    },
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });

  const handleCountryChange = (event: any, handleChange: any) => {
    const selectedCountryName = event.target.value;

    const selectedCountryObj = data?.countries?.find(
      (country) => country.id === selectedCountryName
    );

    if (selectedCountryObj !== null && selectedCountryObj !== undefined) {
      setStates(selectedCountryObj.states!);
    } else {
      setStates([]);
    }
    handleChange({
      target: {
        name: 'countryId',
        value: selectedCountryObj!.id || {},
      },
    });
  };
  //TODO:code to add exchange rate
  // const exchangeRates = useMemo(async () => {
  //   return await getCurrencyExchange(
  //     Currency[projectExpense.projectExpenseCurrency],
  //     selectedDate
  //   );
  // }, [projectExpense.projectExpenseCurrency, selectedDate]);

  const {
    data: exchangeRates,
    isLoading: isExchLoading,
    isError,
  } = useQuery(
    [
      'exchangeRates',
      projectExpense.projectExpenseCurrency,
      moment(selectedDate).format('YYYY-MM-DD'),
    ],
    () =>
      getCurrencyExchange(
        Currency[projectExpense.projectExpenseCurrency],
        selectedDate
      ),
    {
      staleTime: 60 * (60 * 1000), // 5 mins
      cacheTime: 120 * (60 * 1000),
    }
  );
  useEffect(() => {
    if (initialValues?.id && countries && !countryLoading) {
      const selectedCountryObj = countries.find(
        (country) => country.id === initialValues.countryId
      );

      if (selectedCountryObj !== null && selectedCountryObj !== undefined) {
        setStates(selectedCountryObj.states!);
      } else {
        setStates([]);
      }
    }
  }, [initialValues?.id, countryLoading, countries]);

  return (
    <div className=" fixed  left-10 top-5 z-50 flex h-full w-full items-center justify-center bg-white  bg-opacity-20 dark:bg-gray-700 dark:bg-opacity-20">
      <div className=" relative mt-20 max-h-full  w-[60%] overflow-y-auto rounded bg-white p-6 shadow-md dark:bg-gray-700">
        <div className="mb-4 text-2xl font-semibold">
          {!!initialValues
            ? t('expenseForm.labels.edit')
            : t('expenseForm.labels.add')}
        </div>
        <button
          type="button"
          className="absolute right-2.5 top-2  inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={handleClose}
        >
          <svg
            className="h-3 w-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">{t('RequestLeaveModal.closeModal')}</span>
        </button>
        {countryLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={
              initialValues || {
                title: '',
                stateId: '',
                nature: '',
                countryId: '',
                category: ExpenseCategory.Others,
                fromCurrency: Currency.EUR,
                amountExcludingTax: 0,
                vat: 0,
                taxRate: 0,
                total: 0,
                expenditureDate: new Date(),
                paymentMethod: PaymentMethod.Others,
                comments: '',
                exchangeRate: 0,
              }
            }
            onSubmit={handleSubmit}
            enableReinitialize={!!initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isValid,
              setFieldValue,
            }) => (
              console.warn('<arrrrr', states),
              (
                <Form>
                  <div className="mb-4">
                    <div className="grid md:grid-cols-4 md:gap-6">
                      <div className="group relative z-0 mb-4 w-full">
                        <DefaultInput
                          id="title"
                          type="text"
                          name="title"
                          label={t('expenseForm.placeholders.title')}
                          onChange={handleChange}
                          value={values.title}
                          errors={errors.title}
                          disabled={readOnly}
                        />
                      </div>
                      <div className="group relative z-0 mb-4 w-full">
                        <DefaultInput
                          id="nature"
                          type="text"
                          name="nature"
                          label={t('expenseForm.placeholders.nature')}
                          onChange={handleChange}
                          value={values.nature}
                          errors={errors.nature}
                          disabled={readOnly}
                        />
                      </div>
                      {/* <div className="group relative z-0 mb-4 w-full">
                    <Dropdown
                      id="category"
                      name="category"
                      value={values.category}
                      optionValue="id"
                      optionLabel="name"
                      labelClassName="w-[40%] pl-0"
                      onChange={handleChange}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={expenseCategoryOptions ?? []}
                      label={t('expenseForm.selectCategory')}
                    />
                  </div> */}

                      <div className="group relative mb-4 w-full">
                        <DatePickerDefault
                          labelDir="Above"
                          label={t('projectExpenses.expenditureDate')}
                          id="expenditureDate"
                          name="expenditureDate"
                          labelClassName="w-[80%]"
                          value={new Date(values?.expenditureDate)}
                          defaultDate={new Date(values?.expenditureDate)}
                          onChange={(date: any) => {
                            setSelectedDate(date);
                            handleChange({
                              target: {
                                name: 'expenditureDate',
                                value: date,
                              },
                            });
                          }}
                          errors={errors.expenditureDate}
                          inputContainerClassName="py-0"
                          disabled={readOnly}
                        />
                      </div>
                      <div className="group relative z-0 mb-4 w-full">
                        <Dropdown
                          id="fromCurrency"
                          name="fromCurrency"
                          value={values.fromCurrency}
                          optionValue="id"
                          optionLabel="name"
                          labelClassName="w-[40%] pl-0"
                          onChange={(e) => {
                            setSelectedFromCurrency(e.target.value);
                            setFieldValue('fromCurrency', e.target.value);
                          }}
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                          options={currencyOptions ?? []}
                          disabled={readOnly}
                          label={t('expenseForm.selectfromCurrency')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="grid md:grid-cols-4 md:gap-6">
                      <div className="group relative z-0 mb-4 w-full">
                        <Dropdown
                          id="countryId"
                          name="countryId"
                          value={values.countryId}
                          optionValue="id"
                          optionLabel="name"
                          labelClassName="w-[40%] pl-0"
                          onChange={(e) => handleCountryChange(e, handleChange)}
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                          options={countries ?? []}
                          disabled={readOnly}
                          label={t('expenseForm.selectCountry')}
                        />
                      </div>
                      <div className="group relative z-0 mb-6 w-full">
                        <Dropdown
                          id="stateId"
                          name="stateId"
                          optionValue="id"
                          labelClassName="w-[40%] pl-0"
                          optionLabel="name"
                          onChange={(e) => {
                            setFieldValue('stateId', e.target.value);
                          }}
                          options={states ?? []}
                          label={t('expenseForm.selectState')}
                          error={errors.stateId}
                          value={values.stateId}
                          disabled={readOnly}
                        />
                      </div>
                      <div className="group relative z-0 mb-4 w-full">
                        <Dropdown
                          id="paymentMethod"
                          name="paymentMethod"
                          value={values.paymentMethod}
                          optionValue="id"
                          optionLabel="name"
                          labelClassName="w-[40%] pl-0"
                          onChange={handleChange}
                          isTranslated={true}
                          optionTransalatedKey="name"
                          translatedNameSpace="PaymentMethod"
                          disabled={readOnly}
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                          options={paymentMethodOptions ?? []}
                          label={t('expenseForm.selectPaymentMethod')}
                        />
                      </div>
                      <div className="group relative mb-4 w-full">
                        <Dropdown
                          id="category"
                          name="category"
                          value={values.category}
                          optionValue="id"
                          disabled={readOnly}
                          optionLabel="name"
                          isTranslated={true}
                          optionTransalatedKey="name"
                          translatedNameSpace="ExpenseCategory"
                          labelClassName="w-[40%] pl-0"
                          onChange={handleChange}
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                          options={expenseCategoryOptions ?? []}
                          label={t('expenseForm.selectCategory')}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mb-4">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="category"
                      name="category"
                      value={values.category}
                      optionValue="id"
                      optionLabel="name"
                      labelClassName="w-[40%] pl-0"
                      onChange={handleChange}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={expenseCategoryOptions ?? []}
                      label={t('expenseForm.selectCategory')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="fromCurrency"
                      name="fromCurrency"
                      value={values.fromCurrency}
                      optionValue="id"
                      optionLabel="name"
                      labelClassName="w-[40%] pl-0"
                      onChange={handleChange}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={currencyOptions ?? []}
                      label={t('expenseForm.selectfromCurrency')}
                    />
                  </div>
                </div>
              </div> */}

                  <div className="mb-4">
                    <div className="grid md:grid-cols-4 md:gap-6">
                      <div className="group relative z-0 mb-4 w-full">
                        <DefaultInput
                          id="amountExcludingTax"
                          type="number"
                          name="amountExcludingTax"
                          disabled={readOnly}
                          label={t(
                            'expenseForm.placeholders.amountExcludingTax'
                          )}
                          onChange={handleChange}
                          labelClassName="w-[80%]"
                          value={values.amountExcludingTax}
                          errors={errors.amountExcludingTax}
                        />
                      </div>
                      <div className="group relative z-0 mb-4 w-full">
                        <DefaultInput
                          id="vat"
                          type="number"
                          name="vat"
                          disabled={readOnly}
                          label={t('expenseForm.placeholders.vat')}
                          onChange={handleChange}
                          labelClassName="w-[80%]"
                          value={values.vat}
                          errors={errors.vat}
                        />
                      </div>
                      <div className="group relative z-0 mb-4 w-full">
                        <DefaultInput
                          id="taxRate"
                          type="number"
                          name="taxRate"
                          disabled={readOnly}
                          labelClassName="w-[80%]"
                          label={t('expenseForm.placeholders.taxRate')}
                          onChange={handleChange}
                          value={values.vat / values.amountExcludingTax ?? 0}
                          errors={errors.taxRate}
                          readOnly
                        />
                      </div>
                      <div className="group relative z-0 mb-4 w-full">
                        <DefaultInput
                          id="total"
                          type="number"
                          disabled={readOnly}
                          name="total"
                          label={t('expenseForm.placeholders.total')}
                          onChange={handleChange}
                          labelClassName="w-[80%]"
                          value={values.amountExcludingTax + values.vat}
                          readOnly
                          errors={errors.total}
                        />
                      </div>

                      {isExchLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <div className="group relative z-0 mb-4 w-full">
                            <DefaultInput
                              id="exhangeRate"
                              type="number"
                              name="exhangeRate"
                              disabled={readOnly}
                              label={t('expenseForm.placeholders.exhangeRate')}
                              labelClassName="w-[80%]"
                              value={
                                exchangeRates[Currency[values.fromCurrency]]
                                  .value
                              }
                              readOnly
                            />
                          </div>

                          <div className="group relative z-0 mb-4 flex w-full">
                            <DefaultInput
                              id="totalEx"
                              type="number"
                              name="totalEx"
                              label={t('expenseForm.placeholders.totalEx')}
                              labelClassName="w-[80%]"
                              value={totalAfterExchange(
                                exchangeRates,
                                values
                              )?.toFixed(2)}
                              readOnly
                            />
                            <span className="mb-1 ml-2 flex items-end justify-end">
                              {Currency[projectExpense.projectExpenseCurrency]}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* <div className="mb-4">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      id="taxRate"
                      type="text"
                      name="taxRate"
                      label={t('expenseForm.placeholders.taxRate')}
                      onChange={handleChange}
                      value={values.taxRate}
                      errors={errors.taxRate}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      id="total"
                      type="text"
                      name="total"
                      label={t('expenseForm.placeholders.total')}
                      onChange={handleChange}
                      value={values.total}
                      errors={errors.total}
                    />
                  </div>
                </div>
              </div> */}
                  {/* <div className="mb-4">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="paymentMethod"
                      name="paymentMethod"
                      value={values.paymentMethod}
                      optionValue="id"
                      optionLabel="name"
                      labelClassName="w-[40%] pl-0"
                      onChange={handleChange}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={paymentMethodOptions ?? []}
                      label={t('expenseForm.selectPaymentMethod')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DatePickerDefault
                      labelDir="inLine"
                      label={t('projectExpenses.expenditureDate')}
                      id="expenditureDate"
                      name="expenditureDate"
                      labelClassName="w-[30%]"
                      value={new Date(values?.expenditureDate)}
                      defaultDate={new Date(values?.expenditureDate)}
                      onChange={(date: any) => {
                        handleChange({
                          target: {
                            name: 'expenditureDate',
                            value: date,
                          },
                        });
                      }}
                      errors={errors.expenditureDate}
                      inputContainerClassName="py-0"
                    />
                  </div>
                </div>
              </div> */}
                  {!readOnly && (
                    <div
                      className="flex h-full items-center justify-between
              "
                    >
                      <LabelButton type="button" onClick={handleClose}>
                        {t('expenseForm.buttons.cancel')}
                      </LabelButton>
                      <button
                        type="button"
                        onClick={(e) => handleSubmit(values)}
                        disabled={!isValid}
                        className=" mr-2 rounded-lg border border-primary-700 px-5 py-2.5 text-center text-sm font-medium text-primary-700 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300 dark:border-primary-500 dark:text-primary-500 dark:hover:bg-primary-600 dark:hover:text-white dark:focus:ring-primary-800 "
                      >
                        {t('expenseForm.buttons.save')}
                      </button>
                    </div>
                  )}
                </Form>
              )
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ExpenseTableForm;
function totalAfterExchange(
  exchangeRates: any,
  values: ExpenseModel
): number | undefined {
  return (
    (values.amountExcludingTax + values.vat) /
    exchangeRates[Currency[values.fromCurrency]].value
  );
}
