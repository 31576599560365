//@ts-nocheck
export function stringToEnumArray<T>(str: string, enumType: any): T[] {
  const enumValues = Object.keys(enumType);
  const strValues = str.split(',').map((s) => s.trim());

  return enumValues.filter((value) => strValues.includes(value)) as T[];
}
export function stringToEnumArrayValues<T>(str: string, enumType: any): T[] {
  const enumValues = Object.values(enumType);
  const strValues = str.split(',').map((s) => s.trim());

  return enumValues.filter((value) => strValues.includes(value)) as T[];
}

export function enumArrayToString<T>(enumArray: T[]): string {
  return enumArray.join(', ');
}

export const convertEnumToCheckboxes = (
  enumObj: any
): [
  {
    id: string;
    label: string;
  }
] => {
  const checkboxes = Object.entries(enumObj).map(([id, label]) => {
    return {
      id,
      label: label.toString(),
    };
  });
  return checkboxes;
};
export const convertEnumToStringArray = (enumObj: any) => {
  const array = Object.entries(enumObj).map(([id, label]) => {
    return label.toString();
  });
  return array;
};
