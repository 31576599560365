import { createContext, useContext, useEffect } from 'react';

import SignalRService from '@/services/SignalRService';
import { getJwt } from '@/services/AuthService';

interface SignalRContextType {
  startConnection: () => void;
  stopConnection: () => void;
}

const SignalRContext = createContext<SignalRContextType | undefined>(undefined);

export const SignalRProvider = ({ children }) => {
  useEffect(() => {
    if (getJwt()) {
      SignalRService.startConnection();
    }

    return () => {
      SignalRService.stopConnection();
    };
  }, [SignalRService.startConnection, SignalRService.stopConnection]);

  return (
    <SignalRContext.Provider
      value={{
        startConnection: SignalRService.startConnection,
        stopConnection: SignalRService.stopConnection,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalR = () => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error('useSignalR must be used within a SignalRProvider');
  }
  return context;
};
