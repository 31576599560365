import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import {
  Competitor,
  getCompetitor,
  saveCompetitor,
} from '@/services/CompetitorService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('CompetitorForm.nameRequired'),
});

interface FormValues extends Competitor {
  name: string;
}

function CompetitorForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    name: '',
    id: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: competitorData,
    isLoading,
    refetch: refetchCompetitor,
  } = useQuery<Competitor | undefined>(
    ['competitor', id],
    async () => await getCompetitor(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (competitor) => {
        if (competitor) {
          setFormikValuesState((prev) => ({ ...prev, ...competitor }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && competitorData) {
      // Update form values with competitor data
      setFormikValuesState((prev) => ({ ...prev, ...competitorData }));
    }
  }, [editForm, competitorData]);

  const handleSaveCompetitor = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const companyId = getCompanyId();
      const competitor: Competitor = {
        ...values,
        companyId: companyId!,
      };

      const data = await saveCompetitor(competitor);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.Competitor'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.Competitor'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchCompetitor();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('CompetitorForm.editCompetitor')
            : t('CompetitorForm.addCompetitor')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveCompetitor}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="name"
                  id="name"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.name}
                  label={t('CompetitorForm.nameLabel')}
                />
              </div>

              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('CompetitorForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('CompetitorForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default CompetitorForm;
