import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

export interface IReactTimePickerDefaultProps {
  label?: string;
  errors?: string;
  name: string;
  onChange: (date: Date) => void;
  touched?: any;
  value: Date;
  defaultDate?: Date;
  id: string;
  disabled?: boolean;
  containerClass?: string;
  labelClassName?: string;
  labelDir?: 'inLine' | 'Above';
}

const userTimeZone = moment.tz.guess();
const ReactTimePickerDefault = ({
  id,
  label,
  errors,
  onChange,
  value,
  labelDir = 'Above',
  containerClass,
  labelClassName,
}: IReactTimePickerDefaultProps) => {
  const { t } = useTranslation();
  const [showTime, setShowTime] = useState<boolean>(false);
  const timePickerRef = useRef<HTMLDivElement>(null);

  const handleTimeChange = (selectedTime: Date) => {
    onChange(selectedTime);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target as Node)
      ) {
        setShowTime(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const inputClass = classNames(
    'block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 ',
    {
      'text-red-700 dark:text-red-500 bg-red-300 dark:bg-red-300': errors,
    }
  );

  return (
    <div
      className={classNames(
        containerClass ?? '',
        labelDir == 'Above'
          ? 'relative '
          : ' relative inline-flex w-full items-baseline  gap-4 '
      )}
      ref={timePickerRef}
    >
      <label
        htmlFor={id}
        className={classNames(
          labelClassName,
          `
         mb-2 block w-[30%] text-sm font-medium text-gray-900 dark:text-white
        ${errors ? 'text-red-700 dark:text-red-500' : ''}
      `
        )}
      >
        {label}
      </label>

      <DatePicker
        selected={new Date(value)}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        className={inputClass}
      />

      {errors && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors)}
        </p>
      )}
    </div>
  );
};

export default ReactTimePickerDefault;
