import { Option } from '@/components/DropDown';

let minOffset = 0,
  maxOffset = 10;

export var languages = [
  { code: 'en', name: 'English', countryCode: 'GB' },
  { code: 'es', name: 'Spanish', countryCode: 'ES' },
  // { code: 'ar', name: 'Arabic', countryCode: 'SA' },
  { code: 'fr', name: 'French', countryCode: 'FR' },
  { code: 'de', name: 'German', countryCode: 'DE' },
];

export const SupportPhoneNumber = '+33615311815';

export var DaysCalendarColor = {
  weekend: 'bg-yellow-300', // Use your preferred color
  holiday: 'bg-red-300', // Use your preferred color
  enterpriseClosing: 'bg-purple-300', // Use your preferred color
};

export const DaysCalendarColorHex = {
  weekend: '#D97706', // Darker shade of yellow
  holiday: '#C53030', // Darker shade of red
  enterpriseClosing: '#6B46C1', // Darker shade of purple
  default: '#1F2937',
};

export const genderOptions = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
  { id: 3, name: 'No Answer' },
];

export const maritalStatusOptions = [
  { id: 1, name: 'Single' },
  { id: 2, name: 'Married' },
  { id: 3, name: 'Divorced' },
  { id: 4, name: 'Widowed' },
];

export const getYearOptions = (): Option[] => {
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  return allYears.map((x) => {
    return {
      id: x,
      name: x.toString(),
    };
  });
};
