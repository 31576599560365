import { InterviewState } from '@/enums/InterviewState';
import { SkillLevel } from '@/enums/SkillLevel';
import {
  AnnualInterviewModel,
  AttitudeModel,
  CollebratoEvolutionModel,
  CommentsAndSignedModel,
  CompetencesModel,
  DynamismeModel,
  LastYearReviewModel,
  MobilityModel,
  ObjectivesAndTasksModel,
  ProffessionalPerspectivesModel,
  RelationnelModel,
  RepresentationModel,
  RigorModel,
  TechnicalSkillsModel,
  TrainingNeededModel,
} from '@/models/AnnualInterviewModel';
import Mobility from '@/pages/AnnualInterview/components/Mobility';
import {
  getAnnualInterviewById,
  saveAnnualInterview,
} from '@/services/AnnualInterviewService';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

// Async Thunk for fetching job description by employee ID
export const fetchAnnualInterviewById = createAsyncThunk<
  initStateProps,
  string
>('annualInteview/fetchById', async (id: string, thunkAPI) => {
  try {
    const response: AnnualInterviewModel = await getAnnualInterviewById(id);
    return {
      annualInterview: response,
      status: 'succeeded',
      error: undefined,
    };
  } catch (error: any) {
    // Handle errors here if needed
    return thunkAPI.rejectWithValue({
      annualInterview: null,
      status: 'failed',
      error: error.message,
    });
  }
});

// Async Thunk for saving/updating job description
export const saveAnnualInterviewAsync = createAsyncThunk(
  'annualInterviews/save',
  async (annualInterview: AnnualInterviewModel) => {
    const response = await saveAnnualInterview(annualInterview);
    return response;
  }
);
const initialAnnualInterviewModel = {
  id: undefined,
  employeeId: '',
  responsibleId: '',
  interviewDate: new Date(),
  technicalSkills: null,
  objectivesAndTasks: null,
  attitude: null,
  representation: null,
  relationnel: null,
  rigor: null,
  dynamisme: null,
  competences: null,
  lastYearReviewByEmployee: null,
  lastYearReviewByResponsable: null,
  nextYearObjectives: null,
  reasonForJobChange: false,
  workplaceChange: false,
  expatriation: false,
  employeeProffessionalPerspectives: null,
  responsableProffessionalPerspectives: null,
  trainingNeededByEmployee: null,
  trainingNeededByResponsable: null,
  strengthsToDevelop: null,
  areasToImprove: null,
  overallEvaluation: SkillLevel.Bien,
  employeeComments: null,
  responsableComments: null,
  validatedByEmployee: false,
  validatedByResponsable: false,
  signedByEmployee: false,
  signedByResponsable: false,
  interviewState: InterviewState.Scheduled,
  employeeFullName: '',
  responsibleFullName: '',
};
export interface initStateProps {
  annualInterview: AnnualInterviewModel;
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  error: string | undefined;
}
// Initial state
const initialState: initStateProps = {
  annualInterview: initialAnnualInterviewModel,
  status: 'idle',
  error: undefined,
};

// Create the slice
const annualInterviewSlice = createSlice({
  name: 'annualInterviews',
  initialState,
  reducers: {
    updateGeneralAnnualInterviewValues(
      state,
      action: PayloadAction<{ annualInterview: AnnualInterviewModel }>
    ) {
      state.annualInterview = action.payload.annualInterview;
    },
    updateTechnicalSkills(
      state,
      action: PayloadAction<{ technicalSkills: TechnicalSkillsModel }>
    ) {
      state.annualInterview.technicalSkills = action.payload.technicalSkills;
    },

    addTechnicalSkills(
      state,
      action: PayloadAction<{ technicalSkills: TechnicalSkillsModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.technicalSkills = action.payload.technicalSkills;
    },

    updateObjectivesAndTasks(
      state,
      action: PayloadAction<{ objectivesAndTasks: ObjectivesAndTasksModel }>
    ) {
      state.annualInterview.objectivesAndTasks =
        action.payload.objectivesAndTasks;
    },

    addObjectivesAndTasks(
      state,
      action: PayloadAction<{ objectivesAndTasks: ObjectivesAndTasksModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.objectivesAndTasks =
        action.payload.objectivesAndTasks;
    },

    updateAttitude(state, action: PayloadAction<{ attitude: AttitudeModel }>) {
      state.annualInterview.attitude = action.payload.attitude;
    },

    addAttitude(state, action: PayloadAction<{ attitude: AttitudeModel }>) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.attitude = action.payload.attitude;
    },

    updateDynamisme(
      state,
      action: PayloadAction<{ dynamisme: DynamismeModel }>
    ) {
      state.annualInterview.dynamisme = action.payload.dynamisme;
    },

    addDynamisme(state, action: PayloadAction<{ dynamisme: DynamismeModel }>) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.dynamisme = action.payload.dynamisme;
    },

    updateCompetences(
      state,
      action: PayloadAction<{ competences: CompetencesModel }>
    ) {
      state.annualInterview.competences = action.payload.competences;
    },

    addCompetences(
      state,
      action: PayloadAction<{ competences: CompetencesModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.competences = action.payload.competences;
    },
    updateRepresentation(
      state,
      action: PayloadAction<{ representation: RepresentationModel }>
    ) {
      state.annualInterview.representation = action.payload.representation;
    },

    addRepresentation(
      state,
      action: PayloadAction<{ representation: RepresentationModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.representation = action.payload.representation;
    },
    updateRelationnel(
      state,
      action: PayloadAction<{ relationnel: RelationnelModel }>
    ) {
      state.annualInterview.relationnel = action.payload.relationnel;
    },

    addRelationnel(
      state,
      action: PayloadAction<{ relationnel: RelationnelModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.relationnel = action.payload.relationnel;
    },
    updateRigor(state, action: PayloadAction<{ rigor: RigorModel }>) {
      state.annualInterview.rigor = action.payload.rigor;
    },

    addRigor(state, action: PayloadAction<{ rigor: RigorModel }>) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.rigor = action.payload.rigor;
    },
    updateTrainingNeeded(
      state,
      action: PayloadAction<{ trainingNeeded: TrainingNeededModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.trainingNeededByEmployee =
        action.payload.trainingNeeded.trainingNeededByEmployee;
      state.annualInterview.trainingNeededByResponsable =
        action.payload.trainingNeeded.trainingNeededByResponsable;
    },
    updateLastYearReview(
      state,
      action: PayloadAction<{ lastYearReview: LastYearReviewModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.lastYearReviewByEmployee =
        action.payload.lastYearReview.lastYearReviewByEmployee;
      state.annualInterview.lastYearReviewByResponsable =
        action.payload.lastYearReview.lastYearReviewByResponsable;
    },
    updateProffessionalPerspectives(
      state,
      action: PayloadAction<{
        proffessionalPerspectives: ProffessionalPerspectivesModel;
      }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.employeeProffessionalPerspectives =
        action.payload.proffessionalPerspectives.employeeProffessionalPerspectives;
      state.annualInterview.responsableProffessionalPerspectives =
        action.payload.proffessionalPerspectives.responsableProffessionalPerspectives;
    },
    updateNextYearObjectives(
      state,
      action: PayloadAction<{ nextYearObjectives: string | null }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.nextYearObjectives =
        action.payload.nextYearObjectives;
    },
    updateMobility(state, action: PayloadAction<{ mobility: MobilityModel }>) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.reasonForJobChange =
        action.payload.mobility.reasonForJobChange;
      state.annualInterview.workplaceChange =
        action.payload.mobility.workplaceChange;
      state.annualInterview.expatriation = action.payload.mobility.expatriation;
    },
    updateColleberatorEvolution(
      state,
      action: PayloadAction<{ colleberatorEvoutlion: CollebratoEvolutionModel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.strengthsToDevelop =
        action.payload.colleberatorEvoutlion.strengthsToDevelop;
      state.annualInterview.areasToImprove =
        action.payload.colleberatorEvoutlion.areasToImprove;
    },
    updateOverallEvoutlion(
      state,
      action: PayloadAction<{ overallEvaluation: SkillLevel }>
    ) {
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.overallEvaluation =
        action.payload.overallEvaluation;
    },
    updateCommentsAndSigned(
      state,
      action: PayloadAction<{ commentsAndSigned: CommentsAndSignedModel }>
    ) {
      console.log(
        '🚀 ~ file: annualInterviewSlice.ts:288 ~ commentsAndSigned:',
        action.payload.commentsAndSigned
      );
      // You may want to handle adding logic here based on your requirements
      state.annualInterview.employeeComments =
        action.payload.commentsAndSigned.employeeComments;
      state.annualInterview.responsableComments =
        action.payload.commentsAndSigned.responsableComments;
      state.annualInterview.signedByEmployee =
        action.payload.commentsAndSigned.signedByEmployee;
      state.annualInterview.signedByResponsable =
        action.payload.commentsAndSigned.signedByResponsable;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetching job description by employee ID
    builder.addCase(fetchAnnualInterviewById.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchAnnualInterviewById.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.annualInterview = action.payload.annualInterview;
    });
    builder.addCase(fetchAnnualInterviewById.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });

    // Reducer for saving/updating job description
    builder.addCase(saveAnnualInterviewAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(saveAnnualInterviewAsync.fulfilled, (state, action) => {
      state.status = 'succeeded';
    });
    builder.addCase(saveAnnualInterviewAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

// Export actions and reducer
export const {
  addCompetences,
  updateCompetences,
  addDynamisme,
  updateDynamisme,
  addAttitude,
  updateAttitude,
  addObjectivesAndTasks,
  updateObjectivesAndTasks,
  addTechnicalSkills,
  updateTechnicalSkills,
  updateRelationnel,
  updateRepresentation,
  addRelationnel,
  addRepresentation,
  addRigor,
  updateRigor,
  updateGeneralAnnualInterviewValues,
  updateTrainingNeeded,
  updateLastYearReview,
  updateNextYearObjectives,
  updateProffessionalPerspectives,
  updateMobility,
  updateColleberatorEvolution,
  updateOverallEvoutlion,
  updateCommentsAndSigned,
} = annualInterviewSlice.actions;
export default annualInterviewSlice.reducer;
