import { TFunction } from 'i18next';

export enum VisitReason {
  PreRecoveryVisit = 'PreRecoveryVisit',
  InitialInformationAndPreventionVisit = 'InitialInformationAndPreventionVisit',
  PeriodicInformationAndPreventionVisit = 'PeriodicInformationAndPreventionVisit',
  ReinforcedIndividualFollowUp = 'ReinforcedIndividualFollowUp',
  RecoveryVisit = 'RecoveryVisit',
  LiaisonAppointment = 'LiaisonAppointment',
  OccasionalVisit = 'OccasionalVisit',
  MidCareerMedicalVisit = 'MidCareerMedicalVisit',
  EndOfCareerMedicalVisit = 'EndOfCareerMedicalVisit',
}

export const mapVisiteReasonToFrontendType = (
  backendType: number
): string | null => {
  switch (backendType) {
    case 0:
      return VisitReason.PreRecoveryVisit;
    case 1:
      return VisitReason.InitialInformationAndPreventionVisit;
    case 2:
      return VisitReason.PeriodicInformationAndPreventionVisit;
    case 3:
      return VisitReason.ReinforcedIndividualFollowUp;
    case 4:
      return VisitReason.RecoveryVisit;
    case 5:
      return VisitReason.LiaisonAppointment;
    case 6:
      return VisitReason.OccasionalVisit;
    case 7:
      return VisitReason.MidCareerMedicalVisit;
    case 8:
      return VisitReason.EndOfCareerMedicalVisit;
    default:
      return null;
  }
};

export const getVisitReasonsArray = (t: TFunction) => {
  return Object.entries(VisitReason)
    .filter(([key, value]) => typeof value === 'string')
    .map(([key, value]) => ({
      id: value as string,
      label: t(`VisitReasons.${VisitReason[key].toString()}`),
    }));
};
