export enum PaymentMethod {
  CreditCard,
  Check,
  Cash,
  MealVoucher,
  Others,
}

export const paymentMethodOptions = Object.keys(PaymentMethod)
  .filter((key) => isNaN(Number(PaymentMethod[key])))
  .map((key) => ({
    id: key,
    name: PaymentMethod[key],
  }));
