import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import {
  CompaniesResponse,
  getCompanies,
  deleteCompany,
} from '@/services/CompanyService';
import {
  disableEmployee,
  EmployeesResponse,
  deleteEmployee,
  getEmployees,
  getEmployeesByTeamId,
  getEmployeesByTeamLeaderId,
  activeEmployee,
} from '@/services/EmployeeService';
import DynamicTable, {
  SelectColumnFilter,
  ModuleTypeCell,
  StatusLicense,
} from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  GreenButton,
  PrimaryButton,
  PurpleButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { UserRoles, UserRolesValues, userRolesArray } from '@/enums/UsersRole';
import { LockClosedIcon } from '@heroicons/react/solid';
import EmployeeRoleChangerModal from './components/EmployeeRoleChangerModal';
import {
  AddResponsibilityToUser,
  updateUser,
  updateUserRole,
} from '@/services/UserService';
import { siteName } from '@/variables/Urls';
import { UserResponsibility } from '@/enums/UserResponsibility';

function Employees() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDesactiveModalVisible, setIsDesactiveModalVisible] = useState(false);
  const [isRoleChangeModalVisible, setRoleChangeModalVisibleVisible] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [selectedRole, setSelectedrole] = useState(UserRoles.Default);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const isDevelopment = import.meta.env.MODE === 'development';
  const onlyAdminAccess = useMemo(
    () => user?.role !== UserRoles.Admin,
    [user?.role]
  );
  const onlyRHAdminAccess = useMemo(
    () =>
      user?.responsibilityClaim ==
      UserResponsibility[UserResponsibility.GestionDesRH].toString(),
    [user?.responsibilityClaim]
  );
  const AvatarCell = ({ cell: { value } }: any) => {
    var url = isDevelopment ? siteName + value : value;
    return (
      <div className="w-10">
        <img
          className="h-10 w-10 rounded-full object-cover"
          src={url}
          alt="Profile Avatar"
        />
      </div>
    );
  };
  const ActionButtonsCell = ({ value, rowData }: any) => {
    const role = Object.values(UserRoles)[rowData.original['employeeRole']];
    const isDisabled = rowData.original['disabled'];

    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('Employees.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${pathname}/${value}`);
          }}
        >
          {t('Employees.editButton')}
        </EditButton>

        {!isDisabled ? (
          <PurpleButton
            type="button"
            onClick={() => {
              // disable the employee
              setSelectedRow(value);
              setIsDesactiveModalVisible(true);
            }}
          >
            {t('Employees.disableButton')}
          </PurpleButton>
        ) : (
          <GreenButton
            type="button"
            onClick={() => {
              handleActiveEmployee.mutate(value);
            }}
          >
            {t('Employees.activeButton')}
          </GreenButton>
        )}
        {/* <PrimaryButton
          type="button"
          onClick={() => {
            setSelectedRow(value);
            setSelectedrole(role);
            setRoleChangeModalVisibleVisible(true);
          }}
          disabled={true}
        >
          {t(`AdminRoles.${role}`)}
          {showLockedBtn(role)}
        </PrimaryButton>
        <PrimaryButton
          type="button"
          onClick={() => {
            setSelectedRow(value);
            setSelectedrole(role);
            setRoleChangeModalVisibleVisible(true);
          }}
          disabled={user?.role !== UserRoles.Admin}
        >
          {t(`AdminRoles.${role}`)}
          {showLockedBtn(role)}
        </PrimaryButton> */}
      </div>
    );
  };

  const showLockedBtn = (role: UserRoles) => {
    if (user?.role !== UserRoles.Admin) {
      return <LockClosedIcon className="h-4 w-4" />;
    } else {
      if (role === UserRoles.TeamLeader) {
        <LockClosedIcon className="h-4 w-4" />;
      } else {
        return;
      }
    }
  };
  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteCompany.mutate(selectedRow);
    setIsModalVisible(false);
  };
  const handleCancelDesactive = () => {
    setIsDesactiveModalVisible(false);
  };

  const handleDesactiveConfirmation = () => {
    handleDesactiveCompany.mutate(selectedRow);
    setIsDesactiveModalVisible(false);
  };

  const handleChangeRoleConfirmation = () => {
    handleChangeUserRoleMutation.mutate({
      userRole: selectedRole,
      userId: selectedRow,
    });
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'profileImgUrl',
        Cell: AvatarCell,
      },
      {
        Header: t('Employees.firstNameHeader'),
        accessor: 'firstName',
      },
      {
        Header: t('Employees.lastNameHeader'),
        accessor: 'lastName',
        Filter: SelectColumnFilter,
      },

      {
        Header: t('Employees.actionsHeader'),
        accessor: 'id',
        Cell: ({ cell: { value, row } }: any) => (
          <ActionButtonsCell
            value={value}
            rowData={row}
            handleDelete={handleDeleteCompany}
          />
        ),
      },
      {
        Header: t('Employees.Role'),
        accessor: 'employeeRole',
        Cell: ({ cell: { value, row } }: any) => {
          const role = Object.values(UserRoles)[value];

          return (
            <PrimaryButton type="button" disabled={onlyAdminAccess}>
              {t(`AdminRoles.${role}`)}
            </PrimaryButton>
          );
        },
      },
      {
        Header: t('Employees.Responsibility'),
        accessor: 'employeeResponsibility',
        Cell: ({ cell: { value, row } }: any) => {
          let role =
            Object.values(UserResponsibility)[
              row.original['employeeResponsibility']
            ];
          const userId = row.original['id'];
          if (role === undefined) {
            role = 'Nothing';
          }
          return (
            <PrimaryButton
              type="button"
              disabled={onlyAdminAccess}
              onClick={() => {
                setSelectedRow(userId);
                setSelectedrole(role);
                setRoleChangeModalVisibleVisible(true);
              }}
            >
              {t(`AccessRight.${role}`)}
            </PrimaryButton>
          );
        },
      },
      {
        Header: t('Employees.emailHeader'),
        accessor: 'email',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('Employees.jobTitleHeader'),
        accessor: 'jobTitle',
      },
      {
        Header: t('Employees.departementNameHeader'),
        accessor: 'departementName',
      },
      {
        Header: t('Employees.branchNameHeader'),
        accessor: 'branchName',
      },
      {
        Header: t('Employees.TeamName'),
        accessor: 'teamName',
      },
      {
        Header: t('Employees.TeamLeaderEmail'),
        accessor: 'teamLeaderEmail',
      },
      {
        Header: t('Employees.TeamLeaderName'),
        accessor: 'teamLeaderFullName',
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  // const {
  //   isLoading,
  //   isError,
  //   data: employees,
  // } = useQuery<EmployeesResponse | undefined>(
  //   ['employees', pageIndex, pageSize, search],
  //   () =>
  //     getEmployees({
  //       fromvalue: pageIndex,
  //       takevalue: pageSize,
  //       search: search,
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     staleTime: 6000,
  //     keepPreviousData: true,
  //   }
  // );

  const {
    isLoading,
    isError,
    data: employees,
  } = useQuery<EmployeesResponse | undefined>(
    ['employees', pageIndex, pageSize, search],
    () => {
      if (user && user?.isTeamLeader == 'True') {
        return getEmployeesByTeamLeaderId({
          teamLeaderId: user.id,
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
        });
      } else {
        // Fetch all employees
        return getEmployees({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  const handleDeleteCompany = useMutation(
    async (id: string) => {
      await deleteEmployee(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'employees',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );
  const handleDesactiveCompany = useMutation(
    async (id: string) => {
      await disableEmployee(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'employees',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const handleActiveEmployee = useMutation(
    async (id: string) => {
      await activeEmployee(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'employees',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );
  const handleChangeUserRoleMutation = useMutation(
    async ({ userId, userRole }: { userId: string; userRole: string }) => {
      await AddResponsibilityToUser(userId, userRole);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'employees',
          pageIndex,
          pageSize,
          search,
        ]);
        setRoleChangeModalVisibleVisible(false);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((employees?.count ?? 0) / pageSize);
  }, [pageSize, employees]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('Employees.pageName')}
        addbtn={true}
        addRouter={`${pathname}/new`}
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={employees?.count ?? 0}
            data={employees?.employees ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {isDesactiveModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              textContent={t('Employees.deactivate_confirmation')}
              onDelete={handleDesactiveConfirmation}
              onCancel={handleCancelDesactive}
            />
          )}
          {isRoleChangeModalVisible && (
            <EmployeeRoleChangerModal
              isOpen={isModalVisible}
              onChangeRole={handleChangeRoleConfirmation}
              onCancel={() => setRoleChangeModalVisibleVisible(false)}
              textContent={t('ChangeRoleTitle')}
              role={selectedRole}
              setSelectedRole={setSelectedrole}
              userId={selectedRow}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default Employees;
