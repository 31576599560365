import { useAuth } from '@/context/AuthContext';
import { getImageByUserId } from '@/services/ImageService';
import { siteName } from '@/variables/Urls';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

function getFirstLetter(str: string) {
  return str.charAt(0).toUpperCase();
}

export interface AvatarProps {
  username?: string;
  email?: string;
  id?: string;
}
export function Avatar({ username, email }: AvatarProps) {
  const { user: currentUser } = useAuth();

  const {
    data: imageData,
    refetch,
    isSuccess,
  } = useQuery<string | undefined>(
    ['image', currentUser?.id],
    () => {
      return getImageByUserId(currentUser?.id!, currentUser?.role!);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: false,
    }
  );

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role && currentUser?.id) {
        refetch();
      }
    }
  }, [currentUser]);

  const letter = getFirstLetter(username || email!);
  const isDevelopment = import.meta.env.MODE === 'development';

  var url = useMemo(
    () => (isDevelopment ? siteName + imageData : imageData),
    [imageData]
  );
  return imageData && isSuccess ? (
    <img
      className="h-12 w-12 items-center justify-center rounded-full object-cover"
      src={url}
      alt="Default avatar"
    />
  ) : (
    <span className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-500 text-xl font-bold text-white">
      {letter}
    </span>
  );
}

export default Avatar;
