import Accordion from '@/components/Accordian';
import DefaultLayout from '@/layout/DefaultLayout';
import React, { useEffect, useMemo } from 'react';
import Input from '@/components/Input';
import SkillTable from './components/TechinicalLevelTable';
import ObjectivesAndTasksTable from './components/ObjectivesAndTasksTable';
import AttitudeTable from './components/AttitudeTable';
import RepresentationTable from './components/RepresentationTable';
import RelationnelTable from './components/RelationnelTable';
import RigorTable from './components/RigorTable';
import DynamismeTable from './components/DynamismeTable';
import CompetencesTable from './components/CompetencesTable';
import {
  AnnualInterviewModel,
  CommentsAndSignedModel,
} from '@/models/AnnualInterviewModel';
import { getAnnualInterviewBriefById } from '@/services/AnnualInterviewService';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobDescriptionByEmployeeId } from '@/state/slices/jobdescriptionSlice';
import {
  fetchAnnualInterviewById,
  saveAnnualInterviewAsync,
  updateCommentsAndSigned,
} from '@/state/slices/annualInterviewSlice';
import TrainingNeeded from './components/TrainingNeeded';
import LastYearReview from './components/LastYearReview';
import NextYearObjectives from './components/NextYearObjectives';
import ProffessionalPerspectives from './components/ProffessionalPerspectives';
import Mobility from './components/Mobility';
import TextArea from '@/components/TextArea';
import { t } from 'i18next';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import ColleberatorEvolution from './components/ColleberatorEvolution';
import GlobalEvolution from './components/GlobalEvolution';
import SignalRService from '@/services/SignalRService';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { InterviewState } from '@/enums/InterviewState';
import EmployeeCard from './components/EmployeeCard';
import EmployeeTrainingTable from './components/EmployeeTrainingTable';
import SignedAndComments from './components/SignedAndComments';
import { useTranslation } from 'react-i18next';

const init: CommentsAndSignedModel = {
  signedByResponsable: false,
  signedByEmployee: false,
  employeeComments: '',
  responsableComments: '',
};
function AnnualInterviewMeeting() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const { user } = useAuth();
  const isTeamLeader = user?.isTeamLeader == 'True';
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { annualInterview, status } = useSelector(
    (state: RootState) => state.annualInterviews
  );
  useEffect(() => {
    if (id) {
      dispatch(fetchAnnualInterviewById(id));
      //startConnection();
      SignalRService.onReceiveAnnualInterviewSync(() => {
        // Handle the received notification
        dispatch(fetchAnnualInterviewById(id!));
        // setNotifications((prevNotifications) => [...prevNotifications, notification]);
      });
    }
  }, [dispatch, id]);

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     ...init,
  //     employeeComments: annualInterview.employeeComments,
  //     responsableComments: annualInterview.responsableComments,
  //     signedByEmployee: annualInterview.signedByEmployee,
  //     signedByResponsable: annualInterview.signedByResponsable,
  //   },
  //   onSubmit: (values) => {
  //     console.log(
  //       '🚀 ~ file: AnnualInterviewMeeting.tsx:87 ~ AnnualInterviewMeeting ~ values:',
  //       values
  //     );
  //     dispatch(
  //       updateCommentsAndSigned({
  //         commentsAndSigned: {
  //           employeeComments: values.employeeComments,
  //           responsableComments: values.responsableComments,
  //           signedByEmployee: values.signedByEmployee,
  //           signedByResponsable: values.signedByResponsable,
  //         },
  //       })
  //     );
  //     console.log('this is annual interview ', annualInterview);

  //     dispatch(saveAnnualInterviewAsync(annualInterview));
  //   },
  // });
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInterview));
  };

  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <div className="bg-white dark:bg-boxdark-2 dark:text-bodydark ">
        <Accordion title={t('AnnualInterviewMeeting.EmployeeInfo')}>
          <EmployeeCard />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.LastYearReview')}>
          <LastYearReview />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.NextYearObjectives')}>
          <NextYearObjectives />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.Mobility')}>
          <Mobility />
        </Accordion>

        <Accordion
          title={t('AnnualInterviewMeeting.ProffessionalPerspectives')}
        >
          <ProffessionalPerspectives />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.UserTrainingTable')}>
          <EmployeeTrainingTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.TrainingNeeded')}>
          <TrainingNeeded />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.UserSkills')}>
          <SkillTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.CompetencesTable')}>
          <CompetencesTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.DynamismeTable')}>
          <DynamismeTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.RigorTable')}>
          <RigorTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.RelationnelTable')}>
          <RelationnelTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.RepresentationTable')}>
          <RepresentationTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.AttitudeTable')}>
          <AttitudeTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.ObjectivesAndTasksTable')}>
          <ObjectivesAndTasksTable />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.CollebratorEvolution')}>
          <ColleberatorEvolution />
        </Accordion>

        <Accordion title={t('AnnualInterviewMeeting.OverallEvolution')}>
          <GlobalEvolution />
        </Accordion>

        <SignedAndComments />
      </div>
    </DefaultLayout>
  );

  // function newFunction() {
  //   return (
  //     <form onSubmit={formik.handleSubmit}>
  //       <div className="flex justify-between gap-4 px-10 py-5">
  //         <div className="group relative mb-6 w-full">
  //           <TextArea
  //             id="employeeComments"
  //             name="employeeComments"
  //             label={t('annualInterviewForm.employeeComments')}
  //             value={formik.values.employeeComments ?? ''}
  //             onChange={formik.handleChange}
  //             disabled={isTeamLeader}
  //           />
  //         </div>
  //         <div className="group relative mb-6 w-full">
  //           <TextArea
  //             id="responsableComments"
  //             name="responsableComments"
  //             label={t('annualInterviewForm.responsableComments')}
  //             value={formik.values.responsableComments ?? ''}
  //             onChange={formik.handleChange}
  //             disabled={!isTeamLeader}
  //           />
  //         </div>
  //       </div>
  //       <div className="px-10 py-5">
  //         <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
  //           <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
  //             <tr>
  //               <th scope="col" className=" px-6 py-3">
  //                 {t('annualInterviewForm.SignedByEmployee')}
  //               </th>
  //               <th scope="col" className=" px-6 py-3">
  //                 {t('annualInterviewForm.SignedByResponsable')}
  //               </th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
  //               <td className="px-6 py-4 pl-10">
  //                 <input
  //                   id="signedByEmployee"
  //                   name="signedByEmployee"
  //                   type="checkbox"
  //                   checked={formik.values.signedByEmployee ?? false}
  //                   onChange={(e) => {
  //                     formik.handleChange(e);
  //                     const newValue = e.target.checked;
  //                     formik.setFieldValue('signedByEmployee', newValue);
  //                   }}
  //                   disabled={isTeamLeader}
  //                 />
  //               </td>
  //               <td className="px-6 py-4  pl-10">
  //                 <input
  //                   id="signedByResponsable"
  //                   name="signedByResponsable"
  //                   type="checkbox"
  //                   checked={formik.values.signedByResponsable ?? false}
  //                   onChange={(e) => {
  //                     formik.handleChange(e);
  //                     const newValue = e.target.checked;
  //                     formik.setFieldValue('signedByResponsable', newValue);
  //                   }}
  //                   disabled={!isTeamLeader}
  //                 />
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>
  //       <div className="flex justify-end px-10 py-5">
  //         {/* <PrimaryButton
  //         className="border-0 bg-purple-300"
  //         type="button"
  //         onClick={() => {}}
  //       >
  //         <div className="flex items-center gap-2">
  //           <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
  //           {t('annualInterviewForm.Mobility.Sync')}
  //         </div>
  //       </PrimaryButton> */}
  //         {annualInterview.interviewState === InterviewState.InProgress && (
  //           <PrimaryButton type="submit" className="border-0 bg-primary-300">
  //             {t('annualInterviewForm.Mobility.submit')}
  //           </PrimaryButton>
  //         )}
  //       </div>
  //     </form>
  //   );
  // }
}

export default AnnualInterviewMeeting;
