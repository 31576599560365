import TextArea from '@/components/TextArea';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { useAuth } from '@/context/AuthContext';
import { InterviewState } from '@/enums/InterviewState';
import { UserRoles } from '@/enums/UsersRole';
import { CollebratoEvolutionModel } from '@/models/AnnualInterviewModel';
import { RootState } from '@/state';
import {
  updateLastYearReview,
  saveAnnualInterviewAsync,
  updateColleberatorEvolution,
} from '@/state/slices/annualInterviewSlice';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const init: CollebratoEvolutionModel = {
  strengthsToDevelop: '',
  areasToImprove: '',
};
function ColleberatorEvolution() {
  const { user } = useAuth();
  // const isTeamLeader = user?.isTeamLeader == 'True';
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const strengthsToDevelop = useSelector(
    (state: RootState) =>
      state.annualInterviews.annualInterview.strengthsToDevelop
  );
  const areasToImprove = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview.areasToImprove
  );
  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const disabled = annualInerview.interviewState == InterviewState.Completed;
  const formik = useFormik({
    initialValues: {
      ...init,
      strengthsToDevelop: strengthsToDevelop,
      areasToImprove: areasToImprove,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(updateColleberatorEvolution({ colleberatorEvoutlion: values }));
    },
  });
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <div className="flex justify-between gap-4">
          <div className="group relative mb-6 w-full">
            <TextArea
              id="strengthsToDevelop"
              name="strengthsToDevelop"
              label={t(
                'annualInterviewForm.CollebratoEvolution.strengthsToDevelop'
              )}
              value={formik.values.strengthsToDevelop ?? ''}
              onChange={formik.handleChange}
              disabled={!isTeamLeader || disabled}
            />
          </div>
          <div className="group relative mb-6 w-full">
            <TextArea
              id="areasToImprove"
              name="areasToImprove"
              label={t(
                'annualInterviewForm.CollebratoEvolution.areasToImprove'
              )}
              value={formik.values.areasToImprove ?? ''}
              onChange={formik.handleChange}
              disabled={!isTeamLeader || disabled}
            />
          </div>
        </div>
      </div>

      {isTeamLeader && !disabled && (
        <div className="flex justify-between">
          <LabelButton type="button" onClick={() => resetForm()}>
            {t('annualInterviewForm.CollebratoEvolution.reset')}
          </LabelButton>
          <PrimaryButton
            className="border-0 bg-purple-300 dark:bg-purple-500"
            type="button"
            onClick={handleSyncData}
          >
            <div className="flex items-center gap-2">
              <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
              {t('annualInterviewForm.CollebratoEvolution.Sync')}
            </div>
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            className="border-0 bg-primary-300 dark:bg-primary-500"
          >
            {t('annualInterviewForm.CollebratoEvolution.submit')}
          </PrimaryButton>
        </div>
      )}
    </form>
  );
}

export default ColleberatorEvolution;
