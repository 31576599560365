import api from '@/utils/api';
import { OuvrageEntriesUrl } from '@/variables/Urls';

const apiEndpoint = OuvrageEntriesUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface OuvrageEntry {
  id: string;
  iD_REF: string;
  ouvrage: string;
  zone: string;
  url: string;
  alt: string;
  commentaires: string;
  projectId: string;
  projectTitle?: string;
}

export interface OuvrageEntryResponse {
  ouvrageEntries: OuvrageEntry[];
  count: number;
}

export async function getOuvrageEntriesByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  const config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<OuvrageEntryResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

export async function saveOuvrageEntry(entry: OuvrageEntry) {
  if (entry.id) {
    const response = await api.put(apiEndpoint + '/put', entry);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', entry);
  return response;
}

export async function getOuvrageEntry(id: string) {
  const { data } = await api.get<OuvrageEntry>(apiEndpoint + '/GetById', {
    params: { id: id },
  });

  return data;
}

export function deleteOuvrageEntry(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: id } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
