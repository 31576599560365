import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  ProjectTeamMember,
  getProjectTeamMember,
  saveProjectTeamMember,
} from '@/services/ProjectTeamMemberService';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import { AssignedTask, AssignedTaskOptions } from '@/enums/AssignedTask';

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required(
    'ProjectTeamMemberForm.employeeNameRequired'
  ),
  // position: Yup.string().required('ProjectTeamMemberForm.positionRequired'),
  assignedTask: Yup.string().required(
    'ProjectTeamMemberForm.assignedTaskRequired'
  ),
});

interface FormValues extends ProjectTeamMember {}

function ProjectTeamMemberForm() {
  const { teamId, id } = useParams();
  const editForm = teamId !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    employeeId: '',

    assignedTask: AssignedTask.ActionFollowUp,
    projectId: id!,
    id: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: projectTeamMemberData,
    isLoading,
    refetch: refetchProjectTeamMember,
  } = useQuery<ProjectTeamMember | undefined>(
    ['projectTeamMember', teamId],
    async () => await getProjectTeamMember(teamId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (projectTeamMember) => {
        if (projectTeamMember) {
          setFormikValuesState((prev) => ({ ...prev, ...projectTeamMember }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && projectTeamMemberData) {
      setFormikValuesState((prev) => ({ ...prev, ...projectTeamMemberData }));
    }
  }, [editForm, projectTeamMemberData]);

  const handleSaveProjectTeamMember = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const projectTeamMember: ProjectTeamMember = {
        ...values,
      };

      const data = await saveProjectTeamMember(projectTeamMember);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.ProjectTeamMember'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.ProjectTeamMember'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchProjectTeamMember();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const {
    data: employeesData,
    isLoading: isLoadingEmployees,
    refetch: refetchEmployees,
    isSuccess,
  } = useQuery<EmployeesResponse | undefined>(
    ['employees'],
    async () =>
      await getEmployees({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('ProjectTeamMemberForm.editProjectTeamMember')
            : t('ProjectTeamMemberForm.addProjectTeamMember')
        }
      />
      {loading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveProjectTeamMember}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="group relative z-0 mb-6 w-full">
                {/* <DefaultInput
                  type="text"
                  name="employeeName"
                  id="employeeName"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.employeeName}
                  label={t('ProjectTeamMemberForm.employeeNameLabel')}
                /> */}
                <Dropdown
                  id="employeeId"
                  name="employeeId"
                  optionValue="id"
                  optionLabel="email"
                  onChange={handleChange}
                  options={employeesData?.employees ?? []}
                  label={t('ProjectTeamMemberForm.employeeNameLabel')}
                  labelClassName="w-[50%]"
                  error={errors.employeeId}
                  value={values.employeeId}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                {/* <DefaultInput
                  type="text"
                  name="position"
                  id="position"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.position}
                  label={t('ProjectTeamMemberForm.positionLabel')}
                /> */}
                <Dropdown
                  name="assignedTask"
                  id="assignedTask"
                  label={t('ProjectTeamMemberForm.assignedTaskLabel')}
                  value={values.assignedTask}
                  translatedNameSpace="AssignedTask"
                  isTranslated={true}
                  optionTransalatedKey="name"
                  optionValue="id"
                  onChange={handleChange}
                  labelClassName="pl-0"
                  error={errors.assignedTask}
                  options={AssignedTaskOptions ?? []}
                />
              </div>
              {/* <div className="group relative z-0 mb-6 w-full">
               
                <DefaultInput
                  type="text"
                  name="assignedTask"
                  id="assignedTask"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.assignedTask}
                  label={t('ProjectTeamMemberForm.assignedTaskLabel')}
                />
              </div> */}

              <button
                type="submit"
                className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                disabled={loading}
              >
                {t('ProjectTeamMemberForm.submitButton')}
              </button>
              <button
                type="button"
                id="cancelForm"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              >
                {t('ProjectTeamMemberForm.cancelButton')}
              </button>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default ProjectTeamMemberForm;
