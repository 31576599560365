export enum OfferStatus {
  Received,
  InPreparation,
  Cancelled,
}

export const OfferStatusOptions = Object.keys(OfferStatus)
  .filter((key) => isNaN(Number(OfferStatus[key])))
  .map((key) => ({
    id: key,
    name: OfferStatus[key],
  }));
