import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import Input, { default as DefaultInput } from '@/components/Input';
import { Formik, Field, getIn } from 'formik';
import * as Yup from 'yup';
import ImageUploader from '@/components/ImagePicker';
import Dropdown from '@/components/DropDown';
import CheckboxGroup from '@/components/CheckboxGroup';
import DatePickerDefault from '@/components/DatePicker';
import { useParams } from 'react-router-dom';
import {
  JobDescriptionModel,
  getJobDescriptionById,
  getJobDescriptionEmployeeById,
} from '@/services/JobDescriptionService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import { siteName } from '@/variables/Urls';
import Accordion from '@/components/Accordian';
import moment from 'moment';

import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';

import { useTranslation } from 'react-i18next';
import { useAuth } from '@/context/AuthContext';
import ChildrenTable from '../JobsDescription/components/ChildrenTable';
import JobHistoriesTable from '../JobsDescription/components/JobHistoriesTable';
import LanguageSkillsTable from '../JobsDescription/components/LanguageSkillsTable';
import { RootState } from '@/state';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobDescriptionByEmployeeId,
  saveJobDescriptionAsync,
} from '@/state/slices/jobdescriptionSlice';
import {
  State,
  Country,
  CountriesResponse,
  getCountries,
} from '@/services/CountryService';
import { LabelButton } from '@/components/shared/Button';
import DegreesTable from '../JobsDescription/components/DegreesTable';
import toast from 'react-hot-toast';
import {
  MaritalStatusEnum,
  maritalStatusOptions,
} from '@/enums/maritialStatusEnum';
import { GenderEnum, genderOptions } from '@/enums/genderEnum';
import {
  getTranslatedEnumNames,
  translateOptionName,
} from '@/enums/SeniorityRange';
import { AsUTC } from '@/components/shared/Utils';

const JobDescriptionDetailForm = () => {
  const { user } = useAuth();
  const isDevelopment = import.meta.env.MODE === 'development';
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { jobDescription, status } = useSelector(
    (state: RootState) => state.jobDescriptions
  );

  const queryClient = useQueryClient();

  const [states, setStates] = React.useState<State[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const handleSubmit = async (values: JobDescriptionModel) => {
    const newchildren = values.children.map((child) => {
      if (child.id?.includes('newC'))
        return {
          ...child,
          id: null,
        };

      return child;
    });
    const newlanguges = values.languageSkills.map((lang) => {
      if (lang.id?.includes('newL'))
        return {
          ...lang,
          id: null,
        };

      return lang;
    });
    const newJobHistory = values.jobHistories.map((jh) => {
      if (jh.id?.includes('newJ'))
        return {
          ...jh,
          id: null,
          startDate: moment.utc(jh.startDate).toDate(),
          endDate: moment.utc(jh.endDate).toDate(),
        };

      return jh;
    });
    const newDegrees = values.degrees.map((dg) => {
      if (dg.id?.includes('newD'))
        return {
          ...dg,
          id: null,
        };

      return dg;
    });
    // convert Date to utc

    await dispatch(
      saveJobDescriptionAsync({
        ...values,
        marriageDate: AsUTC(values.marriageDate),
        spouseDateOfBirth: AsUTC(values.spouseDateOfBirth),
        birthDate: AsUTC(values.birthDate),
        identityPapers:
          values.identityPapers?.identityCardIssueDate == null
            ? null
            : {
                ...values.identityPapers,
                passportIssueDate: AsUTC(
                  values.identityPapers?.passportIssueDate
                ),
                identityCardIssueDate: AsUTC(
                  values.identityPapers?.identityCardIssueDate
                ),
                drivingLicenseIssueDate: AsUTC(
                  values.identityPapers?.drivingLicenseIssueDate
                ),
              },
        disabilityInformation:
          values.disabilityInformation?.rqthDate == null
            ? null
            : {
                ...values.disabilityInformation,
                rqthDate: AsUTC(values.disabilityInformation?.rqthDate),
              },
        children: newchildren,
        languageSkills: newlanguges,
        jobHistories: newJobHistory,
        degrees: newDegrees,
      })
    );
    if (status === 'succeeded') {
      toast.success('the job description has been saved ');
      dispatch(fetchJobDescriptionByEmployeeId(user.id));
    }
  };

  const translatedGenderOptions = genderOptions.map((option) => ({
    id: option.id,
    name: translateOptionName(
      t,
      'GenderEnum',
      option.name.trim().replace(/\s+/g, '')
    ),
  }));

  const translatedMaritalStatusOptions = maritalStatusOptions.map((option) => ({
    id: option.id,
    name: translateOptionName(
      t,
      'MaritalStatusEnum',
      option.name.trim().replace(/\s+/g, '')
    ),
  }));

  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      setCountries(data?.countries!);
    },
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchJobDescriptionByEmployeeId(user.id));
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    const selectedCountryObjData = data?.countries?.find(
      (country) => country.id == jobDescription.countryId
    );
    console.warn(
      '🚀 ~ useEffect ~ selectedCountryObj:',
      selectedCountryObjData,
      jobDescription.countryId
    );
    if (
      selectedCountryObjData !== null &&
      selectedCountryObjData !== undefined
    ) {
      setStates(selectedCountryObjData?.states ?? []);
    } else {
      setStates([]);
    }
  }, [data?.countries, jobDescription, setStates]);

  const handleCountryChange = (event: any, handleChange: any) => {
    const selectedCountryName = event.target.value;

    const selectedCountryObj = data?.countries?.find(
      (country) => country.id === selectedCountryName
    );

    if (selectedCountryObj !== null && selectedCountryObj !== undefined) {
      setStates(selectedCountryObj.states!);
    } else {
      setStates([]);
    }
    handleChange({
      target: {
        name: 'countryId',
        value: selectedCountryObj!.id || {},
      },
    });
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('jobDescriptionDetails.breadcrumb')} />

      {status === 'loading' || user == null || countryLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={jobDescription}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          // validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isValid,
            setFieldValue,
          }) => (
            <form>
              <div>
                <Accordion
                  title={t('jobDescriptionDetails.generalInformation.title')}
                >
                  <div className="grid grid-cols-4 gap-6">
                    <div className="col-span-1">
                      <img
                        src={
                          isDevelopment
                            ? siteName + values?.profileImgUrl
                            : values?.profileImgUrl
                        }
                        className="mx-2 h-40 w-40 rounded-lg object-cover"
                      />
                    </div>
                    <div className="col-span-3 ">
                      <div className="flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-2">
                        <div className="flex flex-col gap-4">
                          <Input
                            name="name"
                            id="name"
                            value={values?.firstName + ' ' + values?.lastName}
                            label={t(
                              'jobDescriptionDetails.generalInformation.fullName'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                            labelClassName="pl-0"
                            readOnly
                          />
                          {/* <Input
                            name="start"
                            value={moment(jobDescription?.birthDate).format(
                              'DD/MM/YYYY'
                            )}
                            label={t(
                              'jobDescriptionDetails.generalInformation.birthDate'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                          /> */}
                          <DatePickerDefault
                            labelDir="inLine"
                            label={t(
                              'jobDescriptionDetails.generalInformation.birthDate'
                            )}
                            id="birthDate"
                            name="birthDate"
                            value={new Date(values?.birthDate)}
                            defaultDate={new Date(values?.birthDate)}
                            onChange={(date: any) => {
                              handleChange({
                                target: {
                                  name: 'birthDate',
                                  value: date,
                                },
                              });
                            }}
                            labelClassName="pl-0"
                            errors={errors.birthDate}
                          />
                          {/* <Input
                            name="start"
                            value={jobDescription?.gender}
                            label={t(
                              'jobDescriptionDetails.generalInformation.gender'
                            )}
                            labelDir="inLine"
                            containerClass="w-full self-end"
                          /> */}

                          <Dropdown
                            labelDir="inLine"
                            id="gender"
                            name="gender"
                            labelClassName="pl-0"
                            label={t(
                              'jobDescriptionDetails.generalInformation.gender'
                            )}
                            onChange={handleChange}
                            value={values.gender!}
                            errors={errors.gender}
                            optionValue="id"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            options={translatedGenderOptions ?? []}
                          />
                          {/* <Input
                            name="start"
                            value={jobDescription?.maritalStatus}
                            label={t(
                              'jobDescriptionDetails.generalInformation.maritalStatus'
                            )}
                            labelDir="inLine"
                            containerClass="w-full "
                          /> */}

                          <Dropdown
                            id="maritalStatus"
                            name="maritalStatus"
                            optionValue="id"
                            labelDir="inLine"
                            value={values.maritalStatus!}
                            onChange={handleChange}
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            options={translatedMaritalStatusOptions ?? []}
                            label={t('Employee.maritalStatus')}
                          />
                          {/* <Input
                            name="start"
                            value={
                              jobDescription?.spouseDateOfBirth
                                ? moment(
                                    jobDescription?.spouseDateOfBirth
                                  ).format('DD/MM/YYYY')
                                : ''
                            }
                            label={t(
                              'jobDescriptionDetails.generalInformation.spouseDateOfBirth'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                          /> */}

                          <DatePickerDefault
                            labelDir="inLine"
                            label={t(
                              'jobDescriptionDetails.generalInformation.spouseDateOfBirth'
                            )}
                            id="spouseDateOfBirth"
                            name="spouseDateOfBirth"
                            value={new Date(values?.spouseDateOfBirth)}
                            onChange={(date: any) => {
                              handleChange({
                                target: {
                                  name: 'spouseDateOfBirth',
                                  value: date,
                                },
                              });
                            }}
                            errors={errors.spouseDateOfBirth}
                          />
                        </div>
                        <div className="flex flex-col gap-4">
                          {/* <Input
                            name="start"
                            value={
                              jobDescription?.firstName +
                              ' ' +
                              jobDescription?.lastName
                            }
                            label={t(
                              'jobDescriptionDetails.generalInformation.maidenName'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                          /> */}

                          <Input
                            label={t(
                              'jobDescriptionDetails.generalInformation.spouseName'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                            id="spouseName"
                            type="text"
                            name="spouseName"
                            value={values?.spouseName}
                            onChange={handleChange}
                            errors={errors?.spouseName}
                          />
                          {/* <Input
                            name="start"
                            value={jobDescription?.birthCountry ?? ''}
                            label={t(
                              'jobDescriptionDetails.generalInformation.birthCountry'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                          /> */}
                          <Dropdown
                            id="birthCountry"
                            name="birthCountry"
                            value={values.birthCountry}
                            labelDir="inLine"
                            optionValue="id"
                            optionLabel="name"
                            onChange={handleChange}
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            options={countries ?? []}
                            label={t(
                              'jobDescriptionDetails.generalInformation.birthCountry'
                            )}
                          />
                          {/* <Input
                            name="start"
                            value={jobDescription?.nationality ?? ''}
                            label={t(
                              'jobDescriptionDetails.generalInformation.nationality'
                            )}
                            labelDir="inLine"
                            containerClass="w-full "
                          /> */}
                          <Dropdown
                            id="nationality"
                            name="nationality"
                            value={values.nationality}
                            labelDir="inLine"
                            optionValue="id"
                            optionLabel="name"
                            onChange={handleChange}
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            options={countries ?? []}
                            label={t(
                              'jobDescriptionDetails.generalInformation.nationality'
                            )}
                          />
                          {/* <Input
                            name="start"
                            value={jobDescription?.spouseName ?? ''}
                            label={t(
                              'jobDescriptionDetails.generalInformation.spouseName'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                          /> */}
                          {/* <Input
                            name="start"
                            value={
                              jobDescription?.marriageDate
                                ? moment(
                                    jobDescription?.marriageDate ?? new Date()
                                  ).format('DD/MM/YYYY')
                                : ''
                            }
                            label={t(
                              'jobDescriptionDetails.generalInformation.marriageDate'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                          /> */}
                          <DatePickerDefault
                            labelDir="inLine"
                            label={t(
                              'jobDescriptionDetails.generalInformation.marriageDate'
                            )}
                            id="marriageDate"
                            name="marriageDate"
                            value={new Date(values?.marriageDate)}
                            defaultDate={new Date(values?.marriageDate)}
                            onChange={(date: any) => {
                              handleChange({
                                target: {
                                  name: 'marriageDate',
                                  value: date,
                                },
                              });
                            }}
                            errors={errors.disabilityInformation?.rqthDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion>

                <Accordion
                  title={t('jobDescriptionDetails.contactInformation.title')}
                >
                  <>
                    <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-2">
                      <div className="flex flex-col gap-4">
                        <Input
                          label={t(
                            'jobDescriptionDetails.contactInformation.street'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                          id="address.street"
                          type="text"
                          name="street"
                          onChange={handleChange}
                          value={values?.address?.street}
                          errors={getIn(errors, 'address.street')}
                        />
                        <Input
                          label={t(
                            'jobDescriptionDetails.contactInformation.zipCode'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                          id="address.zipCode"
                          type="text"
                          name="address.zipCode"
                          onChange={handleChange}
                          value={values.address?.zipCode}
                          errors={getIn(errors, 'address.zipCode')}
                        />
                        {/* <Input
                          name="start"
                          value={jobDescription?.countryId ?? ''}
                          label={t(
                            'jobDescriptionDetails.contactInformation.country'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                        /> */}
                        <Dropdown
                          id="countryId"
                          name="countryId"
                          value={values.countryId}
                          labelDir="inLine"
                          optionValue="id"
                          optionLabel="name"
                          onChange={(e) => handleCountryChange(e, handleChange)}
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                          options={countries ?? []}
                          label={t(
                            'jobDescriptionDetails.contactInformation.country'
                          )}
                        />
                        <Input
                          label={t(
                            'jobDescriptionDetails.contactInformation.phoneNumber'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                          id="phoneNumber1"
                          type="text"
                          name="phoneNumber1"
                          onChange={handleChange}
                          value={values?.phoneNumber1}
                          errors={errors.phoneNumber1}
                        />
                      </div>
                      <div className="flex flex-col gap-4">
                        <Input
                          label={t(
                            'jobDescriptionDetails.contactInformation.email'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                          id="email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          value={values?.email ?? ''}
                          errors={errors.email}
                          disabled={true}
                        />

                        <Input
                          label={t(
                            'jobDescriptionDetails.contactInformation.postalCode'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                          id="address.postalCode"
                          type="text"
                          name="address.postalCode"
                          onChange={handleChange}
                          value={values?.address?.postalCode}
                          errors={getIn(errors, 'address.postalCode')}
                        />
                        {/* <Input
                          name="start"
                          value={jobDescription?.stateId ?? ''}
                          label={t(
                            'jobDescriptionDetails.contactInformation.state'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                        /> */}

                        <Dropdown
                          id="stateId"
                          name="stateId"
                          optionValue="id"
                          optionLabel="name"
                          onChange={(e) => {
                            setFieldValue('stateId', e.target.value);
                          }}
                          options={states ?? []}
                          label={t(
                            'jobDescriptionDetails.contactInformation.state'
                          )}
                          error={errors.stateId}
                          value={values.stateId}
                          labelDir="inLine"
                        />
                        <Input
                          label={t(
                            'jobDescriptionDetails.contactInformation.phoneNumber2'
                          )}
                          labelDir="inLine"
                          containerClass="w-full"
                          id="phoneNumber2"
                          type="text"
                          name="phoneNumber2"
                          onChange={handleChange}
                          value={values?.phoneNumber2}
                          errors={errors.phoneNumber2}
                        />
                      </div>
                    </div>
                    <Accordion
                      title={t('jobDescriptionDetails.emergencyContact.title')}
                    >
                      <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-2">
                        <div className="flex flex-col gap-4">
                          <Input
                            label={t(
                              'jobDescriptionDetails.emergencyContact.firstName'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                            id="emergencyContact.firstName"
                            type="text"
                            name="emergencyContact.firstName"
                            onChange={handleChange}
                            value={values?.emergencyContact?.firstName ?? ''}
                            errors={errors.emergencyContact?.firstName}
                          />
                          <Input
                            label={t(
                              'jobDescriptionDetails.emergencyContact.phoneNumber'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                            id="emergencyContact.phoneNumber"
                            type="text"
                            name="emergencyContact.phoneNumber"
                            onChange={handleChange}
                            value={values?.emergencyContact?.phoneNumber ?? ''}
                            errors={errors.emergencyContact?.phoneNumber}
                          />
                        </div>
                        <div className="flex flex-col gap-4">
                          <Input
                            label={t(
                              'jobDescriptionDetails.emergencyContact.lastName'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                            id="emergencyContact.lastName"
                            type="text"
                            name="emergencyContact.lastName"
                            onChange={handleChange}
                            value={values?.emergencyContact?.lastName ?? ''}
                            errors={errors.emergencyContact?.lastName}
                          />
                          <Input
                            label={t(
                              'jobDescriptionDetails.emergencyContact.email'
                            )}
                            labelDir="inLine"
                            containerClass="w-full"
                            id="emergencyContact.email"
                            type="email"
                            name="emergencyContact.email"
                            onChange={handleChange}
                            value={values?.emergencyContact?.email ?? ''}
                            errors={errors.emergencyContact?.email}
                          />
                        </div>
                      </div>
                    </Accordion>
                  </>
                </Accordion>

                <Accordion title={t('jobDescriptionDetails.degrees.title')}>
                  <DegreesTable degrees={values?.degrees ?? []} />
                </Accordion>
                <Accordion title={t('jobDescriptionDetails.children.title')}>
                  <ChildrenTable children={values?.children ?? []} />
                </Accordion>

                <Accordion
                  title={t('jobDescriptionDetails.languageSkills.title')}
                >
                  <LanguageSkillsTable
                    languageSkills={values?.languageSkills ?? []}
                  />
                </Accordion>

                <Accordion title={t('jobDescriptionDetails.jobHistory.title')}>
                  <JobHistoriesTable
                    jobHistories={values?.jobHistories ?? []}
                  />
                </Accordion>

                <Accordion
                  title={t('jobDescriptionDetails.disabilityInformation.title')}
                >
                  <div className="flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-2">
                    <div className="flex flex-col gap-4 ">
                      <Input
                        label={t(
                          'jobDescriptionDetails.disabilityInformation.ippRate'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        id="disabilityInformation.ippRate"
                        type="number"
                        name="disabilityInformation.ippRate"
                        value={values?.disabilityInformation?.ippRate}
                        onChange={handleChange}
                        errors={errors?.disabilityInformation?.ippRate}
                      />
                      {/* <Input
                        name="rqthDate"
                        value={moment(
                          jobDescription?.disabilityInformation?.rqthDate
                        ).format('DD/MM/YYYY')}
                        label={t(
                          'jobDescriptionDetails.disabilityInformation.rqthDate'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        onChange={handleChange}
                      /> */}
                      <DatePickerDefault
                        labelDir="inLine"
                        label={t(
                          'jobDescriptionDetails.disabilityInformation.rqthDate'
                        )}
                        id="disabilityInformation.rqthDate"
                        name="disabilityInformation.rqthDate"
                        value={
                          new Date(values?.disabilityInformation?.rqthDate)
                        }
                        defaultDate={
                          new Date(values?.disabilityInformation?.rqthDate) ??
                          undefined
                        }
                        onChange={(date: any) => {
                          handleChange({
                            target: {
                              name: 'disabilityInformation.rqthDate',
                              value: date,
                            },
                          });
                        }}
                        errors={errors.disabilityInformation?.rqthDate}
                      />
                    </div>
                    <div className="flex flex-col gap-4 ">
                      <TextArea
                        label={t(
                          'jobDescriptionDetails.disabilityInformation.workRestrictions'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        onChange={handleChange}
                        id="disabilityInformation.workRestrictions"
                        name="disabilityInformation.workRestrictions"
                        value={
                          values?.disabilityInformation?.workRestrictions ?? ''
                        }
                        errors={errors.disabilityInformation?.workRestrictions}
                      />
                    </div>
                  </div>
                </Accordion>

                <Accordion
                  title={t('jobDescriptionDetails.identityPapers.title')}
                >
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="md:col-span-1">
                      <Input
                        label={t(
                          'jobDescriptionDetails.identityPapers.socialSecurityNumber'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        id="identityPapers.socialSecurityNumber"
                        type="text"
                        name="identityPapers.socialSecurityNumber"
                        onChange={handleChange}
                        value={values?.identityPapers?.socialSecurityNumber}
                        errors={errors.identityPapers?.socialSecurityNumber}
                      />
                      <Input
                        label={t(
                          'jobDescriptionDetails.identityPapers.identityCardNumber'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        id="identityPapers.identityCardNumber"
                        type="text"
                        name="identityPapers.identityCardNumber"
                        onChange={handleChange}
                        value={values?.identityPapers?.identityCardNumber}
                        errors={errors.identityPapers?.identityCardNumber}
                      />
                      {/* <Input
                        name="identityCardIssueDate"
                        value={moment(
                          jobDescription?.identityPapers?.identityCardIssueDate
                        ).format('DD/MM/YYYY')}
                        label={t(
                          'jobDescriptionDetails.identityPapers.identityCardIssueDate'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                      /> */}
                      <DatePickerDefault
                        labelDir="inLine"
                        label={t(
                          'jobDescriptionDetails.identityPapers.identityCardIssueDate'
                        )}
                        id="identityPapers.identityCardIssueDate"
                        name="identityPapers.identityCardIssueDate"
                        value={
                          new Date(
                            values?.identityPapers?.identityCardIssueDate
                          )
                        }
                        defaultDate={
                          new Date(
                            values?.identityPapers?.identityCardIssueDate
                          )
                        }
                        onChange={(date: any) => {
                          handleChange({
                            target: {
                              name: 'identityPapers.identityCardIssueDate',
                              value: date,
                            },
                          });
                        }}
                        errors={errors.identityPapers?.identityCardIssueDate}
                      />
                      <Input
                        label={t(
                          'jobDescriptionDetails.identityPapers.passportNumber'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        id="identityPapers.passportNumber"
                        type="text"
                        name="identityPapers.passportNumber"
                        onChange={handleChange}
                        value={values?.identityPapers?.passportNumber}
                        errors={errors.identityPapers?.passportNumber}
                      />
                      {/* <Input
                        name="passportIssueDate"
                        value={moment(
                          jobDescription?.identityPapers?.passportIssueDate
                        ).format('DD/MM/YYYY')}
                        label={t(
                          'jobDescriptionDetails.identityPapers.passportIssueDate'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        readOnly
                      /> */}
                      <DatePickerDefault
                        labelDir="inLine"
                        id="identityPapers.passportIssueDate"
                        name="identityPapers.passportIssueDate"
                        label={t(
                          'jobDescriptionDetails.identityPapers.passportIssueDate'
                        )}
                        value={
                          new Date(values?.identityPapers?.passportIssueDate)
                        }
                        defaultDate={
                          new Date(values?.identityPapers?.passportIssueDate)
                        }
                        onChange={(date: any) => {
                          handleChange({
                            target: {
                              name: 'identityPapers.passportIssueDate',
                              value: date,
                            },
                          });
                        }}
                        errors={errors.identityPapers?.passportIssueDate}
                      />
                    </div>
                    <div className="md:col-span-1">
                      <Input
                        label={t(
                          'jobDescriptionDetails.identityPapers.drivingLicenseNumber'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        id="identityPapers.drivingLicenseNumber"
                        type="text"
                        name="identityPapers.drivingLicenseNumber"
                        onChange={handleChange}
                        value={values?.identityPapers?.drivingLicenseNumber}
                        errors={errors.identityPapers?.drivingLicenseNumber}
                      />
                      {/* <Input
                        name="drivingLicenseIssueDate"
                        value={moment(
                          jobDescription?.identityPapers
                            ?.drivingLicenseIssueDate
                        ).format('DD/MM/YYYY')}
                        label={t(
                          'jobDescriptionDetails.identityPapers.drivingLicenseIssueDate'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        readOnly
                      /> */}
                      <DatePickerDefault
                        labelDir="inLine"
                        id="identityPapers.drivingLicenseIssueDate"
                        name="identityPapers.drivingLicenseIssueDate"
                        value={
                          new Date(
                            values?.identityPapers?.drivingLicenseIssueDate
                          )
                        }
                        defaultDate={
                          new Date(
                            values?.identityPapers?.drivingLicenseIssueDate
                          )
                        }
                        onChange={(date: any) => {
                          handleChange({
                            target: {
                              name: 'identityPapers.drivingLicenseIssueDate',
                              value: date,
                            },
                          });
                        }}
                        label={t(
                          'jobDescriptionDetails.identityPapers.drivingLicenseIssueDate'
                        )}
                        errors={errors.identityPapers?.drivingLicenseIssueDate}
                      />
                      <Input
                        label={t(
                          'jobDescriptionDetails.identityPapers.drivingLicenseCategory'
                        )}
                        labelDir="inLine"
                        containerClass="w-full"
                        id="identityPapers.drivingLicenseCategory"
                        type="text"
                        name="identityPapers.drivingLicenseCategory"
                        onChange={handleChange}
                        value={values?.identityPapers?.drivingLicenseCategory}
                        errors={errors.identityPapers?.drivingLicenseCategory}
                      />
                    </div>
                  </div>
                </Accordion>

                {/* <button type="submit">{t('jobDescriptionDetails.submit')}</button> */}
              </div>
              <div className="mt-2 flex items-center justify-between">
                <LabelButton type="button">
                  {t('jobHistories.buttons.cancel')}
                </LabelButton>
                <button
                  type="button"
                  onClick={(e) => handleSubmit(values)}
                  disabled={!isValid}
                  className="mb-2 mr-2 rounded-lg border border-primary-700 px-5 py-2.5 text-center text-sm font-medium text-primary-700 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300 dark:border-primary-500 dark:text-primary-500 dark:hover:bg-primary-600 dark:hover:text-white dark:focus:ring-primary-800"
                >
                  {t('jobHistories.buttons.save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
};

export default JobDescriptionDetailForm;
