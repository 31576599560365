import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { getCompanyId } from '@/services/AuthService';
import {
  OffDay,
  WorkCalendar,
  getWorkCalendar,
  saveWorkCalendar,
} from '@/services/WorkCalendarsService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import MutiDatePickerRange, {
  DateRange,
} from '@/components/MultiDatePickerRange';
import { Country } from '@/services/CountryService';
import moment from 'moment';
import CheckboxGroup from '@/components/CheckboxGroup';
import { DaysEnum } from '@/enums/daysEnum';
import DatePicker from 'react-datepicker';
import { date } from 'react-i18next/icu.macro';
import Dropdown from '@/components/DropDown';
import { VactionCalculateSystemOptions } from '@/enums/VactionCalculateSystem';
import { translateOptionName } from '@/enums/SeniorityRange';
// Translations for Yup validation

export interface WeekDaysType {
  value: string;
  label: string;
  checked: boolean;
  id: string;
}
interface FormValues extends WorkCalendar {
  holidaysArray: DateRange[];
  enterpriseClosingDatesArray: DateRange[];
  weekendDays: WeekDaysType[];
}
// Translations for Yup validation
const validationMessages = {
  nameRequired: 'Calendar.titleIsRequired',
  weekendsRequired: 'Calendar.weekendsIsRequired',
  holidaysRequired: 'Calendar.holidaysIsRequired',
};

const CustomInput = forwardRef<HTMLInputElement, any>(
  (
    { value, onClick, onChange, disabled }: any,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <input
      type="text"
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      disabled={disabled}
      className="block w-full rounded-lg border border-gray-300  bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
    />
  )
);

function CalendarTypeForm() {
  const [weekendsOptions, setWeekendsOptions] = useState<WeekDaysType[]>([]);
  const [holidaysOptions, setHolidaysOptions] = useState([]);
  const [supportedCountries, setSupportedCountries] = useState({});
  const { id } = useParams();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [countries, setCountries] = useState<Country>();
  const editForm = id !== 'new';
  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    companyId: getCompanyId()! as string,
    name: '',
    id: '',
    holidaysArray: [],
    enterpriseClosingDatesArray: [],
    weekendDays: [],
    workingYearEnd: new Date(),
    workingYearStart: new Date(),
    rttrIntervalEnd: new Date(),
    rttrIntervalStart: new Date(),
  });
  const [loading, setLoading] = useState(false);

  const translatedVactionCalculateSystemOptions =
    VactionCalculateSystemOptions.map((option) => ({
      id: option.id,
      name: translateOptionName(
        t,
        'VactionCalculateSystem',
        option.name.trim().replace(/\s+/g, '')
      ),
    }));
  // Fetching known weekends using react-select options
  const getWeekendsOptions = useCallback(() => {
    return [
      { value: 'Saturday', label: t('Calendar.Saturday'), id: '0' },
      { value: 'Sunday', label: t('Calendar.Sunday'), id: '1' },
      { value: 'Monday', label: t('Calendar.Monday'), id: '2' },
      { value: 'Tuesday', label: t('Calendar.Tuesday'), id: '3' },
      { value: 'Wednesday', label: t('Calendar.Wednesday'), id: '4' },
      { value: 'Thursday', label: t('Calendar.Thursday'), id: '5' },
      { value: 'Friday', label: t('Calendar.Friday'), id: '6' },
      // Add more weekdays here as needed
    ];
  }, [t]);

  // Fetch weekends and holidays options
  useEffect(() => {
    setWeekendsOptions(getWeekendsOptions());
    // setHolidaysOptions(getKnownHolidays());
  }, []);

  const calendarTypeFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(validationMessages.nameRequired),
    weekendDays: Yup.array()
      .min(1, validationMessages.weekendsRequired)
      .required(validationMessages.weekendsRequired),
  });

  const {
    data: workCalendarData,
    isLoading,
    isSuccess,
    refetch: refetchWorkCalendar,
  } = useQuery<WorkCalendar | undefined>(
    ['workCalendar', id],
    async () => await getWorkCalendar(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 8000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (editForm && workCalendarData) {
      // Update form values with calendarType data

      // get the weekend days
      const selectedWeekendDays = [
        workCalendarData.saturday ? DaysEnum.Saturday : '',
        workCalendarData.sunday ? DaysEnum.Sunday : '',
        workCalendarData.monday ? DaysEnum.Monday : '',
        workCalendarData.tuesday ? DaysEnum.Tuesday : '',
        workCalendarData.wednesday ? DaysEnum.Wednesday : '',
        workCalendarData.thursday ? DaysEnum.Thursday : '',
        workCalendarData.friday ? DaysEnum.Friday : '',
      ].filter((day) => day !== '');

      // Map the selected weekend days to their corresponding WeekDaysType
      let weekendDays = weekendsOptions.filter((day) =>
        selectedWeekendDays.includes(day.id)
      );

      // Parse holidays and enterprise closing dates into arrays
      const holidaysArray = workCalendarData
        .offDays!.filter((x) => x.isPayed === true)
        .map((dayOff) => {
          return {
            id: dayOff.id,
            startDate: moment(dayOff.fromDate, 'YYYY-MM-DD').toDate(),
            endDate: moment(dayOff.toDate, 'YYYY-MM-DD').toDate(),
          } as DateRange;
        });
      const enterpriseClosingDatesArray = workCalendarData
        .offDays!.filter((x) => x.isPayed === false)
        .map((dayOff) => {
          return {
            id: dayOff.id,
            startDate: moment(dayOff.fromDate, 'YYYY-MM-DD').toDate(),
            endDate: moment(dayOff.toDate, 'YYYY-MM-DD').toDate(),
          } as DateRange;
        });

      setFormikValuesState((prev) => ({
        ...prev,
        ...workCalendarData,
        name: workCalendarData.name,
        weekendDays,
        holidaysArray,
        enterpriseClosingDatesArray,
      }));
    }
  }, [editForm, workCalendarData, id, weekendsOptions]);

  function formatDateForSave(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  const handleSaveWorkCalendar = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);
    try {
      const companyId = getCompanyId();

      const holidayOffDays = values.holidaysArray.map((h, index) => {
        const offday: OffDay = {
          name: `holiday-${index}-${companyId}`,
          fromDate: h.startDate,
          toDate: h.endDate,
          isPayed: true,
        };
        return offday;
      });
      const enterpriseOffDays = values.enterpriseClosingDatesArray.map(
        (h, index) => {
          const offday: OffDay = {
            name: `enterpirse-closing-${index}-${companyId}`,
            fromDate: h.startDate,
            toDate: h.endDate,
            isPayed: false,
          };
          return offday;
        }
      );

      const calendarType: WorkCalendar = {
        ...values,
        saturday: values.weekendDays.find((x) => x.id === DaysEnum.Saturday)
          ? true
          : false,
        sunday: values.weekendDays.find((x) => x.id === DaysEnum.Sunday)
          ? true
          : false,
        monday: values.weekendDays.find((x) => x.id === DaysEnum.Monday)
          ? true
          : false,
        thursday: values.weekendDays.find((x) => x.id === DaysEnum.Thursday)
          ? true
          : false,
        wednesday: values.weekendDays.find((x) => x.id === DaysEnum.Wednesday)
          ? true
          : false,
        tuesday: values.weekendDays.find((x) => x.id === DaysEnum.Tuesday)
          ? true
          : false,
        friday: values.weekendDays.find((x) => x.id === DaysEnum.Friday)
          ? true
          : false,
        offDays: [...holidayOffDays, ...enterpriseOffDays],
      };

      const calendarTypeId = await saveWorkCalendar(calendarType);

      if (calendarTypeId) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.WorkCalendar'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.WorkCalendar'),
            })
          );
        }
      }

      if (calendarTypeId && editForm) {
        refetchWorkCalendar();
      }
      //toast.success(t('saveSuccessMessage'));
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('Calendar.editCalendarType')
            : t('Calendar.addCalendarType')
        }
      />

      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={calendarTypeFormValidationSchema}
          onSubmit={handleSaveWorkCalendar}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group  w-full">
                  <DefaultInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.name}
                    label={t('Calendar.name')}
                  />
                </div>

                <div className="group  w-full">
                  <Dropdown
                    labelDir="Above"
                    id="vactionCalculateSystem"
                    name="vactionCalculateSystem"
                    labelClassName="pl-0 w-[50%]"
                    label={t('VactionCalculateSystem.Title')}
                    onChange={handleChange}
                    value={values.vactionCalculateSystem!}
                    errors={errors.vactionCalculateSystem}
                    optionValue="id"
                    optionName="name"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={translatedVactionCalculateSystemOptions ?? []}
                  />
                </div>
              </div>

              {/* Multi-Select for Weekends */}
              <div className="group relative  mb-6 mt-2 w-full">
                <CheckboxGroup
                  title="Weekend"
                  checkboxes={weekendsOptions}
                  selectedCheckboxes={
                    values.weekendDays?.map((em) => em.id) ?? []
                  }
                  onChange={(selectedCheckboxes) => {
                    const newWeekendDays = weekendsOptions.filter((option) =>
                      selectedCheckboxes.includes(option.id)
                    );

                    handleChange({
                      target: {
                        name: 'weekendDays',
                        value: newWeekendDays,
                      },
                    });
                  }}
                />
              </div>
              {/* End Multi-Select for Weekends */}

              {/* Multi-Select for Holidays */}
              {/* <div className="group relative mb-6 w-full">
                  <label
                    htmlFor="holidays"
                    className={`mb-2 block text-sm font-medium 
                     text-gray-900 dark:text-white
                  `}
                  >
                    {t('Calendar.holidays')}
                  </label>
                  <Select
                    id="holidaysArray"
                    name="holidaysArray"
                    classNames={{
                      control: () => `block w-full rounded-lg border
                    border-gray-300   text-sm z-10
                     text-gray-900 focus:border-blue-500
                      focus:ring-blue-500 dark:border-gray-600
                       dark:bg-gray-700 dark:text-white
                        dark:placeholder-gray-400
                         dark:focus:border-blue-500
                          dark:focus:ring-blue-500 
                          font-bold text-lg dark:text-white
                          `,
                      menu: () => 'bg-white dark:bg-gray-700 dark:text-black',
                    }}
                    options={holidaysOptions}
                    isMulti
                    value={holidaysOptions.filter((option) =>
                      values.holidaysArray.includes(option.id)
                    )}
                    onChange={(selectedOptions, { action }) => {
                      handleChange({
                        target: {
                          name: 'holidaysArray',
                          value: selectedOptions
                            ? selectedOptions
                                .map((option) => option.id)
                                .join(',')
                            : '',
                        },
                      });
                    }}
                  />
                </div> */}

              {/* End Multi-Select for Holidays */}

              {/* ... */}
              <div className="group relative mb-10  w-full">
                <MutiDatePickerRange
                  value={values.holidaysArray}
                  onChange={(dateRanges) =>
                    handleChange({
                      target: {
                        name: 'holidaysArray',
                        value: dateRanges,
                      },
                    })
                  }
                  label={t('Calendar.holidays')}
                  name="holidaysArray"
                  id="holidaysArray"
                  errors={errors}
                />
              </div>
              <div className="group relative   w-full">
                <MutiDatePickerRange
                  value={values.enterpriseClosingDatesArray}
                  onChange={(dateRanges) =>
                    handleChange({
                      target: {
                        name: 'enterpriseClosingDatesArray',
                        value: dateRanges,
                      },
                    })
                  }
                  label={t('Calendar.enterpriseClosingDate')}
                  name="enterpriseClosingDatesArray"
                  id="enterpriseClosingDatesArray"
                  errors={errors}
                />
              </div>
              {/* ... */}

              <div className="flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-2">
                <div className="group relative my-6 w-full">
                  <label
                    htmlFor="workingYearStart"
                    className="block px-3 py-2 font-medium text-gray-700 dark:text-white"
                  >
                    {t('Calendar.workingYearStartLabel')}
                  </label>
                  <DatePicker
                    id="workingYearStart"
                    locale={i18n.language}
                    selected={new Date(values.workingYearStart ?? '')}
                    onChange={(date) =>
                      setFieldValue(
                        'workingYearStart',
                        date instanceof Date && !isNaN(date.getTime())
                          ? new Date(
                              Date.UTC(
                                date.getUTCFullYear(),
                                date.getUTCMonth()
                              )
                            )
                          : null
                      )
                    }
                    dateFormat="MMMM"
                    wrapperClassName="w-full px-3 py-2 border rounded-lg"
                    customInput={
                      <CustomInput
                        value={
                          values.workingYearStart instanceof Date &&
                          !isNaN(values.workingYearStart.getTime())
                            ? moment.utc(values.workingYearStart).format('MMMM')
                            : ''
                        }
                      />
                    }
                    showMonthYearPicker
                  />
                </div>
                <div className="group relative my-6 w-full">
                  <label
                    htmlFor="workingYearEnd"
                    className="block px-3 py-2 font-medium text-gray-700 dark:text-white"
                  >
                    {t('Calendar.workingYearEndLabel')}
                  </label>
                  <DatePicker
                    id="workingYearEnd"
                    locale={i18n.language}
                    selected={new Date(values.workingYearEnd ?? '')}
                    onChange={(date) =>
                      setFieldValue(
                        'workingYearEnd',
                        new Date(
                          Date.UTC(date!.getUTCFullYear(), date!.getUTCMonth())
                        )
                      )
                    }
                    dateFormat="MMMM"
                    wrapperClassName="w-full px-3 py-2 border rounded-lg"
                    customInput={
                      <CustomInput
                        value={
                          values.workingYearEnd instanceof Date &&
                          !isNaN(values.workingYearEnd.getTime())
                            ? moment.utc(values.workingYearEnd).format('MMMM')
                            : ''
                        }
                      />
                    }
                    showMonthYearPicker
                  />
                </div>
                <div className="group relative my-6 w-full">
                  <label
                    htmlFor="rttrIntervalStart"
                    className=" block px-3 py-2 font-medium text-gray-700 dark:text-white"
                  >
                    {t('Calendar.rttrIntervalStartLabel')}
                  </label>
                  <DatePicker
                    id="rttrIntervalStart"
                    locale={i18n.language}
                    selected={new Date(values.rttrIntervalStart ?? '')}
                    onChange={(date) =>
                      setFieldValue(
                        'rttrIntervalStart',
                        date instanceof Date && !isNaN(date.getTime())
                          ? new Date(
                              Date.UTC(
                                date.getUTCFullYear(),
                                date.getUTCMonth()
                              )
                            )
                          : null
                      )
                    }
                    dateFormat="MMMM"
                    wrapperClassName="w-full px-3 py-2 border rounded-lg"
                    customInput={
                      <CustomInput
                        value={
                          values.rttrIntervalStart instanceof Date &&
                          !isNaN(values.rttrIntervalStart.getTime())
                            ? moment
                                .utc(values.rttrIntervalStart)
                                .format('MMMM')
                            : ''
                        }
                      />
                    }
                    showMonthYearPicker
                  />
                </div>
                <div className="group relative my-6 w-full">
                  <label
                    htmlFor="rttrIntervalEnd"
                    className="block px-3 py-2 font-medium text-gray-700 dark:text-white"
                  >
                    {t('Calendar.rttrIntervalEndLabel')}
                  </label>
                  <DatePicker
                    id="rttrIntervalEnd"
                    locale={i18n.language}
                    selected={new Date(values.rttrIntervalEnd ?? '')}
                    onChange={(date) =>
                      setFieldValue(
                        'rttrIntervalEnd',
                        date instanceof Date && !isNaN(date.getTime())
                          ? new Date(
                              Date.UTC(
                                date.getUTCFullYear(),
                                date.getUTCMonth()
                              )
                            )
                          : null
                      )
                    }
                    dateFormat="MMMM"
                    wrapperClassName="w-full px-3 py-2 border rounded-lg"
                    customInput={
                      <CustomInput
                        value={
                          values.rttrIntervalEnd instanceof Date &&
                          !isNaN(values.rttrIntervalEnd.getTime())
                            ? moment.utc(values.rttrIntervalEnd).format('MMMM')
                            : ''
                        }
                      />
                    }
                    showMonthYearPicker
                  />
                </div>
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  className="focus:shadow-outline float-right mb-4 rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                  disabled={loading}
                >
                  {t('Calendar.submit')}
                </button>
                <button
                  type="button"
                  id="cancelForm"
                  className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
                  onClick={() => {
                    resetForm();
                    navigate(-1);
                  }}
                >
                  {t('Calendar.cancel')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default CalendarTypeForm;
{
  /* Checkbox  */
}
{
  /* <div className="group relative my-6 w-full">
                  <Checkbox
                    onChange={handleChange}
                    id="extractEnterpriseClosingDatesFromEmployee"
                    name="extractEnterpriseClosingDatesFromEmployee"
                    checked={values.extractEnterpriseClosingDatesFromEmployee!}
                    label={t('Calendar.extractFromEmployee')}
                    errors={errors}
                  />
                </div> */
}
{
  /* ----- */
}
