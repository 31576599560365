import Breadcrumb from '@/components/Breadcrumb';
import DefaultLayout from '@/layout/DefaultLayout';
import React from 'react';

function Settings() {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Settings" />
      <h1>Settings </h1>
    </DefaultLayout>
  );
}

export default Settings;
