import api from '@/utils/api';
import { WorkHoursUrl } from '@/variables/Urls';

const apiEndpoint = WorkHoursUrl;

export interface PaginatingParams {
  page?: number;
  take?: number;
  search?: string;
  projectId?: string;
}

export interface WorkHourResponse {
  workHours: WorkHour[];
  count: number;
}

export interface AssignedTask {
  taskId: string;
  taskName: string;
}

export interface WorkHour {
  id?: string;
  memberId: string;
  date: Date;
  hours: number;
}

export async function getWorkHoursByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParams) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<WorkHourResponse>(
    `${apiEndpoint}/GetByProjectId`,
    config
  );

  return data;
}

// export async function saveWorkHour(workHour: WorkHour) {
//   if (workHour.id) {
//     const response = await api.put(`${apiEndpoint}/put`, workHour);
//     return response.data;
//   }

//   const response = await api.post(`${apiEndpoint}/post`, workHour);
//   return response.data;
// }

export interface WorkhoursRequest {
  workHours: WorkHour[];
  projectId: string | undefined;
}
export async function saveWorkHour(workHour: WorkhoursRequest) {
  const response = await api.post(`${apiEndpoint}/post`, workHour);
  return response.data;
}
export async function getWorkHour(id: string) {
  const { data } = await api.get<WorkHour>(`${apiEndpoint}/GetById`, {
    params: { id },
  });

  return data;
}

export function deleteWorkHour(id: string) {
  return api
    .delete(`${apiEndpoint}/delete`, { data: { id } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
