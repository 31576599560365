import { GenderEnum } from '@/enums/genderEnum';
import { MaritalStatusEnum } from '@/enums/maritialStatusEnum';
import {
  ChildModel,
  DegreeModel,
  JobDescriptionModel,
  JobHistoryModel,
  LanguageSkillModel,
  getJobDescriptionEmployeeById,
  saveJobDescription,
} from '@/services/JobDescriptionService';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

// Async Thunk for fetching job description by employee ID
export const fetchJobDescriptionByEmployeeId = createAsyncThunk<
  initStateProps,
  string
>('jobDescriptions/fetchByEmployeeId', async (employeeId: string, thunkAPI) => {
  try {
    const response: JobDescriptionModel = await getJobDescriptionEmployeeById(
      employeeId
    );

    return {
      jobDescription: response,
      status: 'succeeded',
      error: undefined,
    };
  } catch (error: any) {
    // Handle errors here if needed
    return thunkAPI.rejectWithValue({
      jobDescription: null,
      status: 'failed',
      error: error.message,
    });
  }
});

// Async Thunk for saving/updating job description
export const saveJobDescriptionAsync = createAsyncThunk(
  'jobDescriptions/save',
  async (jobDescription: JobDescriptionModel) => {
    const response = await saveJobDescription(jobDescription);
    return response;
  }
);
const initialJobDescription = {
  id: undefined,
  maritalStatus: MaritalStatusEnum.NoAnswer,
  spouseName: undefined,
  spouseDateOfBirth: null,
  marriageDate: null,
  employeeId: '',
  emergencyContactId: null,
  identityPapersId: null,
  firstName: '',
  lastName: '',
  gender: GenderEnum.NoAnswer,
  email: '',
  grade: null,
  idNumber: '',
  jobTitle: null,
  leaveCarriedOver: 0,
  birthDate: null,
  nationality: null,
  birthCountry: null,
  childNumber: 0,
  profileImgUrl: '',
  emergencyContact: {
    id: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    jobDescriptionId: '',
  },
  children: [],
  degrees: [],
  languageSkills: [],
  jobHistories: [],
  identityPapers: {
    id: null,
    socialSecurityNumber: '',
    identityCardNumber: '',
    identityCardIssueDate: null,
    passportNumber: '',
    passportIssueDate: null,
    drivingLicenseNumber: '',
    drivingLicenseIssueDate: null,
    drivingLicenseCategory: null,
    jobDescriptionId: '',
  },
  disabilityInformation: {
    ippRate: 0,
    rqthDate: null,
    workRestrictions: '',
    jobDescriptionId: '',
  },
  address: {
    street: '',
    zipCode: '',
    postalCode: '',
  },
  stateId: '',
  countryId: '',
  phoneNumber1: '',
  phoneNumber2: '',
};
export interface initStateProps {
  jobDescription: JobDescriptionModel;
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  error: string | undefined;
}
// Initial state
const initialState: initStateProps = {
  jobDescription: initialJobDescription,
  status: 'idle',
  error: undefined,
};

// Create the slice
const jobDescriptionSlice = createSlice({
  name: 'jobDescriptions',
  initialState,
  reducers: {
    updateGeneralJobDescriptionValues(
      state,
      action: PayloadAction<{ jobDescription: JobDescriptionModel }>
    ) {
      state.jobDescription = action.payload.jobDescription;
    },
    addChild(state, action: PayloadAction<{ child: ChildModel }>) {
      let children = [...state.jobDescription.children];
      var neChild = { ...action.payload.child, id: 'newC' + uniqueId() };
      state.jobDescription.children = [...children, neChild];
      return state;
    },
    updateChild(state, action: PayloadAction<{ child: ChildModel }>) {
      const index = state.jobDescription.children.findIndex(
        (ls) => ls.id === action.payload.child.id
      );
      if (index !== -1) {
        state.jobDescription.children[index] = action.payload.child;
      }
      return state;
    },
    removeChild(state, action: PayloadAction<{ childId: string }>) {
      let newChildren = state.jobDescription.children.filter(
        (x) => x.id !== action.payload.childId
      );
      state.jobDescription.children = [...newChildren];
      return state;
    },

    addDegree(state, action: PayloadAction<{ degree: DegreeModel }>) {
      let degrees = [...state.jobDescription.degrees];
      var newDegree = { ...action.payload.degree, id: 'newD' + uniqueId() };
      state.jobDescription.degrees = [...degrees, newDegree];
      return state;
    },
    updateDegree(state, action: PayloadAction<{ degree: DegreeModel }>) {
      const index = state.jobDescription.degrees.findIndex(
        (ls) => ls.id === action.payload.degree.id
      );
      if (index !== -1) {
        state.jobDescription.degrees[index] = action.payload.degree;
      }
      return state;
    },
    removeDegree(state, action: PayloadAction<{ degreeId: string }>) {
      let newDegrees = state.jobDescription.degrees.filter(
        (x) => x.id !== action.payload.degreeId
      );
      state.jobDescription.degrees = [...newDegrees];
      return state;
    },

    addLanguageSkill(
      state,
      action: PayloadAction<{ languageSkill: LanguageSkillModel }>
    ) {
      var languageSkill = {
        ...action.payload.languageSkill,
        id: 'newL' + uniqueId(),
      };
      state.jobDescription.languageSkills = [
        ...state.jobDescription.languageSkills,
        languageSkill,
      ];
    },

    // Reducer for updating an existing language skill
    updateLanguageSkill(
      state,
      action: PayloadAction<{ languageSkill: LanguageSkillModel }>
    ) {
      const index = state.jobDescription.languageSkills.findIndex(
        (ls) => ls.id === action.payload.languageSkill.id
      );
      if (index !== -1) {
        state.jobDescription.languageSkills[index] =
          action.payload.languageSkill;
      }
    },

    // Reducer for removing a language skill
    removeLanguageSkill(
      state,
      action: PayloadAction<{ languageSkillId: string }>
    ) {
      state.jobDescription.languageSkills =
        state.jobDescription.languageSkills.filter(
          (ls) => ls.id !== action.payload.languageSkillId
        );
    },

    // Reducer for adding a new job history
    addJobHistory(
      state,
      action: PayloadAction<{ jobHistory: JobHistoryModel }>
    ) {
      var newJobHistory = {
        ...action.payload.jobHistory,
        id: 'newJ' + uniqueId(),
      };
      state.jobDescription.jobHistories = [
        ...state.jobDescription.jobHistories,
        newJobHistory,
      ];
    },

    // Reducer for updating an existing job history
    updateJobHistory(
      state,
      action: PayloadAction<{ jobHistory: JobHistoryModel }>
    ) {
      const index = state.jobDescription.jobHistories.findIndex(
        (jh) => jh.id === action.payload.jobHistory.id
      );
      if (index !== -1) {
        state.jobDescription.jobHistories[index] = action.payload.jobHistory;
      }
    },

    // Reducer for removing a job history
    removeJobHistory(state, action: PayloadAction<{ jobHistoryId: string }>) {
      state.jobDescription.jobHistories =
        state.jobDescription.jobHistories.filter(
          (jh) => jh.id !== action.payload.jobHistoryId
        );
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetching job description by employee ID
    builder.addCase(fetchJobDescriptionByEmployeeId.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      fetchJobDescriptionByEmployeeId.fulfilled,
      (state, action) => {
        state.status = 'succeeded';
        state.jobDescription = action.payload.jobDescription;
      }
    );
    builder.addCase(
      fetchJobDescriptionByEmployeeId.rejected,
      (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      }
    );

    // Reducer for saving/updating job description
    builder.addCase(saveJobDescriptionAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(saveJobDescriptionAsync.fulfilled, (state, action) => {
      state.status = 'succeeded';
    });
    builder.addCase(saveJobDescriptionAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

// Export actions and reducer
export const {
  addChild,
  updateChild,
  removeChild,
  addLanguageSkill,
  updateLanguageSkill,
  removeLanguageSkill,
  addJobHistory,
  updateJobHistory,
  removeJobHistory,
  removeDegree,
  updateDegree,
  addDegree,
} = jobDescriptionSlice.actions;
export default jobDescriptionSlice.reducer;
