import { forgetPassword } from '@/services/AuthService';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { NavLink } from 'react-router-dom';

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email) {
      setEmailError(
        'Please include a valid email address so we can get back to you'
      );
    } else {
      setEmailError('');
      const token = '[token]';
      const emailplaceholder = '[email]';
      const resetUrl = `${window.location.origin}/reset-password?token=${token}&email=${emailplaceholder}`;
      const data = await forgetPassword({ email, callback: resetUrl });
      if (data) {
        toast.success('we have send you message to you email , check it', {
          duration: 6000,
        });
      }
    }
  };

  return (
    <main id="content" role="main" className="mx-auto w-full max-w-md p-6">
      <div className="mt-7 rounded-xl bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            <h1 className="block  text-2xl font-bold text-gray-800 dark:text-white">
              Forgot password?
            </h1>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Remember your password?
              <NavLink
                className="font-medium text-blue-600 decoration-2 hover:underline"
                to="/auth/login"
              >
                Login here
              </NavLink>
            </p>
          </div>

          <div className="mt-5">
            <form onSubmit={handleResetPassword}>
              <div className="grid gap-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="mb-2 ml-1 block text-sm font-bold dark:text-white"
                  >
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full rounded-md border-2 border-gray-200 px-4 py-3 text-sm shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      required
                      aria-describedby="email-error"
                    />
                  </div>

                  {emailError && (
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                      {emailError}
                    </p>
                  )}
                </div>
                <button
                  type="submit"
                  onClick={handleResetPassword}
                  className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-blue-500 px-4 py-3 text-sm font-semibold text-white transition-all hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
                >
                  Reset password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPasswordForm;
