import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export enum UserResponsibility {
  GestionDesAbsences,
  GestionNoteDeFrais,
  GestionDeVisiteMedicale,
  GestionEntretienAnnuel,
  GestionDesFormations,
  GestionDesFichesDePostesCV,
  GestionDesFournisseurs,
  GestionDesST,
  GestionDesIntervenantsPOA,
  GestionDesPlanningsPOA,
  GestionDesLivrablesPOA,
  GestionEtudeDePrixPOA,
  GestionMethodesPOA,
  GestionQualitePOA,
  GestionHSEPOA,
  GestionDesEtudesPOA,
  GestionDesRisquesPOA,
  GestionDesReunionsPOA,
  GestionDesRapports,
  GestionDesRH,
  GestionDesPOA,
  GestionDesPREA,
  GestionDesFournisseursPREA,
  GestionDesSTPREA,
  GestionDesIntervenantsPREA,
  GestionDesPlanningsPREA,
  GestionDesLivrablesPREA,
  GestionEtudeDePrixPREA,
  GestionMethodesPREA,
  GestionQualitePREA,
  GestionHSEPREA,
  GestionDesEtudesPREA,
  GestionDesRisquesPREA,
  GestionDesReunionsPREA,
  GestionDesRHRapports,
  GestionDesMaterials,
}

export const accessRightsArray = Object.entries(UserResponsibility)
  .filter(([key, value]) => typeof value === 'number')
  .map(([key, value]) => ({
    id: value as string,
    label: UserResponsibility[value].toString(),
  }));

export const getAccessRightsRHArray = (t: TFunction) => {
  return Object.entries(UserResponsibility)
    .filter(
      ([key, value]) =>
        typeof value === 'number' &&
        !key.includes('POA') &&
        !key.includes('PREA') &&
        !key.includes('Rapports')
    )
    .map(([key, value]) => ({
      id: value.toString(),
      label: t(`AccessRight.${UserResponsibility[value].toString()}`),
    }));
};

export const getAccessRightsPOAArray = (t: TFunction) => {
  return Object.entries(UserResponsibility)
    .filter(([key, value]) => typeof value === 'number' && key.includes('POA'))
    .map(([key, value]) => ({
      id: value.toString(),
      label: t(`AccessRight.${UserResponsibility[value].toString()}`),
    }));
};
export const getAccessRightsPREAArray = (t: TFunction) => {
  return Object.entries(UserResponsibility)
    .filter(([key, value]) => typeof value === 'number' && key.includes('PREA'))
    .map(([key, value]) => ({
      id: value.toString(),
      label: t(`AccessRight.${UserResponsibility[value].toString()}`),
    }));
};

export const getAccessRightsRapportArray = (t: TFunction) => {
  return Object.entries(UserResponsibility)
    .filter(
      ([key, value]) => typeof value === 'number' && key.includes('Rapports')
    )
    .map(([key, value]) => ({
      id: value.toString(),
      label: t(`AccessRight.${UserResponsibility[value].toString()}`),
    }));
};

export const accessRightNumbersToString = (
  accesRight: UserResponsibility[]
) => {
  return accesRight.map((a) => UserResponsibility[a]);
};
