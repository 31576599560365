import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ArrowRightIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/solid';
import { DataAndMonthPicker } from '@/components/DateAndMonthPicker';
import { useTranslation } from 'react-i18next';
import { getDaysData } from '@/utils/CalendarHelper';
import { locales, weekdays } from 'moment';
import { format } from 'date-fns';
import arLocale from 'date-fns/locale/ar'; // Import the Arabic locale
import enLocale from 'date-fns/locale/en-US'; // Import the Arabic locale
import esLocale from 'date-fns/locale/es'; // Import the Arabic locale
import deLocale from 'date-fns/locale/de'; // Import the Arabic locale
import frLocale from 'date-fns/locale/fr'; // Import the Arabic locale
import { registerLocale } from 'react-datepicker';
import { OffDay } from '@/services/WorkCalendarsService';
import { DaysCalendarColor } from '@/variables/const';
import moment from 'moment';
import useColorMode from '@/hooks/useColorMode';
import Avatar from './Avatar';
import { siteName } from '@/variables/Urls';
import { ProjectTeamMemberWorkCharges } from '@/services/ProjectTeamMemberService';
import { WorkHour } from '@/services/WorkHoursService';
import { uniqueId } from 'lodash';
import { AssignedTask } from '@/enums/AssignedTask';
import { PlanningTask } from '@/services/PlanningAOTasksService';

registerLocale('ar', arLocale);
registerLocale('en', enLocale);
registerLocale('es', esLocale);
registerLocale('de', deLocale);
registerLocale('fr', frLocale);

/* Timeline Interfaces */
export interface ResourceType extends ProjectTeamMemberWorkCharges {
  id: string;
}

export interface EventType {
  startDate: Date;
  endDate: Date;
  taskName: string;
  tooltip?: string;

  resourceId: string;
}

export interface OffDaysType extends OffDay {
  employeeId: string;
}

export interface WeekendsType {
  employeeId: string;
  days: string[];
}
/* --- End Timeline Interfaces ---*/
export interface PlanningTaskTimeLineProps {
  resourcesLength: number;
  daysOfWeek?: { day: number; dayOfWeek: string; isToday: boolean }[];
  resourcesList: PlanningTask[];
  searchResource: any;
  setSearchResource?: any;
  handleSearchResource?: any;
  selectedDate?: any;
  setSelectedDate?: any;
  isTodayinMonth?: any;
  selectedLanguage?: string;
  myEvents?: EventType[];
  handleEditClick: (id: string) => void;
  handleDeleteClick: (id: string) => void;
  weekendDays?: WeekendsType[];
  holidays?: OffDaysType[];
  enterpriseClosing?: OffDaysType[];
}
export const PlanningTaskTimeLine = React.forwardRef(
  (
    {
      resourcesLength,
      resourcesList,
      searchResource,
      setSearchResource,
      handleSearchResource,
      weekendDays,
      selectedDate,
      setSelectedDate,
      myEvents,
      holidays,
      enterpriseClosing,
      handleEditClick,
      handleDeleteClick,
    }: PlanningTaskTimeLineProps,
    ref
  ) => {
    console.log('🚀 ~ myEvents:', myEvents);
    const { i18n, t } = useTranslation();
    // const [isDarkMode, setIsDarkMode] = useState(false);
    const [colorMode, setColorMode] = useColorMode();
    const [, updateDummyState] = useState();

    const [workChargesData, setWorkChargesData] = useState(resourcesList);
    const [totalArray, setTotalArray] = useState(
      Array(resourcesList.length + 1).fill(0)
    );

    const [daysOfWeek, setDaysOfWeek] = useState([]);
    // const [specialEventsData, setSpecialEventsData] = useState(new Map());
    const [isTodayinMonth, setIsTodayinMonth] = useState<any>(true);
    //const specialDays: OffDay[] = [...holidays, ...enterpriseClosing];

    const locales: any = {
      en: enLocale,
      ar: arLocale,
      es: esLocale,
      de: deLocale,
      fr: frLocale,
    };

    useEffect(() => {
      let daysOfWeek1 = getDaysData(selectedDate, i18n.language);
      setDaysOfWeek(daysOfWeek1);
      const isToday = selectedDate.toDateString() === new Date().toDateString();
      setIsTodayinMonth((prev: boolean) => (prev = isToday));
    }, [selectedDate, i18n.language]);

    useEffect(() => {
      const className = 'dark';
      const bodyClass = window.document.body.classList;

      colorMode === 'dark'
        ? bodyClass.add(className)
        : bodyClass.remove(className);
    }, [colorMode]);

    // Create refs for both sections
    const resourcesRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);

    // // State to track the scroll positions of both sections
    // const [resourcesScrollTop, setResourcesScrollTop] = useState(0);
    // const [calendarScrollTop, setCalendarScrollTop] = useState(0);

    const decreaseMonth = () => {
      const currentDate = new Date(selectedDate);
      currentDate.setMonth(currentDate.getMonth() - 1);
      setSelectedDate(currentDate);
    };

    const increaseMonth = () => {
      const currentDate = new Date(selectedDate);
      currentDate.setMonth(currentDate.getMonth() + 1);
      setSelectedDate(currentDate);
    };
    // Generate cell ID based on resource ID and day number
    const generateCellId = (
      resourceId: string,
      dayOfWeek: string,
      month: number,
      year: number
    ) => `${resourceId}-${dayOfWeek}-${month}-${year}`;

    const currentMonth = selectedDate.getMonth() + 1; // Months are 0-indexed, so we add 1.
    const currentYear = selectedDate.getFullYear();

    const createSingleDayEvents = (events: any) => {
      const singleDayEventsMap = new Map();
      console.log('🚀 ~ events.forEach ~ events:', events);

      events.forEach((event: any) => {
        const { startDate, endDate, resourceId, ...rest } = event;
        const currentDay = new Date(startDate);
        console.warn(
          '🚀 ~ events.forEach ~ currentDay:',
          currentDay,
          endDate,
          resourceId
        );

        while (currentDay <= new Date(endDate)) {
          const eventId = generateCellId(
            resourceId,
            currentDay.getDate().toString(),
            currentDay.getMonth() + 1,
            currentDay.getFullYear()
          );

          const singleDayEvent = {
            ...rest,

            start: new Date(currentDay),
            end: new Date(currentDay),
            resourceId: resourceId,
            id: eventId,
          };

          //singleDayEvents.push(singleDayEvent);
          singleDayEventsMap.set(eventId, singleDayEvent);
          currentDay.setDate(currentDay.getDate() + 1);
        }
      });

      return singleDayEventsMap;
    };

    const myEventsData = useMemo(() => {
      // Your original events array
      // ...

      const singleDayEvents = createSingleDayEvents(myEvents);

      return singleDayEvents;
    }, [myEvents]);

    // const createSpecialEvents = (specialDays: OffDaysType[]) => {
    //   const specialEventsMap = new Map();

    //   specialDays.forEach((specialDay) => {
    //     const { fromDate, toDate, isPayed, employeeId } = specialDay;
    //     const start = moment(fromDate).startOf('day').toDate();
    //     const end = moment(toDate).endOf('day').toDate();

    //     const currentDay = new Date(start);
    //     while (currentDay <= end) {
    //       const type = isPayed ? 'holiday' : 'enterpriseClosing';

    //       const eventId = `${employeeId}-${currentDay.getDate()}-${
    //         currentDay.getMonth() + 1
    //       }-${currentDay.getFullYear()}`;
    //       const specialEvent = {
    //         title: type,
    //         start: new Date(currentDay),
    //         end: new Date(currentDay),
    //         resourceId: employeeId,
    //         id: eventId,
    //         color: DaysCalendarColor[type],
    //       };

    //       specialEventsMap.set(eventId, specialEvent);
    //       currentDay.setDate(currentDay.getDate() + 1);
    //     }
    //   });
    //   // for (const weekend of weekendDays) {
    //   //   for (const day of weekend.days) {
    //   //     const dayIndex = daysOfWeek.findIndex(
    //   //       (weekDay) => weekDay.dayOfWeek === day
    //   //     );

    //   //     if (dayIndex !== -1) {
    //   //       const currentDate = new Date(selectedDate);
    //   //       currentDate.setDate(currentDate.getDate() + dayIndex);

    //   //       for (let i = 0; i < resourcesList.length; i++) {
    //   //         const eventId = `${
    //   //           weekend.employeeId
    //   //         }-${day}-${currentDate.getMonth()}-${currentDate.getFullYear()}`;

    //   //         const weekendEvent = {
    //   //           title: 'Weekend',
    //   //           start: new Date(currentDate),
    //   //           end: new Date(currentDate),
    //   //           resourceId: weekend.employeeId,
    //   //           id: eventId,
    //   //           color: DaysCalendarColor.weekend,
    //   //         };

    //   //         specialEventsMap.set(eventId, weekendEvent);
    //   //       }
    //   //     }
    //   //   }
    //   // }

    //   return specialEventsMap;
    // };

    // const specialEventsData = useMemo(() => {
    //   const allSpecialDays = [...holidays, ...enterpriseClosing];

    //   return createSpecialEvents(allSpecialDays);

    //   // setSpecialEventsData(special);
    // }, [t, selectedDate]);

    // Function to get the resource index in the grid
    const calendarGridRef = useRef<HTMLDivElement>(null);

    const updateGridTemplateRows = () => {
      if (calendarGridRef.current) {
        const grid = calendarGridRef.current;
        const gridTemplateRows = `repeat(${resourcesList.length + 2}, 52px)`;
        grid.style.gridTemplateRows = gridTemplateRows;
      }
    };

    // const handleWriteHoursinCell = (e, memberId, date) => {
    //   console.log('it logs', e.target.value);

    //   // Parse the input value as a number
    //   const inputHours = parseFloat(e.target.value);

    //   // Clone the workChargesData array
    //   const workChargesClone = [...workChargesData];

    //   // Find the member by id
    //   const member = workChargesClone.find((x) => x.id === memberId);
    //   console.log('🚀 ~ handleWriteHoursinCell ~ member:', member);

    //   if (member) {
    //     // Find the workHours by date
    //     const workHours = member.workHours.find((x) =>
    //       moment(x.date).isSame(date)
    //     );
    //     console.log('🚀 ~ handleWriteHoursinCell ~ workHours:', workHours);

    //     if (workHours) {
    //       // Update the existing workHours if it exists
    //       workHours.hours = inputHours;
    //     } else {
    //       // Create a new workHours object if it doesn't exist
    //       const newWorkHours = {
    //         id: uniqueId(),
    //         date: date,
    //         memberId: memberId,
    //         hours: inputHours,
    //       };

    //       // Update the member's workHours array
    //       member.workHours = [...member.workHours, newWorkHours];
    //     }

    //     // Update the state with the modified workChargesClone
    //     setWorkChargesData((prev) => (prev = workChargesClone));
    //   }
    // };
    useEffect(() => {
      updateGridTemplateRows();
    }, [resourcesList.length]);
    //specialEventsData in usefeefect
    const isDevelopment = import.meta.env.MODE === 'development';

    const url = (imageData: string) => {
      return isDevelopment ? siteName + imageData : imageData;
    };

    // const getTotalHoursByResourceId = (resourceIndex: string): number => {
    //   const resource = workChargesData[resourceIndex];
    //   if (resource == null) {
    //     return workChargesData
    //       .map((x) => x.workHours)
    //       .flat()
    //       .reduce((total, workHour) => {
    //         const date = new Date(workHour.date);

    //         return total + workHour.hours;

    //         return total;
    //       }, 0);
    //   }
    //   return (
    //     resource?.workHours.reduce(
    //       (total, workHour) => total + workHour.hours,
    //       0
    //     ) ?? 0
    //   );
    // };

    // const getDailyHoursTotal = (
    //   day: number,
    //   month: number,
    //   year: number
    // ): number => {
    //   return workChargesData
    //     .map((x) => x.workHours)
    //     .flat()
    //     .reduce((total, workHour) => {
    //       const date = new Date(workHour.date);
    //       if (
    //         date.getDate() === day &&
    //         date.getMonth() === month &&
    //         date.getFullYear() === year
    //       ) {
    //         return total + workHour.hours;
    //       }
    //       return total;
    //     }, 0);
    // };

    return (
      <div
        ref={ref}
        className="  flex  overflow-x-scroll  bg-white p-0 dark:border-strokedark dark:bg-boxdark dark:text-bodydark"
      >
        {/* Legend */}

        {/* //! Resources Pannel */}
        <div
          className="bg-red flex 
                  min-w-[30%] border-r-2 border-[#dbe5ec]"
        >
          <div ref={resourcesRef} className="  w-full overflow-y-scroll">
            {/* ? thisis the head  */}

            <div
              className="sticky top-0 flex flex-col items-center  justify-between
               bg-white dark:border-strokedark dark:bg-boxdark dark:text-bodydark"
            >
              <div className="flex w-full justify-between px-2">
                <div>
                  <p>{t('Timeline.employees')} </p>
                  <p>
                    {resourcesList.length < 0 ? 0 : resourcesList.length}
                    of
                    {resourcesList.length < 0 ? 0 : resourcesList.length}
                  </p>
                </div>
                <div>
                  <span
                    onClick={() => setSearchResource('')}
                    className="cursor-pointer font-medium text-[#0db5df]"
                  >
                    <p>{t('Timeline.clearAll')}</p>
                  </span>
                </div>
              </div>

              <div
                key={'whitespace'}
                className="flex h-[52px]
                                    w-full items-center border-b border-[#dbe5ec]
                                   pl-4   "
              >
                <SearchIcon
                  className={`text-heading 
                      rounded-mddark:text-white
                       h-7
                        w-7
                         -rotate-180
                         cursor-pointer 
                         `}
                />
                <input
                  className="text-md  outline-none hover:ring-0 focus:ring-0 dark:border-strokedark dark:bg-boxdark dark:text-bodydark "
                  value={searchResource}
                  onChange={(e) => {
                    setSearchResource(e.target.value);
                  }}
                />
              </div>
            </div>

            {/* list of resources  */}

            <div className="w-full overflow-auto">
              <table className="w-full max-w-full overflow-x-scroll text-left text-sm text-gray-500 dark:text-gray-400">
                <thead className="text-md  bg-gray-50 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.taskNameColumn')}
                    </th>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.actionsColumn')}
                    </th>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.startDateColumn')}
                    </th>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.endDateColumn')}
                    </th>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.durationColumn')}
                    </th>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.progressColumn')}
                    </th>
                    <th className="px-6 py-3">
                      {t('PlanningAOTasks.responsibleColumn')}
                    </th>
                  </tr>
                </thead>

                {resourcesList.map(
                  (
                    {
                      id,

                      responsibleName,
                      progress,
                      duration,
                      endDate,
                      startDate,
                      taskName,
                    },
                    index
                  ) => (
                    <tr
                      key={id}
                      className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <td className="px-6 py-3">{taskName}</td>
                      <td className="flex px-6 py-3">
                        <button
                          type="button"
                          onClick={() => handleEditClick(id)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <PencilIcon className="mr-1 h-6 w-6" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteClick(id)}
                          className="ml-2 text-red-500 hover:text-red-700"
                        >
                          <TrashIcon className="mr-1 h-6 w-6" />
                        </button>
                      </td>
                      <td className="px-6 py-3">
                        {moment.utc(startDate).format('YYYY-MM-DD')}
                      </td>
                      <td className="px-6 py-3">
                        {moment.utc(endDate).format('YYYY-MM-DD')}
                      </td>
                      <td className="px-6 py-3">{duration}</td>
                      <td className="px-6 py-3">{progress}</td>
                      <td className="px-6 py-3">{responsibleName}</td>
                    </tr>
                  )
                )}
              </table>
            </div>
          </div>
        </div>
        {/* //? calander Module  */}
        <div
          ref={calendarRef}
          className="flex w-full flex-col overflow-x-hidden "
        >
          {/* //!header */}
          <div
            className="z-13   flex min-h-[50px]
                   w-[100%] items-center gap-2 bg-[#F4F7F9]    dark:border-strokedark dark:bg-boxdark dark:text-bodydark
                  "
          >
            <div className="flex items-center gap-2   dark:border-strokedark dark:bg-boxdark dark:text-bodydark">
              <button
                className="flex cursor-pointer items-center justify-center "
                onClick={decreaseMonth}
              >
                <ArrowRightIcon
                  className={`text-heading 
                  h-5 w-5 -rotate-180 cursor-pointer rounded-md`}
                />
              </button>
              <button
                className="flex cursor-pointer items-center justify-center  "
                onClick={increaseMonth}
              >
                <ArrowRightIcon
                  className={`text-heading 
                  h-5 w-5 cursor-pointer rounded-md`}
                />
              </button>
            </div>
            <DataAndMonthPicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedLanguage={i18n.language}
            />

            {!isTodayinMonth && (
              <button
                className="ml-6 text-sm font-medium text-[#0db5df]"
                onClick={() => setSelectedDate(new Date())}
              >
                {t('Timeline.today')}
              </button>
            )}
          </div>

          <div
            ref={calendarGridRef}
            className=" relative  grid h-full w-full overflow-x-scroll "
            style={{
              gridTemplateColumns: `repeat(${daysOfWeek.length}, minmax(50px, 1fr))`,
              gridTemplateRows: `repeat(${resourcesList.length}, 52px)`,
              gridAutoFlow: 'column',
            }}
          >
            {daysOfWeek.map(({ day, dayOfWeek, isToday }, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className=" sticky top-0 
              flex flex-col  items-center
               justify-center  border-b border-gray-300  bg-[#F4F7F9]  p-2
                px-2 dark:border-strokedark dark:bg-boxdark dark:text-white
               "
                    style={{
                      gridArea: `1 / ${day} / span 1 / span 1`,
                      color: isToday ? '#0db5df' : undefined,
                      boxShadow: isToday
                        ? 'inset 0 -4px 0 0 #0db5df'
                        : undefined,
                    }}
                  >
                    <p className="text-sm">{day}</p>
                    <p className="text-sm">{dayOfWeek}</p>
                  </div>
                </React.Fragment>
              );
            })}

            {resourcesList.map((resource, resourceIndex) => {
              return (
                <React.Fragment key={resource.id}>
                  {daysOfWeek.map(({ day, dayOfWeek }, index) => {
                    const cellId = generateCellId(
                      resource.id,
                      day.toString(),
                      currentMonth,
                      currentYear
                    );
                    console.log('thissi ', cellId, myEventsData);

                    // const eventId = generateCellId(
                    //   resourceId,
                    //   currentDay.getDate().toString(),
                    //   currentDay.getMonth() + 1,
                    //   currentDay.getFullYear()
                    // );
                    const specialCellId = `${
                      resource.id
                    }-${day.toString()}-${currentMonth}-${currentYear}`;
                    // const weekendCellId = `${resource.id}-${dayOfWeek}-${currentMonth}-${currentYear}`;
                    // const isWeekend = weekendDays?.some(
                    //   (weekend) =>
                    //     weekend &&
                    //     weekend.days &&
                    //     weekend.days.includes(dayOfWeek) &&
                    //     weekend.employeeId == resource.id
                    // );

                    return (
                      <div
                        key={cellId}
                        className="linear-gradient(#dbe5ec 1px,transparent 0) dark:text-black"
                        style={{
                          backgroundPosition: '-1px -1px',
                          backgroundSize: ' 52px 52px',
                          borderBottom: '1px solid #dbe5ec',
                          borderRight: '1px solid #dbe5ec',
                          gridArea: `${resourceIndex + 2} / ${
                            index + 1
                          } / span 1 / span 1`,
                          position: 'relative', // Add this to allow absolute positioning inside
                        }}
                      >
                        {/* {isWeekend && (
                          <div
                            className={`${DaysCalendarColor.weekend}`}
                            style={{
                              textAlign: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              position: 'absolute', // Position the colored div absolutely
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          ></div>
                        )} */}
                        {/* {specialEventsData.get(specialCellId) && (
                          <div
                            className={`${
                              specialEventsData.get(specialCellId).color
                            }`}
                            style={{
                              zIndex: 10,
                              textAlign: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              position: 'absolute', // Position the colored div absolutely
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          ></div>
                        )} */}

                        {myEventsData.get(cellId) && (
                          <div
                            style={{
                              backgroundColor: '#f22ff2',
                              paddingInline: '8px',
                              zIndex: 10,
                              textAlign: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              position: 'absolute', // Position the colored div absolutely
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              overflow: 'hidden', // Clip overflow content
                              whiteSpace: 'nowrap', // Prevent text from wrapping
                              textOverflow: 'ellipsis',
                            }}
                            // title={t(
                            //   `VacationType.${myEventsData.get(cellId).title}`
                            // )}
                          ></div>
                        )}
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

{
  /* <div className="flex w-full border-t-2 border-[#dbe5ec] bg-white p-4 dark:bg-boxdark dark:text-bodydark">
            <p className="mb-2 text-sm">{t('Legend')}</p>
            <div className="flex gap-2">
              <div
                className="h-4 w-4 rounded-md bg-red-500"
                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
              <p className="text-sm">{t('Holiday')}</p>
            </div>
            <div className="flex gap-2">
              <div
                className="h-4 w-4 rounded-md bg-blue-500"
                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
              <p className="text-sm">{t('Weekend')}</p>
            </div>
            <div className="flex gap-2">
              <div
                className="h-4 w-4 rounded-md bg-green-500"
                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
              <p className="text-sm">{t('Enterprise Closing')}</p>
            </div>
          </div> */
}
