import Accordion from '@/components/Accordian';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import { UserRoles } from '@/enums/UsersRole';
import DefaultLayout from '@/layout/DefaultLayout';
import { Pie3D } from 'react-pie3d';

import {
  EmployeesResponse,
  getEmployeesByTeamLeaderId,
  getEmployees,
} from '@/services/EmployeeService';
import { siteName } from '@/variables/Urls';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { GenderEnum } from '@/enums/genderEnum';
import { ContractType } from '@/enums/contractType';
import { RHRapportsModel, getRHRapports } from '@/services/RapportsServices';
import Spinner from '@/components/Spinner';
import { Chart, ChartWrapperOptions } from 'react-google-charts';
import PieChart from './components/PieChart';
import Dropdown, { Option } from '@/components/DropDown';
import { getYearOptions } from '@/variables/const';

function RhIndicators() {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedYear, setSelectedYear] = useState<Option>();
  const [yearOptions, setYearOptions] = useState(getYearOptions());

  const [search, setSearch] = useState<string>('');
  const isDevelopment = import.meta.env.MODE === 'development';

  const AvatarCell = ({ cell: { value } }: any) => {
    var url = isDevelopment ? siteName + value : value;
    return (
      <div className="w-10">
        <img
          className="h-10 w-10 rounded-full object-cover"
          src={url}
          alt="Profile Avatar"
        />
      </div>
    );
  };
  const {
    isLoading,
    isError,
    data: employees,
  } = useQuery<EmployeesResponse | undefined>(
    ['employees', pageIndex, pageSize, search],
    () => {
      return getEmployees({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  const {
    isLoading: isRapportsLaoding,
    isError: isRapportError,
    data: rapports,
  } = useQuery<RHRapportsModel | undefined>(
    ['rapports', selectedYear?.id],
    () => {
      return getRHRapports({ year: selectedYear?.id });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'profileImgUrl',
        Cell: AvatarCell,
      },
      {
        Header: t('Employees.firstNameHeader'),
        accessor: 'firstName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('Employees.lastNameHeader'),
        accessor: 'lastName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('Employees.emailHeader'),
        accessor: 'email',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('Employees.jobTitleHeader'),
        accessor: 'jobTitle',
      },
      {
        Header: t('Employees.departementNameHeader'),
        accessor: 'departementName',
      },
      {
        Header: t('Employees.branchNameHeader'),
        accessor: 'branchName',
      },
      {
        Header: t('Employees.TeamName'),
        accessor: 'teamName',
      },
      {
        Header: t('Employees.TeamLeaderEmail'),
        accessor: 'teamLeaderEmail',
      },
      {
        Header: t('Employees.TeamLeaderName'),
        accessor: 'teamLeaderFullName',
      },
    ],
    [t]
  );

  const handleYearChange = (event: any) => {
    const selectedYearId = event.target.value;

    const selectedYearObj = yearOptions.find((tem) => tem.id == selectedYearId);

    // Update the inputRef value
    setSelectedYear(selectedYearObj);
  };

  const data = [
    { value: 10, label: 'apples', color: 'red' },
    { value: 20, label: 'bananas', color: 'green' },
    { value: 30, label: 'oranges', color: 'blue' },
  ];
  const pieConfig = {
    ir: 0,
    strokeWidth: 0,
    angle: 50,
    size: 1,
    showLabelPercentage: true,
    showLabels: true,
    showTooltips: true,
  };
  const data14 = [
    ['Task', 'Hours per Day'],
    ['Work', 11],
    ['Eat', 2],
    ['Commute', 2],
    ['Watch TV', 2],
    ['Sleep', 7],
  ];

  const options: ChartWrapperOptions['options'] = {
    title: 'My Daily Activities',
    width: 450,
    height: 400,
    is3D: true,
    // legend: {
    //   position: 'bottom',
    //   textStyle: {
    //     color: '#000000', // Changing legend text color
    //   },
    // },
    animation: {
      startup: true,
      easing: 'inAndOut',
      duration: 1500,
    },
    backgroundColor: 'transparent',
    // titleTextStyle: {
    //   color: '#000000', // Changing title text color
    // },
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('RhIndicatorsPage.breadcrumb')} />
      <Accordion title={t('sidebar.Employees')}>
        <DynamicTable
          dataCount={employees?.count ?? 0}
          data={employees?.employees ?? []}
          columns={columns}
          setSearch={setSearch}
          exportPdf={false}
        />
      </Accordion>
      <Accordion title={t('RhIndicatorsPage.indicators')}>
        {isRapportsLaoding ? (
          <Spinner />
        ) : (
          <div>
            <Dropdown
              label={t('Year')}
              optionValue="id"
              optionLabel="name"
              options={yearOptions}
              labelClassName="w-[50%]"
              containerClass="w-[20%] mx-auto my-5"
              value={selectedYear?.id ?? ''}
              onChange={(e) => handleYearChange(e)}
            />
            <div className="grid gap-2 md:grid-cols-2">
              <PieChart
                data={rapports?.genderGraphData ?? []}
                options={options}
                title={t('RhIndicatorsPage.GenderChart')}
                translationNameSpace="GenderEnum"
              />

              <div>
                <PieChart
                  data={rapports?.nationalitiesData ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.Nationality')}
                  translationNameSpace="Countries"
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.languageSkillsData ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.Languages')}
                />
              </div>

              <div>
                <PieChart
                  data={rapports?.disabilityData ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.Disability')}
                />
              </div>

              <div>
                <PieChart
                  data={rapports?.annualInterviewsData ?? []}
                  options={options}
                  title={t('AnnualInterviews.pageName')}
                />
              </div>

              <div>
                <PieChart
                  data={rapports?.maritalStateData ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.MaritalState')}
                  translationNameSpace="MaritalStatusEnum"
                />
              </div>

              <div>
                <PieChart
                  data={rapports?.contractTypeData ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.ContractType')}
                  translationNameSpace="ContractType"
                />
              </div>

              <div>
                <PieChart
                  data={rapports?.enterAndExitData ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.EnterAndExitData')}
                  translationNameSpace="ContractState"
                />
              </div>

              <div>
                <PieChart
                  data={rapports?.educationLevels ?? []}
                  options={options}
                  title={t('RhIndicatorsPage.EducationLevel')}
                  translationNameSpace="ProfessionalStatus"
                />
              </div>
              <div>
                <PieChart
                  data={rapports?.projectExpensesData ?? []}
                  options={options}
                  title={t('ExpenseCategoryPage.ExpenseCategoryType')}
                  translationNameSpace="ExpenseCategory"
                />
              </div>
            </div>
          </div>
        )}
      </Accordion>
    </DefaultLayout>
  );
}

export default RhIndicators;
