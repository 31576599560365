import { WorkCalendarsUrl } from '@/variables/Urls';
import api from '@/utils/api';
import { AxiosResponse } from 'axios';
import { getCompanyId } from './AuthService';
import { VactionCalculateSystem } from '@/enums/VactionCalculateSystem';

export interface FreeDaysModel {
  weekendDates: Date[];
  holidayDates: Date[];
  closingDates: Date[];
}
export interface WorkCalendarsBrief {
  id: string;
  Weekends: string;
  Holidays: string;
  EnterpriseClosingDates: string;
  Name: string;
}
export interface WorkCalendar {
  id?: string;
  name: string;
  companyId: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;

  saturday?: boolean;
  sunday?: boolean;
  offDays?: OffDay[];

  workingYearStart?: Date;
  workingYearEnd?: Date;
  rttrIntervalStart?: Date;
  rttrIntervalEnd?: Date;
  vactionCalculateSystem?: VactionCalculateSystem;
}

export interface OffDay {
  id?: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  isPayed: boolean;
}

export interface WorkCalendarsResponse {
  workCalendars: WorkCalendar[];
  count: number;
}
export interface WorkCalendarsBriefResponse {
  workCalendars: WorkCalendarsBrief[];
  count: number;
}

const apiEndpoint = WorkCalendarsUrl;

export interface PaginatingParams {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}

export async function getWorkCalendars({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetAll', config);
    return data as WorkCalendar[];
  } catch (ex: any) {
    console.log(
      '🚀 ~ file: CalendarTypesService.ts:43 ~ getWorkCalendars ~ ex:',
      ex
    );
  }
}
export async function getWorkCalendarsBreif({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParams) {
  try {
    let config = {
      params: {
        page: fromvalue,
        take: takevalue,
        search: search,
        companyId: getCompanyId(),
      },
    };
    const { data } = await api.get(apiEndpoint + '/GetBrief', config);
    return data as WorkCalendarsBriefResponse;
  } catch (ex: any) {
    console.log(
      '🚀 ~ file: CalendarTypesService.ts:43 ~ getWorkCalendars ~ ex:',
      ex
    );
  }
}

export async function getWorkCalendar(workCalendarId: string) {
  try {
    const { data } = await api.get<WorkCalendar>(apiEndpoint + '/GetById', {
      params: { id: workCalendarId },
    });

    return data;
  } catch (error) {
    console.log(
      '🚀 ~ file: CalendarTypesService.ts:43 ~ getWorkCalendars ~ ex:',
      error
    );
  }
}

export async function getFreeDaysByEmployeeId({
  employeeId,
  month,
  year,
}: {
  employeeId: string;
  month: number;
  year: number;
}) {
  try {
    const { data } = await api.get<FreeDaysModel>(
      apiEndpoint + '/GetFreeDaysByEmployeeId',
      {
        params: {
          employeeId: employeeId,
          month: month,
          year: year,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(
      '🚀 ~ file: CalendarTypesService.ts:43 ~ getWorkCalendars ~ ex:',
      error
    );
  }
}
export async function getWorkCalendarByEmployeeId(employeeId: string) {
  try {
    const { data } = await api.get<WorkCalendar>(
      apiEndpoint + '/GetByEmployeeId',
      {
        params: { employeeId: employeeId },
      }
    );

    return data;
  } catch (error) {
    console.log(
      '🚀 ~ file: CalendarTypesService.ts:43 ~ getWorkCalendars ~ ex:',
      error
    );
  }
}

export async function saveWorkCalendar(workCalendar: WorkCalendar) {
  if (workCalendar.id) {
    const body = { ...workCalendar };

    try {
      const response = await api.put(apiEndpoint + '/put', body);
      return response;
    } catch (error) {
      console.log(
        '🚀 ~ file: CalendarTypesService.ts:83 ~ workCalendar ~ error:',
        error
      );
    }
  }
  try {
    const response_1 = await api.post(apiEndpoint + '/post', workCalendar);
    return response_1;
  } catch (error_1: any) {
    console.log(error_1.response);
  }
}

export function deleteWorkCalendar(workCalendarId: string) {
  return api
    .delete(apiEndpoint + '/delete', {
      data: { id: workCalendarId, companyId: getCompanyId() },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      return error;
    });
}
