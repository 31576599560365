import api from '@/utils/api';
import { ProjectCharacteristicsUrl } from '@/variables/Urls';

const apiEndpoint = ProjectCharacteristicsUrl;

export interface PaginatingParmas {
  page?: number;
  take?: number;
  search?: string;
  projectId: string;
}

export interface ProjectCharacteristicsResponse {
  projectCharacteristics: ProjectCharacteristics[];
  count: number;
}
export interface ProjectCharacteristics {
  positivePoints: string;
  negativePoints: string;
  pointsOfVigilance: string;
  projectId: string;
  id?: string;
}

export async function getProjectCharacteristicsByProjectId({
  projectId,
  page,
  take,
  search,
}: PaginatingParmas) {
  let config = {
    params: {
      projectId,
      page,
      take,
      search,
    },
  };

  const { data } = await api.get<ProjectCharacteristicsResponse>(
    apiEndpoint + '/GetByProjectId',
    config
  );

  return data;
}

// Example of a save function, assuming there is an endpoint for saving/updating characteristics
export async function saveProjectCharacteristics(
  characteristics: ProjectCharacteristics
) {
  if (characteristics.id) {
    const response = await api.put(apiEndpoint + '/put', characteristics);
    return response;
  }

  const response = await api.post(apiEndpoint + '/post', characteristics);
  return response;
}

export async function getProjectCharacteristics(partnerId: string) {
  const { data } = await api.get<ProjectCharacteristics>(
    apiEndpoint + '/GetById',
    {
      params: { id: partnerId },
    }
  );

  return data;
}

export function deleteProjectCharacteristic(id: string) {
  return api
    .delete(apiEndpoint + '/delete', { data: { id: id } })
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
}
