export enum DeliverableCategory {
  Design,
  Method,
  Planning,
  CalculationNote,
  Documentation,
  SoftwareDevelopment,
  Reports,
  Marketing,
  Training,
  FinancialDeliverables,
  ContractsAndAgreements,
  ProjectManagementDeliverables,
  Prototypes,
  MarketStudies,
  QualityDeliverables,
  RiskManagementPlans,
  DataModels,
  PerformanceEvaluations,
  CommunicationPlan,
  SecurityDeliverables,
  BusinessContinuityPlans,
  EnvironmentalImpactStudies,
  FeasibilityAnalysis,
  ComplianceDeliverables,
  TransitionDeliverables,
  IntegrationDeliverables,
  PoliciesAndProcedures,
  ProcessMaps,
  Dashboards,
  Flowcharts,
  SWOTAnalysis,
  TechnicalSpecifications,
  SpecificationsDocuments,
  TraceabilityMatrices,
  SpecificationSheets,
  DeploymentDeliverables,
  TestPlans,
  ValidationProtocols,
  ProjectClosureReports,
  FeedbackAndRecommendations,
  TechnicalSupportDeliverables,
  ChangeManagementPlans,
  GovernanceDeliverables,
  MaintenanceDeliverables,
  BackupAndRecoveryPlans,
  TrainingPlans,
  TechnologyRoadmaps,
  CaseStudies,
  UserManuals,
  AuditReports,
  SkillsAssessments,
  RecruitmentPlans,
  EvaluationGrids,
  OperationalProcedures,
  FeasibilityStudies,
  RegulatoryComplianceReports,
  ImplementationPlans,
  DataMigrationDeliverables,
  AcceptanceDocuments,
  FunctionalSpecifications,
  ContinuousImprovementPlans,
  IncidentReports,
  ContingencyPlans,
  SustainableDevelopmentDeliverables,
  InspectionReports,
  ComparativeTables,
  SimulationModels,
  QuarterlyFinancialReports,
  CertificationPlans,
  ProcessReengineeringPlans,
}

export const DeliverableCategoryOptions = Object.keys(DeliverableCategory)
  .filter((key) => isNaN(Number(DeliverableCategory[key])))
  .map((key) => ({
    id: key,
    name: DeliverableCategory[key],
  }));
