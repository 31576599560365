import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import './i18n/config';
import { LicenseProvider } from './context/LicenseContext';
import AppRouter from './routes';
import { SignalRProvider } from './context/SignalRContext';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1add07c1b9669a14017ed8dfc050cd16@o4507668542849024.ingest.de.sentry.io/4507668546912336',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

  // if (!isUserDataLoaded && signed) {
  //   // Display a loading indicator
  //   return <div>Loading...</div>;
  // }

  return (
    <SignalRProvider>
      <>
        <Toaster />
        <LicenseProvider>
          <AppRouter />
        </LicenseProvider>
      </>
    </SignalRProvider>
  );
}

export default App;
