import React, { useState } from 'react';
import { DynamismeModel } from '@/models/DynamismeModel';
import { SkillLevel } from '@/enums/SkillLevel';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRelationnel,
  saveAnnualInterviewAsync,
  updateDynamisme,
} from '@/state/slices/annualInterviewSlice';
import { useFormik } from 'formik';
import TextArea from '@/components/TextArea';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';
import { resetForm } from '@/state/slices/employeeSlice';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { InterviewState } from '@/enums/InterviewState';

const init: DynamismeModel = {
  id: '',
  contributionToDevelopment: SkillLevel.Bien,
  availability: SkillLevel.Correct,
  ideaContribution: SkillLevel.Excellent,
  projectProcessingSpeed: SkillLevel.Excellent,
  involvement: SkillLevel.Correct,
};

const DynamismeTable = () => {
  const { user } = useAuth();

  const { t } = useTranslation();
  const dynamisme = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview.dynamisme
  );
  const annualInerview = useSelector(
    (state: RootState) => state.annualInterviews.annualInterview
  );
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const [dynamismeLevels, setDynamismeLevels] = useState(dynamisme ?? init);
  const dynamismeKeys = Object.keys(dynamismeLevels) as Array<
    keyof DynamismeModel
  >;

  const isTeamLeader = annualInerview.responsibleId == user?.id;
  const isEmployee = annualInerview.employeeId == user?.id;
  const nonNumericKeys = Object.keys(SkillLevel).filter(
    (key) => !isNaN(Number(SkillLevel[key]))
  ) as Array<keyof typeof SkillLevel>;
  const disabled = annualInerview.interviewState == InterviewState.Completed;
  const formik = useFormik({
    initialValues: {
      ...init,
      ...dynamisme,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(updateDynamisme({ dynamisme: values }));
    },
  });
  const handleSkillLevelChange = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };
  const handleSyncData = () => {
    dispatch(saveAnnualInterviewAsync(annualInerview));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mx-2 flex flex-col gap-2">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3"></th>
              {nonNumericKeys.map((level: keyof typeof SkillLevel) => (
                <th key={level} scope="col" className="px-6 py-3">
                  {t(`SkillLevel.${level}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dynamismeKeys
              .filter(
                (objective) =>
                  objective !== 'id' &&
                  objective !== 'annualInterviewId' &&
                  objective !== 'annualInterview' &&
                  objective !== 'specificAbilities' &&
                  objective !== 'comments'
              )
              .map((dyn: keyof DynamismeModel) => (
                <tr
                  key={dyn}
                  className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <th scope="row" className="px-6 py-4 font-medium">
                    {t(`annualInterviewForm.Dynamisme.${dyn}`)}
                  </th>
                  {nonNumericKeys.map((level: keyof typeof SkillLevel) => (
                    <td key={level} className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={formik.values[dyn] === SkillLevel[level]}
                        onChange={() =>
                          handleSkillLevelChange(dyn, SkillLevel[level])
                        }
                        disabled={!isTeamLeader || disabled}
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        <div className="group relative mb-6 w-full">
          <TextArea
            id="specificAbilities"
            name="specificAbilities"
            label={t('annualInterviewForm.Dynamisme.specificAbilities')}
            value={formik.values.specificAbilities ?? ''}
            onChange={formik.handleChange}
            disabled={!isTeamLeader || disabled}
          />
        </div>
        <div className="group relative mb-6 w-full">
          <TextArea
            id="comments"
            name="comments"
            label={t('annualInterviewForm.Dynamisme.comments')}
            value={formik.values.comments ?? ''}
            onChange={formik.handleChange}
            disabled={isTeamLeader || disabled}
          />
        </div>
        {!disabled && (
          <div className="flex justify-between">
            <LabelButton type="button" onClick={() => resetForm()}>
              {t('annualInterviewForm.Dynamisme.reset')}
            </LabelButton>
            <PrimaryButton
              className="border-0 bg-purple-300 dark:bg-purple-500"
              type="button"
              onClick={handleSyncData}
            >
              <div className="flex items-center gap-2">
                <PaperAirplaneIcon className="h-4 w-4 rotate-90" />
                {t('annualInterviewForm.Dynamisme.Sync')}
              </div>
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              className="border-0 bg-primary-300 dark:bg-primary-500"
            >
              {t('annualInterviewForm.Dynamisme.submit')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </form>
  );
};

export default DynamismeTable;
