import api from '@/utils/api';
import { jobdescriptionsUrl } from '@/variables/Urls';
import { getCompanyId } from './AuthService';
import { Country, State } from './CountryService';
import { GenderEnum } from '@/enums/genderEnum';
import { MaritalStatusEnum } from '@/enums/maritialStatusEnum';

const jobDescriptionsUrl = jobdescriptionsUrl;

export interface JobDescriptionsResponse {
  jobDescriptions: JobDescriptionModel[];
  count: number;
}
export interface JobDescriptionModel {
  id?: string;
  maritalStatus?: MaritalStatusEnum;
  spouseName?: string;
  spouseDateOfBirth?: Date | null;
  marriageDate?: Date | null;
  employeeId: string;
  emergencyContactId?: string | null;
  identityPapersId?: string | null;
  firstName: string;
  lastName: string;
  gender: GenderEnum;
  email: string;
  grade?: string | null;
  idNumber: string;
  jobTitle?: string | null;
  leaveCarriedOver: number;
  birthDate: Date | null;
  nationality?: string | null;
  birthCountry?: string | null;
  childNumber: number;
  profileImgUrl: string;
  emergencyContact: EmergencyContactModel;
  children: ChildModel[];
  degrees: DegreeModel[];
  languageSkills: LanguageSkillModel[];
  jobHistories: JobHistoryModel[];
  identityPapers: IdentityPapersModel;
  disabilityInformation?: DisabilityInformationModel;
  address?: {
    street: string;
    zipCode: string;
    postalCode: string;
  };
  stateId?: string;
  countryId?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  birthCountryName?: string;
  nationalityName?: string;
  stateName?: string;
}

export interface EmergencyContactModel {
  id?: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  jobDescriptionId: string;
}

export interface ChildModel {
  id?: string | null;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: Date | null;
  isDependent: boolean;
  jobDescriptionId: string;
}

export interface DegreeModel {
  id?: string | null;
  yearOfCompletion: number;
  title: string;
  institution: string;
  jobDescriptionId: string;
}

export interface LanguageSkillModel {
  id?: string | null;
  language: string;
  canSpeak: boolean;
  canRead: boolean;
  canWrite: boolean;
  hasTest: boolean;
  testScore: string;
  jobDescriptionId: string;
}

export interface JobHistoryModel {
  id?: string | null;
  startDate: Date | null;
  endDate: Date | null;
  employerName?: string | null;
  employerAddress?: string | null;
  position?: string | null;
  reasonForLeaving?: string | null;
  jobDescriptionId: string;
}

export interface IdentityPapersModel {
  id?: string | null;
  socialSecurityNumber: string;
  identityCardNumber: string;
  identityCardIssueDate: Date | null;
  passportNumber: string;
  passportIssueDate: Date | null;
  drivingLicenseNumber: string;
  drivingLicenseIssueDate: Date | null;
  drivingLicenseCategory?: string | null;
  jobDescriptionId: string;
}
export interface DisabilityInformationModel {
  ippRate: number;
  rqthDate: Date | null;
  workRestrictions?: string;
  jobDescriptionId: string;
}
export interface PaginatingParmas {
  takevalue?: number;
  fromvalue?: number;
  search?: string;
}

export async function getJobDescriptions({
  fromvalue = 0,
  takevalue = 10,
  search = '',
}: PaginatingParmas) {
  let config = {
    params: {
      page: fromvalue,
      take: takevalue,
      search: search,
      companyId: getCompanyId(),
    },
  };
  const { data } = await api.get(jobDescriptionsUrl + '/Get', config);
  return data;
}

export async function getJobDescriptionEmployeeById(id: string) {
  const { data } = await api.get(jobDescriptionsUrl + '/GetByEmployeeId', {
    params: { employeeId: id },
  });
  return data as JobDescriptionModel;
}
export async function getJobDescriptionById(id: string) {
  const { data } = await api.get(jobDescriptionsUrl + '/GetById', {
    params: { id: id },
  });
  return data;
}
export async function saveJobDescription(jobDescription: JobDescriptionModel) {
  console.log(
    '🚀 ~ file: JobDescriptionService.ts:155 ~ saveJobDescription ~ jobDescription:',
    jobDescription
  );

  if (jobDescription.id) {
    const body = { ...jobDescription };

    const response = await api.put(jobDescriptionsUrl + '/put', body);
    return response;
  }

  const response_1 = await api.post(
    jobDescriptionsUrl + '/post',
    jobDescription
  );
  return response_1;
}
