import React from 'react';

interface BorderWrapperProps {
  title: string;
  children: React.ReactNode;
}

const BorderWrapper: React.FC<BorderWrapperProps> = ({ title, children }) => {
  return (
    <div className="relative rounded-lg border-2  border-gray-200 bg-white p-4 dark:border-strokedark dark:bg-boxdark">
      <div className="absolute -left-1 -top-3 mx-2 my-4 bg-white px-2 font-semibold text-gray-600 dark:border-strokedark dark:bg-boxdark dark:text-gray-200">
        {title}
      </div>
      <div className="px-5 py-4">{children}</div>
    </div>
  );
};

export default BorderWrapper;
